import { ContainerProps, components } from "react-select";
import { TPersonSelectOption } from "types/app/select";

export const SelectContainer = <
  Option extends TPersonSelectOption = TPersonSelectOption
>({
  children,
  ...props
}: ContainerProps<Option>): JSX.Element => {
  return (
    <components.SelectContainer {...props}>
      {children}
    </components.SelectContainer>
  );
};
