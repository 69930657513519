export enum EUserRole {
  ADMIN = "ADMIN",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

export type TRolePermission = {
  id: number;
  name: string;
  allowModificationAction: boolean;
};

export type TRole = {
  id: number;
  name: EUserRole;
  permissions: TRolePermission;
};
