import { Table } from "./Table.constants";
import { TDefaultTableData, TTableRowIdentifier } from "./Table.types";

/**
 * Get table column width which rely on provided `size` in `columns` array.
 *
 * This is a hack for `react-table` lib.
 * Library do not support custom width in percentage for columns, thats why this helper function exists.
 *
 * IMPORTANT!
 * To receive width in percentage you need to pass value in range from 0 to 1.
 * To receive auto, omit `size` property for column completely.
 * To receive pixels pass value bigger than 1.
 *
 * @param {Number} incomeSize - size returned what we passed to column.
 * @returns {String} result - width value based on income size.
 */
export const getCellHackedSize = (incomeSize: number): string => {
  if (incomeSize <= 1) {
    return `${incomeSize * 100}%`;
  }

  if (incomeSize === Table.DEFAULT.WIDTH_HACK) {
    return "auto";
  }

  return `${incomeSize}px`;
};

/**
 * The table utility function mutates the order of an array of table data based on input parameters.
 * @param previousData array of current table data.
 * @param updatedItemId item ID that was updated.
 * @param newPosition new position of the updated array item.
 * @param oldPosition old position of the updated array item.
 * @returns reordered array of table data.
 */
export const updateTableDataOrder = <
  TData extends TDefaultTableData = TDefaultTableData
>(
  previousData: TData[],
  updatedItemId: TTableRowIdentifier,
  newPosition: number,
  oldPosition: number
): TData[] =>
  previousData
    .map((item) =>
      item.id === updatedItemId
        ? { ...item, ...(item.order && { order: newPosition }) }
        : {
            ...item,
            ...(item.order && {
              order:
                newPosition <= item.order && oldPosition > item.order
                  ? item.order + 1
                  : newPosition >= item.order && oldPosition < item.order
                  ? item.order - 1
                  : item.order,
            }),
          }
    )
    .sort((a, b) => (a.order && b.order ? a.order - b.order : 0));
