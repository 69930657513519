import { useEffect, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { DraggableStartZoneList } from "../DraggableStartZoneList";
import { DroppableList } from "../DroppableList";
import { DraggableOverlay } from "../DraggableOverlay";
import {
  MAX_MAKE_WORDS_FROM_LETTERS_ATTEMPTS,
  letterFromBlank,
  letterFromBlankToEmptyBlank,
  letterFromBlankToOccupiedBlank,
  letterFromStartZoneSlot,
  letterFromStartZoneSlotWithReplacement,
  letterFromStartZoneToEmptyStartZoneSlot,
  letterFromStartZoneToOccupiedStartZoneSlot,
  letterToEmptyBlank,
  letterToEmptyStartZoneSlot,
  letterToOccupiedBlank,
  letterToOccupiedStartZoneSlot,
} from "../MakeWordFromLetters.helpers";
import {
  TExerciseIdentifier,
  TMakeWordFromLettersExerciseParsedWord,
  TMakeWordFromLettersExerciseParsedWordLetter,
  TMakeWordFromLettersExerciseWordBlank,
} from "types/app/exercises";
import "./DndContainer.styles.scss";

type TDndContainerProps = {
  /**
   * Parsed word data from exercise payload.
   */
  word: TMakeWordFromLettersExerciseParsedWord;
  /**
   * Fires after a draggable word letter was dropped into a blank.
   * @param letter data of the letter that was drag and dropped.
   * @param details detailed information about letter start and destination points.
   */
  onDragComplete?: (
    letter: TMakeWordFromLettersExerciseParsedWordLetter,
    details: {
      source: {
        type: "start-zone" | "blank";
        blank: TMakeWordFromLettersExerciseWordBlank | null;
      };
      destination: {
        type: "start-zone" | "blank";
        blank: TMakeWordFromLettersExerciseWordBlank | null;
      };
    }
  ) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DndContainer: React.FC<TDndContainerProps> = (props) => {
  const { word, onDragComplete, className } = props;

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragActiveId, setDragActiveId] = useState<TExerciseIdentifier | null>(
    null
  );
  const [startZoneLetters, setStartZoneLetters] = useState<
    TMakeWordFromLettersExerciseParsedWordLetter[]
  >(word.shuffledLetters);
  const [blanks, setBlanks] = useState<TMakeWordFromLettersExerciseWordBlank[]>(
    word.blanks
  );
  const [currentAttempt, setCurrentAttempt] = useState<number>(1);
  const [isShowResult, setIsShowResult] = useState<boolean>(false);

  const { isExercisePreviewMode, isExerciseLiveMode } = useLessonSettings();

  useEffect(() => {
    if (isExercisePreviewMode) {
      const filledBlanks = blanks.map((blank, index) => ({
        ...blank,
        currentLetter: {
          ...blank.currentLetter,
          attempts: [],
          content: blank.correctContent,
          currentAttempt: 0,
          currentBlankId: blank.id,
          id: word.letters[index]?.id || "",
          isCorrectDestination: true,
          isTouched: false,
        },
        isCorrectLetter: true,
      }));
      const filledStartZoneLetters = startZoneLetters.map((letter) => ({
        ...letter,
        isCorrectDestination: true,
        currentBlankId:
          filledBlanks.find((blank) => blank.currentLetter?.id === letter.id)
            ?.id || null,
      }));

      setBlanks(filledBlanks);
      setStartZoneLetters(filledStartZoneLetters);
      setIsShowResult(true);
    }

    if (isExerciseLiveMode) {
      setBlanks(word.blanks);
      setStartZoneLetters(word.shuffledLetters);
      setIsShowResult(false);
    }
  }, [isExercisePreviewMode, isExerciseLiveMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isAllLettersInBlanks: boolean = blanks.every(
      ({ currentLetter }) => currentLetter !== null
    );
    const isAllCorrect: boolean = blanks.every(
      ({ isCorrectLetter }) => isCorrectLetter
    );

    if (
      isAllLettersInBlanks &&
      currentAttempt < MAX_MAKE_WORDS_FROM_LETTERS_ATTEMPTS
    ) {
      setCurrentAttempt((prevAttempt) => prevAttempt + 1);
    }

    if (isAllCorrect) {
      setIsShowResult(true);
    }

    if (
      isAllLettersInBlanks &&
      currentAttempt === MAX_MAKE_WORDS_FROM_LETTERS_ATTEMPTS
    ) {
      setIsShowResult(true);
    }
    // We need to disable this rule because if we add `currentAttempt` it will cause a loop of circular dependency.
  }, [blanks]); // eslint-disable-line react-hooks/exhaustive-deps

  const wordDragStartHandler = (event: DragStartEvent) => {
    const { active } = event;

    setDragActiveId(active.id);
    setIsDragging(true);
  };

  const wordDragEndHandler = (event: DragEndEvent) => {
    const { over, active } = event;

    if (over && active) {
      const targetLetter: TMakeWordFromLettersExerciseParsedWordLetter | null =
        word.letters.find(({ id }) => id === active.id) || null;
      const targetBlank: TMakeWordFromLettersExerciseWordBlank | null =
        over.data.current?.blank || null;
      const replaceLetter: TMakeWordFromLettersExerciseParsedWordLetter | null =
        over.data.current?.letter || null;
      const previousBlank: TMakeWordFromLettersExerciseWordBlank | null =
        blanks.find((blank) => blank.currentLetter?.id === targetLetter?.id) ||
        null;

      const isLetterFromStartZone: boolean = startZoneLetters.some(
        ({ id, currentBlankId }) =>
          id === targetLetter?.id && currentBlankId === null
      );
      // Start zone blanks have letter ids, so we can check if the destination is start zone by checking if it has letter id
      const isDestinationStartZone: boolean = startZoneLetters.some(
        ({ id }) => id === over.id
      );

      // CASE 1: FROM START ZONE TO BLANK
      if (
        isLetterFromStartZone &&
        !replaceLetter &&
        targetLetter &&
        targetBlank &&
        targetBlank.currentLetter === null
      ) {
        setStartZoneLetters((prevLetters) =>
          letterFromStartZoneSlot(prevLetters, targetLetter.id, targetBlank)
        );
        setBlanks((prevBlanks) =>
          letterToEmptyBlank(prevBlanks, targetBlank.id, targetLetter)
        );
      }

      // CASE 2: FROM START ZONE TO REPLACE WORD IN BLANK
      if (
        isLetterFromStartZone &&
        replaceLetter &&
        targetLetter &&
        targetBlank &&
        targetBlank.currentLetter?.id === replaceLetter.id
      ) {
        setStartZoneLetters((prevLetters) =>
          letterFromStartZoneSlotWithReplacement(
            prevLetters,
            targetLetter,
            replaceLetter,
            targetBlank
          )
        );
        setBlanks((prevBlanks) =>
          letterToOccupiedBlank(prevBlanks, targetBlank.id, targetLetter)
        );
      }

      // CASE 3: FROM BLANK TO START ZONE
      if (
        !isLetterFromStartZone &&
        isDestinationStartZone &&
        targetLetter &&
        !replaceLetter
      ) {
        setStartZoneLetters((prevLetters) =>
          letterToEmptyStartZoneSlot(prevLetters, targetLetter, over.id)
        );
        setBlanks((prevBlanks) => letterFromBlank(prevBlanks, targetLetter.id));
      }

      // CASE 4: FROM BLANK TO OTHER EMPTY BLANK
      if (
        !isLetterFromStartZone &&
        previousBlank &&
        !replaceLetter &&
        targetBlank &&
        targetLetter &&
        targetBlank.currentLetter === null
      ) {
        setBlanks((prevBlanks) =>
          letterFromBlankToEmptyBlank(
            prevBlanks,
            previousBlank.id,
            targetBlank.id,
            targetLetter
          )
        );
      }

      // CASE 5: FROM BLANK TO OTHER OCCUPIED BLANK
      if (
        !isLetterFromStartZone &&
        previousBlank &&
        replaceLetter &&
        targetBlank &&
        targetLetter &&
        targetBlank.currentLetter?.id !== targetLetter.id
      ) {
        setBlanks((prevBlanks) =>
          letterFromBlankToOccupiedBlank(
            prevBlanks,
            previousBlank.id,
            targetBlank.id,
            targetLetter,
            replaceLetter
          )
        );
      }

      // CASE 6: FROM BLANK TO OCCUPIED START ZONE
      if (
        !isLetterFromStartZone &&
        isDestinationStartZone &&
        replaceLetter &&
        targetLetter &&
        previousBlank
      ) {
        setStartZoneLetters((prevLetters) =>
          letterToOccupiedStartZoneSlot(
            prevLetters,
            targetLetter,
            replaceLetter,
            previousBlank
          )
        );
        setBlanks((prevBlanks) =>
          letterToOccupiedBlank(prevBlanks, previousBlank.id, replaceLetter)
        );
      }

      // CASE 7: FROM START ZONE TO EMPTY START ZONE
      if (
        isLetterFromStartZone &&
        isDestinationStartZone &&
        targetLetter &&
        !replaceLetter
      ) {
        setStartZoneLetters((prevLetters) =>
          letterFromStartZoneToEmptyStartZoneSlot(
            prevLetters,
            targetLetter,
            over.id
          )
        );
      }

      // CASE 8: FROM START ZONE TO OCCUPIED START ZONE
      if (
        isLetterFromStartZone &&
        isDestinationStartZone &&
        targetLetter &&
        replaceLetter
      ) {
        setStartZoneLetters((prevLetters) =>
          letterFromStartZoneToOccupiedStartZoneSlot(
            prevLetters,
            targetLetter,
            replaceLetter
          )
        );
      }

      if (targetLetter) {
        onDragComplete?.(targetLetter, {
          source: {
            type: isLetterFromStartZone ? "start-zone" : "blank",
            blank: isLetterFromStartZone ? null : previousBlank,
          },
          destination: {
            type: isDestinationStartZone ? "start-zone" : "blank",
            blank: isDestinationStartZone ? null : targetBlank,
          },
        });
      }
    }

    setDragActiveId(null);
    setIsDragging(false);
  };

  const wordDragCancelHandler = () => {
    setDragActiveId(null);
    setIsDragging(false);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
      onDragStart={wordDragStartHandler}
      onDragEnd={wordDragEndHandler}
      onDragCancel={wordDragCancelHandler}
    >
      <Space
        direction="vertical"
        size="medium"
        justify="start"
        fullWidth
        className={className}
      >
        <DroppableList
          blanks={blanks}
          hint={word.hint}
          dragging={isDragging}
          dragActiveId={dragActiveId}
          showResult={isShowResult}
        />
        <DraggableStartZoneList
          letters={startZoneLetters}
          dragActiveId={dragActiveId}
        />
      </Space>
      <DraggableOverlay letters={word.letters} dragActiveId={dragActiveId} />
    </DndContext>
  );
};
