import { useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { LessonDetailsContent as Content } from "content";
import { useModal } from "hooks/common";
import { useLessonSettings } from "hooks/redux";
import { useRemoveCourseLessonChapter } from "hooks/api/courses";
import { ActionModal } from "components/Common/ActionModal";
import { Button } from "components/Interactive/Button";
import { Loader } from "components/Common/Loader";
import { ChapterSidebarList } from "../ChaptersSidebarList";
import { EditLessonChapterModal } from "../EditLessonChapterModal";
import { logError, logSuccess } from "utils/notifications";
import type { TCourseLessonChapter } from "types/api/course";
import "./ChaptersSidebar.styles.scss";

type ChaptersSidebarProps = {
  /**
   * Lesson chapters data to display.
   * @default []
   */
  lessonChapters?: TCourseLessonChapter[];
  /**
   * If `true` component will display loader with animation.
   *
   * @default false
   */
  loading?: boolean;
  /**
   * Indicates active chapter item ID, if any.
   * @default null
   */
  activeChapterId?: number | null;
  /**
   * Callback function fires on chapter item click to set active state.
   * @param chapterId current active chapter ID.
   * @returns {void} nothing.
   */
  onChapterClick?: (chapterId: number) => void;
  /**
   * Callback function fires on chapter add button click.
   * @returns {void} nothing.
   */
  onAddChapterClick?: () => void;
};

export const ChaptersSidebar: React.FC<ChaptersSidebarProps> = (props) => {
  const {
    lessonChapters = [],
    loading = false,
    activeChapterId = null,
    onChapterClick,
    onAddChapterClick,
  } = props;

  const { courseId, lessonId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedChapter, setSelectedChapter] =
    useState<TCourseLessonChapter | null>(null);

  const { viewMode } = useLessonSettings();

  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal(false);

  const { mutate: deleteLessonChapter } = useRemoveCourseLessonChapter(
    courseId,
    lessonId
  );

  const openEditModalHandler = (chapterData: TCourseLessonChapter) => {
    setSelectedChapter(chapterData);
    openEditModal();
  };

  const openDeleteModalHandler = (chapterData: TCourseLessonChapter) => {
    setSelectedChapter(chapterData);
    openDeleteModal();
  };

  const chapterDeleteButtonClickHandler = () => {
    if (!selectedChapter) {
      logError({
        message: Content.Chapter.Error.INVALID_LESSON_CHAPTER_ID,
      });
      return;
    }
    deleteLessonChapter(selectedChapter.id, {
      onSuccess: () => {
        logSuccess(Content.Chapter.Success.DELETED);
        setSelectedChapter(null);
        closeDeleteModal();
        searchParams.delete("chapterId");
        setSearchParams(searchParams);
      },
    });
  };

  return (
    <section className="nb-lesson-details-chapters-sidebar">
      {loading ? (
        <Loader className="nb-lesson-details-chapters-loader" />
      ) : (
        <ChapterSidebarList
          lessonChapters={lessonChapters}
          activeChapterId={activeChapterId}
          onChapterClick={onChapterClick}
          onChapterEditClick={openEditModalHandler}
          onChapterDeleteClick={openDeleteModalHandler}
        />
      )}
      {viewMode === "constructor" && (
        <Button variant="secondary" fullWidth onClick={onAddChapterClick}>
          {Content.Sidebar.Chapters.Button.ADD}
        </Button>
      )}
      {viewMode === "constructor" && (
        <EditLessonChapterModal
          open={isEditModalOpen}
          lessonChapter={selectedChapter}
          onClose={closeEditModal}
        />
      )}
      {viewMode === "constructor" && (
        <ActionModal
          text={Content.Delete.Chapter.TITLE}
          emoji={Content.Delete.Chapter.EMOJI}
          open={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onSubmit={chapterDeleteButtonClickHandler}
        />
      )}
    </section>
  );
};
