import { isValidElement, useCallback, useMemo, useRef } from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import ReactSelect, {
  ControlProps,
  DropdownIndicatorProps,
  NoticeProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";
import { nanoid } from "nanoid";
import cx from "classnames";
import { SelectContent, ValidationContent } from "content";
import { SelectDropdownIndicator } from "./components/DropdownIndicator";
import { SelectControl } from "./components/Control";
import { SelectMenu } from "./components/Menu";
import { SelectOption } from "./components/Option";
import { SelectSingleValue } from "./components/SingleValue";
import { SelectPlaceholder } from "./components/Placeholder";
import { SelectNoOptionsMessage } from "./components/NoOptionsMessage";
import { SelectInput } from "./components/Input";
import { SelectClearIndicator } from "./components/ClearIndicator";
import { SelectValueContainer } from "./components/ValueContainer";
import { SelectMenuList } from "./components/MenuList";
import { SelectMenuPortal } from "./components/MenuPortal";
import { getPortalTarget } from "./Select.helpers";
import type { TSelectDefaultOption } from "./Select.types";
import "./Select.styles.scss";

type SelectProps<
  TFormValues extends FieldValues = FieldValues,
  Option extends TSelectDefaultOption = TSelectDefaultOption
> = {
  /**
   * Name attribute of the `Select` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: FieldPath<TFormValues>;
  /**
   * 	Validation rules in the same format for register, which includes: required, min, max, minLength, maxLength, pattern, validate
   *
   * @example
   * ```tsx
   * rules={{
   *  pattern: {
   *    value: EMAIL_REGEX,
   *    message: "Email address is invalid",
   *  }
   * }}
   * ```
   * @link https://react-hook-form.com/api/useform/register#options
   */
  rules?: RegisterOptions;
  /**
   * 	An object with field errors. There is also an ErrorMessage component to retrieve error message easily.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * Array of options that populate the select menu
   */
  options?: Option[];
  /**
   * Is the select value clearable
   *
   * @default {false}
   */
  clearable?: boolean;
  /**
   * Is the select disabled
   *
   * @default {false}
   */
  disabled?: boolean;
  /**
   * Is the select readonly
   *
   * @default false
   */
  readonly?: boolean;
  /**
   * Element placed left of the selected value.
   */
  icon?: React.ReactElement;
  /**
   * If `true`, the `Select` element is required.
   *
   * @default {false}
   */
  required?: boolean;
  /**
   * Whether to enable search functionality
   *
   * @default {false}
   */
  searchable?: boolean;
  /**
   * If `true`, the `select` will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * The short hint displayed in the `Select` before the user selects a value.
   */
  placeholder?: string;
  /**
   * Message to be displayed in the menu if there are no options available.
   */
  noOptionsText?: string;
  /**
   * Select label text for the `select` element.
   */
  label?: string;
  /**
   * The id of the `select` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * `Select` interactive element based on `react-select` library in association with `react-hook-form`
 */
export const Select = <
  TFormValues extends FieldValues = FieldValues,
  Option extends TSelectDefaultOption = TSelectDefaultOption
>(
  props: SelectProps<TFormValues, Option>
): JSX.Element => {
  const {
    clearable = false,
    disabled = false,
    required = false,
    searchable = false,
    fullWidth = false,
    readonly = false,
    id,
    label,
    icon,
    options,
    name,
    rules,
    control,
    errors,
    placeholder = SelectContent.DEFAULT.PLACEHOLDER,
    noOptionsText = SelectContent.DEFAULT.EMPTY,
    className,
  } = props;

  const targetError = errors?.[name];
  const hasError = !!(errors && targetError);

  const innerRules: RegisterOptions = {
    ...(required && { required: ValidationContent.Required }),
    ...rules,
    disabled,
  };

  const idRef = useRef<string>(`select-${nanoid()}`);
  /**
   * Check if all options labels are JSX elements
   * If true, then we need to add additional styles modifications on some sub-components
   */
  const isLabelsJSXElements: boolean = useMemo(
    () =>
      (options?.length !== 0 &&
        options?.every((option) => isValidElement(option?.label))) ||
      false,
    [options]
  );

  const SelectControlMemorized = useCallback(
    (controlProps: ControlProps<Option, false>) => (
      <SelectControl {...controlProps} icon={icon} readonly={readonly} />
    ),
    [icon, readonly]
  );

  const SelectPlaceholderMemorized = useCallback(
    (placeholderProps: PlaceholderProps<Option, false>) => (
      <SelectPlaceholder {...placeholderProps} placeholder={placeholder} />
    ),
    [placeholder]
  );

  const SelectNoOptionsMemorized = useCallback(
    (noOptionsProps: NoticeProps<Option, false>) => (
      <SelectNoOptionsMessage
        {...noOptionsProps}
        noOptionsText={noOptionsText}
      />
    ),
    [noOptionsText]
  );

  const SelectValueContainerMemorized = useCallback(
    (valueContainerProps: ValueContainerProps<Option, false>) => (
      <SelectValueContainer
        {...valueContainerProps}
        icon={icon}
        isLabelsJSXElements={isLabelsJSXElements}
      />
    ),
    [icon, isLabelsJSXElements]
  );

  const SelectDropdownIndicatorMemorized = useCallback(
    (dropdownIndicatorProps: DropdownIndicatorProps<Option, false>) => (
      <SelectDropdownIndicator
        {...dropdownIndicatorProps}
        isLabelsJSXElements={isLabelsJSXElements}
      />
    ),
    [isLabelsJSXElements]
  );

  const SelectOptionMemorized = useCallback(
    (optionProps: OptionProps<Option, false>) => (
      <SelectOption
        {...optionProps}
        isLabelsJSXElements={isLabelsJSXElements}
      />
    ),
    [isLabelsJSXElements]
  );

  const SelectSingleValueMemorized = useCallback(
    (singleValueProps: SingleValueProps<Option, false>) => (
      <SelectSingleValue
        {...singleValueProps}
        isLabelsJSXElements={isLabelsJSXElements}
      />
    ),
    [isLabelsJSXElements]
  );

  return (
    <div
      className={cx(["nb-interactive-select-wrapper", className])}
      aria-live="polite"
    >
      {label && (
        <label className="nb-interactive-select-label" htmlFor={id}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={innerRules}
        render={({ field }) => (
          <ReactSelect<Option, false>
            {...field}
            value={field.value as Option}
            isClearable={clearable}
            isDisabled={readonly || disabled}
            isMulti={false}
            required={required}
            isSearchable={searchable}
            placeholder={placeholder}
            options={options}
            id={id}
            menuPortalTarget={getPortalTarget(idRef.current)}
            className={cx([
              "nb-interactive-select",
              {
                "nb-interactive-select--full": fullWidth,
              },
            ])}
            components={{
              IndicatorSeparator: () => null,
              Control: SelectControlMemorized,
              DropdownIndicator: readonly
                ? () => null
                : SelectDropdownIndicatorMemorized,
              Menu: SelectMenu,
              MenuList: SelectMenuList,
              Option: SelectOptionMemorized,
              ValueContainer: SelectValueContainerMemorized,
              SingleValue: SelectSingleValueMemorized,
              MenuPortal: SelectMenuPortal,
              Placeholder: SelectPlaceholderMemorized,
              NoOptionsMessage: SelectNoOptionsMemorized,
              Input: SelectInput,
              ClearIndicator: SelectClearIndicator,
            }}
          />
        )}
      />
      {hasError && (
        <small className="nb-interactive-select-error-text">
          {targetError?.message?.toString()}
        </small>
      )}
    </div>
  );
};
