import { DropdownIndicatorProps, components } from "react-select";
import cx from "classnames";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { TAssertionSelectDefaultOption } from "../../../AssertionSentences.types";
import "./DropdownIndicator.styles.scss";

export const DropdownIndicator = <
  Option extends TAssertionSelectDefaultOption = TAssertionSelectDefaultOption
>({
  isFocused,
  ...dropdownProps
}: DropdownIndicatorProps<Option, false>): JSX.Element => {
  return (
    <components.DropdownIndicator
      {...dropdownProps}
      className={cx([
        "nb-interactive-true-or-false-assertion-select-dropdown",
        {
          "nb-interactive-true-or-false-assertion-select-dropdown--focused":
            isFocused,
        },
      ])}
      isFocused={isFocused}
    >
      <Chevron
        width={15}
        height={15}
        className={cx([
          "nb-interactive-true-or-false-assertion-select-icon-chevron",
          {
            "nb-interactive-true-or-false-assertion-select-icon-chevron--focused":
              isFocused,
          },
        ])}
      />
    </components.DropdownIndicator>
  );
};
