import { TUrlParam } from "types/utils";

export enum EAdminQueryKeys {
  getLandingOffers = "getLandingOffers",
  createLandingOffer = "createLandingOffer",
  deleteLandingOffer = "deleteLandingOffer",
  editLandingOffer = "editLandingOffer",
}

export const ADMIN_ENDPOINTS = {
  LANDING: "/admin/landing",
  LANDING_ID: (id: TUrlParam) => `/admin/landing/${id}`,
};
