import { Control, Path, UseFormRegister } from "react-hook-form";
import { Checkbox } from "components/Interactive/Checkbox";
import { IconButton } from "components/Interactive/IconButton";
import { Input } from "components/Interactive/Input";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { TTestExerciseQuestions } from "types/app/exercises";
import "./AnswerInput.styles.scss";

type AnswerInputProps<
  TFormValues extends TTestExerciseQuestions = TTestExerciseQuestions
> = {
  /**
   * The id that `react-hook-form` created to associate `input` element and form array fields.
   */
  id: string;
  /**
   * Index of the question `react-hook-form` array fields.
   */
  questionIndex: number;
  /**
   * Index of the question answer `react-hook-form` array fields.
   */
  answerIndex: number;
  /**
   * The short hint displayed in the answers `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * If `true`, the delete answer button is disabled. Used for delete prevention of only one answer.
   * @default false
   */
  disableDelete?: boolean;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (
    answerIndex: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

export const AnswerInput = <
  TFormValues extends TTestExerciseQuestions = TTestExerciseQuestions
>(
  props: AnswerInputProps<TFormValues>
): JSX.Element => {
  const {
    id,
    placeholder,
    control,
    register,
    disableDelete = false,
    questionIndex,
    answerIndex,
    onDelete,
  } = props;

  const answerDeleteButtonClickHandler = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onDelete?.(answerIndex, evt);
  };

  return (
    <div className="nb-interactive-answer-input-container">
      <Checkbox<TFormValues>
        name={
          `questions.${questionIndex}.answers.${answerIndex}.isCorrect` as Path<TFormValues>
        }
        id={`answer-input-is-correct-${id}`}
        className="nb-interactive-answer-input-checkbox"
        control={control}
      />
      <Input<TFormValues>
        name={
          `questions.${questionIndex}.answers.${answerIndex}.content` as Path<TFormValues>
        }
        id={`answer-input-field-${id}`}
        className="nb-interactive-answer-input-field"
        placeholder={`${placeholder} ${answerIndex + 1}`}
        register={register}
        fullWidth
      />
      <IconButton
        variant="secondary"
        size="medium"
        icon={<Close />}
        className="nb-interactive-answer-input-remove"
        onClick={answerDeleteButtonClickHandler}
        disabled={disableDelete}
      />
    </div>
  );
};
