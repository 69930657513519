import { useParams } from "react-router-dom";
import { useHomeworkExercises } from "hooks/api/exercises";
import { useExerciseConstructor, useLessonSettings } from "hooks/redux";
import { LessonDetailsContent } from "content";
import { Button } from "components/Interactive/Button";
import { Divider } from "components/Common/Divider";
import { ChapterNameText } from "../ChapterNameText";
import { ExercisesPreview } from "../ExercisesPreview";
import "./HomeworkContentExercises.styles.scss";

type HomeworkContentExercisesProps = {
  /**
   * Unique identifier of the lesson homework entity.
   */
  homeworkId?: number;
};

export const HomeworkContentExercises: React.FC<
  HomeworkContentExercisesProps
> = (props) => {
  const { homeworkId } = props;

  const { courseId, lessonId } = useParams();

  const { data: homeworkExercises, isLoading } = useHomeworkExercises(
    courseId,
    lessonId,
    homeworkId
  );

  const { openExerciseList } = useExerciseConstructor();
  const { viewMode } = useLessonSettings();

  const openExerciseConstructorClickHandler = () => {
    openExerciseList();
  };

  return (
    <div className="nb-lesson-details-homework-exercises-wrapper">
      <div className="nb-lesson-details-homework-exercises">
        <ChapterNameText
          text={LessonDetailsContent.Content.Homework.EXERCISES}
        />
        <ExercisesPreview
          exercises={homeworkExercises}
          chapterId={1}
          loading={isLoading}
        />
        {viewMode === "constructor" && (
          <Button
            variant="secondary"
            fullWidth
            onClick={openExerciseConstructorClickHandler}
          >
            {LessonDetailsContent.Content.Homework.Button.ADD}
          </Button>
        )}
      </div>
      <Divider
        margin="yAxis"
        marginSize="large"
        className="nb-lesson-details-homework-exercises-divider"
      />
    </div>
  );
};
