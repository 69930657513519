export const LessonDetails = {
  Name: "Урок",
  Emoji: "📒",
  Header: {
    TEXT: "Це режим перегляду та редагування інтерактивного уроку",
    Button: {
      EXIT: "Вийти",
      PREVIEW: "Інтерактивний урок",
    },
  },
  Content: {
    Lesson: {
      TITLE: "Урок",
      Button: {
        ADD: "Додати нову вправу",
      },
    },
    Homework: {
      TITLE: "Домашня робота",
      EXERCISES: "Вправи для домашньої роботи",
      Button: {
        ADD: "Додати нову вправу",
      },
    },
  },
  Sidebar: {
    Chapters: {
      EMOJI: "🖍",
      TEXT: "Розділ",
      Button: {
        ADD: "Додати розділ",
      },
    },
    Homework: {
      EMOJI: "📮",
      TEXT: "Домашня робота",
    },
  },
  Add: {
    EMOJI: "🖍",
    TITLE: "Новий розділ",
    SUBMIT: "Додати розділ",
    Input: {
      Name: {
        LABEL: "Назва",
        PLACEHOLDER: "Введіть назву розділу",
      },
    },
    Notification: {
      SUCCESS: "Розділ успішно додано до уроку",
      ERROR: "Не вдалось додати розділ до уроку",
    },
  },
  Edit: {
    Chapter: {
      EMOJI: "📒",
      TITLE: "Редагувати розділ",
    },
    SUBMIT: "Змінити розділ",
    Input: {
      Name: {
        LABEL: "Назва",
        PLACEHOLDER: "Введіть назву розділу",
      },
    },
    Notification: {
      SUCCESS: "Розділ успішно змінено",
      ERROR: "Не вдалось змінити розділ до уроку",
    },
  },
  Delete: {
    Chapter: {
      EMOJI: "😥",
      TITLE: "Ви дійсно хочете видалити розділ уроку?",
    },
  },
  Preview: {
    Empty: {
      TEXT: "Наразі тут немає вправ",
    },
    Error: {
      INVALID_EXERCISE: "Такий тип вправи не підтримується",
    },
  },
  Chapter: {
    Success: {
      DELETED: "Розділ успішно видалено",
    },
    Error: {
      INVALID_LESSON_CHAPTER_ID: "Невірний ідентифікатор розділу уроку",
    },
  },
};
