import { useQuery } from "react-query";
import { fetchLanguage } from "api/services/teachers";
import { ETeachersQueryKeys } from "api/endpoints/teachers";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useLanguage = () => {
  return useQuery<string[], TApiError>(
    ETeachersQueryKeys.getLanguages,
    fetchLanguage,
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
