export const StudentClassroom = {
  Name: "Особистий клас",
  Emoji: "🎓",
  Empty: {
    LABEL: "Додайте підручник для студента",
    MESSAGE:
      "Студенту не додано підручник для роботи.\nНатисніть +, щоб додати",
  },
  Add: {
    TITLE: "Додати підручник студенту",
    EMOJI: "📚",
    SUBMIT: "Додати",
    Notification: {
      SUCCESS: "Підручник успішно доданий",
      ERROR: "Не вдалось додати підручник",
    },
    Empty: {
      MESSAGE:
        "Немає підручників... Додайте підручник, щоб дати студенту роботу",
    },
  },
  Table: {
    Action: {
      VIEW: "Переглянути",
      PIN: "Закріпити",
      UNPIN: "Відкріпити",
    },
  },
  Hero: {
    Action: {
      BACK: "Повернутися до класу",
      UNPIN: "Відкріпити курс",
    },
  },
  LessonPreview: {
    Action: {
      BACK: "Повернутися назад",
      PIN: "Закріпити урок",
      UNPIN: "Відкріпити урок",
    },
  },
};
