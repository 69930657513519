import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { removeCourse } from "api/services/courses";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useRemoveCourse = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(removeCourse, {
    onSuccess: () => {
      /**
       * We need to reset `allCourses` query instead of invalidation or refetch because after
       * remove course request we redirect user to the page with `allCourses` data and this data will be outdated.
       * Reason: the `allCourses` query might not be executed at this point and invalidation or refetch will not have affect
       * on our redirected user, so user will have an error on just deleted course file download.
       */
      queryClient.resetQueries(ECoursesQueryKeys.getAllCourses);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
