export enum EAuthQueryKeys {
  login = "login",
  logout = "logout",
  getCurrentUser = "getCurrentUser",
}

export const AUTH_ENDPOINTS = {
  LOGIN: "/login",
  LOGOUT: "/login/logout",
  CURRENT_USER: "/login/current",
};
