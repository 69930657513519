import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useStudentById } from "hooks/api/students";
import { useTeacherById } from "hooks/api/teachers";
import { useGroupById } from "hooks/api/groups";
import {
  useEditScheduleLesson,
  useRemoveScheduleLesson,
} from "hooks/api/schedule";
import { useModal } from "hooks/common/useModal";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { Button } from "components/Interactive/Button";
import { Checkbox } from "components/Interactive/Checkbox";
import { Select } from "components/Interactive/Select";
import { DatePicker } from "components/Interactive/DatePicker";
import { ActionModal } from "components/Common/ActionModal";
import { Input } from "components/Interactive/Input";
import { ReactPortal } from "components/Common/ReactPortal";
import { DetailedDurationSelect } from "../DetailedDurationSelect";
import { ScheduleContent } from "content";
import { LESSON_DURATION_OPTIONS, LESSON_TIME_OPTIONS } from "utils/options";
import { logError, logSuccess } from "utils/notifications";
import {
  ELessonType,
  TEditLessonBody,
  TLesson,
  TLessonDuration,
} from "types/api/lesson";
import { TSelectOption } from "types/app/select";
import {
  TEditEventAdminTeacherDetails,
  TEditScheduleEventAdminForm,
} from "./EditEventAdminForm.types";
import "./EditEventAdminForm.styles.scss";

type TEditEventAdminFormProps = {
  /**
   * Selected schedule event (lesson) which comes from `ScheduleDashboard`.
   */
  lesson?: TLesson;
  /**
   * Callback fired when `Submit` event ocurred.
   */
  onSubmit?: () => void;
  /**
   * Callback fired when `Delete` event ocurred.
   */
  onDelete?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const EditEventAdminForm: React.FC<TEditEventAdminFormProps> = (
  props
) => {
  const { lesson, onSubmit, onDelete, className } = props;

  const [innerStartDate, setInnerStartDate] = useState<Date>(new Date());
  const [innerEndDate, setInnerEndDate] = useState<Date>(new Date());
  const idRef = useRef<string>(nanoid());

  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const { data: studentData } = useStudentById(lesson?.studentId ?? undefined);
  const { data: groupData } = useGroupById(lesson?.groupId ?? undefined);
  const { data: teacherData } = useTeacherById(lesson?.teacherId);
  const { mutate: editScheduleLesson } = useEditScheduleLesson();
  const { mutate: deleteScheduleLesson } = useRemoveScheduleLesson();

  // Constructing options for selects
  const teacherDetails: TEditEventAdminTeacherDetails = teacherData && {
    id: teacherData.id,
    name: `${teacherData.person.firstName} ${teacherData.person.lastName}`,
    description: teacherData.person.email,
  };

  // Making default option values
  const initialDurationOption = LESSON_DURATION_OPTIONS.find(
    (option) => option.value === lesson?.duration
  );
  const [firstTimeOption] = LESSON_TIME_OPTIONS;
  const timeOption = LESSON_TIME_OPTIONS.find(
    (time) => time.value === format(innerStartDate, "HH:mm")
  );
  const defaultValues: Partial<TEditScheduleEventAdminForm> = useMemo(
    () => ({
      group: groupData?.name,
      student:
        studentData &&
        `${studentData.person.firstName} ${studentData.person.lastName}`,
      duration: initialDurationOption,
      time: timeOption || firstTimeOption,
      repeated: true,
    }),
    [
      groupData?.name,
      studentData,
      timeOption,
      initialDurationOption,
      firstTimeOption,
    ]
  );

  // Initiating form handler
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TEditScheduleEventAdminForm>({
    mode: "onSubmit",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // Handling date update
  useEffect(() => {
    if (lesson) {
      const startDate = new Date(lesson.startDateTime);
      const endDate = new Date(lesson.endDateTime);
      setInnerStartDate(startDate);
      setInnerEndDate(endDate);
    }
  }, [lesson]);

  // Form handlers
  const editScheduleEventSubmitHandler = async (
    formData: TEditScheduleEventAdminForm
  ) => {
    if (!lesson) {
      logError({ message: ScheduleContent.Edit.Notification.ERROR });
      return;
    }

    const lessonDate = format(innerStartDate, "MM/dd/yyyy");
    const lessonTime = `${formData.time.value}:00`;
    const lessonEvent: TEditLessonBody = {
      lesson: {
        id: lesson.id,
        teacherId: lesson.teacherId,
        type: lesson.type,
        duration: formData.duration.value,
        studentId: lesson.studentId,
        groupId: lesson.groupId,
        startDateTime: `${lessonDate} ${lessonTime}`,
      },
      repeated: formData.repeated,
    };
    editScheduleLesson(lessonEvent, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(ScheduleContent.Edit.Notification.SUCCESS);
        reset();
      },
    });
  };

  const dateChangeHandler = (rangeStart: Date, rangeEnd: Date) => {
    setInnerStartDate(rangeStart);
    setInnerEndDate(rangeEnd);
  };

  const deleteButtonClickHandler = () => {
    openDeleteModal();
  };

  const handleDeleteSubmit = () => {
    if (!lesson) {
      logError({ message: ScheduleContent.Delete.Notification.ERROR });
      return;
    }

    deleteScheduleLesson(lesson.id, {
      onSuccess: () => {
        onDelete?.();
        logSuccess(ScheduleContent.Delete.Notification.SUCCESS);
        closeDeleteModal();
      },
    });
  };

  return (
    <>
      <form
        id={idRef.current}
        className={cx(["nb-page-component-admin-edit-event-form", className])}
        onSubmit={handleSubmit(editScheduleEventSubmitHandler)}
      >
        <DetailedDurationSelect<
          TEditScheduleEventAdminForm,
          TSelectOption<TLessonDuration>
        >
          name="duration"
          id="admin-edit-event-duration"
          placeholder={ScheduleContent.Edit.Input.Duration.PLACEHOLDER}
          details={teacherDetails}
          options={LESSON_DURATION_OPTIONS}
          control={control}
          errors={errors}
        />
        {lesson?.type === ELessonType.SINGLE && (
          <Input<TEditScheduleEventAdminForm>
            name="student"
            id="admin-edit-event-teacher"
            className="nb-page-component-admin-edit-event-student"
            label={ScheduleContent.Edit.Input.Student.LABEL}
            placeholder={ScheduleContent.Edit.Input.Student.PLACEHOLDER}
            register={register}
            readOnly
            required
            fullWidth
          />
        )}
        {lesson?.type === ELessonType.GROUP && (
          <Input<TEditScheduleEventAdminForm>
            name="group"
            id="admin-edit-event-group"
            className="nb-page-component-admin-edit-event-group"
            label={ScheduleContent.Edit.Input.Group.LABEL}
            placeholder={ScheduleContent.Edit.Input.Group.PLACEHOLDER}
            register={register}
            readOnly
            required
            fullWidth
          />
        )}
        <div className="nb-page-component-admin-edit-event-date-wrapper">
          <DatePicker
            variant="input"
            className="nb-page-component-admin-edit-event-date-picker"
            onChange={dateChangeHandler}
            label={ScheduleContent.Edit.Input.Date.LABEL}
            value={[innerStartDate, innerEndDate]}
            mode="day"
            view="month"
            position="bottom-right"
          />
          <Select<TEditScheduleEventAdminForm, TSelectOption>
            name="time"
            id="admin-edit-event-time"
            className="nb-page-component-admin-edit-event-time"
            icon={<TimeIcon width={15} height={15} />}
            placeholder={ScheduleContent.Edit.Input.Time.PLACEHOLDER}
            options={LESSON_TIME_OPTIONS}
            control={control}
            errors={errors}
            required
            fullWidth
          />
        </div>
        <Checkbox<TEditScheduleEventAdminForm>
          name="repeated"
          id="admin-edit-event-repeat"
          className="nb-page-component-admin-edit-event-repeat"
          control={control}
          label={ScheduleContent.Edit.Input.Repeat.LABEL}
          errors={errors}
          fullWidth
        />
        <ReactPortal wrapperId="edit-schedule-event-modal-footer">
          <Button
            className="nb-page-component-admin-edit-event-delete"
            variant="secondary"
            onClick={deleteButtonClickHandler}
            fullWidth
          >
            {ScheduleContent.Edit.Button.DELETE}
          </Button>
          <Button
            className="nb-page-component-admin-edit-event-button"
            variant="primary"
            type="submit"
            form={idRef.current}
            fullWidth
          >
            {ScheduleContent.Edit.Button.SUBMIT}
          </Button>
        </ReactPortal>
      </form>
      <ActionModal
        text={ScheduleContent.Delete.TITLE}
        emoji={ScheduleContent.Delete.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteSubmit}
      />
    </>
  );
};
