import { cloneElement, forwardRef, useEffect, useRef } from "react";
import { Link, To } from "react-router-dom";
import cx from "classnames";
import { mergeRefs } from "utils/react";
import type { TButtonVariant } from "./Button.types";
import "./Button.styles.scss";

export type ButtonProps = {
  /**
   * The variant to use.
   *
   * @default 'primary'
   */
  variant?: TButtonVariant;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the button will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the `input` element is focused during the first mount.
   *
   * @default false
   */
  autoFocus?: boolean;
  /**
   * HTML button type.
   *
   * @default 'button'
   */
  type?: "button" | "submit" | "reset";
  /**
   * The form attribute specifies the form the button belongs to.
   * The value of this attribute must be equal to the id attribute of a <form> element in the same document.
   */
  form?: string;
  /**
   * Describes a location that is the destination of some navigation, either via history.push or history.replace. May be either a URL or the pieces of a URL path.
   *
   * @required if `variant` prop is equal to `link`
   */
  to?: To;
  /**
   * Element placed left of the text node.
   */
  iconLeft?: React.ReactElement;
  /**
   * Element placed right of the text node.
   */
  iconRight?: React.ReactElement;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * Button mouse click handler function
   *
   * @param {object} event - mouse event
   * @return {void} should be voided function
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  props,
  ref
) => {
  const {
    variant = "primary",
    type = "button",
    disabled = false,
    fullWidth = false,
    autoFocus = false,
    form,
    children,
    className,
    iconLeft,
    iconRight,
    to,
    onClick,
  } = props;

  const innerRef = useRef<HTMLButtonElement>(null);

  if (variant === "link" && !to) {
    throw new Error("Button component required `to` prop for variant `link`");
  }

  useEffect(() => {
    // Adding timeout for autofocus if Button component was hidden in portal or parent element has transition so we need to delay it
    const autofocusTimeout = setTimeout(() => {
      if (
        document.activeElement !== innerRef.current &&
        autoFocus &&
        innerRef.current
      ) {
        innerRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(autofocusTimeout);
  }, [autoFocus]);

  const handleClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (disabled) {
      evt.preventDefault();
      return;
    }
    onClick?.(evt);
  };

  if (variant === "link" && to) {
    return (
      <Link
        to={to}
        tabIndex={disabled ? -1 : 0}
        className={cx([
          "nb-interactive-button",
          "nb-interactive-button-link",
          {
            "nb-interactive-button--full": fullWidth,
            "nb-interactive-button-link--disabled": disabled,
          },
          className,
        ])}
      >
        {iconLeft &&
          cloneElement(iconLeft, {
            className: cx(
              "nb-interactive-button-icon",
              "nb-interactive-button-icon--left"
            ),
          })}
        {children}
        {iconRight &&
          cloneElement(iconRight, {
            className: cx(
              "nb-interactive-button-icon",
              "nb-interactive-button-icon--right"
            ),
          })}
      </Link>
    );
  }

  return (
    <button
      ref={mergeRefs([ref, innerRef])}
      type={type}
      form={form}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      autoFocus={autoFocus}
      className={cx([
        "nb-interactive-button",
        {
          "nb-interactive-button-secondary": variant === "secondary",
          "nb-interactive-button-tertiary": variant === "tertiary",
          "nb-interactive-button-text": variant === "text",
          "nb-interactive-button-ghost": variant === "ghost",
          "nb-interactive-button--full": fullWidth,
        },
        className,
      ])}
      onClick={handleClick}
    >
      {iconLeft &&
        cloneElement(iconLeft, {
          className: cx(
            "nb-interactive-button-icon",
            "nb-interactive-button-icon--left",
            {
              "nb-interactive-button-icon--secondary": variant === "secondary",
              "nb-interactive-button-icon--tertiary": variant === "tertiary",
              "nb-interactive-button-icon--text": variant === "text",
            }
          ),
        })}
      {children}
      {iconRight &&
        cloneElement(iconRight, {
          className: cx(
            "nb-interactive-button-icon",
            "nb-interactive-button-icon--right",
            {
              "nb-interactive-button-icon--secondary": variant === "secondary",
              "nb-interactive-button-icon--tertiary": variant === "tertiary",
              "nb-interactive-button-icon--text": variant === "text",
            }
          ),
        })}
    </button>
  );
};

/**
 * Button interactive element based on component variant
 */
export const ForwardedButton = forwardRef(Button);
