export const DragAndDrop = {
  Default: {
    Draggable: {
      LABEL: "Елемент, який можна перетягнути",
    },
    Droppable: {
      LABEL: "Місце в яке можна перенести відповідь",
    },
    StartZone: {
      EMPTY: "Ви перетягнули всі слова...",
    },
  },
} as const;
