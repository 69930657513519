import "./CourseHeroImage.styles.scss";

type CourseHeroImageProps = {
  /**
   * The image ID to fetch it from server and display.
   * @default null
   */
  imageUrl?: string | null;
};

export const CourseHeroImage: React.FC<CourseHeroImageProps> = (props) => {
  const { imageUrl = null } = props;

  return (
    <div className="nb-sub-materials-details-image">
      <div className="nb-sub-materials-details-poster">
        {imageUrl ? (
          <img
            src={imageUrl}
            width="171"
            height="219"
            alt="Обкладинка курсу завантажена користувачем"
            className="nb-sub-materials-details-picture"
          />
        ) : (
          <span className="nb-sub-materials-details-emoji">🚀</span>
        )}
      </div>
    </div>
  );
};
