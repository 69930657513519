import { useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CompareImageWithDescriptionExerciseContent as ExerciseContent } from "content";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import {
  Draggable,
  DraggableOverlay,
  DraggablePlaceholder,
  DraggableStartZone,
  Droppable,
} from "components/DragAndDrop";
import { ImagesList } from "../ImagesList";
import { ImageItem } from "../ImageItem";
import {
  START_ZONE_ID,
  addAnswerToImageBlank,
  addAnswerToStartZone,
  removeAnswerFromImageBlank,
  removeAnswerFromStartZone,
  replaceAnswerInStartZone,
  replaceAnswersInImageBlanks,
  switchAnswerInImageBlanks,
} from "./DndController.helpers";
import {
  TCompareImageWithDescriptionExercise,
  TCompareImageWithDescriptionExerciseParsedAnswer,
  TCompareImageWithDescriptionExerciseParsedImage,
  TExerciseIdentifier,
} from "types/app/exercises";
import "./DndController.styles.scss";

type TDndControllerProps = {
  /**
   * An exercise raw data from API response.
   */
  exercise: TCompareImageWithDescriptionExercise;
  /**
   * Fires after a draggable answer was dropped into a blank.
   * @param answer data of the answer that was drag and dropped.
   * @param details detailed information about answer start and destination points.
   */
  onDragComplete?: (
    answer: TCompareImageWithDescriptionExerciseParsedAnswer,
    details: {
      source: {
        type: "start-zone" | "image";
        image: TCompareImageWithDescriptionExerciseParsedImage | null;
      };
      destination: {
        type: "start-zone" | "image";
        image: TCompareImageWithDescriptionExerciseParsedImage | null;
      };
    }
  ) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DndController: React.FC<TDndControllerProps> = (props) => {
  const { exercise, onDragComplete, className } = props;

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragActiveId, setDragActiveId] = useState<TExerciseIdentifier | null>(
    null
  );
  const [answers, setAnswers] = useState<
    TCompareImageWithDescriptionExerciseParsedAnswer[]
  >(exercise.payload.data.shuffledAnswers);
  const [images, setImages] = useState<
    TCompareImageWithDescriptionExerciseParsedImage[]
  >(exercise.payload.data.images);

  const { isExerciseReadOnlyMode } = useLessonSettings();

  useEffect(() => {
    setAnswers(exercise.payload.data.shuffledAnswers);
    setImages(exercise.payload.data.images);
  }, [exercise]);

  const isAllowToShowResult: boolean = useMemo(
    () => images.every((image) => image.currentAnswer !== null),
    [images]
  );

  const draggableAnswer: TCompareImageWithDescriptionExerciseParsedAnswer | null =
    useMemo(() => {
      if (!dragActiveId) {
        return null;
      }
      const answer = answers.find(({ id }) => id === dragActiveId);
      return answer || null;
    }, [dragActiveId, answers]);

  const answerDragStartHandler = (event: DragStartEvent) => {
    const { active } = event;

    setDragActiveId(active.id);
    setIsDragging(true);
  };

  const answerDragEndHandler = (event: DragEndEvent) => {
    const { over, active } = event;

    if (over && active.id !== over.id) {
      const targetAnswer: TCompareImageWithDescriptionExerciseParsedAnswer | null =
        exercise.payload.data.answers.find(({ id }) => id === active.id) ||
        null;
      const targetImage = over.data.current
        ?.data as TCompareImageWithDescriptionExerciseParsedImage;
      const previousImage: TCompareImageWithDescriptionExerciseParsedImage | null =
        images.find((image) => image.currentAnswer?.id === targetAnswer?.id) ||
        null;
      const previousAnswer: TCompareImageWithDescriptionExerciseParsedAnswer | null =
        targetImage?.currentAnswer || null;

      const isAnswerFromStartZone: boolean = answers.some(
        ({ id, isSelected }) => id === targetAnswer?.id && !isSelected
      );
      const isBlankStartZone: boolean = over.id === START_ZONE_ID;

      // CASE 1: FROM START ZONE TO EMPTY DROPPABLE
      if (isAnswerFromStartZone && targetAnswer && targetImage?.isEmpty) {
        setAnswers((prevAnswers) =>
          removeAnswerFromStartZone(prevAnswers, targetAnswer.id)
        );
        setImages((prevImages) =>
          addAnswerToImageBlank(prevImages, targetImage.id, targetAnswer)
        );
      }

      // CASE 2: FROM START ZONE TO OCCUPIED DROPPABLE
      if (
        isAnswerFromStartZone &&
        targetAnswer &&
        targetImage &&
        previousAnswer
      ) {
        setAnswers((prevAnswers) =>
          replaceAnswerInStartZone(prevAnswers, targetAnswer, previousAnswer)
        );
        setImages((prevImages) =>
          addAnswerToImageBlank(prevImages, targetImage.id, targetAnswer)
        );
      }

      // CASE 3: FROM DROPPABLE TO OTHER EMPTY DROPPABLE
      if (
        !isAnswerFromStartZone &&
        targetAnswer &&
        targetImage?.isEmpty &&
        previousImage
      ) {
        setImages((prevImages) =>
          switchAnswerInImageBlanks(
            prevImages,
            targetAnswer,
            targetImage.id,
            previousImage.id
          )
        );
      }

      // CASE 4: FROM DROPPABLE TO OTHER OCCUPIED DROPPABLE
      if (!isAnswerFromStartZone && previousAnswer && previousImage) {
        setImages((prevImages) =>
          replaceAnswersInImageBlanks(prevImages, targetImage, previousImage)
        );
      }

      // CASE 5: FROM DROPPABLE TO START ZONE
      if (
        !isAnswerFromStartZone &&
        targetAnswer &&
        isBlankStartZone &&
        previousImage
      ) {
        setAnswers((prevAnswers) =>
          addAnswerToStartZone(prevAnswers, targetAnswer)
        );
        setImages((prevImages) =>
          removeAnswerFromImageBlank(prevImages, previousImage.id)
        );
      }

      if (targetAnswer) {
        onDragComplete?.(targetAnswer, {
          source: {
            type: isAnswerFromStartZone ? "start-zone" : "image",
            image: isAnswerFromStartZone ? null : previousImage,
          },
          destination: {
            type: isBlankStartZone ? "start-zone" : "image",
            image: isBlankStartZone ? null : targetImage,
          },
        });
      }
    }

    setDragActiveId(null);
    setIsDragging(false);
  };

  const answerDragCancelHandler = () => {
    setDragActiveId(null);
    setIsDragging(false);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
      onDragStart={answerDragStartHandler}
      onDragEnd={answerDragEndHandler}
      onDragCancel={answerDragCancelHandler}
    >
      <Space
        direction="vertical"
        size="medium"
        justify="start"
        fullWidth
        className={className}
      >
        <DraggableStartZone
          droppableId={START_ZONE_ID}
          empty={answers.length === 0}
        >
          {answers.map((answer) =>
            answer.id === dragActiveId || answer.isSelected ? (
              <DraggablePlaceholder key={answer.id} content={answer.content} />
            ) : (
              <Draggable
                key={answer.id}
                draggableId={answer.id}
                data={answer}
                content={answer.content}
                ariaLabel={ExerciseContent.DragAndDrop.DRAGGABLE_LABEL}
                disabled={isAllowToShowResult || isExerciseReadOnlyMode}
              />
            )
          )}
        </DraggableStartZone>
        <ImagesList>
          {images.map((image) => (
            <ImageItem key={image.id} fileUrl={image.fileUrl}>
              <Droppable
                droppableId={image.id}
                data={image}
                hint={image.hint}
                dragging={isDragging}
                failed={image.isAllAttemptsFailed}
              >
                {image.currentAnswer !== null &&
                  image.currentAnswer.id !== dragActiveId && (
                    <Draggable
                      draggableId={image.currentAnswer.id}
                      content={image.currentAnswer.content}
                      data={image.currentAnswer}
                      correct={image.isCorrectAnswer}
                      showResult={isAllowToShowResult}
                    />
                  )}
              </Droppable>
            </ImageItem>
          ))}
        </ImagesList>
      </Space>
      <DraggableOverlay>
        {draggableAnswer && (
          <Draggable
            draggableId={draggableAnswer.id}
            content={draggableAnswer.content}
            dragging
            dragOverlay
          />
        )}
      </DraggableOverlay>
    </DndContext>
  );
};
