import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { addCourse } from "api/services/courses";
import { logError } from "utils/notifications";
import { TAddCourseBody, TCourse } from "types/api/course";
import { TApiError } from "types/api/error";

export const useAddCourse = () => {
  const queryClient = useQueryClient();
  return useMutation<TCourse, TApiError, TAddCourseBody>(addCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries(ECoursesQueryKeys.getAllCourses);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
