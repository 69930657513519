import { cloneElement } from "react";
import { Space } from "components/Common/Space";
import { Paragraph } from "components/Typography/Paragraph";
import "./SelectModeOption.styles.scss";

type TSelectModeOptionProps = {
  /**
   * Icon of the option.
   */
  icon: React.ReactElement;
  /**
   * Title content for the option.
   */
  title: string;
  /**
   * Description text for the option.
   */
  description: string;
};

export const SelectModeOption: React.FC<TSelectModeOptionProps> = (props) => {
  const { icon, title, description } = props;

  return (
    <Space
      direction="horizontal"
      justify="start"
      align="start"
      size="x-small"
      className="nb-common-select-custom-option"
    >
      {cloneElement(icon, {
        className: "nb-common-select-custom-option-icon",
      })}
      <Space direction="vertical" justify="start" align="start" size="xx-small">
        <Paragraph>{title}</Paragraph>
        <Paragraph
          variant={2}
          className="nb-common-select-custom-option-description"
        >
          {description}
        </Paragraph>
      </Space>
    </Space>
  );
};
