import { useCallback, useEffect, useState } from "react";
import ReactSelect, {
  ControlProps,
  NoticeProps,
  SingleValue,
} from "react-select";
import cx from "classnames";
import { SelectContent } from "content";
import { ReactComponent as More } from "assets/icons/more.svg";
import { Space } from "components/Common/Space";
import { Tooltip } from "components/Interactive/Tooltip";
import { ClueSelectDropdownIndicator } from "./components/DropdownIndicator";
import { ClueSelectControl } from "./components/Control";
import { ClueSelectMenu } from "./components/Menu";
import { ClueSelectMenuList } from "./components/MenuList";
import { ClueSelectOption } from "./components/Option";
import { ClueSelectSingleValue } from "./components/SingleValue";
import { ClueSelectPlaceholder } from "./components/Placeholder";
import { ClueSelectNoOptionsMessage } from "./components/NoOptionsMessage";
import { ClueSelectInput } from "./components/Input";
import { ClueSelectValueContainer } from "./components/ValueContainer";
import type { TClueSelectDefaultOption } from "./ClueSelect.types";
import "./ClueSelect.styles.scss";

type TClueSelectProps<
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
> = {
  /**
   * Name attribute of the `Select` element.
   */
  name?: string | number;
  /**
   * Array of options that populate the select menu
   */
  options?: Option[];
  /**
   * Is the select disabled
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the `Select` element is required.
   *
   * @default false
   */
  required?: boolean;
  /**
   * If `true`, the `select` will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Message to be displayed in the menu if there are no options available.
   */
  noOptionsText?: string;
  /**
   * Select label text for the `select` element.
   */
  label?: string;
  /**
   * The default value of the `Select` element.
   * @default null
   */
  defaultValue?: SingleValue<Option>;
  /**
   * The value of the `Select` element, required for a controlled component.
   * @default null
   */
  value?: SingleValue<Option>;
  /**
   * The id of the `select` element.
   * Provide if label is used.
   */
  id: string | number;
  /**
   * Indicates if selected option is correct.
   * Used to display correct answer with predefined styles.
   * @default false
   */
  correct?: boolean;
  /**
   * Indicates if the result should be shown.
   * This prop is used to display correctness or incorrectness of the answer.
   * @default false
   */
  showResult?: boolean;
  /**
   * Hint text to be displayed in the tooltip.
   * @default null
   */
  hint?: string | null;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Callback fired when a new option is selected.
   * @param {Option} value The selected option.
   * @param {string | number} id The id of the `select` element.
   */
  onChange?: (value: Option, id: string | number) => void;
};

/**
 * A `ClueSelect` component that is used to select a single option from a dropdown menu for an `ChooseRightWord` exercise.
 * It uses `react-select` under the hood.
 * @see https://react-select.com/home
 */
export const ClueSelect = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>(
  props: TClueSelectProps<Option>
): JSX.Element => {
  const {
    disabled = false,
    required = false,
    fullWidth = false,
    correct = false,
    showResult = false,
    defaultValue = null,
    value = null,
    id,
    label,
    options,
    name,
    noOptionsText = SelectContent.DEFAULT.EMPTY,
    hint = null,
    className,
    onChange,
  } = props;

  const [innerValue, setInnerValue] = useState<SingleValue<Option>>(
    defaultValue as SingleValue<Option>
  );

  useEffect(() => {
    if (value?.value !== innerValue?.value) {
      setInnerValue(value as SingleValue<Option>);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectChangeHandler = (selectedOption: SingleValue<Option>) => {
    if (innerValue?.value === selectedOption?.value) {
      return;
    }
    setInnerValue(selectedOption);
    onChange?.(selectedOption as Option, id);
  };

  const ClueSelectControlMemorized = useCallback(
    (controlProps: ControlProps<Option, false>) => (
      <ClueSelectControl
        {...controlProps}
        correct={correct}
        showResult={showResult}
      />
    ),
    [correct, showResult]
  );

  const ClueSelectNoOptionsMemorized = useCallback(
    (noOptionsProps: NoticeProps<Option, false>) => (
      <ClueSelectNoOptionsMessage
        {...noOptionsProps}
        noOptionsText={noOptionsText}
      />
    ),
    [noOptionsText]
  );

  return (
    <div
      className={cx(["nb-interactive-clue-select-wrapper", className])}
      aria-live="polite"
    >
      {label && (
        <label
          className="nb-interactive-clue-select-label"
          htmlFor={id.toString()}
        >
          {label}
        </label>
      )}
      <Space
        direction="horizontal"
        justify="start"
        align="center"
        size="xx-small"
      >
        <ReactSelect
          name={name?.toString()}
          id={id.toString()}
          value={innerValue}
          onChange={selectChangeHandler}
          defaultValue={null}
          placeholder={null}
          options={options}
          required={required}
          isDisabled={disabled}
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          className={cx([
            "nb-interactive-clue-select",
            {
              "nb-interactive-clue-select--full": fullWidth,
            },
          ])}
          components={{
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            Control: ClueSelectControlMemorized,
            DropdownIndicator: ClueSelectDropdownIndicator,
            Menu: ClueSelectMenu,
            MenuList: ClueSelectMenuList,
            Option: ClueSelectOption,
            SingleValue: ClueSelectSingleValue,
            Placeholder: ClueSelectPlaceholder,
            NoOptionsMessage: ClueSelectNoOptionsMemorized,
            Input: ClueSelectInput,
            ValueContainer: ClueSelectValueContainer,
          }}
        />
        {/** TODO: Indicator of correct answer when API will be ready. Icon will be rendered as a placeholder for now */}
        <Tooltip
          text={hint}
          disabled={false} // TODO: When permissions will be implemented this should be always disabled for student
          trigger="click"
          position="top"
          spaceSize="small"
          arrow="visible"
          containerClassName="nb-interactive-clue-select-tooltip-container"
          className="nb-interactive-clue-select-tooltip"
        >
          <More />
        </Tooltip>
      </Space>
    </div>
  );
};
