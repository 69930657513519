import { useQuery } from "react-query";
import { fetchGroupMembers } from "api/services/groups";
import { EGroupsQueryKeys } from "api/endpoints/groups";
import { logError } from "utils/notifications";
import { TStudent } from "types/api/student";
import { TApiError } from "types/api/error";

export const useGroupMembers = (id?: number) => {
  return useQuery<TStudent[], TApiError>(
    [EGroupsQueryKeys.getGroupMembers, id],
    () => fetchGroupMembers(id),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: id !== undefined,
    }
  );
};
