import { forwardRef, useEffect } from "react";
import cx from "classnames";
import { useBodyScrollBlock, useKeyPress } from "hooks/common";
import { ModalContent } from "content";
import { ReactPortal } from "components/Common/ReactPortal";
import { IconButton } from "components/Interactive/IconButton";
import { ReactComponent as Close } from "assets/icons/close.svg";
import "./ContentModal.styles.scss";

type ContentModalProps = {
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * The title of the modal component.
   */
  title?: string;
  /**
   * The emoji before modal text content.
   */
  emoji?: string;
  /**
   * The content of the modal.
   */
  children?: React.ReactNode;
  /**
   * Override react portal container id.
   */
  portalId?: string;
  /**
   * The id of the `ContentModal` component footer.
   * If present, the footer will be rendered and will expect to have `ReactPortal` component with the same id.
   * @default null
   */
  footerId?: string | null;
  /**
   * Override or extend the styles applied to the component wrapper.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component content wrapper.
   */
  contentClassName?: string;
  /**
   * Override or extend the styles applied to the component modal wrapper.
   */
  modalClassName?: string;
  /**
   * Override or extend the styles applied to the component footer.
   */
  footerClassName?: string;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: () => void;
};

const ContentModal: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ContentModalProps
> = (props, ref) => {
  const {
    open = false,
    title = ModalContent.Content.DEFAULT.TITLE,
    emoji = ModalContent.Content.DEFAULT.EMOJI,
    portalId = "content-modal",
    footerId = null,
    children,
    className,
    contentClassName,
    modalClassName,
    footerClassName,
    onClose,
  } = props;

  useKeyPress("Escape", onClose);
  const { allowScroll, blockScroll } = useBodyScrollBlock();

  useEffect(() => {
    if (open) {
      blockScroll();
    }
    if (!open) {
      allowScroll();
    }

    return () => allowScroll();
  }, [open, allowScroll, blockScroll]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const modalElement = document.querySelector(
      `#${portalId} .nb-common-content-modal`
    ) as HTMLDivElement;
    if (!modalElement) {
      return;
    }

    // Setting header offset
    const headerElement = document.querySelector(
      `#${portalId} .nb-common-content-modal-header`
    );
    if (headerElement) {
      const headerStyles = window.getComputedStyle(headerElement);
      const headerOffset = parseInt(headerStyles.height);
      modalElement.style.setProperty(
        "--nb-modal-default-header-offset",
        `${headerOffset}px`
      );
    }

    // Setting footer offset
    const footerElement = document.querySelector(
      `#${portalId} .nb-common-content-modal-footer`
    );
    if (footerElement) {
      const footerStyles = window.getComputedStyle(footerElement);
      const footerOffset = parseInt(footerStyles.height);
      modalElement.style.setProperty(
        "--nb-modal-default-footer-offset",
        `${footerOffset}px`
      );
    }
  }, [portalId, footerId, open, title]);

  return (
    <ReactPortal wrapperId={portalId}>
      <div
        aria-hidden
        tabIndex={-1}
        className={cx([
          "nb-common-content-modal",
          { "nb-common-content-modal--open": open },
          modalClassName,
        ])}
        onClick={onClose}
      >
        <div
          ref={ref}
          role="dialog"
          aria-modal
          className={cx([
            "nb-common-content-modal-content",
            { "nb-common-content-modal-content--open": open },
            className,
          ])}
          onClick={(evt) => evt.stopPropagation()}
        >
          <div className="nb-common-content-modal-header">
            <div className="nb-common-content-modal-title-wrapper">
              {emoji && (
                <span className="nb-common-content-modal-emoji">{emoji}</span>
              )}
              <h2 className="nb-common-content-modal-title">{title}</h2>
            </div>
            <IconButton
              variant="secondary"
              icon={<Close />}
              onClick={onClose}
            />
          </div>
          <div
            className={cx(["nb-common-content-modal-main", contentClassName])}
          >
            {children}
          </div>
          {footerId && (
            <div
              id={footerId}
              className={cx([
                "nb-common-content-modal-footer",
                footerClassName,
              ])}
            />
          )}
        </div>
      </div>
    </ReactPortal>
  );
};

/**
 * Content modal is common component with a backdrop that accepts variable content as children components.
 */
export const ForwardedContentModal = forwardRef(ContentModal);
