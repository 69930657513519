import { useMutation } from "react-query";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { EAuthQueryKeys } from "api/endpoints/auth";
import { logoutUser } from "api/services/auth";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useLogout = () => {
  const { removeActiveUser } = useActiveUser();

  return useMutation<void, TApiError, null>(EAuthQueryKeys.logout, logoutUser, {
    onSuccess: () => {
      removeActiveUser();
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
