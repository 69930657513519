import cx from "classnames";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { ExerciseConstructorContent } from "content";
import {
  AddToDictionaryExerciseForm,
  ButtonExerciseForm,
  ChooseRightWordFormExerciseForm,
  CompareImageWithDescriptionExerciseForm,
  DescribeAudioExerciseForm,
  DescribeGifExerciseForm,
  DescribeImageExerciseForm,
  DescribeVideoExerciseForm,
  EssayExerciseForm,
  InputWordsIntoGapsExerciseForm,
  MakeWordFromLettersExerciseForm,
  MoveWordsIntoGapsExerciseForm,
  NotesExerciseForm,
  RecordAudioExerciseForm,
  SentenceOrderExerciseForm,
  SortWordsExerciseForm,
  TestExerciseForm,
  TrueOrFalseExerciseForm,
  WordsComparingExerciseForm,
} from "../Exercises";
import { logError } from "utils/notifications";
import { EExerciseType } from "types/app/exercises";
import "./ConstructorSecondary.styles.scss";

export const ConstructorSecondary: React.FC = () => {
  const { isSecondaryDrawerOpen, currentExerciseType } =
    useExerciseConstructor();

  const renderExerciseConfigForm = (
    type: EExerciseType
  ): JSX.Element | null => {
    switch (type) {
      case EExerciseType.ADD_TO_DICTIONARY:
        return <AddToDictionaryExerciseForm />;

      case EExerciseType.BUTTON:
        return <ButtonExerciseForm />;

      case EExerciseType.TEST:
        return <TestExerciseForm />;

      case EExerciseType.CHOOSE_RIGHT_WORD_FORM:
        return <ChooseRightWordFormExerciseForm />;

      case EExerciseType.WORDS_COMPARING:
        return <WordsComparingExerciseForm />;

      case EExerciseType.MAKE_WORD_FROM_LETTERS:
        return <MakeWordFromLettersExerciseForm />;

      case EExerciseType.COMPARE_IMAGE_WITH_DESCRIPTION:
        return <CompareImageWithDescriptionExerciseForm />;

      case EExerciseType.DESCRIBE_AUDIO:
        return <DescribeAudioExerciseForm />;

      case EExerciseType.GIF:
        return <DescribeGifExerciseForm />;

      case EExerciseType.IMAGE:
        return <DescribeImageExerciseForm />;

      case EExerciseType.VIDEO:
        return <DescribeVideoExerciseForm />;

      case EExerciseType.MOVE_WORDS_INTO_GAPS:
        return <MoveWordsIntoGapsExerciseForm />;

      case EExerciseType.TEACHER_NOTES:
        return <NotesExerciseForm />;

      case EExerciseType.RECORD_AUDIO:
        return <RecordAudioExerciseForm />;

      case EExerciseType.SENTENCE_ORDER:
        return <SentenceOrderExerciseForm />;

      case EExerciseType.SORT_WORDS:
        return <SortWordsExerciseForm />;

      case EExerciseType.TRUE_OR_FALSE:
        return <TrueOrFalseExerciseForm />;

      case EExerciseType.INPUT_WORDS_INTO_GAPS:
        return <InputWordsIntoGapsExerciseForm />;

      case EExerciseType.ESSAY:
        return <EssayExerciseForm />;

      default:
        logError({
          message:
            ExerciseConstructorContent.Notification.Internal
              .INVALID_EXERCISE_ERROR,
        });
        return null;
    }
  };

  return (
    <aside
      className={cx([
        "nb-exercise-constructor-secondary",
        { "nb-exercise-constructor-secondary--open": isSecondaryDrawerOpen },
      ])}
    >
      {renderExerciseConfigForm(currentExerciseType)}
    </aside>
  );
};
