import cx from "classnames";
import "./BrandLogo.styles.scss";

type BrandLogoProps = {
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const BrandLogo: React.FC<BrandLogoProps> = ({ className }) => {
  return (
    <strong className={cx(["nb-common-brand-logo", className])}>
      not botan
    </strong>
  );
};
