import { useQuery } from "react-query";
import { getLandingOffers } from "api/services/admin";
import { EAdminQueryKeys } from "api/endpoints/admin";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TAdminLandingOffer } from "types/api/admin";

export const useLandingOffers = () => {
  return useQuery<TAdminLandingOffer[], TApiError>(
    [EAdminQueryKeys.getLandingOffers],
    getLandingOffers,
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
