export const Requests = {
  Name: "Заявки",
  Emoji: "📬",
  Search: {
    PLACEHOLDER: "Пошук по заявкам",
    NO_OPTIONS: "Заявок не знайдено",
  },
  Empty: "На даний момент список заявок порожній 📭",
  Table: {
    Header: {
      NUMBER: "№",
      NAME: "Ім'я",
      PHONE: "Телефон",
      STUDY_METHOD: "Метод навчання",
      PERIOD: "Період",
      STATUS: "Статус",
      ACTION: "Дії",
      CREATED_AT: "Дата",
    },
    Button: {
      EDIT: "Змінити",
    },
  },
  Fallback: {
    STUDY_METHOD: "Не вказано",
    PERIOD: "Не вказано",
  },
  Add: {
    TITLE: "Додати заявку",
    EMOJI: "📬",
    SUBMIT: "Додати",
    Notification: {
      SUCCESS: "Заявка успішно додана",
      ERROR: "Не вдалось додати заявку",
    },
    Input: {
      Name: {
        LABEL: "Ім'я",
        PLACEHOLDER: "Тарас Шевченко",
      },
      Phone: {
        LABEL: "Телефон",
        PLACEHOLDER: "+380 (099) 111-22-33",
      },
      StudyMethod: {
        LABEL: "Метод навчання",
        PLACEHOLDER: "Індивідуально",
      },
      Period: {
        LABEL: "Період навчання",
        PLACEHOLDER: "4 місяці",
      },
    },
  },
  Delete: {
    TITLE: "Ви дійсно хочете видалити заявку?",
    EMOJI: "😥",
    Notification: {
      SUCCESS: "Заявка успішно видалена",
      ERROR: "Не вдалось видалити заявку",
    },
  },
  EditStatus: {
    Notification: {
      SUCCESS: "Статус заявки успішно змінено",
      ERROR: "Не вдалось змінити статус заявки",
    },
  },
  Option: {
    INDIVIDUAL: "Індивідуально",
    GROUP: "Міні-група",
    NEW: "Не оброблена",
    PROCESSED: "Оброблено",
  },
};
