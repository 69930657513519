import cx from "classnames";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import { ValidationContent } from "content";
import "./Checkbox.styles.scss";

type CheckboxProps<TFormValues extends Record<string, unknown>> = {
  /**
   * Name attribute of the `Checkbox` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: Path<TFormValues>;
  /**
   * Input label text for the `Checkbox` element.
   */
  label?: string;
  /**
   * The id of the `Checkbox` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the `Checkbox` element is focused during the first mount.
   *
   * @default false
   */
  autoFocus?: boolean;
  /**
   * If `true`, the `Checkbox` element is required to be checked.
   *
   * @default false
   */
  required?: boolean;
  /**
   * If `true`, the `Checkbox` will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * 	Validation rules in the same format for register, which includes: required, min, max, minLength, maxLength, pattern, validate
   *
   * @example
   * ```tsx
   * rules={{
   *  pattern: {
   *    value: EMAIL_REGEX,
   *    message: "Email address is invalid",
   *  }
   * }}
   * ```
   * @link https://react-hook-form.com/api/useform/register#options
   */
  rules?: RegisterOptions;
  /**
   * 	An object with field errors. There is also an ErrorMessage component to retrieve error message easily.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * Checkbox interactive element based on `react-hook-form` library.
 */
export const Checkbox = <TFormValues extends FieldValues = FieldValues>(
  props: CheckboxProps<TFormValues>
) => {
  const {
    disabled = false,
    required = false,
    autoFocus = false,
    fullWidth = false,
    label,
    className,
    id,
    errors,
    rules,
    name,
    control,
  } = props;

  const targetError = errors?.[name];
  const hasError = !!(errors && targetError);

  const innerRules: RegisterOptions = {
    ...(required && { required: ValidationContent.Required }),
    ...rules,
    disabled,
  };

  return (
    <div
      className={cx([
        "nb-interactive-checkbox-wrapper",
        {
          "nb-interactive-checkbox-wrapper--full": fullWidth,
        },
        className,
      ])}
    >
      <Controller
        name={name}
        control={control}
        rules={innerRules}
        render={({ field }) => (
          <div className="nb-interactive-checkbox-input-wrapper">
            <input
              type="checkbox"
              aria-invalid={hasError}
              aria-label={label}
              id={id}
              required={required}
              autoFocus={autoFocus}
              className="nb-interactive-checkbox-input"
              checked={field.value}
              disabled={disabled}
              {...field}
            />
            <div
              className={cx([
                "nb-interactive-checkbox-tick",
                {
                  "nb-interactive-checkbox-tick--checked": !!field.value,
                },
              ])}
            >
              <TickIcon
                className={cx([
                  "nb-interactive-checkbox-tick-icon",
                  {
                    "nb-interactive-checkbox-tick-icon--checked": !!field.value,
                  },
                ])}
                width={10}
                height={7}
              />
            </div>
          </div>
        )}
      />
      {label && (
        <label className="nb-interactive-checkbox-label" htmlFor={id}>
          {label}
        </label>
      )}
      {hasError && (
        <small className="nb-interactive-checkbox-error-text">
          {targetError?.message?.toString()}
        </small>
      )}
    </div>
  );
};
