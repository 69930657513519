export const CourseDetails = {
  Name: "Курс",
  Emoji: "📒",
  Search: {
    PLACEHOLDER: "Пошук по урокам в курсі",
    NO_OPTIONS: "Урок не знайдено",
  },
  Table: {
    Header: {
      NUMBER: "№",
      AVATAR: "Фото",
      NAME: "Назва",
      ACTION: "Дії",
    },
    Button: {
      OPEN: "Відкрити",
    },
  },
  Empty:
    "Список уроків цього курсу порожній. Натисніть “Додати новий урок”, щоб почати роботу 💪",
  Add: {
    TITLE: "Створити новий урок",
    EMOJI: "📒",
    SUBMIT: "Створити урок",
    Input: {
      Name: {
        LABEL: "Назва",
        PLACEHOLDER: "Введіть назву уроку",
      },
      Description: {
        LABEL: "Опис",
        PLACEHOLDER: "Опишіть коротко цей урок",
      },
    },
    Notification: {
      SUCCESS: "Урок успішно додано в курс",
      ERROR: "Не вдалось додати урок до курсу",
    },
  },
  Edit: {
    Course: {
      TITLE: "Редагувати курс",
      EMOJI: "📒",
      SUBMIT: "Зберегти зміни",
      Input: {
        Name: {
          LABEL: "Назва",
          PLACEHOLDER: "Вступний курс №1",
        },
        Language: {
          LABEL: "Мова",
          PLACEHOLDER: "Оберіть мову",
        },
        Level: {
          LABEL: "Рівень",
          PLACEHOLDER: "Оберіть рівень знань",
        },
        Description: {
          LABEL: "Опис курсу",
          PLACEHOLDER: "Докладніший опис курсу...",
        },
      },
      Notification: {
        SUCCESS: "Курс успішно змінено",
        ERROR: "Не вдалось внести зміни до курсу",
      },
    },
    Lesson: {
      TITLE: "Редагувати урок",
      EMOJI: "📒",
      SUBMIT: "Зберегти зміни",
      Input: {
        Name: {
          LABEL: "Назва",
          PLACEHOLDER: "Введіть назву уроку",
        },
        Description: {
          LABEL: "Опис",
          PLACEHOLDER: "Опишіть коротко цей урок",
        },
      },
      Notification: {
        SUCCESS: "Урок успішно змінено",
        ERROR: "Не вдалось внести зміни до уроку",
      },
    },
  },
  Delete: {
    Lesson: {
      TITLE: "Ви дійсно хочете видалити урок?",
      EMOJI: "😥",
      Notification: {
        SUCCESS: "Урок успішно видалено",
        ERROR: "Не вдалося видалити урок",
      },
    },
    Course: {
      TITLE: "Ви дійсно хочете видалити курс?",
      EMOJI: "😥",
      Notification: {
        SUCCESS: "Курс успішно видалено",
        ERROR: "Не вдалося видалити курс",
      },
    },
  },
  Hero: {
    Content: {
      Heading: {
        EMOJI: "🎓",
      },
    },
    Action: {
      Button: {
        ADD_LESSON: "Додати новий урок",
      },
    },
  },
};
