export const TrueOrFalse = {
  Add: {
    Input: {
      Condition: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Timer: {
        LABEL: "Таймер",
        PLACEHOLDER: "Оберіть значення таймера",
      },
      Sentences: {
        LABEL: "Твердження",
        ASSERTION_PLACEHOLDER: "Твердження",
        ADD_ASSERTION_TEXT: "Додати твердження",
      },
    },
  },
  Option: {
    TRUE: "True",
    FALSE: "False",
  },
};
