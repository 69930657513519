import { useMemo } from "react";
import { Row } from "@tanstack/react-table";
import { DragOverlay } from "@dnd-kit/core";
import cx from "classnames";
import { TableRow } from "../TableRow";
import {
  TDefaultTableData,
  TTableClassNames,
  TTableRowIdentifier,
} from "../Table.types";
import "./TableDragOverlay.styles.scss";

type TableDragOverlayProps<
  TData extends TDefaultTableData = TDefaultTableData
> = {
  /**
   * If `true` the `Table` rows can be reordered by drag'n'drop mechanics
   * and can be handled with `onDragComplete` event listener.
   *
   * @default false
   */
  allowRowReorder?: boolean;
  /**
   * Currently dragged active row identifier.
   * @default null
   */
  dragActiveId?: TTableRowIdentifier | null;
  /**
   * Array of row objects generated in `react-table` to operate with data and cells.
   */
  rows: Row<TData>[];
  /**
   * Object for `Table` sub-components to override or extend the styles applied to the particular component.
   */
  className?: TTableClassNames;
};

export const TableDragOverlay = <
  TData extends TDefaultTableData = TDefaultTableData
>(
  props: TableDragOverlayProps<TData>
): JSX.Element => {
  const {
    allowRowReorder = false,
    dragActiveId = null,
    rows,
    className,
  } = props;

  const dragSelectedRow: Row<TData> | null = useMemo(() => {
    if (!dragActiveId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === dragActiveId);
    return row || null;
  }, [dragActiveId, rows]);

  return (
    <DragOverlay>
      {dragActiveId && (
        <table className={cx(["nb-table-drag-overlay", className?.root])}>
          <tbody
            className={cx([
              "nb-table-drag-overlay-body",
              className?.body?.root,
            ])}
          >
            {dragSelectedRow && (
              <TableRow<TData>
                row={dragSelectedRow}
                allowRowReorder={allowRowReorder}
                className={className?.body?.row}
              />
            )}
          </tbody>
        </table>
      )}
    </DragOverlay>
  );
};
