import { LessonDetailsContent as Content } from "content";
import { ContentModal } from "components/Common/ContentModal";
import { EditLessonChapterForm } from "../EditLessonChapterForm";
import { TCourseLessonChapter } from "types/api/course";
import "./EditLessonChapterModal.styles.scss";

type EditLessonChapterModalProps = {
  /**
   * Lesson chapter data for default values in `EditLessonChapterForm` component.
   */
  lessonChapter: TCourseLessonChapter | null;
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: () => void;
};

export const EditLessonChapterModal: React.FC<EditLessonChapterModalProps> = (
  props
) => {
  const { lessonChapter = null, open = false, onClose } = props;

  return (
    <ContentModal
      portalId="edit-course-lesson-chapter-modal"
      footerId="edit-course-lesson-chapter-modal-footer"
      open={open}
      title={Content.Edit.Chapter.TITLE}
      emoji={Content.Edit.Chapter.EMOJI}
      onClose={onClose}
    >
      <EditLessonChapterForm onSubmit={onClose} lessonChapter={lessonChapter} />
    </ContentModal>
  );
};
