import { useMutation, useQueryClient } from "react-query";
import { editTeacher } from "api/services/teachers";
import { ETeachersQueryKeys } from "api/endpoints/teachers";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TTeacher } from "types/api/teacher";

export const useEditTeacher = () => {
  const queryClient = useQueryClient();
  return useMutation<TTeacher, TApiError, TTeacher>(editTeacher, {
    onSuccess: () => {
      queryClient.invalidateQueries(ETeachersQueryKeys.getTeachers);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
