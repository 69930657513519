import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { constructorExercises } from "constants/exercises";
import { TUrlParam } from "types/utils";
import { EExerciseType, TExerciseItem } from "types/app/exercises";
import { TExercise, TExerciseTarget } from "types/api/exercise";
import { EAppReducers } from "../../white-list";

export type TExerciseConstructorState<
  TExercisePayload extends Record<string, unknown> = Record<string, unknown>
> = {
  /**
   * Field for ID of the course. Required for exercise manipulation with API. Should be provided before any exercise API event, otherwise request wont be executed.
   * @default null
   */
  courseId: TUrlParam;
  /**
   * Field for ID of the course lesson. Required for exercise manipulation with API. Should be provided before any exercise API event, otherwise request wont be executed.
   * @default null
   */
  lessonId: TUrlParam;
  /**
   * Field for ID of the lesson chapter. Required for exercise manipulation with API. Should be provided before any exercise API event, otherwise request wont be executed.
   * @default null
   */
  chapterId: TUrlParam;
  /**
   * Field for ID of the lesson homework. Required for exercise manipulation with API. Should be provided before any exercise API event, otherwise request wont be executed.
   * @default null
   */
  homeworkId: TUrlParam;
  /**
   * Indicates `open` state of main drawer with list of exercises.
   * @default false
   */
  isMainDrawerOpen: boolean;
  /**
   * Indicates `open` state of secondary drawer with exercise configuration form which based on selected exercise type.
   * @default false
   */
  isSecondaryDrawerOpen: boolean;
  /**
   * Indicates `edit` mode of exercise configuration form. If `true` - form will be filled with data from `editExercise` field.
   */
  isEditMode: boolean;
  /**
   * List of all exercises.
   */
  exercises: TExerciseItem[];
  /**
   * Selected exercise type. Secondary drawer configuration form will depend on this field to display correct (current/selected) exercise type.
   * @default EExerciseType.DESCRIBE_IMAGE
   */
  currentExerciseType: EExerciseType;
  /**
   * Exercise data for editing. If `null` - form will be filled with default values, otherwise - with data from this field.
   */
  editExercise: TExercise<TExercisePayload> | null;
  /**
   * Indicates mode of constructor. If `lesson` - constructor will be used for lesson exercises, if `homework` - for homework exercises.
   */
  constructorMode: TExerciseTarget;
};

/**
 * This function will create slices for exercise constructor. Main reason is to make generic exercise payload slices.
 */
const createExerciseConstructorSlices = <
  TExercisePayload extends Record<string, unknown> = Record<string, unknown>
>() => {
  const initialState: TExerciseConstructorState<TExercisePayload> = {
    courseId: null,
    lessonId: null,
    chapterId: null,
    homeworkId: null,
    isMainDrawerOpen: false,
    isSecondaryDrawerOpen: false,
    isEditMode: false,
    exercises: constructorExercises,
    currentExerciseType: EExerciseType.IMAGE,
    editExercise: null,
    constructorMode: "lesson",
  };

  return createSlice({
    name: EAppReducers.EXERCISE_CONSTRUCTOR,
    initialState,
    reducers: {
      toggleExerciseList: (state, action: PayloadAction<boolean>) => {
        state.isMainDrawerOpen = action.payload;
      },
      toggleExerciseConfig: (state, action: PayloadAction<boolean>) => {
        state.isSecondaryDrawerOpen = action.payload;
      },
      toggleEditMode: (state, action: PayloadAction<boolean>) => {
        state.isEditMode = action.payload;
      },
      filterExercises: (state, action: PayloadAction<string>) => {
        state.exercises = initialState.exercises.filter((exercise) =>
          exercise.tags.some((tag) =>
            tag
              .trim()
              .toLowerCase()
              .includes(action.payload.trim().toLowerCase())
          )
        );
      },
      resetExercises: (state) => {
        state.exercises = initialState.exercises;
      },
      setCurrentExerciseType: (state, action: PayloadAction<EExerciseType>) => {
        state.currentExerciseType = action.payload;
      },
      setCourseId: (state, action: PayloadAction<TUrlParam>) => {
        state.courseId = action.payload;
      },
      setLessonId: (state, action: PayloadAction<TUrlParam>) => {
        state.lessonId = action.payload;
      },
      setChapterId: (state, action: PayloadAction<TUrlParam>) => {
        state.chapterId = action.payload;
      },
      setHomeworkId: (state, action: PayloadAction<TUrlParam>) => {
        state.homeworkId = action.payload;
      },
      setEditExercise: (
        state,
        action: PayloadAction<TExercise<TExercisePayload> | null>
      ) => {
        state.editExercise = action.payload as Draft<
          TExercise<TExercisePayload>
        > | null;
      },
      setConstructorMode: (state, action: PayloadAction<TExerciseTarget>) => {
        state.constructorMode = action.payload;
      },
    },
  });
};

export const exerciseConstructorSlices = createExerciseConstructorSlices();

export const exerciseConstructorReducer = exerciseConstructorSlices.reducer;
