import { Outlet } from "react-router-dom";
import { AuthContainer } from "components/Layout/AuthContainer";

export const AuthLayout: React.FC = () => {
  return (
    <AuthContainer>
      <Outlet />
    </AuthContainer>
  );
};
