import { EventImpl } from "@fullcalendar/core/internal";
import { TLesson } from "types/api/lesson";

export enum EScheduleAddFormStep {
  FIRST = "FIRST",
  SECOND = "SECOND",
}

export type TScheduleEvent = {
  id: string;
  title: string;
  start: string;
  end: string;
  data: TLesson;
  className: string;
};

export type TScheduleEditEvent = EventImpl;

export type TScheduleAddEvent = {
  startDate: Date;
  endDate: Date;
};
