import { OptionProps, components } from "react-select";
import cx from "classnames";
import { TClueSelectDefaultOption } from "../../ClueSelect.types";
import "./Option.styles.scss";

export const Option = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>({
  isFocused,
  isSelected,
  ...optionProps
}: OptionProps<Option, false>): JSX.Element => {
  return (
    <components.Option
      {...optionProps}
      isFocused={isFocused}
      isSelected={isSelected}
      className={cx([
        "nb-interactive-clue-select-option",
        {
          "nb-interactive-clue-select-option--focused": isFocused,
          "nb-interactive-clue-select-option--selected": isSelected,
        },
      ])}
    />
  );
};
