import { nanoid } from "nanoid";
import { TPairWordsInputInitialValue } from "./PairWordsInput.types";

export const getPairWordsInputInitialValue =
  (): TPairWordsInputInitialValue => ({
    id: nanoid(),
    word: "",
    linkedWord: "",
    hint: null,
  });
