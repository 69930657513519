export enum ETeachersQueryKeys {
  getTeachers = "getTeachers",
  getLanguages = "getLanguages",
  getTeachersByLanguage = "getTeachersByLanguage",
  getTeacherById = "getTeacherById",
}

export const TEACHERS_ENDPOINTS = {
  GET_ALL_TEACHERS: "/teachers",
  GET_LANGUAGES: "/languages",
};
