import { forwardRef } from "react";
import cx from "classnames";
import "./Paragraph.styles.scss";

type ParagraphProps = {
  /**
   * The component maps the variant prop to a range of different paragraph types.
   * @default {
   *   1: 'body1',
   *   2: 'body2',
   * }
   */
  variant?: 1 | 2;
  /**
   * The component maps the font variant prop to a range of different font families.
   *
   * @default 'circe'
   */
  font?: "circe" | "ptmono";
  /**
   * If `true`, the text will have a line break for `\n` (new line) characters.
   * @default false
   */
  lineBreak?: boolean;
  /**
   * If `true`, the text will be bold font weight.
   *
   * @default false
   */
  bold?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

const Paragraph: React.ForwardRefRenderFunction<
  HTMLParagraphElement,
  ParagraphProps
> = (props, ref) => {
  const {
    children,
    variant = 1,
    font = "circe",
    bold = false,
    lineBreak = false,
    className,
  } = props;

  return (
    <p
      ref={ref}
      className={cx([
        {
          "nb-typography-paragraph-body-1": variant === 1,
          "nb-typography-paragraph-body-1--bold": variant === 1 && bold,
          "nb-typography-paragraph-body-1--ptmono":
            variant === 1 && font === "ptmono",
          "nb-typography-paragraph-body-2": variant === 2,
          "nb-typography-paragraph-body-2--bold": variant === 2 && bold,
          "nb-typography-paragraph-body-2--ptmono":
            variant === 2 && font === "ptmono",
          "nb-typography-paragraph-line-break": lineBreak,
        },
        className,
      ])}
    >
      {children}
    </p>
  );
};

/**
 * Paragraph typography component to display block of text
 */
export const ForwardedParagraph = forwardRef(Paragraph);
