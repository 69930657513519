import { Space } from "components/Common/Space";
import { DroppableItem } from "../DroppableItem";
import {
  TExerciseIdentifier,
  TExerciseSentence,
  TMoveWordsIntoGapsExerciseBlank,
} from "types/app/exercises";
import "./DroppableList.styles.scss";

type TDroppableListProps = {
  /**
   * An array of sentence data to construct an exercise.
   */
  sentences: TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[];
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DroppableList: React.FC<TDroppableListProps> = (props) => {
  const {
    dragging = false,
    showResult = false,
    sentences = [],
    dragActiveId = null,
  } = props;

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      {sentences.map((sentence) => (
        <DroppableItem
          key={sentence.id}
          sentence={sentence}
          dragging={dragging}
          dragActiveId={dragActiveId}
          showResult={showResult}
        />
      ))}
    </Space>
  );
};
