import { TUserState, userSelector, userSlices } from "store/features/user";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { TActiveUser } from "types/api/user";

type TUseActiveUser = () => TUserState & {
  /**
   * Handler function that will set current active user data.
   * @param user currently active user.
   */
  setActiveUser: (user: TActiveUser) => void;
  /**
   * Handler function that will reset active user to `null`.
   */
  removeActiveUser: () => void;
};

export const useActiveUser: TUseActiveUser = () => {
  const dispatch = useAppDispatch();
  const { activeUser } = useAppSelector(userSelector);
  const { setUser, resetUser } = userSlices.actions;

  return {
    activeUser,
    setActiveUser: (user: TActiveUser) => {
      dispatch(setUser(user));
    },
    removeActiveUser: () => {
      dispatch(resetUser());
    },
  };
};
