import { LessonDetailsContent } from "content";
import { Loader } from "components/Common/Loader";
import { Divider } from "components/Common/Divider";
import { Heading } from "components/Typography/Heading";
import { HomeworkContentExercises } from "../HomeworkContentExercises";
import "./HomeworkContent.styles.scss";

type HomeworkContentProps = {
  /**
   * If `true` component will display loader with animation.
   * @default false
   */
  loading?: boolean;
  /**
   * Unique identifier of the lesson homework entity.
   */
  homeworkId?: number;
};

export const HomeworkContent: React.FC<HomeworkContentProps> = (props) => {
  const { loading = false, homeworkId } = props;

  return (
    <div className="nb-lesson-details-homework-content-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Heading>{LessonDetailsContent.Content.Homework.TITLE}</Heading>
          <Divider variant="dashed" margin="yAxis" marginSize="large" />
          <HomeworkContentExercises homeworkId={homeworkId} />
        </>
      )}
    </div>
  );
};
