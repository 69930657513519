import { forwardRef } from "react";
import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { DragAndDropContent } from "content";
import { ReactComponent as More } from "assets/icons/more.svg";
import { Space } from "components/Common/Space";
import { Tooltip } from "components/Interactive/Tooltip";
import { mergeRefs } from "utils/react";
import "./Droppable.styles.scss";

type TDroppableProps = {
  /**
   * The identifier of the droppable zone.
   */
  droppableId: string | number;
  /**
   * The data that will be passed to the droppable zone.
   */
  data?: unknown;
  /**
   * The hint that will be shown in the tooltip.
   * @default null
   */
  hint?: string | null;
  /**
   * The label that will be used for the aria-label attribute.
   */
  ariaLabel?: string;
  /**
   * Indicates if the droppable zone is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates if a user is failed all attempts to pick the correct answer on this droppable blank.
   * @default false
   */
  failed?: boolean;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

const Droppable: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TDroppableProps
> = (props, ref) => {
  const {
    children,
    droppableId,
    data,
    hint = null,
    ariaLabel = DragAndDropContent.Default.Droppable.LABEL,
    dragging = false,
    disabled = false,
  } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: droppableId,
    data: { data: data ?? null },
    disabled,
  });

  return (
    <Space
      direction="horizontal"
      justify="start"
      align="center"
      size="xx-small"
    >
      <Tooltip
        text={hint}
        disabled={false} // TODO: When permissions will be implemented this should be always disabled for student
        trigger="click"
        position="top-start"
        spaceSize="small"
        arrow="visible"
        containerClassName="nb-drag-and-drop-droppable-tooltip-container"
        className="nb-drag-and-drop-droppable-tooltip"
      >
        <div
          ref={mergeRefs([setNodeRef, ref])}
          className={cx("nb-drag-and-drop-droppable", {
            "nb-drag-and-drop-droppable--over": isOver,
            "nb-drag-and-drop-droppable--dragging": dragging,
            "nb-drag-and-drop-droppable--dropped": children,
            "nb-drag-and-drop-droppable--disabled": disabled,
          })}
          aria-label={ariaLabel}
        >
          {children}
        </div>
      </Tooltip>
      {/** TODO: Indicator of correct answer when API will be ready. Icon will be rendered as a placeholder for now */}
      <More />
    </Space>
  );
};

export const ForwardedDroppable = forwardRef(Droppable);
