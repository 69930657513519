import { useCallback, useEffect, useRef, useState } from "react";

type TUseScrollAndHighlightOptions = {
  /**
   * If `true` will scroll to referred element.
   * @default false
   */
  enable?: boolean;
  /**
   * Indicates time in ms of `highlight` state .
   * @default 1000
   */
  highlightTime?: number;
  /**
   * Specifies the number of pixels for the offset of the referred element along the Y axis to scroll.
   * @default 0
   */
  scrollOffset?: number;
  /**
   * Specifies the time in ms before scroll function execution.
   * @default 0
   */
  delay?: number;
};

type TUseScrollAndHighlightReturn = {
  /**
   * Indicates highlight state.
   */
  highlight: boolean;
};

type TUseScrollAndHighlight = (
  element: HTMLElement | null,
  options?: TUseScrollAndHighlightOptions
) => TUseScrollAndHighlightReturn;

export const useScrollAndHighlight: TUseScrollAndHighlight = (
  element,
  options
) => {
  const {
    enable = false,
    highlightTime = 1000,
    scrollOffset = 0,
    delay = 0,
  } = options || {};

  const [highlight, setHighlight] = useState<boolean>(false);
  const highlightTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const scrollElementHandler = useCallback(() => {
    highlightTimer.current = setTimeout(
      () => setHighlight(false),
      highlightTime
    );
    if (enable) {
      setHighlight(true);
    }
    if (enable && element) {
      window.scrollTo({
        top: element.offsetTop - scrollOffset,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [element, enable, highlightTime, scrollOffset]);

  useEffect(() => {
    const delayTimer = setTimeout(() => scrollElementHandler(), delay);

    return () => {
      if (highlightTimer.current) {
        clearTimeout(highlightTimer.current);
      }
      clearTimeout(delayTimer);
    };
  }, [
    enable,
    delay,
    element,
    highlightTime,
    scrollOffset,
    scrollElementHandler,
  ]);

  return {
    highlight,
  };
};
