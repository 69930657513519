/**
 * Function creates image element with provided url as source path.
 * @param url the image url.
 * @returns the HTMLImageElement interface represents an HTML <img> element, providing the properties and methods used to manipulate image elements.
 */
export const createImageElement = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
  });
