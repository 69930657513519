import { useMemo } from "react";
import { IconButton } from "components/Interactive/IconButton";
import { Text } from "components/Typography/Text";
import { Space } from "components/Common/Space";
import { TableContent } from "content";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import "./MaterialsPagination.styles.scss";

type TMaterialsPaginationProps = {
  /**
   * Current page number.
   * @default 0
   */
  currentPage?: number;
  /**
   * Total pages number.
   * @default 0
   */
  totalPages?: number;
  /**
   * Callback fired when the page is changed.
   *
   * @param {Number} page - new page index of the materials list.
   */
  onPageChange?: (newPage: number) => void;
};

export const MaterialsPagination: React.FC<TMaterialsPaginationProps> = (
  props
): JSX.Element | null => {
  const { currentPage = 0, totalPages = 0, onPageChange } = props;

  const isNextPageDisabled: boolean = useMemo(
    () => currentPage === totalPages - 1,
    [currentPage, totalPages]
  );
  const isPreviousPageDisabled: boolean = useMemo(
    () => currentPage === 0,
    [currentPage]
  );
  const isPaginationHidden: boolean = useMemo(
    () => totalPages <= 1,
    [totalPages]
  );

  const handlePreviousButtonClick = () => {
    if (isPreviousPageDisabled) {
      return;
    }
    onPageChange?.(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    if (isNextPageDisabled) {
      return;
    }
    onPageChange?.(currentPage + 1);
  };

  if (isPaginationHidden) {
    return null;
  }

  return (
    <Space
      direction="horizontal"
      justify="center"
      align="center"
      size="x-small"
      fullWidth
    >
      <IconButton
        icon={<Chevron />}
        className="nb-courses-materials-pagination-previous"
        onClick={handlePreviousButtonClick}
        disabled={isPreviousPageDisabled}
      />
      <IconButton
        icon={<Chevron />}
        className="nb-courses-materials-pagination-next"
        onClick={handleNextButtonClick}
        disabled={isNextPageDisabled}
      />
      <div className="nb-courses-materials-pagination-counter">
        <Text variant="body2">
          {TableContent.Pagination.PAGE}&nbsp;
          <Text bold variant="body2">
            {currentPage + 1}
          </Text>
          &nbsp;{TableContent.Pagination.SEPARATOR}&nbsp;
          <Text bold variant="body2">
            {totalPages}
          </Text>
        </Text>
      </div>
    </Space>
  );
};
