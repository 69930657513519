import { forwardRef } from "react";
import cx from "classnames";
import { Space } from "components/Common/Space";
import "./RadioRectInput.styles.scss";

type TRadioRectInputProps = {
  /**
   * Input label text for the `RadioRectInput` element.
   */
  label?: string;
  /**
   * The id of the `RadioRectInput` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * Name attribute of the `RadioRectInput` element.
   */
  name?: string;
  /**
   * Value attribute of the `RadioRectInput` element.
   */
  value?: string;
  /**
   * If `true`, the `RadioRectInput` will be checked.
   * @default false
   */
  checked?: boolean;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * The radio buttons are arranged horizontally or vertically.
   * @default "horizontal"
   */
  layout?: "horizontal" | "vertical";
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Callback will be triggered when the checkmark state was changed.
   *
   * @param event - react input change event
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioRectInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TRadioRectInputProps
> = (props, ref) => {
  const {
    label,
    id,
    name,
    value,
    disabled = false,
    checked = false,
    layout = "horizontal",
    className,
    onChange,
  } = props;

  return (
    <Space
      direction={layout === "horizontal" ? "vertical" : "horizontal"}
      justify="start"
      align={layout === "horizontal" ? "start" : "center"}
      size="xx-small"
      className={cx([
        "nb-interactive-radio-group-rect-input-container",
        className,
      ])}
    >
      <div className="nb-interactive-radio-group-rect-input-wrapper">
        <input
          ref={ref}
          type="radio"
          aria-label={label}
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className="nb-interactive-radio-group-rect-input"
        />
        <div
          className={cx([
            "nb-interactive-radio-group-rect-input-shape",
            {
              "nb-interactive-radio-group-rect-input-shape--checked": checked,
            },
          ])}
        >
          {/* Design is strange (seems incomplete). Should be an image here or something */}
        </div>
      </div>
      {label && (
        <label
          className="nb-interactive-radio-group-rect-input-label"
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </Space>
  );
};

/**
 * `RadioRectInput` is interactive component which allows to toggle the state of a radio input with a rect shape.
 */
export const ForwardedRadioRectInput = forwardRef(RadioRectInput);
