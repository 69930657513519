import { useCallback } from "react";
import { lessonSelector, lessonSlices } from "store/features/lesson";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import type { TLessonState } from "store/features/lesson";
import type {
  TLessonExerciseAnswerMode,
  TLessonViewMode,
} from "types/api/lesson";

type TUseLessonSettings = () => TLessonState & {
  /**
   * Returns true if exercise answer mode is set to "live".
   */
  isExerciseLiveMode: boolean;
  /**
   * Returns true if exercise answer mode is set to "preview".
   */
  isExercisePreviewMode: boolean;
  /**
   * Returns true if exercise answer mode is set to "read-only".
   */
  isExerciseReadOnlyMode: boolean;
  /**
   * Handler function that will change `viewMode` based on passed argument.
   * @param mode new view mode.
   */
  setViewMode: (mode: TLessonViewMode) => void;
  /**
   * Handler function that will change `exerciseAnswerMode` based on passed argument.
   * @param mode new exercise answer mode.
   */
  setExerciseAnswerMode: (mode: TLessonExerciseAnswerMode) => void;
  /**
   * Handler function that will reset all lesson settings to initial state.
   */
  resetLessonSettings: () => void;
};

export const useLessonSettings: TUseLessonSettings = () => {
  const dispatch = useAppDispatch();
  const { viewMode, exerciseAnswerMode } = useAppSelector(lessonSelector);
  const { reset, setViewMode, setExerciseAnswerMode } = lessonSlices.actions;

  return {
    isExerciseLiveMode: exerciseAnswerMode === "live",
    isExercisePreviewMode: exerciseAnswerMode === "preview",
    isExerciseReadOnlyMode: exerciseAnswerMode === "read-only",
    viewMode,
    exerciseAnswerMode,
    setViewMode: useCallback(
      (mode) => {
        dispatch(setViewMode(mode));
      },
      [dispatch, setViewMode]
    ),
    setExerciseAnswerMode: useCallback(
      (mode) => {
        dispatch(setExerciseAnswerMode(mode));
      },
      [dispatch, setExerciseAnswerMode]
    ),
    resetLessonSettings: useCallback(() => {
      dispatch(reset());
    }, [dispatch, reset]),
  };
};
