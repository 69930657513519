import { MenuListProps, components } from "react-select";
import { TSelectDefaultOption } from "../../Select.types";
import "./MenuList.styles.scss";

export const MenuList = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>(
  props: MenuListProps<Option, false>
): JSX.Element => (
  <components.MenuList {...props} className="nb-interactive-select-menu-list">
    {props.children}
  </components.MenuList>
);
