import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { TMakeWordFromLettersExerciseWordBlank } from "types/app/exercises";
import "./Droppable.styles.scss";

type TDroppableProps = {
  /**
   * A blank data to be used in the droppable zone.
   */
  blank: TMakeWordFromLettersExerciseWordBlank;
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const Droppable: React.FC<TDroppableProps> = (props) => {
  const { children, blank, dragging = false } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: blank.id,
    data: { blank, letter: blank.currentLetter },
  });

  return (
    <div
      ref={setNodeRef}
      className={cx("nb-exercise-make-words-from-letters-droppable", {
        "nb-exercise-make-words-from-letters-droppable--over": isOver,
        "nb-exercise-make-words-from-letters-droppable--dragging": dragging,
        "nb-exercise-make-words-from-letters-droppable--dropped": children,
      })}
      aria-label="Поле в яке можна перенести літеру"
    >
      {children}
    </div>
  );
};
