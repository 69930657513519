import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { addCourseLessonChapter } from "api/services/courses";
import { logError } from "utils/notifications";
import {
  TAddCourseLessonChapterBody,
  TCourseLessonChapter,
} from "types/api/course";
import { TApiError } from "types/api/error";

export const useAddCourseLessonChapter = (
  courseId?: string | number,
  lessonId?: string | number
) => {
  const queryClient = useQueryClient();
  return useMutation<
    TCourseLessonChapter,
    TApiError,
    TAddCourseLessonChapterBody
  >(
    (lessonChapter: TAddCourseLessonChapterBody) =>
      addCourseLessonChapter(courseId, lessonId, lessonChapter),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          ECoursesQueryKeys.getAllCourseLessonChapters
        );
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
