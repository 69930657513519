import { useMemo } from "react";
import {
  ArrayPath,
  Control,
  FieldArray,
  FieldErrorsImpl,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { PairWordsInputContent } from "content";
import { Space } from "components/Common/Space";
import { InputLabel } from "components/Common/InputLabel";
import { Button } from "components/Interactive/Button";
import { InputList } from "./InputList";
import { getPairWordsInputInitialValue } from "./PairWordsInput.helpers";
import { TPairWordsInputDefaultValue } from "./PairWordsInput.types";
import "./PairWordsInput.styles.scss";

type TPairWordsInputProps<
  TFormValues extends TPairWordsInputDefaultValue = TPairWordsInputDefaultValue
> = {
  /**
   * Name attribute of the `PairWordsInput` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: ArrayPath<TFormValues>;
  /**
   * Label text for the `PairWordsInput` component.
   */
  label?: string;
  /**
   * The short hint displayed in the word `input` before the user enters a value.
   */
  wordPlaceholder?: string;
  /**
   * The short hint displayed in the linked word `input` before the user enters a value.
   */
  linkWordPlaceholder?: string;
  /**
   * The content of the add pair button.
   */
  addPairButtonText?: string;
  /**
   * The text hint displayed as tooltip popup right to the label, which looks like a question icon button. Hint will appear when user clicks on the hint button.
   */
  hint?: string;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * `PairWordsInput` interactive element in association with `react-hook-form` library made for `ExerciseConstructor` component.
 */
export const PairWordsInput = <
  TFormValues extends TPairWordsInputDefaultValue = TPairWordsInputDefaultValue
>(
  props: TPairWordsInputProps<TFormValues>
): JSX.Element => {
  const {
    label = PairWordsInputContent.Default.LABEL,
    wordPlaceholder = PairWordsInputContent.Default.WORD_PLACEHOLDER,
    linkWordPlaceholder = PairWordsInputContent.Default.LINK_WORD_PLACEHOLDER,
    addPairButtonText = PairWordsInputContent.Default.ADD_PAIR_BUTTON,
    hint,
    name,
    control,
    register,
    errors,
    className,
  } = props;

  const { fields, remove, append } = useFieldArray<TFormValues>({
    control,
    name,
  });

  const addPairClickHandler = () => {
    append(
      getPairWordsInputInitialValue() as FieldArray<
        TFormValues,
        ArrayPath<TFormValues>
      >
    );
  };

  const removePairClickHandler = (pairIndex: number) => {
    remove(pairIndex);
  };

  const firstFieldId: string = useMemo(() => {
    const [firstField] = fields;
    return firstField?.id || "";
  }, [fields]);

  return (
    <Space
      direction="vertical"
      justify="start"
      size="x-small"
      fullWidth
      className={className}
    >
      <InputLabel
        label={label}
        hint={hint}
        id={`pair-words-input-word-${firstFieldId}`}
      />
      <InputList<TFormValues>
        name={name}
        wordPlaceholder={wordPlaceholder}
        linkWordPlaceholder={linkWordPlaceholder}
        fields={fields}
        register={register}
        errors={errors}
        onDelete={removePairClickHandler}
      />
      <Button variant="secondary" fullWidth onClick={addPairClickHandler}>
        {addPairButtonText}
      </Button>
    </Space>
  );
};
