import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { EAuthQueryKeys } from "api/endpoints/auth";
import { fetchCurrentUser } from "api/services/auth";
import { Route } from "constants/routes";
import { TLogin } from "types/api/auth";
import { TApiError } from "types/api/error";

export const useCurrentUser = () => {
  const navigate = useNavigate();
  const { setActiveUser } = useActiveUser();

  return useQuery<TLogin, TApiError>(
    EAuthQueryKeys.getCurrentUser,
    fetchCurrentUser,
    {
      onSuccess(data) {
        setActiveUser({
          ...data.person,
          studentId: data.studentId,
          teacherId: data.teacherId,
        });
      },
      onError: () => {
        navigate(Route.Auth.Login.ROOT.path);
      },
      retry: false,
    }
  );
};
