import cx from "classnames";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { Dropdown } from "components/Interactive/Dropdown";
import { ProfileDropdownContent } from "components/Navigation/ProfileDropdownContent";
import "./ProfileDropdown.styles.scss";

type ProfileDropdownProps = {
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * Profile Dropdown is navigation element with user info, picture and list of menu options
 */
export const ProfileDropdown: React.FC<ProfileDropdownProps> = (props) => {
  const { className } = props;

  const { activeUser } = useActiveUser();
  const isDisable: boolean = activeUser === null;

  return (
    <Dropdown
      trigger="click"
      position="bottom-end"
      spaceSize="small"
      disabled={isDisable}
      bordered
      closeOnItemClick
      content={<ProfileDropdownContent />}
    >
      <button
        aria-label="Відкрити меню користувача"
        aria-disabled={isDisable}
        type="button"
        disabled={isDisable}
        tabIndex={isDisable ? -1 : 0}
        className={cx(["nb-interactive-profile-dropdown-button", className])}
      >
        <img
          src="/assets/images/profile-picture.png"
          width={40}
          height={40}
          alt="Завантажена аватарка користувача"
        />
      </button>
    </Dropdown>
  );
};
