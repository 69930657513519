import { Link, To } from "react-router-dom";
import { Heading } from "components/Typography/Heading";
import { Avatar } from "components/Common/Avatar";
import { GroupItem } from "../GroupItem";
import "./StudyGroup.styles.scss";

type StudyGroupProps = {
  /**
   * The title of the `StudyGroup` component.
   */
  title: string;
  /**
   * Describes a location that is the destination of navigation via URL.
   */
  link: To;
};

export const StudyGroup: React.FC<StudyGroupProps> = ({ title, link }) => {
  return (
    <Link to={link} className="nb-sub-study-group-link">
      <section>
        <Heading level={2}>{title}</Heading>
      </section>
    </Link>
  );
};
