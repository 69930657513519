import { useCallback } from "react";
import {
  breadcrumbSelector,
  breadcrumbsSlices,
} from "store/features/breadcrumbs";
import { useAppDispatch, useAppSelector } from "hooks/redux";

export const useBreadcrumbs = () => {
  const dispatch = useAppDispatch();
  const { name } = useAppSelector(breadcrumbSelector);
  const { setName, resetName } = breadcrumbsSlices.actions;

  return {
    pageName: name,
    setPageName: useCallback(
      (name: string) => {
        dispatch(setName(name));
      },
      [dispatch, setName]
    ),
    resetPageName: useCallback(() => {
      dispatch(resetName());
    }, [dispatch, resetName]),
  };
};
