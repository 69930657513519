import { Fragment, useMemo } from "react";
import { LessonDetailsContent } from "content";
import {
  ButtonExercise,
  ChooseRightWordExercise,
  CompareImageWithDescriptionExercise,
  DescribeGifExercise,
  DescribeImageExercise,
  DescribeVideoExercise,
  EssayExercise,
  InputWordsIntoGapsExercise,
  MakeWordFromLettersExercise,
  MoveWordsIntoGapsExercise,
  NotesExercise,
  SortWordsExercise,
  TestExercise,
  TrueOrFalseExercise,
  WordsComparingExercise,
} from "components/Exercises";
import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { Loader } from "components/Common/Loader";
import { Empty } from "components/Common/Empty";
import { logError } from "utils/notifications";
import { TExercise } from "types/api/exercise";
import {
  EExerciseType,
  TButtonExercise,
  TChooseRightWordFormExercise,
  TCompareImageWithDescriptionExercise,
  TDescribeGifExercise,
  TDescribeImageExercise,
  TDescribeVideoExercise,
  TEssayExercise,
  TInputWordsIntoGapsExercise,
  TMakeWordFromLettersExercise,
  TMoveWordsIntoGapsExercise,
  TNoteExercise,
  TSortWordsExercise,
  TTestExercise,
  TTrueOrFalseExercise,
  TWordsComparingExercise,
} from "types/app/exercises";
import "./ExercisesPreview.styles.scss";

type TExercisesPreviewProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * Array of lesson chapter exercise data.
   * @default []
   */
  exercises?: TExercise[];
  /**
   * The chapter sequence order.
   * @default 1
   */
  chapterOrder?: number;
  /**
   * If `true` component will display loader with animation.
   * @default false
   */
  loading?: boolean;
};

export const ExercisesPreview: React.FC<TExercisesPreviewProps> = (props) => {
  const {
    chapterId,
    exercises = [],
    chapterOrder = 1,
    loading = false,
  } = props;

  const renderExercise = (exercise: TExercise, prefix: string) => {
    switch (exercise.type) {
      case EExerciseType.ADD_TO_DICTIONARY:
        return null;

      case EExerciseType.BUTTON:
        return (
          <ButtonExercise
            exercise={exercise as TButtonExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.TEST:
        return (
          <TestExercise
            exercise={exercise as TTestExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.CHOOSE_RIGHT_WORD_FORM:
        return (
          <ChooseRightWordExercise
            exercise={exercise as TChooseRightWordFormExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.WORDS_COMPARING:
        return (
          <WordsComparingExercise
            exercise={exercise as TWordsComparingExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.MAKE_WORD_FROM_LETTERS:
        return (
          <MakeWordFromLettersExercise
            exercise={exercise as TMakeWordFromLettersExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.COMPARE_IMAGE_WITH_DESCRIPTION:
        return (
          <CompareImageWithDescriptionExercise
            exercise={exercise as TCompareImageWithDescriptionExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.DESCRIBE_AUDIO:
        return null;

      case EExerciseType.GIF:
        return (
          <DescribeGifExercise
            exercise={exercise as TDescribeGifExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.IMAGE:
        return (
          <DescribeImageExercise
            exercise={exercise as TDescribeImageExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.VIDEO:
        return (
          <DescribeVideoExercise
            exercise={exercise as TDescribeVideoExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.MOVE_WORDS_INTO_GAPS:
        return (
          <MoveWordsIntoGapsExercise
            exercise={exercise as TMoveWordsIntoGapsExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.TEACHER_NOTES:
        return <NotesExercise exercise={exercise as TNoteExercise} />;

      case EExerciseType.RECORD_AUDIO:
        return null;

      case EExerciseType.SENTENCE_ORDER:
        return null;

      case EExerciseType.SORT_WORDS:
        return (
          <SortWordsExercise
            exercise={exercise as TSortWordsExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.TRUE_OR_FALSE:
        return (
          <TrueOrFalseExercise
            exercise={exercise as TTrueOrFalseExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.INPUT_WORDS_INTO_GAPS:
        return (
          <InputWordsIntoGapsExercise
            exercise={exercise as TInputWordsIntoGapsExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      case EExerciseType.ESSAY:
        return (
          <EssayExercise
            exercise={exercise as TEssayExercise}
            prefix={prefix}
            chapterId={chapterId}
          />
        );

      default:
        logError({
          message: LessonDetailsContent.Preview.Error.INVALID_EXERCISE,
        });
        return null;
    }
  };

  const isEmpty: boolean = useMemo(() => exercises.length === 0, [exercises]);

  return (
    <Space direction="vertical" justify="start" size="medium" fullWidth>
      {loading && <Loader />}
      {isEmpty && !loading && (
        <Empty text={LessonDetailsContent.Preview.Empty.TEXT} />
      )}
      {!isEmpty &&
        !loading &&
        exercises.map((exercise, index) => (
          <Fragment key={exercise.id}>
            {renderExercise(exercise, `${chapterOrder}.${index + 1}.`)}
            {index !== exercises.length - 1 && <Divider />}
          </Fragment>
        ))}
    </Space>
  );
};
