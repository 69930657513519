export const ColumnInputs = {
  Default: {
    Name: {
      LABEL: "Назва",
      PLACEHOLDER: "Впишіть назву колонки",
    },
    Value: {
      LABEL: "Вміст",
      PLACEHOLDER: "Впишіть вміст колонки",
    },
    Button: {
      ADD: "Додати колонку",
    },
  },
};
