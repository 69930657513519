export const MakeWordFromLetters = {
  Add: {
    Input: {
      Condition: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Words: {
        LABEL: "Слова",
        HINT: "Підказка\n Напишіть слово чи фразу. Також можете додати підказку для учнів. Слова будуть автоматично розбиті на літери після збереження.",
        WORD_PLACEHOLDER: "Слово",
        HINT_PLACEHOLDER: "Підказка до слова",
        ADD_INPUT_BUTTON: "Додати слово",
      },
    },
  },
};
