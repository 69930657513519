import { Space } from "components/Common/Space";
import { ProfileAvatar } from "./components/ProfileAvatar";
import { ProfileForm } from "./components/ProfileForm";
import "./Settings.styles.scss";

export const Settings: React.FC = () => (
  <div className="nb-page-user-settings-container">
    <Space
      justify="start"
      align="start"
      direction="horizontal"
      size="xxx-large"
      fullWidth
    >
      <ProfileAvatar />
      <ProfileForm />
    </Space>
  </div>
);
