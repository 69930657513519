import { SingleValueProps, components } from "react-select";
import { TPersonSelectOption } from "types/app/select";
import "./SingleValue.styles.scss";

export const SingleValue = <
  Option extends TPersonSelectOption = TPersonSelectOption
>(
  props: SingleValueProps<Option>
): JSX.Element => {
  return (
    <components.SingleValue
      {...props}
      className="nb-interactive-person-select-single-value"
    >
      {props.data.name}
    </components.SingleValue>
  );
};
