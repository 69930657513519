import { NoticeProps, components } from "react-select";
import { TPersonSelectOption } from "types/app/select";
import "./LoadingMessage.styles.scss";

type LoadingMessageProps<
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
> = {
  loadingText: string;
} & NoticeProps<Option, IsMulti>;

export const LoadingMessage = <
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
>(
  props: LoadingMessageProps<Option, IsMulti>
): JSX.Element => {
  const { loadingText } = props;

  return (
    <components.LoadingMessage
      {...props}
      className="nb-interactive-person-select-loading"
    >
      {loadingText ? loadingText : props.children}
    </components.LoadingMessage>
  );
};
