import { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useLazyTimeout } from "hooks/common/useLazyTimeout";
import { useStateCallback } from "hooks/common/useStateCallback";
import "./Switch.styles.scss";

type SwitchProps = {
  /**
   * Whether to set the initial state.
   *
   * @default false
   */
  defaultChecked?: boolean;
  /**
   * Whether the component is checked.
   * Provide for controlled component behavior.
   */
  value?: boolean;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the `Switch` element is focused during the first mount.
   *
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Trigger when the checked state is changing.
   *
   * @param {boolean} checked - active state for checked parameter
   * @param {object} event - mouse button event
   * @return {void} should be voided function
   */
  onChange?: (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

/**
 * `Switch` is interactive component which allows to toggle the state of a single setting on or off.
 */
export const Switch: React.FC<SwitchProps> = (props) => {
  const {
    defaultChecked = false,
    disabled = false,
    autoFocus = false,
    value,
    className,
    onChange,
  } = props;

  const innerRef = useRef<HTMLButtonElement>(null);

  const [isInnerChecked, setIsInnerChecked] =
    useStateCallback<boolean>(defaultChecked);
  const [showWave, setShowWave] = useState<boolean>(false);

  const autofocusTimer = useLazyTimeout(100);
  const waveAnimationTimer = useLazyTimeout(400);

  useEffect(() => {
    autofocusTimer(() => {
      if (
        document.activeElement !== innerRef.current &&
        autoFocus &&
        !disabled &&
        innerRef.current
      ) {
        innerRef.current.focus();
      }
    });
  }, [autoFocus, disabled, autofocusTimer]);

  useEffect(() => {
    if (value !== undefined) {
      setIsInnerChecked(value);
    }
  }, [value, setIsInnerChecked]);

  const switchClickHandler = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (disabled) {
      evt.preventDefault();
      return;
    }

    setShowWave(true);
    waveAnimationTimer(() => {
      setShowWave(false);
    });

    setIsInnerChecked(
      (prevState) => !prevState,
      (updatedState) => onChange?.(updatedState, evt)
    );
  };

  return (
    <button
      ref={innerRef}
      type="button"
      role="switch"
      aria-checked={isInnerChecked}
      aria-label="Перемикач"
      autoFocus={autoFocus}
      disabled={disabled}
      onClick={switchClickHandler}
      className={cx([
        "nb-interactive-switch",
        { "nb-interactive-switch--checked": isInnerChecked },
        className,
      ])}
    >
      {/* Wave for animation on click */}
      <div className="nb-interactive-switch-wave-container">
        <span
          className={cx([
            "nb-interactive-switch-wave",
            { "nb-interactive-switch-wave--show": showWave },
          ])}
        />
      </div>
      <div
        className={cx([
          "nb-interactive-switch-handle",
          { "nb-interactive-switch-handle--checked": isInnerChecked },
        ])}
      />
      <span
        className={cx([
          "nb-interactive-switch-inner",
          { "nb-interactive-switch-inner--checked": isInnerChecked },
        ])}
      >
        {/* We can add text or icons here in this spans */}
        <span
          className={cx([
            "nb-interactive-switch-inner-checked",
            { "nb-interactive-switch-inner-checked--checked": isInnerChecked },
          ])}
        ></span>
        <span
          className={cx([
            "nb-interactive-switch-inner-unchecked",
            {
              "nb-interactive-switch-inner-unchecked--checked": isInnerChecked,
            },
          ])}
        ></span>
      </span>
    </button>
  );
};
