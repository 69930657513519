export const SortWords = {
  Add: {
    Input: {
      Condition: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Columns: {
        Name: {
          LABEL: "Колонка",
          PLACEHOLDER: "Fruits",
        },
        Value: {
          LABEL: "Слова для колонки",
          PLACEHOLDER: "apples/bananas/oranges",
        },
      },
    },
  },
  StartZone: {
    EMPTY: "Ви перетягнули всі слова...",
  },
  Column: {
    EMPTY: "Перетягніть слова сюди",
  },
};
