import { useEffect } from "react";
import { Area } from "react-easy-crop";
import {
  TImageUploadState,
  imageUploadSelector,
  imageUploadSlice,
} from "store/features/image-upload";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { convertBlobToBase64 } from "utils/common";

type TUseImageUpload = () => TImageUploadState & {
  /**
   * Handler function that will reset uploaded image state to `null`.
   */
  resetUploadedImage: () => void;
  /**
   * Handler function that will reset cropped image state to `null`.
   */
  resetCroppedImage: () => void;
  /**
   * Handler function that will reset cropped area state to `null`.
   */
  resetCroppedArea: () => void;
  /**
   * Handler function that will reset all image upload store states to `null`.
   */
  resetAllImageUploadStates: () => void;
  /**
   * Handler function that will change `croppedArea` state.
   * @param area an object containing data from the image cropper to cut the image based on it.
   */
  setCroppedArea: (area: Area) => void;
  /**
   * Handler function that will convert blob to URL encoded string and change `imageBase64` state.
   * @param image an image blob data.
   */
  setImage: (image: Blob) => void;
  /**
   * Handler function that will convert blob to URL encoded string and change `croppedImageBase64` state.
   * @param image an image blob data.
   */
  setCroppedImage: (image: Blob) => void;
};

export const useImageUpload: TUseImageUpload = () => {
  const dispatch = useAppDispatch();
  const { croppedArea, imageBase64, croppedImageBase64 } =
    useAppSelector(imageUploadSelector);
  const {
    resetAll,
    setCroppedArea,
    setImageBase64,
    setCroppedImageBase64,
    resetImage,
    resetCroppedImage,
    resetCroppedArea,
  } = imageUploadSlice.actions;

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch, resetAll]);

  return {
    croppedArea,
    imageBase64,
    croppedImageBase64,
    resetUploadedImage: () => {
      dispatch(resetImage());
    },
    resetCroppedImage: () => {
      dispatch(resetCroppedImage());
    },
    resetCroppedArea: () => {
      dispatch(resetCroppedArea());
    },
    resetAllImageUploadStates: () => {
      dispatch(resetAll());
    },
    setCroppedArea: (area) => {
      dispatch(setCroppedArea(area));
    },
    setImage: (image) => {
      dispatch(setImageBase64(convertBlobToBase64(image)));
    },
    setCroppedImage: (image) => {
      dispatch(setCroppedImageBase64(convertBlobToBase64(image)));
    },
  };
};
