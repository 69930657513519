import { useMemo } from "react";
import {
  ArrayPath,
  Control,
  FieldArray,
  FieldErrorsImpl,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { ColumnInputsContent } from "content";
import { Space } from "components/Common/Space";
import { Button } from "components/Interactive/Button";
import { ColumnList } from "./ColumnList";
import { getColumnInitialValues } from "./ColumnInputs.helpers";
import { TColumnInputsDefaultValues } from "./ColumnInputs.types";
import "./ColumnInputs.styles.scss";

type TColumnInputsProps<
  TFormValues extends TColumnInputsDefaultValues = TColumnInputsDefaultValues
> = {
  /**
   * Name attribute of the `ColumnInputs` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: ArrayPath<TFormValues>;
  /**
   * The minimum number of columns that the user can add.
   * @default 1
   */
  minColumns?: number;
  /**
   * Label text for the `ColumnInputs` component.
   */
  label?: string;
  /**
   * Label text for the column name input.
   */
  columnNameInputLabel?: string;
  /**
   * The short hint displayed in the column name input before the user enters a value.
   */
  columnNameInputPlaceholder?: string;
  /**
   * Label text for the column value input.
   */
  columnValueInputLabel?: string;
  /**
   * The short hint displayed in the column value input before the user enters a value.
   */
  columnValueInputPlaceholder?: string;
  /**
   * The content of the add column button.
   */
  addColumnButtonText?: string;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * `ColumnInputs` interactive element in association with `react-hook-form` library made for `ExerciseConstructor` component.
 */
export const ColumnInputs = <
  TFormValues extends TColumnInputsDefaultValues = TColumnInputsDefaultValues
>(
  props: TColumnInputsProps<TFormValues>
): JSX.Element => {
  const {
    name,
    label,
    minColumns = 1,
    columnNameInputLabel = ColumnInputsContent.Default.Name.LABEL,
    columnNameInputPlaceholder = ColumnInputsContent.Default.Name.PLACEHOLDER,
    columnValueInputLabel = ColumnInputsContent.Default.Value.LABEL,
    columnValueInputPlaceholder = ColumnInputsContent.Default.Value.PLACEHOLDER,
    addColumnButtonText = ColumnInputsContent.Default.Button.ADD,
    control,
    register,
    errors,
    className,
  } = props;

  const { fields, remove, append } = useFieldArray<TFormValues>({
    control,
    name,
  });

  const addColumnClickHandler = () => {
    append(
      getColumnInitialValues() as FieldArray<
        TFormValues,
        ArrayPath<TFormValues>
      >
    );
  };

  const removeColumnClickHandler = (columnIndex: number) => {
    remove(columnIndex);
  };

  const firstFieldId: string = useMemo(() => {
    const [firstField] = fields;
    return firstField?.id || "";
  }, [fields]);

  return (
    <Space
      direction="vertical"
      justify="start"
      size="medium"
      fullWidth
      className={className}
    >
      {label && (
        <label
          className="nb-interactive-column-inputs-label"
          htmlFor={`column-inputs-${firstFieldId}`}
        >
          {label}
        </label>
      )}
      <ColumnList<TFormValues>
        name={name}
        fields={fields}
        register={register}
        errors={errors}
        minColumns={minColumns}
        columnNameInputLabel={columnNameInputLabel}
        columnNameInputPlaceholder={columnNameInputPlaceholder}
        columnValueInputLabel={columnValueInputLabel}
        columnValueInputPlaceholder={columnValueInputPlaceholder}
        onDelete={removeColumnClickHandler}
      />
      <Button variant="secondary" fullWidth onClick={addColumnClickHandler}>
        {addColumnButtonText}
      </Button>
    </Space>
  );
};
