import { NoticeProps, components } from "react-select";
import type { TSelectDefaultOption } from "../../Select.types";
import "./NoOptionsMessage.styles.scss";

type NoOptionsMessageProps<
  Option extends TSelectDefaultOption = TSelectDefaultOption
> = {
  noOptionsText: string;
} & NoticeProps<Option, false>;

export const NoOptionsMessage = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>(
  props: NoOptionsMessageProps<Option>
): JSX.Element => {
  const { noOptionsText } = props;

  return (
    <components.NoOptionsMessage
      {...props}
      className="nb-interactive-select-no-options"
    >
      {noOptionsText ? noOptionsText : props.children}
    </components.NoOptionsMessage>
  );
};
