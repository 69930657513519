import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { LandingDashboardContent } from "content";
import { Space } from "components/Common/Space";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { Tooltip } from "components/Interactive/Tooltip";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { getRowSequenceNumber } from "utils/table";
import { TAdminLandingOffer } from "types/api/admin";

type TUseStudentColumns = (options?: {
  onDeleteClick?: (id: number) => void;
  onEditClick?: (offer: TAdminLandingOffer) => void;
}) => ColumnDef<TAdminLandingOffer, string>[];

export const useLandingOffersColumns: TUseStudentColumns = (options) => {
  const { onDeleteClick, onEditClick } = options || {};

  const columnHelper = createColumnHelper<TAdminLandingOffer>();

  const columns: ColumnDef<TAdminLandingOffer, string>[] = [
    columnHelper.accessor(() => "index", {
      id: "index",
      header: LandingDashboardContent.Table.Header.NUMBER,
      cell: (info) =>
        getRowSequenceNumber(info.row.index, info.table.getState().pagination),
      size: 0.05,
    }),
    columnHelper.accessor(() => "type", {
      id: "type",
      header: LandingDashboardContent.Table.Header.TYPE,
      cell: ({ row: { original } }) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={original.type}
          spaceSize="small"
          variant="compact"
        >
          {original.type}
        </Tooltip>
      ),
      size: 0.15,
    }),
    columnHelper.accessor(() => "description", {
      id: "description",
      header: LandingDashboardContent.Table.Header.DESCRIPTION,
      cell: ({ row: { original } }) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={original.description.join("\n")}
          spaceSize="small"
          variant="compact"
        >
          {original.description[0] || "Не вказано"}
        </Tooltip>
      ),
      size: 0.4,
    }),
    columnHelper.accessor(() => "price", {
      id: "price",
      header: LandingDashboardContent.Table.Header.PRICE,
      cell: ({ row: { original } }) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={original.options.map((item) => `${item.price} грн`).join("\n")}
          spaceSize="small"
          variant="compact"
        >
          {`Від ${original.options.map((item) => item.price)[0]} грн`}
        </Tooltip>
      ),
      size: 0.2,
    }),
    columnHelper.accessor(() => "action", {
      id: "action",
      header: LandingDashboardContent.Table.Header.ACTION,
      cell: ({ row }) => (
        <Space
          direction="horizontal"
          size="small"
          justify="start"
          align="center"
        >
          <Button onClick={() => onEditClick?.(row.original)}>
            {LandingDashboardContent.Table.Button.EDIT}
          </Button>
          <IconButton
            icon={<Delete />}
            onClick={() => onDeleteClick?.(row.original.id)}
          />
        </Space>
      ),
      size: 140,
    }),
  ];

  return columns;
};
