import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useAddCourseLessonChapter } from "hooks/api/courses";
import { LessonDetailsContent } from "content";
import { Input } from "components/Interactive/Input";
import { Button } from "components/Interactive/Button";
import { ReactPortal } from "components/Common/ReactPortal";
import { logError, logSuccess } from "utils/notifications";
import type { TAddLessonChapterForm } from "./AddLessonChapterForm.types";
import "./AddLessonChapterForm.styles.scss";

type AddLessonChapterFormProps = {
  /**
   * Callback function fires on add chapter submit event.
   */
  onSubmit?: (chapterId: number) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const AddLessonChapterForm: React.FC<AddLessonChapterFormProps> = (
  props
) => {
  const { onSubmit, className } = props;

  const idRef = useRef<string>(nanoid());

  const { courseId, lessonId } = useParams();

  const { mutate: addLessonChapterHandler } = useAddCourseLessonChapter(
    courseId,
    lessonId
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddLessonChapterForm>({
    mode: "onBlur",
    defaultValues: { name: "" },
  });

  const handleAddChapterSubmit = (formData: TAddLessonChapterForm) => {
    if (!courseId && !lessonId) {
      logError({ message: LessonDetailsContent.Add.Notification.ERROR });
      return;
    }
    const lessonChapter = {
      courseId: Number(courseId),
      name: formData.name,
    };

    addLessonChapterHandler(lessonChapter, {
      onSuccess: (chapterData) => {
        onSubmit?.(chapterData.id);
        logSuccess(LessonDetailsContent.Add.Notification.SUCCESS);
        reset({ name: "" });
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-lesson-details-add-chapter-form", className])}
      onSubmit={handleSubmit(handleAddChapterSubmit)}
    >
      <Input<TAddLessonChapterForm>
        name="name"
        id="add-lesson-chapter-name"
        label={LessonDetailsContent.Add.Input.Name.LABEL}
        placeholder={LessonDetailsContent.Add.Input.Name.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <ReactPortal wrapperId="add-course-lesson-chapter-modal-footer">
        <Button variant="primary" type="submit" form={idRef.current} fullWidth>
          {LessonDetailsContent.Add.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
