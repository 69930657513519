import { matchRoutes, useLocation } from "react-router-dom";
import { breadcrumbs } from "constants/breadcrumbs";

type TUseCurrentPath = () => string | null;

export const useCurrentPath: TUseCurrentPath = () => {
  const routes = breadcrumbs.map((breadcrumb) => ({
    path: breadcrumb.route.path,
  }));
  const location = useLocation();
  const route = matchRoutes(routes, location);

  if (route) {
    const [routeMatch] = route;
    return routeMatch.route.path;
  }

  return null;
};
