import { useQuery } from "react-query";
import { fetchGroups } from "api/services/groups";
import { EGroupsQueryKeys } from "api/endpoints/groups";
import { logError } from "utils/notifications";
import { TAllGroups, TGroupsOptions } from "types/api/groups";
import { TApiError } from "types/api/error";

export const useGroups = (options?: TGroupsOptions) => {
  const {
    searchText = "",
    pageNumber = 0,
    pageSize = 10,
    blocked = false,
  } = options || {};
  return useQuery<TAllGroups, TApiError>(
    [EGroupsQueryKeys.getGroups, searchText, pageNumber, pageSize, blocked],
    () => fetchGroups(searchText, pageNumber, pageSize),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: !blocked,
    }
  );
};
