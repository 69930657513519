import { CourseDetailsContent } from "content";
import { Heading } from "components/Typography/Heading";
import { Paragraph } from "components/Typography/Paragraph";
import { Text } from "components/Typography/Text";
import "./CourseHeroContent.styles.scss";

type CourseHeroContentProps = {
  /**
   * Course name string to display.
   * @default 'Курс'
   */
  name?: string;
  /**
   * Course description data to display, if any provided.
   * @default null
   */
  description?: string | null;
};

export const CourseHeroContent: React.FC<CourseHeroContentProps> = (props) => {
  const { name = CourseDetailsContent.Name, description = null } = props;

  return (
    <div className="nb-sub-materials-details-content">
      <Heading level={2} className="nb-sub-materials-details-heading">
        <Text className="nb-sub-materials-details-heading--emoji">
          {CourseDetailsContent.Hero.Content.Heading.EMOJI}
        </Text>
        {name}
      </Heading>
      {description && (
        <Paragraph variant={2} className="nb-sub-materials-details-paragraph">
          {description}
        </Paragraph>
      )}
    </div>
  );
};
