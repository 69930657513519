import { useMutation, useQueryClient } from "react-query";
import { removeStudent } from "api/services/students";
import { EStudentsQueryKeys } from "api/endpoints/students";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useRemoveStudent = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(removeStudent, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStudentsQueryKeys.getStudents);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
