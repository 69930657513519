import { useQuery } from "react-query";
import { fetchStudents } from "api/services/students";
import { EStudentsQueryKeys } from "api/endpoints/students";
import { logError } from "utils/notifications";
import { TStudentResponse, TStudentsOptions } from "types/api/student";
import { TApiError } from "types/api/error";

export const useStudents = (options?: TStudentsOptions) => {
  const {
    searchText = "",
    pageNumber = 0,
    pageSize = 10,
    blocked = false,
    teacherId = null,
  } = options || {};
  return useQuery<TStudentResponse, TApiError>(
    [EStudentsQueryKeys.getStudents, searchText, pageNumber, pageSize, blocked],
    () => fetchStudents(searchText, pageNumber, pageSize, teacherId),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: !blocked,
    }
  );
};
