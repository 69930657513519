import {
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useState,
} from "react";
import cx from "classnames";
import {
  FieldError,
  FieldErrorsImpl,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import { ValidationContent } from "content";
import { mergeRefs } from "utils/react";
import "./Input.styles.scss";

type InputProps<TFormValues extends Record<string, unknown>> = {
  /**
   * Name attribute of the `input` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: Path<TFormValues>;
  /**
   * The id of the `input` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the `input` element is required.
   */
  required?: boolean;
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * Input label text for the `input` element.
   */
  label?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   * @default 'text'
   */
  type?: string;
  /**
   * If `true`, the `input` will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the `input` element is focused during the first mount.
   */
  autoFocus?: boolean;
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete?: string;
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * 	Validation rules in the same format for register, which includes: required, min, max, minLength, maxLength, pattern, validate
   *
   * @example
   * ```tsx
   * rules={{
   *  pattern: {
   *    value: EMAIL_REGEX,
   *    message: "Email address is invalid",
   *  }
   * }}
   * ```
   * @link https://react-hook-form.com/api/useform/register#options
   */
  rules?: RegisterOptions;
  /**
   * 	An object with field errors. There is also an ErrorMessage component to retrieve error message easily.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Icon to display as `input` prefix.
   */
  leftIcon?: React.ReactElement;
  /**
   * Icon to display as `input` suffix.
   */
  rightIcon?: React.ReactElement;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input = <TFormValues extends Record<string, unknown>>(
  props: InputProps<TFormValues>,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
  const {
    className,
    disabled = false,
    placeholder,
    required = false,
    name,
    id,
    type = "text",
    fullWidth = false,
    autoFocus,
    autoComplete,
    label,
    readOnly = false,
    errors,
    register,
    rules,
    leftIcon,
    rightIcon,
    ...other
  } = props;

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const targetError: FieldError | null = name
    .split(".")
    .reduce(
      (prev, curr) => prev?.[curr as keyof FieldError] as unknown as FieldError,
      (errors || null) as unknown as FieldError | null
    );
  const hasError = !!(errors && targetError);

  const innerRules: RegisterOptions = {
    ...(required && { required: ValidationContent.Required }),
    ...rules,
    disabled,
  };

  const {
    onChange,
    ref: registerRef,
    ...registerProps
  } = register?.(name, innerRules) || {};

  const handleInputChange = (evt: SyntheticEvent<HTMLInputElement>) => {
    setIsTouched(true);
    onChange?.(evt);
  };

  useEffect(() => {
    if (isTouched && !hasError) {
      setIsSuccess(true);
    }

    if (hasError) {
      setIsSuccess(false);
    }
  }, [isTouched, hasError]);

  return (
    <div
      className={cx(["nb-interactive-input-wrapper", className])}
      aria-live="polite"
    >
      {label && (
        <label className="nb-interactive-input-label" htmlFor={id}>
          {label}
        </label>
      )}
      <div
        className={cx([
          "nb-interactive-input-holder",
          {
            "nb-interactive-input-holder--full": fullWidth,
          },
        ])}
      >
        {leftIcon && (
          <div
            className={cx([
              "nb-interactive-input-icon",
              "nb-interactive-input-icon--left",
              { "nb-interactive-input-icon--fade": disabled },
            ])}
          >
            {leftIcon}
          </div>
        )}
        <input
          ref={mergeRefs([ref, ...(registerRef ? [registerRef] : [])])}
          aria-invalid={hasError}
          aria-label={label}
          id={id}
          placeholder={placeholder}
          required={required}
          type={type}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          readOnly={readOnly}
          className={cx([
            "nb-interactive-input",
            {
              "nb-interactive-input--success": isSuccess,
              "nb-interactive-input--error": hasError,
              "nb-interactive-input--prefix": leftIcon,
              "nb-interactive-input--suffix": rightIcon,
            },
          ])}
          onChange={handleInputChange}
          {...registerProps}
          {...other}
        />
        {rightIcon && (
          <div
            className={cx([
              "nb-interactive-input-icon",
              "nb-interactive-input-icon--right",
              { "nb-interactive-input-icon--fade": disabled },
            ])}
          >
            {rightIcon}
          </div>
        )}
      </div>
      {hasError && (
        <small className="nb-interactive-input-error-text">
          {targetError?.message?.toString()}
        </small>
      )}
    </div>
  );
};

/**
 * Input interactive element based on `react-hook-form` library
 */
export const ForwardedInput = forwardRef(Input) as <
  TFormValues extends Record<string, unknown>
>(
  props: InputProps<TFormValues> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof Input>;
