import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const describeGifExercise: TExerciseItem = {
  title: "Гіф",
  backgroundText: "Гіфка",
  tags: [
    "гіф",
    "гіфка",
    "гіфку",
    "описати",
    "написати",
    "gif",
    "image",
    "picture",
    "photo",
    "describe",
  ],
  type: EExerciseType.GIF,
  cover: "describe-gif.png",
};
