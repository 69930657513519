import { sha256 } from "js-sha256";
import { get, post } from "api";
import { AUTH_ENDPOINTS } from "api/endpoints/auth";
import { TLogin, TLoginCredentials } from "types/api/auth";

export const fetchCurrentUser = () => get<TLogin>(AUTH_ENDPOINTS.CURRENT_USER);

export const loginUser = async (
  formData: TLoginCredentials
): Promise<TLogin> => {
  const requestOject = {
    email: formData.email,
    password: sha256(formData.password),
  };
  return post<TLogin>(AUTH_ENDPOINTS.LOGIN, requestOject);
};

export const logoutUser = async (): Promise<void> => {
  return post(AUTH_ENDPOINTS.LOGOUT);
};
