export const MoveWordsIntoGaps = {
  Add: {
    Input: {
      Condition: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Text: {
        LABEL: "Текст вправи",
        PLACEHOLDER: "I like [walking] in the park in the morning.",
        HINT: "Підказка\n Напишіть текст. Слова та фрази, які потрібно вставити з рамочки, покладіть у квадратні дужки. \n\nПриклад:\n I am [walking] in the park in the morning.",
      },
      Wrap: {
        LABEL: "Переносити текст на новий рядок після кожного речення",
      },
    },
  },
  EMPTY: "Ви перетягнули всі слова...",
};
