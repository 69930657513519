/**
 * Utility function that helps to omit `undefined` and `null` types from the argument.
 * Good for cases when you are sure that the argument is not `undefined` or `null`. For example in `Array.prototype.find` method.
 * @see https://stackoverflow.com/a/54738437
 * @param argument generic argument.
 * @param message error message.
 * @returns the argument without `undefined` and `null` types.
 */
export const ensure = <T>(
  argument: T | undefined | null,
  message = "This value was promised to be there."
): T => {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
};
