import { get, post, put, remove } from "api";
import { SCHEDULE_ENDPOINTS } from "api/endpoints/schedule";
import {
  TAddLessonBody,
  TEditLessonBody,
  TLesson,
  TLessonFilter,
  TLessonResponse,
} from "types/api/lesson";

export const getScheduleLessons = (
  options: TLessonFilter
): Promise<TLessonResponse> => {
  const { teacherId, studentId, groupId, start, end, type } = options;

  return get<TLessonResponse>(SCHEDULE_ENDPOINTS.SCHEDULE, {
    params: {
      teacherId,
      studentId,
      groupId,
      start,
      end,
      type,
    },
  });
};

export const addScheduleLesson = (body: TAddLessonBody): Promise<TLesson> =>
  post<TLesson>(SCHEDULE_ENDPOINTS.SCHEDULE, body);

export const editScheduleLesson = (body: TEditLessonBody): Promise<TLesson> =>
  put<TLesson>(SCHEDULE_ENDPOINTS.SCHEDULE, body);

export const removeScheduleLesson = (id: number): Promise<void> =>
  remove<undefined>(`${SCHEDULE_ENDPOINTS.SCHEDULE}/${id}`);
