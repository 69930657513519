import { StudentGlobalContent } from "content";

/**
 * Simple function to get correct word based on number of students.
 * @param amount - number of students.
 * @returns correct plural word for student.
 */
export const getStudentPlural = (amount = 0): string => {
  const remainder = amount % 10;

  const singleNumbers = [1];
  const manyNumbers = [2, 3, 4];
  const muchNumbers = [5, 6, 7, 8, 9, 0];
  const outRuleNumbers = [11, 12, 13, 14];

  const inNone = amount === 0;
  const isOutRuleAmount = outRuleNumbers.includes(amount);
  const isSingle = singleNumbers.includes(remainder) && !isOutRuleAmount;
  const isMany = manyNumbers.includes(remainder) && !isOutRuleAmount;
  const isMuch =
    !inNone && (muchNumbers.includes(remainder) || isOutRuleAmount);

  if (isSingle) {
    return `${amount} ${StudentGlobalContent.SINGLE}`;
  }
  if (isMany) {
    return `${amount} ${StudentGlobalContent.MANY}`;
  }
  if (isMuch) {
    return `${amount} ${StudentGlobalContent.MUCH}`;
  }
  return StudentGlobalContent.NONE;
};
