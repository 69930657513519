import { EUserRole } from "types/api/role";

export const isAdmin = (role?: EUserRole) => {
  return role === EUserRole.ADMIN;
};

export const isTeacher = (role?: EUserRole) => {
  return role === EUserRole.TEACHER;
};

export const isStudent = (role?: EUserRole) => {
  return role === EUserRole.STUDENT;
};
