import cx from "classnames";
import "./CoverButton.styles.scss";

type TCoverButtonProps = {
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * Button mouse click handler function
   *
   * @param {object} event - mouse event
   * @return {void} should be voided function
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const CoverButton: React.FC<TCoverButtonProps> = (props) => {
  const { disabled = false, className, children, onClick } = props;

  const handleClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (disabled) {
      evt.preventDefault();
      return;
    }
    onClick?.(evt);
  };

  return (
    <button
      type="button"
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      className={cx(["nb-interactive-cover-button", className])}
      onClick={handleClick}
    >
      <span className="nb-interactive-cover-button-content">{children}</span>
    </button>
  );
};
