import { useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { CourseDetailsContent } from "content";
import { Avatar } from "components/Common/Avatar";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { Tooltip } from "components/Interactive/Tooltip";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { getRowSequenceNumber } from "utils/table";
import { TCourseLesson } from "types/api/course";

type TUseCourseLessonColumns = (
  courseId?: string,
  options?: {
    onDeleteClick?: (courseLessonId: number) => void;
    onEditClick?: (courseLesson: TCourseLesson) => void;
  }
) => ColumnDef<TCourseLesson, string>[];

export const useCourseLessonColumns: TUseCourseLessonColumns = (
  courseId,
  options
) => {
  const { onDeleteClick, onEditClick } = options || {};

  const columnHelper = createColumnHelper<TCourseLesson>();

  const columns: ColumnDef<TCourseLesson, string>[] = useMemo(
    () => [
      columnHelper.accessor(() => "index", {
        id: "index",
        cell: (info) =>
          getRowSequenceNumber(
            info.row.index,
            info.table.getState().pagination
          ),
        size: 0.1,
      }),
      columnHelper.accessor(() => "avatar", {
        id: "avatar",
        cell: (info) => (
          <Avatar bordered size="small" image={info.row.original.imageUrl} />
        ),
        size: 0.15,
      }),
      columnHelper.accessor(() => "name", {
        id: "name",
        cell: (info) => (
          <Tooltip
            mouseEnterDelay={1000}
            text={info.row.original.name}
            spaceSize="small"
            variant="compact"
          >
            {info.row.original.name}
          </Tooltip>
        ),
        size: 0.6,
      }),
      columnHelper.accessor(() => "action", {
        id: "action",
        cell: ({ row }) => (
          <div className="nb-page-materials-details-action">
            <IconButton
              className="nb-page-materials-details-action-edit"
              icon={<Edit />}
              onClick={() => onEditClick?.(row.original)}
            />
            <IconButton
              className="nb-page-materials-details-action-delete"
              icon={<Delete />}
              onClick={() => onDeleteClick?.(row.original.id)}
            />
            <Button
              variant="link"
              to={`/materials/course/${courseId}/lesson/${row.original.id}`}
            >
              {CourseDetailsContent.Table.Button.OPEN}
            </Button>
          </div>
        ),
        size: 191,
      }),
    ],
    // Adding only courseId as dependency to prevent avatar file refetch on every rerender
    [courseId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return columns;
};
