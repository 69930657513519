import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { Text } from "components/Typography/Text";
import { ClueInput } from "components/Interactive/ClueInput";
import {
  TExerciseIdentifier,
  TExerciseSentence,
  TInputWordsIntoGapsExerciseBlank,
} from "types/app/exercises";
import "./SentenceItem.styles.scss";

type TSentenceItemProps = {
  /**
   * A sentence data to construct an exercise.
   */
  sentence: TExerciseSentence<TInputWordsIntoGapsExerciseBlank>;
  /**
   * A function to handle a change of the sentence.
   */
  onSentenceChange?: (
    sentence: TExerciseSentence<TInputWordsIntoGapsExerciseBlank>
  ) => void;
};

export const SentenceItem: React.FC<TSentenceItemProps> = (props) => {
  const { sentence, onSentenceChange } = props;

  const [inputValues, setInputValues] = useState<
    Record<TExerciseIdentifier, string>
  >({});

  const { isExerciseReadOnlyMode, isExercisePreviewMode, isExerciseLiveMode } =
    useLessonSettings();

  useEffect(() => {
    if (isExercisePreviewMode) {
      const filledInputValues = sentence.chunks.reduce(
        (acc, chunk) =>
          chunk.blank === null
            ? {
                ...acc,
              }
            : {
                ...acc,
                [chunk.blank.id]: chunk.blank.currentAnswer?.content || "",
              },
        {}
      );
      setInputValues(filledInputValues);
    }
    if (isExerciseLiveMode) {
      const emptyInputValues = sentence.chunks.reduce(
        (acc, chunk) =>
          chunk.blank === null
            ? {
                ...acc,
              }
            : {
                ...acc,
                [chunk.blank.id]: chunk.blank.isCorrectAnswer
                  ? inputValues[chunk.blank.id] || ""
                  : "",
              },
        {}
      );
      setInputValues(emptyInputValues);
    }
  }, [isExercisePreviewMode, isExerciseLiveMode, sentence]);

  const clueSelectChangeHandler = (value: string, blankId: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [blankId]: value,
    }));
  };

  const clueSelectBlurHandler = (value: string, blankId: string) => {
    if (!value) {
      return;
    }
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [blankId]: value,
    }));

    const [targetAnswer] = sentence.chunks.flatMap(
      (chunk) =>
        chunk.blank?.answers.find(
          (answer) =>
            answer.content.trim().toLowerCase() === value.trim().toLowerCase()
        ) || []
    );

    const isCorrectAnswer = Boolean(targetAnswer);
    const currentAnswer = targetAnswer || {
      id: nanoid(),
      content: value,
    };

    const updatedSentence = {
      ...sentence,
      chunks: sentence.chunks.map((chunk) => ({
        ...chunk,
        ...(chunk.blank?.id === blankId && {
          blank: {
            ...chunk.blank,
            currentAnswer,
            isEmpty: false,
            isCorrectAnswer,
            isAllAttemptsFailed:
              !isCorrectAnswer &&
              chunk.blank.currentAttempt + 1 >= chunk.blank.maxAttempts,
            currentAttempt: chunk.blank?.currentAttempt + 1,
            attempts: [...chunk.blank.attempts, currentAnswer],
          },
        }),
      })),
    };
    onSentenceChange?.(updatedSentence);
  };

  return (
    <Space
      direction="horizontal"
      align="center"
      justify="start"
      size={["xx-small", "x-small"]}
      wrap
    >
      {sentence.chunks.map((chunk) =>
        chunk.blank !== null ? (
          <ClueInput
            key={chunk.id}
            type="text"
            name={chunk.blank.id.toString()}
            id={chunk.blank.id.toString()}
            hint={chunk.blank.answers
              .map((answer) => answer.content)
              .join("\n")}
            placeholder={chunk.blank.hint || ""}
            error={chunk.blank.isAllAttemptsFailed}
            success={chunk.blank.isCorrectAnswer}
            disabled={
              chunk.blank.isCorrectAnswer ||
              chunk.blank.isAllAttemptsFailed ||
              isExerciseReadOnlyMode
            }
            value={
              chunk.blank.isAllAttemptsFailed
                ? chunk.blank.answers[0]?.content || inputValues[chunk.blank.id]
                : inputValues[chunk.blank.id]
            }
            required
            fitValue
            onBlur={clueSelectBlurHandler}
            onChange={clueSelectChangeHandler}
            style={{ maxWidth: "unset" }}
          />
        ) : (
          <Text key={chunk.id} variant="body2">
            {chunk.text?.content}
          </Text>
        )
      )}
    </Space>
  );
};
