import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UserSettingsContent } from "content";
import { ReactComponent as ExclamationIcon } from "assets/icons/exclamation.svg";
import { useLanguage } from "hooks/api/language";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { useEditProfile } from "hooks/api/profile";
import { useModal } from "hooks/common";
import { Input } from "components/Interactive/Input";
import { Select } from "components/Interactive/Select";
import { Space } from "components/Common/Space";
import { ChangePasswordModal } from "../ChangePasswordModal";
import { logSuccess } from "utils/notifications";
import { TSelectOption } from "types/app/select";
import { TUser } from "types/api/user";
import { TProfileForm } from "./ProfileForm.types";
import "./ProfileForm.styles.scss";

export const ProfileForm: React.FC = () => {
  const { activeUser } = useActiveUser();

  const { mutate: editProfile } = useEditProfile();

  const { isModalOpen, closeModal, openModal } = useModal(false);

  const { data: languageData, isSuccess: isLanguageSuccess } = useLanguage();
  const languageOptions: TSelectOption[] =
    languageData?.map((lang) => ({
      label: lang,
      value: lang,
    })) || [];
  const initialLanguageOption = languageOptions.find(
    (lang) => lang.value === activeUser?.language
  );

  const {
    register,
    control,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<TProfileForm>({
    mode: "onBlur",
    defaultValues: {
      email: activeUser?.email,
      firstName: activeUser?.firstName,
      lastName: activeUser?.lastName,
      phone: activeUser?.phone,
      language: initialLanguageOption,
    },
  });

  useEffect(() => {
    const isLanguageFieldTouched = !!touchedFields.language;
    if (isLanguageSuccess && !isLanguageFieldTouched && initialLanguageOption) {
      setValue("language", initialLanguageOption);
    }
  }, [
    isLanguageSuccess,
    touchedFields.language,
    initialLanguageOption,
    setValue,
  ]);

  const userDetailsBlurHandler = (
    formData: TProfileForm,
    event?: React.BaseSyntheticEvent
  ) => {
    const targetName = event?.target.name as keyof TProfileForm;
    if (!activeUser || !targetName) {
      return;
    }

    if (activeUser[targetName] === formData[targetName]) {
      return;
    }

    const targetValue = formData[targetName];

    const user: TUser = {
      ...activeUser,
      [targetName]:
        typeof targetValue === "string" ? targetValue : targetValue?.value,
    };
    editProfile(user, {
      onSuccess: (updatedUser) => {
        logSuccess(UserSettingsContent.Form.Notification.SUCCESS);
        resetField(targetName, {
          defaultValue: updatedUser[targetName],
          keepTouched: false,
          keepError: false,
          keepDirty: false,
        });
      },
    });
  };

  return (
    <>
      <form
        className="nb-page-user-settings-form"
        onBlur={handleSubmit(userDetailsBlurHandler)}
      >
        <Space direction="vertical" size="small" justify="start" fullWidth>
          <Input<TProfileForm>
            id="user-profile-first-name"
            type="text"
            name="firstName"
            label={UserSettingsContent.Form.Input.FirstName.LABEL}
            placeholder={UserSettingsContent.Form.Input.FirstName.PLACEHOLDER}
            register={register}
            errors={errors}
            fullWidth
            required
          />
          <Input<TProfileForm>
            id="user-profile-last-name"
            type="text"
            name="lastName"
            label={UserSettingsContent.Form.Input.LastName.LABEL}
            placeholder={UserSettingsContent.Form.Input.LastName.PLACEHOLDER}
            register={register}
            errors={errors}
            fullWidth
            required
          />
          <Input<TProfileForm>
            id="user-profile-email"
            type="email"
            name="email"
            label={UserSettingsContent.Form.Input.Email.LABEL}
            placeholder={UserSettingsContent.Form.Input.Email.PLACEHOLDER}
            register={register}
            errors={errors}
            leftIcon={
              <ExclamationIcon className="nb-page-user-settings-form-input-icon" />
            }
            fullWidth
            readOnly
            required
          />
          <Select<TProfileForm, TSelectOption>
            name="language"
            id="user-profile-language"
            label={UserSettingsContent.Form.Input.Language.LABEL}
            placeholder={UserSettingsContent.Form.Input.Language.PLACEHOLDER}
            options={languageOptions}
            control={control}
            errors={errors}
            fullWidth
          />
          <button
            type="button"
            className="nb-page-user-settings-form-button"
            onClick={openModal}
          >
            {UserSettingsContent.Form.Button.CHANGE_PASSWORD}
          </button>
        </Space>
      </form>
      <ChangePasswordModal open={isModalOpen} onClose={closeModal} />
    </>
  );
};
