import { NoticeProps, components } from "react-select";
import { TSelectOption } from "types/app/select";
import "./NoOptionsMessage.styles.scss";

type NoOptionsMessageProps<
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
> = {
  noOptionsText: string;
} & NoticeProps<Option, false>;

export const NoOptionsMessage = <
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>(
  props: NoOptionsMessageProps<Option>
): JSX.Element => {
  const { noOptionsText } = props;

  return (
    <components.NoOptionsMessage
      {...props}
      className="nb-interactive-search-no-options"
    >
      {noOptionsText ? noOptionsText : props.children}
    </components.NoOptionsMessage>
  );
};
