export const Login = {
  Admin: {
    Title: "Хей 👋",
    Text: "Раді бачити тебе \nу not_botan_school!",
  },
  Form: {
    Field: {
      Email: "Email",
      Password: "Password",
    },
    Label: {
      Email: "Ваша електронна адреса",
      Password: "Ваш пароль",
    },
    Button: {
      ResetPassword: "Забули пароль?",
      Submit: "Увійти",
    },
  },
};
