import { useNavigate } from "react-router-dom";
import { Space } from "components/Common/Space";
import { Heading } from "components/Typography/Heading";
import { Paragraph } from "components/Typography/Paragraph";
import { Button } from "components/Interactive/Button";
import { Route } from "constants/routes";
import "./Admin.styles.scss";

export const AdminPage: React.FC = () => {
  const navigate = useNavigate();

  const landingDashboardClickHandler = () => {
    navigate(Route.App.Admin.LANDING.path);
  };

  const homeClickHandler = () => {
    navigate(Route.App.Schedule.ROOT.path);
  };

  return (
    <Space
      direction="vertical"
      size="large"
      justify="start"
      align="start"
      fullWidth
      className="nb-page-admin-container"
    >
      <Heading bold level={2} font="ptmono">
        Привіт, Адміністратор! 👋
      </Heading>
      <div className="nb-page-admin-wrapper">
        <Space
          justify="between"
          align="center"
          direction="horizontal"
          size="large"
          fullWidth
        >
          <Space
            direction="vertical"
            size="xx-small"
            justify="start"
            align="start"
          >
            <Paragraph variant={1} bold>
              Управління видами занять та цінами доступно в розділі
              &quot;Налаштування лендінгу&quot;.
            </Paragraph>
            <Paragraph variant={2}>
              Наразі на цій сторінці більше нічого немає, але скоро тут буде
              більше 🤯
            </Paragraph>
          </Space>
          <Space
            direction="horizontal"
            size="xx-small"
            justify="start"
            align="start"
          >
            <Button
              variant="primary"
              type="button"
              onClick={landingDashboardClickHandler}
            >
              Налаштування&nbsp;лендінгу
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={homeClickHandler}
            >
              Головна
            </Button>
          </Space>
        </Space>
      </div>
    </Space>
  );
};
