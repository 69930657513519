export const TestExercise = {
  Add: {
    Input: {
      Condition: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Timer: {
        LABEL: "Таймер",
        PLACEHOLDER: "Оберіть значення таймера",
      },
      TestQuestions: {
        QUESTION_LABEL: "Питання",
        QUESTION_PLACEHOLDER: "Питання №",
        ANSWER_LABEL: "Відповіді",
        ANSWER_PLACEHOLDER: "Відповідь №",
        ADD_QUESTION_TEXT: "Додати питання",
        ADD_ANSWER_TEXT: "Додати відповідь",
      },
    },
  },
};
