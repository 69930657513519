import { useMutation, useQueryClient } from "react-query";
import { addStudent } from "api/services/students";
import { EStudentsQueryKeys } from "api/endpoints/students";
import { logError } from "utils/notifications";
import { TStudent, TStudentAddRequestBody } from "types/api/student";
import { TApiError } from "types/api/error";

export const useAddStudent = () => {
  const queryClient = useQueryClient();
  return useMutation<TStudent, TApiError, TStudentAddRequestBody>(addStudent, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStudentsQueryKeys.getStudents);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
