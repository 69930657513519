import { nanoid } from "nanoid";
import {
  BRACKET_CONTENT,
  MATCH_NEWLINE,
  SENTENCE_SEPARATOR,
} from "constants/regexp";
import { shuffleArray } from "utils/common";
import {
  TExerciseSentence,
  TExerciseSentenceAnswer,
  TExerciseSentenceChunk,
  TExerciseSentenceText,
  TMoveWordsIntoGapsExerciseBlank,
  TMoveWordsIntoGapsExerciseData,
} from "types/app/exercises";

export const MAX_ANSWER_ATTEMPTS = 3;

/**
 * Utility function that helps to adapt raw exercise string to structured data which makes easier to work with drag and drop operations.
 * @param exerciseText raw exercise string
 * @returns object of structured `move words into blanks` exercise data.
 */
export const adaptExerciseStringToData = (
  exerciseText: string,
  isWrapOnSentence: boolean
): TMoveWordsIntoGapsExerciseData => {
  const sentences: TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[] = [];
  const answers: TExerciseSentenceAnswer[] = [];

  const allSentences = exerciseText
    .split(isWrapOnSentence ? SENTENCE_SEPARATOR : MATCH_NEWLINE)
    .map((item) => item.trim())
    .filter(Boolean)
    .filter((item) => item.length > 1);

  for (const sentence of allSentences) {
    const rawAnswers = [...(sentence.match(BRACKET_CONTENT) || [])];
    const inputSentence = rawAnswers.reduce(
      (acc, answer) => acc.replace(answer, "[]"),
      sentence
    );
    const splitSentence = sentence
      .split(BRACKET_CONTENT)
      .map((item) => item.trim())
      .filter(Boolean);

    const sentenceChunks: TExerciseSentenceChunk<TMoveWordsIntoGapsExerciseBlank>[] =
      [];

    for (const chunk of splitSentence) {
      const isBlank = chunk.startsWith("[") && chunk.endsWith("]");
      const content = isBlank ? chunk.slice(1, chunk.length - 1) : chunk;

      let blank: TMoveWordsIntoGapsExerciseBlank | null = null;
      let text: TExerciseSentenceText | null = null;

      if (isBlank) {
        const answer: TExerciseSentenceAnswer = {
          id: nanoid(),
          content,
        };
        answers.push(answer);
        blank = {
          id: nanoid(),
          acceptAnswerIds: [],
          attempts: [],
          correctAnswer: answer,
          currentAnswer: null,
          currentAttempt: 0,
          maxAttempts: MAX_ANSWER_ATTEMPTS,
          isEmpty: true,
          isCorrectAnswer: false,
          isAllAttemptsFailed: false,
          hint: null, // TODO: pass hint data when API will provide this field
        };
      }

      if (!isBlank) {
        text = {
          id: nanoid(),
          content,
        };
      }

      sentenceChunks.push({
        id: nanoid(),
        raw: chunk,
        isBlank,
        blank,
        text,
      });
    }

    sentences.push({
      id: nanoid(),
      raw: sentence,
      inputSentence,
      splitSentence,
      chunks: sentenceChunks,
    });
  }

  const allAnswerIds = answers.map((answer) => answer.id);
  const completeSentences: TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[] =
    sentences.map((sentence) => ({
      ...sentence,
      chunks: sentence.chunks.map(({ blank, ...chunk }) => ({
        ...chunk,
        blank: blank && {
          ...blank,
          acceptAnswerIds: allAnswerIds,
        },
      })),
    }));

  return {
    sentences: completeSentences,
    answers: shuffleArray(answers),
    isWrapOnSentence,
  };
};
