import { nanoid } from "nanoid";
import { TRUE_OR_FALSE_EXERCISE_OPTIONS } from "utils/options/exercise";
import { TTrueOrFalseExerciseAnswer } from "types/app/exercises";
import { TSelectOption } from "types/app/select";

const [firstAssertionOption] = TRUE_OR_FALSE_EXERCISE_OPTIONS;

export const getTrueOrFalseAnswerInitialValue = (): TTrueOrFalseExerciseAnswer<
  TSelectOption<boolean>
> => ({
  id: nanoid(),
  content: "",
  isCorrect: firstAssertionOption,
});
