import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const composeWordsFromLettersExercise: TExerciseItem = {
  title: "Скласти слово з літер",
  backgroundText: "Скласти слово",
  tags: [
    "Скласти слово з літер",
    "скласти",
    "побудувати",
    "слово",
    "слова",
    "з",
    "літер",
    "букв",
    "compose",
    "create",
    "words",
    "from",
    "letters",
    "characters",
  ],
  type: EExerciseType.MAKE_WORD_FROM_LETTERS,
  cover: "compose-words-from-letters.png",
};
