import { useQuery } from "react-query";
import { getScheduleLessons } from "api/services/schedule";
import { EScheduleQueryKeys } from "api/endpoints/schedule";
import { logError } from "utils/notifications";
import { TLessonFilter, TLessonResponse } from "types/api/lesson";
import { TApiError } from "types/api/error";

export const useScheduleLessons = (options: TLessonFilter) => {
  return useQuery<TLessonResponse, TApiError>(
    [EScheduleQueryKeys.getScheduleLessons, options],
    () => getScheduleLessons(options),
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
