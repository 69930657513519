import { cloneElement, forwardRef, useEffect, useRef } from "react";
import cx from "classnames";
import { mergeRefs } from "utils/react";
import { TIconButtonSize, TIconButtonVariant } from "./IconButton.types";
import "./IconButton.styles.scss";

type IconButtonProps = {
  /**
   * Icon element placed as a children of the button
   */
  icon: React.ReactElement;
  /**
   * The variant to use.
   *
   * @default 'primary'
   */
  variant?: TIconButtonVariant;
  /**
   * The size of the button.
   *
   * @default 'medium'
   */
  size?: TIconButtonSize;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the `input` element is focused during the first mount.
   *
   * @default false
   */
  autoFocus?: boolean;
  /**
   * HTML button type.
   *
   * @default 'button'
   */
  type?: "button" | "submit" | "reset";
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Override or extend inline styles applied to the component.
   */
  style?: React.CSSProperties;
  /**
   * Button mouse click handler function
   *
   * @param {object} event - mouse event
   * @return {void} should be voided function
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  IconButtonProps
> = (props, ref) => {
  const {
    variant = "primary",
    type = "button",
    size = "medium",
    disabled = false,
    autoFocus = false,
    className,
    style,
    icon,
    onClick,
  } = props;

  const innerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Adding timeout for autofocus if Button component was hidden in portal or parent element has transition so we need to delay it
    const autofocusTimeout = setTimeout(() => {
      if (
        document.activeElement !== innerRef.current &&
        autoFocus &&
        innerRef.current
      ) {
        innerRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(autofocusTimeout);
  }, [autoFocus]);

  const handleClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (disabled) {
      evt.preventDefault();
      return;
    }
    onClick?.(evt);
  };

  return (
    <button
      ref={mergeRefs([ref, innerRef])}
      type={type}
      disabled={disabled}
      autoFocus={autoFocus}
      tabIndex={disabled ? -1 : 0}
      className={cx([
        {
          "nb-interactive-icon-button-primary": variant === "primary",
          "nb-interactive-icon-button-secondary": variant === "secondary",
          "nb-interactive-icon-button-tertiary": variant === "tertiary",
          "nb-interactive-icon-button-primary--small": size === "small",
          "nb-interactive-icon-button-primary--medium": size === "medium",
          "nb-interactive-icon-button-primary--large": size === "large",
        },
        className,
      ])}
      style={style}
      onClick={handleClick}
    >
      {cloneElement(icon, {
        className: cx({
          "nb-interactive-icon-button-primary--svg": variant === "primary",
          "nb-interactive-icon-button-secondary--svg": variant === "secondary",
          "nb-interactive-icon-button-tertiary--svg": variant === "tertiary",
        }),
      })}
    </button>
  );
};

/**
 * Icon Button interactive element without text and based on variant
 */
export const ForwardedIconButton = forwardRef(Button);
