import { InputProps, components } from "react-select";
import type { TClueSelectDefaultOption } from "../../ClueSelect.types";
import "./Input.styles.scss";

export const Input = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>(
  props: InputProps<Option, false>
): JSX.Element => (
  <components.Input className="nb-interactive-clue-select-input" {...props} />
);
