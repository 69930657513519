import { forwardRef } from "react";
import cx from "classnames";
import "./Heading.styles.scss";

type HeadingProps = {
  /**
   * The component maps the level prop to a range of different HTML heading types.
   *
   * Map of level to heading tag {
   *   1: 'h1',
   *   2: 'h2',
   * }
   *
   * @default 1
   */
  level?: 1 | 2;
  /**
   * The component maps the font variant prop to a range of different font families.
   *
   * @default 'circe'
   */
  font?: "circe" | "ptmono";
  /**
   * If `false`, the text will be regular font weight.
   *
   * @default true
   */
  bold?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

const Heading: React.ForwardRefRenderFunction<
  HTMLHeadingElement,
  HeadingProps
> = (props, ref) => {
  const { children, level = 1, font = "circe", bold = true, className } = props;

  if (level === 2) {
    return (
      <h2
        ref={ref}
        className={cx([
          "nb-typography-heading-2",
          {
            "nb-typography-heading-2--regular": !bold,
            "nb-typography-heading-2--ptmono": font === "ptmono",
          },
          className,
        ])}
      >
        {children}
      </h2>
    );
  }

  return (
    <h1
      ref={ref}
      className={cx([
        "nb-typography-heading-1",
        {
          "nb-typography-heading-1--regular": !bold,
          "nb-typography-heading-1--ptmono": font === "ptmono",
        },
        className,
      ])}
    >
      {children}
    </h1>
  );
};

/**
 * Heading typography component to display heading element based on provided level
 */
export const ForwardedHeading = forwardRef(Heading);
