import cx from "classnames";
import type {
  TDividerColor,
  TDividerDirection,
  TDividerMargin,
  TDividerMarginSize,
  TDividerVariant,
} from "./Divider.types";
import "./Divider.styles.scss";

type DividerProps = {
  /**
   * Variant of divider line display style.
   * @default "solid"
   */
  variant?: TDividerVariant;
  /**
   * Divider line direction.
   * @default "horizontal"
   */
  direction?: TDividerDirection;
  /**
   * Divider line color.
   * @namespace {
   *  grey-100: #f4f4f4,
   *  grey-300: #dddddd,
   * }
   * @default "grey-300"
   */
  color?: TDividerColor;
  /**
   * Make space with margin by specified direction.
   * @default "none"
   */
  margin?: TDividerMargin;
  /**
   * Space size to set with `margin` property.
   * @namespace {
   *  x-small: 5px,
   *  small: 10px,
   *  medium: 15px,
   *  large: 20px,
   *  x-large: 25px,
   *  xx-large: 30px
   * }
   * @default "medium"
   */
  marginSize?: TDividerMarginSize;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * Divider is common component which separate other components with horizontal line.
 */
export const Divider: React.FC<DividerProps> = (props) => {
  const {
    variant = "solid",
    margin = "none",
    marginSize = "medium",
    color = "grey-300",
    direction = "horizontal",
    className,
  } = props;

  return (
    <div
      className={cx([
        "nb-common-divider",
        {
          "nb-common-divider--margin-top":
            margin === "top" || margin === "yAxis",
          "nb-common-divider--margin-bottom":
            margin === "bottom" || margin === "yAxis",
          "nb-common-divider--dashed": variant === "dashed",
          "nb-common-divider--size-xsm": marginSize === "x-small",
          "nb-common-divider--size-sm": marginSize === "small",
          "nb-common-divider--size-md": marginSize === "medium",
          "nb-common-divider--size-l": marginSize === "large",
          "nb-common-divider--size-xl": marginSize === "x-large",
          "nb-common-divider--size-xxl": marginSize === "xx-large",
          "nb-common-divider--color-grey-100": color === "grey-100",
          "nb-common-divider--color-grey-300": color === "grey-300",
          "nb-common-divider--direction-vertical": direction === "vertical",
        },
        className,
      ])}
    />
  );
};
