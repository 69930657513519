import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { SortWordsExerciseContent } from "content";
import { Space } from "components/Common/Space";
import { Text } from "components/Typography/Text";
import { DraggableItem } from "../DraggableItem";
import { Droppable } from "../Droppable";
import {
  TExerciseIdentifier,
  TSortWordsExerciseParsedColumn,
} from "types/app/exercises";
import "./DroppableItem.styles.scss";

type TDroppableItemProps = {
  /**
   * A column data to construct an exercise.
   */
  column: TSortWordsExerciseParsedColumn;
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DroppableItem: React.FC<TDroppableItemProps> = (props) => {
  const {
    dragging = false,
    showResult = false,
    column,
    dragActiveId = null,
  } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: column.id,
    data: { column, word: null },
  });

  return (
    <Space
      direction="vertical"
      justify="center"
      align="start"
      size="none"
      className="nb-exercise-sort-words-droppable-item"
      ref={setNodeRef}
    >
      <div
        className={cx([
          "nb-exercise-sort-words-droppable-item-head",
          {
            "nb-exercise-sort-words-droppable-item-head--dragging": dragging,
            "nb-exercise-sort-words-droppable-item-head--over": isOver,
          },
        ])}
      >
        <Text variant="body2" bold>
          {column.content}
        </Text>
      </div>
      <div
        className={cx([
          "nb-exercise-sort-words-droppable-item-area",
          {
            "nb-exercise-sort-words-droppable-item-area--dragging": dragging,
            "nb-exercise-sort-words-droppable-item-area--over": isOver,
            "nb-exercise-sort-words-droppable-item-area--empty": column.isEmpty,
          },
        ])}
      >
        {column.currentWords.map((word) => (
          <Droppable
            key={word.id}
            column={column}
            word={word}
            dragging={dragging}
            failed={word.isAllAttemptsFailed}
          >
            {word.id !== dragActiveId && (
              <DraggableItem
                word={word}
                correct={word.isCorrectDestination}
                showResult={showResult}
              />
            )}
          </Droppable>
        ))}
        {column.isEmpty && (
          <Text
            variant="body2"
            className="nb-exercise-sort-words-droppable-item-area-empty"
          >
            {SortWordsExerciseContent.Column.EMPTY}
          </Text>
        )}
      </div>
    </Space>
  );
};
