import { useCallback, useMemo, useState } from "react";
import { useRemoveTeacher, useTeachers } from "hooks/api/teachers";
import { useModal } from "hooks/common/useModal";
import { useTeacherColumns } from "hooks/table/useTeacherColumns";
import { Table } from "components/Table";
import { Empty } from "components/Common/Empty";
import { Divider } from "components/Common/Divider";
import { ActionModal } from "components/Common/ActionModal";
import { ContentModal } from "components/Common/ContentModal";
import { StudySearch } from "../../components/StudySearch";
import { AddTeacherForm } from "./components/AddTeacherForm";
import { EditTeacherForm } from "./components/EditTeacherForm";
import { TeachersContent } from "content";
import { logError, logSuccess } from "utils/notifications";
import type { TTeacher } from "types/api/teacher";
import { TSelectOption } from "types/app/select";
import { EStudySearchTarget } from "types/app/study";
import "./Teachers.styles.scss";

export const Teachers: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedTeacherId, setSelectedTeacherId] = useState<number | null>(
    null
  );
  const [selectedTeacher, setSelectedTeacher] = useState<TTeacher | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { data, isLoading } = useTeachers({
    searchText: searchValue,
    pageNumber,
  });
  const { mutate } = useRemoveTeacher();

  const teachers: TTeacher[] = useMemo(
    () => data?.content || [],
    [data?.content]
  );
  const isEmpty: boolean = teachers.length === 0;

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);
  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal(false);
  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const handleEditButtonClick = (teacher: TTeacher) => {
    setSelectedTeacher(teacher);
    openEditModal();
  };

  const handleDeleteButtonClick = (id: number) => {
    setSelectedTeacherId(id);
    openDeleteModal();
  };

  const handleDeleteSubmit = () => {
    if (selectedTeacherId === null) {
      logError({ message: TeachersContent.Delete.Notification.ERROR });
      return;
    }

    mutate(selectedTeacherId, {
      onSuccess: () => {
        logSuccess(TeachersContent.Delete.Notification.SUCCESS);
        setPageNumber(0);
        closeDeleteModal();
      },
    });
  };

  const handleAddTeacherSubmit = () => {
    closeAddModal();
  };

  const handleEditTeacherSubmit = () => {
    closeEditModal();
  };

  const searchCallback = useCallback(async (): Promise<
    TSelectOption<number>[]
  > => {
    return teachers.map((teacher) => ({
      label: `${teacher.person.firstName} ${teacher.person.lastName}`,
      value: teacher.id,
    }));
  }, [teachers]);

  const columns = useTeacherColumns({
    onEditClick: handleEditButtonClick,
    onDeleteClick: handleDeleteButtonClick,
  });

  return (
    <>
      <StudySearch
        searchTarget={EStudySearchTarget.Teachers}
        searchPlaceholder={TeachersContent.Search.PLACEHOLDER}
        searchCallback={searchCallback}
        setSearchValue={setSearchValue}
        onAddButtonClick={openAddModal}
      />
      <Divider margin="bottom" />
      {isEmpty && !isLoading ? (
        <Empty text={TeachersContent.Empty} />
      ) : (
        <Table<TTeacher>
          data={teachers}
          columns={columns}
          loading={isLoading}
          totalItems={data?.totalElements}
          onPageChange={setPageNumber}
        />
      )}
      <ActionModal
        text={TeachersContent.Delete.TITLE}
        emoji={TeachersContent.Delete.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteSubmit}
      />
      <ContentModal
        portalId="add-teacher-modal"
        footerId="add-teacher-modal-footer"
        open={isAddModalOpen}
        title={TeachersContent.Add.TITLE}
        emoji={TeachersContent.Add.EMOJI}
        onClose={closeAddModal}
      >
        <AddTeacherForm onSubmit={handleAddTeacherSubmit} />
      </ContentModal>
      <ContentModal
        portalId="edit-teacher-modal"
        footerId="edit-teacher-modal-footer"
        open={isEditModalOpen}
        title={TeachersContent.Edit.TITLE}
        emoji={TeachersContent.Edit.EMOJI}
        onClose={closeEditModal}
      >
        <EditTeacherForm
          targetTeacher={selectedTeacher}
          onSubmit={handleEditTeacherSubmit}
        />
      </ContentModal>
    </>
  );
};
