import { configureStore } from "@reduxjs/toolkit";
import { appReducer } from "./features";

export const appStore = () => {
  return configureStore({
    reducer: appReducer,
  });
};

export type AppDispatch = AppStore["dispatch"];
export type RootState = ReturnType<typeof appReducer>;
export type AppStore = ReturnType<typeof appStore>;
