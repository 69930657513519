import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { editCourse } from "api/services/courses";
import { logError } from "utils/notifications";
import { TCourse, TEditCourseBody } from "types/api/course";
import { TApiError } from "types/api/error";

export const useEditCourse = () => {
  const queryClient = useQueryClient();
  return useMutation<TCourse, TApiError, TEditCourseBody>(editCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries(ECoursesQueryKeys.getAllCourses);
      queryClient.invalidateQueries(ECoursesQueryKeys.getCourseById);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
