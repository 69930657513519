import { HeaderGroup } from "@tanstack/react-table";
import cx from "classnames";
import { TableHeadRow } from "../TableHeadRow";
import type { TDefaultTableData, TTableSubClassnames } from "../Table.types";
import "./TableHead.styles.scss";

type TableHeadProps<TData extends TDefaultTableData = TDefaultTableData> = {
  /**
   * Array of headers or grouped header objects generated in `react-table` to operate with data and cells.
   */
  headerGroup: HeaderGroup<TData>[];
  /**
   * Object for `Table` sub-components to override or extend the styles applied to the particular component.
   */
  className?: TTableSubClassnames;
};

export const TableHead = <TData extends TDefaultTableData = TDefaultTableData>(
  props: TableHeadProps<TData>
): JSX.Element => {
  const { headerGroup, className } = props;

  return (
    <thead className={cx(["nb-table-head", className?.root])}>
      {headerGroup.map((headerGroup) => (
        <TableHeadRow<TData>
          key={headerGroup.id}
          headers={headerGroup.headers}
          className={className?.row}
        />
      ))}
    </thead>
  );
};
