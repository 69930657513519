import { ClearIndicatorProps, components } from "react-select";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { TSelectDefaultOption } from "../../Select.types";
import "./ClearIndicator.styles.scss";

export const ClearIndicator = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>(
  props: ClearIndicatorProps<Option, false>
): JSX.Element => {
  return (
    <components.ClearIndicator
      {...props}
      className="nb-interactive-select-clear-indicator"
    >
      <Close
        width={15}
        height={15}
        className="nb-interactive-select-clear-indicator-icon"
      />
    </components.ClearIndicator>
  );
};
