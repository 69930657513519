import { LoginContent } from "content";
import { Heading } from "components/Typography/Heading";
import { LoginForm } from "../LoginForm";
import "./LoginCard.styles.scss";

export const LoginCard: React.FC = () => {
  return (
    <div className="nb-page-component-login-card">
      <Heading className="nb-page-component-login-card-title">
        {LoginContent.Admin.Title}
      </Heading>
      <Heading level={2} className="nb-page-component-login-card-text">
        {LoginContent.Admin.Text}
      </Heading>
      <LoginForm />
    </div>
  );
};
