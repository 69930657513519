import { useMutation, useQueryClient } from "react-query";
import { removeGroup } from "api/services/groups";
import { EGroupsQueryKeys } from "api/endpoints/groups";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useRemoveGroup = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(removeGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(EGroupsQueryKeys.getGroups);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
