import { userSelector } from "store/features/user";
import { useAppSelector } from "hooks/redux";
import { isAdmin, isStudent, isTeacher } from "utils/check";

type TUseRole = () => {
  isUserAdmin: boolean;
  isUserTeacher: boolean;
  isUserStudent: boolean;
};

export const useRole: TUseRole = () => {
  const { activeUser } = useAppSelector(userSelector);
  const currentUserRole = activeUser?.role.name;

  const isUserAdmin = isAdmin(currentUserRole);
  const isUserTeacher = isTeacher(currentUserRole);
  const isUserStudent = isStudent(currentUserRole);

  return { isUserAdmin, isUserTeacher, isUserStudent };
};
