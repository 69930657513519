import { nanoid } from "nanoid";
import { BRACKET_CONTENT, SENTENCE_SEPARATOR } from "constants/regexp";
import {
  TExerciseSentence,
  TExerciseSentenceAnswer,
  TExerciseSentenceChunk,
  TExerciseSentenceText,
  TInputWordsIntoGapsExerciseBlank,
  TInputWordsIntoGapsExerciseData,
} from "types/app/exercises";

const MAX_ANSWER_ATTEMPTS = 3;

/**
 * Utility function that helps to adapt raw exercise string to structured data which makes easier to work with inputs.
 * @description The `exerciseText` can come with answers in 3 different formats: `[answer1]`, `[hint/answer1/answer2/...]`, `[/answer1/answer2/...]`, so the goal is to split each sentence into chunks and then check if the chunk is a blank or not. If the chunk is a blank, then we need to split it into answers and hint, otherwise we just need to save the chunk as a text. Note: if the first string answer is starting with `/` then it will be considered as an omitted hint and will be ignored. So, if brackets contain only one answer, then it will be considered as a single answer, otherwise it will be considered as a hint with answers.
 * @example
 * "This is an example with single item [string1]" => "[answer1]"
 * "This is an example with hint [string1/string2]" => "[hint/answer1]"
 * "This is an example with omitted hint [/string1/string2]" => "[answer1/answer2]"
 * @param {string} exerciseText raw exercise string
 * @returns {TInputWordsIntoGapsData} object of structured `InputWordsIntoGaps` exercise data.
 */
export const adaptExerciseStringToData = (
  exerciseText: string
): TInputWordsIntoGapsExerciseData => {
  const sentences: TExerciseSentence<TInputWordsIntoGapsExerciseBlank>[] = [];

  const allSentences = exerciseText
    .split(SENTENCE_SEPARATOR)
    .map((item) => item.trim())
    .filter(Boolean)
    .filter((item) => item.length > 1);

  for (const sentence of allSentences) {
    const rawAnswers = [...(sentence.match(BRACKET_CONTENT) || [])];
    const inputSentence = rawAnswers.reduce(
      (acc, answer) => acc.replace(answer, "[]"),
      sentence
    );
    const splitSentence = sentence
      .split(BRACKET_CONTENT)
      .map((item) => item.trim())
      .filter(Boolean);

    const sentenceChunks: TExerciseSentenceChunk<TInputWordsIntoGapsExerciseBlank>[] =
      [];

    for (const chunk of splitSentence) {
      const isBlank = chunk.startsWith("[") && chunk.endsWith("]");
      const content = isBlank ? chunk.slice(1, chunk.length - 1) : chunk;

      let blank: TInputWordsIntoGapsExerciseBlank | null = null;
      let text: TExerciseSentenceText | null = null;

      const answersRaw: string[] = isBlank
        ? chunk.slice(1, chunk.length - 1).split("/")
        : [];

      const isSingleAnswer = answersRaw.length === 1;
      const [hintRaw] = isSingleAnswer ? [] : answersRaw.splice(0, 1);
      const answers: TExerciseSentenceAnswer[] = answersRaw.map((item) => ({
        id: nanoid(),
        content: item,
      }));
      const hint = hintRaw || null;

      if (isBlank) {
        blank = {
          id: nanoid(),
          answers,
          attempts: [],
          currentAnswer: null,
          currentAttempt: 0,
          maxAttempts: MAX_ANSWER_ATTEMPTS,
          isEmpty: true,
          isCorrectAnswer: false,
          isAllAttemptsFailed: false,
          hint,
        };
      }

      if (!isBlank) {
        text = {
          id: nanoid(),
          content,
        };
      }

      sentenceChunks.push({
        id: nanoid(),
        raw: chunk,
        isBlank,
        blank,
        text,
      });
    }

    sentences.push({
      id: sentence,
      raw: sentence,
      inputSentence,
      splitSentence,
      chunks: sentenceChunks,
    });
  }

  return {
    sentences,
  };
};
