import cx from "classnames";
import { useModal } from "hooks/common/useModal";
import { useAddFile } from "hooks/api/files";
import { useImageUpload } from "hooks/redux";
import { AvatarUploadContent } from "content";
import { Avatar } from "components/Common/Avatar";
import { ImageUploadModal } from "components/Interactive/ImageUploadModal";
import { logError, logSuccess } from "utils/notifications";
import { EFileType, TFile } from "types/api/file";
import { TAvatarUploadSize } from "./AvatarUpload.types";
import "./AvatarUpload.styles.scss";

export type TAvatarUploadProps = {
  /**
   * Indicates file handle mode.
   * If `upload` then component will upload file on the server.
   * If `save` then component will return raw binary file through `onSave` callback.
   * @default "upload"
   */
  mode?: "upload" | "save";
  /**
   * The type of the file to be uploaded.
   * @required for `mode` equals to `upload`.
   */
  fileType?: EFileType;
  /**
   * The id of the image entity to fetch it from the server.
   * @required for `mode` equals to `upload`.
   * @default null
   */
  imageEntityId?: number | null;
  /**
   * Previous entity image to show as a placeholder before a new one was selected.
   * @default null
   */
  previousImage?: string | null;
  /**
   * Shape of the crop area.
   * @default "rect"
   */
  cropShape?: "rect" | "round";
  /**
   * Image display shape.
   * @default "round"
   */
  shape?: "rect" | "round";
  /**
   * Aspect of the cropper. The value is the ratio between its width and its height.
   * @default 1
   */
  aspect?: number;
  /**
   * If `true`, the avatar circle will be bordered.
   * @default false
   */
  bordered?: boolean;
  /**
   * If `true`, selected file and cropped area will be reset to default.
   * @default false
   */
  resetOnClose?: boolean;
  /**
   * The size of the avatar.
   * @default "medium"
   */
  size?: TAvatarUploadSize;
  /**
   * Override or extend the styles applied to the avatar component.
   */
  avatarClassName?: string;
  /**
   * Override or extend the styles applied to the modal component.
   */
  modalClassName?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Callback fired when the file upload event was completed.
   * @remark this callback only fires when `mode` props equals to `upload`.
   * @param file file data.
   */
  onUploadComplete?: (file: TFile) => void;
  /**
   * Callback fired when the file save event was occurred.
   * @remark this callback only fires when `mode` props equals to `save`.
   * @param file file raw binary data.
   */
  onSave?: (file: Blob) => void;
};

/**
 * `AvatarUpload` interactive component that allows to display or change user uploaded photos.
 */
export const AvatarUpload: React.FC<TAvatarUploadProps> = (props) => {
  const {
    fileType,
    imageEntityId = null,
    previousImage = null,
    mode = "upload",
    cropShape,
    shape = "round",
    aspect,
    bordered,
    resetOnClose = false,
    size,
    avatarClassName,
    modalClassName,
    className,
    onUploadComplete,
    onSave,
  } = props;

  const { resetCroppedArea, resetUploadedImage, croppedImageBase64 } =
    useImageUpload();

  const { isModalOpen, openModal, closeModal } = useModal(false);

  const { mutate: addFile } = useAddFile();

  const closeUploadFileModalHandler = () => {
    closeModal();
    if (resetOnClose) {
      resetCroppedArea();
      resetUploadedImage();
    }
  };

  const uploadImage = (fileBlob: Blob) => {
    if (!fileType || imageEntityId === null) {
      throw new Error(
        `Upload mode for AvatarUpload component requires fileType and imageEntityId to be provided`
      );
    }
    const formData = new FormData();
    formData.append("file", fileBlob);
    const fileBody = {
      file: formData,
      params: { fileType, entityId: imageEntityId },
    };
    addFile(fileBody, {
      onSuccess: (file) => {
        onUploadComplete?.(file);
        logSuccess(AvatarUploadContent.Notification.Add.SUCCESS);
        closeUploadFileModalHandler();
      },
      onError: () => {
        logError({ message: AvatarUploadContent.Notification.Add.ERROR });
      },
    });
  };

  const saveImage = (fileBlob: Blob) => {
    onSave?.(fileBlob);
    closeUploadFileModalHandler();
  };

  const imageHandler = (fileBlob: Blob) => {
    if (mode === "upload") {
      uploadImage(fileBlob);
    }
    if (mode === "save") {
      saveImage(fileBlob);
    }
  };

  return (
    <div className={cx(["nb-interactive-avatar-upload-container", className])}>
      <button
        type="button"
        className={cx(
          "nb-interactive-avatar-upload-opener",
          `nb-interactive-avatar-upload-opener--${shape}`
        )}
        onClick={openModal}
      >
        <Avatar
          image={croppedImageBase64 || previousImage}
          bordered={bordered}
          size={size}
          shape={shape}
          emoji="+"
          className={cx([
            "nb-interactive-avatar-upload-avatar",
            avatarClassName,
          ])}
        />
      </button>
      <ImageUploadModal
        open={isModalOpen}
        outputImageType="image/webp"
        aspect={aspect}
        zoomSpeed={0.5}
        cropShape={cropShape}
        id="user-avatar-upload-input"
        name="user-avatar-upload-input"
        portalId="user-avatar-upload-modal"
        title={AvatarUploadContent.Modal.TITLE}
        emoji={AvatarUploadContent.Modal.EMOJI}
        selectButtonText={AvatarUploadContent.Root.Button.SELECT}
        uploadButtonText={AvatarUploadContent.Root.Button.UPLOAD}
        inputLabel={AvatarUploadContent.Input.LABEL}
        withImageCropper
        withUploadButton
        onClose={closeUploadFileModalHandler}
        onUpload={imageHandler}
        className={cx(["nb-interactive-avatar-upload-modal", modalClassName])}
      />
    </div>
  );
};
