import { useEffect, useMemo, useState } from "react";
import { TrueOrFalseExerciseContent } from "content";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { InfoRadioButton } from "components/Interactive/InfoRadioButton";
import { Paragraph } from "components/Typography/Paragraph";
import "./CarouselSlide.styles.scss";

type TCarouselSlideProps = {
  /**
   * Id of the exercise sentence item.
   */
  id: string;
  /**
   * Text of the exercise sentence item.
   */
  sentenceText: string;
  /**
   * Flag that indicates whether the sentence is correct or not.
   */
  isSentenceCorrect: boolean;
  /**
   * Callback that is called when user clicks on the sentence answer.
   * @param sentenceId Id of the sentence item.
   * @param isUserAnswerCorrect Flag that indicates whether the user answer is correct or not.
   */
  onAnswerClick?: (sentenceId: string, isUserAnswerCorrect: boolean) => void;
};

export const CarouselSlide: React.FC<TCarouselSlideProps> = (props) => {
  const { id, sentenceText, isSentenceCorrect, onAnswerClick } = props;

  const [userAnswer, setUserAnswer] = useState<boolean | undefined>(undefined);
  const [isAnswerPicked, setIsAnswerPicked] = useState<boolean>(false);

  const { isExerciseReadOnlyMode, isExercisePreviewMode, isExerciseLiveMode } =
    useLessonSettings();

  useEffect(() => {
    if (isExerciseLiveMode) {
      setUserAnswer(undefined);
      setIsAnswerPicked(false);
    }
  }, [isExerciseLiveMode]);

  const isTrueChecked = useMemo(
    () =>
      isExercisePreviewMode
        ? isSentenceCorrect
        : isExerciseLiveMode && userAnswer === undefined
        ? false
        : isAnswerPicked
        ? userAnswer
        : undefined,
    [
      isExercisePreviewMode,
      isExerciseLiveMode,
      isSentenceCorrect,
      userAnswer,
      isAnswerPicked,
    ]
  );
  const isFalseChecked = useMemo(
    () =>
      isExercisePreviewMode
        ? !isSentenceCorrect
        : isExerciseLiveMode && userAnswer === undefined
        ? false
        : isAnswerPicked
        ? !userAnswer
        : undefined,
    [
      isExercisePreviewMode,
      isExerciseLiveMode,
      isSentenceCorrect,
      userAnswer,
      isAnswerPicked,
    ]
  );

  const answerClickButtonHandler = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isUserChooseCorrectAnswer =
      evt.target.value === isSentenceCorrect.toString();
    setUserAnswer(evt.target.value === "true");
    setIsAnswerPicked(true);
    onAnswerClick?.(id, isUserChooseCorrectAnswer);
  };

  return (
    <Space
      direction="vertical"
      justify="stretch"
      align="center"
      size="medium"
      fullWidth
    >
      <Paragraph variant={2} bold>
        {sentenceText}
      </Paragraph>
      <Space
        direction="horizontal"
        justify="center"
        align="center"
        size="small"
      >
        <InfoRadioButton
          id={`${id}-true`}
          name={id}
          variant="success"
          value="true"
          bordered
          checked={isTrueChecked}
          fullWidth
          onChange={answerClickButtonHandler}
          disabled={isExerciseReadOnlyMode}
        >
          {TrueOrFalseExerciseContent.Option.TRUE}
        </InfoRadioButton>
        <InfoRadioButton
          id={`${id}-false`}
          name={id}
          variant="error"
          value="false"
          bordered
          checked={isFalseChecked}
          onChange={answerClickButtonHandler}
          disabled={isExerciseReadOnlyMode}
        >
          {TrueOrFalseExerciseContent.Option.FALSE}
        </InfoRadioButton>
      </Space>
    </Space>
  );
};
