export const ChooseRightWordForm = {
  Add: {
    Input: {
      Condition: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Text: {
        LABEL: "Текст вправи",
        PLACEHOLDER: "I [to play/am playing*] tennis.",
        HINT: "Підказка\n Запишіть варіанти відповіді у квадратних дужках та позначте правильний варіант зірочкою*. \n\nПриклад:\n They [is/are/was/were*] on holiday yesterday.",
      },
    },
  },
};
