import { useMutation, useQueryClient } from "react-query";
import { removeScheduleLesson } from "api/services/schedule";
import { EScheduleQueryKeys } from "api/endpoints/schedule";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useRemoveScheduleLesson = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(removeScheduleLesson, {
    onSuccess: () => {
      queryClient.invalidateQueries(EScheduleQueryKeys.getScheduleLessons);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
