import { useMemo } from "react";
import { DragOverlay, defaultDropAnimation } from "@dnd-kit/core";
import { ReactPortal } from "components/Common/ReactPortal";
import { Draggable } from "../Draggable";
import {
  TExerciseIdentifier,
  TSortWordsExerciseParsedWord,
} from "types/app/exercises";
import "./DraggableOverlay.styles.scss";

type TDraggableOverlayProps = {
  /**
   * An array of all available words to find the one thats currently dragged.
   */
  words: TSortWordsExerciseParsedWord[];
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DraggableOverlay: React.FC<TDraggableOverlayProps> = (props) => {
  const { dragActiveId = null, words, className } = props;

  const dragSelectedWord: TSortWordsExerciseParsedWord | null = useMemo(() => {
    if (!dragActiveId) {
      return null;
    }
    const word = words.find(({ id }) => id === dragActiveId);
    return word || null;
  }, [dragActiveId, words]);

  return (
    <ReactPortal wrapperId="sort-words-draggable-item-overlay">
      <DragOverlay dropAnimation={defaultDropAnimation} className={className}>
        {dragSelectedWord && (
          <Draggable content={dragSelectedWord.content} dragging dragOverlay />
        )}
      </DragOverlay>
    </ReactPortal>
  );
};
