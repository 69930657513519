import { StudyGroup } from "./components/StudyGroup";
import {
  GroupsContent,
  ReportsContent,
  RequestsContent,
  StudentsContent,
  TeachersContent,
} from "content";
import { useRole } from "hooks/common";
import { Route } from "constants/routes";
import "./Study.styles.scss";

export const Study: React.FC = () => {
  const { isUserAdmin } = useRole();

  return (
    <div className="nb-page-study-container">
      <StudyGroup
        title={`${StudentsContent.Emoji} ${StudentsContent.Name}`}
        link={Route.App.Study.STUDENTS.path}
      />
      <StudyGroup
        title={`${GroupsContent.Emoji} ${GroupsContent.Name}`}
        link={Route.App.Study.GROUPS.path}
      />
      <StudyGroup
        title={`${TeachersContent.Emoji} ${TeachersContent.Name}`}
        link={Route.App.Study.TEACHERS.path}
      />
      {isUserAdmin && (
        <StudyGroup
          title={`${RequestsContent.Emoji} ${RequestsContent.Name}`}
          link={Route.App.Study.REQUESTS.path}
        />
      )}
      <StudyGroup
        title={`${ReportsContent.Emoji} ${ReportsContent.Name}`}
        link={Route.App.Study.REPORTS.path}
      />
    </div>
  );
};
