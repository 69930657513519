import { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useEditCourseLessonChapter } from "hooks/api/courses";
import { LessonDetailsContent as Content } from "content";
import { Input } from "components/Interactive/Input";
import { Button } from "components/Interactive/Button";
import { ReactPortal } from "components/Common/ReactPortal";
import { logError, logSuccess } from "utils/notifications";
import { TCourseLessonChapter } from "types/api/course";
import type { TEditLessonChapterForm } from "./EditLessonChapterForm.types";
import "./EditLessonChapterForm.styles.scss";

type EditLessonChapterFormProps = {
  /**
   * Lesson chapter data to display current (default) values.
   */
  lessonChapter: TCourseLessonChapter | null;
  /**
   * Callback function fires on edit chapter submit event.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const EditLessonChapterForm: React.FC<EditLessonChapterFormProps> = (
  props
) => {
  const { lessonChapter = null, onSubmit, className } = props;

  const idRef = useRef<string>(nanoid());

  const { courseId, lessonId } = useParams();

  const { mutate: editLessonChapterHandler } = useEditCourseLessonChapter(
    courseId,
    lessonId
  );

  const defaultValues = useMemo(
    () => ({
      name: lessonChapter?.name,
    }),
    [lessonChapter]
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TEditLessonChapterForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleEditChapterSubmit = (formData: TEditLessonChapterForm) => {
    if (!lessonChapter) {
      logError({ message: Content.Edit.Notification.ERROR });
      return;
    }
    const chapterData = {
      ...lessonChapter,
      name: formData.name,
    };

    editLessonChapterHandler(chapterData, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(Content.Edit.Notification.SUCCESS);
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-lesson-details-edit-chapter-form", className])}
      onSubmit={handleSubmit(handleEditChapterSubmit)}
    >
      <Input<TEditLessonChapterForm>
        name="name"
        id="edit-lesson-chapter-name"
        label={Content.Edit.Input.Name.LABEL}
        placeholder={Content.Edit.Input.Name.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <ReactPortal wrapperId="edit-course-lesson-chapter-modal-footer">
        <Button variant="primary" type="submit" form={idRef.current} fullWidth>
          {Content.Edit.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
