import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { removeCourseLesson } from "api/services/courses";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useRemoveCourseLesson = (courseId?: string | number) => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(
    (lessonId: number) => removeCourseLesson(courseId, lessonId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ECoursesQueryKeys.getAllCourseLessons);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
