import { useEffect, useRef, useState } from "react";
import { FieldErrorsImpl, Path, UseFormRegister } from "react-hook-form";
import cx from "classnames";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { Input } from "components/Interactive/Input";
import { IconButton } from "components/Interactive/IconButton";
import { Space } from "components/Common/Space";
import { TColumnInputsDefaultValues } from "../ColumnInputs.types";
import "./ColumnItem.styles.scss";

type TColumnItemProps<
  TFormValues extends TColumnInputsDefaultValues = TColumnInputsDefaultValues
> = {
  /**
   * Name attribute of the `ColumnInputs` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: Path<TFormValues>;
  /**
   * Index of the column from `react-hook-form` array fields.
   */
  index: number;
  /**
   * The id that `react-hook-form` created to associate `input` element and form array fields.
   */
  id?: string;
  /**
   * Label text for the column name input.
   */
  columnNameInputLabel?: string;
  /**
   * The short hint displayed in the column name input before the user enters a value.
   */
  columnNameInputPlaceholder?: string;
  /**
   * Label text for the column value input.
   */
  columnValueInputLabel?: string;
  /**
   * The short hint displayed in the column value input before the user enters a value.
   */
  columnValueInputPlaceholder?: string;
  /**
   * If `true`, the delete column button is disabled. Used for preventing deletion of only one assertion sentence.
   * @default false
   */
  disableDelete?: boolean;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ColumnItem = <
  TFormValues extends TColumnInputsDefaultValues = TColumnInputsDefaultValues
>(
  props: TColumnItemProps<TFormValues>
): JSX.Element => {
  const {
    name,
    id,
    index,
    columnNameInputLabel,
    columnNameInputPlaceholder,
    columnValueInputLabel,
    columnValueInputPlaceholder,
    register,
    errors,
    disableDelete = false,
    onDelete,
  } = props;

  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [topDistance, setTopDistance] = useState<number>();

  useEffect(() => {
    const inputRect = nameInputRef.current?.getBoundingClientRect();
    const containerRect = containerRef.current?.getBoundingClientRect();
    const top =
      inputRect && containerRect
        ? inputRect.top - containerRect.top
        : undefined;
    setTopDistance(top);
  }, []);

  return (
    <Space
      direction="vertical"
      justify="start"
      align="stretch"
      size="medium"
      fullWidth
    >
      <Space
        ref={containerRef}
        justify="start"
        align="start"
        size="x-small"
        className="nb-interactive-column-item-name-wrapper"
        fullWidth
      >
        <Input
          ref={nameInputRef}
          name={`${name}.name` as Path<TFormValues>}
          id={`column-name-${id}`}
          label={`${columnNameInputLabel} ${index + 1}`}
          placeholder={columnNameInputPlaceholder}
          register={register}
          errors={errors}
          className={cx([
            "nb-interactive-column-item-input",
            "nb-interactive-column-item-input--name",
          ])}
          required
          fullWidth
        />
        <IconButton
          variant="secondary"
          size="medium"
          icon={<DeleteIcon />}
          className="nb-interactive-column-item-delete"
          onClick={onDelete}
          disabled={disableDelete}
          style={{ top: topDistance }}
        />
      </Space>
      <Input
        name={`${name}.value` as Path<TFormValues>}
        id={`column-value-${id}`}
        label={`${columnValueInputLabel} ${index + 1}`}
        placeholder={columnValueInputPlaceholder}
        register={register}
        errors={errors}
        className="nb-interactive-column-item-input"
        required
        fullWidth
      />
    </Space>
  );
};
