import { useMemo } from "react";
import {
  ArrayPath,
  Control,
  FieldArray,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { Button } from "components/Interactive/Button";
import { AnswerInput } from "../AnswerInput";
import { getTestQuestionAnswerInitialValue } from "../TestQuestions.helpers";
import { TTestExerciseQuestions } from "types/app/exercises";
import "./AnswerList.styles.scss";

type AnswerListProps<
  TFormValues extends TTestExerciseQuestions = TTestExerciseQuestions
> = {
  /**
   * Index of the question `react-hook-form` array fields.
   */
  questionIndex: number;
  /**
   * Answers input label text for the first answer `input` element.
   */
  label?: string;
  /**
   * The short hint displayed in the answers `input` before the user enters a value.
   */
  answerPlaceholder?: string;
  /**
   * The content of the add answer button.
   */
  addAnswerButtonText?: string;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
};

export const AnswerList = <
  TFormValues extends TTestExerciseQuestions = TTestExerciseQuestions
>(
  props: AnswerListProps<TFormValues>
): JSX.Element => {
  const {
    control,
    register,
    label,
    answerPlaceholder,
    questionIndex,
    addAnswerButtonText,
  } = props;

  const { fields, append, remove } = useFieldArray<TFormValues>({
    control,
    name: `questions.${questionIndex}.answers` as ArrayPath<TFormValues>,
  });

  const addQuestionAnswerHandler = () => {
    append(
      getTestQuestionAnswerInitialValue() as FieldArray<
        TFormValues,
        ArrayPath<TFormValues>
      >
    );
  };

  const removeQuestionAnswerHandler = (questionIndex: number) => {
    if (fields.length > 1) {
      remove(questionIndex);
    }
  };

  const firstAnswerId: string = useMemo(() => {
    const [firstField] = fields;
    return firstField?.id || "";
  }, [fields]);

  return (
    <div className="nb-interactive-answer-list-container">
      {label && (
        <label
          className="nb-interactive-answer-list-label"
          htmlFor={`answer-input-field-${firstAnswerId}`}
        >
          {label}
        </label>
      )}
      {fields.map((item, index, self) => (
        <AnswerInput<TFormValues>
          key={item.id}
          id={item.id}
          questionIndex={questionIndex}
          answerIndex={index}
          placeholder={answerPlaceholder}
          disableDelete={self.length === 1}
          register={register}
          control={control}
          onDelete={removeQuestionAnswerHandler}
        />
      ))}
      <Button
        variant="secondary"
        fullWidth
        onClick={addQuestionAnswerHandler}
        className="nb-interactive-answer-list-add"
      >
        {addAnswerButtonText}
      </Button>
    </div>
  );
};
