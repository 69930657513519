import { forwardRef, useMemo } from "react";
import cx from "classnames";
import { TAvatarColor, TAvatarSize } from "./Avatar.types";
import "./Avatar.styles.scss";

export type TAvatarProps = {
  /**
   * The background color for emoji avatar.
   * @default 'white'
   */
  color?: TAvatarColor;
  /**
   * The emoji if no image provided.
   */
  emoji?: string;
  /**
   * The `src` attribute for the `img` element.
   */
  image?: string | null;
  /**
   * Shape of the avatar.
   * @default "round"
   */
  shape?: "rect" | "round";
  /**
   * If `true`, the avatar circle will be bordered.
   *
   * @default false
   */
  bordered?: boolean;
  /**
   * The size of the avatar.
   * @default 'medium'
   */
  size?: TAvatarSize;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

const Avatar: React.ForwardRefRenderFunction<HTMLDivElement, TAvatarProps> = (
  props,
  ref
) => {
  const {
    color = "white",
    emoji = "👩",
    image = null,
    bordered = false,
    size = "medium",
    shape = "round",
    className,
  } = props;

  const avatarSize: number = useMemo(() => {
    switch (size) {
      case "small":
        return 50;

      case "medium":
        return 60;

      case "large":
        return 79;

      case "x-large":
        return 144;

      default:
        return 60;
    }
  }, [size]);

  return (
    <div
      ref={ref}
      className={cx([
        "nb-common-avatar",
        {
          "nb-common-avatar--small": size === "small",
          "nb-common-avatar--medium": size === "medium",
          "nb-common-avatar--large": size === "large",
          "nb-common-avatar--x-large": size === "x-large",
        },
        className,
      ])}
    >
      <div
        className={cx([
          "nb-common-avatar-wrapper",
          {
            "nb-common-avatar-wrapper--bordered": bordered,
            "nb-common-avatar-wrapper--white": color === "white",
            "nb-common-avatar-wrapper--blue": color === "blue",
            "nb-common-avatar-wrapper--orange": color === "orange",
            "nb-common-avatar-wrapper--grey": color === "grey",
          },
          `nb-common-avatar-wrapper--${shape}`,
        ])}
      >
        {image ? (
          <img
            src={image}
            width={avatarSize}
            height={avatarSize}
            alt="Фото завантажене користувачем"
            className={cx(
              "nb-common-avatar-image",
              `nb-common-avatar-image--${shape}`
            )}
          />
        ) : (
          <span className="nb-common-avatar-emoji">{emoji}</span>
        )}
      </div>
    </div>
  );
};

/**
 * `Avatar` common component to display user uploaded photo and it can handle various logic possibilities based on passed props.
 * If component was provided with `image` prop then it will render picture directly from this particular prop.
 * If component was not provided with `image` then it will render fallback emoji.
 */
export const ForwardedAvatar = forwardRef(Avatar);
