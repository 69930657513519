import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useStudents } from "hooks/api/students";
import { useTeachers } from "hooks/api/teachers";
import { useGroups } from "hooks/api/groups";
import { useAddScheduleLesson } from "hooks/api/schedule";
import { useRole } from "hooks/common";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { PersonSelect } from "components/Interactive/PersonSelect";
import { Button } from "components/Interactive/Button";
import { Checkbox } from "components/Interactive/Checkbox";
import { Select } from "components/Interactive/Select";
import { DatePicker } from "components/Interactive/DatePicker";
import { ReactPortal } from "components/Common/ReactPortal";
import { DetailedDurationSelect } from "../DetailedDurationSelect";
import { ScheduleContent } from "content";
import { LESSON_DURATION_OPTIONS, LESSON_TIME_OPTIONS } from "utils/options";
import { getStudentPlural } from "utils/content";
import { logSuccess } from "utils/notifications";
import { TStudent } from "types/api/student";
import { TTeacher } from "types/api/teacher";
import { TGroup } from "types/api/groups";
import { EScheduleAddFormStep } from "types/app/schedule";
import { ELessonType, TAddLessonBody, TLessonDuration } from "types/api/lesson";
import { TPersonSelectOption, TSelectOption } from "types/app/select";
import { TAddScheduleEventForm } from "./AddEventForm.types";
import "./AddEventForm.styles.scss";

type AddEventFormProps = {
  /**
   * Selected teacher ID which comes from `ScheduleControls`.
   */
  teacherId?: number;
  /**
   * Active form step to display.
   */
  formStep: EScheduleAddFormStep;
  /**
   * Active lesson type to show correct fields.
   */
  lessonType: ELessonType;
  /**
   * Start date of targeted schedule event.
   */
  startDate?: Date;
  /**
   * End date of targeted schedule event.
   */
  endDate?: Date;
  /**
   * Callback fired when `Next` button was clicked.
   */
  onNextClick?: () => void;
  /**
   * Callback fired when `Submit` event ocurred.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const AddEventForm: React.FC<AddEventFormProps> = (props) => {
  const {
    lessonType,
    formStep,
    teacherId,
    startDate,
    endDate,
    onNextClick,
    onSubmit,
    className,
  } = props;

  const [studentSearchValue, setStudentSearchValue] = useState<string>("");
  const [groupSearchValue, setGroupSearchValue] = useState<string>("");
  const [innerStartDate, setInnerStartDate] = useState<Date>(
    startDate || new Date()
  );
  const [innerEndDate, setInnerEndDate] = useState<Date>(endDate || new Date());
  const idRef = useRef<string>(nanoid());

  useEffect(() => {
    if (startDate) {
      setInnerStartDate(startDate);
    }
    if (endDate) {
      setInnerEndDate(endDate);
    }
  }, [startDate, endDate]);

  const { data: studentsData } = useStudents({
    searchText: studentSearchValue,
    pageSize: 1000,
  });
  const { data: groupsData } = useGroups({
    searchText: groupSearchValue,
    pageSize: 1000,
  });
  const { data: teachersData } = useTeachers();
  const { mutate: addScheduleLesson } = useAddScheduleLesson();

  const { isUserStudent } = useRole();

  // Getting data from responses
  const students: TStudent[] = useMemo(
    () => studentsData?.content || [],
    [studentsData?.content]
  );
  const groups: TGroup[] = useMemo(
    () => groupsData?.content || [],
    [groupsData?.content]
  );
  const teachers: TTeacher[] = useMemo(
    () => teachersData?.content || [],
    [teachersData?.content]
  );

  // Constructing options for selects
  const studentOptions: TPersonSelectOption[] = useMemo(
    () =>
      students.map((student) => ({
        id: student.id,
        name: `${student.person?.firstName} ${student.person?.lastName}`,
        description: student.person?.email,
      })),
    [students]
  );
  const groupDetailedOptions: TPersonSelectOption[] = useMemo(
    () =>
      groups.map((group) => ({
        id: group.id,
        name: group.name,
        description: getStudentPlural(group.memberIds.length),
      })),
    [groups]
  );
  const teacherOptions: TSelectOption<number>[] = useMemo(
    () =>
      teachers.map((teacher) => ({
        label: `${teacher.person.firstName} ${teacher.person.lastName}`,
        value: teacher.id,
      })),
    [teachers]
  );

  // Making default option values
  const [initialDurationOption] = LESSON_DURATION_OPTIONS;
  const initialTeacherOption = teacherOptions.find(
    (teacher) => teacher.value === teacherId
  );
  const defaultValues: Partial<TAddScheduleEventForm> = useMemo(
    () => ({
      teacher: initialTeacherOption,
      duration: initialDurationOption,
      repeated: true,
    }),
    [initialTeacherOption, initialDurationOption]
  );

  // Initiating form handler
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<TAddScheduleEventForm>({
    mode: "onSubmit",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // Handling date update
  useEffect(() => {
    // Doing this kind of hack to ignore timezone
    const startDateClean = new Date(
      innerStartDate.valueOf() + innerStartDate.getTimezoneOffset() * 60 * 1000
    );
    const [firstTimeOption] = LESSON_TIME_OPTIONS;
    const timeOption = LESSON_TIME_OPTIONS.find(
      (time) => time.value === format(startDateClean, "HH:mm")
    );
    setValue("time", timeOption || firstTimeOption);
  }, [innerStartDate, setValue]);

  const { student, group } = watch();

  // Form handlers
  const nextStepHandler = () => {
    if (lessonType === ELessonType.SINGLE && student) {
      onNextClick?.();
    }
    if (lessonType === ELessonType.GROUP && group) {
      onNextClick?.();
    }
  };

  const addScheduleEventSubmitHandler = async (
    formData: TAddScheduleEventForm
  ) => {
    if (isUserStudent) {
      return;
    }

    const lessonDate = format(innerStartDate, "MM/dd/yyyy");
    const lessonTime = `${formData.time.value}:00`;
    const lessonEvent: TAddLessonBody = {
      lesson: {
        teacherId: formData.teacher.value,
        type: lessonType,
        duration: formData.duration.value,
        studentId: formData.student?.id,
        groupId: formData.group?.id,
        startDateTime: `${lessonDate} ${lessonTime}`,
      },
      repeated: formData.repeated,
    };
    addScheduleLesson(lessonEvent, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(ScheduleContent.Add.Notification.SUCCESS);
        reset();
      },
    });
  };

  const dateChangeHandler = (rangeStart: Date, rangeEnd: Date) => {
    setInnerStartDate(rangeStart);
    setInnerEndDate(rangeEnd);
  };

  const studentsSearchCallback = useCallback(
    async (): Promise<TPersonSelectOption[]> => Promise.resolve(studentOptions),
    [studentOptions]
  );

  const studentsSearchHandler = (searchValue: string) => {
    setStudentSearchValue(searchValue);
  };

  const groupsSearchCallback = useCallback(
    async (): Promise<TPersonSelectOption[]> =>
      Promise.resolve(groupDetailedOptions),
    [groupDetailedOptions]
  );

  const groupsSearchHandler = (searchValue: string) => {
    setGroupSearchValue(searchValue);
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-page-component-add-event-form", className])}
      onSubmit={handleSubmit(addScheduleEventSubmitHandler)}
    >
      {/* Student select for individual lesson */}
      {lessonType === ELessonType.SINGLE &&
        formStep === EScheduleAddFormStep.FIRST && (
          <PersonSelect<TAddScheduleEventForm, TPersonSelectOption, false>
            name="student"
            id="add-event-student"
            placeholder={ScheduleContent.Add.Input.Student.PLACEHOLDER}
            searchCallback={studentsSearchCallback}
            onSearchChange={studentsSearchHandler}
            control={control}
            errors={errors}
            defaultOptions={studentOptions}
            multi={false}
            size={4}
            menuAlwaysOpen
            required
            fullWidth
          />
        )}

      {/* Group select for group lesson */}
      {lessonType === ELessonType.GROUP &&
        formStep === EScheduleAddFormStep.FIRST && (
          <PersonSelect<TAddScheduleEventForm, TPersonSelectOption, false>
            name="group"
            id="add-event-group"
            placeholder={ScheduleContent.Add.Input.Group.PLACEHOLDER}
            searchCallback={groupsSearchCallback}
            onSearchChange={groupsSearchHandler}
            control={control}
            errors={errors}
            defaultOptions={groupDetailedOptions}
            multi={false}
            size={4}
            menuAlwaysOpen
            required
            fullWidth
          />
        )}

      {/* Detailed person info with duration select */}
      {formStep === EScheduleAddFormStep.SECOND && (
        <DetailedDurationSelect<
          TAddScheduleEventForm,
          TSelectOption<TLessonDuration>
        >
          name="duration"
          id="add-event-duration"
          placeholder={ScheduleContent.Add.Input.Duration.PLACEHOLDER}
          details={lessonType === ELessonType.SINGLE ? student : group}
          options={LESSON_DURATION_OPTIONS}
          control={control}
          errors={errors}
        />
      )}

      {/* Teacher select for individual lesson */}
      {formStep === EScheduleAddFormStep.SECOND && (
        <Select<TAddScheduleEventForm, TSelectOption<number>>
          name="teacher"
          id="add-event-teacher"
          className="nb-page-component-add-event-teacher"
          label={ScheduleContent.Add.Input.Teacher.LABEL}
          placeholder={ScheduleContent.Add.Input.Teacher.PLACEHOLDER}
          options={teacherOptions}
          control={control}
          errors={errors}
          required
          fullWidth
        />
      )}

      {/* Lesson date and time */}
      {formStep === EScheduleAddFormStep.SECOND && (
        <div className="nb-page-component-add-event-date-wrapper">
          <DatePicker
            variant="input"
            className="nb-page-component-add-event-date-picker"
            onChange={dateChangeHandler}
            label={ScheduleContent.Add.Input.Date.LABEL}
            value={[innerStartDate, innerEndDate]}
            mode="day"
            view="month"
            position="bottom-right"
          />
          <Select<TAddScheduleEventForm, TSelectOption>
            name="time"
            id="add-event-time"
            className="nb-page-component-add-event-time"
            icon={<TimeIcon width={15} height={15} />}
            placeholder={ScheduleContent.Add.Input.Time.PLACEHOLDER}
            options={LESSON_TIME_OPTIONS}
            control={control}
            errors={errors}
            required
            fullWidth
          />
        </div>
      )}

      {/* Repeat lesson checkbox */}
      {formStep === EScheduleAddFormStep.SECOND && (
        <Checkbox<TAddScheduleEventForm>
          name="repeated"
          id="add-event-repeat"
          className="nb-page-component-add-event-repeat"
          control={control}
          label={ScheduleContent.Add.Input.Repeat.LABEL}
          errors={errors}
          fullWidth
        />
      )}

      {/* Form control buttons */}
      <ReactPortal wrapperId="add-schedule-event-modal-footer">
        {formStep === EScheduleAddFormStep.FIRST && (
          <Button
            className="nb-page-component-add-event-button"
            variant="primary"
            fullWidth
            onClick={nextStepHandler}
          >
            {ScheduleContent.Add.Step.First.BUTTON}
          </Button>
        )}
        {formStep === EScheduleAddFormStep.SECOND && (
          <Button
            className="nb-page-component-add-event-button"
            variant="primary"
            type="submit"
            form={idRef.current}
            fullWidth
          >
            {ScheduleContent.Add.Step.Second.BUTTON}
          </Button>
        )}
      </ReactPortal>
    </form>
  );
};
