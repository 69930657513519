import cx from "classnames";
import { OptionProps, components } from "react-select";
import { Avatar } from "components/Common/Avatar";
import { Text } from "components/Typography/Text";
import { TPersonSelectOption } from "types/app/select";
import "./Option.styles.scss";

export const Option = <
  Option extends TPersonSelectOption = TPersonSelectOption
>({
  isFocused,
  isSelected,
  data,
  ...optionProps
}: OptionProps<Option>): JSX.Element => {
  return (
    <components.Option
      {...optionProps}
      isFocused={isFocused}
      isSelected={isSelected}
      data={data}
      className={cx([
        "nb-interactive-person-select-option",
        {
          "nb-interactive-person-select-option--focused": isFocused,
          "nb-interactive-person-select-option--searched": isSelected,
        },
      ])}
    >
      <Avatar size="small" />
      <div className="nb-interactive-person-select-option-content">
        <Text variant="body2" bold>
          {data.name}
        </Text>
        <Text
          variant="body2"
          className="nb-interactive-person-select-option-text"
        >
          {data.description}
        </Text>
      </div>
    </components.Option>
  );
};
