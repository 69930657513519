import { FieldErrorsImpl, Path, UseFormRegister } from "react-hook-form";
import { Input } from "components/Interactive/Input";
import { IconButton } from "components/Interactive/IconButton";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { TTestExerciseQuestions } from "types/app/exercises";
import "./QuestionInput.styles.scss";

type QuestionInputProps<
  TFormValues extends TTestExerciseQuestions = TTestExerciseQuestions
> = {
  /**
   * The id that `react-hook-form` created to associate `input` element and form array fields.
   */
  id: string;
  /**
   * Index of the question `react-hook-form` array fields.
   */
  questionIndex: number;
  /**
   * Question input label text for the `input` element.
   */
  questionLabel?: string;
  /**
   * The short hint displayed in the question `input` before the user enters a value.
   */
  questionPlaceholder?: string;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (
    questionIndex: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

export const QuestionInput = <
  TFormValues extends TTestExerciseQuestions = TTestExerciseQuestions
>(
  props: QuestionInputProps<TFormValues>
): JSX.Element => {
  const {
    id,
    questionIndex,
    register,
    errors,
    questionLabel,
    questionPlaceholder,
    onDelete,
  } = props;

  const removeQuestionClickHandler = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onDelete?.(questionIndex, evt);
  };

  return (
    <div className="nb-interactive-question-input-container">
      <Input<TFormValues>
        name={`questions.${questionIndex}.question` as Path<TFormValues>}
        id={`test-question-input-${id}`}
        register={register}
        errors={errors}
        label={questionLabel}
        placeholder={`${questionPlaceholder} ${questionIndex + 1}`}
        className="nb-interactive-question-input-field"
        required
        fullWidth
      />
      <IconButton
        icon={<Delete />}
        variant="secondary"
        size="medium"
        className="nb-interactive-question-input-remove"
        onClick={removeQuestionClickHandler}
      />
    </div>
  );
};
