import { useQuery } from "react-query";
import { fetchGroupById } from "api/services/groups";
import { EGroupsQueryKeys } from "api/endpoints/groups";
import { logError } from "utils/notifications";
import { TGroup } from "types/api/groups";
import { TApiError } from "types/api/error";

export const useGroupById = (id?: number) => {
  return useQuery<TGroup, TApiError>(
    [EGroupsQueryKeys.getGroupById, id],
    () => fetchGroupById(id),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: id !== undefined,
    }
  );
};
