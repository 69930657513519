import { useMemo } from "react";
import {
  ArrayPath,
  Control,
  FieldArray,
  FieldErrorsImpl,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { Divider } from "components/Common/Divider";
import { Space } from "components/Common/Space";
import { Button } from "components/Interactive/Button";
import { AssertionList } from "./AssertionList";
import { AssertionSentencesContent } from "content";
import { getTrueOrFalseAnswerInitialValue } from "./AssertionSentences.helpers";
import { TAssertionSentencesDefaultValue } from "./AssertionSentences.types";
import "./AssertionSentences.styles.scss";

type TAssertionSentencesProps<
  TFormValues extends TAssertionSentencesDefaultValue = TAssertionSentencesDefaultValue
> = {
  /**
   * Name attribute of the `AssertionSentences` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: ArrayPath<TFormValues>;
  /**
   * Assertions label text for the `AssertionSentences` component.
   */
  label?: string;
  /**
   * The short hint displayed in the assertion `input` before the user enters a value.
   */
  assertionPlaceholder?: string;
  /**
   * The content of the add assertion button.
   */
  addAssertionButtonText?: string;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * `AssertionSentences` interactive element in association with `react-hook-form` library made for `ExerciseConstructor` component.
 */
export const AssertionSentences = <
  TFormValues extends TAssertionSentencesDefaultValue = TAssertionSentencesDefaultValue
>(
  props: TAssertionSentencesProps<TFormValues>
): JSX.Element => {
  const {
    label = AssertionSentencesContent.Default.LABEL,
    assertionPlaceholder = AssertionSentencesContent.Default
      .ASSERTION_PLACEHOLDER,
    addAssertionButtonText = AssertionSentencesContent.Default
      .ADD_ASSERTION_TEXT,
    name,
    control,
    register,
    errors,
    className,
  } = props;

  const { fields, remove, append } = useFieldArray<TFormValues>({
    control,
    name,
  });

  const addAssertionClickHandler = () => {
    append(
      getTrueOrFalseAnswerInitialValue() as FieldArray<
        TFormValues,
        ArrayPath<TFormValues>
      >
    );
  };

  const removeAssertionClickHandler = (assertionIndex: number) => {
    remove(assertionIndex);
  };

  const firstFieldId: string = useMemo(() => {
    const [firstField] = fields;
    return firstField.id || "";
  }, [fields]);

  return (
    <Space direction="vertical" justify="start" fullWidth className={className}>
      {label && (
        <label
          className="nb-interactive-answer-list-label"
          htmlFor={`true-or-false-assertion-input-${firstFieldId}`}
        >
          {label}
        </label>
      )}
      <AssertionList<TFormValues>
        name={name}
        assertionPlaceholder={assertionPlaceholder}
        fields={fields}
        control={control}
        register={register}
        errors={errors}
        onDelete={removeAssertionClickHandler}
      />
      <Divider margin="yAxis" marginSize="large" />
      <Button variant="secondary" fullWidth onClick={addAssertionClickHandler}>
        {addAssertionButtonText}
      </Button>
    </Space>
  );
};
