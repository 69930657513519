import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const chooseRightWordFormExercise: TExerciseItem = {
  title: "Вибрати правильну форму слова",
  backgroundText: "Правильна",
  tags: [
    "Вибрати правильну форму слова",
    "вибрати",
    "оберіть",
    "обрати",
    "правильну",
    "вірну",
    "форму",
    "слова",
    "choose",
    "correct",
    "right",
    "word",
    "form",
  ],
  type: EExerciseType.CHOOSE_RIGHT_WORD_FORM,
  cover: "choose-right-word-form.png",
};
