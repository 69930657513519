import "./MainContainer.styles.scss";

type MainContainerProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const MainContainer: React.FC<MainContainerProps> = ({ children }) => {
  return <main className="nb-layout-main-container">{children}</main>;
};
