import { useMutation, useQueryClient } from "react-query";
import { addScheduleLesson } from "api/services/schedule";
import { EScheduleQueryKeys } from "api/endpoints/schedule";
import { logError } from "utils/notifications";
import { TAddLessonBody, TLesson } from "types/api/lesson";
import { TApiError } from "types/api/error";

export const useAddScheduleLesson = () => {
  const queryClient = useQueryClient();
  return useMutation<TLesson, TApiError, TAddLessonBody>(addScheduleLesson, {
    onSuccess: () => {
      queryClient.invalidateQueries(EScheduleQueryKeys.getScheduleLessons);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
