import { useMemo } from "react";
import cx from "classnames";
import { Paragraph } from "components/Typography/Paragraph";
import { EmptyContent } from "content";
import { getRandomNumber } from "utils/common";
import "./Empty.styles.scss";

type EmptyProps = {
  /**
   * The content of the `Empty` component.
   */
  text?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * Empty is common component with a random funny image.
 */
export const Empty: React.FC<EmptyProps> = (props) => {
  const { text = EmptyContent.DEFAULT.TEXT, className } = props;
  const imageNum: number = useMemo(() => getRandomNumber(1, 10), []);

  return (
    <div className={cx(["nb-common-empty-container", className])}>
      <img
        src={`/assets/images/empty/${imageNum}.png`}
        alt="Смішна реакція на пусту сторінку"
        className={cx([
          "nb-common-empty-image",
          `nb-common-empty-image--${imageNum}`,
        ])}
      />
      <Paragraph variant={2}>{text}</Paragraph>
    </div>
  );
};
