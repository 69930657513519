export const AvatarUpload = {
  Root: {
    Button: {
      SELECT: "Завантажити нове фото",
      UPLOAD: "Додати це фото",
    },
  },
  Input: {
    LABEL: "Перетягніть або завантажте файл",
  },
  Modal: {
    TITLE: "Фото",
    EMOJI: "📷",
  },
  Notification: {
    Add: {
      SUCCESS: "Фото успішно завантажено",
      ERROR: "Не вдалось завантажити фото",
    },
  },
};
