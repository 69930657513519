export const Schedule = {
  Name: "Розклад",
  Emoji: "🗓",
  Controls: {
    Title: {
      GREETING: "👋🏻 Привіт,",
      PREFIX: "Викладач",
      DEFAULT: "Викладач не обраний",
    },
    Group: {
      PLACEHOLDER: "Група",
      EMPTY: "Немає груп(-и)",
    },
    Student: {
      PLACEHOLDER: "Студент",
      EMPTY: "Немає студента(-ів)",
    },
    Teacher: {
      PLACEHOLDER: "Викладач",
      EMPTY: "Немає викладача(-ів)",
    },
  },
  Add: {
    TITLE: "Новий урок",
    EMOJI: "📚",
    Tabs: {
      Individual: {
        TITLE: "Індивідуальний",
        Student: {
          PLACEHOLDER: "Пошук",
        },
      },
      Group: {
        TITLE: "Груповий",
        Group: {
          PLACEHOLDER: "Пошук",
        },
      },
    },
    Notification: {
      SUCCESS: "Урок успішно додано",
    },
    Step: {
      First: {
        BUTTON: "Далі",
      },
      Second: {
        BUTTON: "Запланувати",
      },
    },
    Input: {
      Repeat: {
        LABEL: "Повторювати кожну неділю",
      },
      Date: {
        LABEL: "Оберіть дату та час",
      },
      Teacher: {
        LABEL: "Викладач",
        PLACEHOLDER: "Оберіть викладача",
      },
      Group: {
        LABEL: "Група",
        PLACEHOLDER: "Оберіть групу",
      },
      Student: {
        PLACEHOLDER: "Оберіть студента",
      },
      Duration: {
        PLACEHOLDER: "Оберіть тривалість",
      },
      Time: {
        PLACEHOLDER: "Оберіть час",
      },
    },
  },
  Edit: {
    TITLE: "Редагувати урок",
    LESSON: "Урок",
    EMOJI: "📚",
    Notification: {
      SUCCESS: "Урок успішно змінено",
      ERROR: "Не вдалось змінити урок",
    },
    Input: {
      Duration: {
        PLACEHOLDER: "Оберіть тривалість",
      },
      Student: {
        LABEL: "Студент",
        PLACEHOLDER: "Оберіть студента",
      },
      Group: {
        LABEL: "Група",
        PLACEHOLDER: "Оберіть групу",
      },
      Date: {
        LABEL: "Оберіть дату та час",
      },
      Time: {
        PLACEHOLDER: "Оберіть час",
      },
      Status: {
        LABEL: "Статус уроку",
        PLACEHOLDER: "Оберіть статус уроку",
      },
      Repeat: {
        LABEL: "Повторювати кожну неділю",
      },
    },
    Button: {
      SUBMIT: "Зберегти",
      DELETE: "Видалити",
    },
  },
  Read: {
    LESSON: "Урок",
    EMOJI: "📚",
    Notification: {
      SUCCESS: "Урок успішно змінено",
      ERROR: "Не вдалось змінити урок",
    },
    Input: {
      Duration: {
        PLACEHOLDER: "Оберіть тривалість",
      },
      Student: {
        LABEL: "Студент",
        PLACEHOLDER: "Оберіть студента",
      },
      Group: {
        LABEL: "Група",
        PLACEHOLDER: "Оберіть групу",
      },
      Date: {
        LABEL: "Дата та час",
      },
      Time: {
        PLACEHOLDER: "Оберіть час",
      },
      Status: {
        LABEL: "Статус уроку",
        PLACEHOLDER: "Оберіть статус уроку",
      },
    },
    Button: {
      SUBMIT: "Зберегти",
      GO_TO_LESSON: "Перейти до уроку",
    },
  },
  Delete: {
    TITLE: "Ви дійсно хочете видалити урок?",
    EMOJI: "😥",
    Notification: {
      SUCCESS: "Урок успішно видалено",
      ERROR: "Не вдалося видалити урок",
    },
  },
};
