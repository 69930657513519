import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { ExerciseConstructorContent } from "content";
import { Empty } from "components/Common/Empty";
import { ExerciseListItem } from "../ExerciseListItem";
import "./ExerciseList.styles.scss";

export const ExerciseList: React.FC = () => {
  const { exercises } = useExerciseConstructor();

  const isEmpty: boolean = exercises.length === 0;

  return (
    <ul className="nb-exercise-constructor-list">
      {isEmpty ? (
        <Empty
          text={ExerciseConstructorContent.List.EMPTY}
          className="nb-exercise-constructor-list-empty"
        />
      ) : (
        exercises.map((exercise) => (
          <ExerciseListItem key={exercise.type} exercise={exercise} />
        ))
      )}
    </ul>
  );
};
