export const ExerciseConstructor = {
  List: {
    EMPTY: "На жаль, у нас немає такої вправи, спробуйте якусь іншу 💪",
  },
  Config: {
    ADD_SUBMIT: "Зберегти вправу",
    EDIT_SUBMIT: "Зберегти зміни",
  },
  Delete: {
    EMOJI: "😥",
    TITLE: "Ви дійсно хочете видалити вправу?",
  },
  Notification: {
    Add: {
      SUCCESS: "Вправу успішно додано",
      ERROR: "Не вдалось додати вправу",
      ERROR_NO_FILE:
        "Не вдалось додати вправу, оскільки не було завантажено зображення",
    },
    Delete: {
      SUCCESS: "Вправу успішно видалено",
      ERROR: "Не вдалось видалити вправу",
    },
    Edit: {
      SUCCESS: "Вправу успішно відредаговано",
      ERROR: "Не вдалось відредагувати вправу",
    },
    Internal: {
      INVALID_EXERCISE_ERROR: "Не вірний тип вправи",
    },
  },
};
