import { format } from "date-fns";
import { generateColorBasedOnString } from "utils/common";
import { ELessonType, TLesson } from "types/api/lesson";
import { TStudent } from "types/api/student";
import { TGroup } from "types/api/groups";
import { TScheduleEvent } from "types/app/schedule";

export const adaptScheduleEventData = (
  data: TLesson[],
  students: TStudent[],
  groups: TGroup[]
): TScheduleEvent[] => {
  /**
   * TODO: Refactor this adaptor and ask to include names in lessons.
   */
  const FALLBACK = "Unknown";

  const events: TScheduleEvent[] = [];
  for (const lesson of data) {
    const targetStudent = students.find(
      (student) => student.id === lesson.studentId
    )?.person;
    const studentName =
      targetStudent && `${targetStudent?.firstName} ${targetStudent?.lastName}`;

    const targetGroup = groups.find((group) => group.id === lesson.groupId);
    const groupName = targetGroup?.name;

    const title = lesson.type === ELessonType.SINGLE ? studentName : groupName;

    const eventColor = generateColorBasedOnString(title || FALLBACK);
    const event: TScheduleEvent = {
      id: String(lesson.id),
      title: title || FALLBACK,
      start: format(new Date(lesson.startDateTime), "yyyy-MM-dd HH:mm:ss"),
      end: format(new Date(lesson.endDateTime), "yyyy-MM-dd HH:mm:ss"),
      data: lesson,
      className: `nb-sub-schedule-event-color--${eventColor}`,
    };
    events.push(event);
  }
  return events;
};
