import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  ExerciseConstructorContent,
  CompareImageWithDescriptionExerciseContent as ExerciseContent,
} from "content";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { Space } from "components/Common/Space";
import { Textarea } from "components/Interactive/Textarea";
import { Select } from "components/Interactive/Select";
import { ImageListUpload } from "components/Interactive/ImageListUpload";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { COMPARE_IMAGE_WITH_DESCRIPTION_EXERCISE_MODE_OPTIONS } from "utils/options";
import {
  adaptExerciseImagesToData,
  getCompareImageWithDescriptionExerciseFormInitialValues,
} from "./CompareImageWithDescriptionExerciseForm.helpers";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import {
  EExerciseType,
  TCompareImageWithDescriptionExerciseImage,
  TCompareImageWithDescriptionExercisePayload,
} from "types/app/exercises";
import { TCompareImageWithDescriptionExerciseForm } from "./CompareImageWithDescriptionExerciseForm.types";
import "./CompareImageWithDescriptionExerciseForm.styles.scss";

export const CompareImageWithDescriptionExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TCompareImageWithDescriptionExercisePayload>();

  const { mutate: addExercise } =
    useAddExercise<TCompareImageWithDescriptionExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TCompareImageWithDescriptionExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TCompareImageWithDescriptionExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TCompareImageWithDescriptionExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TCompareImageWithDescriptionExerciseForm> =
    useMemo(
      () =>
        getCompareImageWithDescriptionExerciseFormInitialValues(editExercise),
      [editExercise]
    );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<TCompareImageWithDescriptionExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TCompareImageWithDescriptionExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TCompareImageWithDescriptionExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TCompareImageWithDescriptionExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TCompareImageWithDescriptionExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (
    formData: TCompareImageWithDescriptionExerciseForm
  ) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const isImagesValid: boolean = formData.images.some(
      (image) => image.imageEncoded !== null
    );
    if (!isImagesValid && !isEditMode) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR_NO_FILE,
      });
      return;
    }

    const parsedData = adaptExerciseImagesToData(
      formData.images as TCompareImageWithDescriptionExerciseImage[],
      editExercise?.payload.data.images
    );

    const addBody: TAddExercise<TCompareImageWithDescriptionExercisePayload> = {
      type: EExerciseType.COMPARE_IMAGE_WITH_DESCRIPTION,
      payload: {
        text: formData.text,
        data: {
          mode: formData.mode.value,
          ...parsedData,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TCompareImageWithDescriptionExercisePayload> =
        {
          id: editExercise.id,
          ...addBody,
        };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TCompareImageWithDescriptionExerciseForm>
          name="text"
          id="compare-image-with-description-exercise-text"
          className="nb-exercise-constructor-compare-image-with-description-text"
          label={ExerciseContent.Add.Input.Text.LABEL}
          placeholder={ExerciseContent.Add.Input.Text.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Select<TCompareImageWithDescriptionExerciseForm>
          name="mode"
          id="compare-image-with-description-exercise-mode"
          className="nb-exercise-constructor-compare-image-with-description-mode"
          placeholder={ExerciseContent.Add.Input.Mode.PLACEHOLDER}
          label={ExerciseContent.Add.Input.Mode.LABEL}
          options={COMPARE_IMAGE_WITH_DESCRIPTION_EXERCISE_MODE_OPTIONS}
          control={control}
          errors={errors}
          required
          fullWidth
        />
        <ImageListUpload<TCompareImageWithDescriptionExerciseForm>
          name="images"
          className="nb-exercise-constructor-compare-image-with-description-images"
          label={ExerciseContent.Add.Input.Images.LABEL}
          hint={ExerciseContent.Add.Input.Images.HINT}
          pickImageButtonText={ExerciseContent.Add.Input.Images.Button.PICK}
          addImageButtonText={ExerciseContent.Add.Input.Images.Button.ADD}
          changeImageButtonText={ExerciseContent.Add.Input.Images.Button.CHANGE}
          descriptionPlaceholder={
            ExerciseContent.Add.Input.Images.DESCRIPTION_PLACEHOLDER
          }
          control={control}
          register={register}
          errors={errors}
          fullWidth
          descriptionRequired
        />
      </Space>
    </ExerciseConfigForm>
  );
};
