import { Route as AppRoute } from "constants/routes";
import { TBreadcrumbsRoute } from "types/app/breadcrumbs";

export const breadcrumbs: TBreadcrumbsRoute[] = [
  {
    route: AppRoute.App.Schedule.ROOT,
    preRoutes: [],
  },
  {
    route: AppRoute.App.Study.ROOT,
    preRoutes: [],
  },
  {
    route: AppRoute.App.Study.STUDENTS,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
    ],
  },
  {
    route: AppRoute.App.Study.STUDENT_CLASSROOM,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
      {
        path: AppRoute.App.Study.STUDENTS.path,
        name: AppRoute.App.Study.STUDENTS.name,
        key: AppRoute.App.Study.STUDENTS.key,
      },
    ],
  },
  {
    route: AppRoute.App.Study.STUDENT_CLASSROOM_COURSE,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
      {
        path: AppRoute.App.Study.STUDENTS.path,
        name: AppRoute.App.Study.STUDENTS.name,
        key: AppRoute.App.Study.STUDENTS.key,
      },
    ],
  },
  {
    route: AppRoute.App.Study.STUDENT_CLASSROOM_COURSE_LESSON,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
      {
        path: AppRoute.App.Study.STUDENTS.path,
        name: AppRoute.App.Study.STUDENTS.name,
        key: AppRoute.App.Study.STUDENTS.key,
      },
    ],
  },
  {
    route: AppRoute.App.Study.TEACHERS,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
    ],
  },
  {
    route: AppRoute.App.Study.GROUPS,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
    ],
  },
  {
    route: AppRoute.App.Study.GROUP_DETAILS,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
      {
        path: AppRoute.App.Study.GROUPS.path,
        name: AppRoute.App.Study.GROUPS.name,
        key: AppRoute.App.Study.GROUPS.key,
      },
    ],
  },
  {
    route: AppRoute.App.Study.REPORTS,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
    ],
  },
  {
    route: AppRoute.App.Study.REQUESTS,
    preRoutes: [
      {
        path: AppRoute.App.Study.ROOT.path,
        name: AppRoute.App.Study.ROOT.name,
        key: AppRoute.App.Study.ROOT.key,
      },
    ],
  },
  {
    route: AppRoute.App.Materials.ROOT,
    preRoutes: [],
  },
  {
    route: AppRoute.App.Materials.COURSE_DETAILS,
    preRoutes: [
      {
        path: AppRoute.App.Materials.ROOT.path,
        name: AppRoute.App.Materials.ROOT.name,
        key: AppRoute.App.Materials.ROOT.key,
      },
    ],
  },
  {
    route: AppRoute.App.Finance.ROOT,
    preRoutes: [],
  },
  {
    route: AppRoute.App.Support.ROOT,
    preRoutes: [],
  },
  {
    route: AppRoute.App.User.SETTINGS,
    preRoutes: [],
  },
  {
    route: AppRoute.App.Admin.ROOT,
    preRoutes: [],
  },
  {
    route: AppRoute.App.Admin.LANDING,
    preRoutes: [
      {
        path: AppRoute.App.Admin.ROOT.path,
        name: AppRoute.App.Admin.ROOT.name,
        key: AppRoute.App.Admin.ROOT.key,
      },
    ],
  },
];
