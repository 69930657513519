export const DescribeGif = {
  Add: {
    Input: {
      Text: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Upload: {
        LABEL: "Додати зображення (.gif)",
        Button: {
          ADD: "Додати",
          CHANGE: "Змінити",
        },
      },
      Description: {
        LABEL: "Опис вправи",
        PLACEHOLDER:
          "Перегляньте гіф-зображення та коротко перекажіть, що на ньому відбувається...",
        HINT: "Підказка\n Напишіть текст, який дасть студенту розуміння, що йому потрібно зробити в цій вправі.",
      },
    },
    Modal: {
      TITLE: "Оберіть гіф-зображення",
      EMOJI: "🖼️",
      Button: {
        SELECT: "Обрати зображення",
        UPLOAD: "Додати це зображення",
      },
      Input: {
        LABEL: "Перетягніть або оберіть зображення",
      },
    },
  },
};
