import { useMemo, useRef } from "react";
import { nanoid } from "nanoid";
import { useForm } from "react-hook-form";
import cx from "classnames";
import { useLanguage } from "hooks/api/language";
import { useAddStudent } from "hooks/api/students";
import { useTeachersByLanguage } from "hooks/api/teachers";
import { Input } from "components/Interactive/Input";
import { Select } from "components/Interactive/Select";
import { Button } from "components/Interactive/Button";
import { ReactPortal } from "components/Common/ReactPortal";
import { StudentsContent, ValidationContent } from "content";
import { logSuccess } from "utils/notifications";
import { EMAIL_REGEX } from "constants/validation";
import { TTeacher } from "types/api/teacher";
import { ELanguageLevel } from "types/api/language";
import { TSelectOption } from "types/app/select";
import { TAddStudentForm } from "./AddStudentForm.types";
import "./AddStudentForm.styles.scss";

type AddStudentFormProps = {
  className?: string;
  onSubmit?: () => void;
};

export const AddStudentForm: React.FC<AddStudentFormProps> = (props) => {
  const { className, onSubmit } = props;

  const idRef = useRef<string>(nanoid());

  const { mutate } = useAddStudent();

  const { data: languageData } = useLanguage();
  const languageOptions: TSelectOption[] =
    languageData?.map((lang) => ({
      label: lang,
      value: lang,
    })) || [];
  const [initialLanguageOption] = languageOptions;
  const levelOptions: TSelectOption<ELanguageLevel>[] = Object.entries(
    ELanguageLevel
  ).map(([key, level]) => ({
    label: key,
    value: level,
  }));
  const [initialLevelOption] = levelOptions;

  const defaultValues: Partial<TAddStudentForm> = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      language: initialLanguageOption,
      languageLevel: initialLevelOption,
      price: "",
      teacher: undefined,
    }),
    [initialLanguageOption, initialLevelOption]
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<TAddStudentForm>({
    mode: "onBlur",
    defaultValues,
  });

  const currentLanguage = watch("language");

  const { data: teachersData } = useTeachersByLanguage(currentLanguage?.value);
  const teachers: TTeacher[] = teachersData?.content || [];
  const teacherOptions: TSelectOption<number>[] = teachers.map((teacher) => ({
    label: `${teacher.person.firstName} ${teacher.person.lastName}`,
    value: teacher.id,
  }));

  const handleAddStudentSubmit = (formData: TAddStudentForm) => {
    const student = {
      person: {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        language: formData.language.value,
        photoUrl: null,
      },
      languageLevel: formData.languageLevel.value,
      price: parseInt(formData.price),
      teacherId: formData.teacher.value,
    };

    mutate(student, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(StudentsContent.Add.Notification.SUCCESS);
        reset();
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-add-student-form", className])}
      onSubmit={handleSubmit(handleAddStudentSubmit)}
    >
      <Input<TAddStudentForm>
        name="firstName"
        id="add-student-first-name"
        label={StudentsContent.Add.Input.FirstName.LABEL}
        placeholder={StudentsContent.Add.Input.FirstName.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Input<TAddStudentForm>
        name="lastName"
        id="add-student-last-name"
        label={StudentsContent.Add.Input.LastName.LABEL}
        placeholder={StudentsContent.Add.Input.LastName.PLACEHOLDER}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <Input<TAddStudentForm>
        name="email"
        type="email"
        id="add-student-email"
        label={StudentsContent.Add.Input.Email.LABEL}
        placeholder={StudentsContent.Add.Input.Email.PLACEHOLDER}
        register={register}
        errors={errors}
        rules={{
          pattern: {
            value: EMAIL_REGEX,
            message: ValidationContent.Pattern.Email,
          },
        }}
        required
        fullWidth
      />
      <Input<TAddStudentForm>
        name="phone"
        type="tel"
        id="add-student-phone"
        label={StudentsContent.Add.Input.Phone.LABEL}
        placeholder={StudentsContent.Add.Input.Phone.PLACEHOLDER}
        minLength={12}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <div className="nb-sub-add-student-selects-wrapper">
        <Select<TAddStudentForm, TSelectOption>
          name="language"
          id="add-student-language"
          label={StudentsContent.Add.Input.Language.LABEL}
          placeholder={StudentsContent.Add.Input.Language.PLACEHOLDER}
          options={languageOptions}
          control={control}
          errors={errors}
          required
        />
        <Select<TAddStudentForm, TSelectOption<ELanguageLevel>>
          name="languageLevel"
          id="add-student-level"
          label={StudentsContent.Add.Input.Level.LABEL}
          placeholder={StudentsContent.Add.Input.Level.PLACEHOLDER}
          options={levelOptions}
          control={control}
          errors={errors}
          required
        />
      </div>
      <Select<TAddStudentForm, TSelectOption<number>>
        name="teacher"
        id="add-student-teacherId"
        label={StudentsContent.Add.Input.Teacher.LABEL}
        placeholder={StudentsContent.Add.Input.Teacher.PLACEHOLDER}
        options={teacherOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Input<TAddStudentForm>
        name="price"
        type="number"
        id="add-student-price"
        label={StudentsContent.Add.Input.Price.LABEL}
        placeholder={StudentsContent.Add.Input.Price.PLACEHOLDER}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <ReactPortal wrapperId="add-student-modal-footer">
        <Button
          className="nb-sub-add-student-submit"
          variant="primary"
          type="submit"
          form={idRef.current}
          fullWidth
        >
          {StudentsContent.Add.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
