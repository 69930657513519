import { forwardRef, useMemo } from "react";
import cx from "classnames";
import { useDraggable } from "@dnd-kit/core";
import { DragAndDropContent } from "content";
import { mergeRefs } from "utils/react";
import "./Draggable.styles.scss";

type TDraggableProps = {
  /**
   * The unique identifier of the draggable element.
   */
  draggableId: string | number;
  /**
   * Indicates if the draggable element that is being used in draggable overlay that is removed from the normal document flow and is positioned relative to the viewport, the `dragOverlay` will be `true`.
   * @default false
   */
  dragOverlay?: boolean;
  /**
   * Indicates if the draggable element that is currently being dragged is the current one where `useDraggable` is used, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates if draggable element has been placed in correct droppable.
   * @default false
   */
  correct?: boolean;
  /**
   * Indicates whenever to show draggable element result states.
   * @default false
   */
  showResult?: boolean;
  /**
   * Indicates whenever draggable element is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Indicates whenever draggable element is freezed.
   * Freezed draggable element can't be dragged and acts like a static element.
   * @default false
   */
  freezed?: boolean;
  /**
   * The data that will be passed to the droppable zone.
   */
  data?: unknown;
  /**
   * Inner text content of the component.
   */
  content?: string;
  /**
   * Aria label for the draggable element.
   * @default "Елемент, який можна перетягнути"
   */
  ariaLabel?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component container.
   */
  containerClassName?: string;
};

const Draggable: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  TDraggableProps
> = (props, ref) => {
  const {
    draggableId,
    dragOverlay = false,
    dragging = false,
    correct = false,
    showResult = false,
    disabled = false,
    freezed = false,
    data,
    content,
    className,
    containerClassName,
    ariaLabel = DragAndDropContent.Default.Draggable.LABEL,
  } = props;

  const { isDragging, setNodeRef, listeners, transform } = useDraggable({
    id: draggableId,
    data: { data: data ?? null },
    disabled: disabled || showResult,
  });

  const draggableStyles = useMemo(
    () => ({
      "--nb-drag-and-drop-draggable-translate-x": `${transform?.x ?? 0}px`,
      "--nb-drag-and-drop-draggable-translate-y": `${transform?.y ?? 0}px`,
    }),
    [transform]
  ) as React.CSSProperties;

  return (
    <div
      className={cx(
        "nb-drag-and-drop-draggable",
        {
          "nb-drag-and-drop-draggable--overlay": dragOverlay,
          "nb-drag-and-drop-draggable--dragging": dragging || isDragging,
        },
        containerClassName
      )}
      style={draggableStyles}
    >
      <button
        ref={mergeRefs([setNodeRef, ref])}
        aria-label={ariaLabel}
        className={cx([
          "nb-drag-and-drop-draggable-button",
          {
            "nb-drag-and-drop-draggable-button--correct": correct && showResult,
            "nb-drag-and-drop-draggable-button--fail": !correct && showResult,
            "nb-drag-and-drop-draggable-button--disabled": disabled,
            "nb-drag-and-drop-draggable-button--freezed": freezed,
          },
          className,
        ])}
        disabled={disabled || freezed || showResult}
        {...listeners}
      >
        {content}
      </button>
    </div>
  );
};

export const ForwardedDraggable = forwardRef(Draggable);
