import { Divider } from "components/Common/Divider";
import { Empty } from "components/Common/Empty";
import { StudySearch } from "../../components/StudySearch";
import { ReportsContent } from "content";
import { EStudySearchTarget } from "types/app/study";

export const Reports: React.FC = () => {
  return (
    <>
      <StudySearch
        searchTarget={EStudySearchTarget.Reports}
        searchPlaceholder={ReportsContent.Search.PLACEHOLDER}
      />
      <Divider margin="bottom" />
      <Empty />
    </>
  );
};
