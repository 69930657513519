import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EAppReducers } from "../../white-list";

type TBreadcrumbsState = {
  name: string;
};

const initialState: TBreadcrumbsState = {
  name: "",
};

export const breadcrumbsSlices = createSlice({
  name: EAppReducers.BREADCRUMBS,
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    resetName: (state) => {
      state.name = initialState.name;
    },
  },
});

export const breadcrumbsReducer = breadcrumbsSlices.reducer;
