export const DescribeImage = {
  Add: {
    Input: {
      Text: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      GalleryView: {
        LABEL: "Вид галереї",
        PLACEHOLDER: "Оберіть вид галереї",
      },
      Upload: {
        LABEL: "Додати зображення",
        HINT: "Підказка\n Прикріпіть зображення та напишіть текст, який дасть студенту розуміння, що йому потрібно зробити в цій вправі.",
        DESCRIPTION:
          "Перегляньте зображення та коротко перекажіть, що на ньому відбувається...",
        Button: {
          ADD: "Додати зображення",
          CHANGE: "Змінити зображення",
          PICK: "Прикріпити зображення",
        },
      },
    },
    Modal: {
      TITLE: "Оберіть зображення",
      EMOJI: "🖼️",
      Button: {
        SELECT: "Обрати зображення",
        UPLOAD: "Додати це зображення",
      },
      Input: {
        LABEL: "Перетягніть або оберіть зображення",
      },
    },
  },
  Option: {
    COLUMN: "Відкрити один під одним",
  },
};
