import { ValueContainerProps, components } from "react-select";
import { TAssertionSelectDefaultOption } from "../../../AssertionSentences.types";
import "./Value.styles.scss";

export const ValueContainer = <
  Option extends TAssertionSelectDefaultOption = TAssertionSelectDefaultOption
>({
  children,
  ...props
}: ValueContainerProps<Option, false>): JSX.Element => (
  <components.ValueContainer
    {...props}
    className="nb-interactive-true-or-false-assertion-select-value"
  >
    {children}
  </components.ValueContainer>
);
