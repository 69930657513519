import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const notesExercise: TExerciseItem = {
  title: "Замітка / Цікаві факти",
  backgroundText: "Замітка",
  tags: [
    "Замітка / Цікаві факти",
    "замітка",
    "замітки",
    "нотатки",
    "цікаві",
    "факти",
    "notes",
    "fun",
    "facts",
    "interesting",
    "curious",
  ],
  type: EExerciseType.TEACHER_NOTES,
  cover: "notes.png",
};
