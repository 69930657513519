import { useMutation } from "react-query";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { EAuthQueryKeys } from "api/endpoints/auth";
import { loginUser } from "api/services/auth";
import { logError } from "utils/notifications";
import { TLogin, TLoginCredentials } from "types/api/auth";
import { TApiError } from "types/api/error";

export const useLogin = () => {
  const { setActiveUser } = useActiveUser();

  return useMutation<TLogin, TApiError, TLoginCredentials>(
    EAuthQueryKeys.login,
    loginUser,
    {
      onSuccess(data) {
        setActiveUser({
          ...data.person,
          studentId: data.studentId,
          teacherId: data.teacherId,
        });
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
