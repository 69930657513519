import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useLanguage } from "hooks/api/language";
import { useAddGroup } from "hooks/api/groups";
import { useStudents } from "hooks/api/students";
import { useTeachersByLanguage } from "hooks/api/teachers";
import { Input } from "components/Interactive/Input";
import { Select } from "components/Interactive/Select";
import { PersonSelect } from "components/Interactive/PersonSelect";
import { Button } from "components/Interactive/Button";
import { Avatar } from "components/Common/Avatar";
import { ReactPortal } from "components/Common/ReactPortal";
import { GroupsContent } from "content";
import { logSuccess } from "utils/notifications";
import { TTeacher } from "types/api/teacher";
import { TStudent } from "types/api/student";
import { ELanguageLevel } from "types/api/language";
import { TPersonSelectOption, TSelectOption } from "types/app/select";
import { TAddGroupForm } from "./AddGroupForm.types";
import "./AddGroupForm.styles.scss";

type AddGroupFormProps = {
  /**
   * Callback function fires on add group submit event.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const AddGroupForm: React.FC<AddGroupFormProps> = (props) => {
  const { className, onSubmit } = props;

  const [studentSearchValue, setStudentSearchValue] = useState<string>("");
  const idRef = useRef<string>(nanoid());

  const { mutate: addGroupHandler } = useAddGroup();
  const { data: studentsData } = useStudents({
    searchText: studentSearchValue,
  });
  const { data: languageData, isSuccess: isLanguageSuccess } = useLanguage();

  const studentsContent: TStudent[] = studentsData?.content || [];
  const students: TPersonSelectOption[] = studentsContent.map((student) => ({
    id: student.id,
    name: `${student.person.firstName} ${student.person.lastName}`,
    description: student.person.email,
  }));

  const languageOptions: TSelectOption[] = useMemo(
    () =>
      languageData?.map((lang) => ({
        label: lang,
        value: lang,
      })) || [],
    [languageData]
  );
  const [initialLanguageOption] = languageOptions;
  const levelOptions: TSelectOption<ELanguageLevel>[] = Object.entries(
    ELanguageLevel
  ).map(([key, level]) => ({
    label: key,
    value: level,
  }));
  const [initialLevelOption] = levelOptions;

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<TAddGroupForm>({
    mode: "onBlur",
    defaultValues: {
      languageLevel: initialLevelOption,
    },
  });

  useEffect(() => {
    const isLanguageFieldTouched = !!touchedFields.language;
    if (isLanguageSuccess && !isLanguageFieldTouched) {
      setValue("language", initialLanguageOption);
    }
  }, [
    isLanguageSuccess,
    touchedFields.language,
    initialLanguageOption,
    setValue,
  ]);

  const currentLanguage = watch("language");

  const { data: teachersData } = useTeachersByLanguage(currentLanguage?.value);
  const teachers: TTeacher[] = teachersData?.content || [];
  const teacherOptions: TSelectOption<number>[] = teachers.map((teacher) => ({
    label: `${teacher.person.firstName} ${teacher.person.lastName}`,
    value: teacher.id,
  }));

  const handleAddGroupSubmit = (formData: TAddGroupForm) => {
    const group = {
      name: formData.name,
      teacherId: formData.teacher.value,
      language: formData.language.value,
      level: formData.languageLevel.value,
      memberIds: formData.members.map((member) => member.id),
    };

    addGroupHandler(group, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(GroupsContent.Add.Notification.SUCCESS);
        reset({
          language: initialLanguageOption,
          languageLevel: initialLevelOption,
          members: [],
          name: "",
        });
      },
    });
  };

  const studentsSearchCallback = useCallback(
    async (): Promise<TPersonSelectOption[]> => Promise.resolve(students),
    [students]
  );

  const studentsSearchHandler = (searchValue: string) => {
    setStudentSearchValue(searchValue);
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-add-group-form", className])}
      onSubmit={handleSubmit(handleAddGroupSubmit)}
    >
      <div className="nb-sub-add-group-first-row">
        {/* TODO: Replace Avatar placeholder with photo upload when backend will be ready */}
        <Avatar
          bordered
          size="large"
          emoji={GroupsContent.Add.EMOJI}
          className="nb-sub-add-group-avatar"
        />
        <Input<TAddGroupForm>
          name="name"
          id="add-group-name"
          className="nb-sub-add-group-name-input"
          label={GroupsContent.Add.Input.Name.LABEL}
          placeholder={GroupsContent.Add.Input.Name.PLACEHOLDER}
          register={register}
          errors={errors}
          autoFocus
          required
          fullWidth
        />
      </div>
      <Select<TAddGroupForm, TSelectOption<number>>
        name="teacher"
        id="add-group-teacherId"
        label={GroupsContent.Add.Input.Teacher.LABEL}
        placeholder={GroupsContent.Add.Input.Teacher.PLACEHOLDER}
        options={teacherOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Select<TAddGroupForm, TSelectOption>
        name="language"
        id="add-group-language"
        label={GroupsContent.Add.Input.Language.LABEL}
        placeholder={GroupsContent.Add.Input.Language.PLACEHOLDER}
        options={languageOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Select<TAddGroupForm, TSelectOption<ELanguageLevel>>
        name="languageLevel"
        id="add-group-level"
        label={GroupsContent.Add.Input.Level.LABEL}
        placeholder={GroupsContent.Add.Input.Level.PLACEHOLDER}
        options={levelOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <PersonSelect<TAddGroupForm, TPersonSelectOption, true>
        name="members"
        id="add-group-members"
        label={GroupsContent.Add.Input.Participants.LABEL}
        placeholder={GroupsContent.Add.Input.Participants.PLACEHOLDER}
        searchCallback={studentsSearchCallback}
        onSearchChange={studentsSearchHandler}
        control={control}
        errors={errors}
        defaultOptions={students}
        multi
        menuAlwaysOpen
        required
        fullWidth
      />
      <ReactPortal wrapperId="add-group-modal-footer">
        <Button variant="primary" type="submit" form={idRef.current} fullWidth>
          {GroupsContent.Add.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
