import { useMutation } from "react-query";
import { editRequest } from "api/services/requests";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TEditRequestBody, TRequest } from "types/api/requests";

export const useEditRequest = () => {
  return useMutation<TRequest, TApiError, TEditRequestBody>(editRequest, {
    onError: (error) => logError({ error }),
    retry: false,
  });
};
