import { useRef } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useLanguage } from "hooks/api/language";
import { useAddTeacher } from "hooks/api/teachers";
import { Avatar } from "components/Common/Avatar";
import { Input } from "components/Interactive/Input";
import { Select } from "components/Interactive/Select";
import { Button } from "components/Interactive/Button";
import { ReactPortal } from "components/Common/ReactPortal";
import { TeachersContent, ValidationContent } from "content";
import { logSuccess } from "utils/notifications";
import { EMAIL_REGEX } from "constants/validation";
import { TSelectOption } from "types/app/select";
import { TAddTeacherForm } from "./AddTeacherForm.types";
import "./AddTeacherForm.styles.scss";

type AddTeacherFormProps = {
  className?: string;
  onSubmit?: () => void;
};

export const AddTeacherForm: React.FC<AddTeacherFormProps> = (props) => {
  const { className, onSubmit } = props;

  const idRef = useRef<string>(nanoid());

  const { data: languageData } = useLanguage();
  const { mutate } = useAddTeacher();

  const languageOptions: TSelectOption[] =
    languageData?.map((lang) => ({
      label: lang,
      value: lang,
    })) || [];

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddTeacherForm>({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });

  const handleAddTeacherSubmit = (formData: TAddTeacherForm) => {
    const teacher = {
      person: {
        ...formData,
        language: formData.language.value,
        photoUrl: null,
      },
    };

    mutate(teacher, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(TeachersContent.Add.Notification.SUCCESS);
        reset();
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-add-teacher-form", className])}
      onSubmit={handleSubmit(handleAddTeacherSubmit)}
    >
      <div className="nb-sub-add-teacher-avatar-wrapper">
        <Avatar bordered size="large" />
      </div>
      <Input<TAddTeacherForm>
        name="firstName"
        id="add-teacher-first-name"
        label={TeachersContent.Add.Input.FirstName.LABEL}
        placeholder={TeachersContent.Add.Input.FirstName.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Input<TAddTeacherForm>
        name="lastName"
        id="add-teacher-last-name"
        label={TeachersContent.Add.Input.LastName.LABEL}
        placeholder={TeachersContent.Add.Input.LastName.PLACEHOLDER}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <Input<TAddTeacherForm>
        name="email"
        type="email"
        id="add-teacher-email"
        label={TeachersContent.Add.Input.Email.LABEL}
        placeholder={TeachersContent.Add.Input.Email.PLACEHOLDER}
        register={register}
        errors={errors}
        rules={{
          pattern: {
            value: EMAIL_REGEX,
            message: ValidationContent.Pattern.Email,
          },
        }}
        required
        fullWidth
      />
      <Input<TAddTeacherForm>
        name="phone"
        type="tel"
        id="add-teacher-phone"
        label={TeachersContent.Add.Input.Phone.LABEL}
        placeholder={TeachersContent.Add.Input.Phone.PLACEHOLDER}
        minLength={12}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <Select<TAddTeacherForm, TSelectOption>
        name="language"
        id="add-teacher-language"
        label={TeachersContent.Add.Input.Language.LABEL}
        placeholder={TeachersContent.Add.Input.Language.PLACEHOLDER}
        options={languageOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <ReactPortal wrapperId="add-teacher-modal-footer">
        <Button
          className="nb-sub-add-teacher-submit"
          variant="primary"
          type="submit"
          form={idRef.current}
          fullWidth
        >
          {TeachersContent.Add.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
