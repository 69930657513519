import { useMutation, useQueryClient } from "react-query";
import { removeTeacher } from "api/services/teachers";
import { ETeachersQueryKeys } from "api/endpoints/teachers";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useRemoveTeacher = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(removeTeacher, {
    onSuccess: () => {
      queryClient.invalidateQueries(ETeachersQueryKeys.getTeachers);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
