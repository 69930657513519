import { useCallback, useState } from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import {
  ControlProps,
  MenuProps,
  NoticeProps,
  PlaceholderProps,
  createFilter,
} from "react-select";
import AsyncSelect from "react-select/async";
import cx from "classnames";
import { SearchControl } from "./components/Control";
import { SearchInput } from "./components/Input";
import { SearchLoadingMessage } from "./components/LoadingMessage";
import { SearchMenu } from "./components/Menu";
import { SearchNoOptionsMessage } from "./components/NoOptionsMessage";
import { SearchOption } from "./components/Option";
import { SearchSingleValue } from "./components/SingleValue";
import { SearchPlaceholder } from "./components/Placeholder";
import { SearchContent, ValidationContent } from "content";
import type { TSelectOption } from "types/app/select";
import "./Search.styles.scss";

type SearchProps<
  TFormValues extends FieldValues = FieldValues,
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
> = {
  /**
   * Name attribute of the `Search` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: FieldPath<TFormValues>;
  /**
   * 	Validation rules in the same format for register, which includes: required, min, max, minLength, maxLength, pattern, validate
   *
   * @example
   * ```tsx
   * rules={{
   *  pattern: {
   *    value: EMAIL_REGEX,
   *    message: "Email address is invalid",
   *  }
   * }}
   * ```
   * @link https://react-hook-form.com/api/useform/register#options
   */
  rules?: RegisterOptions;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * Function that returns a promise, which is the set of options to be used once the promise resolves.
   * Advice: Use debounce pattern for this callback for better performance.
   */
  searchCallback?: (searchValue: string) => Promise<Option[]>;
  /**
   * The default set of options to show before the user starts searching.
   * When set to `on-mount`, the results for searchCallback will be loaded on `Search` mount.
   * When set to `on-change`, options initially will be empty until user starts searching.
   *
   * @default "on-change"
   */
  defaultOptionsMode?: "on-mount" | "on-change";
  /**
   * Is the Search value clearable
   *
   * @default {false}
   */
  clearable?: boolean;
  /**
   * Is the Search disabled
   *
   * @default {false}
   */
  disabled?: boolean;
  /**
   * If `true`, the `Search` element is required.
   *
   * @default {false}
   */
  required?: boolean;
  /**
   * If `true`, the `Search` will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the `Search` will light grey border.
   *
   * @default false
   */
  bordered?: boolean;
  /**
   * If `false`, the `Search` will fire change event only on option select.
   * If `true`, the `Search` will fire change event on search input.
   *
   * @default false
   */
  filterSearch?: boolean;
  /**
   * If `false`, the `Search` will always open options menu with suggestions.
   * If `true`, the `Search` will never display option menu.
   *
   * @default false
   */
  hideMenu?: boolean;
  /**
   * The short hint displayed in the `Search` before the user input a value.
   */
  placeholder?: string;
  /**
   * Search label text for the `Search` element.
   */
  label?: string;
  /**
   * Message to be displayed in the menu if there are no options available.
   */
  noOptionsText?: string;
  /**
   * Message to display in the menu when there are no options and loading state is true.
   */
  loadingText?: string;
  /**
   * The id of the `Search` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * Search interactive element based on `react-select` library in association with `react-hook-form`
 */
export const Search = <
  TFormValues extends FieldValues = FieldValues,
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>(
  props: SearchProps<TFormValues, Option>
): JSX.Element => {
  const {
    clearable = false,
    disabled = false,
    required = false,
    fullWidth = false,
    bordered = false,
    filterSearch = false,
    hideMenu = false,
    defaultOptionsMode = "on-change",
    id,
    label,
    name,
    rules,
    control,
    placeholder = SearchContent.DEFAULT.PLACEHOLDER,
    className,
    noOptionsText = SearchContent.DEFAULT.EMPTY,
    loadingText = SearchContent.DEFAULT.LOADING,
    searchCallback,
  } = props;

  const innerRules: RegisterOptions = {
    ...(required && { required: ValidationContent.Required }),
    ...rules,
    disabled,
  };

  const [inputValue, setInputValue] = useState<string>("");

  const promiseOptions = (searchValue: string): Promise<Option[]> => {
    if (!searchCallback) {
      return Promise.resolve([]);
    }

    return searchCallback(searchValue);
  };

  const SearchControlMemorized = useCallback(
    (controlProps: ControlProps<Option, false>) => (
      <SearchControl<Option>
        {...controlProps}
        bordered={bordered}
        menuHidden={hideMenu}
      />
    ),
    [bordered, hideMenu]
  );

  const SearchNoOptionsMessageMemorized = useCallback(
    (noticeProps: NoticeProps<Option, false>) => (
      <SearchNoOptionsMessage<Option>
        {...noticeProps}
        noOptionsText={noOptionsText}
      />
    ),
    [noOptionsText]
  );

  const SearchMenuMemorized = useCallback(
    (menuProps: MenuProps<Option, false>) =>
      hideMenu ? null : (
        <SearchMenu<Option> {...menuProps} bordered={bordered} />
      ),
    [bordered, hideMenu]
  );

  const SearchLoadingMessageMemorized = useCallback(
    (noticeProps: NoticeProps<Option, false>) => (
      <SearchLoadingMessage<Option>
        {...noticeProps}
        loadingText={loadingText}
      />
    ),
    [loadingText]
  );

  const SearchPlaceholderMemorized = useCallback(
    (placeholderProps: PlaceholderProps<Option, false>) => (
      <SearchPlaceholder<Option>
        {...placeholderProps}
        placeholder={placeholder}
      />
    ),
    [placeholder]
  );

  return (
    <div
      className={cx(["nb-interactive-search-wrapper", className])}
      aria-live="polite"
    >
      {label && (
        <label className="nb-interactive-search-label" htmlFor={id}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={innerRules}
        render={({ field }) => (
          <AsyncSelect
            {...field}
            onChange={(newValue) => {
              const labelValue = newValue?.label;
              if (labelValue) {
                setInputValue(labelValue);
              }
              if (filterSearch && labelValue) {
                field.onChange(labelValue);
              } else if (filterSearch && newValue === null) {
                field.onChange("");
              } else if (!filterSearch && newValue === null) {
                field.onChange(undefined);
              } else {
                field.onChange(newValue);
              }
            }}
            value={field.value as Option}
            isClearable={clearable}
            isDisabled={disabled}
            isMulti={false}
            required={required}
            isSearchable
            placeholder={placeholder}
            id={id}
            cacheOptions
            defaultOptions={defaultOptionsMode === "on-change" ? [] : true}
            filterOption={createFilter({
              ignoreCase: true,
              ignoreAccents: true,
              matchFrom: "any",
              stringify: (option) => option.label,
            })}
            getOptionValue={(option) => option.value.toString()}
            loadOptions={promiseOptions}
            inputValue={field.value === "" ? "" : inputValue}
            onInputChange={(newValue, meta) => {
              if (
                meta.action !== "input-blur" &&
                meta.action !== "menu-close"
              ) {
                setInputValue(newValue);
                if (filterSearch) {
                  field.onChange(newValue);
                }
              }
            }}
            className={cx([
              "nb-interactive-search",
              {
                "nb-interactive-search--full": fullWidth,
              },
            ])}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
              Control: SearchControlMemorized,
              Menu: SearchMenuMemorized,
              Option: SearchOption,
              SingleValue: SearchSingleValue,
              NoOptionsMessage: SearchNoOptionsMessageMemorized,
              LoadingMessage: SearchLoadingMessageMemorized,
              Input: SearchInput,
              Placeholder: SearchPlaceholderMemorized,
            }}
          />
        )}
      />
    </div>
  );
};
