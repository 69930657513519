import { useCallback, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 * @param ref Ref to the element
 * @param outsideCallback Callback function to be called when clicked outside
 * @param insideCallback Callback function to be called when clicked inside
 * @returns void
 */
export const useOutsideClick = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  outsideCallback: (event: MouseEvent) => void,
  insideCallback?: (event: MouseEvent) => void
): void => {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      const isOutside =
        ref.current && !ref.current.contains(event.target as Node);
      if (isOutside) {
        outsideCallback(event);
      }
      if (!isOutside) {
        insideCallback?.(event);
      }
    },
    [ref, outsideCallback, insideCallback]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  });
};
