import { ActionMeta, ControlProps, MultiValue, components } from "react-select";
import cx from "classnames";
import { CustomMultiValueContainer } from "../CustomMultiValueContainer";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { TPersonSelectOption } from "types/app/select";
import "./Control.styles.scss";

type ControlCustomProps<
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
> = {
  multi: IsMulti | false;
} & ControlProps<Option, IsMulti>;

export const Control = <
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
>({
  children,
  menuIsOpen,
  isFocused,
  multi = false,
  ...controlProps
}: ControlCustomProps<Option, IsMulti>): JSX.Element => {
  return (
    <>
      <components.Control
        {...controlProps}
        menuIsOpen={menuIsOpen}
        isFocused={isFocused}
        className={cx([
          "nb-interactive-person-select-control",
          {
            "nb-interactive-person-select-control--open": menuIsOpen,
            "nb-interactive-person-select-control--focused": isFocused,
          },
        ])}
      >
        <SearchIcon className="nb-interactive-person-select-control-icon" />
        {children}
      </components.Control>
      {multi && (
        <CustomMultiValueContainer<Option>
          onChange={
            // Should hardcode correct type because generic do not understand that this component renders on condition
            controlProps.selectProps.onChange as (
              newValue: MultiValue<Option>,
              actionMeta: ActionMeta<Option>
            ) => void
          }
          values={controlProps.getValue()}
        />
      )}
    </>
  );
};
