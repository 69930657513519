import { InputProps, components } from "react-select";
import type { TSelectDefaultOption } from "../../Select.types";
import "./Input.styles.scss";

export const Input = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>(
  props: InputProps<Option, false>
): JSX.Element => (
  <components.Input className="nb-interactive-select-input" {...props} />
);
