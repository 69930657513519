import axios, { AxiosRequestConfig } from "axios";

type TRequestArgs = [string, unknown?, AxiosRequestConfig?];
type TGetRequestArgs = [string, AxiosRequestConfig?];

axios.defaults.baseURL =
  `${process.env.REACT_APP_BACKEND_URL}/api/v1` ||
  "http://localhost:8080/api/v1";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

export const get = async <TData = unknown>(
  ...args: TGetRequestArgs
): Promise<TData> => {
  const response = await axios.get<TData>(...args);
  return response.data;
};

export const post = async <TData = unknown>(
  ...args: TRequestArgs
): Promise<TData> => {
  const response = await axios.post<TData>(...args);
  return response.data;
};

export const put = async <TData = unknown>(
  ...args: TRequestArgs
): Promise<TData> => {
  const response = await axios.put<TData>(...args);
  return response.data;
};

export const remove = async <TData = unknown>(
  ...args: TGetRequestArgs
): Promise<void> => {
  await axios.delete<TData>(...args);
};
