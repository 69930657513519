import "./LessonContainer.styles.scss";

type LessonContainerProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const LessonContainer: React.FC<LessonContainerProps> = (props) => {
  const { children } = props;

  return <div className="nb-lesson-details-container">{children}</div>;
};
