import { ControlProps, components } from "react-select";
import cx from "classnames";
import { TAssertionSelectDefaultOption } from "../../../AssertionSentences.types";
import "./Control.styles.scss";

type SelectControlProps<
  Option extends TAssertionSelectDefaultOption = TAssertionSelectDefaultOption
> = ControlProps<Option, false>;

export const Control = <
  Option extends TAssertionSelectDefaultOption = TAssertionSelectDefaultOption
>({
  children,
  menuIsOpen,
  isFocused,
  isDisabled,
  ...controlProps
}: SelectControlProps<Option>): JSX.Element => {
  return (
    <components.Control
      {...controlProps}
      menuIsOpen={menuIsOpen}
      isFocused={isFocused}
      isDisabled={isDisabled}
      className={cx([
        "nb-interactive-true-or-false-assertion-select-control",
        {
          "nb-interactive-true-or-false-assertion-select-control--open":
            menuIsOpen,
          "nb-interactive-true-or-false-assertion-select-control--focused":
            isFocused,
          "nb-interactive-true-or-false-assertion-select-control--disabled":
            isDisabled,
        },
      ])}
    >
      {children}
    </components.Control>
  );
};
