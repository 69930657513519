import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "hooks/api/auth";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { useRole } from "hooks/common";
import { NavigationContent } from "content";
import { Divider } from "components/Common/Divider";
import { Text } from "components/Typography/Text";
import { Route } from "constants/routes";
import { getRoleContent } from "utils/content";
import "./ProfileDropdownContent.styles.scss";

export const ProfileDropdownContent: React.FC = () => {
  const navigate = useNavigate();
  const { mutate: logout } = useLogout();
  const { activeUser } = useActiveUser();
  const { isUserAdmin } = useRole();

  const logoutButtonClickHandler = () => {
    logout(null, {
      onSuccess: () => {
        navigate(Route.Auth.Login.ROOT.path);
      },
    });
  };

  return (
    <div className="nb-interactive-profile-dropdown-content">
      <div className="nb-interactive-profile-dropdown-header">
        <Text
          variant="body1"
          bold
          className="nb-interactive-profile-dropdown-user-name"
        >
          {activeUser?.firstName}&nbsp;{activeUser?.lastName}
        </Text>
        <Text
          variant="body2"
          className="nb-interactive-profile-dropdown-user-role"
        >
          {getRoleContent(activeUser?.role.name)}
        </Text>
        <Divider color="grey-100" margin="top" marginSize="medium" />
      </div>
      <ul className="nb-interactive-profile-dropdown-list">
        <li
          className="nb-interactive-profile-dropdown-item"
          data-dropdown-closable="true"
        >
          <Link to={Route.App.User.SETTINGS.path}>
            {NavigationContent.Profile.Settings}
          </Link>
        </li>
        {isUserAdmin && (
          <li
            className="nb-interactive-profile-dropdown-item"
            data-dropdown-closable="true"
          >
            <Link to={Route.App.Admin.LANDING.path}>
              {NavigationContent.Profile.LandingDashboard}
            </Link>
          </li>
        )}
        <li
          className="nb-interactive-profile-dropdown-item"
          data-dropdown-closable="true"
        >
          <button type="button" onClick={logoutButtonClickHandler}>
            {NavigationContent.Profile.Logout}
          </button>
        </li>
      </ul>
    </div>
  );
};
