import { useQuery } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { getCourseLessons } from "api/services/courses";
import { logError } from "utils/notifications";
import {
  TCourseLessonRequestParams,
  TCourseLessonResponse,
} from "types/api/course";
import { TApiError } from "types/api/error";

export const useCourseLessons = (
  courseId?: string | number,
  options?: TCourseLessonRequestParams
) => {
  return useQuery<TCourseLessonResponse, TApiError>(
    [ECoursesQueryKeys.getAllCourseLessons, courseId, options],
    () => getCourseLessons(courseId, options),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: courseId !== undefined,
    }
  );
};
