import { useState } from "react";
import Cropper, { Area, Point } from "react-easy-crop";
import cx from "classnames";
import { FileUploadContent } from "content";
import { Text } from "components/Typography/Text";
import "./ImageCropper.styles.scss";

export type TImageCropperProps = {
  /**
   * The image as base64 url encoded string to be cropped.
   */
  image?: string | null;
  /**
   * Multiplies the value by which the zoom changes.
   * @default 1
   */
  zoomSpeed?: number;
  /**
   * Aspect of the cropper. The value is the ratio between its width and its height.
   * @default 1
   */
  aspect?: number;
  /**
   * Minimum zoom of the media.
   * @default 1
   */
  minZoom?: number;
  /**
   * Maximum zoom of the media.
   * @default 3
   */
  maxZoom?: number;
  /**
   * Zoom of the media between `minZoom` and `maxZoom` props.
   * @default 1
   */
  defaultZoom?: number;
  /**
   * Default crop position of the media. Points equals to zeros (default values) will center the media under the cropper.
   * @default { x: 0, y: 0 }
   */
  defaultCrop?: Point;
  /**
   * Whether to show or not the grid (third-lines).
   * @default false
   */
  showGrid?: boolean;
  /**
   * Shape of the crop area.
   * @default "rect"
   */
  cropShape?: "rect" | "round";
  /**
   * Message to display in the area when there are file reading process is running.
   */
  loadingText?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Called when the user stops moving the media or stops zooming.
   * It will be passed the corresponding cropped area on the media in percentages and pixels (rounded to the nearest integer).
   * @param croppedArea coordinates and dimensions of the cropped area in percentage of the media dimension
   * @param croppedAreaPixels coordinates and dimensions of the cropped area in pixels.
   */
  onCropComplete?: (croppedArea: Area, croppedAreaPixels: Area) => void;
};

/**
 * `ImageCropper` component to crop images with easy interactions based on `react-easy-crop` library.
 */
export const ImageCropper: React.FC<TImageCropperProps> = (props) => {
  const {
    image,
    zoomSpeed = 1,
    aspect = 1,
    minZoom = 1,
    maxZoom = 3,
    defaultZoom = 1,
    defaultCrop = { x: 0, y: 0 },
    showGrid = false,
    cropShape = "rect",
    loadingText = FileUploadContent.Cropper.LOADING,
    className,
    onCropComplete,
  } = props;

  const [crop, setCrop] = useState<Point>(defaultCrop);
  const [zoom, setZoom] = useState<number>(defaultZoom);

  return (
    <div className={cx(["nb-interactive-file-upload-cropper", className])}>
      {image ? (
        <Cropper
          image={image}
          crop={crop}
          cropShape={cropShape}
          minZoom={minZoom}
          maxZoom={maxZoom}
          zoom={zoom}
          zoomSpeed={zoomSpeed}
          aspect={aspect}
          showGrid={showGrid}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      ) : (
        <div className="nb-interactive-file-upload-cropper-loader">
          <Text
            variant="body2"
            className="nb-interactive-file-upload-cropper-loader-text"
          >
            {loadingText}
          </Text>
        </div>
      )}
    </div>
  );
};
