import {
  TExerciseIdentifier,
  TSortWordsExerciseParsedColumn,
  TSortWordsExerciseParsedWord,
} from "types/app/exercises";

export const START_ZONE_ID = "sort-words-exercise-start-zone";

export const MAX_SORT_WORDS_ATTEMPTS = 3;

/**
 * Helper function that allows to update a word data on removing operation.
 * @param word word data that should be updated.
 * @param destinationColumn destination column data where the target word should be moved.
 * @returns updated array of words without targeted word.
 */
const updateWordDataOnColumnEnter = (
  word: TSortWordsExerciseParsedWord,
  destinationColumn: TSortWordsExerciseParsedColumn
): TSortWordsExerciseParsedWord => ({
  ...word,
  attempts: [...word.attempts, destinationColumn.id],
  currentAttempt: word.currentAttempt + 1,
  currentColumnId: destinationColumn.id,
  isTouched: true,
  isCorrectDestination: word.correctColumnId === destinationColumn.id,
  isAllAttemptsFailed: word.currentAttempt + 1 === MAX_SORT_WORDS_ATTEMPTS,
});

/**
 * Helper function that allows to update a word data on adding operation.
 * @param word word data that should be updated.
 * @returns updated array of words without targeted word.
 */
const updateWordDataOnColumnLeave = (
  word: TSortWordsExerciseParsedWord
): TSortWordsExerciseParsedWord => ({
  ...word,
  currentColumnId: null,
  isTouched: true,
  isCorrectDestination: false,
});

/**
 * Helper function that allows to update an array of start zone words by adding new item.
 * @param words an array of words that are located in the start zone area.
 * @param newWord new word data that should be added to the existing start zone words array.
 * @returns updated array of words with new word.
 */
export const addWordToStartZone = (
  words: TSortWordsExerciseParsedWord[],
  newWord: TSortWordsExerciseParsedWord
): TSortWordsExerciseParsedWord[] =>
  words.concat(updateWordDataOnColumnLeave(newWord));

/**
 * Helper function that allows to update an array of start zone words by removing requested item.
 * @param words an array of words that are located in the start zone area.
 * @param targetWordId identifier of the target word that should be removed from start zone.
 * @param destinationColumn destination column data where the target word should be moved.
 * @returns updated array of words without targeted word.
 */
export const removeWordFromStartZone = (
  words: TSortWordsExerciseParsedWord[],
  targetWordId: TExerciseIdentifier,
  destinationColumn: TSortWordsExerciseParsedColumn
): TSortWordsExerciseParsedWord[] =>
  words
    .map((word) =>
      word.id === targetWordId
        ? updateWordDataOnColumnEnter(word, destinationColumn)
        : word
    )
    .filter((word) => word.id !== targetWordId);

/**
 * Helper function that allows updating an array of start zone words by replacing a word.
 * @param words an array of words that are located in the start zone area.
 * @param draggedWordId identifier of the dragged word that should be replaced.
 * @param replaceWord replace word data that should take place of the dragged word.
 * @returns updated array of words with replaced word.
 */
export const replaceWordInStartZone = (
  words: TSortWordsExerciseParsedWord[],
  draggedWordId: TExerciseIdentifier,
  replaceWord: TSortWordsExerciseParsedWord
): TSortWordsExerciseParsedWord[] =>
  words.map((word) =>
    word.id === draggedWordId ? updateWordDataOnColumnLeave(replaceWord) : word
  );

/**
 * Helper function that allows updating an array of exercise columns by adding a word to the targeted column.
 * @param columns an array of all columns that exist in an exercise.
 * @param targetColumnId identifier of the targeted column that should be updated.
 * @param newWordId new word ID that should be added to the targeted column.
 * @returns updated array of columns with new word in targeted column.
 */
export const addWordToColumn = (
  columns: TSortWordsExerciseParsedColumn[],
  targetColumnId: TExerciseIdentifier,
  newWordId: TExerciseIdentifier
): TSortWordsExerciseParsedColumn[] => {
  const newWord = columns
    .find((column) => column.correctWords.some((word) => word.id === newWordId))
    ?.correctWords.find((word) => word.id === newWordId);

  return columns.map((column) =>
    column.id === targetColumnId && newWord
      ? {
          ...column,
          isEmpty: false,
          currentWords: [
            ...column.currentWords,
            updateWordDataOnColumnEnter(newWord, column),
          ],
        }
      : column
  );
};

/**
 * Helper function that allows updating an array of exercise columns by replacing a word.
 * Which means that word in target column will replace with new given word.
 * @param columns an array of all columns that exist in an exercise.
 * @param targetColumnId target column ID with a word which should be moved to the destination blank.
 * @param newWordId new word ID that should be added to the targeted column.
 * @param replaceWordId replace word ID that should be replaced in the targeted column.
 * @returns updated array of columns with replaced word in targeted column.
 */
export const replaceWordInColumn = (
  columns: TSortWordsExerciseParsedColumn[],
  targetColumnId: TExerciseIdentifier,
  newWordId: TExerciseIdentifier,
  replaceWordId: TExerciseIdentifier
): TSortWordsExerciseParsedColumn[] => {
  const newWord = columns
    .find((column) => column.correctWords.some((word) => word.id === newWordId))
    ?.correctWords.find((word) => word.id === newWordId);

  return columns.map((column) =>
    column.id === targetColumnId && newWord
      ? {
          ...column,
          isEmpty: false,
          currentWords: column.currentWords.map((word) =>
            word.id === replaceWordId
              ? updateWordDataOnColumnEnter(newWord, column)
              : word
          ),
        }
      : column
  );
};

/**
 * Helper function that allows updating an array of exercise columns by removing an word from targeted column.
 * @param columns an array of all columns that exist in an exercise.
 * @param targetWordId target word ID that should be removed from the targeted column.
 * @returns updated array of columns without targeted word.
 */
export const removeWordFromColumn = (
  columns: TSortWordsExerciseParsedColumn[],
  targetWordId: TExerciseIdentifier
): TSortWordsExerciseParsedColumn[] =>
  columns
    // Updating word data on column leave
    .map((column) =>
      column.currentWords.some((currentWord) => currentWord.id === targetWordId)
        ? {
            ...column,
            currentWords: column.currentWords.map((currentWord) =>
              currentWord.id === targetWordId
                ? updateWordDataOnColumnLeave(currentWord)
                : currentWord
            ),
          }
        : column
    )
    // Updating column data
    .map((column) => ({
      ...column,
      isEmpty: column.currentWords.every(
        (currentWord) => currentWord.id === targetWordId
      ),
      currentWords: column.currentWords.filter(
        (currentWord) => currentWord.id !== targetWordId
      ),
    }));

/**
 * Helper function that allows updating an array of exercise columns by moving a word item from source to destination one.
 * @param columns an array of all columns that exist in an exercise.
 * @param previousColumnId identifier of the source column that should be updated.
 * @param destinationColumnId identifier of the destination column that should be updated.
 * @param targetWordId target word ID that should be switched from source column to destination column.
 * @returns updated array of columns with switched target word from source to destination column.
 */
export const moveWordBetweenColumns = (
  columns: TSortWordsExerciseParsedColumn[],
  previousColumnId: TExerciseIdentifier,
  destinationColumnId: TExerciseIdentifier,
  targetWordId: TExerciseIdentifier
): TSortWordsExerciseParsedColumn[] => {
  const word = columns
    .find((column) => column.id === previousColumnId)
    ?.currentWords.find((word) => word.id === targetWordId);

  return columns.map((column) =>
    column.id === previousColumnId
      ? {
          ...column,
          isEmpty: column.currentWords.every(
            (currentWord) => currentWord.id === targetWordId
          ),
          currentWords: column.currentWords.filter(
            (currentWord) => currentWord.id !== targetWordId
          ),
        }
      : column.id === destinationColumnId && word
      ? {
          ...column,
          isEmpty: false,
          currentWords: [
            ...column.currentWords,
            updateWordDataOnColumnEnter(word, column),
          ],
        }
      : column
  );
};

/**
 * Helper function that allows updating an array of exercise columns by switching words from source to destination.
 * Which means that word in source column will take place in destination column and word in destination column will take place in source one.
 * @param columns an array of all columns that exist in an exercise.
 * @param previousColumnId identifier of the source column that should be updated.
 * @param destinationColumnId identifier of the destination column that should be updated.
 * @param targetWordId source word ID which one should be moved to the destination column.
 * @param replaceWordId destination column word ID which one should be moved to the source column.
 * @returns updated array of columns with replaced words from source to destination and otherwise.
 */
export const switchWordsBetweenColumns = (
  columns: TSortWordsExerciseParsedColumn[],
  previousColumnId: TExerciseIdentifier,
  destinationColumnId: TExerciseIdentifier,
  targetWordId: TExerciseIdentifier,
  replaceWordId: TExerciseIdentifier
): TSortWordsExerciseParsedColumn[] => {
  const replaceWord = columns
    .find((column) => column.id === destinationColumnId)
    ?.currentWords.find((word) => word.id === replaceWordId);

  const targetWord = columns
    .find((column) => column.id === previousColumnId)
    ?.currentWords.find((word) => word.id === targetWordId);

  return columns.map((column) =>
    column.id === previousColumnId
      ? {
          ...column,
          currentWords: column.currentWords.map((currentWord) =>
            currentWord.id === targetWordId && replaceWord
              ? updateWordDataOnColumnEnter(replaceWord, column)
              : currentWord
          ),
        }
      : column.id === destinationColumnId
      ? {
          ...column,
          currentWords: column.currentWords.map((currentWord) =>
            currentWord.id === replaceWordId && targetWord
              ? updateWordDataOnColumnEnter(targetWord, column)
              : currentWord
          ),
        }
      : column
  );
};
