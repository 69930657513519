import { SingleValueProps, components } from "react-select";
import { TAssertionSelectDefaultOption } from "../../../AssertionSentences.types";
import "./SingleValue.styles.scss";

export const SingleValue = <
  Option extends TAssertionSelectDefaultOption = TAssertionSelectDefaultOption
>({
  children,
  ...props
}: SingleValueProps<Option, false>): JSX.Element => (
  <components.SingleValue
    {...props}
    className="nb-interactive-true-or-false-assertion-select-single-value"
  >
    {children}
  </components.SingleValue>
);
