import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LessonDetailsContent as Content } from "content";
import {
  useCourseLessonById,
  useCourseLessonChapters,
} from "hooks/api/courses";
import { useModal } from "hooks/common/useModal";
import { useExerciseConstructor, useLessonSettings } from "hooks/redux";
import { ExerciseConstructor } from "components/ExerciseConstructor";
import {
  AddLessonChapterModal,
  ChaptersSidebar,
  HomeworkContent,
  HomeworkSidebar,
  LessonContainer,
  LessonContent,
  LessonHeader,
  LessonSidebar,
} from "components/Lesson";
import { logError } from "utils/notifications";
import { Route } from "constants/routes";
import { ELessonContentType } from "types/api/lesson";
import "./LessonDetails.styles.scss";

export const LessonDetails: React.FC = () => {
  const { courseId, lessonId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryChapterId = searchParams.get("chapterId");

  const [contentType, setContentType] = useState<ELessonContentType>(
    ELessonContentType.LESSON
  );
  const [activeChapterId, setActiveChapterId] = useState<number | null>(null);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);

  const { data: lessonChapters = [], isLoading: isChaptersLoading } =
    useCourseLessonChapters(courseId, lessonId);
  const {
    data: lesson,
    isLoading: isLessonLoading,
    error,
  } = useCourseLessonById(courseId, lessonId);

  useEffect(() => {
    // TODO: handle properly not found resource error
    if (error) {
      navigate(Route.App.Materials.ROOT.path);
    }
  }, [error, navigate]);

  const {
    setCourseId,
    setLessonId,
    setHomeworkMode,
    setLessonMode,
    setHomeworkId,
  } = useExerciseConstructor();
  const { setExerciseAnswerMode } = useLessonSettings();

  useEffect(() => {
    setExerciseAnswerMode("preview");

    return () => {
      setExerciseAnswerMode("live");
    };
  }, [setExerciseAnswerMode]);

  useEffect(() => {
    setCourseId(courseId);
    setLessonId(lessonId);
  }, [courseId, lessonId, setCourseId, setLessonId]);

  useEffect(() => {
    const isChaptersAvailable: boolean = lessonChapters.length > 0;

    // Case 0: Lesson does not have chapters
    if (!isChaptersAvailable && !queryChapterId) return;

    // Case 1: Lesson has chapters and chapterId parameter is not provided (when user opens lesson from materials list)
    if (!queryChapterId && isChaptersAvailable) {
      const [firstChapter] = lessonChapters;
      setActiveChapterId(firstChapter.id);
      setSearchParams({ chapterId: firstChapter.id.toString() });
      return;
    }

    const targetChapter = lessonChapters.find(
      (chapter) => chapter.id === Number(queryChapterId)
    );

    // Case 2: Lesson has chapters and chapterId parameter is provided (when user opens direct link to lesson chapter)
    if (queryChapterId && isChaptersAvailable && targetChapter) {
      setActiveChapterId(targetChapter.id);
      setSearchParams({ chapterId: targetChapter.id.toString() });
      return;
    }

    // Case 3: Lesson has chapters and chapterId parameter is provided but chapter with this id does not exist
    if (queryChapterId && isChaptersAvailable && !targetChapter) {
      logError({ message: Content.Chapter.Error.INVALID_LESSON_CHAPTER_ID });
      return;
    }
  }, [lessonChapters, queryChapterId, setSearchParams]);

  const submitAddChapterHandler = (chapterId: number) => {
    setContentType(ELessonContentType.LESSON);
    setLessonMode();
    setActiveChapterId(chapterId);
    closeAddModal();
  };

  const chapterButtonClickHandler = (chapterId: number) => {
    setContentType(ELessonContentType.LESSON);
    setActiveChapterId(chapterId);
    setSearchParams({ chapterId: chapterId.toString() });
    setLessonMode();
  };

  const homeworkButtonClickHandler = () => {
    setContentType(ELessonContentType.HOMEWORK);
    setActiveChapterId(null);
    setHomeworkId(lesson?.homeworkId);
    setHomeworkMode();
  };

  const previewModeChangeHandler = (isPreviewMode: boolean) => {
    setIsPreviewMode(isPreviewMode);
    setExerciseAnswerMode(isPreviewMode ? "live" : "preview");
  };

  return (
    <>
      <LessonHeader
        isPreviewMode={isPreviewMode}
        onPreviewChange={previewModeChangeHandler}
      />
      <LessonContainer>
        {contentType === ELessonContentType.LESSON && (
          <LessonContent
            activeChapterId={activeChapterId}
            loading={isLessonLoading || isChaptersLoading}
            lesson={lesson}
            lessonChapters={lessonChapters}
          />
        )}
        {contentType === ELessonContentType.HOMEWORK && (
          <HomeworkContent
            loading={isChaptersLoading}
            homeworkId={lesson?.homeworkId}
          />
        )}
        <LessonSidebar>
          <ChaptersSidebar
            loading={isChaptersLoading}
            lessonChapters={lessonChapters}
            activeChapterId={activeChapterId}
            onChapterClick={chapterButtonClickHandler}
            onAddChapterClick={openAddModal}
          />
          <HomeworkSidebar
            active={contentType === ELessonContentType.HOMEWORK}
            onButtonClick={homeworkButtonClickHandler}
          />
        </LessonSidebar>
      </LessonContainer>
      <AddLessonChapterModal
        open={isAddModalOpen}
        onClose={closeAddModal}
        onSubmit={submitAddChapterHandler}
      />
      <ExerciseConstructor />
    </>
  );
};
