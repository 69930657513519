import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  ButtonExerciseContent,
  ExerciseConstructorContent,
  ValidationContent,
} from "content";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { Textarea } from "components/Interactive/Textarea";
import { Input } from "components/Interactive/Input";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { URL_REGEX } from "constants/validation";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import { EExerciseType, TButtonExercisePayload } from "types/app/exercises";
import { TButtonExerciseForm } from "./ButtonExerciseForm.types";
import "./ButtonExerciseForm.styles.scss";

export const ButtonExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TButtonExercisePayload>();

  const { mutate: addExercise } = useAddExercise<TButtonExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TButtonExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TButtonExercisePayload>(courseId, lessonId, chapterId);
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TButtonExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TButtonExerciseForm> = useMemo(
    () => ({
      text: editExercise?.payload.text || "",
      label: editExercise?.payload.data.label || "",
      url: editExercise?.payload.data.url || "",
    }),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<TButtonExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (body: TAddExercise<TButtonExercisePayload>) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TButtonExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TButtonExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TButtonExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TButtonExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const addBody: TAddExercise<TButtonExercisePayload> = {
      type: EExerciseType.BUTTON,
      payload: {
        text: formData.text,
        data: {
          label: formData.label,
          url: formData.url,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TButtonExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TButtonExerciseForm>
          name="text"
          id="button-exercise-condition"
          className="nb-exercise-constructor-button-condition"
          label={ButtonExerciseContent.Add.Input.Text.LABEL}
          placeholder={ButtonExerciseContent.Add.Input.Text.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Divider />
        <Input<TButtonExerciseForm>
          name="label"
          id="button-exercise-label"
          className="nb-exercise-constructor-button-label"
          label={ButtonExerciseContent.Add.Input.Label.LABEL}
          placeholder={ButtonExerciseContent.Add.Input.Label.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Input<TButtonExerciseForm>
          name="url"
          id="button-exercise-url"
          className="nb-exercise-constructor-button-url"
          type="url"
          label={ButtonExerciseContent.Add.Input.Url.LABEL}
          placeholder={ButtonExerciseContent.Add.Input.Url.PLACEHOLDER}
          register={register}
          errors={errors}
          rules={{
            pattern: {
              value: URL_REGEX,
              message: ValidationContent.Pattern.Url,
            },
          }}
          required
          fullWidth
        />
        <Divider />
      </Space>
    </ExerciseConfigForm>
  );
};
