import { DropdownIndicatorProps, components } from "react-select";
import cx from "classnames";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { TSelectDefaultOption } from "../../Select.types";
import "./DropdownIndicator.styles.scss";

type SelectDropdownIndicatorProps<
  Option extends TSelectDefaultOption = TSelectDefaultOption
> = {
  /**
   * If `true`, the dropdown indicator will be aligned on top.
   * @default false
   */
  isLabelsJSXElements?: boolean;
} & DropdownIndicatorProps<Option, false>;

export const DropdownIndicator = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>({
  isFocused,
  isLabelsJSXElements = false,
  ...dropdownProps
}: SelectDropdownIndicatorProps<Option>): JSX.Element => (
  <components.DropdownIndicator
    {...dropdownProps}
    isFocused={isFocused}
    className={cx([
      "nb-interactive-select-indicator-container",
      {
        "nb-interactive-select-indicator-container--element":
          isLabelsJSXElements,
      },
    ])}
  >
    <Chevron
      width={15}
      height={15}
      className={cx([
        "nb-interactive-select-icon-chevron",
        {
          "nb-interactive-select-icon-chevron--focused": isFocused,
        },
      ])}
    />
  </components.DropdownIndicator>
);
