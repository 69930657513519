import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StudentClassroomContent as Content } from "content";
import { useAddCourseToStudent, useCourses } from "hooks/api/courses";
import { Empty } from "components/Common/Empty";
import { Space } from "components/Common/Space";
import { Loader } from "components/Common/Loader";
import { ContentModal } from "components/Common/ContentModal";
import { MaterialsCoverItem } from "components/Courses/MaterialsCoverItem";
import { MaterialsPagination } from "components/Courses/MaterialsPagination";
import { Route } from "constants/routes";
import { TAddCourseToStudentBody, TCourse } from "types/api/course";
import "./AddStudentMaterialModal.styles.scss";

type TAddStudentMaterialModalProps = {
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * List of courses that the student is already enrolled in.
   * This is used to filter out the courses that the student is already enrolled in.
   */
  existingCourses?: TCourse[];
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
};

export const AddStudentMaterialModal: React.FC<
  TAddStudentMaterialModalProps
> = (props) => {
  const { open = false, existingCourses = [], onClose } = props;

  const { studentId } = useParams();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(0);

  const { data: coursesData, isFetching: isCourseLoading } = useCourses({
    pageNumber,
  });
  const { mutate: addCourseToStudent } = useAddCourseToStudent();

  // TODO: check if this is correct filtering
  const courses = useMemo(
    () =>
      (coursesData?.content || []).filter((course) =>
        existingCourses.every(
          (existingCourse) => existingCourse.id !== course.id
        )
      ),
    [coursesData?.content, existingCourses]
  );
  const totalPages: number = useMemo(
    () => coursesData?.totalPages || 0,
    [coursesData?.totalPages]
  );
  const isEmpty: boolean = useMemo(() => courses.length === 0, [courses]);

  const modalCloseHandler = () => {
    onClose?.();
  };

  const pageChangeHandler = (newPage: number) => {
    setPageNumber(newPage);
  };

  const courseClickHandler = (courseId: number) => {
    if (!studentId) return;

    const body: TAddCourseToStudentBody = {
      courseId,
      studentId: parseInt(studentId),
    };
    addCourseToStudent(body, {
      onSuccess: () => {
        onClose?.();
        navigate(
          Route.App.Study.STUDENT_CLASSROOM_COURSE.construct(
            studentId,
            courseId.toString()
          )
        );
      },
    });
  };

  return (
    <ContentModal
      portalId="add-student-material-modal"
      open={open}
      title={Content.Add.TITLE}
      emoji={Content.Add.EMOJI}
      onClose={modalCloseHandler}
      className="nb-sub-classroom-add-course-modal"
    >
      <Space
        direction="vertical"
        justify="start"
        align="start"
        size="medium"
        fullWidth
      >
        {isCourseLoading ? (
          <Loader />
        ) : isEmpty && !isCourseLoading ? (
          <Empty text={Content.Add.Empty.MESSAGE} />
        ) : (
          <ul className="nb-sub-classroom-add-course-list">
            {courses.map((course) => (
              <MaterialsCoverItem
                key={course.id}
                course={course}
                onClick={courseClickHandler}
              />
            ))}
          </ul>
        )}
        <MaterialsPagination
          currentPage={pageNumber}
          totalPages={totalPages}
          onPageChange={pageChangeHandler}
        />
      </Space>
    </ContentModal>
  );
};
