import { nanoid } from "nanoid";
import { IMAGE_EXERCISE_GALLERY_VIEW_OPTIONS } from "utils/options";
import {
  TDescribeImageExerciseImage,
  TDescribeImageExercisePayload,
} from "types/app/exercises";
import { TExercise } from "types/api/exercise";
import { TDescribeImageExerciseForm } from "./DescribeImageExerciseForm.types";

const [initialGalleryView] = IMAGE_EXERCISE_GALLERY_VIEW_OPTIONS;

export const getDescribeImageExerciseFormInitialValues = (
  editExercise: TExercise<TDescribeImageExercisePayload> | null
): TDescribeImageExerciseForm => ({
  text: editExercise?.payload.text || "",
  galleryView:
    IMAGE_EXERCISE_GALLERY_VIEW_OPTIONS.find(
      (option) => option.value === editExercise?.payload.data.galleryView
    ) || initialGalleryView,
  images: editExercise?.payload.data.images.map((image) => ({
    id: image.id,
    description: image.description,
    imageEncoded: image.imageEncoded || null,
    imageUrl: image.imageUrl || null,
  })) || [
    {
      id: nanoid(),
      description: "",
      imageEncoded: null,
      imageUrl: null,
    },
  ],
});

export const adaptExerciseImagesToData = (
  images: TDescribeImageExerciseImage[],
  prevImages: TDescribeImageExerciseImage[] = []
): TDescribeImageExerciseImage[] => {
  const parsedImages: TDescribeImageExerciseImage[] = [];

  for (const [index, image] of images.entries()) {
    const { id, description, imageEncoded } = image;
    const prevImage = prevImages[index];

    const [, fileEncoded] = imageEncoded?.split(",") || [];

    parsedImages.push({
      id,
      description: description || null,
      imageEncoded: fileEncoded || null,
      imageUrl: prevImage?.imageUrl || null,
    });
  }

  return parsedImages;
};
