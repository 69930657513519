import { InputProps, components } from "react-select";
import { TSelectOption } from "types/app/select";
import "./Input.styles.scss";

export const Input = <
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>(
  props: InputProps<Option, false>
): JSX.Element => (
  <components.Input
    className="nb-interactive-search-input"
    {...props}
    isHidden={false}
  />
);
