import { COMPARE_IMAGE_WITH_DESCRIPTION_MAX_ANSWER_ATTEMPTS } from "../CompareImageWithDescription.helpers";
import {
  TCompareImageWithDescriptionExerciseParsedAnswer,
  TCompareImageWithDescriptionExerciseParsedImage,
  TExerciseIdentifier,
} from "types/app/exercises";

/**
 * Helper function that allows to update answer information on user select.
 * @param answers an array of answers that should be updated.
 * @param selectedAnswer target answer data that should be updated.
 * @returns updated answers data.
 */
export const updateAnswersData = (
  answers: TCompareImageWithDescriptionExerciseParsedAnswer[],
  selectedAnswer: TCompareImageWithDescriptionExerciseParsedAnswer
): TCompareImageWithDescriptionExerciseParsedAnswer[] =>
  answers.map((answer) =>
    answer.id === selectedAnswer.id
      ? {
          ...answer,
          isSelected: true,
          isTouched: true,
        }
      : answer
  );

/**
 * Helper function that allows to update image information on user select.
 * @param images an array of images that should be updated.
 * @param targetImageId target image id that should be updated.
 * @param selectedAnswer target answer data that should be associated with the image.
 * @returns updated images data.
 */
export const updateImagesData = (
  images: TCompareImageWithDescriptionExerciseParsedImage[],
  targetImageId: TExerciseIdentifier,
  selectedAnswer: TCompareImageWithDescriptionExerciseParsedAnswer
): TCompareImageWithDescriptionExerciseParsedImage[] =>
  images.map((image) =>
    image.id === targetImageId
      ? {
          ...image,
          attempts: [...image.attempts, selectedAnswer],
          currentAnswer: selectedAnswer,
          currentAttempt: image.currentAttempt + 1,
          isCorrectAnswer: image.correctAnswers.some(
            (correctAnswer) => correctAnswer.id === selectedAnswer.id
          ),
          isEmpty: false,
          isTouched: true,
          isAllAttemptsFailed:
            image.correctAnswers.some(
              (correctAnswer) => correctAnswer.id === selectedAnswer.id
            ) &&
            image.currentAttempt + 1 >=
              COMPARE_IMAGE_WITH_DESCRIPTION_MAX_ANSWER_ATTEMPTS,
        }
      : image
  );
