export const ImageListUpload = {
  Default: {
    IMAGE_DESCRIPTION_PLACEHOLDER: "Опишіть зображення",
    ADD_IMAGE_BUTTON: "Додати зображення",
    PICK_IMAGE_BUTTON: "Обрати зображення",
    CHANGE_IMAGE_BUTTON: "Змінити зображення",
  },
  Modal: {
    TITLE: "Оберіть зображення",
    EMOJI: "🖼️",
    Button: {
      SELECT: "Обрати зображення",
      UPLOAD: "Додати це зображення",
    },
    Input: {
      LABEL: "Перетягніть або оберіть зображення",
    },
  },
};
