import { nanoid } from "nanoid";
import { shuffleArray } from "utils/common";
import {
  TMakeWordFromLettersExerciseParsedWord,
  TMakeWordFromLettersExerciseParsedWordLetter,
  TMakeWordFromLettersExercisePayload,
  TMakeWordFromLettersExerciseWord,
  TMakeWordFromLettersExerciseWordBlank,
} from "types/app/exercises";
import { TExercise } from "types/api/exercise";
import { TMakeWordFromLettersExerciseForm } from "./MakeWordFromLettersExerciseForm.types";

export const getMakeWordFromLettersExerciseFormInitialValues = (
  editExercise: TExercise<TMakeWordFromLettersExercisePayload> | null
): TMakeWordFromLettersExerciseForm => ({
  text: editExercise?.payload.text || "",
  words: editExercise?.payload.data.words.map((word) => word.rawWord) || [
    {
      id: nanoid(),
      content: "",
      hint: "",
    },
  ],
});

/**
 * Utility function that helps to adapt raw words array to structured data which makes easier to work with complex operations.
 * @param rawWords raw words array from react-hook-form.
 * @returns object of structured `MakeWordFromLetters` exercise data.
 */
export const adaptWordsToExerciseData = (
  rawWords: TMakeWordFromLettersExerciseWord[]
): TMakeWordFromLettersExerciseParsedWord[] => {
  const words: TMakeWordFromLettersExerciseParsedWord[] = [];

  for (const rawWord of rawWords) {
    const splitWord: string[] = [...rawWord.content];
    const letters: TMakeWordFromLettersExerciseParsedWordLetter[] = [];
    const blanks: TMakeWordFromLettersExerciseWordBlank[] = [];

    for (const letter of rawWord.content) {
      const parsedLetter: TMakeWordFromLettersExerciseParsedWordLetter = {
        id: nanoid(),
        content: letter,
        isTouched: false,
        isCorrectDestination: false,
        currentAttempt: 0,
        currentBlankId: null,
        attempts: [],
      };

      const parsedBlank: TMakeWordFromLettersExerciseWordBlank = {
        id: nanoid(),
        isEmpty: true,
        isCorrectLetter: false,
        currentLetter: null,
        correctContent: letter,
      };

      letters.push(parsedLetter);
      blanks.push(parsedBlank);
    }

    const parsedWord: TMakeWordFromLettersExerciseParsedWord = {
      id: nanoid(),
      word: rawWord.content,
      rawWord,
      splitWord,
      hint: rawWord.hint || null,
      letters,
      shuffledLetters: shuffleArray(letters),
      blanks,
    };

    words.push(parsedWord);
  }

  return words;
};
