/**
 * Get random number in range.
 * @param {Number} min - min number in range.
 * @param {Number} max - max number in range.
 * @returns {Number} result - random generated number in provided range.
 */
export const getRandomNumber = (min: number, max: number): number =>
  +Math.floor(Math.random() * (max - min)) + min;

/**
 * Convert an angle from degrees to radians.
 * @param degrees value in degrees.
 * @param precision  representing the number of decimal places the resulting radians value should have.
 * @returns radians number.
 */
export const convertDegreesToRadians = (
  degrees: number,
  precision = 2
): number => parseFloat(((degrees * Math.PI) / 180).toFixed(precision));
