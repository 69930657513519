import {
  GroupsContent,
  ReportsContent,
  RequestsContent,
  StudentsContent,
  TeachersContent,
} from "content";
import { EStudySearchTarget } from "types/app/study";

export const SelectTargetLabel = {
  [EStudySearchTarget.Teachers]: TeachersContent.Name,
  [EStudySearchTarget.Students]: StudentsContent.Name,
  [EStudySearchTarget.Groups]: GroupsContent.Name,
  [EStudySearchTarget.Reports]: ReportsContent.Name,
  [EStudySearchTarget.Requests]: RequestsContent.Name,
};

export const selectTargetOptions = Object.values(EStudySearchTarget).map(
  (target) => ({
    label: SelectTargetLabel[target],
    value: target,
  })
);
