import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { addCourseLesson } from "api/services/courses";
import { logError } from "utils/notifications";
import { TAddCourseLessonBody, TCourseLesson } from "types/api/course";
import { TApiError } from "types/api/error";
import { TUrlParam } from "types/utils";

export const useAddCourseLesson = (courseId: TUrlParam) => {
  const queryClient = useQueryClient();
  return useMutation<TCourseLesson, TApiError, TAddCourseLessonBody>(
    (courseLesson: TAddCourseLessonBody) =>
      addCourseLesson(courseId, courseLesson),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ECoursesQueryKeys.getAllCourseLessons);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
