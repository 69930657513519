import { useForm } from "react-hook-form";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { TSentenceOrderExerciseForm } from "./SentenceOrderExerciseForm.types";
import "./SentenceOrderExerciseForm.styles.scss";

export const SentenceOrderExerciseForm: React.FC = () => {
  const { handleSubmit } = useForm<TSentenceOrderExerciseForm>({
    mode: "onBlur",
    defaultValues: {},
  });

  const addExerciseSubmitHandler = (formData: TSentenceOrderExerciseForm) => {
    // eslint-disable-next-line no-console
    console.log("add SentenceOrderExerciseForm handler", formData);
  };

  return (
    <ExerciseConfigForm onSubmit={handleSubmit(addExerciseSubmitHandler)}>
      SentenceOrderExerciseForm
    </ExerciseConfigForm>
  );
};
