import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ExerciseConstructorContent,
  TrueOrFalseExerciseContent,
} from "content";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { Textarea } from "components/Interactive/Textarea";
import { Text } from "components/Typography/Text";
import { Switch } from "components/Interactive/Switch";
import { Select } from "components/Interactive/Select";
import { AssertionSentences } from "components/Interactive/AssertionSentences";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { ReactComponent as Time } from "assets/icons/time.svg";
import { logError, logSuccess } from "utils/notifications";
import { EXERCISE_DURATION_OPTIONS } from "utils/options/exercise";
import { getTrueOrFalseExerciseFormInitialValues } from "./TrueOrFalseExerciseForm.helpers";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import {
  EExerciseType,
  TTrueOrFalseExerciseAnswer,
  TTrueOrFalseExercisePayload,
} from "types/app/exercises";
import { TTrueOrFalseExerciseForm } from "./TrueOrFalseExerciseForm.types";
import "./TrueOrFalseExerciseForm.styles.scss";

export const TrueOrFalseExerciseForm: React.FC = () => {
  const [isWithTimer, setIsWithTimer] = useState<boolean>(true);

  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TTrueOrFalseExercisePayload>();
  const { mutate: addExercise } = useAddExercise<TTrueOrFalseExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TTrueOrFalseExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TTrueOrFalseExercisePayload>(courseId, lessonId, chapterId);
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TTrueOrFalseExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TTrueOrFalseExerciseForm> = useMemo(
    () => getTrueOrFalseExerciseFormInitialValues(editExercise),
    [editExercise]
  );

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<TTrueOrFalseExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (editExercise && isEditMode) {
      setIsWithTimer(editExercise.payload.data.timer !== null);
    }
  }, [editExercise, isEditMode]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TTrueOrFalseExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TTrueOrFalseExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TTrueOrFalseExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TTrueOrFalseExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TTrueOrFalseExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const answers: TTrueOrFalseExerciseAnswer[] = formData.answers.map(
      (item) => ({
        id: item.id,
        content: item.content,
        isCorrect: item.isCorrect.value,
      })
    );

    const addBody: TAddExercise<TTrueOrFalseExercisePayload> = {
      type: EExerciseType.TRUE_OR_FALSE,
      payload: {
        text: formData.text,
        data: {
          timer: isWithTimer ? formData.timer.value : null,
          answers,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TTrueOrFalseExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  const timerSwitchChangeHandler = (checked: boolean) => {
    setIsWithTimer(checked);
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TTrueOrFalseExerciseForm>
          name="text"
          id="true-or-false-exercise-condition"
          className="nb-exercise-constructor-true-or-false-condition"
          label={TrueOrFalseExerciseContent.Add.Input.Condition.LABEL}
          placeholder={
            TrueOrFalseExerciseContent.Add.Input.Condition.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <div className="nb-exercise-constructor-true-or-false-timer-wrapper">
          <Text variant="body2">
            {TrueOrFalseExerciseContent.Add.Input.Timer.LABEL}
          </Text>
          <Switch value={isWithTimer} onChange={timerSwitchChangeHandler} />
          {isWithTimer && (
            <Select<TTrueOrFalseExerciseForm>
              name="timer"
              id="true-or-false-exercise-timer-select"
              className="nb-exercise-constructor-true-or-false-timer"
              placeholder={
                TrueOrFalseExerciseContent.Add.Input.Timer.PLACEHOLDER
              }
              options={EXERCISE_DURATION_OPTIONS}
              control={control}
              errors={errors}
              icon={<Time />}
              required
              fullWidth
            />
          )}
        </div>
        <Divider margin="none" />
        <AssertionSentences<TTrueOrFalseExerciseForm>
          name="answers"
          control={control}
          register={register}
          errors={errors}
          addAssertionButtonText={
            TrueOrFalseExerciseContent.Add.Input.Sentences.ADD_ASSERTION_TEXT
          }
          label={TrueOrFalseExerciseContent.Add.Input.Sentences.LABEL}
          assertionPlaceholder={
            TrueOrFalseExerciseContent.Add.Input.Sentences.ASSERTION_PLACEHOLDER
          }
        />
      </Space>
    </ExerciseConfigForm>
  );
};
