import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

type TStateCallback<TData> = (value: TData) => void;
type TDispatchWithCallback<TData> = (
  value: SetStateAction<TData>,
  callback?: TStateCallback<TData>
) => void;

/**
 * The hook that let run code after a setState operation finished.
 * The initial useStateCallback api is the same as the useState hook from react, with the only difference that the setState function can also take a callback as input.
 * This callback is guaranteed to be called after the state has been updated and the new state is also passed as input.
 * @param initialState The value you want the state to be initially. It can be a value of any type, but there is a special behavior for functions. This argument is ignored after the initial render.
 * @returns an array with exactly two values:
 * The current state. During the first render, it will match the initialState you have passed.
 * The set function with two arguments: first argument lets you update the state to a different value and trigger a re-render and the second one is the callback that executes after the state has been updated.
 */
export const useStateCallback = <TData>(
  initialState: TData | (() => TData)
): [TData, TDispatchWithCallback<TData>] => {
  const [state, _setState] = useState<TData>(initialState);

  const callbackRef = useRef<TStateCallback<TData>>();
  const isFirstCallbackCall = useRef<boolean>(true);

  const setState = useCallback(
    (
      setStateAction: SetStateAction<TData>,
      callback?: TStateCallback<TData>
    ): void => {
      callbackRef.current = callback;
      _setState(setStateAction);
    },
    []
  );

  useEffect(() => {
    if (isFirstCallbackCall.current) {
      isFirstCallbackCall.current = false;
      return;
    }
    callbackRef.current?.(state);
  }, [state]);

  return [state, setState];
};
