import { useMutation, useQueryClient } from "react-query";
import { editLandingOffer } from "api/services/admin";
import { EAdminQueryKeys } from "api/endpoints/admin";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TAdminLandingOffer } from "types/api/admin";

export const useEditLandingOffer = () => {
  const queryClient = useQueryClient();
  return useMutation<TAdminLandingOffer, TApiError, TAdminLandingOffer>(
    [EAdminQueryKeys.editLandingOffer],
    editLandingOffer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EAdminQueryKeys.getLandingOffers);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
