import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { TMakeWordFromLettersExerciseParsedWordLetter } from "types/app/exercises";
import "./DroppableStartZone.styles.scss";

type TDroppableStartZoneProps = {
  /**
   * A letter data from the parsed word.
   */
  letter: TMakeWordFromLettersExerciseParsedWordLetter;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const DroppableStartZone: React.FC<TDroppableStartZoneProps> = (
  props
) => {
  const { children, letter } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: letter.id,
    // We need to pass the letter data only when the droppable zone is occupied with draggable.
    data: { letter: children ? letter : null },
  });

  return (
    <div
      ref={setNodeRef}
      className={cx("nb-exercise-make-words-from-letters-droppable-start", {
        "nb-exercise-make-words-from-letters-droppable-start--over": isOver,
        "nb-exercise-make-words-from-letters-droppable-start--empty": !children,
      })}
      aria-label="Початкове поле в яке можна перенести літеру"
    >
      {children}
    </div>
  );
};
