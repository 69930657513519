export const FileUpload = {
  Root: {
    Button: {
      SELECT: "Обрати файл",
      UPLOAD: "Завантажити",
    },
  },
  Input: {
    LABEL: "Перетягніть або завантажте файл",
  },
  Cropper: {
    LOADING: "Зображення оброблюється...",
  },
  Modal: {
    TITLE: "Завантажте файл",
    EMOJI: "📁",
  },
  Notification: {
    NO_FILES_ERROR: "Не вдалось додати файл",
  },
};
