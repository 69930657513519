/**
 * This regular expression will separate a sentence in `str.split()` method by common sentence ending symbols, like `.`, `!`, `?` and `...`.
 * Normally `split` method on String prototype will omit splitter but this expression will keep it.
 * @example
 * "This is cool. Take a look!".split(SENTENCE_SEPARATOR); // output: ["This is cool.", " Take a look!"]
 */
export const SENTENCE_SEPARATOR = /(?<=(?<!\d)\.|!|\?|$)/gm;

/**
 * This regular expression will match only content inside square brackets.
 * @example
 * "This text will be omitted [but this one will stay]".match(BRACKET_CONTENT); // output: ["[but this one will stay]"]
 */
export const BRACKET_CONTENT = /(\[.*?\])/g;

/**
 * Regular expression for matching newlines and carriage returns.
 * @type {RegExp}
 * @example
 * 'Some text\nwith newline'.match(MATCH_NEWLINE) // ["\n"]
 */
export const MATCH_NEWLINE = /([\n\r])/g;
