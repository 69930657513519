import { useLocation } from "react-router-dom";
import { NavigationItem } from "components/Navigation/NavigationItem";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { SITE_NAVIGATION } from "constants/navigation";
import { EUserRole } from "types/api/role";
import "./SiteNavigation.styles.scss";

export const SiteNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const { activeUser } = useActiveUser();

  const userRole = activeUser?.role.name || EUserRole.STUDENT;

  return (
    <nav className="nb-site-navigation">
      <ul className="nb-site-navigation-list">
        {SITE_NAVIGATION.filter((item) => item.access.includes(userRole)).map(
          (item) => (
            <li key={item.path} className="nb-site-navigation-list-item">
              <NavigationItem
                link={item.path}
                label={item.label}
                icon={item.icon}
                active={pathname.includes(item.path)}
              />
            </li>
          )
        )}
      </ul>
    </nav>
  );
};
