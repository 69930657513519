import { useEffect, useMemo, useState } from "react";
import { CompareImageWithDescriptionExerciseContent as ExerciseContent } from "content";
import { useLessonSettings } from "hooks/redux";
import {
  Draggable,
  DraggablePlaceholder,
  DraggableStartZone,
} from "components/DragAndDrop";
import { Space } from "components/Common/Space";
import { ClueInput } from "components/Interactive/ClueInput";
import { ImagesList } from "../ImagesList";
import { ImageItem } from "../ImageItem";
import {
  updateAnswersDataOnInputEnter,
  updateAnswersDataOnInputLeave,
  updateImagesDataOnInputEnter,
  updateImagesDataOnInputLeave,
} from "./InputController.helpers";
import {
  TCompareImageWithDescriptionExercise,
  TCompareImageWithDescriptionExerciseParsedAnswer,
  TCompareImageWithDescriptionExerciseParsedImage,
  TExerciseIdentifier,
} from "types/app/exercises";
import "./InputController.styles.scss";

type TInputControllerProps = {
  /**
   * An exercise raw data from API response.
   */
  exercise: TCompareImageWithDescriptionExercise;
  /**
   * Callback fired when the answer was changed in an input.
   * @param imagedId id of the image that was performed the change.
   * @param details detailed information about the change.
   */
  onChange?: (
    imageId: TExerciseIdentifier,
    details: {
      action: "enter" | "leave" | "replace";
      answer: TCompareImageWithDescriptionExerciseParsedAnswer;
      imageWithSameAnswerToRemove: TCompareImageWithDescriptionExerciseParsedImage | null;
    }
  ) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const InputController: React.FC<TInputControllerProps> = (props) => {
  const { exercise, onChange, className } = props;

  const [answers, setAnswers] = useState<
    TCompareImageWithDescriptionExerciseParsedAnswer[]
  >(exercise.payload.data.answers);
  const [images, setImages] = useState<
    TCompareImageWithDescriptionExerciseParsedImage[]
  >(exercise.payload.data.images);
  const [inputValues, setInputValues] = useState<
    Record<TExerciseIdentifier, string>
  >({});

  const { isExerciseReadOnlyMode, isExercisePreviewMode, isExerciseLiveMode } =
    useLessonSettings();

  useEffect(() => {
    if (isExercisePreviewMode) {
      setInputValues(
        exercise.payload.data.images.reduce(
          (acc, image) => ({
            ...acc,
            [image.id]: image.currentAnswer?.content || "",
          }),
          {}
        )
      );
    }

    if (isExerciseLiveMode) {
      setInputValues(
        exercise.payload.data.images.reduce(
          (acc, image) => ({
            ...acc,
            [image.id]: "",
          }),
          {}
        )
      );
    }
  }, [isExercisePreviewMode, isExerciseLiveMode, exercise]);

  useEffect(() => {
    setAnswers(exercise.payload.data.shuffledAnswers);
    setImages(exercise.payload.data.images);
  }, [exercise]);

  const isAllowToShowResult: boolean = useMemo(
    () => images.every((image) => image.currentAnswer !== null),
    [images]
  );

  const imageInputChangeHandler = (value: string, id: TExerciseIdentifier) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));
    const targetAnswer: TCompareImageWithDescriptionExerciseParsedAnswer | null =
      answers.find(
        (answer) =>
          answer.content.trim().toLowerCase() === value.trim().toLowerCase()
      ) || null;
    const targetImage: TCompareImageWithDescriptionExerciseParsedImage | null =
      images.find((image) => image.id === id) || null;

    const previousAnswer: TCompareImageWithDescriptionExerciseParsedAnswer | null =
      targetImage?.currentAnswer || null;

    const imageWithOccupiedAnswer: TCompareImageWithDescriptionExerciseParsedImage | null =
      images.find(
        (image) =>
          targetAnswer !== null &&
          image.id !== id &&
          image.currentAnswer?.id === targetAnswer.id
      ) || null;

    // CASE 1 (LEAVE): USER MODIFIED A VALID ANSWER
    if (previousAnswer && !targetAnswer) {
      setAnswers((prevAnswers) =>
        updateAnswersDataOnInputLeave(prevAnswers, previousAnswer)
      );
      setImages((prevImages) => updateImagesDataOnInputLeave(prevImages, id));
      onChange?.(id, {
        action: "leave",
        answer: previousAnswer,
        imageWithSameAnswerToRemove: null,
      });
      return;
    }

    // CASE 2 (ENTER): USER INPUTTED A VALID ANSWER THAT IS NOT OCCUPIED BY ANOTHER IMAGE
    if (!imageWithOccupiedAnswer && !previousAnswer && targetAnswer) {
      setAnswers((prevAnswers) =>
        updateAnswersDataOnInputEnter(prevAnswers, targetAnswer)
      );
      setImages((prevImages) =>
        updateImagesDataOnInputEnter(prevImages, id, targetAnswer)
      );
      onChange?.(id, {
        action: "enter",
        answer: targetAnswer,
        imageWithSameAnswerToRemove: null,
      });
      return;
    }

    // CASE 3 (REPLACE): USER INPUTTED A VALID ANSWER THAT IS OCCUPIED BY ANOTHER IMAGE
    if (imageWithOccupiedAnswer && !previousAnswer && targetAnswer) {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [imageWithOccupiedAnswer.id]: "",
      }));
      setImages((prevImages) =>
        updateImagesDataOnInputLeave(prevImages, imageWithOccupiedAnswer.id)
      );
      setAnswers((prevAnswers) =>
        updateAnswersDataOnInputEnter(prevAnswers, targetAnswer)
      );
      setImages((prevImages) =>
        updateImagesDataOnInputEnter(prevImages, id, targetAnswer)
      );
      onChange?.(id, {
        action: "replace",
        answer: targetAnswer,
        imageWithSameAnswerToRemove: imageWithOccupiedAnswer,
      });
    }
  };

  return (
    <Space
      direction="vertical"
      size="medium"
      justify="start"
      fullWidth
      className={className}
    >
      <DraggableStartZone
        droppableId="start-zone-placeholder"
        empty={answers.length === 0}
      >
        {answers.map((answer) =>
          answer.isSelected ? (
            <DraggablePlaceholder key={answer.id} content={answer.content} />
          ) : (
            <Draggable
              key={answer.id}
              draggableId={answer.id}
              content={answer.content}
              ariaLabel={ExerciseContent.DragAndDrop.INPUT_LABEL}
              disabled={isAllowToShowResult}
              freezed
            />
          )
        )}
      </DraggableStartZone>
      <ImagesList>
        {images.map((image) => (
          <ImageItem key={image.id} fileUrl={image.fileUrl}>
            <ClueInput
              id={image.id.toString()}
              name={image.id.toString()}
              value={inputValues[image.id] || ""}
              hint={image.hint}
              disabled={isAllowToShowResult || isExerciseReadOnlyMode}
              error={!image.isCorrectAnswer && isAllowToShowResult}
              success={image.isCorrectAnswer && isAllowToShowResult}
              required
              placeholder={ExerciseContent.DragAndDrop.INPUT_PLACEHOLDER}
              onChange={imageInputChangeHandler}
              className="nb-compare-image-with-description-exercise-answer-input"
            />
          </ImageItem>
        ))}
      </ImagesList>
    </Space>
  );
};
