import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { editCourseLesson } from "api/services/courses";
import { logError } from "utils/notifications";
import { TCourseLesson, TEditCourseLessonBody } from "types/api/course";
import { TApiError } from "types/api/error";
import { TEveryRequestParams } from "types/api/server";

export const useUpdateCourseLessonOrder = (
  courseId?: string | number,
  options?: TEveryRequestParams // We need request params for correct invalidation after lesson position updates
) => {
  const queryClient = useQueryClient();
  return useMutation<TCourseLesson, TApiError, TEditCourseLessonBody>(
    (courseLesson: TEditCourseLessonBody) =>
      editCourseLesson(courseId, courseLesson),
    {
      onError: (error) => logError({ error }),
      // As we use optimistic table updates we need to refetch lessons data on any request outcome
      // So if even an error happened we should refetch our pre-changed lesson to return our data to the valid db state
      onSettled: () => {
        queryClient.invalidateQueries([
          ECoursesQueryKeys.getAllCourseLessons,
          courseId,
          options,
        ]);
      },
      retry: false,
    }
  );
};
