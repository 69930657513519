import { nanoid } from "nanoid";
import { EXERCISE_DURATION_OPTIONS } from "utils/options/exercise";
import { TExercise } from "types/api/exercise";
import { TTestExercisePayload } from "types/app/exercises";
import { TChooseAnswerFromOptionsExerciseForm } from "./TestExerciseForm.types";

const [firstTimerOption] = EXERCISE_DURATION_OPTIONS;

export const getTestExerciseFormInitialValues = (
  editExercise: TExercise<TTestExercisePayload> | null
): TChooseAnswerFromOptionsExerciseForm => ({
  text: editExercise?.payload.text || "",
  timer:
    EXERCISE_DURATION_OPTIONS.find(
      (option) => option.value === editExercise?.payload.data.timer
    ) || firstTimerOption,
  questions: editExercise?.payload.data.questions || [
    {
      id: nanoid(),
      question: "",
      answers: [
        {
          id: nanoid(),
          content: "",
          isCorrect: false,
        },
      ],
    },
  ],
});
