import { useEffect, useMemo, useRef } from "react";
import { nanoid } from "nanoid";
import { useFieldArray, useForm } from "react-hook-form";
import cx from "classnames";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { useEditLandingOffer } from "hooks/api/admin";
import { Input } from "components/Interactive/Input";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { ReactPortal } from "components/Common/ReactPortal";
import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { LandingDashboardContent } from "content";
import { logSuccess } from "utils/notifications";
import { TAdminLandingOffer } from "types/api/admin";
import { TAddOfferForm } from "./types";
import "./EditOfferForm.styles.scss";

type TEditOfferFormProps = {
  /**
   * The offer to edit.
   */
  targetOffer: TAdminLandingOffer | null;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Callback that is called when the offer form is submitted.
   */
  onSubmit?: () => void;
};

export const EditOfferForm: React.FC<TEditOfferFormProps> = (props) => {
  const { targetOffer, className, onSubmit } = props;

  const idRef = useRef<string>(nanoid());

  const { mutate: editOffer } = useEditLandingOffer();

  const defaultValues: Partial<TAddOfferForm> = useMemo(
    () => ({
      type: targetOffer?.type,
      description: targetOffer?.description.map((value) => ({
        id: nanoid(),
        value,
      })),
      options: targetOffer?.options.map((option) => ({
        id: nanoid(),
        name: option.name,
        price: option.price.toString(),
        economy: option.economy.toString(),
      })),
    }),
    [targetOffer]
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddOfferForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const {
    fields: descriptionFields,
    remove: removeDescription,
    append: appendDescription,
  } = useFieldArray<TAddOfferForm>({
    control,
    name: "description",
  });

  const {
    fields: optionFields,
    remove: removeOption,
    append: appendOption,
  } = useFieldArray<TAddOfferForm>({
    control,
    name: "options",
  });

  const appendDescriptionClickHandler = () => {
    appendDescription({
      id: nanoid(),
      value: "",
    });
  };

  const appendOptionClickHandler = () => {
    appendOption({
      id: nanoid(),
      name: "",
      economy: "",
      price: "",
    });
  };

  const editOfferSubmitHandler = (formData: TAddOfferForm) => {
    if (targetOffer === null) return;

    const offer: TAdminLandingOffer = {
      ...targetOffer,
      type: formData.type,
      description: formData.description.map((item) => item.value),
      options: formData.options.map((item) => ({
        name: item.name,
        price: parseInt(item.price) || 0,
        economy: parseInt(item.economy) || 0,
      })),
    };

    editOffer(offer, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(LandingDashboardContent.Edit.Notification.SUCCESS);
        reset();
      },
    });
  };

  const firstDescriptionId: string = useMemo(
    () => descriptionFields[0]?.id ?? "",
    [descriptionFields]
  );

  return (
    <form
      id={idRef.current}
      className={cx(["nb-admin-add-offer-form", className])}
      onSubmit={handleSubmit(editOfferSubmitHandler)}
    >
      <Input<TAddOfferForm>
        name="type"
        id="add-offer-type"
        label={LandingDashboardContent.Edit.Input.Type.LABEL}
        placeholder={LandingDashboardContent.Edit.Input.Type.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Space direction="vertical" size="x-small" align="start" fullWidth>
        <label
          className="nb-admin-add-offer-label"
          {...(firstDescriptionId && { htmlFor: firstDescriptionId })}
        >
          {LandingDashboardContent.Edit.Input.Description.LABEL}
        </label>
        {descriptionFields.map((item, index) => (
          <Space
            key={item.id}
            direction="horizontal"
            size="xx-small"
            justify="start"
            align="center"
            fullWidth
          >
            <Input<TAddOfferForm>
              name={`description.${index}.value`}
              id={item.id}
              className="nb-admin-add-offer-input-field"
              placeholder={
                LandingDashboardContent.Edit.Input.Description.PLACEHOLDER
              }
              register={register}
              fullWidth
              required
            />
            <IconButton
              type="button"
              variant="secondary"
              size="medium"
              icon={<Close />}
              className="nb-interactive-answer-input-remove"
              onClick={() => removeDescription(index)}
              disabled={descriptionFields.length <= 1}
            />
          </Space>
        ))}
        <Button
          type="button"
          variant="secondary"
          fullWidth
          onClick={appendDescriptionClickHandler}
        >
          {LandingDashboardContent.Edit.Button.ADD_DESCRIPTION}
        </Button>
      </Space>
      <Space direction="vertical" size="x-small" align="start" fullWidth>
        {optionFields.map((item, index) => (
          <Space
            key={item.id}
            direction="vertical"
            size="x-small"
            justify="stretch"
            align="start"
            fullWidth
          >
            <Space
              direction="horizontal"
              size="xx-small"
              justify="start"
              align="end"
              fullWidth
            >
              <Input<TAddOfferForm>
                name={`options.${index}.name`}
                id={`option-name-${item.id}`}
                className="nb-admin-add-offer-input-field"
                label={LandingDashboardContent.Edit.Input.OptionName.LABEL}
                placeholder={
                  LandingDashboardContent.Edit.Input.OptionName.PLACEHOLDER
                }
                register={register}
                fullWidth
                required
              />
              <IconButton
                type="button"
                variant="secondary"
                size="medium"
                icon={<Close />}
                className="nb-interactive-answer-input-remove"
                onClick={() => removeOption(index)}
                disabled={optionFields.length <= 1}
              />
            </Space>
            <Space
              direction="horizontal"
              size="xx-small"
              justify="start"
              align="center"
              fullWidth
            >
              <Input<TAddOfferForm>
                name={`options.${index}.price`}
                id={`offer-price-${item.id}`}
                type="number"
                className="nb-admin-add-offer-input-field--half"
                label={LandingDashboardContent.Edit.Input.OptionPrice.LABEL}
                placeholder={
                  LandingDashboardContent.Edit.Input.OptionPrice.PLACEHOLDER
                }
                register={register}
                required
              />
              <Input<TAddOfferForm>
                name={`options.${index}.economy`}
                id={`offer-economy-${item.id}`}
                type="number"
                className="nb-admin-add-offer-input-field--half"
                label={LandingDashboardContent.Edit.Input.OptionEconomy.LABEL}
                placeholder={
                  LandingDashboardContent.Edit.Input.OptionEconomy.PLACEHOLDER
                }
                register={register}
              />
            </Space>
            {optionFields.length !== index + 1 && <Divider />}
          </Space>
        ))}
        <Button
          type="button"
          variant="secondary"
          fullWidth
          onClick={appendOptionClickHandler}
        >
          {LandingDashboardContent.Edit.Button.ADD_OPTION}
        </Button>
      </Space>
      <ReactPortal wrapperId="edit-landing-offer-footer">
        <Button variant="primary" type="submit" form={idRef.current} fullWidth>
          {LandingDashboardContent.Edit.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
