import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { Divider } from "components/Common/Divider";
import { Search } from "components/Interactive/Search";
import { IconButton } from "components/Interactive/IconButton";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { EExerciseType } from "types/app/exercises";
import { TSelectOption } from "types/app/select";
import { TExerciseConstructorSearchForm } from "./ExerciseSearch.types";
import "./ExerciseSearch.styles.scss";

export const ExerciseSearch: React.FC = () => {
  const { closeAll, filterExercises } = useExerciseConstructor();

  const { control, reset, watch } = useForm<TExerciseConstructorSearchForm>({
    defaultValues: { search: "" },
  });

  const search = watch("search");

  useEffect(() => {
    filterExercises(search);
  }, [search, filterExercises]);

  const closeConstructorClickHandler = () => {
    closeAll();
    reset();
  };

  return (
    <div className="nb-exercise-constructor-search-container">
      <div className="nb-exercise-constructor-search-wrapper">
        <Search<TExerciseConstructorSearchForm, TSelectOption<EExerciseType>>
          name="search"
          control={control}
          id="exercise-constructor-search"
          fullWidth
          bordered
          filterSearch
          hideMenu
          className="nb-exercise-constructor-search"
        />
        <IconButton
          variant="secondary"
          icon={<Close />}
          onClick={closeConstructorClickHandler}
          className="nb-exercise-constructor-search-button"
        />
      </div>
      <Divider margin="top" marginSize="large" color="grey-100" />
    </div>
  );
};
