import { useImageUpload } from "hooks/redux";
import { useLazyTimeout } from "hooks/common/useLazyTimeout";
import { UserSettingsContent } from "content";
import { ContentModal } from "components/Common/ContentModal";
import { ChangePasswordForm } from "../ChangePasswordForm";
import "./ChangePasswordModal.styles.scss";

type TChangePasswordModalProps = {
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
};

export const ChangePasswordModal: React.FC<TChangePasswordModalProps> = (
  props
) => {
  const { open = false, onClose } = props;

  const { resetAllImageUploadStates } = useImageUpload();
  const resetDelay = useLazyTimeout(300);

  const modalCloseHandler = () => {
    onClose?.();
    resetDelay(resetAllImageUploadStates);
  };

  return (
    <ContentModal
      portalId="change-user-password-modal"
      open={open}
      title={UserSettingsContent.ChangePassword.TITLE}
      emoji={UserSettingsContent.ChangePassword.EMOJI}
      onClose={modalCloseHandler}
    >
      <ChangePasswordForm onSubmit={modalCloseHandler} />
    </ContentModal>
  );
};
