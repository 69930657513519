import { forwardRef, useMemo } from "react";
import cx from "classnames";
import type { DraggableSyntheticListeners } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import { useLessonSettings } from "hooks/redux";
import "./Draggable.styles.scss";

type TDraggableProps = {
  /**
   * If the draggable element that is being used in draggable overlay that is removed from the normal document flow and is positioned relative to the viewport, the `dragOverlay` will be `true`.
   * @default false
   */
  dragOverlay?: boolean;
  /**
   * If the draggable element that is currently being dragged is the current one where `useDraggable` is used, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates if draggable letter is placed in correct blank.
   * @default false
   */
  correct?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
  /**
   * The `@dnd-kit` lib provide listeners to the DOM node that become the activator to start dragging.
   */
  listeners?: DraggableSyntheticListeners;
  /**
   * After a draggable item is picked up, the transform property will be populated with the translate coordinates to move the item on the screen.
   */
  transform?: Transform | null;
  /**
   * Inner text content of the component.
   */
  content?: string;
};

const Draggable: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  TDraggableProps
> = (props, ref) => {
  const {
    dragOverlay = false,
    dragging = false,
    correct = false,
    showResult = false,
    transform,
    content,
    listeners,
  } = props;

  const { isExerciseReadOnlyMode } = useLessonSettings();

  const draggableStyles = useMemo(
    () => ({
      "--nb-move-words-from-letters-draggable-translate-x": `${
        transform?.x ?? 0
      }px`,
      "--nb-move-words-from-letters-draggable-translate-y": `${
        transform?.y ?? 0
      }px`,
    }),
    [transform]
  ) as React.CSSProperties;

  return (
    <div
      className={cx("nb-exercise-make-words-from-letters-draggable", {
        "nb-exercise-make-words-from-letters-draggable--overlay": dragOverlay,
        "nb-exercise-make-words-from-letters-draggable--dragging": dragging,
      })}
      style={draggableStyles}
    >
      <button
        ref={ref}
        aria-label="Літера, яку можна перетягнути"
        className={cx([
          "nb-exercise-make-words-from-letters-draggable-button",
          {
            "nb-exercise-make-words-from-letters-draggable-button--correct":
              correct && showResult,
            "nb-exercise-make-words-from-letters-draggable-button--fail":
              !correct && showResult,
          },
        ])}
        disabled={isExerciseReadOnlyMode}
        {...listeners}
      >
        {content}
      </button>
    </div>
  );
};

export const forwardedDraggable = forwardRef(Draggable);
