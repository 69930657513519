import { post, put } from "api";
import { PROFILE_ENDPOINTS } from "api/endpoints/profile";
import { TUrlParam } from "types/utils";
import { TUser } from "types/api/user";
import { TFileChangePhoto } from "types/api/file";

export const changeProfilePhoto = (
  userId: TUrlParam,
  body: TFileChangePhoto
): Promise<TUser> => post<TUser>(PROFILE_ENDPOINTS.CHANGE_PHOTO(userId), body);

export const editProfile = (user: TUser): Promise<TUser> =>
  put<TUser>(PROFILE_ENDPOINTS.ROOT, user);
