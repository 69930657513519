import {
  eachMinuteOfInterval,
  endOfDay,
  format,
  formatDuration,
  startOfDay,
} from "date-fns";
import { LessonGlobalContent } from "content";
import { LessonCreateTimeRange, LessonDuration } from "constants/lesson";
import { TSelectOption } from "types/app/select";
import { ELessonStatus, TLessonDuration } from "types/api/lesson";

export const LESSON_DURATION_OPTIONS: TSelectOption<TLessonDuration>[] =
  Object.values(LessonDuration).map((value) => ({
    label: formatDuration({ minutes: value }),
    value: value as TLessonDuration,
  }));

export const LESSON_TIME_OPTIONS: TSelectOption[] = eachMinuteOfInterval(
  {
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  },
  { step: 60 }
)
  .filter(
    (date) =>
      date.getHours() >= LessonCreateTimeRange.MIN &&
      date.getHours() < LessonCreateTimeRange.MAX
  )
  .map((date) => ({
    label: format(date, "HH:mm"),
    value: format(date, "HH:mm"),
  }));

export const LESSON_STATUS_OPTIONS: TSelectOption<ELessonStatus>[] = [
  {
    label: LessonGlobalContent.Option.SUCCESS,
    value: ELessonStatus.SUCCESS,
  },
  {
    label: LessonGlobalContent.Option.CANCELED,
    value: ELessonStatus.CANCELED,
  },
];
