import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  ExerciseConstructorContent,
  MakeWordFromLettersExerciseContent,
} from "content";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { Space } from "components/Common/Space";
import { Textarea } from "components/Interactive/Textarea";
import { WordsInputList } from "components/Interactive/WordsInputList";
import { Divider } from "components/Common/Divider";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import {
  adaptWordsToExerciseData,
  getMakeWordFromLettersExerciseFormInitialValues,
} from "./MakeWordFromLettersExerciseForm.helpers";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import {
  EExerciseType,
  TMakeWordFromLettersExercisePayload,
} from "types/app/exercises";
import { TMakeWordFromLettersExerciseForm } from "./MakeWordFromLettersExerciseForm.types";
import "./MakeWordFromLettersExerciseForm.styles.scss";

export const MakeWordFromLettersExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TMakeWordFromLettersExercisePayload>();
  const { mutate: addExercise } =
    useAddExercise<TMakeWordFromLettersExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TMakeWordFromLettersExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TMakeWordFromLettersExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TMakeWordFromLettersExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TMakeWordFromLettersExerciseForm> = useMemo(
    () => getMakeWordFromLettersExerciseFormInitialValues(editExercise),
    [editExercise]
  );

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<TMakeWordFromLettersExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TMakeWordFromLettersExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TMakeWordFromLettersExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TMakeWordFromLettersExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TMakeWordFromLettersExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (
    formData: TMakeWordFromLettersExerciseForm
  ) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const words = adaptWordsToExerciseData(formData.words);

    const addBody: TAddExercise<TMakeWordFromLettersExercisePayload> = {
      type: EExerciseType.MAKE_WORD_FROM_LETTERS,
      payload: {
        text: formData.text,
        data: {
          words,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TMakeWordFromLettersExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TMakeWordFromLettersExerciseForm>
          name="text"
          id="compose-words-from-letters-exercise-condition"
          className="nb-exercise-constructor-compose-words-from-letters-condition"
          label={MakeWordFromLettersExerciseContent.Add.Input.Condition.LABEL}
          placeholder={
            MakeWordFromLettersExerciseContent.Add.Input.Condition.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Divider />
        <WordsInputList<TMakeWordFromLettersExerciseForm>
          name="words"
          control={control}
          register={register}
          errors={errors}
          addInputButtonText={
            MakeWordFromLettersExerciseContent.Add.Input.Words.ADD_INPUT_BUTTON
          }
          label={MakeWordFromLettersExerciseContent.Add.Input.Words.LABEL}
          wordPlaceholder={
            MakeWordFromLettersExerciseContent.Add.Input.Words.WORD_PLACEHOLDER
          }
          hintPlaceholder={
            MakeWordFromLettersExerciseContent.Add.Input.Words.HINT_PLACEHOLDER
          }
          hint={MakeWordFromLettersExerciseContent.Add.Input.Words.HINT}
        />
      </Space>
    </ExerciseConfigForm>
  );
};
