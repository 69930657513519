import cx from "classnames";
import { Space } from "components/Common/Space";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { useState } from "react";
import { ExerciseContainer } from "./ExerciseContainer";
import {
  TCompareImageWithDescriptionExercise,
  TCompareImageWithDescriptionExercisePayload,
} from "types/app/exercises";
import "./CompareImageWithDescription.styles.scss";

type TCompareImageWithDescriptionProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TCompareImageWithDescriptionExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const CompareImageWithDescription: React.FC<
  TCompareImageWithDescriptionProps
> = (props) => {
  const { chapterId, exercise, prefix = null } = props;

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TCompareImageWithDescriptionExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
        isCollapsed={isCollapsed}
        onCollapseToggle={() => setIsCollapsed((prev) => !prev)}
      />
      <div
        className={cx([
          "nb-exercise-container",
          {
            "nb-exercise-container--collapsed": isCollapsed,
          },
        ])}
      >
        <ExerciseContainer exercise={exercise} />
      </div>
    </Space>
  );
};
