import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  DescribeVideoExerciseContent,
  ExerciseConstructorContent,
  ValidationContent,
} from "content";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { Space } from "components/Common/Space";
import { Textarea } from "components/Interactive/Textarea";
import { Input } from "components/Interactive/Input";
import { VideoPlayer } from "components/Interactive/VideoPlayer";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { YOUTUBE_REGEX } from "constants/validation";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import {
  EExerciseType,
  TDescribeVideoExercisePayload,
} from "types/app/exercises";
import { TDescribeVideoExerciseForm } from "./DescribeVideoExerciseForm.types";
import "./DescribeVideoExerciseForm.styles.scss";

export const DescribeVideoExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TDescribeVideoExercisePayload>();

  const { mutate: addExercise } = useAddExercise<TDescribeVideoExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TDescribeVideoExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TDescribeVideoExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TDescribeVideoExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TDescribeVideoExerciseForm> = useMemo(
    () => ({
      text: editExercise?.payload.text || "",
      description: editExercise?.payload.data.description || "",
      videoUrl: editExercise?.payload.data.videoUrl || "",
    }),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<TDescribeVideoExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const videoUrl = watch("videoUrl");
  const isValidVideoLink: boolean = YOUTUBE_REGEX.test(videoUrl);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TDescribeVideoExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TDescribeVideoExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TDescribeVideoExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TDescribeVideoExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TDescribeVideoExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const addBody: TAddExercise<TDescribeVideoExercisePayload> = {
      type: EExerciseType.VIDEO,
      payload: {
        text: formData.text,
        data: {
          description: formData.description,
          videoUrl: formData.videoUrl,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TDescribeVideoExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TDescribeVideoExerciseForm>
          name="text"
          id="describe-video-exercise-condition"
          className="nb-exercise-constructor-describe-video-condition"
          label={DescribeVideoExerciseContent.Add.Input.Text.LABEL}
          placeholder={DescribeVideoExerciseContent.Add.Input.Text.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Textarea<TDescribeVideoExerciseForm>
          name="description"
          id="describe-video-exercise-text"
          className="nb-exercise-constructor-describe-video-description"
          label={DescribeVideoExerciseContent.Add.Input.Description.LABEL}
          placeholder={
            DescribeVideoExerciseContent.Add.Input.Description.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
          hint={DescribeVideoExerciseContent.Add.Input.Description.HINT}
        />
        <Input<TDescribeVideoExerciseForm>
          name="videoUrl"
          id="describe-video-exercise-url"
          className="nb-exercise-constructor-describe-video-url"
          type="url"
          label={DescribeVideoExerciseContent.Add.Input.Url.LABEL}
          placeholder={DescribeVideoExerciseContent.Add.Input.Url.PLACEHOLDER}
          register={register}
          errors={errors}
          rules={{
            pattern: {
              value: YOUTUBE_REGEX,
              message: ValidationContent.Pattern.YouTube,
            },
          }}
          required
          fullWidth
        />
        {isValidVideoLink && (
          <VideoPlayer
            url={videoUrl}
            controls
            light
            lazy
            volume={0.2}
            width="100%"
            height="100%"
          />
        )}
      </Space>
    </ExerciseConfigForm>
  );
};
