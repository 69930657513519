import { Outlet } from "react-router-dom";
import { AppContainer } from "components/Layout/AppContainer";
import { Sidebar } from "components/Layout/Sidebar";
import { SiteContent } from "components/Layout/SiteContent";
import { SiteHeader } from "components/Layout/SiteHeader";
import { MainContainer } from "components/Layout/MainContainer";

type AppLayoutProps = {
  /**
   * If `true` site header component will be hidden.
   * @default false
   */
  hideHeader?: boolean;
};

export const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const { hideHeader = false } = props;

  return (
    <AppContainer>
      <Sidebar />
      <SiteContent>
        {!hideHeader && <SiteHeader />}
        <MainContainer>
          <Outlet />
        </MainContainer>
      </SiteContent>
    </AppContainer>
  );
};
