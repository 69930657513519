import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const describeImageExercise: TExerciseItem = {
  title: "Зображення",
  backgroundText: "Зображення",
  tags: [
    "Зображення",
    "картинка",
    "фото",
    "описати",
    "image",
    "picture",
    "photo",
    "describe",
  ],
  type: EExerciseType.IMAGE,
  cover: "describe-image.png",
};
