import { ChapterSidebarItem } from "../ChaptersSidebarItem";
import type { TCourseLessonChapter } from "types/api/course";
import "./ChaptersSidebarList.styles.scss";

type ChapterSidebarListProps = {
  /**
   * Lesson chapters data to display.
   * @default []
   */
  lessonChapters?: TCourseLessonChapter[];
  /**
   * Indicates active chapter item ID, if any.
   * @default null
   */
  activeChapterId?: number | null;
  /**
   * Callback function fires on chapter item click to set active state.
   * @param chapterId current active chapter ID.
   * @returns {void} nothing.
   */
  onChapterClick?: (chapterId: number) => void;
  /**
   * Callback function fires on chapter item edit button click.
   * @param chapter current active chapter data.
   * @returns {void} nothing.
   */
  onChapterEditClick?: (chapter: TCourseLessonChapter) => void;
  /**
   * Callback function fires on chapter item delete button click.
   * @param chapter current active chapter data.
   * @returns {void} nothing.
   */
  onChapterDeleteClick?: (chapter: TCourseLessonChapter) => void;
};

export const ChapterSidebarList: React.FC<ChapterSidebarListProps> = (
  props
) => {
  const {
    lessonChapters = [],
    activeChapterId = null,
    onChapterClick,
    onChapterDeleteClick,
    onChapterEditClick,
  } = props;

  if (lessonChapters.length === 0) {
    return null;
  }

  return (
    <ul className="nb-lesson-details-chapters-sidebar-list">
      {lessonChapters.map((chapter) => (
        <ChapterSidebarItem
          key={chapter.id}
          active={chapter.id === activeChapterId}
          chapter={chapter}
          onChapterClick={onChapterClick}
          onChapterDeleteClick={onChapterDeleteClick}
          onChapterEditClick={onChapterEditClick}
        />
      ))}
    </ul>
  );
};
