import cx from "classnames";
import {
  add,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
  sub,
} from "date-fns";
import { useScheduleDates } from "hooks/redux";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { IconButton } from "components/Interactive/IconButton";
import { DatePicker } from "components/Interactive/DatePicker";
import { logError } from "utils/notifications";
import { EScheduleViewType } from "store/features/schedule";
import "./DateControls.styles.scss";

type DateControlsProps = {
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DateControls: React.FC<DateControlsProps> = (props) => {
  const { className } = props;

  const { viewType, startDate, endDate, setStartDate, setEndDate } =
    useScheduleDates();

  const previousDateClickHandler = () => {
    switch (viewType) {
      case EScheduleViewType.DAY:
        setStartDate(startOfDay(sub(startDate, { days: 1 })));
        setEndDate(endOfDay(sub(endDate, { days: 1 })));
        break;

      case EScheduleViewType.WEEK:
        setStartDate(startOfISOWeek(sub(startDate, { weeks: 1 })));
        setEndDate(endOfISOWeek(sub(endDate, { weeks: 1 })));
        break;

      case EScheduleViewType.MONTH:
        setStartDate(startOfMonth(sub(startDate, { months: 1 })));
        setEndDate(endOfMonth(sub(endDate, { months: 1 })));
        break;

      case EScheduleViewType.YEAR:
        setStartDate(startOfYear(sub(startDate, { years: 1 })));
        setEndDate(endOfYear(sub(endDate, { years: 1 })));
        break;

      default:
        logError({ message: "Invalid view type was provided!" });
    }
  };

  const nextDateClickHandler = () => {
    switch (viewType) {
      case EScheduleViewType.DAY:
        setStartDate(startOfDay(add(startDate, { days: 1 })));
        setEndDate(endOfDay(add(endDate, { days: 1 })));
        break;

      case EScheduleViewType.WEEK:
        setStartDate(startOfISOWeek(add(startDate, { weeks: 1 })));
        setEndDate(endOfISOWeek(add(endDate, { weeks: 1 })));
        break;

      case EScheduleViewType.MONTH:
        setStartDate(startOfMonth(add(startDate, { months: 1 })));
        setEndDate(endOfMonth(add(endDate, { months: 1 })));
        break;

      case EScheduleViewType.YEAR:
        setStartDate(startOfYear(add(startDate, { years: 1 })));
        setEndDate(endOfYear(add(endDate, { years: 1 })));
        break;

      default:
        logError({ message: "Invalid view type was provided!" });
    }
  };

  const rangeDateClickHandler = (start: Date, end: Date) => {
    switch (viewType) {
      case EScheduleViewType.DAY:
        setStartDate(startOfDay(start));
        setEndDate(endOfDay(end));
        break;

      case EScheduleViewType.WEEK:
        setStartDate(startOfISOWeek(start));
        setEndDate(endOfISOWeek(end));
        break;

      case EScheduleViewType.MONTH:
        /**
         * Currently not supported view type by `DatePicker`.
         */
        break;

      case EScheduleViewType.YEAR:
        /**
         * Currently not supported view type by `DatePicker`.
         */
        break;

      default:
        logError({ message: "Invalid view type was provided!" });
    }
  };

  return (
    <div className={cx(["nb-sub-date-controls-container", className])}>
      <IconButton
        icon={<Chevron width={15} height={15} />}
        className="nb-sub-date-controls-previous"
        onClick={previousDateClickHandler}
      />
      <DatePicker
        className="nb-sub-date-controls-picker"
        onChange={rangeDateClickHandler}
        value={[new Date(startDate), new Date(endDate)]}
        mode="week"
        view="month"
        position="bottom-left"
      />
      <IconButton
        icon={<Chevron width={15} height={15} />}
        className="nb-sub-date-controls-next"
        onClick={nextDateClickHandler}
      />
    </div>
  );
};
