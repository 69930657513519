import { Link, To } from "react-router-dom";
import cx from "classnames";
import "./NavigationItem.styles.scss";

type NavigationItemProps = {
  /**
   * Describes a location that is the destination of navigation via URL.
   */
  link: To;
  /**
   * Label text for the `link` element.
   */
  label: string;
  /**
   * Element placed left of the text node as icon.
   */
  icon?: React.ReactNode;
  /**
   * If `true`, the component is displaying as active.
   *
   * @default false
   */
  active?: boolean;
};

export const NavigationItem: React.FC<NavigationItemProps> = (props) => {
  const { link, label, icon, active = false } = props;

  return (
    <Link
      to={link}
      className={cx([
        "nb-navigation-item",
        { "nb-navigation-item--active": active },
      ])}
    >
      {icon && <span className="nb-navigation-item-icon">{icon}</span>}
      <span className="nb-navigation-item-text">{label}</span>
    </Link>
  );
};
