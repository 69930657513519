import { forwardRef, useMemo } from "react";
import cx from "classnames";
import {
  TSpaceAlign,
  TSpaceDirection,
  TSpaceFixedSize,
  TSpaceJustify,
  TSpaceSize,
  TSpaceSizeStyles,
} from "./Space.types";
import "./Space.styles.scss";

type SpaceProps = {
  /**
   * Aligns items along the main axis of the current line of the flex container.
   * For `direction` equals to `horizontal` main axis is X, and for `vertical` main axis is Y.
   * @default "stretch"
   */
  justify?: TSpaceJustify;
  /**
   * Aligns flex items along the cross axis of the current line of the flex container.
   * @default "stretch"
   */
  align?: TSpaceAlign;
  /**
   * The space direction.
   * @default "horizontal"
   */
  direction?: TSpaceDirection;
  /**
   * The space size.
   * @namespace {
   *  none: 0px,
   *  xx-small: 5px,
   *  x-small: 10px,
   *  small: 15px,
   *  medium: 20px,
   *  large: 25px,
   *  x-large: 30px,
   *  xx-large: 35px,
   *  xxx-large: 40px
   * }
   * @default "none"
   */
  size?: TSpaceSize;
  /**
   * Auto wrap line, when `horizontal` effective.
   * @default false
   */
  wrap?: boolean;
  /**
   * Option to fit width to its parent's width.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the container.
   */
  style?: React.CSSProperties;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

/**
 * `Space` common component serves as a wrapper component for children to avoid them clinging together and add specified spacing.
 */
const Space: React.ForwardRefRenderFunction<HTMLDivElement, SpaceProps> = (
  props,
  ref
) => {
  const {
    justify = "stretch",
    align = "stretch",
    direction = "horizontal",
    size = "none",
    wrap = false,
    fullWidth = false,
    className,
    style,
    children,
  } = props;

  const spaceSizeClassName: string = useMemo(() => {
    let xSize: TSpaceFixedSize = "none";
    let ySize: TSpaceFixedSize = "none";

    if (Array.isArray(size)) {
      const [xArraySize = "none", yArraySize = "none"] = size;
      xSize = typeof xArraySize === "string" ? xArraySize : "none";
      ySize = typeof yArraySize === "string" ? yArraySize : "none";
    }

    if (typeof size === "string") {
      xSize = size;
      ySize = size;
    }

    return cx({
      "nb-common-space--size-row-none": xSize === "none",
      "nb-common-space--size-column-none": ySize === "none",
      "nb-common-space--size-row-xx-small": xSize === "xx-small",
      "nb-common-space--size-column-xx-small": ySize === "xx-small",
      "nb-common-space--size-row-x-small": xSize === "x-small",
      "nb-common-space--size-column-x-small": ySize === "x-small",
      "nb-common-space--size-row-small": xSize === "small",
      "nb-common-space--size-column-small": ySize === "small",
      "nb-common-space--size-row-medium": xSize === "medium",
      "nb-common-space--size-column-medium": ySize === "medium",
      "nb-common-space--size-row-large": xSize === "large",
      "nb-common-space--size-column-large": ySize === "large",
      "nb-common-space--size-row-x-large": xSize === "x-large",
      "nb-common-space--size-column-x-large": ySize === "x-large",
      "nb-common-space--size-row-xx-large": xSize === "xx-large",
      "nb-common-space--size-column-xx-large": ySize === "xx-large",
      "nb-common-space--size-row-xxx-large": xSize === "xxx-large",
      "nb-common-space--size-column-xxx-large": ySize === "xxx-large",
    });
  }, [size]);

  const spaceCustomSize: TSpaceSizeStyles = useMemo(() => {
    if (typeof size === "number") {
      return {
        style: {
          gap: `${size}px`,
        },
      };
    }

    if (Array.isArray(size)) {
      const [xSize, ySize] = size;
      return {
        style: {
          ...(xSize && typeof xSize === "number" && { rowGap: `${xSize}px` }),
          ...(ySize &&
            typeof ySize === "number" && { columnGap: `${ySize}px` }),
        },
      };
    }

    return { style: {} };
  }, [size]);

  return (
    <div
      ref={ref}
      className={cx([
        "nb-common-space",
        {
          "nb-common-space--direction-horizontal": direction === "horizontal",
          "nb-common-space--direction-vertical": direction === "vertical",
          "nb-common-space--wrap": wrap,
          "nb-common-space--full-width": fullWidth,
          "nb-common-space--align-stretch": align === "stretch",
          "nb-common-space--align-start": align === "start",
          "nb-common-space--align-end": align === "end",
          "nb-common-space--align-center": align === "center",
          "nb-common-space--align-baseline": align === "baseline",
          "nb-common-space--justify-stretch": justify === "stretch",
          "nb-common-space--justify-start": justify === "start",
          "nb-common-space--justify-end": justify === "end",
          "nb-common-space--justify-center": justify === "center",
          "nb-common-space--justify-baseline": justify === "baseline",
          "nb-common-space--justify-between": justify === "between",
        },
        spaceSizeClassName,
        className,
      ])}
      style={{ ...style, ...spaceCustomSize.style }}
    >
      {children}
    </div>
  );
};

export const ForwardedSpace = forwardRef(Space);
