export const Modal = {
  Action: {
    DEFAULT: {
      TEXT: "Ви впевнені, що хочете виконати цю дію?",
      CLOSE_BUTTON: "Відмінити",
      SUBMIT_BUTTON: "Так",
      EMOJI: "😥",
    },
  },
  Content: {
    DEFAULT: {
      TITLE: "Назва вікна",
      SUBMIT_BUTTON: "Зберегти зміни",
      EMOJI: "😊",
    },
  },
};
