import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Area } from "react-easy-crop";
import { EAppReducers } from "store/white-list";

export type TImageUploadState = {
  /**
   * User selected image as URL encoded string in original shape.
   * @default null
   */
  imageBase64: string | null;
  /**
   * Cropped selected image based on cropped area as URL encoded string.
   * @default null
   */
  croppedImageBase64: string | null;
  /**
   * Cropped area returned by image cropper package.
   * @default null
   */
  croppedArea: Area | null;
};

const initialState: TImageUploadState = {
  imageBase64: null,
  croppedImageBase64: null,
  croppedArea: null,
};

export const imageUploadSlice = createSlice({
  name: EAppReducers.IMAGE_UPLOAD,
  initialState,
  reducers: {
    setImageBase64: (state, action: PayloadAction<string>) => {
      state.imageBase64 = action.payload;
    },
    setCroppedImageBase64: (state, action: PayloadAction<string>) => {
      state.croppedImageBase64 = action.payload;
    },
    setCroppedArea: (state, action: PayloadAction<Area>) => {
      state.croppedArea = action.payload;
    },
    resetImage: (state) => {
      state.imageBase64 = initialState.imageBase64;
    },
    resetCroppedImage: (state) => {
      state.croppedImageBase64 = initialState.croppedImageBase64;
    },
    resetCroppedArea: (state) => {
      state.croppedArea = initialState.croppedArea;
    },
    resetAll: (state) => {
      state.imageBase64 = initialState.imageBase64;
      state.croppedImageBase64 = initialState.croppedImageBase64;
      state.croppedArea = initialState.croppedArea;
    },
  },
});

export const imageUploadReducer = imageUploadSlice.reducer;
