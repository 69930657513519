import { nanoid } from "nanoid";
import { shuffleArray } from "utils/common";
import {
  TWordsComparingExerciseParsedData,
  TWordsComparingExerciseParsedLinkedWord,
  TWordsComparingExerciseParsedWord,
  TWordsComparingExerciseWord,
} from "types/app/exercises";
import { TExercise } from "types/api/exercise";
import { TWordsComparingExerciseForm } from "./WordsComparingExerciseForm.types";

export const getWordsComparingExerciseFormInitialValues = (
  editExercise: TExercise<TWordsComparingExerciseParsedData> | null
): TWordsComparingExerciseForm => ({
  text: editExercise?.payload.text || "",
  words: editExercise?.payload.data.rawWords || [
    {
      id: nanoid(),
      word: "",
      linkedWord: "",
      hint: null,
    },
  ],
});

/**
 * Utility function that helps to adapt raw words array to structured data which makes easier to work with complex operations.
 * @param rawWords raw words array from react-hook-form.
 * @returns object of structured `WordsComparing` exercise data.
 */
export const adaptWordsToExerciseData = (
  rawWords: TWordsComparingExerciseWord[]
): TWordsComparingExerciseParsedData => {
  const words: TWordsComparingExerciseParsedWord[] = [];
  const linkedWords: TWordsComparingExerciseParsedLinkedWord[] = [];

  for (const rawWord of rawWords) {
    const wordId: string = nanoid();
    const linkedWordId: string = nanoid();

    const word: TWordsComparingExerciseParsedWord = {
      id: wordId,
      content: rawWord.word,
      correctLinkedWordId: linkedWordId,
      selectedLinkedWordId: null,
      isPairSelected: false,
      isPairCorrect: false,
      hint: rawWord.hint || null,
      currentAttempt: 0,
      attempts: [],
    };
    words.push(word);

    const linkedWord: TWordsComparingExerciseParsedLinkedWord = {
      id: linkedWordId,
      content: rawWord.linkedWord,
      correctWordId: wordId,
      isPairCorrect: false,
      isPairSelected: false,
      isTouched: false,
      isLocked: false,
      selectedWordId: null,
    };
    linkedWords.push(linkedWord);
  }

  return {
    rawWords,
    words,
    linkedWords,
    shuffledLinkedWords: shuffleArray(linkedWords),
  };
};
