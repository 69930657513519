import { useMemo } from "react";
import { Paragraph } from "components/Typography/Paragraph";
import "./ExerciseCondition.styles.scss";

type TExerciseConditionProps = {
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
  /**
   * Exercise condition text to display to the student.
   */
  text?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const ExerciseCondition: React.FC<TExerciseConditionProps> = (props) => {
  const { prefix = null, text, className } = props;

  const content: string = useMemo(
    () => (prefix ? `${prefix} ${text}` : `${text}`),
    [prefix, text]
  );

  return (
    <Paragraph variant={2} bold className={className}>
      {content}
    </Paragraph>
  );
};
