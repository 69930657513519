import { Fragment, useEffect, useState } from "react";
import { createPortal } from "react-dom";

type ReactPortalProps = {
  /**
   * The children to render into the `container`.
   */
  children: React.ReactNode;
  /**
   * Property, which is the ID attribute of a DOM element and acts as the container for the portal.
   */
  wrapperId: string;
};

/**
 * Portals provide a first-class way to render children into a DOM node
 * that exists outside the DOM hierarchy of the parent component.
 */
export const ReactPortal: React.FC<ReactPortalProps> = (props) => {
  const { children, wrapperId } = props;

  const [mountNode, setMountNode] = useState<
    HTMLDivElement | HTMLElement | null
  >(null);

  const createWrapperAndAppendToBody = (id: string): HTMLDivElement => {
    const wrapperElement = document.createElement("div");
    wrapperElement.setAttribute("id", id);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
  };

  useEffect(() => {
    const element = document.getElementById(wrapperId);
    if (element) {
      setMountNode(element);
    } else {
      setMountNode(createWrapperAndAppendToBody(wrapperId));
    }
  }, [wrapperId]);

  useEffect(() => {
    return () => {
      mountNode?.remove();
    };
  }, [mountNode]);

  return <Fragment>{mountNode && createPortal(children, mountNode)}</Fragment>;
};
