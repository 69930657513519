import { ExerciseSearch } from "../ExerciseSearch";
import { ExerciseList } from "../ExerciseList";
import "./ConstructorMain.styles.scss";

export const ConstructorMain: React.FC = () => {
  return (
    <aside className="nb-exercise-constructor-main">
      <ExerciseSearch />
      <div className="nb-exercise-constructor-list-holder">
        <ExerciseList />
      </div>
    </aside>
  );
};
