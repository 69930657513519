import { useQuery } from "react-query";
import { fetchTeachers } from "api/services/teachers";
import { ETeachersQueryKeys } from "api/endpoints/teachers";
import { logError } from "utils/notifications";
import { TTeachersOptions, TTeachersResponse } from "types/api/teacher";
import { TApiError } from "types/api/error";

export const useTeachers = (options?: TTeachersOptions) => {
  const {
    searchText = "",
    pageNumber = 0,
    pageSize = 10,
    blocked = false,
  } = options || {};
  return useQuery<TTeachersResponse, TApiError>(
    [ETeachersQueryKeys.getTeachers, searchText, pageNumber, pageSize, blocked],
    () => fetchTeachers(searchText, pageNumber, pageSize),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: !blocked,
    }
  );
};
