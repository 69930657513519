import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { DragAndDropContent } from "content";
import { Space } from "components/Common/Space";
import { Paragraph } from "components/Typography/Paragraph";
import "./DraggableStartZone.styles.scss";

type TDraggableStartZoneProps = {
  /**
   * The identifier of the droppable zone.
   */
  droppableId: string;
  /**
   * Indicates if the start zone is empty.
   * @default false
   */
  empty?: boolean;
  /**
   * The text that will be displayed when the start zone is empty.
   */
  emptyText?: string;
  /**
   * The content of the draggable start zone.
   */
  children?: React.ReactNode;
};

export const DraggableStartZone: React.FC<TDraggableStartZoneProps> = (
  props
) => {
  const {
    droppableId,
    empty = false,
    emptyText = DragAndDropContent.Default.StartZone.EMPTY,
    children,
  } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: droppableId,
  });

  return (
    <Space
      ref={setNodeRef}
      direction="horizontal"
      align="center"
      justify={empty ? "center" : "start"}
      size="medium"
      fullWidth
      wrap
      className={cx([
        "nb-drag-and-drop-draggable-start-zone",
        { "nb-drag-and-drop-draggable-start-zone--over": isOver },
      ])}
    >
      {empty && <Paragraph variant={2}>{emptyText}</Paragraph>}
      {children}
    </Space>
  );
};
