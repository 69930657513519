import { useMemo } from "react";
import {
  Control,
  FieldErrorsImpl,
  Path,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { Space } from "components/Common/Space";
import { Image } from "components/Common/Image";
import { IconButton } from "components/Interactive/IconButton";
import { Textarea } from "components/Interactive/Textarea";
import { Button } from "components/Interactive/Button";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { TImageListUploadDefaultValue } from "../ImageListUpload.types";
import "./ImageItem.styles.scss";

type TImageItemProps<
  TFormValues extends TImageListUploadDefaultValue = TImageListUploadDefaultValue
> = {
  /**
   * Name attribute of the `PairWordsInput` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: Path<TFormValues>;
  /**
   * Index of the input item from `react-hook-form` array fields.
   */
  index: number;
  /**
   * The id that `react-hook-form` created to associate `input` element and form array fields.
   */
  id?: string;
  /**
   * Description label text for the `textarea` element.
   */
  label?: string;
  /**
   * The short hint displayed in the description `textarea` before the user enters a value.
   */
  placeholder?: string;
  /**
   * The text hint displayed as tooltip popup right to the label, which looks like a question icon button. Hint will appear when user clicks on the hint button.
   */
  hint?: string;
  /**
   * The content of the pick image button.
   */
  pickImageButtonText?: string;
  /**
   * The content of the change image button.
   */
  changeImageButtonText?: string;
  /**
   * If `true`, the delete button is disabled. Used for preventing deletion of the single input item.
   * @default false
   */
  disableDelete?: boolean;
  /**
   * If `true`, the description field will be required to fill.
   * @default false
   */
  descriptionRequired?: boolean;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * Callback fired when user clicks on `Add image` button.
   */
  onAddModalOpen?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

export const ImageItem = <
  TFormValues extends TImageListUploadDefaultValue = TImageListUploadDefaultValue
>(
  props: TImageItemProps<TFormValues>
): JSX.Element => {
  const {
    name,
    label,
    placeholder,
    hint,
    id,
    pickImageButtonText,
    changeImageButtonText,
    control,
    register,
    errors,
    disableDelete = false,
    descriptionRequired = false,
    onDelete,
    onAddModalOpen,
  } = props;

  const imageEncoded = useWatch({
    control,
    name: `${name}.imageEncoded` as Path<TFormValues>,
  });

  const imageUrl = useWatch({
    control,
    name: `${name}.imageUrl` as Path<TFormValues>,
  });

  const isImageAvailable: boolean = useMemo(
    () => imageEncoded !== null || imageUrl !== null,
    [imageEncoded, imageUrl]
  );

  const imageSource: string = useMemo(
    () => (imageEncoded || imageUrl) as string,
    [imageEncoded, imageUrl]
  );

  return (
    <Space direction="vertical" justify="start" size="x-small" fullWidth>
      <Space
        direction="vertical"
        justify="start"
        size="x-small"
        fullWidth
        className="nb-interactive-image-upload-preview-container"
      >
        {isImageAvailable && <Image src={imageSource} rounded fullWidth />}
        <Space
          direction="horizontal"
          justify="stretch"
          align="center"
          size="x-small"
          fullWidth
        >
          <Button variant="secondary" fullWidth onClick={onAddModalOpen}>
            {isImageAvailable ? changeImageButtonText : pickImageButtonText}
          </Button>
          <IconButton
            icon={<DeleteIcon />}
            variant="secondary"
            className="nb-interactive-image-list-upload-item-remove"
            disabled={disableDelete}
            onClick={onDelete}
          />
        </Space>
      </Space>
      <Textarea<TFormValues>
        name={`${name}.description` as Path<TFormValues>}
        id={id}
        className="nb-interactive-image-list-upload-description-field"
        label={label}
        placeholder={placeholder}
        hint={hint}
        register={register}
        errors={errors}
        required={descriptionRequired}
        fullWidth
      />
    </Space>
  );
};
