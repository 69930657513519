import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  LessonDetailsContent as Content,
  StudentClassroomContent,
} from "content";
import {
  useAddCourseLessonToStudent,
  useCourseLessonById,
  useCourseLessonChapters,
  useCourseLessons,
} from "hooks/api/courses";
import { useStudentById } from "hooks/api/students";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { useBreadcrumbs, useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { Button } from "components/Interactive/Button";
import {
  ChaptersSidebar,
  HomeworkContent,
  HomeworkSidebar,
  LessonContainer,
  LessonContent,
  LessonSidebar,
} from "components/Lesson";
import { logError } from "utils/notifications";
import { Route } from "constants/routes";
import { ELessonContentType } from "types/api/lesson";

export const StudentClassroomCourseLesson: React.FC = () => {
  const { studentId, courseId, lessonId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryChapterId = searchParams.get("chapterId");

  const { setPageName, resetPageName } = useBreadcrumbs();

  const [contentType, setContentType] = useState<ELessonContentType>(
    ELessonContentType.LESSON
  );
  const [activeChapterId, setActiveChapterId] = useState<number | null>(null);

  const { data: student } = useStudentById(studentId);
  const { data: lessonChapters = [], isLoading: isChaptersLoading } =
    useCourseLessonChapters(courseId, lessonId);
  const {
    data: lesson,
    isLoading: isLessonLoading,
    error,
  } = useCourseLessonById(courseId, lessonId);
  const { data: studentCourseLessonsData, isLoading: isStudentLessonsLoading } =
    useCourseLessons(courseId, {
      studentId,
      pageSize: 100,
    });
  const { mutate: addLessonToStudent } = useAddCourseLessonToStudent();

  const isLessonPinned: boolean = useMemo(
    () =>
      studentCourseLessonsData?.content.some(
        (lesson) => lesson.id === Number(lessonId)
      ) || false,
    [studentCourseLessonsData?.content, lessonId]
  );

  const isLoading: boolean = useMemo(
    () => isLessonLoading || isChaptersLoading || isStudentLessonsLoading,
    [isLessonLoading, isChaptersLoading, isStudentLessonsLoading]
  );

  useEffect(() => {
    // TODO: handle properly not found resource error
    if (error) {
      navigate(Route.App.Materials.ROOT.path);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (student?.person) {
      const fullName = `${StudentClassroomContent.Name} ${student.person.firstName} ${student.person.lastName}`;
      setPageName(fullName);
    }
    return () => resetPageName();
  }, [student?.person, setPageName, resetPageName]);

  const {
    setCourseId,
    setLessonId,
    setHomeworkMode,
    setLessonMode,
    setHomeworkId,
  } = useExerciseConstructor();

  const { setViewMode } = useLessonSettings();

  useEffect(() => {
    setCourseId(courseId);
    setLessonId(lessonId);
  }, [courseId, lessonId, setCourseId, setLessonId]);

  useEffect(() => {
    setViewMode("preview");

    return () => {
      setViewMode("constructor");
    };
  }, [setViewMode]);

  useEffect(() => {
    const isChaptersAvailable: boolean = lessonChapters.length > 0;

    // Case 0: Lesson does not have chapters
    if (!isChaptersAvailable && !queryChapterId) return;

    // Case 1: Lesson has chapters and chapterId parameter is not provided (when user opens lesson from materials list)
    if (!queryChapterId && isChaptersAvailable) {
      const [firstChapter] = lessonChapters;
      setActiveChapterId(firstChapter.id);
      setSearchParams({ chapterId: firstChapter.id.toString() });
      return;
    }

    const targetChapter = lessonChapters.find(
      (chapter) => chapter.id === Number(queryChapterId)
    );

    // Case 2: Lesson has chapters and chapterId parameter is provided (when user opens direct link to lesson chapter)
    if (queryChapterId && isChaptersAvailable && targetChapter) {
      setActiveChapterId(targetChapter.id);
      setSearchParams({ chapterId: targetChapter.id.toString() });
      return;
    }

    // Case 3: Lesson has chapters and chapterId parameter is provided but chapter with this id does not exist
    if (queryChapterId && isChaptersAvailable && !targetChapter) {
      logError({ message: Content.Chapter.Error.INVALID_LESSON_CHAPTER_ID });
      return;
    }
  }, [lessonChapters, queryChapterId, setSearchParams]);

  const chapterButtonClickHandler = (chapterId: number) => {
    setContentType(ELessonContentType.LESSON);
    setActiveChapterId(chapterId);
    setSearchParams({ chapterId: chapterId.toString() });
    setLessonMode();
  };

  const homeworkButtonClickHandler = () => {
    setContentType(ELessonContentType.HOMEWORK);
    setActiveChapterId(null);
    setHomeworkId(lesson?.homeworkId);
    setHomeworkMode();
  };

  const backClickHandler = () => {
    navigate(
      Route.App.Study.STUDENT_CLASSROOM_COURSE.construct(studentId, courseId)
    );
  };

  const pinLessonClickHandler = () => {
    if (!studentId) return;
    addLessonToStudent({
      studentId: Number(studentId),
      courseId,
      lessonId,
    });
  };

  const unpinLessonClickHandler = () => {
    // TODO: implement unpin lesson
    // eslint-disable-next-line no-console
    console.log("unpinLessonClickHandler");
  };

  return (
    <LessonContainer>
      {contentType === ELessonContentType.LESSON && (
        <LessonContent
          activeChapterId={activeChapterId}
          loading={isLoading}
          lesson={lesson}
          lessonChapters={lessonChapters}
        />
      )}
      {contentType === ELessonContentType.HOMEWORK && (
        <HomeworkContent
          loading={isChaptersLoading}
          homeworkId={lesson?.homeworkId}
        />
      )}
      <LessonSidebar withHeader>
        <Space
          direction="horizontal"
          size="x-small"
          justify="between"
          align="center"
          fullWidth
        >
          <Button variant="secondary" onClick={backClickHandler} fullWidth>
            {StudentClassroomContent.LessonPreview.Action.BACK}
          </Button>
          <Button
            onClick={
              isLessonPinned ? unpinLessonClickHandler : pinLessonClickHandler
            }
            fullWidth
          >
            {isLessonPinned
              ? StudentClassroomContent.LessonPreview.Action.UNPIN
              : StudentClassroomContent.LessonPreview.Action.PIN}
          </Button>
        </Space>
        <ChaptersSidebar
          loading={isChaptersLoading}
          lessonChapters={lessonChapters}
          activeChapterId={activeChapterId}
          onChapterClick={chapterButtonClickHandler}
        />
        <HomeworkSidebar
          active={contentType === ELessonContentType.HOMEWORK}
          onButtonClick={homeworkButtonClickHandler}
        />
      </LessonSidebar>
    </LessonContainer>
  );
};
