import { cloneElement } from "react";
import { ControlProps, components } from "react-select";
import cx from "classnames";
import type { TSelectDefaultOption } from "../../Select.types";
import "./Control.styles.scss";

type SelectControlProps<
  Option extends TSelectDefaultOption = TSelectDefaultOption
> = {
  /**
   * Element placed left of the selected value.
   */
  icon?: React.ReactElement;
  /**
   * Is the select disabled
   * @default false
   */
  readonly?: boolean;
} & ControlProps<Option, false>;

export const Control = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>({
  children,
  menuIsOpen,
  isFocused,
  icon,
  isDisabled,
  readonly = false,
  ...controlProps
}: SelectControlProps<Option>): JSX.Element => {
  return (
    <components.Control
      {...controlProps}
      menuIsOpen={menuIsOpen}
      isFocused={isFocused}
      isDisabled={isDisabled}
      className={cx([
        "nb-interactive-select-control",
        {
          "nb-interactive-select-control--open": menuIsOpen,
          "nb-interactive-select-control--focused": isFocused,
          "nb-interactive-select-control--disabled": isDisabled,
          "nb-interactive-select-control--readonly": readonly,
        },
      ])}
    >
      {icon &&
        cloneElement(icon, {
          className: "nb-interactive-select-icon",
        })}
      {children}
    </components.Control>
  );
};
