import { useEffect, useMemo, useState } from "react";
import { useLessonSettings } from "hooks/redux";
import { ClueSelect } from "components/Interactive/ClueSelect";
import { ImagesList } from "../ImagesList";
import { ImageItem } from "../ImageItem";
import {
  updateAnswersData,
  updateImagesData,
} from "./SelectController.helpers";
import {
  TCompareImageWithDescriptionExercise,
  TCompareImageWithDescriptionExerciseParsedAnswer,
  TCompareImageWithDescriptionExerciseParsedImage,
  TExerciseIdentifier,
} from "types/app/exercises";
import { TSelectOption } from "types/app/select";
import "./SelectController.styles.scss";

type TSelectControllerProps = {
  /**
   * An exercise raw data from API response.
   */
  exercise: TCompareImageWithDescriptionExercise;
  /**
   * Callback fired when the answer was changed in select.
   * @param imagedId id of the image that was performed the change.
   * @param selectedAnswer data of the answer that was selected.
   */
  onChange?: (
    imageId: TExerciseIdentifier,
    selectedAnswer: TCompareImageWithDescriptionExerciseParsedAnswer
  ) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const SelectController: React.FC<TSelectControllerProps> = (props) => {
  const { exercise, onChange, className } = props;

  const [answers, setAnswers] = useState<
    TCompareImageWithDescriptionExerciseParsedAnswer[]
  >(exercise.payload.data.answers);
  const [images, setImages] = useState<
    TCompareImageWithDescriptionExerciseParsedImage[]
  >(exercise.payload.data.images);

  const { isExerciseReadOnlyMode, isExercisePreviewMode } = useLessonSettings();

  useEffect(() => {
    setAnswers(exercise.payload.data.shuffledAnswers);
    setImages(exercise.payload.data.images);
  }, [exercise]);

  const isAllowToShowResult: boolean = useMemo(
    () => images.every((image) => image.currentAnswer !== null),
    [images]
  );

  const imageSelectChangeHandler = (
    option: TSelectOption<string>,
    id: TExerciseIdentifier
  ) => {
    const selectedAnswer: TCompareImageWithDescriptionExerciseParsedAnswer | null =
      answers.find((answer) => answer.id === option.value) || null;

    if (!selectedAnswer) {
      return;
    }

    setAnswers((prevAnswers) => updateAnswersData(prevAnswers, selectedAnswer));
    setImages((prevImages) => updateImagesData(prevImages, id, selectedAnswer));

    onChange?.(id, selectedAnswer);
  };

  const getCorrectAnswerOption = (
    image: TCompareImageWithDescriptionExerciseParsedImage
  ): TSelectOption<string> | null => {
    if (isExercisePreviewMode) {
      if (!image.currentAnswer) {
        return null;
      }
      return {
        value: image.currentAnswer.id as string,
        label: image.currentAnswer.content,
      };
    }
    return null;
  };

  return (
    <ImagesList className={className}>
      {images.map((image) => (
        <ImageItem key={image.id} fileUrl={image.fileUrl}>
          <ClueSelect
            id={image.id}
            hint={image.hint}
            options={image.options}
            disabled={isAllowToShowResult || isExerciseReadOnlyMode}
            showResult={isAllowToShowResult}
            correct={image.isCorrectAnswer && isAllowToShowResult}
            required
            value={getCorrectAnswerOption(image)}
            onChange={imageSelectChangeHandler}
          />
        </ImageItem>
      ))}
    </ImagesList>
  );
};
