export const Navigation = {
  Main: {
    Schedule: "Розклад",
    Progress: "Мій прогрес",
    Study: "Навчання",
    Materials: "Матеріали",
    Finance: "Фінанси",
    Support: "Підтримка",
  },
  Profile: {
    Settings: "Налаштування профілю",
    LandingDashboard: "Налаштування лендінгу",
    Logout: "Вийти",
  },
};
