import { useCallback } from "react";

type TUseBodyScrollBlockOutput = {
  isScrollBlocked: () => boolean;
  blockScroll: () => void;
  allowScroll: () => void;
};

/**
 * This React hook is used to block the scrolling of the page.
 * A good example of a use case is when you need to open a modal.
 * @remark Important! This hook is anti pattern, because it depends on state of class list of the body element.
 * Hook can be called from different places and manipulate the body class state which can lead to unexpected behavior.
 * Use carefully!
 * @returns set of methods to manipulate with scroll state.
 */
export const useBodyScrollBlock = (): TUseBodyScrollBlockOutput => {
  const isScrollBlocked = () => {
    if (typeof document === "undefined") {
      return false;
    }

    return document.body.classList.contains(
      "nb-layout-app-body-scrollbar--blocked"
    );
  };

  const blockScroll = useCallback(() => {
    if (typeof document === "undefined") {
      return;
    }

    const html = document.documentElement;
    const { body } = document;
    const isBlocked = isScrollBlocked();

    if (!body || !body.style || isBlocked) {
      return;
    }

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    body.classList.add("nb-layout-app-body-scrollbar--blocked");
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
  }, []);

  const allowScroll = useCallback(() => {
    if (typeof document === "undefined") {
      return;
    }

    const { body } = document;

    const isBlocked = isScrollBlocked();

    if (!body || !body.style || !isBlocked) {
      return;
    }

    body.classList.remove("nb-layout-app-body-scrollbar--blocked");
    body.style.paddingRight = "";
  }, []);

  return {
    isScrollBlocked: isScrollBlocked,
    blockScroll,
    allowScroll,
  };
};
