import { forwardRef } from "react";
import cx from "classnames";
import "./Text.styles.scss";

type TextProps = {
  /**
   * The component maps the variant prop to a range of different HTML text types.
   *
   * @default 'body1'
   */
  variant?: "body1" | "body2" | "caption" | "label";
  /**
   * The component maps the font variant prop to a range of different font families.
   *
   * @default 'circe'
   */
  font?: "circe" | "ptmono";
  /**
   * If `true`, the text will be bold font weight.
   *
   * ⚠️ Applies only to 'body1' and 'body2' variants, otherwise will be ignored
   *
   * @default false
   */
  bold?: boolean;
  /**
   * If `true`, the text will have underline.
   *
   * ⚠️ Applies only to 'label' variant, otherwise will be ignored
   *
   * @default false
   */
  underline?: boolean;
  /**
   * If `true`, the text will have a line break for `\n` (new line) characters.
   * @default false
   */
  lineBreak?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

const Text: React.ForwardRefRenderFunction<HTMLSpanElement, TextProps> = (
  props,
  ref
) => {
  const {
    variant = "body1",
    font = "circe",
    children,
    bold = false,
    lineBreak = false,
    className,
    underline,
  } = props;

  return (
    <span
      ref={ref}
      className={cx([
        {
          "nb-typography-text-body-1": variant === "body1",
          "nb-typography-text-body-1--ptmono":
            variant === "body1" && font === "ptmono",
          "nb-typography-text-body-1--bold": variant === "body1" && bold,
          "nb-typography-text-body-2": variant === "body2",
          "nb-typography-text-body-2--ptmono":
            variant === "body2" && font === "ptmono",
          "nb-typography-text-body-2--bold": variant === "body2" && bold,
          "nb-typography-text-caption": variant === "caption",
          "nb-typography-text-caption--ptmono":
            variant === "caption" && font === "ptmono",
          "nb-typography-text-caption--line":
            variant === "caption" && underline,
          "nb-typography-text-label": variant === "label",
          "nb-typography-text-label--ptmono":
            variant === "label" && font === "ptmono",
          "nb-typography-text-line-break": lineBreak,
        },
        className,
      ])}
    >
      {children}
    </span>
  );
};

/**
 * Text typography component to display inline text based on passed variant
 */
export const ForwardedText = forwardRef(Text);
