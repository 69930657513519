import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const testExercise: TExerciseItem = {
  title: "Варіант відповіді",
  backgroundText: "Варіант",
  tags: [
    "Варіант відповіді",
    "оберіть",
    "обрати",
    "варіант",
    "відповіді",
    "відповідь",
    "вірний",
    "тест",
    "choose",
    "correct",
    "right",
    "answer",
    "from",
    "options",
    "test",
  ],
  type: EExerciseType.TEST,
  cover: "choose-answer-from-options.png",
};

export const TestExerciseDuration = {
  THIRTY: 30,
  FORTY_FIVE: 45,
  SIXTY: 60,
  NINETY: 90,
};
