import { COMPARE_IMAGE_WITH_DESCRIPTION_MAX_ANSWER_ATTEMPTS } from "../CompareImageWithDescription.helpers";
import {
  TCompareImageWithDescriptionExerciseParsedAnswer,
  TCompareImageWithDescriptionExerciseParsedImage,
  TExerciseIdentifier,
} from "types/app/exercises";

export const START_ZONE_ID = "COMPARE-IMAGE-WITH-DESCRIPTION-START-ZONE";

/**
 * Helper function that allows to update answer information on answer enter to the image blank.
 * @param answer target answer data that should be updated.
 * @returns updated answer data.
 */
const updateAnswerOnBlankEnter = (
  answer: TCompareImageWithDescriptionExerciseParsedAnswer
): TCompareImageWithDescriptionExerciseParsedAnswer => ({
  ...answer,
  isSelected: true,
  isTouched: true,
});

/**
 * Helper function that allows to update answer information on answer leave from the image blank.
 * @param answer target answer data that should be updated.
 * @returns updated answer data.
 */
const updateAnswerOnBlankLeave = (
  answer: TCompareImageWithDescriptionExerciseParsedAnswer
): TCompareImageWithDescriptionExerciseParsedAnswer => ({
  ...answer,
  isSelected: false,
});

/**
 * Helper function that allows to update image information on answer enter.
 * @param image target image data that should be updated.
 * @param answer target answer data that should be added to the image.
 * @returns updated image data.
 */
const updateImageBlankOnAnswerEnter = (
  image: TCompareImageWithDescriptionExerciseParsedImage,
  answer: TCompareImageWithDescriptionExerciseParsedAnswer
): TCompareImageWithDescriptionExerciseParsedImage => ({
  ...image,
  attempts: [...image.attempts, answer],
  currentAnswer: answer,
  currentAttempt: image.currentAttempt + 1,
  isCorrectAnswer: image.correctAnswers.some(
    (correctAnswer) => correctAnswer.id === answer.id
  ),
  isEmpty: false,
  isTouched: true,
  isAllAttemptsFailed:
    image.correctAnswers.some(
      (correctAnswer) => correctAnswer.id === answer.id
    ) &&
    image.currentAttempt + 1 >=
      COMPARE_IMAGE_WITH_DESCRIPTION_MAX_ANSWER_ATTEMPTS,
});

/**
 * Helper function that allows to update image information on answer leave.
 * @param image target image data that should be updated.
 * @returns updated image data.
 */
const updateImageBlankOnAnswerLeave = (
  image: TCompareImageWithDescriptionExerciseParsedImage
): TCompareImageWithDescriptionExerciseParsedImage => ({
  ...image,
  currentAnswer: null,
  isCorrectAnswer: false,
  isEmpty: true,
});

/**
 * Helper function that allows to updating an array of start zone answers by adding new item.
 * @param answers an array of answers that are located in the start zone area.
 * @param targetAnswer target answer data that should be added to the existing start zone answers array.
 * @returns updated array of answers with new answer.
 */
export const addAnswerToStartZone = (
  answers: TCompareImageWithDescriptionExerciseParsedAnswer[],
  targetAnswer: TCompareImageWithDescriptionExerciseParsedAnswer
): TCompareImageWithDescriptionExerciseParsedAnswer[] =>
  answers.map((answer) =>
    answer.id === targetAnswer.id ? updateAnswerOnBlankLeave(answer) : answer
  );

/**
 * Helper function that allows to updating an array of start zone answers by removing requested item.
 * @param answers an array of answers that are located in the start zone area.
 * @param targetAnswerId identifier of the target answer that should be removed from start zone.
 * @returns updated array of answers without targeted answer.
 */
export const removeAnswerFromStartZone = (
  answers: TCompareImageWithDescriptionExerciseParsedAnswer[],
  targetAnswerId: TExerciseIdentifier
): TCompareImageWithDescriptionExerciseParsedAnswer[] =>
  answers.map((answer) =>
    answer.id === targetAnswerId ? updateAnswerOnBlankEnter(answer) : answer
  );

/**
 * Helper function that allows updating an array of start zone answers by replacing an answer.
 * @param answers an array of answers that are located in the start zone area.
 * @param draggedAnswerId identifier of the dragged answer that should be replaced.
 * @param replaceAnswer replace answer data that should take place of the dragged answer.
 * @returns updated array of answers with replaced answer.
 */
export const replaceAnswerInStartZone = (
  answers: TCompareImageWithDescriptionExerciseParsedAnswer[],
  draggedAnswer: TCompareImageWithDescriptionExerciseParsedAnswer,
  replaceAnswer: TCompareImageWithDescriptionExerciseParsedAnswer
) =>
  answers.map((answer) =>
    answer.id === draggedAnswer.id
      ? updateAnswerOnBlankLeave(replaceAnswer)
      : answer.id === replaceAnswer.id
      ? updateAnswerOnBlankEnter(draggedAnswer)
      : answer
  );

/**
 * Helper function that allows updating an array of exercise images by adding an answer as blank.
 * @param images an array of all images that exist in an exercise.
 * @param targetImageId identifier of the targeted image blank that should be updated.
 * @param newAnswer new answer data that should be added to the targeted image.
 * @returns updated array of images with new answer in targeted blank.
 */
export const addAnswerToImageBlank = (
  images: TCompareImageWithDescriptionExerciseParsedImage[],
  targetImageId: TExerciseIdentifier,
  newAnswer: TCompareImageWithDescriptionExerciseParsedAnswer
): TCompareImageWithDescriptionExerciseParsedImage[] =>
  images.map((image) =>
    image.id === targetImageId
      ? updateImageBlankOnAnswerEnter(image, newAnswer)
      : image
  );

/**
 * Helper function that allows updating an array of exercise image blanks by switching answer from source to destination one.
 * @param images an array of all images that exist in an exercise.
 * @param targetAnswer target answer data that should be switched from source blank to destination blank.
 * @param destinationImageId identifier of the destination image blank that should be updated.
 * @param sourceImageId identifier of the source image blank that should be updated.
 * @returns updated array of images with switched target answer from source to destination blank.
 */
export const switchAnswerInImageBlanks = (
  images: TCompareImageWithDescriptionExerciseParsedImage[],
  targetAnswer: TCompareImageWithDescriptionExerciseParsedAnswer,
  destinationImageId: TExerciseIdentifier,
  sourceImageId: TExerciseIdentifier
): TCompareImageWithDescriptionExerciseParsedImage[] =>
  images.map((image) =>
    image.id === sourceImageId
      ? updateImageBlankOnAnswerLeave(image)
      : image.id === destinationImageId
      ? updateImageBlankOnAnswerEnter(image, targetAnswer)
      : image
  );

/**
 * Helper function that allows updating an array of exercise image blanks by replacing answers from source to destination.
 * Which means that answer in source blank will take place in destination blank and answer in destination blank will take place in source one.
 * @param images an array of all images that exist in an exercise.
 * @param sourceImage source image blank data with a current answer which one should be moved to the destination blank.
 * @param destinationImage destination image blank data with a current answer which one should be moved to the source blank.
 * @returns updated array of images with replaced answers from source to destination and otherwise.
 */
export const replaceAnswersInImageBlanks = (
  images: TCompareImageWithDescriptionExerciseParsedImage[],
  sourceImage: TCompareImageWithDescriptionExerciseParsedImage,
  destinationImage: TCompareImageWithDescriptionExerciseParsedImage
): TCompareImageWithDescriptionExerciseParsedImage[] =>
  images.map((image) =>
    image.id === destinationImage.id && sourceImage.currentAnswer
      ? updateImageBlankOnAnswerEnter(image, sourceImage.currentAnswer)
      : image.id === sourceImage.id && destinationImage.currentAnswer
      ? updateImageBlankOnAnswerEnter(image, destinationImage.currentAnswer)
      : image
  );

/**
 * Helper function that allows updating an array of exercise images by removing an answer from image blank.
 * @param images an array of all images that exist in an exercise.
 * @param targetImageId identifier of the targeted image blank answer that should be removed.
 * @returns updated array of images without targeted image blank answer.
 */
export const removeAnswerFromImageBlank = (
  images: TCompareImageWithDescriptionExerciseParsedImage[],
  targetImageId: TExerciseIdentifier
): TCompareImageWithDescriptionExerciseParsedImage[] =>
  images.map((image) =>
    image.id === targetImageId ? updateImageBlankOnAnswerLeave(image) : image
  );
