import { Area } from "react-easy-crop";
import { convertDegreesToRadians } from "utils/common";
import { createImageElement } from "utils/html";

type TGenerateCroppedImageOptions = {
  /**
   * Crop area parameters.
   * @default null
   */
  crop?: Area | null;
  /**
   * A string indicating the image output format.
   * The default type is image/png; that type is also used if the given type isn't supported.
   * @default "image/webp"
   */
  outputImageType?: string;
  /**
   * Rotation angel in degree.
   * @default 0
   */
  rotation?: number;
};

/**
 * Function generates image with specified cropped area and rotation angel.
 * This function uses canvas API for in-memory render to make desired image manipulations such as crop or(and) rotation.
 * @param imageDataUrl - image base64 URL data.
 * @param options - image options.
 * @returns cropped image blob binary data.
 */
export const generateCroppedImage = async (
  imageDataUrl: string,
  options?: TGenerateCroppedImageOptions
): Promise<Blob> => {
  const {
    crop = null,
    outputImageType = "image/webp",
    rotation = 0,
  } = options || {};

  if (!crop) {
    throw new Error("Crop area was not provided");
  }

  const image = await createImageElement(imageDataUrl);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // Set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // Translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(convertDegreesToRadians(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // Draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // Set canvas width to final desired crop size - this will clear existing context.
  canvas.width = crop.width;
  canvas.height = crop.height;

  // Paste generated rotate image with correct offsets for x, y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - crop.x,
    0 - safeArea / 2 + image.height * 0.5 - crop.y
  );

  return new Promise((resolve, reject) =>
    canvas.toBlob((blob) => {
      if (!blob) {
        return reject("Failed to convert cropped file into binary blob data");
      }
      return resolve(blob);
    }, outputImageType)
  );
};
