import { combineReducers } from "@reduxjs/toolkit";
import { EAppReducers } from "../white-list";
import { userReducer } from "./user";
import { scheduleReducer } from "./schedule";
import { breadcrumbsReducer } from "./breadcrumbs";
import { exerciseConstructorReducer } from "./constructor";
import { imageUploadReducer } from "./image-upload";
import { lessonReducer } from "./lesson";

export const appReducer = combineReducers({
  [EAppReducers.USER]: userReducer,
  [EAppReducers.SCHEDULE]: scheduleReducer,
  [EAppReducers.BREADCRUMBS]: breadcrumbsReducer,
  [EAppReducers.EXERCISE_CONSTRUCTOR]: exerciseConstructorReducer,
  [EAppReducers.IMAGE_UPLOAD]: imageUploadReducer,
  [EAppReducers.LESSON]: lessonReducer,
});
