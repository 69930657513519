import cx from "classnames";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { useLessonSettings } from "hooks/redux";
import { IconButton } from "components/Interactive/IconButton";
import { ChapterNameText } from "../ChapterNameText";
import type { TCourseLessonChapter } from "types/api/course";
import "./ChaptersSidebarItem.styles.scss";

type ChapterSidebarItemProps = {
  /**
   * Lesson chapter data to display.
   */
  chapter: TCourseLessonChapter;
  /**
   * Indicates active state of chapter list item.
   * @default false
   */
  active?: boolean;
  /**
   * Callback function fires on chapter item click to set active state.
   * @param chapterId current active chapter ID.
   * @returns {void} nothing.
   */
  onChapterClick?: (chapterId: number) => void;
  /**
   * Callback function fires on chapter item edit button click.
   * @param chapter current active chapter data.
   * @returns {void} nothing.
   */
  onChapterEditClick?: (chapter: TCourseLessonChapter) => void;
  /**
   * Callback function fires on chapter item delete button click.
   * @param chapterId current active chapter data.
   * @returns {void} nothing.
   */
  onChapterDeleteClick?: (chapter: TCourseLessonChapter) => void;
};

export const ChapterSidebarItem: React.FC<ChapterSidebarItemProps> = (
  props
) => {
  const {
    chapter,
    active = false,
    onChapterClick,
    onChapterEditClick,
    onChapterDeleteClick,
  } = props;

  const { viewMode } = useLessonSettings();

  const chapterButtonClickHandler = () => {
    onChapterClick?.(chapter.id);
  };

  const chapterEditButtonClickHandler = () => {
    onChapterEditClick?.(chapter);
  };

  const chapterDeleteButtonClickHandler = () => {
    onChapterDeleteClick?.(chapter);
  };

  return (
    <li className="nb-lesson-chapters-sidebar-item">
      <button
        type="button"
        className={cx([
          "nb-lesson-chapters-sidebar-item-button",
          {
            ["nb-lesson-chapters-sidebar-item-button--active"]: active,
          },
        ])}
        onClick={chapterButtonClickHandler}
      >
        <ChapterNameText text={chapter.name} />
      </button>
      {viewMode === "constructor" && (
        <IconButton
          icon={<Edit />}
          variant="tertiary"
          onClick={chapterEditButtonClickHandler}
        />
      )}
      {viewMode === "constructor" && (
        <IconButton
          icon={<Delete />}
          variant="tertiary"
          onClick={chapterDeleteButtonClickHandler}
        />
      )}
    </li>
  );
};
