import { TUrlParam } from "types/utils";

export enum EExercisesQueryKeys {
  getExercises = "getExercises",
  getHomeworkExercises = "getHomeworkExercises",
}

export const EXERCISES_ENDPOINTS = {
  CHAPTER_EXERCISES: (
    courseId: TUrlParam,
    lessonId: TUrlParam,
    chapterId: TUrlParam
  ): string =>
    `/materials/courses/${courseId}/lessons/${lessonId}/chapters/${chapterId}/exercises`,
  HOMEWORK_EXERCISES: (
    courseId: TUrlParam,
    lessonId: TUrlParam,
    homeworkId: TUrlParam
  ): string =>
    `/materials/courses/${courseId}/lessons/${lessonId}/homework/${homeworkId}/exercises`,
};
