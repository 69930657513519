/**
 * Helper function to get the target element for the portal.
 * If the target element does not exist, it will be created and appended to the body.
 * If the target element exists, it will be returned.
 * @param targetId - The id of the target element
 * @returns The target element.
 */
export const getPortalTarget = (targetId: string): HTMLElement | null => {
  const createWrapperAndAppendToBody = (id: string): HTMLDivElement => {
    const wrapperElement = document.createElement("div");
    wrapperElement.setAttribute("id", id);
    wrapperElement.classList.add("nb-interactive-select-portal");
    document.body.appendChild(wrapperElement);
    return wrapperElement;
  };

  let mountNode: HTMLElement | null = null;
  const element = document.getElementById(targetId);
  if (element) {
    mountNode = element;
  } else {
    mountNode = createWrapperAndAppendToBody(targetId);
  }

  return mountNode;
};
