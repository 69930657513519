import cx from "classnames";
import { VideoPlayer } from "components/Interactive/VideoPlayer";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { Space } from "components/Common/Space";
import { Paragraph } from "components/Typography/Paragraph";
import { useState } from "react";
import {
  TDescribeVideoExercise,
  TDescribeVideoExercisePayload,
} from "types/app/exercises";
import "./DescribeVideoExercise.styles.scss";

type TDescribeVideoExerciseProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TDescribeVideoExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const DescribeVideoExercise: React.FC<TDescribeVideoExerciseProps> = (
  props
) => {
  const { chapterId, exercise, prefix = null } = props;

  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TDescribeVideoExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
        isCollapsed={isCollapsed}
        onCollapseToggle={() => setIsCollapsed((prev) => !prev)}
      />
      <div
        className={cx([
          "nb-exercise-container",
          {
            "nb-exercise-container--collapsed": isCollapsed,
          },
        ])}
      >
        <VideoPlayer
          url={exercise.payload.data.videoUrl}
          controls
          light
          lazy
          rounded
          volume={0.2}
          width="100%"
          height="100%"
        />
        <Paragraph variant={2}>{exercise.payload.data.description}</Paragraph>
      </div>
    </Space>
  );
};
