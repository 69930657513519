export const Students = {
  Name: "Студенти",
  Emoji: "🎓",
  Search: {
    PLACEHOLDER: "Пошук студентів",
    NO_OPTIONS: "Студентів не знайдено",
  },
  Table: {
    Header: {
      NUMBER: "№",
      AVATAR: "Фото",
      NAME: "Ім'я",
      EMAIL: "Пошта",
      PHONE: "Телефон",
      LEVEL: "Рівень",
      STATUS: "Статус",
      ACTION: "Дії",
    },
    Button: {
      EDIT: "Змінити",
      CLASSROOM: "В клас",
    },
  },
  Empty:
    "Список студентів порожній, натисніть додати, щоб почати плідну роботу 💪",
  Delete: {
    TITLE: "Ви дійсно хочете видалити студента?",
    EMOJI: "😥",
    Notification: {
      SUCCESS: "Студента успішно видалено",
      ERROR: "Не вдалося видалити студента",
    },
  },
  Add: {
    TITLE: "Додати студента",
    EMOJI: "📚",
    SUBMIT: "Додати",
    Notification: {
      SUCCESS: "Студент успішно доданий",
      ERROR: "Не вдалось додати студента",
    },
    Input: {
      FirstName: {
        LABEL: "Ім'я",
        PLACEHOLDER: "Леся",
      },
      LastName: {
        LABEL: "Прізвище",
        PLACEHOLDER: "Українка",
      },
      Email: {
        LABEL: "Email",
        PLACEHOLDER: "lesya@mail.com",
      },
      Phone: {
        LABEL: "Телефон",
        PLACEHOLDER: "+380 (099) 111-22-33",
      },
      Language: {
        LABEL: "Мова",
        PLACEHOLDER: "Оберіть мову",
      },
      Level: {
        LABEL: "Рівень",
        PLACEHOLDER: "Оберіть рівень",
      },
      Teacher: {
        LABEL: "Викладач",
        PLACEHOLDER: "Оберіть викладача",
      },
      Price: {
        LABEL: "Ціна за урок",
        PLACEHOLDER: "Введіть ціну",
      },
    },
  },
  Edit: {
    TITLE: "Особистий профіль",
    EMOJI: "😊",
    SUBMIT: "Зберегти",
    Notification: {
      SUCCESS: "Студент успішно змінений",
      ERROR: "Не вдалось змінити дані студента",
    },
    Input: {
      FirstName: {
        LABEL: "Ім'я",
        PLACEHOLDER: "Леся",
      },
      LastName: {
        LABEL: "Прізвище",
        PLACEHOLDER: "Українка",
      },
      Email: {
        LABEL: "Email",
        PLACEHOLDER: "lesya@mail.com",
      },
      Phone: {
        LABEL: "Телефон",
        PLACEHOLDER: "+380 (099) 111-22-33",
      },
      Language: {
        LABEL: "Мова",
        PLACEHOLDER: "Оберіть мову",
      },
      Level: {
        LABEL: "Рівень",
        PLACEHOLDER: "Оберіть рівень",
      },
      Teacher: {
        LABEL: "Викладач",
        PLACEHOLDER: "Оберіть викладача",
      },
      Price: {
        LABEL: "Ціна за урок",
        PLACEHOLDER: "Введіть ціну",
      },
    },
  },
  Schedule: {
    TITLE: "Профіль",
    EMOJI: "👩🏻‍🦳️",
  },
};
