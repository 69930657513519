import { useForm } from "react-hook-form";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { TDescribeAudioExerciseForm } from "./DescribeAudioExerciseForm.types";
import "./DescribeAudioExerciseForm.styles.scss";

export const DescribeAudioExerciseForm: React.FC = () => {
  const { handleSubmit } = useForm<TDescribeAudioExerciseForm>({
    mode: "onBlur",
    defaultValues: {},
  });

  const addExerciseSubmitHandler = (formData: TDescribeAudioExerciseForm) => {
    // eslint-disable-next-line no-console
    console.log("add DescribeAudioExerciseForm handler", formData);
  };

  return (
    <ExerciseConfigForm onSubmit={handleSubmit(addExerciseSubmitHandler)}>
      DescribeAudioExerciseForm
    </ExerciseConfigForm>
  );
};
