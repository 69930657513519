import { useState } from "react";
import cx from "classnames";
import "./Image.styles.scss";

type TImageProps = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  "className" | "src"
> & {
  /**
   * The image URL source.
   */
  src: string;
  /**
   * Indicates that the image is rounded.
   * @default false
   */
  rounded?: boolean;
  /**
   * Indicates that the image is full width.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Indicates that the image is centered.
   * @default false
   */
  centered?: boolean;
  /**
   * The image caption.
   * @default null
   */
  caption?: string | null;
  /**
   * Override or extend the styles applied to the component wrapper.
   */
  wrapperClassName?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const Image: React.FC<TImageProps> = (props) => {
  const {
    src,
    rounded = false,
    fullWidth = false,
    centered = false,
    caption = null,
    className,
    wrapperClassName,
    onLoad,
    ...restImageProps
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const imageLoadHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setIsLoading(false);
    onLoad?.(event);
  };

  return (
    <figure
      className={cx([
        "nb-common-image-container",
        {
          "nb-common-image-container--skeleton": isLoading,
          "nb-common-image-container--rounded": rounded,
          "nb-common-image-container--full": fullWidth,
          "nb-common-image-container--centered": centered,
        },
        wrapperClassName,
      ])}
    >
      <img
        {...restImageProps}
        src={src}
        onLoad={imageLoadHandler}
        className={cx([
          "nb-common-image",
          {
            "nb-common-image--loading": isLoading,
            "nb-common-image--rounded": rounded,
          },
          className,
        ])}
      />
      {caption !== null && (
        <figcaption className="nb-common-image-caption">{caption}</figcaption>
      )}
    </figure>
  );
};
