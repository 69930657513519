import { MenuProps, components } from "react-select";
import { TSelectDefaultOption } from "../../Select.types";
import "./Menu.styles.scss";

export const Menu = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>(
  props: MenuProps<Option, false>
): JSX.Element => {
  return (
    <components.Menu {...props} className="nb-interactive-select-menu">
      {props.children}
    </components.Menu>
  );
};
