import { useCallback, useMemo, useState } from "react";
import { useModal } from "hooks/common/useModal";
import { useGroups, useRemoveGroup } from "hooks/api/groups";
import { useGroupColumns } from "hooks/table/useGroupColumns";
import { Divider } from "components/Common/Divider";
import { Empty } from "components/Common/Empty";
import { Table } from "components/Table";
import { ActionModal } from "components/Common/ActionModal";
import { AddGroupModal } from "./components/AddGroupModal";
import { StudySearch } from "../../components/StudySearch";
import { GroupsContent } from "content";
import { logError, logSuccess } from "utils/notifications";
import { TGroup } from "types/api/groups";
import { TSelectOption } from "types/app/select";
import { EStudySearchTarget } from "types/app/study";
import "./Groups.styles.scss";

export const Groups: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { data, isLoading } = useGroups({
    searchText: searchValue,
    pageNumber,
  });
  const { mutate } = useRemoveGroup();

  const groups: TGroup[] = useMemo(() => data?.content || [], [data?.content]);
  const isEmpty: boolean = groups.length === 0;

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);
  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const handleDeleteButtonClick = (id: number) => {
    setSelectedGroupId(id);
    openDeleteModal();
  };

  const handleDeleteSubmit = () => {
    if (selectedGroupId === null) {
      logError({ message: GroupsContent.Delete.Notification.ERROR });
      return;
    }

    mutate(selectedGroupId, {
      onSuccess: () => {
        logSuccess(GroupsContent.Delete.Notification.SUCCESS);
        setPageNumber(0);
        closeDeleteModal();
      },
    });
  };

  const searchCallback = useCallback(async (): Promise<
    TSelectOption<number>[]
  > => {
    return groups.map((group) => ({
      label: group.name,
      value: group.id,
    }));
  }, [groups]);

  const columns = useGroupColumns({ onDeleteClick: handleDeleteButtonClick });

  return (
    <>
      <StudySearch
        searchTarget={EStudySearchTarget.Groups}
        searchPlaceholder={GroupsContent.Search.PLACEHOLDER}
        searchCallback={searchCallback}
        setSearchValue={setSearchValue}
        onAddButtonClick={openAddModal}
      />
      <Divider margin="bottom" />
      {isEmpty && !isLoading ? (
        <Empty text={GroupsContent.Empty} />
      ) : (
        <Table<TGroup>
          data={groups}
          columns={columns}
          loading={isLoading}
          totalItems={data?.totalElements}
          onPageChange={setPageNumber}
        />
      )}
      <ActionModal
        text={GroupsContent.Delete.TITLE}
        emoji={GroupsContent.Delete.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteSubmit}
      />
      <AddGroupModal open={isAddModalOpen} onClose={closeAddModal} />
    </>
  );
};
