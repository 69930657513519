import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useAddCourseLesson } from "hooks/api/courses";
import { CourseDetailsContent } from "content";
import { AvatarUpload } from "components/Interactive/AvatarUpload";
import { Button } from "components/Interactive/Button";
import { Input } from "components/Interactive/Input";
import { Textarea } from "components/Interactive/Textarea";
import { ReactPortal } from "components/Common/ReactPortal";
import { logError, logSuccess } from "utils/notifications";
import { convertBlobToBase64String } from "utils/common";
import { TAddCourseLessonBody } from "types/api/course";
import { TAddCourseLessonForm } from "./AddCourseLessonForm.types";
import "./AddCourseLessonForm.styles.scss";

type AddCourseLessonFormProps = {
  /**
   * Callback function fires on add course submit event.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const AddCourseLessonForm: React.FC<AddCourseLessonFormProps> = (
  props
) => {
  const { className, onSubmit } = props;

  const { courseId } = useParams();

  const [selectedFile, setSelectedFile] = useState<Blob | null>(null);
  const idRef = useRef<string>(nanoid());

  const { mutate: addCourseLessonHandler } = useAddCourseLesson(courseId);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddCourseLessonForm>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const handleAddCourseSubmit = async (formValues: TAddCourseLessonForm) => {
    if (!courseId) {
      logError({ message: CourseDetailsContent.Add.Notification.ERROR });
      return;
    }

    const imageEncoded =
      selectedFile &&
      (await convertBlobToBase64String(selectedFile, { removePrefix: true }));

    const courseLesson: TAddCourseLessonBody = {
      name: formValues.name,
      description: formValues.description || null,
      imageEncoded,
    };

    addCourseLessonHandler(courseLesson, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(CourseDetailsContent.Add.Notification.SUCCESS);
        setSelectedFile(null);
        reset({
          name: "",
          description: "",
        });
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-add-course-lesson-form", className])}
      onSubmit={handleSubmit(handleAddCourseSubmit)}
    >
      <AvatarUpload
        bordered
        resetOnClose
        size="large"
        cropShape="round"
        mode="save"
        className="nb-sub-add-course-lesson-avatar"
        onSave={setSelectedFile}
      />
      <Input<TAddCourseLessonForm>
        name="name"
        id="add-course-lesson-name"
        className="nb-sub-add-course-lesson-name-input"
        label={CourseDetailsContent.Add.Input.Name.LABEL}
        placeholder={CourseDetailsContent.Add.Input.Name.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Textarea<TAddCourseLessonForm>
        name="description"
        id="add-course-lesson-description"
        className="nb-sub-add-course-lesson-description-input"
        label={CourseDetailsContent.Add.Input.Description.LABEL}
        placeholder={CourseDetailsContent.Add.Input.Description.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        fullWidth
      />
      <ReactPortal wrapperId="add-course-lesson-modal-footer">
        <Button
          className="nb-sub-add-course-lesson-submit"
          variant="primary"
          type="submit"
          form={idRef.current}
          fullWidth
        >
          {CourseDetailsContent.Add.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
