import "./DraggableItemPlaceholder.styles.scss";

type TDraggableItemPlaceholderProps = {
  /**
   * Inner text content of the component.
   */
  content?: string;
};

export const DraggableItemPlaceholder: React.FC<
  TDraggableItemPlaceholderProps
> = (props) => {
  const { content = "" } = props;

  return (
    <div className="nb-exercise-move-words-draggable-item-placeholder">
      {content}
    </div>
  );
};
