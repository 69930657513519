import { nanoid } from "nanoid";
import {
  TExerciseSentenceAnswerWithCorrectness,
  TTestExerciseQuestionItem,
} from "types/app/exercises";

export const getTestQuestionAnswerInitialValue =
  (): TExerciseSentenceAnswerWithCorrectness => ({
    id: nanoid(),
    content: "",
    isCorrect: false,
  });

export const getTestQuestionInitialValue = (): TTestExerciseQuestionItem => ({
  id: nanoid(),
  question: "",
  answers: [getTestQuestionAnswerInitialValue()],
});
