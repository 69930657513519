import cx from "classnames";
import { Space } from "components/Common/Space";
import "./WordList.styles.scss";

type TWordListProps = {
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the container.
   */
  style?: React.CSSProperties;
  /**
   * The children to render into the `container`.
   */
  children: React.ReactNode;
};

export const WordList: React.FC<TWordListProps> = (props) => {
  const { children, className, style } = props;

  return (
    <Space
      direction="vertical"
      justify="start"
      align="center"
      size="x-small"
      className={cx(["nb-exercise-words-comparing-words-list", className])}
      style={style}
    >
      {children}
    </Space>
  );
};
