import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ExerciseConstructorContent, SortWordsExerciseContent } from "content";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { Textarea } from "components/Interactive/Textarea";
import { ColumnInputs } from "components/Interactive/ColumnInputs";
import { Space } from "components/Common/Space";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import {
  adaptExerciseColumnsToData,
  getSortWordsExerciseFormInitialValues,
} from "./SortWordsExerciseForm.helpers";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import { EExerciseType, TSortWordsExercisePayload } from "types/app/exercises";
import { TSortWordsExerciseForm } from "./SortWordsExerciseForm.types";
import "./SortWordsExerciseForm.styles.scss";

export const SortWordsExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TSortWordsExercisePayload>();

  const { mutate: addExercise } = useAddExercise<TSortWordsExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TSortWordsExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TSortWordsExercisePayload>(courseId, lessonId, chapterId);
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TSortWordsExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TSortWordsExerciseForm> = useMemo(
    () => getSortWordsExerciseFormInitialValues(editExercise),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<TSortWordsExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TSortWordsExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TSortWordsExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TSortWordsExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TSortWordsExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TSortWordsExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const adaptedData = adaptExerciseColumnsToData(formData.columns);

    const addBody: TAddExercise<TSortWordsExercisePayload> = {
      type: EExerciseType.SORT_WORDS,
      payload: {
        text: formData.text,
        data: {
          rawColumns: formData.columns,
          ...adaptedData,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TSortWordsExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TSortWordsExerciseForm>
          name="text"
          id="sort-words-exercise-condition"
          className="nb-exercise-constructor-sort-words-condition"
          label={SortWordsExerciseContent.Add.Input.Condition.LABEL}
          placeholder={SortWordsExerciseContent.Add.Input.Condition.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <ColumnInputs<TSortWordsExerciseForm>
          name="columns"
          control={control}
          register={register}
          errors={errors}
          minColumns={2}
          columnNameInputLabel={
            SortWordsExerciseContent.Add.Input.Columns.Name.LABEL
          }
          columnNameInputPlaceholder={
            SortWordsExerciseContent.Add.Input.Columns.Name.PLACEHOLDER
          }
          columnValueInputLabel={
            SortWordsExerciseContent.Add.Input.Columns.Value.LABEL
          }
          columnValueInputPlaceholder={
            SortWordsExerciseContent.Add.Input.Columns.Value.PLACEHOLDER
          }
        />
      </Space>
    </ExerciseConfigForm>
  );
};
