import { cloneElement } from "react";
import cx from "classnames";
import { Space } from "components/Common/Space";
import { Paragraph } from "components/Typography/Paragraph";
import { Text } from "components/Typography/Text";
import "./Note.styles.scss";

type TNoteProps = {
  /**
   * The note title.
   */
  title?: string;
  /**
   * Element placed left to the note title.
   */
  icon?: React.ReactElement;
  /**
   * The note content.
   */
  content: string;
  /**
   * Option to fit width to its parent's width.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const Note: React.FC<TNoteProps> = (props) => {
  const { title, icon, content, className, fullWidth = false } = props;

  return (
    <Space
      direction="vertical"
      size="x-small"
      justify="start"
      fullWidth={fullWidth}
      className={cx(["nb-common-note", className])}
    >
      {title && (
        <Space
          direction="horizontal"
          align="center"
          justify="start"
          size="x-small"
        >
          {icon &&
            cloneElement(icon, {
              className: "nb-common-note-icon",
            })}
          <Text variant="body1" bold className="nb-common-note-title">
            {title}
          </Text>
        </Space>
      )}
      <Paragraph variant={2} className="nb-common-note-content">
        {content}
      </Paragraph>
    </Space>
  );
};
