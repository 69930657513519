import cx from "classnames";
import { LessonDetailsContent } from "content";
import { Text } from "components/Typography/Text";
import "./HomeworkSidebar.styles.scss";

type HomeworkSidebarProps = {
  /**
   * Indicates active state of homework sidebar button.
   * @default false
   */
  active?: boolean;
  /**
   * Callback function fires on homework button click.
   * @returns {void} nothing.
   */
  onButtonClick?: () => void;
};

export const HomeworkSidebar: React.FC<HomeworkSidebarProps> = (props) => {
  const { active = false, onButtonClick } = props;

  return (
    <section className="nb-lesson-details-homework-sidebar">
      <button
        type="button"
        className={cx([
          "nb-lesson-details-homework-sidebar-button",
          { ["nb-lesson-details-homework-sidebar-button--active"]: active },
        ])}
        onClick={onButtonClick}
      >
        <Text>{LessonDetailsContent.Sidebar.Homework.EMOJI}</Text>
        <Text bold>{LessonDetailsContent.Sidebar.Homework.TEXT}</Text>
      </button>
    </section>
  );
};
