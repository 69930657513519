import { animated } from "@react-spring/web";
import cx from "classnames";
import { useLessonSettings } from "hooks/redux";
import { TExerciseIdentifier } from "types/app/exercises";
import "./WordButton.styles.scss";

type TWordButtonProps = {
  /**
   * Unique identifier of the word.
   */
  id: TExerciseIdentifier;
  /**
   * Text content of the word.
   */
  content: string;
  /**
   * Whether the word button should be highlighted.
   * @default false
   */
  highlight?: boolean;
  /**
   * Whether the word button is selected.
   * @default false
   */
  selected?: boolean;
  /**
   * Whether the word button have a pair.
   * @default false
   */
  paired?: boolean;
  /**
   * Whether the result should be shown.
   * @default false
   */
  showResult?: boolean;
  /**
   * Whether the word button is correct.
   * @default false
   */
  correct?: boolean;
  /**
   * Callback function to handle word button click.
   */
  onClick?: (id: TExerciseIdentifier) => void;
  /**
   * Callback function to handle word button height change.
   */
  onHeightChange?: (id: TExerciseIdentifier, height: number) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the container.
   */
  style?: React.CSSProperties;
};

export const WordButton: React.FC<TWordButtonProps> = (props) => {
  const {
    id,
    content,
    highlight = false,
    selected = false,
    paired = false,
    showResult = false,
    correct = false,
    onClick,
    onHeightChange,
    className,
    style,
  } = props;

  const { isExerciseReadOnlyMode } = useLessonSettings();

  const wordButtonClickHandler = () => {
    onClick?.(id);
  };

  return (
    <div
      className="nb-exercise-words-comparing-word-button-container"
      style={style}
    >
      <button
        ref={(ref: HTMLButtonElement) =>
          onHeightChange?.(id, ref?.offsetHeight)
        }
        type="button"
        className={cx([
          "nb-exercise-words-comparing-word-button",
          {
            "nb-exercise-words-comparing-word-button--highlight": highlight,
            "nb-exercise-words-comparing-word-button--correct":
              showResult && correct,
            "nb-exercise-words-comparing-word-button--incorrect":
              showResult && !correct,
            "nb-exercise-words-comparing-word-button--selected": selected,
            "nb-exercise-words-comparing-word-button--paired": paired,
          },
          className,
        ])}
        disabled={showResult || isExerciseReadOnlyMode}
        id={id.toString()}
        onClick={wordButtonClickHandler}
      >
        {content}
      </button>
    </div>
  );
};

export const AnimatedWordButton = animated(WordButton);
