import { get, post, put, remove } from "../api";
import { REQUESTS_ENDPOINTS } from "../endpoints/requests";
import {
  TAddRequestBody,
  TEditRequestBody,
  TRequest,
  TRequestResponse,
} from "types/api/requests";
import { TPageableRequestParams } from "types/api/server";
import { TUrlParam } from "types/utils";

export const getRequests = (
  options?: TPageableRequestParams
): Promise<TRequestResponse> => {
  const { pageNumber = 0, pageSize = 10 } = options || {};
  return get<TRequestResponse>(REQUESTS_ENDPOINTS.REQUESTS, {
    params: {
      pageNumber,
      pageSize,
    },
  });
};

export const addRequest = (requestBody: TAddRequestBody): Promise<TRequest> =>
  post<TRequest>(REQUESTS_ENDPOINTS.REQUESTS, requestBody);

export const editRequest = (requestBody: TEditRequestBody): Promise<TRequest> =>
  put<TRequest>(REQUESTS_ENDPOINTS.REQUESTS, requestBody);

export const removeRequest = (id: TUrlParam): Promise<void> =>
  remove<undefined>(`${REQUESTS_ENDPOINTS.REQUESTS}/${id}`);
