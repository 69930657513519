import { useCallback, useState } from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import ReactSelect, { NoticeProps, PlaceholderProps } from "react-select";
import cx from "classnames";
import { SelectContent, ValidationContent } from "content";
import { Space } from "components/Common/Space";
import { AssertionSelectControl } from "./components/Control";
import { AssertionSelectDropdownIndicator } from "./components/DropdownIndicator";
import { AssertionSelectInput } from "./components/Input";
import { AssertionSelectMenu } from "./components/Menu";
import { AssertionSelectNoOptionsMessage } from "./components/NoOptionsMessage";
import { AssertionSelectOption } from "./components/Option";
import { AssertionSelectPlaceholder } from "./components/Placeholder";
import { AssertionSelectSingleValue } from "./components/SingleValue";
import { AssertionSelectValueContainer } from "./components/Value";
import { TRUE_OR_FALSE_EXERCISE_OPTIONS } from "utils/options/exercise";
import { TSelectOption } from "types/app/select";
import {
  TAssertionSelectDefaultOption,
  TAssertionSentencesDefaultValue,
} from "../AssertionSentences.types";
import "./TrueOrFalseAssertion.styles.scss";

type TTrueOrFalseAssertionProps<
  TFormValues extends TAssertionSentencesDefaultValue = TAssertionSentencesDefaultValue
> = {
  /**
   * Name attribute of the `input` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: Path<TFormValues>;
  /**
   * Index of the assertion sentence from `react-hook-form` array fields.
   */
  assertionIndex: number;
  /**
   * The id that `react-hook-form` created to associate `input` element and form array fields.
   */
  id?: string;
  /**
   * The short hint displayed in the assertion `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * Message to be displayed in the menu if there are no options available.
   */
  noOptionsText?: string;
  /**
   * If `true`, the `input` element is required.
   */
  required?: boolean;
  /**
   * If `true`, the `input` will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TAssertionSentencesDefaultValue>>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
};

export const TrueOrFalseAssertion = <
  TFormValues extends TAssertionSentencesDefaultValue = TAssertionSentencesDefaultValue
>(
  props: TTrueOrFalseAssertionProps<TFormValues>
): JSX.Element => {
  const {
    name,
    assertionIndex,
    id,
    placeholder = SelectContent.DEFAULT.PLACEHOLDER,
    noOptionsText = SelectContent.DEFAULT.EMPTY,
    required = false,
    disabled = false,
    fullWidth = false,
    register,
    control,
    errors,
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const sentenceError = errors?.answers?.[assertionIndex]?.content;
  const hasSentenceError = !!(errors && sentenceError);

  const innerRules: RegisterOptions = {
    ...(required && { required: ValidationContent.Required }),
    disabled,
  };

  const { onBlur, ...registerProps } =
    register?.(`${name}.content` as Path<TFormValues>, innerRules) || {};

  const SelectPlaceholderMemorized = useCallback(
    (
      placeholderProps: PlaceholderProps<TAssertionSelectDefaultOption, false>
    ) => (
      <AssertionSelectPlaceholder
        {...placeholderProps}
        placeholder={placeholder}
      />
    ),
    [placeholder]
  );

  const SelectNoOptionsMemorized = useCallback(
    (noOptionsProps: NoticeProps<TAssertionSelectDefaultOption, false>) => (
      <AssertionSelectNoOptionsMessage
        {...noOptionsProps}
        noOptionsText={noOptionsText}
      />
    ),
    [noOptionsText]
  );

  const inputBlurHandler = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(evt);
  };

  return (
    <Space
      direction="vertical"
      align="start"
      justify="start"
      size="x-small"
      fullWidth={fullWidth}
      className="nb-interactive-true-or-false-assertion"
    >
      <div
        aria-live="polite"
        className={cx([
          "nb-interactive-true-or-false-assertion-wrapper",
          {
            "nb-interactive-true-or-false-assertion-wrapper--error":
              hasSentenceError,
            "nb-interactive-true-or-false-assertion-wrapper--focused":
              isFocused,
          },
        ])}
      >
        <input
          id={`true-or-false-assertion-input-${id}`}
          type="text"
          placeholder={`${placeholder} ${assertionIndex + 1}`}
          className="nb-interactive-true-or-false-assertion-input"
          onFocus={() => setIsFocused(true)}
          onBlur={inputBlurHandler}
          {...registerProps}
        />
        <div
          aria-hidden
          className="nb-interactive-true-or-false-assertion-divider"
        />
        <Controller
          name={`${name}.isCorrect` as Path<TFormValues>}
          control={control}
          rules={innerRules}
          render={({ field }) => (
            <ReactSelect
              {...field}
              value={field.value as TSelectOption<boolean>}
              isClearable={false}
              isMulti={false}
              isSearchable={false}
              isDisabled={disabled}
              required={required}
              placeholder={placeholder}
              options={TRUE_OR_FALSE_EXERCISE_OPTIONS}
              id={`true-or-false-assertion-select-${id}`}
              className="nb-interactive-true-or-false-assertion-select"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                Control: AssertionSelectControl,
                DropdownIndicator: AssertionSelectDropdownIndicator,
                Menu: AssertionSelectMenu,
                Option: AssertionSelectOption,
                SingleValue: AssertionSelectSingleValue,
                Placeholder: SelectPlaceholderMemorized,
                NoOptionsMessage: SelectNoOptionsMemorized,
                Input: AssertionSelectInput,
                ValueContainer: AssertionSelectValueContainer,
              }}
            />
          )}
        />
      </div>
      {hasSentenceError && (
        <small className="nb-interactive-true-or-false-assertion-error-text">
          {sentenceError?.message?.toString()}
        </small>
      )}
    </Space>
  );
};
