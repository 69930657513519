import { useImageUpload } from "hooks/redux";
import { useLazyTimeout } from "hooks/common/useLazyTimeout";
import { MaterialsContent } from "content";
import { ContentModal } from "components/Common/ContentModal";
import { AddCourseForm } from "../AddCourseForm";
import "./AddCourseModal.styles.scss";

type AddCourseModalProps = {
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
};

export const AddCourseModal: React.FC<AddCourseModalProps> = (props) => {
  const { open = false, onClose } = props;

  const { resetAllImageUploadStates } = useImageUpload();
  const resetDelay = useLazyTimeout(300);

  const modalCloseHandler = () => {
    onClose?.();
    resetDelay(resetAllImageUploadStates);
  };

  return (
    <ContentModal
      portalId="add-course-modal"
      footerId="add-course-modal-footer"
      open={open}
      title={MaterialsContent.Add.TITLE}
      emoji={MaterialsContent.Add.EMOJI}
      onClose={modalCloseHandler}
    >
      <AddCourseForm onSubmit={modalCloseHandler} />
    </ContentModal>
  );
};
