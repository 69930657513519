import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import cx from "classnames";
import { ValidationContent } from "content";
import { Space } from "components/Common/Space";
import { RadioInput } from "./RadioInput";
import { RadioRectInput } from "./RadioRectInput";
import { TRadioDefaultOption } from "./RadioGroup.types";
import "./RadioGroup.styles.scss";

type TRadioGroupProps<
  TFormValues extends FieldValues = FieldValues,
  Option extends TRadioDefaultOption = TRadioDefaultOption
> = {
  /**
   * Name attribute of the `RadioGroup` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: FieldPath<TFormValues>;
  /**
   * Validation rules in the same format for register, which includes: required, min, max, minLength, maxLength, pattern, validate
   *
   * @example
   * ```tsx
   * rules={{
   *  pattern: {
   *    value: EMAIL_REGEX,
   *    message: "Email address is invalid",
   *  }
   * }}
   * ```
   * @see https://react-hook-form.com/api/useform/register#options
   */
  rules?: RegisterOptions;
  /**
   * An object with field errors. There is also an ErrorMessage component to retrieve error message easily.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * Array of options to render radio buttons.
   */
  options?: Option[];
  /**
   * If `true`, the `RadioGroup` element is required to be filled out.
   *
   * @default false
   */
  required?: boolean;
  /**
   * Indicates that the user cannot interact with the radio buttons.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the `RadioGroup` will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * The radio buttons are arranged horizontally or vertically.
   * @default "horizontal"
   */
  layout?: "horizontal" | "vertical";
  /**
   * The variant of the `RadioInput` component.
   * @default "default"
   */
  variant?: "default" | "rect";
  /**
   * The radio group label text.
   */
  label?: string;
  /**
   * The id of the `RadioGroup` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const RadioGroup = <
  TFormValues extends FieldValues = FieldValues,
  Option extends TRadioDefaultOption = TRadioDefaultOption
>(
  props: TRadioGroupProps<TFormValues, Option>
): JSX.Element => {
  const {
    name,
    className,
    control,
    disabled = false,
    errors,
    fullWidth = false,
    id,
    label,
    options = [],
    required = false,
    layout = "horizontal",
    variant = "default",
    rules,
  } = props;

  const targetError = errors?.[name];
  const hasError = !!(errors && targetError);

  const innerRules: RegisterOptions = {
    ...(required && { required: ValidationContent.Required }),
    ...rules,
    disabled,
  };

  const radioButtonChangeHandler = (
    evt: React.ChangeEvent<HTMLInputElement>,
    callback: (value: unknown) => void
  ) => {
    callback(
      options.find((option) => option.value.toString() === evt.target.value)
    );
  };

  return (
    <div
      className={cx([
        "nb-interactive-radio-group-wrapper",
        {
          "nb-interactive-radio-group-wrapper--full": fullWidth,
        },
        className,
      ])}
      aria-live="polite"
    >
      {label && (
        <label className="nb-interactive-radio-group-label" htmlFor={id}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={innerRules}
        render={({
          field: {
            ref,
            onChange,
            value: { value: fieldValue },
          },
        }) => (
          <Space
            direction={layout}
            justify="start"
            align={layout === "horizontal" ? "center" : "start"}
            size="small"
            fullWidth={fullWidth}
          >
            {options.map(({ label, value }) =>
              variant === "rect" ? (
                <RadioRectInput
                  key={value.toString()}
                  ref={ref}
                  name={name}
                  id={`radio-${name}-${value}`}
                  value={value.toString()}
                  checked={fieldValue.toString() === value.toString()}
                  disabled={disabled}
                  label={label}
                  layout={layout}
                  onChange={(evt) => radioButtonChangeHandler(evt, onChange)}
                />
              ) : (
                <RadioInput
                  key={value.toString()}
                  ref={ref}
                  name={name}
                  id={`radio-${name}-${value}`}
                  value={value.toString()}
                  checked={fieldValue.toString() === value.toString()}
                  disabled={disabled}
                  label={label}
                  onChange={(evt) => radioButtonChangeHandler(evt, onChange)}
                />
              )
            )}
          </Space>
        )}
      />
      {hasError && (
        <small className="nb-interactive-radio-group-error-text">
          {targetError?.message?.toString()}
        </small>
      )}
    </div>
  );
};
