export const Essay = {
  Add: {
    Input: {
      Type: {
        LABEL: "Тип",
      },
      Text: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Upload: {
        LABEL: "Зображення",
        Button: {
          ADD: "Прикріпити зображення",
          CHANGE: "Змінити зображення",
        },
      },
      Description: {
        LABEL: "Текст",
        PLACEHOLDER: "Скріпт",
        HINT: "Підказка\n Напишіть текст, який дасть студенту розуміння, що йому потрібно зробити в цій вправі.",
      },
    },
    Modal: {
      TITLE: "Оберіть зображення",
      EMOJI: "🖼️",
      Button: {
        SELECT: "Обрати зображення",
        UPLOAD: "Додати обране зображення",
      },
      Input: {
        LABEL: "Перетягніть або оберіть зображення",
      },
    },
  },
  Main: {
    Input: {
      Essay: {
        PLACEHOLDER: "Напишіть твір",
        SAVE: "Зберегти",
        CHANGE: "Змінити",
      },
    },
  },
  Option: {
    ESSAY: "Твір",
    TEXT: "Текст",
  },
};
