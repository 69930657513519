import {
  CommonPropsAndClassName,
  GroupBase,
  MenuPlacement,
  MenuPosition,
  components,
} from "react-select";
import { TSelectDefaultOption } from "../../Select.types";
import "./MenuPortal.styles.scss";

type TMenuPortalProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  appendTo: HTMLElement | undefined;
  children: React.ReactNode;
  controlElement: HTMLDivElement | null;
  innerProps: JSX.IntrinsicElements["div"];
  menuPlacement: MenuPlacement;
  menuPosition: MenuPosition;
} & CommonPropsAndClassName<Option, IsMulti, Group>;

export const MenuPortal = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>(
  props: TMenuPortalProps<Option, false>
): JSX.Element => {
  return (
    <components.MenuPortal
      {...props}
      className="nb-interactive-select-menu-portal"
    >
      {props.children}
    </components.MenuPortal>
  );
};
