import "./SiteContent.styles.scss";

type SiteContentProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const SiteContent: React.FC<SiteContentProps> = ({ children }) => {
  return <div className="nb-layout-site-content">{children}</div>;
};
