import { format } from "date-fns";
import { useRole } from "hooks/common";
import { ContentModal } from "components/Common/ContentModal";
import { EditEventAdminForm } from "../EditEventAdminForm";
import { EditEventForm } from "../EditEventForm";
import { ScheduleContent } from "content";
import { TLesson } from "types/api/lesson";
import "./EditEventModal.styles.scss";

type EditEventModalProps = {
  /**
   * Selected schedule event (lesson) which comes from `ScheduleDashboard`.
   */
  lesson?: TLesson;
  /**
   * The title of the modal component.
   */
  title?: string;
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: () => void;
};

export const EditEventModal: React.FC<EditEventModalProps> = (props) => {
  const { open = false, onClose, lesson, title } = props;

  const { isUserAdmin } = useRole();

  const formattedDate: string | null = lesson
    ? format(new Date(lesson.startDateTime), "dd.MM")
    : null;
  const teacherAndStudentTitle: string = formattedDate
    ? `${ScheduleContent.Edit.LESSON} ${formattedDate}`
    : ScheduleContent.Edit.TITLE;
  const adminTitle: string = title || ScheduleContent.Edit.TITLE;
  const modalTitle: string = isUserAdmin ? adminTitle : teacherAndStudentTitle;

  const formSubmitHandler = () => {
    onClose?.();
  };

  const formDeleteHandler = () => {
    onClose?.();
  };

  return (
    <ContentModal
      portalId="edit-schedule-event-modal"
      footerId="edit-schedule-event-modal-footer"
      open={open}
      onClose={onClose}
      title={modalTitle}
      emoji={ScheduleContent.Edit.EMOJI}
    >
      {isUserAdmin ? (
        <EditEventAdminForm
          lesson={lesson}
          onSubmit={formSubmitHandler}
          onDelete={formDeleteHandler}
        />
      ) : (
        <EditEventForm lesson={lesson} onSubmit={formSubmitHandler} />
      )}
    </ContentModal>
  );
};
