export const CompareImageWithDescription = {
  Add: {
    Input: {
      Text: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Mode: {
        LABEL: "Режим виконання",
        PLACEHOLDER: "Оберіть режим виконання вправи",
      },
      Images: {
        LABEL: "Зображення та підпис до них",
        HINT: 'Підказка \nДля створення варіантів відповідей використовуйте квадратні дужки: []. Відокремте підказку від відповіді за допомогою "/": [підказка/правильна відповідь]. Тобто, перша завжди йде підказка, а після неї відповіді. Правильні відповіді додавайте за допомогою зірочки "*": [підказка/відповідь 1*/відповідь 2]. \nЯкщо хочете використовувати кілька відповідей без підказки, залиште порожній простір перед першим слешем, тобто почніть з "/". \n\nПриклади: \n1. [to play/am playing*] \n2. [better] \n3. [/have*/are having]',
        DESCRIPTION_PLACEHOLDER: "[hint/cherry/apple/strawberry*]",
        Button: {
          PICK: "Прикріпити зображення",
          ADD: "Додати зображення",
          CHANGE: "Змінити зображення",
        },
      },
    },
  },
  DragAndDrop: {
    DRAGGABLE_LABEL: "Відповідь, яку можна перетягнути",
    INPUT_LABEL: "Варіант відповіді",
    INPUT_PLACEHOLDER: "Відповідь",
  },
  Option: {
    Drag: {
      TITLE: "Перетягніть слово",
      DESCRIPTION: "До зображення потрібно перетягнути правильне слово",
    },
    Input: {
      TITLE: "Вписати слово",
      DESCRIPTION: "До зображень необхідно вписати правильне слово",
    },
    Select: {
      TITLE: "Вибрати слово з варіантів",
      DESCRIPTION:
        "До зображень необхідно вибрати правильну відповідь з варіантів",
    },
  },
};
