import { ControlProps, components } from "react-select";
import cx from "classnames";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { TSelectOption } from "types/app/select";
import "./Control.styles.scss";

type ControlCustomProps<
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
> = {
  bordered: boolean;
  menuHidden: boolean;
} & ControlProps<Option, false>;

export const Control = <
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>({
  children,
  menuIsOpen,
  isFocused,
  bordered,
  menuHidden,
  ...controlProps
}: ControlCustomProps<Option>): JSX.Element => {
  return (
    <components.Control
      {...controlProps}
      menuIsOpen={menuIsOpen}
      isFocused={isFocused}
      className={cx([
        "nb-interactive-search-control",
        {
          "nb-interactive-search-control--open": menuIsOpen,
          "nb-interactive-search-control--focused": isFocused,
          "nb-interactive-search-control--bordered": bordered,
          "nb-interactive-search-control--menu-hidden": menuHidden,
        },
      ])}
    >
      <SearchIcon className="nb-interactive-search-icon" />
      {children}
    </components.Control>
  );
};
