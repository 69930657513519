import { useCallback, useMemo, useState } from "react";
import { useCourses } from "hooks/api/courses";
import { MaterialsContent } from "content";
import { MaterialsSearch } from "./components/MaterialsSearch";
import { MaterialsList } from "./components/MaterialsList";
import { TCourse } from "types/api/course";
import { EMaterialsType } from "types/app/materials";
import { TSelectOption } from "types/app/select";
import "./Materials.styles.scss";

const PAGINATION_ENABLED = false;

export const Materials: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<EMaterialsType>(
    EMaterialsType.CATALOG
  );

  const { data: coursesData } = useCourses({
    searchText,
    pageNumber,
    pageSize: PAGINATION_ENABLED ? undefined : 99999,
  });

  const courses: TCourse[] = useMemo(
    () => coursesData?.content || [],
    [coursesData?.content]
  );
  const totalPages: number = useMemo(
    () => coursesData?.totalPages || 0,
    [coursesData?.totalPages]
  );

  const searchCallback = useCallback(async (): Promise<
    TSelectOption<number>[]
  > => {
    return courses.map((course) => ({
      label: course.name,
      value: course.id,
    }));
  }, [courses]);

  const tabChangeHandler = (activeTab: EMaterialsType) => {
    /**
     * TODO: Add additional logic on tab change (filter or fetch courses data)
     */
    setCurrentTab(activeTab);
  };

  const pageChangeHandler = (newPage: number) => {
    setPageNumber(newPage);
  };

  return (
    <>
      <MaterialsSearch
        currentTab={currentTab}
        onSearch={searchCallback}
        onSearchValueChange={setSearchText}
        onTabChange={tabChangeHandler}
        searchEmptyText={MaterialsContent.Search.NO_OPTIONS}
        searchPlaceholder={MaterialsContent.Search.PLACEHOLDER}
      />
      <MaterialsList
        courses={courses}
        currentPage={pageNumber}
        totalPages={totalPages}
        onPageChange={pageChangeHandler}
        paginationEnabled={PAGINATION_ENABLED}
      />
    </>
  );
};
