import { TExercise } from "types/api/exercise";
import { TExerciseIdentifier } from "./common";

export enum EDescribeImageExerciseGalleryView {
  COLUMN = "COLUMN",
}

export type TDescribeImageExerciseImage<
  TImageEncoded = string | null,
  TImageUrl = string | null
> = {
  /**
   * Identifier for an image data item.
   */
  id: TExerciseIdentifier;
  /**
   * Text content for a description of an image.
   */
  description?: string | null;
  /**
   * Image encoded in base64.
   * @description This field will be available before we upload an image to the server.
   */
  imageEncoded: TImageEncoded;
  /**
   * Image url that was uploaded to the server.
   * @description This field will be available after we upload an image to the server.
   */
  imageUrl: TImageUrl;
};

export type TDescribeImageExercisePayload<
  TImageEncoded = string | null,
  TImageUrl = string | null
> = {
  /**
   * Image gallery view for an exercise.
   */
  galleryView: EDescribeImageExerciseGalleryView;
  /**
   * List of images for an exercise.
   */
  images: TDescribeImageExerciseImage<TImageEncoded, TImageUrl>[];
};

export type TDescribeImageExercise = TExercise<
  TDescribeImageExercisePayload<null, string>
>;
