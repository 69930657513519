import {
  DetailedHTMLProps,
  SyntheticEvent,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from "react";
import cx from "classnames";
import {
  FieldError,
  FieldErrorsImpl,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import { ValidationContent } from "content";
import { Tooltip } from "components/Interactive/Tooltip";
import { Button, ButtonProps } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { ReactComponent as Question } from "assets/icons/question.svg";
import "./Textarea.styles.scss";

type TextareaProps<TFormValues extends Record<string, unknown>> = {
  /**
   * Name attribute of the `textarea` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: Path<TFormValues>;
  /**
   * The id of the `textarea` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  resize?: boolean;
  /**
   * If `true`, the `textarea` element is required.
   */
  required?: boolean;
  /**
   * The short hint displayed in the `textarea` before the user enters a value.
   */
  placeholder?: string;
  /**
   * Input label text for the `textarea` element.
   */
  label?: string;
  /**
   * The text hint displayed as tooltip popup right to the label, which looks like a question icon button. Hint will appear when user clicks on the hint button.
   */
  hint?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * If `true`, the `textarea` will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the `textarea` will be displayed with button in the right bottom corner.
   * @default false
   */
  withButton?: boolean;
  /**
   * Props applied to the button element.
   */
  buttonProps?: ButtonProps;
  /**
   * Text for the button in the right bottom corner.
   */
  buttonText?: string;
  /**
   * Callback fired when the button is clicked.
   */
  onButtonClick?: (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  /**
   * If `true`, the `textarea` element is focused during the first mount.
   */
  autoFocus?: boolean;
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete?: string;
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean;
  /**
   * This method allows you to register an textarea or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * 	Validation rules in the same format for register, which includes: required, min, max, minLength, maxLength, pattern, validate
   *
   * @example
   * ```tsx
   * rules={{
   *  pattern: {
   *    value: EMAIL_REGEX,
   *    message: "Email address is invalid",
   *  }
   * }}
   * ```
   * @link https://react-hook-form.com/api/useform/register#options
   */
  rules?: RegisterOptions;
  /**
   * 	An object with field errors. There is also an ErrorMessage component to retrieve error message easily.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
} & DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

/**
 * Textarea interactive element based on `react-hook-form` library
 */
export const Textarea = <TFormValues extends Record<string, unknown>>(
  props: TextareaProps<TFormValues>
): JSX.Element => {
  const {
    className,
    disabled = false,
    placeholder,
    required = false,
    name,
    id,
    fullWidth = false,
    withButton = false,
    buttonProps,
    buttonText,
    onButtonClick,
    autoFocus,
    autoComplete,
    label,
    hint,
    readOnly = false,
    errors,
    register,
    rules,
    resize = false,
    ...other
  } = props;

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const targetError: FieldError | null = name
    .split(".")
    .reduce(
      (prev, curr) => prev?.[curr as keyof FieldError] as unknown as FieldError,
      (errors || null) as unknown as FieldError | null
    );
  const hasError = !!(errors && targetError);

  const innerRules: RegisterOptions = {
    ...(required && { required: ValidationContent.Required }),
    ...rules,
    disabled,
  };

  const { onChange, ...registerProps } = register?.(name, innerRules) || {};

  const handleInputChange = (evt: SyntheticEvent<HTMLTextAreaElement>) => {
    setIsTouched(true);
    onChange?.(evt);
  };

  useEffect(() => {
    if (isTouched && !hasError) {
      setIsSuccess(true);
    }

    if (hasError) {
      setIsSuccess(false);
    }
  }, [isTouched, hasError]);

  return (
    <div
      className={cx(["nb-interactive-textarea-wrapper", className])}
      aria-live="polite"
    >
      {(label || hint) && (
        <div
          className={cx([
            "nb-interactive-textarea-label-container",
            {
              "nb-interactive-textarea-label-container--only-hint":
                !label && hint,
            },
          ])}
        >
          {label && (
            <label
              className={cx([
                "nb-interactive-textarea-label",
                { "nb-interactive-textarea-label--with-hint": !!hint },
              ])}
              htmlFor={id}
            >
              {label}
            </label>
          )}
          {hint && (
            <div className="nb-interactive-textarea-hint-container">
              <Tooltip
                text={hint}
                trigger="click"
                position="bottom-end"
                spaceSize="x-small"
              >
                <IconButton
                  icon={<Question />}
                  variant="tertiary"
                  size="small"
                />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      <div
        className={cx([
          "nb-interactive-textarea-holder",
          {
            "nb-interactive-textarea-holder--full": fullWidth,
          },
        ])}
      >
        <textarea
          aria-invalid={hasError}
          aria-label={label}
          id={id}
          placeholder={placeholder}
          required={required}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          readOnly={readOnly}
          className={cx([
            "nb-interactive-textarea",
            {
              "nb-interactive-textarea--success": isSuccess,
              "nb-interactive-textarea--error": hasError,
              "nb-interactive-textarea--resizable": resize,
            },
          ])}
          onChange={handleInputChange}
          {...registerProps}
          {...other}
        />
        {withButton && (
          <Button
            variant="primary"
            onClick={onButtonClick}
            className="nb-interactive-textarea-button"
            {...buttonProps}
          >
            {buttonText}
          </Button>
        )}
      </div>
      {hasError && (
        <small className="nb-interactive-textarea-error-text">
          {targetError?.message?.toString()}
        </small>
      )}
    </div>
  );
};
