import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const typeWordsIntoGapsExercise: TExerciseItem = {
  title: "Ввести слова у пропуски",
  backgroundText: "Слова у пропуски",
  tags: [
    "Ввести слова у пропуски",
    "ввести",
    "слова",
    "слово",
    "у",
    "пропуски",
    "перепустки",
    "write",
    "type",
    "input",
    "words",
    "into",
    "gaps",
    "blanks",
  ],
  type: EExerciseType.INPUT_WORDS_INTO_GAPS,
  cover: "type-words-into-gaps.png",
};
