import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { MoveWordsIntoGapsExerciseContent } from "content";
import { Space } from "components/Common/Space";
import { Paragraph } from "components/Typography/Paragraph";
import { DraggableItem } from "../DraggableItem";
import { DraggableItemPlaceholder } from "../DraggableItemPlaceholder";
import { START_ZONE_ID } from "../MoveWordsIntoGaps.helpers";
import {
  TExerciseIdentifier,
  TExerciseSentenceAnswer,
} from "types/app/exercises";
import "./DraggableStartZone.styles.scss";

type TDraggableStartZoneProps = {
  /**
   * An array of answers to display in the start zone area.
   */
  answers?: TExerciseSentenceAnswer[];
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DraggableStartZone: React.FC<TDraggableStartZoneProps> = (
  props
) => {
  const { answers = [], dragActiveId = null } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: START_ZONE_ID,
  });

  const isEmpty: boolean = answers.length === 0;

  return (
    <Space
      ref={setNodeRef}
      direction="horizontal"
      align="center"
      justify={isEmpty ? "center" : "start"}
      size="medium"
      fullWidth
      wrap
      className={cx([
        "nb-exercise-move-words-draggable-start-zone",
        { "nb-exercise-move-words-draggable-start-zone--over": isOver },
      ])}
    >
      {isEmpty && (
        <Paragraph variant={2}>
          {MoveWordsIntoGapsExerciseContent.EMPTY}
        </Paragraph>
      )}
      {answers.map((answer) =>
        answer.id === dragActiveId ? (
          <DraggableItemPlaceholder key={answer.id} content={answer.content} />
        ) : (
          <DraggableItem key={answer.id} answer={answer} />
        )
      )}
    </Space>
  );
};
