import { LessonDetailsContent } from "content";
import { ContentModal } from "components/Common/ContentModal";
import { AddLessonChapterForm } from "../AddLessonChapterForm";
import "./AddLessonChapterModal.styles.scss";

type AddLessonChapterModalProps = {
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: () => void;
  /**
   * Callback function fires on add chapter submit event.
   * @param chapterId - The ID of the added chapter.
   */
  onSubmit?: (chapterId: number) => void;
};

export const AddLessonChapterModal: React.FC<AddLessonChapterModalProps> = (
  props
) => {
  const { open = false, onClose, onSubmit } = props;

  return (
    <ContentModal
      portalId="add-course-lesson-chapter-modal"
      footerId="add-course-lesson-chapter-modal-footer"
      open={open}
      title={LessonDetailsContent.Add.TITLE}
      emoji={LessonDetailsContent.Add.EMOJI}
      onClose={onClose}
    >
      <AddLessonChapterForm onSubmit={onSubmit} />
    </ContentModal>
  );
};
