import { useNavigate } from "react-router-dom";
import { useModal } from "hooks/common/useModal";
import { useRemoveCourse } from "hooks/api/courses";
import { CourseDetailsContent } from "content";
import { ActionModal } from "components/Common/ActionModal";
import { Loader } from "components/Common/Loader";
import { CourseHeroContent } from "../CourseHeroContent";
import { CourseHeroImage } from "../CourseHeroImage";
import { CourseHeroActions } from "../CourseHeroActions";
import { AddCourseLessonModal } from "../AddCourseLessonModal";
import { EditCourseModal } from "../EditCourseModal";
import { Route } from "constants/routes";
import { logError, logSuccess } from "utils/notifications";
import { TCourse } from "types/api/course";
import "./CourseHero.styles.scss";

type CourseHeroProps = {
  /**
   * Course data to display in `Hero` component.
   */
  course?: TCourse;
  /**
   * If `true` component will display loader with animation.
   *
   * @default false
   */
  loading?: boolean;
};

export const CourseHero: React.FC<CourseHeroProps> = (props) => {
  const { course, loading = false } = props;

  const navigate = useNavigate();

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);
  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal(false);
  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const { mutate: removeCourse } = useRemoveCourse();

  const deleteSubmitHandler = () => {
    if (!course) {
      logError({
        message: CourseDetailsContent.Delete.Course.Notification.ERROR,
      });
      return;
    }
    removeCourse(course.id, {
      onSuccess: () => {
        logSuccess(CourseDetailsContent.Delete.Course.Notification.SUCCESS);
        closeDeleteModal();
        navigate(Route.App.Materials.ROOT.path);
      },
    });
  };

  return (
    <>
      <section className="nb-sub-materials-details-hero">
        {loading ? (
          <Loader />
        ) : (
          <div className="nb-sub-materials-details-wrapper">
            <CourseHeroImage imageUrl={course?.imageUrl} />
            <CourseHeroContent
              name={course?.name}
              description={course?.description}
            />
            <CourseHeroActions
              onEditClick={openEditModal}
              onDeleteClick={openDeleteModal}
              onAddClick={openAddModal}
            />
          </div>
        )}
      </section>
      <AddCourseLessonModal open={isAddModalOpen} onClose={closeAddModal} />
      <EditCourseModal
        open={isEditModalOpen}
        onClose={closeEditModal}
        course={course}
      />
      <ActionModal
        text={CourseDetailsContent.Delete.Course.TITLE}
        emoji={CourseDetailsContent.Delete.Course.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={deleteSubmitHandler}
      />
    </>
  );
};
