import { useMutation, useQueryClient } from "react-query";
import { EExercisesQueryKeys } from "api/endpoints/exercises";
import { editHomeworkExercise } from "api/services/exercises";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TEditExercise, TExercise } from "types/api/exercise";
import { TUrlParam } from "types/utils";

export const useEditHomeworkExercise = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  homeworkId: TUrlParam
) => {
  const queryClient = useQueryClient();
  return useMutation<
    TExercise<TPayload, TText>,
    TApiError,
    TEditExercise<TPayload, TText>
  >(
    (homeworkExercise: TEditExercise<TPayload, TText>) =>
      editHomeworkExercise<TPayload, TText>(
        courseId,
        lessonId,
        homeworkId,
        homeworkExercise
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EExercisesQueryKeys.getHomeworkExercises);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
