import { ControlProps, components } from "react-select";
import cx from "classnames";
import type { TClueSelectDefaultOption } from "../../ClueSelect.types";
import "./Control.styles.scss";

type ClueSelectControlProps<
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
> = {
  /**
   * Indicates if selected option is correct.
   * Used to display correct answer with predefined styles.
   * @default false
   */
  correct?: boolean;
  /**
   * Indicates if the result should be shown.
   * This prop is used to display correctness or incorrectness of the answer.
   * @default false
   */
  showResult?: boolean;
} & ControlProps<Option, false>;

export const Control = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>({
  children,
  menuIsOpen,
  isFocused,
  isDisabled,
  correct = false,
  showResult = false,
  ...controlProps
}: ClueSelectControlProps<Option>): JSX.Element => {
  return (
    <components.Control
      {...controlProps}
      menuIsOpen={menuIsOpen}
      isFocused={isFocused}
      isDisabled={isDisabled}
      className={cx([
        "nb-interactive-clue-select-control",
        {
          "nb-interactive-clue-select-control--open": menuIsOpen,
          "nb-interactive-clue-select-control--focused": isFocused,
          "nb-interactive-clue-select-control--disabled": isDisabled,
          "nb-interactive-clue-select-control--correct": showResult && correct,
          "nb-interactive-clue-select-control--fail": showResult && !correct,
        },
      ])}
    >
      {children}
    </components.Control>
  );
};
