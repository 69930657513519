import ReactPlayer, { ReactPlayerProps } from "react-player";
import LazyReactPlayer from "react-player/lazy";
import { useState } from "react";
import cx from "classnames";
import "./VideoPlayer.styles.scss";

type TVideoPlayerProps = ReactPlayerProps & {
  /**
   * If true, the video player will be lazy loaded.
   * @default false
   */
  lazy?: boolean;
  /**
   * Indicates whether the video player should be rounded or not.
   * @default false
   */
  rounded?: boolean;
  /**
   * Override or extend the styles applied to the component container.
   */
  containerClassName?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * `VideoPlayer` is a wrapper around `react-player` component.
 * @see https://www.npmjs.com/package/react-player
 */
export const VideoPlayer: React.FC<TVideoPlayerProps> = (props) => {
  const {
    lazy = false,
    rounded = false,
    url,
    containerClassName,
    className,
    ...reactPlayerProps
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const videoPlayerReadyHandler = (reactPlayer: ReactPlayer) => {
    setIsLoading(false);
    reactPlayerProps.onReady?.(reactPlayer);
  };

  return (
    <div
      className={cx([
        "nb-interactive-video-player-wrapper",
        {
          "nb-interactive-video-player-wrapper--loading": isLoading,
          "nb-interactive-video-player-wrapper--rounded": rounded,
        },
        containerClassName,
      ])}
    >
      {lazy ? (
        <LazyReactPlayer
          url={url}
          {...reactPlayerProps}
          onReady={videoPlayerReadyHandler}
          className={cx(["nb-interactive-video-player", className])}
        />
      ) : (
        <ReactPlayer
          url={url}
          {...reactPlayerProps}
          onReady={videoPlayerReadyHandler}
          className={cx(["nb-interactive-video-player", className])}
        />
      )}
    </div>
  );
};
