import { useMutation, useQueryClient } from "react-query";
import { editGroup } from "api/services/groups";
import { EGroupsQueryKeys } from "api/endpoints/groups";
import { logError } from "utils/notifications";
import { TGroup } from "types/api/groups";
import { TApiError } from "types/api/error";

export const useEditGroup = () => {
  const queryClient = useQueryClient();
  return useMutation<TGroup, TApiError, TGroup>(editGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(EGroupsQueryKeys.getGroups);
      queryClient.invalidateQueries(EGroupsQueryKeys.getGroupById);
      queryClient.invalidateQueries(EGroupsQueryKeys.getGroupMembers);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
