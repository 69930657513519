import { Space } from "components/Common/Space";
import { DroppableItem } from "../DroppableItem";
import {
  TExerciseIdentifier,
  TSortWordsExerciseParsedColumn,
} from "types/app/exercises";
import "./DroppableList.styles.scss";

type TDroppableListProps = {
  /**
   * An array of column data to construct an exercise.
   */
  columns: TSortWordsExerciseParsedColumn[];
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DroppableList: React.FC<TDroppableListProps> = (props) => {
  const {
    dragging = false,
    showResult = false,
    columns = [],
    dragActiveId = null,
  } = props;

  return (
    <Space
      direction="horizontal"
      size="medium"
      justify="start"
      align="stretch"
      fullWidth
      wrap
    >
      {columns.map((column) => (
        <DroppableItem
          key={column.id}
          column={column}
          dragging={dragging}
          dragActiveId={dragActiveId}
          showResult={showResult}
        />
      ))}
    </Space>
  );
};
