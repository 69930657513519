import { useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import cx from "classnames";
// calendar lib (order of imports matter)
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventImpl } from "@fullcalendar/core/internal";
import { useModal } from "hooks/common/useModal";
import { useRole } from "hooks/common";
import { useScheduleDates } from "hooks/redux";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { useScheduleLessons } from "hooks/api/schedule";
import { useGroups } from "hooks/api/groups/useGroups";
import { useStudents } from "hooks/api/students/useStudents";
import { Text } from "components/Typography/Text";
import { AddEventModal } from "../AddEventModal";
import { EditEventModal } from "../EditEventModal";
import { LessonCreateTimeRange } from "constants/lesson";
import { adaptScheduleEventData } from "./ScheduleDashboard.helpers";
import { TStudent } from "types/api/student";
import { TGroup } from "types/api/groups";
import { TLesson } from "types/api/lesson";
import {
  TScheduleAddEvent,
  TScheduleEditEvent,
  TScheduleEvent,
} from "types/app/schedule";
import "./ScheduleDashboard.styles.scss";

type ScheduleDashboardProps = {
  /**
   * Selected teacher ID which comes from `ScheduleControls`.
   */
  teacherId?: number;
  /**
   * Selected student ID which comes from `ScheduleControls`.
   */
  studentId?: number;
  /**
   * Selected group ID which comes from `ScheduleControls`.
   */
  groupId?: number;
};

export const ScheduleDashboard: React.FC<ScheduleDashboardProps> = (props) => {
  const { teacherId, studentId, groupId } = props;

  const { formattedStartDate, queryStartDate, queryEndDate } =
    useScheduleDates();
  const calendarRef = useRef<FullCalendar | null>(null);

  const [selectedEvent, setSelectedEvent] = useState<EventImpl>();
  const [addStartDate, setAddStartDate] = useState<Date>();
  const [addEndDate, setAddEndDate] = useState<Date>();

  const { isUserStudent, isUserTeacher } = useRole();
  const { activeUser } = useActiveUser();

  const { data: studentsData } = useStudents();
  const { data: groupsData } = useGroups();

  const students: TStudent[] = useMemo(
    () => studentsData?.content || [],
    [studentsData]
  );
  const groups: TGroup[] = useMemo(
    () => groupsData?.content || [],
    [groupsData]
  );

  const selectedTeacherId = useMemo(
    () => (isUserTeacher ? activeUser?.teacherId : teacherId),
    [activeUser?.teacherId, teacherId, isUserTeacher]
  );

  const selectedStudentId = useMemo(
    () => (isUserStudent ? activeUser?.studentId : studentId),
    [activeUser?.studentId, studentId, isUserStudent]
  );

  const selectedGroupId = useMemo(
    () => (isUserStudent ? undefined : groupId),
    [groupId, isUserStudent]
  );

  const { data: scheduleLessonsData } = useScheduleLessons({
    start: queryStartDate,
    end: queryEndDate,
    teacherId: selectedTeacherId,
    studentId: selectedStudentId,
    groupId: selectedGroupId,
  });
  const lessons: TLesson[] = useMemo(
    () => scheduleLessonsData || [],
    [scheduleLessonsData]
  );

  const events: TScheduleEvent[] = useMemo(
    () => adaptScheduleEventData(lessons, students, groups),
    [lessons, students, groups]
  );

  useEffect(() => {
    /**
     * Handling date in FullCalendar through ref, because lib do not have prop for such logic.
     * So we need to assign ref and later access an api from that ref.
     * @link see: https://fullcalendar.io/docs/react#calendar-api
     */
    calendarRef?.current?.getApi().gotoDate(formattedStartDate);
  }, [formattedStartDate]);

  const {
    isModalOpen: isAddEventModalOpen,
    openModal: openAddEventModal,
    closeModal: closeAddEventModal,
  } = useModal(false);

  const {
    isModalOpen: isEditEventModalOpen,
    openModal: openEditEventModal,
    closeModal: closeEditEventModal,
  } = useModal(false);

  const addEventHandler = (event: TScheduleAddEvent) => {
    if (isUserStudent || isUserTeacher) {
      return;
    }
    setAddStartDate(event.startDate);
    setAddEndDate(event.endDate);
    openAddEventModal();
  };

  const editEventHandler = (event: TScheduleEditEvent) => {
    setSelectedEvent(event);
    openEditEventModal();
  };

  const closeAddEventModalHandler = () => {
    setAddStartDate(undefined);
    setAddEndDate(undefined);
    closeAddEventModal();
  };

  const closeEditEventModalHandler = () => {
    setSelectedEvent(undefined);
    closeEditEventModal();
  };

  return (
    <>
      <FullCalendar
        /** Config */
        plugins={[timeGridPlugin, interactionPlugin]}
        selectable
        editable
        droppable={false}
        headerToolbar={false}
        allDaySlot={false}
        dragScroll={false}
        eventStartEditable={false}
        eventResizableFromStart={false}
        eventDurationEditable={false}
        ref={calendarRef}
        /** Locale */
        locale="uk"
        timeZone="Europe/Kiev"
        weekNumberCalculation="ISO"
        /** Slot Options */
        slotMinTime={{ hour: LessonCreateTimeRange.MIN }}
        slotMaxTime={{ hour: LessonCreateTimeRange.MAX }}
        slotLabelInterval={{ hour: 1 }}
        slotDuration={{ hour: 1 }}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
        }}
        /** Styles */
        slotMinWidth={75}
        eventMinHeight={41}
        viewClassNames="nb-sub-schedule-view"
        dayHeaderClassNames="nb-sub-schedule-day-header"
        slotLaneClassNames="nb-sub-schedule-slot-lane"
        slotLabelClassNames="nb-sub-schedule-slot-label"
        dayCellClassNames={cx([
          "nb-sub-schedule-day-cell",
          { "nb-sub-schedule-day-cell--student": isUserStudent },
        ])}
        eventClassNames="nb-sub-schedule-event"
        /** View */
        initialView="timeGridWeek"
        contentHeight="auto"
        /** Data */
        initialEvents={[]}
        events={events}
        /** Events */
        select={(arg) =>
          addEventHandler({ startDate: arg.start, endDate: arg.end })
        }
        eventClick={(arg) => editEventHandler(arg.event)}
        /** Content */
        dayHeaderContent={({ date }) => (
          <Text variant="body2" className="nb-schedule-day-header-label">
            <span className="nb-schedule-day-header-label--weekday">
              {format(date, "EEEEEE")}
            </span>
            &nbsp;
            <span className="nb-schedule-day-header-label--date">
              {format(date, "dd.LL")}
            </span>
          </Text>
        )}
        slotLabelContent={({ text }) => (
          <Text variant="body2" className="nb-schedule-slot-label-text">
            {text}
          </Text>
        )}
        eventContent={({ event }) => (
          <Text variant="body2" bold className="nb-schedule-event-text">
            {event.title}
          </Text>
        )}
      />
      <AddEventModal
        startDate={addStartDate}
        endDate={addEndDate}
        open={isAddEventModalOpen}
        teacherId={teacherId}
        onClose={closeAddEventModalHandler}
      />
      <EditEventModal
        open={isEditEventModalOpen}
        onClose={closeEditEventModalHandler}
        title={selectedEvent?.title}
        lesson={selectedEvent?.extendedProps.data}
      />
    </>
  );
};
