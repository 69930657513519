import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Search } from "components/Interactive/Search";
import { Tabs } from "components/Interactive/Tabs";
import { MaterialsContent } from "content";
import { TTabItem } from "types/app/tabs";
import { TSelectOption } from "types/app/select";
import { EMaterialsType } from "types/app/materials";
import { TMaterialsSearchForm } from "./MaterialsSearch.types";
import "./MaterialsSearch.styles.scss";

type MaterialsSearchProps = {
  /**
   * Message to be displayed in the menu if there are no options available.
   */
  searchEmptyText?: string;
  /**
   * The short hint displayed in the Search before the user input a value.
   */
  searchPlaceholder?: string;
  /**
   * Active material type to show correct items.
   */
  currentTab?: EMaterialsType;
  /**
   * Callback will fire when `Tabs` will change value.
   */
  onTabChange?: (activeTab: EMaterialsType) => void;
  /**
   * Callback that fires to fetch new data to populate autocomplete options.
   *
   * @param searchValue - user search input.
   * @returns promise, which is the set of options to be used once the promise resolves.
   */
  onSearch?: (searchValue: string) => Promise<TSelectOption<number>[]>;
  /**
   * Callback will fire when `Search` will change value.
   *
   * @param value - new search value.
   * @returns nothing (voided function).
   */
  onSearchValueChange?: (value: string) => void;
};

export const MaterialsSearch: React.FC<MaterialsSearchProps> = (props) => {
  const {
    searchEmptyText = MaterialsContent.Search.NO_OPTIONS,
    searchPlaceholder = MaterialsContent.Search.PLACEHOLDER,
    currentTab = EMaterialsType.CATALOG,
    onSearch,
    onSearchValueChange,
    onTabChange,
  } = props;

  const { control, watch } = useForm<TMaterialsSearchForm>({
    defaultValues: {
      search: "",
    },
  });

  const searchValue = watch("search");

  useEffect(() => {
    onSearchValueChange?.(searchValue);
  }, [searchValue, onSearchValueChange]);

  // const tabItems: TTabItem<EMaterialsType>[] = [
  //   {
  //     key: EMaterialsType.CATALOG,
  //     label: MaterialsContent.Tabs.Catalog.TITLE,
  //   },
  //   {
  //     key: EMaterialsType.PERSONAL,
  //     label: MaterialsContent.Tabs.Personal.TITLE,
  //   },
  // ];

  return (
    <div className="nb-sub-materials-search-container">
      <Search<TMaterialsSearchForm, TSelectOption<string | number>>
        name="search"
        control={control}
        id="materials-search"
        placeholder={searchPlaceholder}
        noOptionsText={searchEmptyText}
        searchCallback={onSearch}
        fullWidth
        filterSearch
        className="nb-sub-materials-search"
      />
      {/*<Tabs<EMaterialsType>*/}
      {/*  items={tabItems}*/}
      {/*  activeTabKey={currentTab}*/}
      {/*  onChange={onTabChange}*/}
      {/*  className="nb-sub-materials-tabs"*/}
      {/*/>*/}
    </div>
  );
};
