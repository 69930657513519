import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TActiveUser } from "types/api/user";
import { EAppReducers } from "../../white-list";

export type TUserState = {
  /**
   * Currently authorized user data.
   * @default null
   */
  activeUser: TActiveUser | null;
};

const initialState: TUserState = {
  activeUser: null,
};

export const userSlices = createSlice({
  name: EAppReducers.USER,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<TActiveUser>) => {
      state.activeUser = action.payload;
    },
    resetUser: (state) => {
      state.activeUser = null;
    },
  },
});

export const userReducer = userSlices.reducer;
