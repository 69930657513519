/**
 * The convert function is used to represent file data into raw blob.
 * @param file object is a specific kind of Blob, and can be used in any context that a Blob can.
 * @returns file's data as a base64 encoded string.
 */
export const convertFileToBlob = async (file: File): Promise<Blob> =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

/**
 * This conversion function is used to encode raw binary file data into a URL base64 string.
 * @param fileBuffer file object is a Blob or ArrayBuffer.
 * @returns file's data as a base64 encoded string.
 */
export const convertBlobToBase64 = (fileBuffer: Blob): string =>
  URL.createObjectURL(new Blob([fileBuffer]));

/**
 * The convert function is used to represent base64 URL data into raw blob.
 * @param imageDataURL file's data as a base64 encoded string.
 * @returns file object as Blob.
 */
export const convertBase64ToBlob = async (
  imageDataURL: string
): Promise<Blob> => await (await fetch(imageDataURL)).blob();

/**
 * This conversion function is used to encode raw binary file data into a base64 string.
 * @param fileBuffer file object is a Blob or ArrayBuffer.
 * @param options object that contains additional options to convert file data.
 * @returns file's data as a base64 encoded string.
 */
export const convertBlobToBase64String = (
  fileBuffer: Blob,
  options?: { removePrefix?: boolean }
): Promise<string> => {
  const { removePrefix = false } = options || {};
  const reader = new FileReader();
  reader.readAsDataURL(fileBuffer);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      const base64String = reader.result as string;
      if (removePrefix) {
        const [, strippedBase64] = base64String.split(",");
        resolve(strippedBase64);
        return;
      }
      resolve(base64String);
    };
  });
};
