import cx from "classnames";
import "./DraggablePlaceholder.styles.scss";

type TDraggablePlaceholderProps = {
  /**
   * Inner text content of the component.
   */
  content?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DraggablePlaceholder: React.FC<TDraggablePlaceholderProps> = (
  props
) => {
  const { content = "", className } = props;

  return (
    <div className={cx(["nb-drag-and-drop-draggable-placeholder", className])}>
      {content}
    </div>
  );
};
