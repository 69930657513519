import { Image } from "components/Common/Image";
import { Space } from "components/Common/Space";
import "./ImageItem.styles.scss";

type TImageItemProps = {
  /**
   * An image source url.
   */
  fileUrl: string;
  /**
   * The content of the component.
   * Meant to be a blank. Like dropzone, input or select for user to fill.
   */
  children?: React.ReactNode;
};

export const ImageItem: React.FC<TImageItemProps> = (props) => {
  const { fileUrl, children } = props;

  return (
    <Space direction="vertical" justify="start" align="center" size="x-small">
      <Image
        src={fileUrl}
        alt="Describe image"
        className="nb-exercise-compare-image-with-description-image"
        rounded
      />
      {children}
    </Space>
  );
};
