import { useMutation, useQueryClient } from "react-query";
import { deleteLandingOffer } from "api/services/admin";
import { EAdminQueryKeys } from "api/endpoints/admin";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TUrlParam } from "types/utils";

export const useDeleteLandingOffer = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, TUrlParam>(
    [EAdminQueryKeys.deleteLandingOffer],
    deleteLandingOffer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EAdminQueryKeys.getLandingOffers);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
