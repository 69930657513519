import {
  TExercise,
  TExerciseFileEncoded,
  TExerciseFileUrl,
} from "types/api/exercise";
import { TExerciseIdentifier } from "types/app/exercises";
import { TSelectOption } from "types/app/select";

export enum ECompareImageWithDescriptionMode {
  DRAG = "DRAG",
  INPUT = "INPUT",
  SELECT = "SELECT",
}

export type TCompareImageWithDescriptionExerciseImage<
  TImageValue = string | null
> = {
  /**
   * Identifier for an image data item.
   */
  id: TExerciseIdentifier;
  /**
   * Text content for a description of an image.
   */
  description: string;
  /**
   * Image encoded in base64.
   */
  imageEncoded: TImageValue;
  /**
   * Image url that was uploaded to the server.
   * @description This field will be available only for edit mode.
   */
  imageUrl?: string | null;
};

export type TCompareImageWithDescriptionExerciseParsedAnswer = {
  /**
   * Identifier for parsed answer item.
   */
  id: TExerciseIdentifier;
  /**
   * Text content for an answer.
   */
  content: string;
  /**
   * Indicates if answer is correct or not.
   */
  isCorrect: boolean;
  /**
   * Indicates if answer was touched by the user or not.
   */
  isTouched: boolean;
  /**
   * Indicates if the user already associated this answer with an image blank.
   */
  isSelected: boolean;
};

export type TCompareImageWithDescriptionExerciseParsedImage =
  TExerciseFileEncoded &
    TExerciseFileUrl & {
      /**
       * Identifier for parsed image item.
       */
      id: TExerciseIdentifier;
      /**
       * Array of answers which is indicates as correct ones for this image blank.
       */
      correctAnswers: TCompareImageWithDescriptionExerciseParsedAnswer[];
      /**
       * Answer data that was associated to this image blank by the user, otherwise will be `null`.
       */
      currentAnswer: TCompareImageWithDescriptionExerciseParsedAnswer | null;
      /**
       * Indicates if image blank has an answer or not.
       */
      isEmpty: boolean;
      /**
       * Indicates if image blank was touched by the user or not.
       */
      isTouched: boolean;
      /**
       * Indicates if this image blank disabled for user interactions or not.
       */
      isLocked: boolean;
      /**
       * Indicates if image blank has correct answer as `currentAnswer`.
       */
      isCorrectAnswer: boolean;
      /**
       * Indicates if user failed to pick correct answer with some limit.
       */
      isAllAttemptsFailed: boolean;
      /**
       * Current attempts number that user associated answer with an image blank.
       */
      currentAttempt: number;
      /**
       * Stack of attempts answers.
       */
      attempts: TCompareImageWithDescriptionExerciseParsedAnswer[];
      /**
       * Text hint that will be available only for admin and teacher.
       */
      hint: string | null;
      /**
       * Array of all options for this image blank.
       */
      options: TSelectOption<string>[];
      /**
       * User inputted image data before parsing.
       */
      rawImage: Omit<TCompareImageWithDescriptionExerciseImage, "imageEncoded">;
    };

export type TCompareImageWithDescriptionExerciseParsedData = {
  /**
   * Array of all parsed images data for an exercise.
   */
  images: TCompareImageWithDescriptionExerciseParsedImage[];
  /**
   * Array of all answers for an exercise.
   */
  answers: TCompareImageWithDescriptionExerciseParsedAnswer[];
  /**
   * Array of all answers for an exercise but shuffled.
   */
  shuffledAnswers: TCompareImageWithDescriptionExerciseParsedAnswer[];
};

export type TCompareImageWithDescriptionExercisePayload =
  TCompareImageWithDescriptionExerciseParsedData & {
    /**
     * Image compare mode.
     */
    mode: ECompareImageWithDescriptionMode;
  };

export type TCompareImageWithDescriptionExercise =
  TExercise<TCompareImageWithDescriptionExercisePayload>;
