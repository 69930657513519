import { useMutation, useQueryClient } from "react-query";
import { createLandingOffer } from "api/services/admin";
import { EAdminQueryKeys } from "api/endpoints/admin";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import {
  TAdminLandingOffer,
  TAdminLandingOfferCreateBody,
} from "types/api/admin";

export const useCreateLandingOffer = () => {
  const queryClient = useQueryClient();
  return useMutation<
    TAdminLandingOffer,
    TApiError,
    TAdminLandingOfferCreateBody
  >([EAdminQueryKeys.createLandingOffer], createLandingOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries(EAdminQueryKeys.getLandingOffers);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
