import { useQuery } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { getCourses } from "api/services/courses";
import { logError } from "utils/notifications";
import { TCourseRequestParams, TCourseResponse } from "types/api/course";
import { TApiError } from "types/api/error";

export const useCourses = (options?: TCourseRequestParams) => {
  return useQuery<TCourseResponse, TApiError>(
    [ECoursesQueryKeys.getAllCourses, options],
    () => getCourses(options),
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
