import { useEffect, useState } from "react";
import { LessonDetailsContent } from "content";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { DndController } from "../DndController";
import { InputController } from "../InputController";
import { SelectController } from "../SelectController";
import { logError } from "utils/notifications";
import {
  ECompareImageWithDescriptionMode,
  TCompareImageWithDescriptionExercise,
} from "types/app/exercises";
import "./ExerciseContainer.styles.scss";

type TExerciseContainerProps = {
  /**
   * An exercise raw data from API response.
   */
  exercise: TCompareImageWithDescriptionExercise;
};

export const ExerciseContainer: React.FC<TExerciseContainerProps> = (props) => {
  const { exercise } = props;

  const [exerciseData, setExerciseData] =
    useState<TCompareImageWithDescriptionExercise>(exercise);

  const { isExercisePreviewMode, isExerciseLiveMode } = useLessonSettings();

  useEffect(() => {
    if (isExercisePreviewMode) {
      setExerciseData({
        ...exercise,
        payload: {
          ...exercise.payload,
          data: {
            ...exercise.payload.data,
            images: exercise.payload.data.images.map((image) => ({
              ...image,
              isEmpty: false,
              isCorrectAnswer: true,
              currentAnswer:
                image.correctAnswers.find((answer) => answer.isCorrect) || null,
            })),
            answers: exercise.payload.data.answers.map((answer) => ({
              ...answer,
              isSelected: answer.isCorrect,
            })),
            shuffledAnswers: exercise.payload.data.answers.map((answer) => ({
              ...answer,
              isSelected: answer.isCorrect,
            })),
          },
        },
      });
    }

    if (isExerciseLiveMode) {
      setExerciseData(exercise);
    }
  }, [isExercisePreviewMode, isExerciseLiveMode, exercise]);

  const renderExerciseByMode = (
    exerciseMode: ECompareImageWithDescriptionMode
  ): React.ReactNode => {
    switch (exerciseMode) {
      case ECompareImageWithDescriptionMode.DRAG:
        return <DndController exercise={exerciseData} />;

      case ECompareImageWithDescriptionMode.INPUT:
        return <InputController exercise={exerciseData} />;

      case ECompareImageWithDescriptionMode.SELECT:
        return <SelectController exercise={exerciseData} />;

      default:
        logError({
          message: LessonDetailsContent.Preview.Error.INVALID_EXERCISE,
        });
        return null;
    }
  };

  return (
    <Space
      direction="vertical"
      size="medium"
      justify="start"
      align="stretch"
      fullWidth
    >
      {renderExerciseByMode(exerciseData.payload.data.mode)}
    </Space>
  );
};
