export const DescribeVideo = {
  Add: {
    Input: {
      Text: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Description: {
        LABEL: "Опис вправи",
        PLACEHOLDER: "Перегляньте відео та коротко перекажіть його...",
        HINT: "Підказка\n Напишіть текст, який дасть студенту розуміння, що йому потрібно зробити в цій вправі.",
      },
      Url: {
        LABEL: "Посилання на відео",
        PLACEHOLDER: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
      },
    },
  },
};
