import cx from "classnames";
import { ReactComponent as QuestionIcon } from "assets/icons/question.svg";
import { Tooltip } from "components/Interactive/Tooltip";
import { IconButton } from "components/Interactive/IconButton";
import "./InputLabel.styles.scss";

type TInputLabelProps = {
  /**
   * Label text for the `InputLabel` component.
   */
  label?: string;
  /**
   * The id to associate the label with an input element.
   */
  id?: string;
  /**
   * The text hint displayed as tooltip popup right to the label, which looks like a question icon button. Hint will appear when user clicks on the hint button.
   */
  hint?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component container.
   */
  containerClassName?: string;
};

export const InputLabel: React.FC<TInputLabelProps> = (props) => {
  const { label, hint, id, className, containerClassName } = props;

  return label || hint ? (
    <div
      className={cx([
        "nb-common-input-label-container",
        {
          "nb-common-input-label-container--only-hint": !label && hint,
        },
        containerClassName,
      ])}
    >
      {label && (
        <label
          className={cx([
            "nb-common-input-label",
            { "nb-common-input-label--with-hint": !!hint },
            className,
          ])}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {hint && (
        <div className="nb-common-input-label-hint-container">
          <Tooltip
            text={hint}
            trigger="click"
            position="bottom-end"
            spaceSize="x-small"
          >
            <IconButton
              icon={<QuestionIcon />}
              variant="tertiary"
              size="small"
            />
          </Tooltip>
        </div>
      )}
    </div>
  ) : null;
};
