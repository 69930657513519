import "./AuthContainer.styles.scss";

type AuthContainerProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const AuthContainer: React.FC<AuthContainerProps> = ({ children }) => {
  return <div className="nb-layout-auth-container">{children}</div>;
};
