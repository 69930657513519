import {
  TExerciseIdentifier,
  TWordsComparingExerciseParsedLinkedWord,
  TWordsComparingExerciseParsedWord,
} from "types/app/exercises";

export const WORD_GAP = 10;

/**
 * Helper function that allows to update a word data when new pair word was selected.
 * @param word word data that should be updated.
 * @param selectedLinkedWord new selected pair linked word to a target word.
 * @returns updated word.
 */
const pairWord = (
  word: TWordsComparingExerciseParsedWord,
  selectedLinkedWord: TWordsComparingExerciseParsedLinkedWord
): TWordsComparingExerciseParsedWord => ({
  ...word,
  attempts: [...word.attempts, selectedLinkedWord.id],
  currentAttempt: word.currentAttempt + 1,
  selectedLinkedWordId: selectedLinkedWord.id,
  isPairSelected: true,
  isPairCorrect: word.correctLinkedWordId === selectedLinkedWord.id,
});

/**
 * Helper function that allows to update a word data when pair was unpaired.
 * @param word word data that should be updated.
 * @returns updated word.
 */
const unpairWord = (
  word: TWordsComparingExerciseParsedWord
): TWordsComparingExerciseParsedWord => ({
  ...word,
  selectedLinkedWordId: null,
  isPairSelected: false,
  isPairCorrect: false,
});

/**
 * Helper function that allows to update a linked word data when new pair word was selected.
 * @param word linked word data that should be updated.
 * @param selectedWord new selected pair word to a target linked word.
 * @returns updated linked word.
 */
const pairLinkedWord = (
  word: TWordsComparingExerciseParsedLinkedWord,
  selectedWord: TWordsComparingExerciseParsedWord
): TWordsComparingExerciseParsedLinkedWord => ({
  ...word,
  isPairSelected: true,
  isTouched: true,
  isPairCorrect: word.correctWordId === selectedWord.id,
  selectedWordId: selectedWord.id,
});

/**
 * Helper function that allows to update a linked word data when pair was unpaired.
 * @param word linked word data that should be updated.
 * @returns updated linked word.
 */
const unpairLinkedWord = (
  word: TWordsComparingExerciseParsedLinkedWord
): TWordsComparingExerciseParsedLinkedWord => ({
  ...word,
  isPairSelected: false,
  isPairCorrect: false,
  selectedWordId: null,
});

/**
 * Helper function that allows to update words data on a new pair assignment.
 * @param words array of words data to update.
 * @param selectedWordId word ID that should add a new pair.
 * @param selectedLinkedWord new selected pair linked word to a target word.
 * @returns updated array of words.
 */
export const updateWords = (
  words: TWordsComparingExerciseParsedWord[],
  selectedWordId: TExerciseIdentifier,
  selectedLinkedWord: TWordsComparingExerciseParsedLinkedWord
): TWordsComparingExerciseParsedWord[] =>
  words.map((word) =>
    word.id === selectedWordId
      ? pairWord(word, selectedLinkedWord)
      : word.selectedLinkedWordId === selectedLinkedWord.id
      ? unpairWord(word)
      : word
  );

/**
 * Helper function that allows to update linked words data on a new pair assignment.
 * @param linkedWords array of linked words data to update.
 * @param selectedLinkedWordId linked word ID that should add a new pair.
 * @param selectedWord new selected pair word to a target linked word.
 * @returns updated array of linked words.
 */
export const updateLinkedWords = (
  linkedWords: TWordsComparingExerciseParsedLinkedWord[],
  selectedLinkedWordId: TExerciseIdentifier,
  selectedWord: TWordsComparingExerciseParsedWord
): TWordsComparingExerciseParsedLinkedWord[] =>
  linkedWords.map((word) =>
    word.id === selectedLinkedWordId
      ? pairLinkedWord(word, selectedWord)
      : word.selectedWordId === selectedWord.id
      ? unpairLinkedWord(word)
      : word
  );
