import cx from "classnames";
import { OptionProps, components } from "react-select";
import { TSelectOption } from "types/app/select";
import "./Option.styles.scss";

const highlightOption = (
  optionText: string,
  inputText: string
): React.ReactElement => {
  const SEPARATOR = "~~";
  const regex = new RegExp(inputText, "gi");
  const replaced = optionText.replace(
    regex,
    (str) => `${SEPARATOR}${str}${SEPARATOR}`
  );
  const split = replaced.split(SEPARATOR);
  const result = split.map((substring, index) =>
    substring.toLowerCase() === inputText.toLowerCase() ? (
      <mark
        key={`${substring}-${index}`}
        className="nb-interactive-search-option--highlighted"
      >
        {substring}
      </mark>
    ) : (
      <span key={`${substring}-${index}`}>{substring}</span>
    )
  );

  return <>{result}</>;
};

export const Option = <
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>({
  isFocused,
  isSelected,
  children,
  ...optionProps
}: OptionProps<Option, false>): JSX.Element => {
  return (
    <components.Option
      {...optionProps}
      isFocused={isFocused}
      isSelected={isSelected}
      className={cx([
        "nb-interactive-search-option",
        {
          "nb-interactive-search-option--focused": isFocused,
          "nb-interactive-search-option--searched": isSelected,
        },
      ])}
    >
      {typeof children === "string"
        ? highlightOption(children, optionProps.selectProps.inputValue)
        : children}
    </components.Option>
  );
};
