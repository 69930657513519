import {
  ArrayPath,
  Control,
  FieldArrayWithId,
  FieldErrorsImpl,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { Space } from "components/Common/Space";
import { AssertionItem } from "../AssertionItem";
import { TAssertionSentencesDefaultValue } from "../AssertionSentences.types";
import "./AssertionList.styles.scss";

type TAssertionListProps<
  TFormValues extends TAssertionSentencesDefaultValue = TAssertionSentencesDefaultValue
> = {
  /**
   * Name attribute of the `AssertionSentences` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: ArrayPath<TFormValues>;
  /**
   * This object contains the defaultValue and key of the `react-hook-form` fields.
   */
  fields: FieldArrayWithId<TFormValues, ArrayPath<TFormValues>, "id">[];
  /**
   * The short hint displayed in the assertion `input` before the user enters a value.
   */
  assertionPlaceholder?: string;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (assertionIndex: number) => void;
};

export const AssertionList = <
  TFormValues extends TAssertionSentencesDefaultValue = TAssertionSentencesDefaultValue
>(
  props: TAssertionListProps<TFormValues>
): JSX.Element => {
  const {
    name,
    control,
    register,
    fields,
    assertionPlaceholder,
    errors,
    onDelete,
  } = props;

  return (
    <Space direction="vertical" justify="start" size="x-small" fullWidth>
      {fields.map((item, index, self) => (
        <AssertionItem<TFormValues>
          key={item.id}
          id={item.id}
          index={index}
          name={`${name}.${index}` as Path<TFormValues>}
          placeholder={assertionPlaceholder}
          disableDelete={self.length === 1}
          register={register}
          control={control}
          errors={errors}
          onDelete={() => onDelete?.(index)}
        />
      ))}
    </Space>
  );
};
