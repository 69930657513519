import { useQuery } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { getCourseLessonChapters } from "api/services/courses";
import { logError } from "utils/notifications";
import { TCourseLessonChapter } from "types/api/course";
import { TApiError } from "types/api/error";

export const useCourseLessonChapters = (
  courseId?: string | number,
  lessonId?: string | number
) => {
  return useQuery<TCourseLessonChapter[], TApiError>(
    [ECoursesQueryKeys.getAllCourseLessonChapters, courseId, lessonId],
    () => getCourseLessonChapters(courseId, lessonId),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: courseId !== undefined && lessonId !== undefined,
    }
  );
};
