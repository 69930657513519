import { useState } from "react";
import { ContentModal } from "components/Common/ContentModal";
import { Tabs } from "components/Interactive/Tabs";
import { AddEventForm } from "../AddEventFrom";
import { ScheduleContent } from "content";
import { ELessonType } from "types/api/lesson";
import { TTabItem } from "types/app/tabs";
import { EScheduleAddFormStep } from "types/app/schedule";
import "./AddEventModal.styles.scss";

type AddEventModalProps = {
  /**
   * Selected teacher ID which comes from `ScheduleControls`.
   */
  teacherId?: number;
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Start date of targeted schedule event.
   */
  startDate?: Date;
  /**
   * End date of targeted schedule event.
   */
  endDate?: Date;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: () => void;
};

export const AddEventModal: React.FC<AddEventModalProps> = (props) => {
  const { open = false, startDate, endDate, teacherId, onClose } = props;

  const [currentTab, setCurrentTab] = useState<ELessonType>(ELessonType.SINGLE);
  const [currentStep, setCurrentStep] = useState<EScheduleAddFormStep>(
    EScheduleAddFormStep.FIRST
  );
  const [isTabsVisible, setIsTabsVisible] = useState<boolean>(true);

  const tabItems: TTabItem<ELessonType>[] = [
    {
      key: ELessonType.SINGLE,
      label: ScheduleContent.Add.Tabs.Individual.TITLE,
    },
    {
      key: ELessonType.GROUP,
      label: ScheduleContent.Add.Tabs.Group.TITLE,
    },
  ];

  const tabChangeHandler = (activeTab: ELessonType) => {
    setCurrentStep(EScheduleAddFormStep.FIRST);
    setCurrentTab(activeTab);
  };

  const hideTabsVisibilityHandler = () => {
    setIsTabsVisible(false);
    setCurrentStep(EScheduleAddFormStep.SECOND);
  };

  const resetModalStates = () => {
    setCurrentTab(ELessonType.SINGLE);
    setCurrentStep(EScheduleAddFormStep.FIRST);
    setIsTabsVisible(true);
    onClose?.();
  };

  const formSubmitHandler = () => {
    resetModalStates();
  };

  return (
    <ContentModal
      portalId="add-schedule-event-modal"
      footerId="add-schedule-event-modal-footer"
      open={open}
      onClose={resetModalStates}
      title={ScheduleContent.Add.TITLE}
      emoji={ScheduleContent.Add.EMOJI}
    >
      {isTabsVisible && (
        <Tabs<ELessonType>
          items={tabItems}
          activeTabKey={currentTab}
          onChange={tabChangeHandler}
        />
      )}
      <AddEventForm
        formStep={currentStep}
        lessonType={currentTab}
        teacherId={teacherId}
        startDate={startDate}
        endDate={endDate}
        onNextClick={hideTabsVisibilityHandler}
        onSubmit={formSubmitHandler}
      />
    </ContentModal>
  );
};
