import { GroupsContent } from "content";
import { ContentModal } from "components/Common/ContentModal";
import { AddGroupForm } from "../AddGroupForm";
import "./AddGroupModal.styles.scss";

type AddGroupModalProps = {
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
};

export const AddGroupModal: React.FC<AddGroupModalProps> = (props) => {
  const { open = false, onClose } = props;

  return (
    <ContentModal
      portalId="add-group-modal"
      footerId="add-group-modal-footer"
      open={open}
      title={GroupsContent.Add.TITLE}
      emoji={GroupsContent.Add.EMOJI}
      onClose={onClose}
    >
      <AddGroupForm onSubmit={onClose} />
    </ContentModal>
  );
};
