import { Fragment } from "react";
import {
  ArrayPath,
  FieldArrayWithId,
  FieldErrorsImpl,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { ColumnItem } from "../ColumnItem";
import { TColumnInputsDefaultValues } from "../ColumnInputs.types";
import "./ColumnList.styles.scss";

type TColumnListProps<
  TFormValues extends TColumnInputsDefaultValues = TColumnInputsDefaultValues
> = {
  /**
   * Name attribute of the `ColumnInputs` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: ArrayPath<TFormValues>;
  /**
   * This object contains the defaultValue and key of the `react-hook-form` fields.
   */
  fields: FieldArrayWithId<TFormValues, ArrayPath<TFormValues>, "id">[];
  /**
   * The minimum number of columns that the user can add.
   * @default 1
   */
  minColumns?: number;
  /**
   * Label text for the column name input.
   */
  columnNameInputLabel?: string;
  /**
   * The short hint displayed in the column name input before the user enters a value.
   */
  columnNameInputPlaceholder?: string;
  /**
   * Label text for the column value input.
   */
  columnValueInputLabel?: string;
  /**
   * The short hint displayed in the column value input before the user enters a value.
   */
  columnValueInputPlaceholder?: string;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (assertionIndex: number) => void;
};

export const ColumnList = <
  TFormValues extends TColumnInputsDefaultValues = TColumnInputsDefaultValues
>(
  props: TColumnListProps<TFormValues>
): JSX.Element => {
  const {
    name,
    register,
    fields,
    minColumns = 1,
    columnNameInputLabel,
    columnNameInputPlaceholder,
    columnValueInputLabel,
    columnValueInputPlaceholder,
    errors,
    onDelete,
  } = props;

  return (
    <Space direction="vertical" justify="start" fullWidth>
      {fields.map((item, index, self) => (
        <Fragment key={item.id}>
          <ColumnItem
            id={item.id}
            index={index}
            name={`${name}.${index}` as Path<TFormValues>}
            columnNameInputLabel={columnNameInputLabel}
            columnNameInputPlaceholder={columnNameInputPlaceholder}
            columnValueInputLabel={columnValueInputLabel}
            columnValueInputPlaceholder={columnValueInputPlaceholder}
            disableDelete={self.length === minColumns}
            register={register}
            errors={errors}
            onDelete={() => onDelete?.(index)}
          />
          {self.length !== index + 1 && (
            <Divider margin="yAxis" marginSize="large" />
          )}
        </Fragment>
      ))}
    </Space>
  );
};
