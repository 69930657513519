export const Groups = {
  Name: "Групи",
  Emoji: "👩",
  Search: {
    PLACEHOLDER: "Пошук по групам",
    NO_OPTIONS: "Груп не знайдено",
  },
  Table: {
    Header: {
      NUMBER: "№",
      AVATAR: "Фото",
      NAME: "Назва групи",
      PARTICIPANTS: "Учасники",
      ACTION: "Дії",
    },
    Button: {
      CLASSROOM: "До класу",
      DELETE: "Видалити",
    },
  },
  Empty: "Список груп порожній. Натисніть “Додати групу”, щоб почати роботу 💪",
  Delete: {
    TITLE: "Ви дійсно хочете видалити групу?",
    EMOJI: "😥",
    Notification: {
      SUCCESS: "Групу успішно видалено",
      ERROR: "Не вдалося видалити групу",
    },
  },
  Add: {
    TITLE: "Нова група",
    EMOJI: "👫",
    SUBMIT: "Додати групу",
    Notification: {
      SUCCESS: "Групу успішно додано",
      ERROR: "Не вдалось додати групу",
    },
    Input: {
      Name: {
        LABEL: "Ім'я",
        PLACEHOLDER: "Кобзарі",
      },
      Teacher: {
        LABEL: "Викладач",
        PLACEHOLDER: "Оберіть викладача",
      },
      Language: {
        LABEL: "Мова",
        PLACEHOLDER: "Оберіть мову",
      },
      Level: {
        LABEL: "Рівень",
        PLACEHOLDER: "Оберіть рівень знань",
      },
      Participants: {
        LABEL: "Учасники",
        PLACEHOLDER: "Пошук по студентам",
      },
    },
  },
};
