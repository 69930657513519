import { useQuery } from "react-query";
import { fetchTeachersByLanguage } from "api/services/teachers";
import { ETeachersQueryKeys } from "api/endpoints/teachers";
import { logError } from "utils/notifications";
import { TTeachersResponse } from "types/api/teacher";
import { TApiError } from "types/api/error";

export const useTeachersByLanguage = (language: string) => {
  const shouldFetchOnLoad = language?.length > 0;

  return useQuery<TTeachersResponse, TApiError>(
    [ETeachersQueryKeys.getTeachersByLanguage, language],
    () => fetchTeachersByLanguage(language),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: shouldFetchOnLoad,
    }
  );
};
