import { useMutation, useQueryClient } from "react-query";
import { editProfile } from "api/services/profile";
import { EAuthQueryKeys } from "api/endpoints/auth";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TUser } from "types/api/user";

export const useEditProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<TUser, TApiError, TUser>(editProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(EAuthQueryKeys.getCurrentUser);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
