import { useMemo } from "react";
import { DragOverlay, defaultDropAnimation } from "@dnd-kit/core";
import { ReactPortal } from "components/Common/ReactPortal";
import { Draggable } from "../Draggable";
import {
  TExerciseIdentifier,
  TExerciseSentenceAnswer,
} from "types/app/exercises";
import "./DraggableOverlay.styles.scss";

type TDraggableOverlayProps = {
  /**
   * An array of all available answers to find the one that is dragged.
   */
  answers: TExerciseSentenceAnswer[];
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DraggableOverlay: React.FC<TDraggableOverlayProps> = (props) => {
  const { dragActiveId = null, answers, className } = props;

  const dragSelectedAnswer: TExerciseSentenceAnswer | null = useMemo(() => {
    if (!dragActiveId) {
      return null;
    }
    const answer = answers.find(({ id }) => id === dragActiveId);
    return answer || null;
  }, [dragActiveId, answers]);

  return (
    <ReactPortal wrapperId="draggable-item-overlay">
      <DragOverlay dropAnimation={defaultDropAnimation} className={className}>
        {dragSelectedAnswer && (
          <Draggable
            content={dragSelectedAnswer.content}
            dragging
            dragOverlay
          />
        )}
      </DragOverlay>
    </ReactPortal>
  );
};
