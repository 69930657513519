import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EAppReducers } from "../../white-list";
import { TLessonExerciseAnswerMode, TLessonViewMode } from "types/api/lesson";

export type TLessonState = {
  /**
   * Lesson view mode that indicates what elements to display.
   * @param constructor - lesson will have exercise action buttons
   * @param preview - lesson will have active exercises without ability to change them
   * @default "constructor"
   */
  viewMode: TLessonViewMode;
  /**
   * Lesson exercise answer mode that indicates how answers should be revealed.
   * @param read-only - exercise will not be interactive
   * @param live - answers will be revealed after the exercise is finished
   * @param preview - answers will be filled immediately
   * @default "live"
   */
  exerciseAnswerMode: TLessonExerciseAnswerMode;
};

const initialState: TLessonState = {
  viewMode: "constructor",
  exerciseAnswerMode: "live",
};

export const lessonSlices = createSlice({
  name: EAppReducers.LESSON,
  initialState,
  reducers: {
    setViewMode: (state, action: PayloadAction<TLessonViewMode>) => {
      state.viewMode = action.payload;
    },
    setExerciseAnswerMode: (
      state,
      action: PayloadAction<TLessonExerciseAnswerMode>
    ) => {
      state.exerciseAnswerMode = action.payload;
    },
    reset: (state) => {
      state.viewMode = initialState.viewMode;
      state.exerciseAnswerMode = initialState.exerciseAnswerMode;
    },
  },
});

export const lessonReducer = lessonSlices.reducer;
