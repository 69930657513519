import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const compareImageWithDescriptionExercise: TExerciseItem = {
  title: "З'єднати слова та зображення",
  backgroundText: "Слова та зображення",
  tags: [
    "З'єднати слова та зображення",
    "з'єднати",
    "порівняти",
    "зіставити",
    "опис",
    "описом",
    "description",
    "об'єднати",
    "приєднати",
    "слово",
    "слова",
    "та",
    "зображення",
    "картинку",
    "фото",
    "connect",
    "compare",
    "find",
    "words",
    "with",
    "image",
    "photo",
    "picture",
  ],
  type: EExerciseType.COMPARE_IMAGE_WITH_DESCRIPTION,
  cover: "connect-text-with-image.png",
};
