import { InputProps, components } from "react-select";
import { TPersonSelectOption } from "types/app/select";
import "./Input.styles.scss";

export const Input = <
  Option extends TPersonSelectOption = TPersonSelectOption
>({
  children,
  ...props
}: InputProps<Option>): JSX.Element => {
  return (
    <components.Input {...props} className="nb-interactive-person-select-input">
      {children}
    </components.Input>
  );
};
