import { PlaceholderProps, components } from "react-select";
import { TPersonSelectOption } from "types/app/select";
import "./Placeholder.styles.scss";

type CustomPlaceholderProps<
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
> = {
  placeholder: string;
} & PlaceholderProps<Option, IsMulti>;

export const Placeholder = <
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
>(
  props: CustomPlaceholderProps<Option, IsMulti>
): JSX.Element => {
  const { placeholder } = props;

  return (
    <components.Placeholder
      {...props}
      className="nb-interactive-person-select-placeholder"
    >
      {placeholder ? placeholder : props.children}
    </components.Placeholder>
  );
};
