import { Row } from "@tanstack/react-table";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import cx from "classnames";
import { TableCell } from "../TableCell";
import { TDefaultTableData, TTableRowClassnames } from "../Table.types";
import "./TableRow.styles.scss";

type TableRowProps<TData extends TDefaultTableData = TDefaultTableData> = {
  /**
   * Row object generated in `react-table` to operate with data and cells.
   */
  row: Row<TData>;
  /**
   * If `true` the `Table` rows can be reordered by drag'n'drop mechanics
   * and can be handled with `onDragComplete` event listener.
   *
   * @default false
   */
  allowRowReorder?: boolean;
  /**
   * Object for `Table` sub-components to override or extend the styles applied to the particular component.
   */
  className?: TTableRowClassnames;
};

export const TableRow = <TData extends TDefaultTableData = TDefaultTableData>(
  props: TableRowProps<TData>
): JSX.Element => {
  const { row, allowRowReorder = false, className } = props;

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({ id: row.original.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <tr
      ref={setNodeRef}
      className={cx([
        "nb-table-body-row",
        {
          "nb-table-body-row--dragging": isDragging,
        },
        className?.root,
      ])}
      style={style}
    >
      {isDragging ? (
        <td
          className="nb-table-body-row-cell-spacer"
          colSpan={row.getAllCells().length}
          aria-hidden
        />
      ) : (
        row
          .getVisibleCells()
          .map((cell, index) => (
            <TableCell<TData>
              key={cell.id}
              cell={cell}
              className={className?.cell}
              showDragIcon={allowRowReorder && index === 0}
              draggableAttributes={attributes}
              draggableListeners={listeners}
            />
          ))
      )}
    </tr>
  );
};
