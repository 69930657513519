import { OptionProps, components } from "react-select";
import cx from "classnames";
import { TAssertionSelectDefaultOption } from "../../../AssertionSentences.types";
import "./Option.styles.scss";

export const Option = <
  Option extends TAssertionSelectDefaultOption = TAssertionSelectDefaultOption
>({
  isFocused,
  isSelected,
  ...optionProps
}: OptionProps<Option, false>): JSX.Element => {
  return (
    <components.Option
      {...optionProps}
      isFocused={isFocused}
      isSelected={isSelected}
      className={cx([
        "nb-interactive-true-or-false-assertion-select-option",
        {
          "nb-interactive-true-or-false-assertion-select-option--focused":
            isFocused,
          "nb-interactive-true-or-false-assertion-select-option--selected":
            isSelected,
        },
      ])}
    />
  );
};
