import { SingleValueProps, components } from "react-select";
import cx from "classnames";
import { TSelectOption } from "types/app/select";
import "./SingleValue.styles.scss";

export const SingleValue = <
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>({
  children,
  ...props
}: SingleValueProps<Option, false>): JSX.Element => (
  <components.SingleValue
    {...props}
    className={cx(["nb-interactive-search-value"])}
  >
    {children}
  </components.SingleValue>
);
