import { useCallback, useState } from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import {
  ControlProps,
  GroupBase,
  InputActionMeta,
  MenuListProps,
  NoticeProps,
  OptionsOrGroups,
  PlaceholderProps,
  createFilter,
} from "react-select";
import AsyncSelect from "react-select/async";
import cx from "classnames";
import { PersonSelectControl } from "./components/Control";
import { PersonSelectOption } from "./components/Option";
import { PersonSelectNoOptionsMessage } from "./components/NoOptionsMessage";
import { PersonSelectLoadingMessage } from "./components/LoadingMessage";
import { PersonSelectPlaceholder } from "./components/Placeholder";
import { PersonSelectMenu } from "./components/Menu";
import { PersonSelectMenuList } from "./components/MenuList";
import { PersonSelectContainer } from "./components/Container";
import { PersonSelectInput } from "./components/Input";
import { PersonSelectSingleValue } from "./components/SingleValue";
import { PersonSelectContent, ValidationContent } from "content";
import type { TPersonSelectOption } from "types/app/select";
import "./PersonSelect.styles.scss";

type PersonSelectProps<
  TFormValues extends FieldValues = FieldValues,
  Option = TPersonSelectOption,
  IsMulti extends boolean = false
> = {
  /**
   * Name attribute of the `MultiSelect` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: FieldPath<TFormValues>;
  /**
   * 	Validation rules in the same format for register, which includes: required, min, max, minLength, maxLength, pattern, validate
   *
   * @example
   * ```tsx
   * rules={{
   *  pattern: {
   *    value: EMAIL_REGEX,
   *    message: "Email address is invalid",
   *  }
   * }}
   * ```
   * @link https://react-hook-form.com/api/useform/register#options
   */
  rules?: RegisterOptions;
  /**
   * 	An object with field errors. There is also an ErrorMessage component to retrieve error message easily.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * The default set of options to show before the user starts searching. When set to `true`, the results for searchCallback('') will be autoloaded.
   */
  defaultOptions?: boolean | OptionsOrGroups<Option, GroupBase<Option>>;
  /**
   * Function that returns a promise, which is the set of options to be used once the promise resolves.
   *
   * @remark Advice: Use debounce pattern for this callback for better performance.
   */
  searchCallback?: (searchValue: string) => Promise<Option[]>;
  /**
   * Callback function fires on select search input change.
   */
  onSearchChange?: (searchValue: string) => void;
  /**
   * Max number of visible options in `MenuList` component.
   *
   * @default {2}
   */
  size?: 1 | 2 | 3 | 4 | 5;
  /**
   * Is the select disabled
   *
   * @default {false}
   */
  disabled?: boolean;
  /**
   * Allow `Select` component multiple option select.
   *
   * @default {false}
   */
  multi?: IsMulti;
  /**
   * If `true`, the `Select` element is required.
   *
   * @default {false}
   */
  required?: boolean;
  /**
   * If `true`, the `select` will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the `Select` will always show options menu.
   *
   * @default false
   */
  menuAlwaysOpen?: boolean;
  /**
   * The short hint displayed in the `Select` before the user selects a value.
   */
  placeholder?: string;
  /**
   * Select label text for the `select` element.
   */
  label?: string;
  /**
   * Message to be displayed in the menu if there are no options available.
   */
  noOptionsText?: string;
  /**
   * Message to display in the menu when there are no options and loading state is true.
   */
  loadingText?: string;
  /**
   * The id of the `select` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * `PersonSelect` is an interactive element with multiple selected options and specific
 * option style based on `react-select` library in association with `react-hook-form`.
 */
export const PersonSelect = <
  TFormValues extends FieldValues = FieldValues,
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
>(
  props: PersonSelectProps<TFormValues, Option, IsMulti>
): JSX.Element => {
  const {
    disabled = false,
    required = false,
    fullWidth = false,
    menuAlwaysOpen = false,
    multi = false,
    defaultOptions = true,
    size = 2,
    noOptionsText = PersonSelectContent.DEFAULT.EMPTY,
    loadingText = PersonSelectContent.DEFAULT.LOADING,
    placeholder = PersonSelectContent.DEFAULT.PLACEHOLDER,
    id,
    label,
    name,
    rules,
    control,
    errors,
    className,
    searchCallback,
    onSearchChange,
  } = props;

  const targetError = errors?.[name];
  const hasError = !!(errors && targetError);

  const innerRules: RegisterOptions = {
    ...(required && { required: ValidationContent.Required }),
    ...rules,
    disabled,
  };

  const [inputValue, setInputValue] = useState<string>("");

  const promiseOptions = (searchValue: string): Promise<Option[]> => {
    if (!searchCallback) {
      return Promise.resolve([]);
    }

    return searchCallback(searchValue);
  };

  const innerSearchChangeHandler = (
    newValue: string,
    meta: InputActionMeta
  ) => {
    if (meta.action !== "input-blur" && meta.action !== "menu-close") {
      setInputValue(newValue);
      onSearchChange?.(newValue);
    }
  };

  const PersonSelectControlMemorized = useCallback(
    (controlProps: ControlProps<Option, IsMulti>) => (
      <PersonSelectControl<Option, IsMulti> {...controlProps} multi={multi} />
    ),
    [multi]
  );

  const PersonSelectNoOptionsMessageMemorized = useCallback(
    (noticeProps: NoticeProps<Option, IsMulti>) => (
      <PersonSelectNoOptionsMessage<Option, IsMulti>
        {...noticeProps}
        noOptionsText={noOptionsText}
      />
    ),
    [noOptionsText]
  );

  const PersonSelectLoadingMessageMemorized = useCallback(
    (noticeProps: NoticeProps<Option, IsMulti>) => (
      <PersonSelectLoadingMessage<Option, IsMulti>
        {...noticeProps}
        loadingText={loadingText}
      />
    ),
    [loadingText]
  );

  const PersonSelectPlaceholderMemorized = useCallback(
    (noticeProps: PlaceholderProps<Option, IsMulti>) => (
      <PersonSelectPlaceholder<Option, IsMulti>
        {...noticeProps}
        placeholder={placeholder}
      />
    ),
    [placeholder]
  );

  const PersonSelectMenuListMemorized = useCallback(
    (menuListProps: MenuListProps<Option, IsMulti>) => (
      <PersonSelectMenuList<Option, IsMulti> {...menuListProps} size={size} />
    ),
    [size]
  );

  return (
    <div
      className={cx(["nb-interactive-person-select-wrapper", className])}
      aria-live="polite"
    >
      {label && (
        <label className="nb-interactive-person-select-label" htmlFor={id}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={innerRules}
        render={({ field }) => (
          <AsyncSelect
            {...field}
            value={field.value as Option}
            isDisabled={disabled}
            placeholder={placeholder}
            required={required}
            /**
             * We need to pass here OR statement with `undefined`,
             * because `undefined` will be threated like default select behavior and `boolean` like outer-control.
             */
            menuIsOpen={menuAlwaysOpen || undefined}
            isMulti={multi}
            isSearchable
            cacheOptions
            defaultOptions={defaultOptions}
            isClearable={false}
            backspaceRemovesValue={false}
            controlShouldRenderValue={!multi}
            id={id}
            getOptionValue={(option) => option.id.toString()}
            loadOptions={promiseOptions}
            filterOption={createFilter({
              ignoreCase: true,
              ignoreAccents: true,
              matchFrom: "any",
              stringify: (option) => option.data.name,
            })}
            inputValue={inputValue}
            onInputChange={innerSearchChangeHandler}
            className={cx([
              "nb-interactive-person-select",
              {
                "nb-interactive-person-select--full": fullWidth,
              },
            ])}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
              MultiValueContainer: () => null,
              MultiValue: () => null,
              MultiValueLabel: () => null,
              MultiValueRemove: () => null,
              Control: PersonSelectControlMemorized,
              Option: PersonSelectOption,
              NoOptionsMessage: PersonSelectNoOptionsMessageMemorized,
              LoadingMessage: PersonSelectLoadingMessageMemorized,
              Placeholder: PersonSelectPlaceholderMemorized,
              Menu: PersonSelectMenu,
              MenuList: PersonSelectMenuListMemorized,
              SelectContainer: PersonSelectContainer,
              Input: PersonSelectInput,
              SingleValue: PersonSelectSingleValue,
            }}
          />
        )}
      />
      {hasError && (
        <small className="nb-interactive-person-select-error-text">
          {targetError?.message?.toString()}
        </small>
      )}
    </div>
  );
};
