import { useEffect, useMemo, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { format } from "date-fns";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { useScheduleDates } from "hooks/redux";
import { useScheduleLessons } from "hooks/api/schedule";
import { useLazyTimeout } from "hooks/common";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { Space } from "components/Common/Space";
import { IconButton } from "components/Interactive/IconButton";
import { Text } from "components/Typography/Text";
import { LessonCreateTimeRange } from "constants/lesson";
import { adaptStudentScheduleEventData } from "./StudentSchedule.helpers";
import { TStudent } from "types/api/student";
import { TScheduleEvent } from "types/app/schedule";
import "./StudentSchedule.styles.scss";

type TStudentScheduleProps = {
  /**
   * Selected student data to show the schedule for.
   */
  student?: TStudent;
  /**
   * If `true`, the modal with calendar is shown.
   * @default false
   */
  open?: boolean;
  /**
   * If `true`, the student data is loading.
   * @default false
   */
  loading?: boolean;
};

export const StudentSchedule: React.FC<TStudentScheduleProps> = (props) => {
  const { student, open = false } = props;

  const calendarRef = useRef<FullCalendar | null>(null);

  const animationDelay = useLazyTimeout(200);

  const { activeUser } = useActiveUser();
  const { queryStartDate, queryEndDate } = useScheduleDates();
  const { data: scheduleLessonsData } = useScheduleLessons({
    start: queryStartDate,
    end: queryEndDate,
    teacherId: activeUser?.teacherId,
    studentId: student?.id,
  });

  const events: TScheduleEvent[] = useMemo(
    () => adaptStudentScheduleEventData(scheduleLessonsData, student),
    [scheduleLessonsData, student]
  );

  useEffect(() => {
    if (open) {
      /**
       * Workaround for the calendar not being rendered properly
       * when it's inside the modal.
       * (Modal opens with transition so transform scale messes up the calendar width)
       * It still causes ugly calendar shifts, but it's better than nothing.
       * TODO: Find a better solution.
       */
      animationDelay(() => {
        calendarRef.current?.getApi().updateSize();
      });
    }
  }, [open, animationDelay]);

  const previousWeekButtonClickHandler = () => {
    calendarRef.current?.getApi().prev();
  };

  const nextWeekButtonClickHandler = () => {
    calendarRef.current?.getApi().next();
  };

  return (
    <div className="nb-sub-study-student-schedule-container">
      <FullCalendar
        /** Config */
        plugins={[timeGridPlugin, interactionPlugin]}
        selectable
        editable
        droppable={false}
        headerToolbar={false}
        allDaySlot={false}
        dragScroll={false}
        eventStartEditable={false}
        eventResizableFromStart={false}
        eventDurationEditable={false}
        ref={calendarRef}
        /** Locale */
        locale="uk"
        timeZone="Europe/Kiev"
        weekNumberCalculation="ISO"
        /** Slot Options */
        slotMinTime={{ hour: LessonCreateTimeRange.MIN }}
        slotMaxTime={{ hour: LessonCreateTimeRange.MAX }}
        slotLabelInterval={{ hour: 1 }}
        slotDuration={{ hour: 1 }}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
        }}
        /** Styles */

        slotMinWidth={75}
        eventMinHeight={41}
        viewClassNames="nb-sub-study-student-schedule-view"
        dayHeaderClassNames="nb-sub-study-student-schedule-day-header"
        slotLaneClassNames="nb-sub-study-student-schedule-slot-lane"
        slotLabelClassNames="nb-sub-study-student-schedule-slot-label"
        dayCellClassNames="nb-sub-study-student-schedule-day-cell"
        eventClassNames="nb-sub-study-student-schedule-event"
        /** View */
        initialView="timeGridWeek"
        contentHeight="auto"
        /** Data */
        initialEvents={[]}
        events={events}
        /** Events */
        // Add events here if needed
        /** Content */
        dayHeaderContent={({ date, dow }) => (
          <Space
            direction="horizontal"
            justify="center"
            align="center"
            fullWidth
            className="nb-sub-study-student-schedule-day-header-container"
          >
            {dow === 1 && (
              <IconButton
                icon={<Chevron />}
                variant="tertiary"
                className="nb-sub-study-student-schedule-day-header-button--prev"
                onClick={previousWeekButtonClickHandler}
              />
            )}
            <Text
              variant="body2"
              className="nb-sub-study-student-schedule-day-header-label"
            >
              {format(date, "EEEEEE")}&nbsp;{format(date, "dd.LL")}
            </Text>
            {dow === 0 && (
              <IconButton
                icon={<Chevron />}
                variant="tertiary"
                className="nb-sub-study-student-schedule-day-header-button--next"
                onClick={nextWeekButtonClickHandler}
              />
            )}
          </Space>
        )}
        slotLabelContent={({ text }) => (
          <Text
            variant="body2"
            className="nb-sub-study-student-schedule-slot-label-text"
          >
            {text}
          </Text>
        )}
        eventContent={({ event }) => (
          <Text
            variant="body2"
            bold
            className="nb-sub-study-student-schedule-event-text"
          >
            {event.title}
          </Text>
        )}
      />
    </div>
  );
};
