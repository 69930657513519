import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { SortWordsExerciseContent } from "content";
import { Space } from "components/Common/Space";
import { Paragraph } from "components/Typography/Paragraph";
import { DraggableItem } from "../DraggableItem";
import { DraggableItemPlaceholder } from "../DraggableItemPlaceholder";
import { START_ZONE_ID } from "../SortWords.helpers";
import {
  TExerciseIdentifier,
  TSortWordsExerciseParsedWord,
} from "types/app/exercises";
import "./DraggableStartZone.styles.scss";

type TDraggableStartZoneProps = {
  /**
   * An array of words to display in the start zone area.
   */
  words?: TSortWordsExerciseParsedWord[];
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DraggableStartZone: React.FC<TDraggableStartZoneProps> = (
  props
) => {
  const { words = [], dragActiveId = null } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: START_ZONE_ID,
  });

  const isEmpty: boolean = words.length === 0;

  return (
    <Space
      ref={setNodeRef}
      direction="horizontal"
      align="center"
      justify={isEmpty ? "center" : "start"}
      size="medium"
      fullWidth
      wrap
      className={cx([
        "nb-exercise-sort-words-draggable-start-zone",
        { "nb-exercise-sort-words-draggable-start-zone--over": isOver },
      ])}
    >
      {isEmpty && (
        <Paragraph variant={2}>
          {SortWordsExerciseContent.StartZone.EMPTY}
        </Paragraph>
      )}
      {words.map((word) =>
        word.id === dragActiveId ? (
          <DraggableItemPlaceholder key={word.id} content={word.content} />
        ) : (
          <DraggableItem key={word.id} word={word} />
        )
      )}
    </Space>
  );
};
