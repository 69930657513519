import { Paragraph } from "components/Typography/Paragraph";
import { TCourse } from "types/api/course";
import "./MaterialsCoverItem.styles.scss";

type TMaterialsCoverItemProps = {
  /**
   * Course data to display list item card.
   */
  course: TCourse;
  /**
   * Callback fired when the list item is clicked.
   */
  onClick?: (id: number) => void;
};

export const MaterialsCoverItem: React.FC<TMaterialsCoverItemProps> = (
  props
) => {
  const { course, onClick } = props;

  const courseCoverClickHandler = () => {
    onClick?.(course.id);
  };

  return (
    <li className="nb-courses-materials-cover-item">
      <button
        type="button"
        className="nb-courses-materials-cover-item-button"
        onClick={courseCoverClickHandler}
        aria-label="Перейти до курсу"
      >
        <div className="nb-courses-materials-cover-item-poster">
          {course.imageUrl ? (
            <img
              src={course.imageUrl}
              width="171"
              height="219"
              alt={`Обкладинка курсу ${course.name}`}
              className="nb-courses-materials-cover-item-image"
            />
          ) : (
            <span className="nb-courses-materials-cover-item-emoji">🚀</span>
          )}
        </div>
        <Paragraph bold variant={2}>
          {course.name}
        </Paragraph>
      </button>
    </li>
  );
};
