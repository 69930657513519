import { useState } from "react";
import {
  ArrayPath,
  Control,
  FieldArray,
  FieldErrorsImpl,
  UseFormRegister,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { ImageListUploadContent } from "content";
import { useModal } from "hooks/common";
import { useImageUpload } from "hooks/redux";
import { Space } from "components/Common/Space";
import { InputLabel } from "components/Common/InputLabel";
import { Button } from "components/Interactive/Button";
import { ImageUploadModal } from "components/Interactive/ImageUploadModal";
import { ImageList } from "./ImageList";
import { convertBlobToBase64String } from "utils/common";
import { getImageListUploadInitialValue } from "./ImageListUpload.helpers";
import { TImageListUploadDefaultValue } from "./ImageListUpload.types";
import "./ImageListUpload.styles.scss";

type TImageListUploadProps<
  TFormValues extends TImageListUploadDefaultValue = TImageListUploadDefaultValue
> = {
  /**
   * Name attribute of the `ImageListUpload` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: ArrayPath<TFormValues>;
  /**
   * Label text for the `ImageListUpload` component.
   */
  label?: string;
  /**
   * The text hint displayed as tooltip popup right to the label, which looks like a question icon button. Hint will appear when user clicks on the hint button.
   */
  hint?: string;
  /**
   * If `true`, the `ImageListUpload` will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the description field will be required to fill.
   * @default false
   */
  descriptionRequired?: boolean;
  /**
   * The content of the add image button.
   */
  addImageButtonText?: string;
  /**
   * The content of the pick image button.
   */
  pickImageButtonText?: string;
  /**
   * The content of the change image button.
   */
  changeImageButtonText?: string;
  /**
   * The short hint displayed in the description `textarea` before the user enters a value.
   */
  descriptionPlaceholder?: string;
  /**
   * The minimum number of images allowed to upload.
   * @default 1
   */
  minImagesAllowed?: number;
  /**
   * If `true`, the image cropper will show up after user selects file.
   * @remark Cropper wont appear if user selected non valid image MIME type.
   * To prevent that, please provide appropriate `accept` prop.
   * @default false
   */
  withImageCropper?: boolean;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const ImageListUpload = <
  TFormValues extends TImageListUploadDefaultValue = TImageListUploadDefaultValue
>(
  props: TImageListUploadProps<TFormValues>
): JSX.Element => {
  const {
    name,
    label,
    hint,
    fullWidth = false,
    descriptionRequired = false,
    withImageCropper = false,
    minImagesAllowed = 1,
    addImageButtonText = ImageListUploadContent.Default.ADD_IMAGE_BUTTON,
    pickImageButtonText = ImageListUploadContent.Default.PICK_IMAGE_BUTTON,
    changeImageButtonText = ImageListUploadContent.Default.CHANGE_IMAGE_BUTTON,
    descriptionPlaceholder = ImageListUploadContent.Default
      .IMAGE_DESCRIPTION_PLACEHOLDER,
    className,
    control,
    register,
    errors,
  } = props;

  const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);

  const { isModalOpen, openModal, closeModal } = useModal(false);

  const { resetAllImageUploadStates } = useImageUpload();

  const { fields, remove, append, update } = useFieldArray<TFormValues>({
    control,
    name,
  });
  const { images } = useWatch<TFormValues>({
    control,
  });

  const addImageClickHandler = () => {
    append(
      getImageListUploadInitialValue() as FieldArray<
        TFormValues,
        ArrayPath<TFormValues>
      >
    );
  };

  const removeImageClickHandler = (imageIndex: number) => {
    resetAllImageUploadStates();
    remove(imageIndex);
  };

  const openModalClickHandler = (imageIndex: number) => {
    openModal();
    setActiveImageIndex(imageIndex);
  };

  const closeModalClickHandler = () => {
    closeModal();
    setActiveImageIndex(null);
    resetAllImageUploadStates();
  };

  const imageUploadHandler = (imageBlob: Blob) => {
    if (activeImageIndex === null || !images) {
      return;
    }
    const targetImage = images?.[activeImageIndex];
    convertBlobToBase64String(imageBlob)
      .then((imageEncoded) =>
        update(activeImageIndex, { ...targetImage, imageEncoded } as FieldArray<
          TFormValues,
          ArrayPath<TFormValues>
        >)
      )
      .then(closeModalClickHandler);
  };

  return (
    <Space
      direction="vertical"
      justify="start"
      size="x-small"
      fullWidth={fullWidth}
      className={className}
    >
      <InputLabel label={label} hint={hint} />
      <ImageList<TFormValues>
        name={name}
        fields={fields}
        control={control}
        register={register}
        errors={errors}
        minImagesAllowed={minImagesAllowed}
        pickImageButtonText={pickImageButtonText}
        changeImageButtonText={changeImageButtonText}
        descriptionPlaceholder={descriptionPlaceholder}
        descriptionRequired={descriptionRequired}
        onAddModalOpen={openModalClickHandler}
        onDelete={removeImageClickHandler}
      />
      <Button variant="secondary" fullWidth onClick={addImageClickHandler}>
        {addImageButtonText}
      </Button>
      <ImageUploadModal
        open={isModalOpen}
        aspect={16 / 9}
        zoomSpeed={0.5}
        accept="image/jpeg, image/png, image/jpg, image/webp"
        id="image-list-upload-input"
        name="image-list-upload-input"
        portalId="image-list-upload-modal"
        withUploadButton
        withImageCropper={withImageCropper}
        title={ImageListUploadContent.Modal.TITLE}
        emoji={ImageListUploadContent.Modal.EMOJI}
        selectButtonText={ImageListUploadContent.Modal.Button.SELECT}
        uploadButtonText={ImageListUploadContent.Modal.Button.UPLOAD}
        inputLabel={ImageListUploadContent.Modal.Input.LABEL}
        onClose={closeModal}
        onUpload={imageUploadHandler}
        className="nb-interactive-image-list-upload-modal"
      />
    </Space>
  );
};
