import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useLanguage } from "hooks/api/language";
import { useEditTeacher } from "hooks/api/teachers";
import { Avatar } from "components/Common/Avatar";
import { ReactPortal } from "components/Common/ReactPortal";
import { Input } from "components/Interactive/Input";
import { Select } from "components/Interactive/Select";
import { Button } from "components/Interactive/Button";
import { TeachersContent, ValidationContent } from "content";
import { logSuccess } from "utils/notifications";
import { EMAIL_REGEX } from "constants/validation";
import { TTeacher } from "types/api/teacher";
import { TSelectOption } from "types/app/select";
import { TEditTeacherForm } from "./EditTeacherForm.types";
import "./EditTeacherForm.styles.scss";

type EditTeacherFormProps = {
  targetTeacher: TTeacher | null;
  className?: string;
  onSubmit?: () => void;
};

export const EditTeacherForm: React.FC<EditTeacherFormProps> = (props) => {
  const { className, targetTeacher, onSubmit } = props;

  const idRef = useRef<string>(nanoid());

  const { data: languageData } = useLanguage();
  const { mutate } = useEditTeacher();

  const languageOptions: TSelectOption[] =
    languageData?.map((lang) => ({
      label: lang,
      value: lang,
    })) || [];

  const defaultValues = useMemo(
    () => ({
      firstName: targetTeacher?.person.firstName,
      lastName: targetTeacher?.person.lastName,
      email: targetTeacher?.person.email,
      phone: targetTeacher?.person.phone,
      ...(targetTeacher?.person.language && {
        language: {
          label: targetTeacher.person.language,
          value: targetTeacher.person.language,
        },
      }),
    }),
    [targetTeacher]
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TEditTeacherForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleEditTeacherSubmit = (formData: TEditTeacherForm) => {
    if (!targetTeacher) {
      return;
    }
    const teacher = {
      id: targetTeacher.id,
      person: {
        ...targetTeacher.person,
        ...formData,
        language: formData.language.value,
      },
    };

    mutate(teacher, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(TeachersContent.Edit.Notification.SUCCESS);
        reset();
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-edit-teacher-form", className])}
      onSubmit={handleSubmit(handleEditTeacherSubmit)}
    >
      <div className="nb-sub-edit-teacher-avatar-wrapper">
        <Avatar bordered size="large" />
      </div>
      <Input<TEditTeacherForm>
        name="firstName"
        id="edit-teacher-first-name"
        label={TeachersContent.Edit.Input.FirstName.LABEL}
        placeholder={TeachersContent.Edit.Input.FirstName.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Input<TEditTeacherForm>
        name="lastName"
        id="edit-teacher-last-name"
        label={TeachersContent.Edit.Input.LastName.LABEL}
        placeholder={TeachersContent.Edit.Input.LastName.PLACEHOLDER}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <Input<TEditTeacherForm>
        name="email"
        type="email"
        id="edit-teacher-email"
        label={TeachersContent.Edit.Input.Email.LABEL}
        placeholder={TeachersContent.Edit.Input.Email.PLACEHOLDER}
        register={register}
        errors={errors}
        rules={{
          pattern: {
            value: EMAIL_REGEX,
            message: ValidationContent.Pattern.Email,
          },
        }}
        required
        fullWidth
      />
      <Input<TEditTeacherForm>
        name="phone"
        type="tel"
        id="edit-teacher-phone"
        label={TeachersContent.Edit.Input.Phone.LABEL}
        placeholder={TeachersContent.Edit.Input.Phone.PLACEHOLDER}
        minLength={12}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <Select<TEditTeacherForm, TSelectOption>
        name="language"
        id="edit-teacher-language"
        label={TeachersContent.Edit.Input.Language.LABEL}
        placeholder={TeachersContent.Edit.Input.Language.PLACEHOLDER}
        options={languageOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <ReactPortal wrapperId="edit-teacher-modal-footer">
        <Button
          className="nb-sub-edit-teacher-submit"
          variant="primary"
          type="submit"
          form={idRef.current}
          fullWidth
        >
          {TeachersContent.Edit.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
