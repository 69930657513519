import { RequestsContent } from "content";
import { ERequestStatus, ERequestStudyMethod } from "types/api/requests";
import { TSelectOption } from "types/app/select";

export const REQUEST_STUDY_METHOD_OPTIONS: TSelectOption<ERequestStudyMethod>[] =
  [
    {
      label: RequestsContent.Option.INDIVIDUAL,
      value: ERequestStudyMethod.INDIVIDUAL,
    },
    {
      label: RequestsContent.Option.GROUP,
      value: ERequestStudyMethod.GROUP,
    },
  ];

export const REQUEST_STATUS_OPTIONS: TSelectOption<ERequestStatus>[] = [
  {
    label: RequestsContent.Option.NEW,
    value: ERequestStatus.NEW,
  },
  {
    label: RequestsContent.Option.PROCESSED,
    value: ERequestStatus.PROCESSED,
  },
];
