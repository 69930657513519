import { useEffect, useMemo, useState } from "react";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { SentenceItem } from "../SentenceItem";
import {
  TChooseRightWordExerciseBlank,
  TExerciseSentence,
} from "types/app/exercises";
import "./SentenceList.styles.scss";

type TSentenceListProps = {
  /**
   * An array of sentence data to construct an exercise.
   */
  sentences: TExerciseSentence<TChooseRightWordExerciseBlank>[];
};

export const SentenceList: React.FC<TSentenceListProps> = (props) => {
  const { sentences } = props;

  const [innerSentences, setInnerSentences] =
    useState<TExerciseSentence<TChooseRightWordExerciseBlank>[]>(sentences);

  const { isExercisePreviewMode, isExerciseLiveMode } = useLessonSettings();

  useEffect(() => {
    if (isExercisePreviewMode) {
      setInnerSentences(
        sentences.map((sentence) => ({
          ...sentence,
          chunks: sentence.chunks.map((chunk) => ({
            ...chunk,
            blank: chunk.blank
              ? {
                  ...chunk.blank,
                  currentAnswer:
                    chunk.blank?.answers.find((answer) => answer.isCorrect) ||
                    null,
                  isCorrectAnswer: true,
                  isAllAttemptsFailed: false,
                  isEmpty: false,
                }
              : null,
          })),
        }))
      );
    }

    if (isExerciseLiveMode) {
      setInnerSentences(sentences);
    }
  }, [isExercisePreviewMode, isExerciseLiveMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const isShowResults: boolean = useMemo(
    () =>
      isExercisePreviewMode ||
      innerSentences.every((sentence) =>
        sentence.chunks.every((chunk) => chunk.blank?.currentAnswer !== null)
      ),
    [isExercisePreviewMode, innerSentences]
  );

  const sentenceChangeHandler = (
    updatedSentence: TExerciseSentence<TChooseRightWordExerciseBlank>
  ) => {
    setInnerSentences((prevSentences) =>
      prevSentences.map((sentence) =>
        sentence.id === updatedSentence.id ? updatedSentence : sentence
      )
    );
  };

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      {innerSentences.map((sentence) => (
        <SentenceItem
          key={sentence.id}
          sentence={sentence}
          showResult={isShowResults}
          onSentenceChange={sentenceChangeHandler}
        />
      ))}
    </Space>
  );
};
