import { useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { DraggableStartZone } from "../DraggableStartZone";
import { DroppableList } from "../DroppableList";
import { DraggableOverlay } from "../DraggableOverlay";
import {
  START_ZONE_ID,
  addWordToColumn,
  addWordToStartZone,
  moveWordBetweenColumns,
  removeWordFromColumn,
  removeWordFromStartZone,
  replaceWordInColumn,
  replaceWordInStartZone,
  switchWordsBetweenColumns,
} from "../SortWords.helpers";
import {
  TExerciseIdentifier,
  TSortWordsExerciseParsedColumn,
  TSortWordsExerciseParsedWord,
  TSortWordsExercisePayload,
} from "types/app/exercises";
import "./DndContainer.styles.scss";

type TDndContainerProps = {
  /**
   * The core data object for the drag and drop manipulations.
   */
  exerciseData: TSortWordsExercisePayload;
  /**
   * Fires after a draggable word was dropped into column.
   * @param word data of the word that was drag and dropped.
   * @param details detailed information about word start and destination points.
   */
  onDragComplete?: (
    word: TSortWordsExerciseParsedWord,
    details: {
      source: {
        type: "start-zone" | "column";
        column: TSortWordsExerciseParsedColumn | null;
      };
      destination: {
        type: "start-zone" | "column";
        column: TSortWordsExerciseParsedColumn | null;
      };
    }
  ) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DndContainer: React.FC<TDndContainerProps> = (props) => {
  const { exerciseData, onDragComplete, className } = props;

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragActiveId, setDragActiveId] = useState<TExerciseIdentifier | null>(
    null
  );
  const [startZoneWords, setStartZoneWords] = useState<
    TSortWordsExerciseParsedWord[]
  >(exerciseData.words);
  const [columns, setColumns] = useState<TSortWordsExerciseParsedColumn[]>(
    exerciseData.columns
  );

  const { isExercisePreviewMode } = useLessonSettings();

  useEffect(() => {
    if (isExercisePreviewMode) {
      setStartZoneWords([]);
      setColumns(
        exerciseData.columns.map((column) => ({
          ...column,
          isEmpty: false,
          currentWords: column.correctWords.map((word) => ({
            ...word,
            isCorrectDestination: true,
            currentColumnId: column.id,
          })),
        }))
      );
      return;
    }
    setStartZoneWords(exerciseData.words);
    setColumns(exerciseData.columns);
  }, [isExercisePreviewMode, exerciseData]);

  const isAllowToShowResult: boolean = useMemo(
    () => startZoneWords.length === 0,
    [startZoneWords]
  );

  const wordDragStartHandler = (event: DragStartEvent) => {
    const { active } = event;

    setDragActiveId(active.id);
    setIsDragging(true);
  };

  const wordDragEndHandler = (event: DragEndEvent) => {
    const { over, active } = event;

    if (over && active.id !== over.id) {
      const targetWord = exerciseData.words.find(
        ({ id }) => id === active.id
      ) as TSortWordsExerciseParsedWord;
      const targetColumn: TSortWordsExerciseParsedColumn | null =
        over.data.current?.column;
      const replaceWord: TSortWordsExerciseParsedWord | null =
        over.data.current?.word || null;
      const previousColumn: TSortWordsExerciseParsedColumn | null =
        columns.find((column) =>
          column.currentWords.some(
            (columnWord) => columnWord.id === targetWord.id
          )
        ) || null;

      const isWordFromStartZone: boolean = startZoneWords.some(
        ({ id }) => id === targetWord.id
      );
      const isDestinationStartZone: boolean = over.id === START_ZONE_ID;

      // CASE 1: FROM START ZONE TO COLUMN AREA
      if (
        isWordFromStartZone &&
        !replaceWord &&
        targetColumn &&
        !targetColumn.currentWords.some(({ id }) => id === targetWord.id)
      ) {
        setStartZoneWords((prevWords) =>
          removeWordFromStartZone(prevWords, active.id, targetColumn)
        );
        setColumns((prevColumns) =>
          addWordToColumn(prevColumns, targetColumn.id, targetWord.id)
        );
      }

      // CASE 2: FROM START ZONE TO REPLACE WORD IN COLUMN
      if (
        isWordFromStartZone &&
        replaceWord &&
        targetColumn &&
        targetColumn.currentWords.some(({ id }) => id === replaceWord.id)
      ) {
        setStartZoneWords((prevWords) =>
          replaceWordInStartZone(prevWords, active.id, replaceWord)
        );
        setColumns((prevColumns) =>
          replaceWordInColumn(
            prevColumns,
            targetColumn.id,
            targetWord.id,
            replaceWord.id
          )
        );
      }

      // CASE 3: FROM COLUMN TO START ZONE
      if (
        !isWordFromStartZone &&
        isDestinationStartZone &&
        !startZoneWords.some(({ id }) => id === targetWord.id)
      ) {
        setStartZoneWords((prevWords) =>
          addWordToStartZone(prevWords, targetWord)
        );
        setColumns((prevColumns) =>
          removeWordFromColumn(prevColumns, targetWord.id)
        );
      }

      // CASE 4: FROM COLUMN TO OTHER COLUMN
      if (
        !isWordFromStartZone &&
        previousColumn &&
        !replaceWord &&
        targetColumn &&
        !targetColumn.currentWords.some(({ id }) => id === targetWord.id)
      ) {
        setColumns((prevColumns) =>
          moveWordBetweenColumns(
            prevColumns,
            previousColumn.id,
            targetColumn.id,
            targetWord.id
          )
        );
      }

      // CASE 5: FROM COLUMN TO OTHER COLUMN WITH WORD REPLACE
      if (
        !isWordFromStartZone &&
        previousColumn &&
        replaceWord &&
        targetColumn &&
        !targetColumn.currentWords.some(({ id }) => id === targetWord.id)
      ) {
        setColumns((prevColumns) =>
          switchWordsBetweenColumns(
            prevColumns,
            previousColumn.id,
            targetColumn.id,
            targetWord.id,
            replaceWord.id
          )
        );
      }

      onDragComplete?.(targetWord, {
        source: {
          type: isWordFromStartZone ? "start-zone" : "column",
          column: isWordFromStartZone ? null : previousColumn,
        },
        destination: {
          type: isDestinationStartZone ? "start-zone" : "column",
          column: isDestinationStartZone ? null : targetColumn,
        },
      });
    }

    setDragActiveId(null);
    setIsDragging(false);
  };

  const wordDragCancelHandler = () => {
    setDragActiveId(null);
    setIsDragging(false);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
      onDragStart={wordDragStartHandler}
      onDragEnd={wordDragEndHandler}
      onDragCancel={wordDragCancelHandler}
    >
      <Space
        direction="vertical"
        size="medium"
        justify="start"
        fullWidth
        className={className}
      >
        <DraggableStartZone
          words={startZoneWords}
          dragActiveId={dragActiveId}
        />
        <DroppableList
          columns={columns}
          dragging={isDragging}
          dragActiveId={dragActiveId}
          showResult={isAllowToShowResult}
        />
      </Space>
      <DraggableOverlay
        words={exerciseData.words}
        dragActiveId={dragActiveId}
      />
    </DndContext>
  );
};
