/**
 * The Fisher-Yates algorithm of shuffling an array (from the end to the start) and picking a random item from the array and swapping it with the item in the current iteration.
 * @param array an array of any kind of data.
 * @returns randomly shuffled array.
 */
export const shuffleArray = <TData = unknown>(array: TData[]): TData[] => {
  const shuffled: TData[] = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = shuffled[i];
    shuffled[i] = shuffled[j];
    shuffled[j] = temp;
  }
  return shuffled;
};

/**
 * Moves an element from one index to another index in an array.
 * @param array an array of any kind of data.
 * @param fromIndex the previous index of the element.
 * @param toIndex the new index of the element.
 * @returns a new array with the element moved to the new index.
 */
export const moveArrayElement = <TData = unknown>(
  array: TData[],
  fromIndex: number,
  toIndex: number
): TData[] => {
  if (fromIndex === toIndex) {
    return array;
  }
  const newArr = [...array];
  newArr.splice(toIndex, 0, newArr.splice(fromIndex, 1)[0]);
  return newArr;
};
