import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { RequestsContent } from "content";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { Tooltip } from "components/Interactive/Tooltip";
import { IconButton } from "components/Interactive/IconButton";
import { RequestStatusCell } from "./RequestStatusCell";
import { formatPhoneNumber } from "utils/common";
import { getRowSequenceNumber } from "utils/table";
import { ERequestStatus, TRequest } from "types/api/requests";

type TUseRequestColumns = (options?: {
  /**
   * Callback to handle request status change.
   */
  onStatusChange?: (request: TRequest, status: ERequestStatus) => void;
  /**
   * Callback to handle request deletion.
   * @param id - Request id.
   */
  onDelete?: (id: number) => void;
}) => ColumnDef<TRequest, string>[];

export const useRequestColumns: TUseRequestColumns = (options) => {
  const { onStatusChange, onDelete } = options || {};

  const columnHelper = createColumnHelper<TRequest>();

  const columns: ColumnDef<TRequest, string>[] = [
    columnHelper.accessor(() => "index", {
      id: "index",
      header: RequestsContent.Table.Header.NUMBER,
      cell: (info) =>
        getRowSequenceNumber(info.row.index, info.table.getState().pagination),
      size: 0.05,
    }),
    columnHelper.accessor(() => "name", {
      id: "name",
      header: RequestsContent.Table.Header.NAME,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={info.row.original.name}
          spaceSize="small"
          variant="compact"
        >
          {info.row.original.name}
        </Tooltip>
      ),
      size: 0.2,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: "phone",
      header: RequestsContent.Table.Header.PHONE,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={formatPhoneNumber(info.getValue())}
          spaceSize="small"
          variant="compact"
        >
          {formatPhoneNumber(info.getValue())}
        </Tooltip>
      ),
      size: 0.2,
    }),
    columnHelper.accessor(
      (row) => row.studyMethod ?? RequestsContent.Fallback.STUDY_METHOD,
      {
        id: "studyMethod",
        header: RequestsContent.Table.Header.STUDY_METHOD,
        cell: (info) => (
          <Tooltip
            mouseEnterDelay={1000}
            text={info.getValue()}
            spaceSize="small"
            variant="compact"
          >
            {info.getValue()}
          </Tooltip>
        ),
        size: 0.15,
      }
    ),
    columnHelper.accessor(
      (row) => row.period ?? RequestsContent.Fallback.PERIOD,
      {
        id: "period",
        header: RequestsContent.Table.Header.PERIOD,
        cell: (info) => (
          <Tooltip
            mouseEnterDelay={1000}
            text={info.getValue()}
            spaceSize="small"
            variant="compact"
          >
            {info.getValue()}
          </Tooltip>
        ),
        size: 0.1,
      }
    ),
    columnHelper.accessor((row) => row.createdAt, {
      id: "createdAt",
      header: RequestsContent.Table.Header.CREATED_AT,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={info.getValue()}
          spaceSize="small"
          variant="compact"
        >
          {info.getValue()}
        </Tooltip>
      ),
      size: 0.2,
    }),
    columnHelper.accessor(() => "status", {
      id: "status",
      header: RequestsContent.Table.Header.STATUS,
      cell: ({ row }) => (
        <RequestStatusCell request={row.original} onChange={onStatusChange} />
      ),
      size: 0.2,
    }),
    columnHelper.accessor(() => "actions", {
      id: "actions",
      header: RequestsContent.Table.Header.ACTION,
      cell: ({ row }) => (
        <IconButton
          icon={<Delete />}
          onClick={() => onDelete?.(row.original.id)}
        />
      ),
      size: 0.05,
    }),
  ];

  return columns;
};
