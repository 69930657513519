import { useMutation, useQueryClient } from "react-query";
import { removeRequest } from "api/services/requests";
import { ERequestQueryKeys } from "api/endpoints/requests";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useRemoveRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(removeRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(ERequestQueryKeys.getAllRequests);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
