import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { Space } from "components/Common/Space";
import { Tooltip } from "components/Interactive/Tooltip";
import { DroppableItem } from "../DroppableItem";
import {
  TExerciseIdentifier,
  TMakeWordFromLettersExerciseWordBlank,
} from "types/app/exercises";
import "./DroppableList.styles.scss";

type TDroppableListProps = {
  /**
   * An array of word blanks that will be rendered as droppable items.
   */
  blanks: TMakeWordFromLettersExerciseWordBlank[];
  /**
   * Text hint that will be available only for admin and teacher.
   */
  hint: string | null;
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DroppableList: React.FC<TDroppableListProps> = (props) => {
  const {
    dragging = false,
    showResult = false,
    blanks = [],
    dragActiveId = null,
    hint,
  } = props;

  return (
    <Space
      direction="horizontal"
      size="x-small"
      justify="center"
      align="center"
      fullWidth
      wrap
    >
      {blanks.map((blank) => (
        <DroppableItem
          key={blank.id}
          blank={blank}
          dragging={dragging}
          dragActiveId={dragActiveId}
          showResult={showResult}
        />
      ))}
      <Tooltip
        text={hint}
        disabled={false} // TODO: When permissions will be implemented this should be always disabled for student
        trigger="click"
        position="top-start"
        spaceSize="small"
        arrow="visible"
        containerClassName="nb-exercise-make-words-from-letters-tooltip-container"
        className="nb-exercise-make-words-from-letters-tooltip"
      >
        {/** TODO: Indicator of correct answer when API will be ready. Icon will be rendered as a placeholder for now */}
        <MoreIcon />
      </Tooltip>
    </Space>
  );
};
