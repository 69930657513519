import { Breadcrumbs } from "components/Navigation/Breadcrumbs";
import { IconButton } from "components/Interactive/IconButton";
import { ProfileDropdown } from "components/Navigation/ProfileDropdown";
import { ReactComponent as Notifications } from "assets/icons/notifications.svg";
import "./SiteHeader.styles.scss";

export const SiteHeader: React.FC = () => {
  return (
    <header className="nb-layout-site-header">
      <div className="nb-layout-site-header-breadcrumbs">
        <Breadcrumbs />
      </div>
      <div className="nb-layout-site-header-controls">
        <IconButton
          variant="secondary"
          icon={<Notifications />}
          className="nb-layout-site-header-controls-notifications"
        />
        <ProfileDropdown />
      </div>
    </header>
  );
};
