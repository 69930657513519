import { useParams } from "react-router-dom";
import { LessonDetailsContent } from "content";
import { Paragraph } from "components/Typography/Paragraph";
import { Text } from "components/Typography/Text";
import { Button } from "components/Interactive/Button";
import { Switch } from "components/Interactive/Switch";
import { Space } from "components/Common/Space";
import "./LessonHeader.styles.scss";

type TLessonHeaderProps = {
  /**
   * Is lesson in preview mode
   * @default false
   */
  isPreviewMode?: boolean;
  /**
   * Callback that is called when preview mode is changed
   * @param isPreviewMode - new preview mode value
   */
  onPreviewChange?: (isPreviewMode: boolean) => void;
};

export const LessonHeader: React.FC<TLessonHeaderProps> = (props) => {
  const { isPreviewMode = false, onPreviewChange } = props;

  const { courseId } = useParams();

  return (
    <header className="nb-lesson-details-header">
      <Paragraph variant={2}>{LessonDetailsContent.Header.TEXT}</Paragraph>
      <Space direction="horizontal" size="small" justify="start" align="center">
        <Space
          direction="horizontal"
          size="x-small"
          justify="start"
          align="center"
        >
          <Text variant="body2">
            {LessonDetailsContent.Header.Button.PREVIEW}
          </Text>
          <Switch
            value={isPreviewMode}
            onChange={onPreviewChange}
            className="nb-lesson-details-preview-switch"
          />
        </Space>
        <Button variant="link" to={`/materials/course/${courseId}`}>
          {LessonDetailsContent.Header.Button.EXIT}
        </Button>
      </Space>
    </header>
  );
};
