import {
  ArrayPath,
  Control,
  FieldArray,
  FieldErrorsImpl,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import cx from "classnames";
import { Divider } from "components/Common/Divider";
import { Button } from "components/Interactive/Button";
import { QuestionInput } from "./QuestionInput";
import { AnswerList } from "./AnswerList";
import { TestQuestionsContent } from "content";
import { getTestQuestionInitialValue } from "./TestQuestions.helpers";
import { TTestExerciseQuestions } from "types/app/exercises";
import "./TestQuestions.styles.scss";

type TestQuestionProps<
  TFormValues extends TTestExerciseQuestions = TTestExerciseQuestions
> = {
  /**
   * Question input label text for the `input` element.
   */
  questionLabel?: string;
  /**
   * The short hint displayed in the question `input` before the user enters a value.
   */
  questionPlaceholder?: string;
  /**
   * Answers input label text for the first answer `input` element.
   */
  answerLabel?: string;
  /**
   * The short hint displayed in the answers `input` before the user enters a value.
   */
  answerPlaceholder?: string;
  /**
   * The content of the add question button.
   */
  addQuestionButtonText?: string;
  /**
   * The content of the add answer button.
   */
  addAnswerButtonText?: string;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * `TestQuestion` interactive element in association with `react-hook-form` library made for `ExerciseConstructor` component.
 */
export const TestQuestions = <
  TFormValues extends TTestExerciseQuestions = TTestExerciseQuestions
>(
  props: TestQuestionProps<TFormValues>
): JSX.Element => {
  const {
    questionLabel = TestQuestionsContent.Default.QUESTION_LABEL,
    questionPlaceholder = TestQuestionsContent.Default.QUESTION_PLACEHOLDER,
    answerLabel = TestQuestionsContent.Default.ANSWER_LABEL,
    answerPlaceholder = TestQuestionsContent.Default.ANSWER_PLACEHOLDER,
    addQuestionButtonText = TestQuestionsContent.Default.ADD_QUESTION_TEXT,
    addAnswerButtonText = TestQuestionsContent.Default.ADD_ANSWER_TEXT,
    control,
    register,
    errors,
    className,
  } = props;

  const { fields, append, remove } = useFieldArray<TFormValues>({
    control,
    name: "questions" as ArrayPath<TFormValues>,
  });

  const addQuestionClickHandler = () => {
    append(
      getTestQuestionInitialValue() as FieldArray<
        TFormValues,
        ArrayPath<TFormValues>
      >
    );
  };

  return (
    <div className={cx(["nb-interactive-test-question-container", className])}>
      {fields.map((item, index) => (
        <div key={item.id} className="nb-interactive-test-question-wrapper">
          <QuestionInput<TFormValues>
            id={item.id}
            questionIndex={index}
            questionLabel={questionLabel}
            questionPlaceholder={questionPlaceholder}
            register={register}
            errors={errors}
            onDelete={remove}
          />
          <AnswerList<TFormValues>
            questionIndex={index}
            label={answerLabel}
            answerPlaceholder={answerPlaceholder}
            addAnswerButtonText={addAnswerButtonText}
            control={control}
            register={register}
          />
          <Divider margin="yAxis" marginSize="large" />
        </div>
      ))}
      <Button variant="secondary" fullWidth onClick={addQuestionClickHandler}>
        {addQuestionButtonText}
      </Button>
    </div>
  );
};
