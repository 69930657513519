import { useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { DraggableStartZone } from "../DraggableStartZone";
import { DroppableList } from "../DroppableList";
import { DraggableOverlay } from "../DraggableOverlay";
import {
  START_ZONE_ID,
  addAnswerToBlank,
  addAnswerToStartZone,
  removeAnswerFromBlank,
  removeAnswerFromStartZone,
  replaceAnswerInStartZone,
  replaceAnswersInBlanks,
  switchAnswerInBlanks,
} from "../MoveWordsIntoGaps.helpers";
import {
  TExerciseIdentifier,
  TExerciseSentence,
  TExerciseSentenceAnswer,
  TMoveWordsIntoGapsExerciseBlank,
  TMoveWordsIntoGapsExerciseData,
} from "types/app/exercises";
import "./DndContainer.styles.scss";

type TDndContainerProps = {
  /**
   * The core data object for the drag and drop manipulations.
   */
  exerciseData: TMoveWordsIntoGapsExerciseData;
  /**
   * Fires after a draggable answer was dropped into blank.
   *
   * @param answer data of the answer that was drag and dropped.
   * @param details detailed information about answer start and destination points.
   */
  onDragComplete?: (
    answer: TExerciseSentenceAnswer,
    details: {
      source: {
        type: "start-zone" | "blank";
        blank: TMoveWordsIntoGapsExerciseBlank | null;
      };
      destination: {
        type: "start-zone" | "blank";
        blank: TMoveWordsIntoGapsExerciseBlank | null;
      };
    }
  ) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DndContainer: React.FC<TDndContainerProps> = (props) => {
  const { exerciseData, onDragComplete, className } = props;

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragActiveId, setDragActiveId] = useState<TExerciseIdentifier | null>(
    null
  );
  const [startZoneAnswers, setStartZoneAnswers] = useState<
    TExerciseSentenceAnswer[]
  >(exerciseData.answers);
  const [sentences, setSentences] = useState<
    TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[]
  >(exerciseData.sentences);

  const { isExercisePreviewMode } = useLessonSettings();

  useEffect(() => {
    if (isExercisePreviewMode) {
      setStartZoneAnswers([]);
      setSentences(
        exerciseData.sentences.map((sentence) => ({
          ...sentence,
          chunks: sentence.chunks.map((chunk) => ({
            ...chunk,
            blank:
              chunk.blank !== null
                ? {
                    ...chunk.blank,
                    currentAnswer: chunk.blank.correctAnswer,
                    isCorrectAnswer: true,
                    isEmpty: false,
                  }
                : null,
          })),
        }))
      );
      return;
    }
    setStartZoneAnswers(exerciseData.answers);
    setSentences(exerciseData.sentences);
  }, [isExercisePreviewMode, exerciseData]);

  const isAllowToShowResult: boolean = useMemo(
    () => startZoneAnswers.length === 0,
    [startZoneAnswers]
  );

  const answerDragStartHandler = (event: DragStartEvent) => {
    const { active } = event;

    setDragActiveId(active.id);
    setIsDragging(true);
  };

  const answerDragEndHandler = (event: DragEndEvent) => {
    const { over, active } = event;

    if (over && active.id !== over.id) {
      const targetAnswer = exerciseData.answers.find(
        ({ id }) => id === active.id
      ) as TExerciseSentenceAnswer;
      const targetBlank = over.data.current
        ?.blank as TMoveWordsIntoGapsExerciseBlank;
      const previousBlank: TMoveWordsIntoGapsExerciseBlank | null =
        sentences
          .map((sentence) => sentence.chunks.map((chunk) => chunk.blank))
          .flat()
          .flatMap((blank) => (blank ? [blank] : []))
          .find((blank) => blank.currentAnswer?.id === targetAnswer.id) || null;

      const isAnswerFromStartZone: boolean = startZoneAnswers.some(
        ({ id }) => id === targetAnswer.id
      );
      const isBlankStartZone: boolean = over.id === START_ZONE_ID;

      // CASE 1: FROM START ZONE TO EMPTY DROPPABLE
      if (isAnswerFromStartZone && targetBlank?.isEmpty) {
        setStartZoneAnswers((prevAnswers) =>
          removeAnswerFromStartZone(prevAnswers, active.id)
        );
        setSentences((prevSentences) =>
          addAnswerToBlank(prevSentences, targetBlank.id, targetAnswer)
        );
      }

      // CASE 2: FROM START ZONE TO OCCUPIED DROPPABLE
      if (isAnswerFromStartZone && targetBlank?.currentAnswer) {
        const replaceAnswer = targetBlank.currentAnswer;
        setStartZoneAnswers((prevAnswers) =>
          replaceAnswerInStartZone(prevAnswers, active.id, replaceAnswer)
        );
        setSentences((prevSentences) =>
          addAnswerToBlank(prevSentences, targetBlank.id, targetAnswer)
        );
      }

      // CASE 3: FROM DROPPABLE TO OTHER EMPTY DROPPABLE
      if (!isAnswerFromStartZone && targetBlank?.isEmpty && previousBlank) {
        setSentences((prevSentences) =>
          switchAnswerInBlanks(
            prevSentences,
            targetAnswer,
            targetBlank.id,
            previousBlank.id
          )
        );
      }

      // CASE 4: FROM DROPPABLE TO OTHER OCCUPIED DROPPABLE
      if (
        !isAnswerFromStartZone &&
        targetBlank?.currentAnswer &&
        previousBlank
      ) {
        setSentences((prevSentences) =>
          replaceAnswersInBlanks(prevSentences, targetBlank, previousBlank)
        );
      }

      // CASE 5: FROM DROPPABLE TO START ZONE
      if (!isAnswerFromStartZone && isBlankStartZone && previousBlank) {
        setStartZoneAnswers((prevAnswers) =>
          addAnswerToStartZone(prevAnswers, targetAnswer)
        );
        setSentences((prevSentences) =>
          removeAnswerFromBlank(prevSentences, previousBlank.id)
        );
      }

      onDragComplete?.(targetAnswer, {
        source: {
          type: isAnswerFromStartZone ? "start-zone" : "blank",
          blank: isAnswerFromStartZone ? null : previousBlank,
        },
        destination: {
          type: isBlankStartZone ? "start-zone" : "blank",
          blank: isBlankStartZone ? null : targetBlank,
        },
      });
    }

    setDragActiveId(null);
    setIsDragging(false);
  };

  const answerDragCancelHandler = () => {
    setDragActiveId(null);
    setIsDragging(false);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
      onDragStart={answerDragStartHandler}
      onDragEnd={answerDragEndHandler}
      onDragCancel={answerDragCancelHandler}
    >
      <Space
        direction="vertical"
        size="medium"
        justify="start"
        fullWidth
        className={className}
      >
        <DraggableStartZone
          answers={startZoneAnswers}
          dragActiveId={dragActiveId}
        />
        <DroppableList
          sentences={sentences}
          dragging={isDragging}
          dragActiveId={dragActiveId}
          showResult={isAllowToShowResult}
        />
      </Space>
      <DraggableOverlay
        answers={exerciseData.answers}
        dragActiveId={dragActiveId}
      />
    </DndContext>
  );
};
