import { Header, flexRender } from "@tanstack/react-table";
import cx from "classnames";
import { getCellHackedSize } from "../Table.utils";
import { TDefaultTableData } from "../Table.types";
import "./TableHeadCell.styles.scss";

type TableHeadCellProps<TData extends TDefaultTableData = TDefaultTableData> = {
  /**
   * Header object generated in `react-table` to operate with data.
   */
  header: Header<TData, unknown>;
  /**
   * The colspan attribute defines the number of columns a cell should span.
   *
   * @default 1
   */
  colSpan?: number;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const TableHeadCell = <
  TData extends TDefaultTableData = TDefaultTableData
>(
  props: TableHeadCellProps<TData>
): JSX.Element => {
  const { header, className, colSpan = 1 } = props;

  return (
    <th
      className={cx(["nb-table-head-cell", className])}
      colSpan={colSpan}
      style={{
        width: getCellHackedSize(header.getSize()),
      }}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  );
};
