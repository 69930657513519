import {
  endOfDay,
  endOfISOWeek,
  format,
  startOfDay,
  startOfISOWeek,
} from "date-fns";
import {
  TDatePickerDefaultValueResolver,
  TDatePickerNavigationLabelResolver,
} from "./DatePicker.types";

export const getDatePickerDefaultValue: TDatePickerDefaultValueResolver = (
  mode
) => {
  switch (mode) {
    case "day":
      return [startOfDay(new Date()), endOfDay(new Date())];

    case "week":
      return [startOfISOWeek(new Date()), endOfISOWeek(new Date())];

    default:
      throw new Error("Invalid DatePicker mode was provided");
  }
};

export const getNavigationLabelText: TDatePickerNavigationLabelResolver = ({
  date,
}) => {
  return `${format(date, "LLLL")} ${format(date, "yyyy")}`;
};
