import { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { TTabItem, TTabItemDefaultKey, TTabItemStyles } from "types/app/tabs";
import "./Tabs.styles.scss";

type TabsProps<TabItemKey extends TTabItemDefaultKey = TTabItemDefaultKey> = {
  /**
   * Array of items that populate the `Tabs` navigation component.
   */
  items: TTabItem<TabItemKey>[];
  /**
   * The default tab key to set as active before the user starts to interact with `Tabs` component.
   *
   * @default {itemKey} - will take first item key from array of `items`.
   */
  activeTabKey?: TabItemKey;
  /**
   * Function called when tab switches active key.
   *
   * @param activeTabKey - active tab key.
   * @returns {void} - nothing.
   */
  onChange?: (activeTabKey: TabItemKey) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const Tabs = <
  TabItemKey extends TTabItemDefaultKey = TTabItemDefaultKey
>(
  props: TabsProps<TabItemKey>
): JSX.Element => {
  const { items, className, activeTabKey, onChange } = props;
  const [firstItem] = items;

  const [activeKey, setActiveKey] = useState<TabItemKey>(
    activeTabKey || firstItem.key
  );

  useEffect(() => {
    /**
     * We need to force the change of active key if it comes from outer state.
     */
    if (activeTabKey) {
      setActiveKey(activeTabKey);
    }
  }, [activeTabKey]);

  const tabWidth: TTabItemStyles = useMemo(
    () => ({
      style: {
        width: `${100 / items.length}%`,
      },
    }),
    [items.length]
  );

  const underlineProps: TTabItemStyles = useMemo(() => {
    const lineWidth = 100 / items.length;
    const lineScale = lineWidth / 100;
    const activeKeyIndex = items.findIndex((tab) => tab.key === activeKey);
    const transformValue = activeKeyIndex * lineWidth;

    return {
      style: {
        transform: `translateX(${transformValue}%) scaleX(${lineScale})`,
      },
    };
  }, [activeKey, items]);

  const tabChangeHandler = (key: TabItemKey) => {
    setActiveKey(key);
    onChange?.(key);
  };

  return (
    <nav className={cx(["nb-interactive-tabs-container", className])}>
      <ul className="nb-interactive-tabs-list">
        {items.map((tab) => (
          <li key={tab.key} className="nb-interactive-tabs-item" {...tabWidth}>
            <button
              type="button"
              className={cx([
                "nb-interactive-tabs-button",
                {
                  "nb-interactive-tabs-button--active": tab.key === activeKey,
                },
              ])}
              disabled={tab.disabled}
              onClick={() => tabChangeHandler(tab.key)}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      <div className="nb-interactive-tabs-underline" {...underlineProps} />
    </nav>
  );
};
