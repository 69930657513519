import { PlaceholderProps, components } from "react-select";
import type { TSelectDefaultOption } from "../../Select.types";
import "./Placeholder.styles.scss";

type SelectPlaceholderProps<
  Option extends TSelectDefaultOption = TSelectDefaultOption
> = {
  placeholder: string;
} & PlaceholderProps<Option, false>;

export const Placeholder = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>(
  props: SelectPlaceholderProps<Option>
): JSX.Element => {
  const { placeholder } = props;

  return (
    <components.Placeholder
      {...props}
      className="nb-interactive-select-placeholder"
    >
      {placeholder ? placeholder : props.children}
    </components.Placeholder>
  );
};
