import { useCallback } from "react";
import {
  TExerciseConstructorState,
  exerciseConstructorSelector,
  exerciseConstructorSlices,
} from "store/features/constructor";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useBodyScrollBlock } from "hooks/common";
import { EExerciseType } from "types/app/exercises";
import { TUrlParam } from "types/utils";
import { TExercise } from "types/api/exercise";

type TUseExerciseConstructorReturn<
  TExercisePayload extends Record<string, unknown> = Record<string, unknown>
> = TExerciseConstructorState<TExercisePayload> & {
  /**
   * Handler function that filter list of `exercises` based on search value.
   * @param searchValue - user entered text.
   */
  filterExercises: (searchValue: string) => void;
  /**
   * Handler function that will change `isMainDrawerOpen` to `false`.
   */
  closeExerciseList: () => void;
  /**
   * Handler function that will change `isMainDrawerOpen` to `true`.
   */
  openExerciseList: () => void;
  /**
   * Handler function that will change `isMainDrawerOpen` based on passed argument.
   * @param isVisible new state for `isMainDrawerOpen`.
   */
  toggleExerciseList: (isVisible: boolean) => void;
  /**
   * Handler function that will change `isSecondaryDrawerOpen` to `false`.
   */
  closeExerciseConfig: () => void;
  /**
   * Handler function that will change `isSecondaryDrawerOpen` to `true`.
   */
  openExerciseConfig: () => void;
  /**
   * Handler function that will change `isSecondaryDrawerOpen` based on passed argument.
   * @param isVisible new state for `isSecondaryDrawerOpen`.
   */
  toggleExerciseConfig: (isVisible: boolean) => void;
  /**
   * Handler function that will change `isMainDrawerOpen` and `isSecondaryDrawerOpen` to `false` which close all opened drawers.
   */
  closeAll: () => void;
  /**
   * Handler function that will change `currentExerciseType` based on passed argument.
   * @param type selected exercise type.
   */
  setCurrentExerciseType: (type: EExerciseType) => void;
  /**
   * Handler function that will change `courseId` for the exercise submission.
   * @param courseId id of the current course.
   */
  setCourseId: (courseId: TUrlParam) => void;
  /**
   * Handler function that will change `lessonId` for the exercise submission.
   * @param lessonId id of the current lesson.
   */
  setLessonId: (lessonId: TUrlParam) => void;
  /**
   * Handler function that will change `chapterId` for the exercise submission.
   * @param chapterId id of the current lesson chapter.
   */
  setChapterId: (chapterId: TUrlParam) => void;
  /**
   * Handler function that will change `homeworkId` for the exercise submission.
   * @param homeworkId id of the lesson homework entity.
   */
  setHomeworkId: (homeworkId: TUrlParam) => void;
  /**
   * Handler function that will toggle constructor state to edit mode and set `editExercise` to passed argument.
   * @param chapterId id of the current lesson chapter.
   * @param exercise exercise to edit.
   */
  setEditExercise: (
    chapterId: TUrlParam,
    exercise: TExercise<TExercisePayload> | null
  ) => void;
  /**
   * Handler function that will change `constructorMode` to `lesson`.
   */
  setLessonMode: () => void;
  /**
   * Handler function that will change `constructorMode` to `homework`.
   */
  setHomeworkMode: () => void;
};

export const useExerciseConstructor = <
  TExercisePayload extends Record<string, unknown> = Record<string, unknown>
>(): TUseExerciseConstructorReturn<TExercisePayload> => {
  const dispatch = useAppDispatch();
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    isMainDrawerOpen,
    isSecondaryDrawerOpen,
    isEditMode,
    exercises,
    currentExerciseType,
    editExercise,
    constructorMode,
  } = useAppSelector<TExerciseConstructorState<TExercisePayload>>(
    exerciseConstructorSelector as (
      state: unknown
    ) => TExerciseConstructorState<TExercisePayload>
  );
  const {
    toggleExerciseList,
    toggleExerciseConfig,
    filterExercises,
    setCurrentExerciseType,
    setCourseId,
    setLessonId,
    setChapterId,
    setHomeworkId,
    setEditExercise,
    toggleEditMode,
    setConstructorMode,
  } = exerciseConstructorSlices.actions;
  const { allowScroll, blockScroll } = useBodyScrollBlock();

  return {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    isMainDrawerOpen,
    isSecondaryDrawerOpen,
    isEditMode,
    exercises,
    currentExerciseType,
    editExercise,
    constructorMode,
    filterExercises: useCallback(
      (searchValue) => {
        dispatch(filterExercises(searchValue));
      },
      [filterExercises, dispatch]
    ),
    closeExerciseList: () => {
      allowScroll();
      dispatch(setEditExercise(null));
      dispatch(setChapterId(null));
      dispatch(toggleEditMode(false));
      dispatch(toggleExerciseList(false));
    },
    openExerciseList: () => {
      dispatch(toggleExerciseList(true));
      blockScroll();
    },
    toggleExerciseList: (isVisible) => {
      if (!isVisible) {
        dispatch(setEditExercise(null));
        dispatch(setChapterId(null));
      }
      dispatch(toggleExerciseList(isVisible));
    },
    closeExerciseConfig: () => {
      dispatch(setEditExercise(null));
      dispatch(toggleEditMode(false));
      dispatch(toggleExerciseConfig(false));
    },
    openExerciseConfig: () => {
      dispatch(toggleExerciseConfig(true));
    },
    toggleExerciseConfig: (isVisible) => {
      dispatch(toggleExerciseConfig(isVisible));
    },
    closeAll: () => {
      allowScroll();
      dispatch(setEditExercise(null));
      dispatch(setChapterId(null));
      dispatch(toggleEditMode(false));
      dispatch(toggleExerciseConfig(false));
      dispatch(toggleExerciseList(false));
    },
    setCurrentExerciseType: (type) => {
      dispatch(setCurrentExerciseType(type));
    },
    setCourseId: useCallback(
      (courseId) => {
        dispatch(setCourseId(courseId));
      },
      [dispatch, setCourseId]
    ),
    setLessonId: useCallback(
      (lessonId) => {
        dispatch(setLessonId(lessonId));
      },
      [dispatch, setLessonId]
    ),
    setChapterId: useCallback(
      (chapterId) => {
        dispatch(setChapterId(chapterId));
      },
      [dispatch, setChapterId]
    ),
    setHomeworkId: useCallback(
      (homeworkId) => {
        dispatch(setHomeworkId(homeworkId));
      },
      [dispatch, setHomeworkId]
    ),
    setEditExercise: useCallback(
      (chapterId, exercise) => {
        dispatch(setChapterId(chapterId));
        if (exercise) {
          dispatch(setCurrentExerciseType(exercise.type));
        }
        dispatch(setEditExercise(exercise));
        dispatch(toggleEditMode(true));
        dispatch(toggleExerciseList(true));
        dispatch(toggleExerciseConfig(true));
      },
      [
        dispatch,
        setEditExercise,
        setChapterId,
        toggleExerciseList,
        toggleExerciseConfig,
        setCurrentExerciseType,
        toggleEditMode,
      ]
    ),
    setLessonMode: useCallback(() => {
      dispatch(setConstructorMode("lesson"));
    }, [dispatch, setConstructorMode]),
    setHomeworkMode: useCallback(() => {
      dispatch(setConstructorMode("homework"));
    }, [dispatch, setConstructorMode]),
  };
};
