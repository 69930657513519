import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useEditCourse } from "hooks/api/courses";
import { useLanguage } from "hooks/api/language";
import { CourseDetailsContent } from "content";
import { AvatarUpload } from "components/Interactive/AvatarUpload";
import { Input } from "components/Interactive/Input";
import { Select } from "components/Interactive/Select";
import { Textarea } from "components/Interactive/Textarea";
import { Button } from "components/Interactive/Button";
import { ReactPortal } from "components/Common/ReactPortal";
import { logError, logSuccess } from "utils/notifications";
import { convertBlobToBase64String } from "utils/common";
import { TCourse, TEditCourseBody } from "types/api/course";
import { ELanguageLevel } from "types/api/language";
import { TEditCourseForm } from "./EditCourseForm.types";
import { TSelectOption } from "types/app/select";
import "./EditCourseForm.styles.scss";

type EditCourseFormProps = {
  /**
   * Course data to display current (default) values.
   */
  course?: TCourse;
  /**
   * Callback function fires on edit course submit event.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const EditCourseForm: React.FC<EditCourseFormProps> = (props) => {
  const { course, className, onSubmit } = props;

  const [selectedFile, setSelectedFile] = useState<Blob | null>(null);
  const idRef = useRef<string>(nanoid());

  const { mutate: editCourseHandler } = useEditCourse();
  const { data: languageData } = useLanguage();

  const languageOptions: TSelectOption[] =
    languageData?.map((lang) => ({
      label: lang,
      value: lang,
    })) || [];
  const levelOptions: TSelectOption<ELanguageLevel>[] = Object.entries(
    ELanguageLevel
  ).map(([key, level]) => ({
    label: key,
    value: level,
  }));

  const defaultValues = useMemo(
    () => ({
      ...(course?.name && { name: course?.name }),
      ...(course?.language && {
        language: {
          label: course?.language,
          value: course?.language,
        },
      }),
      ...(course?.languageLevel && {
        languageLevel: {
          label: course?.languageLevel,
          value: course?.languageLevel,
        },
      }),
      ...(course?.description && { description: course?.description }),
    }),
    [course]
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TEditCourseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [course, defaultValues, reset]);

  const handleEditCourseSubmit = async (formValues: TEditCourseForm) => {
    if (!course) {
      logError({
        message: CourseDetailsContent.Edit.Course.Notification.ERROR,
      });
      return;
    }

    const imageEncoded =
      selectedFile &&
      (await convertBlobToBase64String(selectedFile, { removePrefix: true }));

    const courseBody: TEditCourseBody = {
      id: course.id,
      name: formValues.name,
      language: formValues.language.value,
      languageLevel: formValues.languageLevel.value,
      description: formValues.description || null,
      imageUrl: course.imageUrl,
      // Adding this field conditionally because if it's null api will delete the image
      ...(imageEncoded && { imageEncoded }),
    };

    editCourseHandler(courseBody, {
      onSuccess: (updatedCourse) => {
        onSubmit?.();
        logSuccess(CourseDetailsContent.Edit.Course.Notification.SUCCESS);
        setSelectedFile(null);
        reset({
          description: updatedCourse.description || "",
          name: updatedCourse.name,
          language: {
            label: updatedCourse.language,
            value: updatedCourse.language,
          },
          languageLevel: {
            label: updatedCourse.languageLevel,
            value: updatedCourse.languageLevel,
          },
        });
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-edit-course-form", className])}
      onSubmit={handleSubmit(handleEditCourseSubmit)}
    >
      <AvatarUpload
        bordered
        resetOnClose
        size="large"
        aspect={171 / 219}
        cropShape="rect"
        shape="rect"
        mode="save"
        className="nb-sub-edit-course-avatar"
        previousImage={course?.imageUrl}
        onSave={setSelectedFile}
      />
      <Input<TEditCourseForm>
        name="name"
        id="edit-course-name"
        className="nb-sub-edit-course-name-input"
        label={CourseDetailsContent.Edit.Course.Input.Name.LABEL}
        placeholder={CourseDetailsContent.Edit.Course.Input.Name.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Select<TEditCourseForm, TSelectOption>
        name="language"
        id="edit-course-language"
        label={CourseDetailsContent.Edit.Course.Input.Language.LABEL}
        placeholder={
          CourseDetailsContent.Edit.Course.Input.Language.PLACEHOLDER
        }
        options={languageOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Select<TEditCourseForm, TSelectOption<ELanguageLevel>>
        name="languageLevel"
        id="edit-course-level"
        label={CourseDetailsContent.Edit.Course.Input.Level.LABEL}
        placeholder={CourseDetailsContent.Edit.Course.Input.Level.PLACEHOLDER}
        options={levelOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Textarea<TEditCourseForm>
        name="description"
        id="edit-course-description"
        className="nb-sub-edit-course-description-input"
        label={CourseDetailsContent.Edit.Course.Input.Description.LABEL}
        placeholder={
          CourseDetailsContent.Edit.Course.Input.Description.PLACEHOLDER
        }
        register={register}
        errors={errors}
        autoFocus
        fullWidth
      />
      <ReactPortal wrapperId="edit-course-modal-footer">
        <Button
          className="nb-sub-edit-course-submit"
          variant="primary"
          type="submit"
          form={idRef.current}
          fullWidth
        >
          {CourseDetailsContent.Edit.Course.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
