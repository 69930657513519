import { get, post, put, remove } from "api";
import { EXERCISES_ENDPOINTS } from "api/endpoints/exercises";
import { TAddExercise, TEditExercise, TExercise } from "types/api/exercise";
import { TUrlParam } from "types/utils";

export const fetchExercises = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam
): Promise<TExercise[]> =>
  get<TExercise[]>(
    EXERCISES_ENDPOINTS.CHAPTER_EXERCISES(courseId, lessonId, chapterId)
  );

export const addExercise = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  exercise: TAddExercise<TPayload, TText>
): Promise<TExercise<TPayload, TText>> =>
  post<TExercise<TPayload, TText>>(
    EXERCISES_ENDPOINTS.CHAPTER_EXERCISES(courseId, lessonId, chapterId),
    exercise
  );

export const editExercise = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  exercise: TEditExercise<TPayload, TText>
): Promise<TExercise<TPayload, TText>> =>
  put<TExercise<TPayload, TText>>(
    EXERCISES_ENDPOINTS.CHAPTER_EXERCISES(courseId, lessonId, chapterId),
    exercise
  );

export const removeExercise = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  exerciseId: number
): Promise<void> =>
  remove<void>(
    `${EXERCISES_ENDPOINTS.CHAPTER_EXERCISES(
      courseId,
      lessonId,
      chapterId
    )}/${exerciseId}`
  );

export const fetchHomeworkExercises = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  homeworkId: TUrlParam
): Promise<TExercise[]> =>
  get<TExercise[]>(
    EXERCISES_ENDPOINTS.HOMEWORK_EXERCISES(courseId, lessonId, homeworkId)
  );

export const addHomeworkExercise = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  homeworkId: TUrlParam,
  exercise: TAddExercise<TPayload, TText>
): Promise<TExercise<TPayload, TText>> =>
  post<TExercise<TPayload, TText>>(
    EXERCISES_ENDPOINTS.HOMEWORK_EXERCISES(courseId, lessonId, homeworkId),
    exercise
  );

export const editHomeworkExercise = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  homeworkId: TUrlParam,
  exercise: TEditExercise<TPayload, TText>
): Promise<TExercise<TPayload, TText>> =>
  put<TExercise<TPayload, TText>>(
    EXERCISES_ENDPOINTS.HOMEWORK_EXERCISES(courseId, lessonId, homeworkId),
    exercise
  );

export const removeHomeworkExercise = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  homeworkId: TUrlParam,
  exerciseId: number
): Promise<void> =>
  remove<void>(
    `${EXERCISES_ENDPOINTS.HOMEWORK_EXERCISES(
      courseId,
      lessonId,
      homeworkId
    )}/${exerciseId}`
  );
