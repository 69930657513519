import { useQuery } from "react-query";
import { fetchTeacherById } from "api/services/teachers";
import { ETeachersQueryKeys } from "api/endpoints/teachers";
import { logError } from "utils/notifications";
import { TTeacher } from "types/api/teacher";
import { TApiError } from "types/api/error";

export const useTeacherById = (id?: number) => {
  return useQuery<TTeacher, TApiError>(
    [ETeachersQueryKeys.getTeacherById, id],
    () => fetchTeacherById(id),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: id !== undefined,
    }
  );
};
