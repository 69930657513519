/**
 * TODO: Completely refactor this function due to poor logic, or get rid of it if backend will format phone numbers.
 *
 * Get formatted phone number string with parenthesis and dashes.
 * @param {String} rawPhoneString - phone number string, exp: `380991112233`
 * @returns {String} result - formatted phone number based on input string, exp: `+38 (099) 111-22-33`
 */
export const formatPhoneNumber = (inputString: string): string => {
  const phoneNumber = inputString.replace(/\D/g, "");
  const stateCode = phoneNumber.slice(0, 2);
  const areaCode = phoneNumber.slice(2, 5);
  const firstThree = phoneNumber.slice(5, 8);
  const nextTwo = phoneNumber.slice(8, 10);
  const lastTwo = phoneNumber.slice(10, 12);
  return `+${stateCode} (${areaCode}) ${firstThree}-${nextTwo}-${lastTwo}`;
};
