import { useForm } from "react-hook-form";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { TAddToDictionaryExerciseForm } from "./AddToDictionaryExerciseForm.types";
import "./AddToDictionaryExerciseForm.styles.scss";

export const AddToDictionaryExerciseForm: React.FC = () => {
  const { handleSubmit } = useForm<TAddToDictionaryExerciseForm>({
    mode: "onBlur",
    defaultValues: {},
  });

  const addExerciseSubmitHandler = (formData: TAddToDictionaryExerciseForm) => {
    // eslint-disable-next-line no-console
    console.log("add AddToDictionaryExerciseForm handler", formData);
  };

  return (
    <ExerciseConfigForm onSubmit={handleSubmit(addExerciseSubmitHandler)}>
      AddToDictionaryExerciseForm
    </ExerciseConfigForm>
  );
};
