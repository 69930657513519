import { CourseDetailsContent } from "content";
import { useImageUpload } from "hooks/redux";
import { useLazyTimeout } from "hooks/common/useLazyTimeout";
import { ContentModal } from "components/Common/ContentModal";
import { EditCourseForm } from "../EditCourseForm";
import { TCourse } from "types/api/course";
import "./EditCourseModal.styles.scss";

type EditCourseModalProps = {
  /**
   * Course data to display in `Hero` component.
   */
  course?: TCourse;
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
};

export const EditCourseModal: React.FC<EditCourseModalProps> = (props) => {
  const { course, open = false, onClose } = props;

  const { resetAllImageUploadStates } = useImageUpload();
  const resetDelay = useLazyTimeout(300);

  const modalCloseHandler = () => {
    onClose?.();
    resetDelay(resetAllImageUploadStates);
  };

  return (
    <ContentModal
      portalId="edit-course-modal"
      footerId="edit-course-modal-footer"
      open={open}
      title={CourseDetailsContent.Edit.Course.TITLE}
      emoji={CourseDetailsContent.Edit.Course.EMOJI}
      onClose={modalCloseHandler}
    >
      <EditCourseForm course={course} onSubmit={modalCloseHandler} />
    </ContentModal>
  );
};
