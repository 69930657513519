import { useNavigate, useParams } from "react-router-dom";
import { CourseDetailsContent, StudentClassroomContent } from "content";
import { Loader } from "components/Common/Loader";
import { Space } from "components/Common/Space";
import { Button } from "components/Interactive/Button";
import { Heading } from "components/Typography/Heading";
import { Paragraph } from "components/Typography/Paragraph";
import { Text } from "components/Typography/Text";
import { Route } from "constants/routes";
import { TCourse } from "types/api/course";
import "./CourseHero.styles.scss";

type TCourseHeroProps = {
  /**
   * Course data to display in `Hero` component.
   */
  course?: TCourse;
  /**
   * If `true` component will display loader with animation.
   *
   * @default false
   */
  loading?: boolean;
};

export const CourseHero: React.FC<TCourseHeroProps> = (props) => {
  const { course, loading = false } = props;
  const { studentId } = useParams();

  const navigate = useNavigate();

  const unpinCourseClickHandler = () => {
    // TODO: implement unpin course
    // eslint-disable-next-line no-console
    console.log("unpinCourseClickHandler", { studentId, courseId: course?.id });
  };

  const backToStudentClassroomClickHandler = () => {
    if (!studentId) return;
    navigate(Route.App.Study.STUDENT_CLASSROOM.construct(studentId));
  };

  return (
    <section className="nb-sub-student-classroom-course-hero">
      {loading ? (
        <Loader />
      ) : (
        <Space direction="horizontal" justify="start" align="start" fullWidth>
          <div className="nb-sub-student-classroom-course-image">
            <div className="nb-sub-student-classroom-course-poster">
              {course?.imageUrl ? (
                <img
                  src={course.imageUrl}
                  width="171"
                  height="219"
                  alt={`Обкладинка курсу ${course?.name}}`}
                  className="nb-sub-student-classroom-course-picture"
                />
              ) : (
                <span className="nb-sub-student-classroom-course-emoji">
                  🚀
                </span>
              )}
            </div>
          </div>
          <Space direction="vertical" justify="start" align="start">
            <Heading
              level={2}
              className="nb-sub-student-classroom-course-heading"
            >
              <Text className="nb-sub-student-classroom-course-heading--emoji">
                {CourseDetailsContent.Hero.Content.Heading.EMOJI}
              </Text>
              {course?.name}
            </Heading>
            {course?.description && (
              <Paragraph
                variant={2}
                className="nb-sub-student-classroom-course-paragraph"
              >
                {course.description}
              </Paragraph>
            )}
          </Space>
          <Space
            direction="horizontal"
            size="x-small"
            justify="start"
            align="center"
            className="nb-sub-student-classroom-course-actions"
          >
            <Button
              variant="secondary"
              onClick={backToStudentClassroomClickHandler}
            >
              {StudentClassroomContent.Hero.Action.BACK}
            </Button>
            <Button onClick={unpinCourseClickHandler}>
              {StudentClassroomContent.Hero.Action.UNPIN}
            </Button>
          </Space>
        </Space>
      )}
    </section>
  );
};
