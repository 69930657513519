import { StudentClassroomContent as Content } from "content";
import { CoverButton } from "components/Interactive/CoverButton";
import { Space } from "components/Common/Space";
import { Paragraph } from "components/Typography/Paragraph";
import "./ClassroomEmpty.styles.scss";

type TClassroomEmptyProps = {
  /**
   * Add material for student mouse button click handler
   * @param event - mouse event
   */
  onAddClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ClassroomEmpty: React.FC<TClassroomEmptyProps> = (props) => {
  const { onAddClick } = props;

  return (
    <Space
      direction="vertical"
      size="medium"
      align="center"
      justify="center"
      fullWidth
      className="nb-sub-classroom-empty-wrapper"
    >
      <div className="nb-sub-classroom-empty-button-wrapper">
        <CoverButton
          onClick={onAddClick}
          className="nb-sub-classroom-empty-button"
        >
          {Content.Empty.LABEL}
        </CoverButton>
        <img
          src="/assets/images/other/1.png"
          width={197}
          height={301}
          aria-hidden="true"
          className="nb-sub-classroom-empty-button-image"
        />
      </div>
      <Paragraph variant={2} className="nb-sub-classroom-empty-message">
        {Content.Empty.MESSAGE}
      </Paragraph>
    </Space>
  );
};
