import { Space } from "components/Common/Space";
import { DraggableItem } from "../DraggableItem";
import { DroppableStartZone } from "../DroppableStartZone";
import {
  TExerciseIdentifier,
  TMakeWordFromLettersExerciseParsedWordLetter,
} from "types/app/exercises";
import "./DraggableStartZoneList.styles.scss";

type TDraggableStartZoneListProps = {
  /**
   * An array of word letters to display in the start zone area.
   */
  letters?: TMakeWordFromLettersExerciseParsedWordLetter[];
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DraggableStartZoneList: React.FC<TDraggableStartZoneListProps> = (
  props
) => {
  const { letters = [], dragActiveId = null } = props;

  return (
    <Space
      direction="horizontal"
      align="center"
      justify="center"
      size="x-small"
      className="nb-exercise-make-words-from-letters-draggable-start-zone-list"
      fullWidth
      wrap
    >
      {letters.map((letter) => (
        <DroppableStartZone key={letter.id} letter={letter}>
          {letter.id !== dragActiveId && letter.currentBlankId === null && (
            <DraggableItem letter={letter} />
          )}
        </DroppableStartZone>
      ))}
    </Space>
  );
};
