import { FieldErrorsImpl, Path, UseFormRegister } from "react-hook-form";
import { Space } from "components/Common/Space";
import { IconButton } from "components/Interactive/IconButton";
import { Input } from "components/Interactive/Input";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { TWordsInputDefaultValue } from "../WordsInputList.types";
import "./InputItem.styles.scss";

type TInputItemProps<
  TFormValues extends TWordsInputDefaultValue = TWordsInputDefaultValue
> = {
  /**
   * Name attribute of the `WordsInputList` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: Path<TFormValues>;
  /**
   * Index of the input item from `react-hook-form` array fields.
   */
  index: number;
  /**
   * The id that `react-hook-form` created to associate `input` element and form array fields.
   */
  id?: string;
  /**
   * The short hint displayed in the word `input` before the user enters a value.
   */
  wordPlaceholder?: string;
  /**
   * The short hint displayed in the hint `input` before the user enters a value.
   */
  hintPlaceholder?: string;
  /**
   * If `true`, the delete button is disabled. Used for preventing deletion of the single input item.
   * @default false
   */
  disableDelete?: boolean;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const InputItem = <
  TFormValues extends TWordsInputDefaultValue = TWordsInputDefaultValue
>(
  props: TInputItemProps<TFormValues>
): JSX.Element => {
  const {
    name,
    index,
    id,
    wordPlaceholder,
    hintPlaceholder,
    register,
    errors,
    disableDelete = false,
    onDelete,
  } = props;

  return (
    <Space direction="vertical" justify="stretch" size="x-small" fullWidth>
      <Space
        direction="horizontal"
        justify="stretch"
        align="start"
        size="x-small"
        fullWidth
      >
        <Input<TFormValues>
          name={`${name}.content` as Path<TFormValues>}
          id={`words-input-list-content-${id}`}
          className="nb-interactive-words-input-list-content-field"
          placeholder={`${wordPlaceholder} ${index + 1}`}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <IconButton
          variant="secondary"
          size="medium"
          icon={<CloseIcon />}
          className="nb-interactive-words-input-list-remove"
          onClick={onDelete}
          disabled={disableDelete}
        />
      </Space>
      <Input<TFormValues>
        name={`${name}.hint` as Path<TFormValues>}
        id={`words-input-list-hint-${id}`}
        className="nb-interactive-words-input-list-hint-field"
        placeholder={`${hintPlaceholder} ${index + 1}`}
        register={register}
        errors={errors}
        fullWidth
      />
    </Space>
  );
};
