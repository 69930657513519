import { useImageUpload } from "hooks/redux";
import { useLazyTimeout } from "hooks/common/useLazyTimeout";
import { CourseDetailsContent } from "content";
import { ContentModal } from "components/Common/ContentModal";
import { AddCourseLessonForm } from "../AddCourseLessonForm";
import "./AddCourseLessonModal.styles.scss";

type AddCourseLessonModalProps = {
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
};

export const AddCourseLessonModal: React.FC<AddCourseLessonModalProps> = (
  props
) => {
  const { open = false, onClose } = props;

  const { resetAllImageUploadStates } = useImageUpload();
  const resetDelay = useLazyTimeout(300);

  const modalCloseHandler = () => {
    onClose?.();
    resetDelay(resetAllImageUploadStates);
  };

  return (
    <ContentModal
      portalId="add-course-lesson-modal"
      footerId="add-course-lesson-modal-footer"
      open={open}
      title={CourseDetailsContent.Add.TITLE}
      emoji={CourseDetailsContent.Add.EMOJI}
      onClose={modalCloseHandler}
    >
      <AddCourseLessonForm onSubmit={modalCloseHandler} />
    </ContentModal>
  );
};
