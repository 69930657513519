import { Header } from "@tanstack/react-table";
import cx from "classnames";
import { TableHeadCell } from "../TableHeadCell";
import type { TDefaultTableData, TTableRowClassnames } from "../Table.types";
import "./TableHeadRow.styles.scss";

type TableHeadRowProps<TData extends TDefaultTableData = TDefaultTableData> = {
  /**
   * Header array generated in `react-table` to operate with data and cells.
   */
  headers: Header<TData, unknown>[];
  /**
   * Object for `Table` sub-components to override or extend the styles applied to the particular component.
   */
  className?: TTableRowClassnames;
};

export const TableHeadRow = <
  TData extends TDefaultTableData = TDefaultTableData
>(
  props: TableHeadRowProps<TData>
): JSX.Element => {
  const { headers, className } = props;

  return (
    <tr className={cx(["nb-table-head-row", className?.root])}>
      {headers.map((header) => (
        <TableHeadCell<TData>
          key={header.id}
          colSpan={header.colSpan}
          header={header}
          className={className?.cell}
        />
      ))}
    </tr>
  );
};
