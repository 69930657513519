import { forwardRef, useRef } from "react";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useKeyPress } from "hooks/common/useKeyPress";
import { ModalContent } from "content";
import { ReactPortal } from "components/Common/ReactPortal";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { ReactComponent as Close } from "assets/icons/close.svg";
import "./ActionModal.styles.scss";

type ActionModalProps = {
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * The content of the modal component.
   */
  text?: string;
  /**
   * The content of the close button.
   */
  closeText?: string;
  /**
   * The content of the submit button.
   */
  submitText?: string;
  /**
   * The emoji before modal text content.
   */
  emoji?: string | boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: () => void;
  /**
   * Callback fired when the component requests to accept an action.
   */
  onSubmit?: () => void;
};

const ActionModal: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ActionModalProps
> = (props, ref) => {
  const {
    open = false,
    text = ModalContent.Action.DEFAULT.TEXT,
    closeText = ModalContent.Action.DEFAULT.CLOSE_BUTTON,
    submitText = ModalContent.Action.DEFAULT.SUBMIT_BUTTON,
    emoji = ModalContent.Action.DEFAULT.EMOJI,
    onClose,
    onSubmit,
  } = props;

  const wrapperIdRef = useRef<string>(`nb-action-modal-${nanoid()}`);

  useKeyPress("Escape", onClose);

  return (
    <ReactPortal wrapperId={wrapperIdRef.current}>
      <div
        aria-hidden={!open}
        tabIndex={-1}
        className={cx([
          "nb-common-action-modal",
          { "nb-common-action-modal--open": open },
        ])}
        onClick={onClose}
      >
        <div
          ref={ref}
          role="dialog"
          aria-modal
          className={cx([
            "nb-common-action-modal-content",
            { "nb-common-action-modal-content--open": open },
          ])}
          onClick={(evt) => evt.stopPropagation()}
        >
          <div className="nb-common-action-modal-header">
            <IconButton
              variant="secondary"
              icon={<Close />}
              onClick={onClose}
            />
          </div>
          <div className="nb-common-action-modal-main">
            {emoji && (
              <span className="nb-common-action-modal-emoji">{emoji}</span>
            )}
            <p className="nb-common-action-modal-text">{text}</p>
          </div>
          <div className="nb-common-action-modal-footer">
            <Button
              variant="secondary"
              onClick={onClose}
              className="nb-common-action-modal-button"
            >
              {closeText}
            </Button>
            <Button
              variant="primary"
              autoFocus={open}
              onClick={onSubmit}
              className="nb-common-action-modal-button"
            >
              {submitText}
            </Button>
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};

/**
 * Action modal is common component with a backdrop that requires some action from user.
 */
export const ForwardedActionModal = forwardRef(ActionModal);
