import { format } from "date-fns";
import { generateColorBasedOnString } from "utils/common";
import { TLesson } from "types/api/lesson";
import { TStudent } from "types/api/student";
import { TScheduleEvent } from "types/app/schedule";

/**
 * Adapts the data from the API to the format required by the calendar.
 * @param data api lesson data
 * @param student api student data
 * @returns event data for the calendar
 */
export const adaptStudentScheduleEventData = (
  data?: TLesson[],
  student?: TStudent
): TScheduleEvent[] => {
  /**
   * TODO: Refactor this adaptor and ask to include names in lessons.
   */
  const FALLBACK = "Unknown";

  const events: TScheduleEvent[] = [];

  if (!data) {
    return events;
  }

  for (const lesson of data) {
    const studentName = student
      ? `${student?.person.firstName} ${student?.person.lastName}`
      : FALLBACK;

    const eventColor = generateColorBasedOnString(studentName);
    const event: TScheduleEvent = {
      id: String(lesson.id),
      title: studentName,
      start: format(new Date(lesson.startDateTime), "yyyy-MM-dd HH:mm:ss"),
      end: format(new Date(lesson.endDateTime), "yyyy-MM-dd HH:mm:ss"),
      data: lesson,
      className: `nb-sub-schedule-event-color--${eventColor}`,
    };
    events.push(event);
  }
  return events;
};
