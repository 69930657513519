import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
/* eslint-disable-next-line import/no-named-as-default */
import Calendar, { OnChangeDateRangeCallback } from "react-calendar";
import { useForm } from "react-hook-form";
import { endOfDay, endOfISOWeek, format, startOfISOWeek } from "date-fns";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useOutsideClick } from "hooks/common";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactPortal } from "components/Common/ReactPortal";
import { IconButton } from "components/Interactive/IconButton";
import { Input } from "components/Interactive/Input";
import { DatePickerContent } from "content";
import { logError } from "utils/notifications";
import { mergeRefs } from "utils/react";
import {
  getDatePickerDefaultValue,
  getNavigationLabelText,
} from "./DatePicker.helpers";
import {
  TDatePickerChangeHandler,
  TDatePickerForm,
  TDatePickerMode,
  TDatePickerPosition,
  TDatePickerVariant,
  TDatePickerView,
} from "./DatePicker.types";
import "./DatePicker.styles.scss";

type DatePickerProps = {
  /**
   * Calendar value that shall be selected initially.
   * Should be array of two Dates, that indicates start and end range values.
   * Default values will be based on provided `mode` prop and will fallback to current day or week.
   */
  defaultValue?: [Date, Date];
  /**
   * Calendar value that shall be controlled outer of DatePicker.
   * Should be array of two Dates, that indicates start and end range values.
   */
  value?: [Date, Date];
  /**
   * Determines which calendar view shall be opened. Can be "month", "year".
   *
   * @default 'month'
   */
  view?: TDatePickerView;
  /**
   * Determines which range of values shall be returned. Can be "day", "week".
   *
   * @default 'day'
   */
  mode?: TDatePickerMode;
  /**
   * Determines `variant` of `DatePicker` opener.
   *
   * @default 'button'
   */
  variant?: TDatePickerVariant;
  /**
   * Label text for the `input` element if variant is `input`.
   */
  label?: string;
  /**
   * Indicates where `DatePicker` will be placed while open.
   *
   * @default 'bottom-left'
   */
  position?: TDatePickerPosition;
  /**
   * If `true`, the `DatePicker` element will be visible after component mount.
   *
   * @default false
   */
  initiallyOpen?: boolean;
  /**
   * If `true`, the `DatePicker` element will be in readonly mode.
   * @default false
   */
  readonly?: boolean;
  /**
   * Indicates offset of the `DatePicker` element from the opener.
   * @default 5
   */
  positionOffset?: number;
  /**
   * Icon element placed as a children of the button or input.
   *
   * @default CalendarIcon
   */
  icon?: React.ReactElement;
  /**
   * Function called when the user clicks on tile (day on month view, month on year view) on the most detailed view available.
   *
   * @param startDate - start day for the new range.
   * @param endDate - end date for the new range.
   * @returns {void} - nothing.
   */
  onChange?: (startDate: Date, endDate: Date) => void;
  /**
   * Function called when the user clicks on `DatePicker` opener element.
   *
   * @returns {void} - nothing.
   */
  onOpenerClick?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * @remark Date picker properly working for month view now in day and week modes. We can extend it functionality by providing new conditions and styling.
 */
const DatePicker: React.ForwardRefRenderFunction<
  HTMLDivElement,
  DatePickerProps
> = (props, ref) => {
  const {
    position = "bottom-left",
    view = "month",
    mode = "day",
    variant = "button",
    initiallyOpen = false,
    readonly = false,
    icon = <CalendarIcon width={15} height={15} />,
    label,
    value,
    positionOffset = 5,
    onOpenerClick,
    onChange,
    className,
  } = props;
  const { defaultValue = getDatePickerDefaultValue(mode) } = props;

  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(initiallyOpen);
  const [innerValue, setInnerValue] = useState<[Date, Date]>(defaultValue);

  const idRef = useRef<string>(`date-picker-${nanoid()}`);
  const pickerOpenerRef = useRef<HTMLDivElement>(null);
  const pickerCalendarRef = useRef<HTMLDivElement>(null);

  const { register, setValue } = useForm<TDatePickerForm>();

  const datePickerPositionHandler = useCallback(() => {
    if (
      !isPickerOpen ||
      !pickerCalendarRef.current ||
      !pickerOpenerRef.current
    ) {
      return;
    }

    // Positioning
    const rect = pickerOpenerRef.current.getBoundingClientRect();
    if (position === "bottom-left") {
      pickerCalendarRef.current.style.top = `${rect.bottom + positionOffset}px`;
      pickerCalendarRef.current.style.left = `${
        rect.left + rect.width - pickerCalendarRef.current.offsetWidth
      }px`;
    }
    if (position === "bottom-right") {
      pickerCalendarRef.current.style.top = `${rect.bottom + positionOffset}px`;
      pickerCalendarRef.current.style.left = `${rect.right - rect.width}px`;
    }
    if (position === "bottom-center") {
      pickerCalendarRef.current.style.top = `${rect.bottom + positionOffset}px`;
      pickerCalendarRef.current.style.left = `${
        rect.left + rect.width / 2 - pickerCalendarRef.current.offsetWidth / 2
      }px`;
    }
    if (position === "top-left") {
      pickerCalendarRef.current.style.bottom = `${
        window.innerHeight - rect.top + positionOffset
      }px`;
      pickerCalendarRef.current.style.left = `${
        rect.left + rect.width - pickerCalendarRef.current.offsetWidth
      }px`;
    }
    if (position === "top-right") {
      pickerCalendarRef.current.style.bottom = `${
        window.innerHeight - rect.top + positionOffset
      }px`;
      pickerCalendarRef.current.style.left = `${rect.right - rect.width}px`;
    }
    if (position === "top-center") {
      pickerCalendarRef.current.style.bottom = `${
        window.innerHeight - rect.top + positionOffset
      }px`;
      pickerCalendarRef.current.style.left = `${
        rect.left + rect.width / 2 - pickerCalendarRef.current.offsetWidth / 2
      }px`;
    }
  }, [positionOffset, isPickerOpen, position]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    datePickerPositionHandler();
    window.addEventListener("scroll", datePickerPositionHandler);
    window.addEventListener("resize", datePickerPositionHandler);
    return () => {
      window.removeEventListener("scroll", datePickerPositionHandler);
      window.removeEventListener("resize", datePickerPositionHandler);
    };
  }, [datePickerPositionHandler]);

  useEffect(() => {
    /**
     * Here we need to control input value for `input` variant.
     * We are using this approach because we need to populate on mount and handle control and uncontrolled states.
     */
    if (variant === "input") {
      const targetDates = value || innerValue;
      const [start, end] = targetDates;
      if (mode === "day") {
        setValue("datePickerInput", format(start, "dd MMMM"));
      }
      if (mode === "week") {
        const inputStartValue = format(start, "dd-MM-yyyy");
        const inputEndValue = format(end, "dd-MM-yyyy");
        const inputValue = `${inputStartValue} - ${inputEndValue}`;
        setValue("datePickerInput", inputValue);
      }
    }
  }, [value, innerValue, mode, setValue, variant]);

  const openButtonClickHandler = () => {
    if (readonly) {
      return;
    }
    setIsPickerOpen((prevState) => !prevState);
    onOpenerClick?.();
  };

  const closeDatePickerHandler = useCallback(() => {
    // We need to check if opener was clicked to prevent closing because openers have their own handlers.
    const isOpenerClicked = pickerOpenerRef.current?.contains(
      window.document.activeElement
    );
    if (isPickerOpen && !isOpenerClicked) {
      setIsPickerOpen(false);
    }
  }, [isPickerOpen]);

  useOutsideClick(pickerCalendarRef, closeDatePickerHandler);

  const dayModeChangeHandler: TDatePickerChangeHandler = (dates) => {
    const [startDate, endDate] = dates;

    const refinedEndDate = endDate || endOfDay(startDate);
    setInnerValue([startDate, refinedEndDate]);
    onChange?.(startDate, refinedEndDate);
    setIsPickerOpen(false);
  };

  const weekModeChangeHandler: TDatePickerChangeHandler = (dates) => {
    /**
     * We need to do a trick here with selecting week range.
     * As the `react-calendar` do not give us ability to select week by clicking on specific day, we have to monkey patch it ourselves with controlled approach.
     */
    const [startDate] = dates;

    const refinedStartDate = startOfISOWeek(startDate);
    const refinedEndDate = endOfISOWeek(startDate);
    setInnerValue([refinedStartDate, refinedEndDate]);
    onChange?.(refinedStartDate, refinedEndDate);
    setIsPickerOpen(false);
  };

  const dateChangeHandler: OnChangeDateRangeCallback = (newValue) => {
    switch (mode) {
      case "day":
        return dayModeChangeHandler(newValue);

      case "week":
        return weekModeChangeHandler(newValue);

      default:
        logError({ message: "Invalid DatePicker mode was provided" });
    }
  };

  return (
    <div
      ref={mergeRefs([ref, pickerOpenerRef])}
      className={cx(["nb-interactive-date-picker-container", className])}
    >
      {variant === "button" && (
        <IconButton
          icon={icon}
          className="nb-interactive-date-picker-opener"
          onClick={openButtonClickHandler}
        />
      )}
      {variant === "input" && (
        <Input<TDatePickerForm>
          name="datePickerInput"
          id="date-picker-input"
          className="nb-interactive-date-picker-opener-input"
          register={register}
          label={label}
          placeholder={DatePickerContent.DEFAULT.PLACEHOLDER}
          leftIcon={icon}
          onClick={openButtonClickHandler}
          fullWidth
          readOnly
        />
      )}
      <ReactPortal wrapperId={idRef.current}>
        <div
          ref={pickerCalendarRef}
          className={cx([
            "nb-interactive-date-picker-holder",
            {
              "nb-interactive-date-picker-holder--open": isPickerOpen,
            },
          ])}
        >
          <Calendar
            // Config
            calendarType="ISO 8601"
            returnValue="range"
            locale="uk"
            showNavigation
            selectRange={false}
            // View
            view={view}
            minDetail={view}
            maxDetail={view}
            // Content
            nextLabel={<Chevron width={10} height={10} />}
            prevLabel={<Chevron width={10} height={10} />}
            next2Label={null}
            prev2Label={null}
            navigationLabel={getNavigationLabelText}
            // Data
            value={value || innerValue}
            // Events
            onChange={dateChangeHandler}
            // Styling
            className="nb-interactive-date-picker"
            tileClassName="nb-interactive-date-picker-tile"
          />
        </div>
      </ReactPortal>
    </div>
  );
};

/**
 * Date Picker is an interactive element based on `react-calendar` library.
 */
export const ForwardedDatePicker = forwardRef(DatePicker);
