import { useQuery } from "react-query";
import { EExercisesQueryKeys } from "api/endpoints/exercises";
import { fetchHomeworkExercises } from "api/services/exercises";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TExercise } from "types/api/exercise";
import { TUrlParam } from "types/utils";

export const useHomeworkExercises = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  homeworkId: TUrlParam
) => {
  return useQuery<TExercise[], TApiError>(
    [EExercisesQueryKeys.getHomeworkExercises, courseId, lessonId, homeworkId],
    () => fetchHomeworkExercises(courseId, lessonId, homeworkId),
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
