import { useQuery } from "react-query";
import { fetchStudentById } from "api/services/students";
import { EStudentsQueryKeys } from "api/endpoints/students";
import { logError } from "utils/notifications";
import { TStudent } from "types/api/student";
import { TApiError } from "types/api/error";
import { TUrlParam } from "types/utils";

export const useStudentById = (id: TUrlParam) => {
  return useQuery<TStudent, TApiError>(
    [EStudentsQueryKeys.getStudentById, id],
    () => fetchStudentById(id),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: id !== undefined,
    }
  );
};
