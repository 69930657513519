import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { LandingDashboardContent } from "content";
import { useDeleteLandingOffer, useLandingOffers } from "hooks/api/admin";
import { useModal } from "hooks/common";
import { useLandingOffersColumns } from "hooks/table/useLandingOffersColumns";
import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { Empty } from "components/Common/Empty";
import { ContentModal } from "components/Common/ContentModal";
import { ActionModal } from "components/Common/ActionModal";
import { Search } from "components/Interactive/Search";
import { Button } from "components/Interactive/Button";
import { Table } from "components/Table";
import { AddOfferForm } from "./components/AddOfferForm";
import { EditOfferForm } from "./components/EditOfferForm";
import { logError, logSuccess } from "utils/notifications";
import { TSelectOption } from "types/app/select";
import { TAdminLandingOffer } from "types/api/admin";
import { TLandingOffersSearchForm } from "./types";
import "./Landing.styles.scss";

export const LandingDashboard: React.FC = () => {
  const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null);
  const [selectedOffer, setSelectedOffer] = useState<TAdminLandingOffer | null>(
    null
  );

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);
  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal(false);
  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const { data: landingOffers = [], isFetching } = useLandingOffers();
  const { mutate: deleteOffer } = useDeleteLandingOffer();

  const isEmpty: boolean = useMemo(
    () => landingOffers.length === 0,
    [landingOffers]
  );

  const { control } = useForm<TLandingOffersSearchForm>({
    defaultValues: {
      search: "",
    },
  });

  const searchCallback = useCallback(async (): Promise<
    TSelectOption<number>[]
  > => {
    return landingOffers.map((offer) => ({
      label: offer.type,
      value: offer.id,
    }));
  }, [landingOffers]);

  const addButtonClickHandler = () => {
    openAddModal();
  };

  const editButtonClickHandler = (offer: TAdminLandingOffer) => {
    setSelectedOffer(offer);
    openEditModal();
  };

  const deleteOfferClickHandler = (id: number) => {
    setSelectedOfferId(id);
    openDeleteModal();
  };

  const deleteSubmitHandler = () => {
    if (selectedOfferId === null) {
      logError({ message: LandingDashboardContent.Delete.Notification.ERROR });
      return;
    }

    deleteOffer(selectedOfferId, {
      onSuccess: () => {
        logSuccess(LandingDashboardContent.Delete.Notification.SUCCESS);
        closeDeleteModal();
        setSelectedOfferId(null);
      },
    });
  };

  const columns = useLandingOffersColumns({
    onEditClick: editButtonClickHandler,
    onDeleteClick: deleteOfferClickHandler,
  });

  return (
    <Space
      direction="vertical"
      size="large"
      justify="start"
      align="start"
      fullWidth
    >
      <Space
        direction="horizontal"
        size="medium"
        justify="between"
        align="center"
        fullWidth
      >
        <Search<TLandingOffersSearchForm, TSelectOption<number>>
          name="search"
          control={control}
          id="landing-offers-search"
          placeholder={LandingDashboardContent.Search.PLACEHOLDER}
          noOptionsText={LandingDashboardContent.Search.NO_OPTIONS}
          searchCallback={searchCallback}
          fullWidth
          bordered
          filterSearch
          className="nb-page-admin-landing-search"
        />
        <Button
          onClick={addButtonClickHandler}
          className="nb-page-admin-landing-add-button"
        >
          {LandingDashboardContent.Button.ADD}
        </Button>
      </Space>
      <Divider />
      {isEmpty && !isFetching ? (
        <Empty text={LandingDashboardContent.Empty} />
      ) : (
        <Table<TAdminLandingOffer>
          data={landingOffers}
          columns={columns}
          loading={isFetching}
          totalItems={landingOffers.length}
          pageSize={100}
        />
      )}
      <ActionModal
        text={LandingDashboardContent.Delete.TITLE}
        emoji={LandingDashboardContent.Delete.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={deleteSubmitHandler}
      />
      <ContentModal
        portalId="add-landing-offer-modal"
        footerId="add-landing-offer-footer"
        open={isAddModalOpen}
        title={LandingDashboardContent.Add.TITLE}
        emoji={LandingDashboardContent.Add.EMOJI}
        onClose={closeAddModal}
      >
        <AddOfferForm onSubmit={closeAddModal} />
      </ContentModal>
      <ContentModal
        portalId="edit-landing-offer-modal"
        footerId="edit-landing-offer-footer"
        open={isEditModalOpen}
        title={LandingDashboardContent.Edit.TITLE}
        emoji={LandingDashboardContent.Edit.EMOJI}
        onClose={closeEditModal}
      >
        <EditOfferForm targetOffer={selectedOffer} onSubmit={closeEditModal} />
      </ContentModal>
    </Space>
  );
};
