export enum ELessonType {
  SINGLE = "SINGLE",
  GROUP = "GROUP",
}

export enum ELessonStatus {
  SUCCESS = "SUCCESS",
  CANCELED = "CANCELED",
}

export enum ELessonContentType {
  LESSON = "LESSON",
  HOMEWORK = "HOMEWORK",
}

export type TLessonViewMode = "preview" | "constructor";

export type TLessonExerciseAnswerMode = "read-only" | "live" | "preview";

export type TLessonDuration = 30 | 45 | 60 | 90;

export type TLesson = {
  id: number;
  teacherId: number;
  type: ELessonType;
  duration: TLessonDuration;
  studentId: number | null;
  groupId: number | null;
  startDateTime: string;
  endDateTime: string;
};

export type TAddLessonBody = {
  lesson: Omit<TLesson, "id" | "endDateTime" | "studentId" | "groupId"> & {
    studentId?: number;
    groupId?: number;
  };
  repeated?: boolean;
};

export type TEditLessonBody = {
  lesson: Omit<TLesson, "duration" | "endDateTime"> & {
    duration?: TLessonDuration;
  };
  repeated?: boolean;
};

export type TLessonFilter = {
  start: string;
  end: string;
  teacherId?: number | null;
  studentId?: number | null;
  groupId?: number | null;
  type?: ELessonType;
};

export type TLessonResponse = TLesson[];
