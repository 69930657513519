import { useState } from "react";

type TUseModal = (initialModal: boolean) => {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

export const useModal: TUseModal = (initialMode) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(initialMode);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return { isModalOpen, openModal, closeModal };
};
