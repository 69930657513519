import { useMutation, useQueryClient } from "react-query";
import { ERequestQueryKeys } from "api/endpoints/requests";
import { addRequest } from "api/services/requests";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TAddRequestBody, TRequest } from "types/api/requests";

export const useAddRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<TRequest, TApiError, TAddRequestBody>(addRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(ERequestQueryKeys.getAllRequests);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
