import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  ExerciseConstructorContent,
  InputWordsIntoGapsExerciseContent,
} from "content";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { Space } from "components/Common/Space";
import { Textarea } from "components/Interactive/Textarea";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { adaptExerciseStringToData } from "./InputWordsIntoGapsExerciseForm.helpers";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import {
  EExerciseType,
  TInputWordsIntoGapsExercisePayload,
} from "types/app/exercises";
import { TInputWordsIntoGapsExerciseForm } from "./InputWordsIntoGapsExerciseForm.types";
import "./InputWordsIntoGapsExerciseForm.styles.scss";

export const InputWordsIntoGapsExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TInputWordsIntoGapsExercisePayload>();

  const { mutate: addExercise } =
    useAddExercise<TInputWordsIntoGapsExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TInputWordsIntoGapsExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TInputWordsIntoGapsExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TInputWordsIntoGapsExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TInputWordsIntoGapsExerciseForm> = useMemo(
    () => ({
      text: editExercise?.payload.text || "",
      exerciseText: editExercise?.payload.data.exerciseText || "",
    }),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<TInputWordsIntoGapsExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TInputWordsIntoGapsExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TInputWordsIntoGapsExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TInputWordsIntoGapsExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TInputWordsIntoGapsExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TInputWordsIntoGapsExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const exerciseData = adaptExerciseStringToData(formData.exerciseText);

    const addBody: TAddExercise<TInputWordsIntoGapsExercisePayload> = {
      type: EExerciseType.INPUT_WORDS_INTO_GAPS,
      payload: {
        text: formData.text,
        data: {
          exerciseText: formData.exerciseText,
          ...exerciseData,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TInputWordsIntoGapsExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TInputWordsIntoGapsExerciseForm>
          name="text"
          id="input-words-into-gaps-form-exercise-condition"
          className="nb-exercise-constructor-input-words-into-gaps-form-condition"
          label={InputWordsIntoGapsExerciseContent.Add.Input.Condition.LABEL}
          placeholder={
            InputWordsIntoGapsExerciseContent.Add.Input.Condition.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Textarea<TInputWordsIntoGapsExerciseForm>
          name="exerciseText"
          id="input-words-into-gaps-form-exercise-text"
          className="nb-exercise-constructor-input-words-into-gaps-form-text"
          label={InputWordsIntoGapsExerciseContent.Add.Input.Text.LABEL}
          placeholder={
            InputWordsIntoGapsExerciseContent.Add.Input.Text.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
          hint={InputWordsIntoGapsExerciseContent.Add.Input.Text.HINT}
        />
      </Space>
    </ExerciseConfigForm>
  );
};
