import { nanoid } from "nanoid";
import { BRACKET_CONTENT, SENTENCE_SEPARATOR } from "constants/regexp";
import { TSelectOption } from "types/app/select";
import {
  TChooseRightWordExerciseBlank,
  TChooseRightWordExerciseData,
  TExerciseSentence,
  TExerciseSentenceAnswerWithCorrectness,
  TExerciseSentenceChunk,
  TExerciseSentenceText,
} from "types/app/exercises";

const MAX_ANSWER_ATTEMPTS = 3;

/**
 * Utility function that helps to adapt raw exercise string to structured data which makes easier to work with select.
 * @param {string} exerciseText raw exercise string
 * @returns {TChooseRightWordExerciseData} object of structured `choose right word` exercise data.
 */
export const adaptExerciseStringToData = (
  exerciseText: string
): TChooseRightWordExerciseData => {
  const sentences: TExerciseSentence<TChooseRightWordExerciseBlank>[] = [];

  const allSentences = exerciseText
    .split(SENTENCE_SEPARATOR)
    .map((item) => item.trim())
    .filter(Boolean)
    .filter((item) => item.length > 1);

  for (const sentence of allSentences) {
    const rawAnswers = [...(sentence.match(BRACKET_CONTENT) || [])];
    const inputSentence = rawAnswers.reduce(
      (acc, answer) => acc.replace(answer, "[]"),
      sentence
    );
    const splitSentence = sentence
      .split(BRACKET_CONTENT)
      .map((item) => item.trim())
      .filter(Boolean);

    const sentenceChunks: TExerciseSentenceChunk<TChooseRightWordExerciseBlank>[] =
      [];

    for (const chunk of splitSentence) {
      const isBlank = chunk.startsWith("[") && chunk.endsWith("]");
      const content = isBlank ? chunk.slice(1, chunk.length - 1) : chunk;

      let blank: TChooseRightWordExerciseBlank | null = null;
      let text: TExerciseSentenceText | null = null;

      const answers: TExerciseSentenceAnswerWithCorrectness[] = isBlank
        ? chunk
            .slice(1, chunk.length - 1)
            .split("/")
            .map((item) => ({
              id: nanoid(),
              content: item.replace(/\*/g, ""),
              isCorrect: item.includes("*"),
            }))
        : [];

      const options: TSelectOption<string>[] = isBlank
        ? answers.map((item) => ({
            label: item.content,
            value: item.id as string,
          }))
        : [];

      if (isBlank) {
        blank = {
          id: nanoid(),
          answers,
          options,
          attempts: [],
          currentAnswer: null,
          currentAttempt: 0,
          maxAttempts: MAX_ANSWER_ATTEMPTS,
          isEmpty: true,
          isCorrectAnswer: false,
          isAllAttemptsFailed: false,
          hint: null, // TODO: pass hint data when API will provide this field
        };
      }

      if (!isBlank) {
        text = {
          id: nanoid(),
          content,
        };
      }

      sentenceChunks.push({
        id: nanoid(),
        raw: chunk,
        isBlank,
        blank,
        text,
      });
    }

    sentences.push({
      id: sentence,
      raw: sentence,
      inputSentence,
      splitSentence,
      chunks: sentenceChunks,
    });
  }

  return {
    sentences,
  };
};
