import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { useLazyTimeout } from "hooks/common/useLazyTimeout";
import { Divider } from "components/Common/Divider";
import { TExerciseItem } from "types/app/exercises";
import "./ExerciseListItem.styles.scss";

type ExerciseListItemProps = {
  exercise: TExerciseItem;
};

export const ExerciseListItem: React.FC<ExerciseListItemProps> = (props) => {
  const { exercise } = props;

  const animationTimeout = useLazyTimeout(220);

  const {
    openExerciseConfig,
    closeExerciseConfig,
    setCurrentExerciseType,
    isSecondaryDrawerOpen,
    currentExerciseType,
  } = useExerciseConstructor();

  const exerciseChangeHandler = () => {
    setCurrentExerciseType(exercise.type);
    openExerciseConfig();
  };

  const exerciseItemClickHandler = () => {
    if (currentExerciseType === exercise.type && isSecondaryDrawerOpen) {
      return;
    }

    if (isSecondaryDrawerOpen) {
      closeExerciseConfig();
      animationTimeout(exerciseChangeHandler);
      return;
    }

    exerciseChangeHandler();
  };

  return (
    <li className="nb-exercise-constructor-list-item-container">
      <button
        type="button"
        className="nb-exercise-constructor-list-item-button"
        onClick={exerciseItemClickHandler}
      >
        <div className="nb-exercise-constructor-list-item-wrapper">
          <div
            className="nb-exercise-constructor-list-item-image"
            aria-hidden
            style={{
              backgroundImage: `url("/assets/images/covers/exercises/${exercise.cover}")`,
            }}
          />
          <small className="nb-exercise-constructor-list-item-text" aria-hidden>
            {exercise.backgroundText}
          </small>
          <b className="nb-exercise-constructor-list-item-title">
            {exercise.title}
          </b>
        </div>
      </button>
      <Divider
        margin="yAxis"
        marginSize="large"
        color="grey-100"
        className="nb-exercise-constructor-list-item-divider"
      />
    </li>
  );
};
