import { useEffect, useState } from "react";
import cx from "classnames";
import { TInfoRadioButtonVariant } from "./InfoRadioButton.types";
import "./InfoRadioButton.styles.scss";

type TInfoRadioButton = {
  /**
   * The variant to use.
   *
   * @default 'default'
   */
  variant?: TInfoRadioButtonVariant;
  /**
   * Name attribute of the `InfoRadioButton` element.
   */
  name: string;
  /**
   * The id of the `InfoRadioButton` element.
   */
  id: string;
  /**
   * The value of the `InfoRadioButton` element.
   */
  value: string;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the `InfoRadioButton` element is required to be checked.
   *
   * @default false
   */
  required?: boolean;
  /**
   * Whether to set the initial state.
   *
   * @default false
   */
  defaultChecked?: boolean;
  /**
   * Radio button checked state, use for controlled component.
   */
  checked?: boolean;
  /**
   * If `true`, the button will be bordered.
   * @default false
   */
  bordered?: boolean;
  /**
   * If `true`, the `InfoRadioButton` will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * Trigger when the checked state is changing.
   *
   * @param {object} event - mouse button event
   * @return {void} should be voided function
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * `InfoRadioButton` is a component that allows the user to make a single choice from a set of options.
 */
export const InfoRadioButton: React.FC<TInfoRadioButton> = (props) => {
  const {
    variant = "default",
    id,
    value,
    className,
    disabled = false,
    fullWidth = false,
    defaultChecked = false,
    bordered = false,
    checked,
    children,
    name,
    required = false,
    onChange,
  } = props;

  const [innerChecked, setInnerChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    if (checked !== undefined) {
      setInnerChecked(checked);
    }
  }, [checked]);

  const radioButtonChangeHandler = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (disabled) {
      evt.preventDefault();
      return;
    }

    onChange?.(evt);
  };

  return (
    <div
      className={cx([
        "nb-interactive-info-radio-button-wrapper",
        {
          "nb-interactive-info-radio-button-wrapper--full": fullWidth,
        },
        className,
      ])}
    >
      <input
        type="radio"
        id={id}
        name={name}
        required={required}
        disabled={disabled}
        value={value}
        checked={innerChecked}
        className="nb-interactive-info-radio-button"
        onChange={radioButtonChangeHandler}
      />
      <label
        className={cx([
          "nb-interactive-info-radio-button-label",
          {
            "nb-interactive-info-radio-button-label--success":
              variant === "success",
            "nb-interactive-info-radio-button-label--error":
              variant === "error",
            "nb-interactive-info-radio-button-label--bordered": bordered,
          },
        ])}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
};
