import { toast } from "react-toastify";
import { NotificationContent } from "content";
import { TApiError } from "types/api/error";
import "./notification.styles.scss";

type TLogError = (option?: { error?: TApiError; message?: string }) => void;

export const logError: TLogError = (options) => {
  const { error, message } = options || {};

  const logMessage =
    message || // Hardcoded message should have higher priority
    error?.response?.data?.message || // Api error message
    error?.message || // Axios error message
    NotificationContent.Error.DEFAULT; // Fall back to default message

  toast.error(logMessage, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4000,
    className: "nb-notification-container nb-notification-container--error",
    bodyClassName: "nb-notification-body",
    icon: NotificationContent.Error.ICON,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
  });
};
