import { forwardRef, useMemo } from "react";
import cx from "classnames";
import { Text } from "components/Typography/Text";
import { Avatar } from "components/Common/Avatar";
import { AvatarListContent } from "content";
import "./AvatarList.styles.scss";

type AvatarListProps = {
  /**
   * TODO: Update this prop and component logic when users photo will be available.
   *
   * The array of users data to get photos or emojis for avatars.
   */
  userData: number;
  /**
   * Number of `Avatar` components to show in the list.
   * @default '3'
   */
  avatarsToShow?: number;
  /**
   * The size of the avatar.
   * @default 'medium'
   */
  size?: "small" | "medium" | "large";
  /**
   * The text content of the `AvatarList` component if data length is `0`.
   */
  emptyText?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

const AvatarList: React.ForwardRefRenderFunction<
  HTMLDivElement,
  AvatarListProps
> = (props, ref) => {
  const {
    userData,
    avatarsToShow = 3,
    size = "medium",
    emptyText = AvatarListContent.Default.EMPTY,
    className,
  } = props;

  const { avatarsAmount, label } = useMemo(() => {
    const avatarsAmount =
      userData >= avatarsToShow ? avatarsToShow : Math.max(userData, 0);
    return {
      avatarsAmount,
      label: Math.max(userData - avatarsAmount, 0),
    };
  }, [userData, avatarsToShow]);

  return (
    <div
      ref={ref}
      className={cx([
        "nb-common-avatar-list",
        `nb-common-avatar-list--${size}`,
        className,
      ])}
    >
      {userData === 0 ? (
        <Text variant="body2" className="nb-common-avatar-list-label">
          {emptyText}
        </Text>
      ) : (
        <>
          <div className="nb-common-avatar-list-wrapper">
            {Array(avatarsAmount)
              .fill(null)
              .map((_, index) => (
                <Avatar
                  key={index}
                  size={size}
                  bordered
                  className={cx("nb-common-avatar-list-item", {
                    "nb-common-avatar-list-item--overlap": index !== 0,
                  })}
                />
              ))}
          </div>
          {label > 0 && (
            <Text variant="body2" className="nb-common-avatar-list-label">
              +{label}
            </Text>
          )}
        </>
      )}
    </div>
  );
};

/**
 * `AvatarList` is a common component to display array of user photos or emojis if first not provided
 */
export const ForwardedAvatarList = forwardRef(AvatarList);
