import { MenuProps, components } from "react-select";
import cx from "classnames";
import { TSelectOption } from "types/app/select";
import "./Menu.styles.scss";

type MenuCustomProps<
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
> = {
  bordered: boolean;
} & MenuProps<Option, false>;

export const Menu = <
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>(
  props: MenuCustomProps<Option>
): JSX.Element => {
  const { bordered } = props;

  return (
    <components.Menu
      {...props}
      className={cx([
        "nb-interactive-search-menu",
        { "nb-interactive-search-menu--bordered": bordered },
      ])}
    >
      {props.children}
    </components.Menu>
  );
};
