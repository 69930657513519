export const ButtonExercise = {
  Add: {
    Input: {
      Text: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Label: {
        LABEL: "Текст на кнопці",
        PLACEHOLDER: "Вікіпедія",
      },
      Url: {
        LABEL: "Посилання",
        PLACEHOLDER: "Вставте посилання на матеріал",
      },
    },
  },
};
