import { useCallback, useRef, useState } from "react";
import cx from "classnames";
import { useKeyPress, useLazyTimeout, useOutsideClick } from "hooks/common";
import {
  TDropdownPosition,
  TDropdownSpaceSize,
  TDropdownTrigger,
} from "./Dropdown.types";
import "./Dropdown.styles.scss";

type DropdownProps = {
  /**
   * The content that will be shown in the dropdown.
   */
  content?: React.ReactNode;
  /**
   * The position of the dropdown content relative to the target.
   * @default "top"
   */
  position?: TDropdownPosition;
  /**
   * Dropdown trigger mode
   * @default "hover"
   */
  trigger?: TDropdownTrigger;
  /**
   * Space size between children and dropdown itself.
   * @namespace {
   *  none: 0px,
   *  x-small: 5px,
   *  small: 10px,
   *  medium: 15px,
   *  large: 20px,
   *  x-large: 25px,
   *  xx-large: 30px
   * }
   * @default "none"
   */
  spaceSize?: TDropdownSpaceSize;
  /**
   * If `true`, the dropdown content will be bordered.
   * @default false
   */
  bordered?: boolean;
  /**
   * If `true`, the dropdown is disabled which means it wont open.
   * @default false
   */
  disabled?: boolean;
  /**
   * Whether the floating dropdown content is open by default.
   * @default false
   */
  defaultVisible?: boolean;
  /**
   * Whether the dropdown content should be closed when interactive item was clicked inside dropdown.
   * @default false
   */
  closeOnItemClick?: boolean;
  /**
   * Delay in seconds, before dropdown is shown on mouse enter.
   * @default 0.1
   */
  mouseEnterDelay?: number;
  /**
   * Delay in seconds, before dropdown is hidden on mouse leave.
   * @default 0.1
   */
  mouseLeaveDelay?: number;
  /**
   * Callback executed when visibility of the dropdown card is changed.
   * @param visible - current `visible` state of the dropdown.
   */
  onVisibilityChange?: (visible: boolean) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

/**
 * `Dropdown` displays custom content such as menu, buttons, etc when user hover over or tap an element.
 */
export const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    content,
    position = "top",
    trigger = "hover",
    spaceSize = "none",
    defaultVisible = false,
    bordered = false,
    disabled = false,
    closeOnItemClick = false,
    mouseEnterDelay = 0.1,
    mouseLeaveDelay = 0.1,
    onVisibilityChange,
    children,
    className,
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState<boolean>(defaultVisible);

  const mouseEnterTimeout = useLazyTimeout(mouseEnterDelay);
  const mouseLeaveTimeout = useLazyTimeout(mouseLeaveDelay);

  const showDropdownHandler = useCallback(() => {
    setVisible(true);
    onVisibilityChange?.(true);
  }, [onVisibilityChange]);

  const hideDropdownHandler = useCallback(() => {
    setVisible(false);
    onVisibilityChange?.(false);
  }, [onVisibilityChange]);

  useKeyPress("Escape", hideDropdownHandler);

  const dropdownMouseEnterHandler = () => {
    if (trigger !== "hover") {
      return;
    }
    mouseEnterTimeout(showDropdownHandler);
  };

  const dropdownMouseLeaveHandler = () => {
    if (trigger !== "hover") {
      return;
    }
    mouseLeaveTimeout(hideDropdownHandler);
  };

  const dropdownClickHandler = () => {
    if (trigger !== "click") {
      return;
    }
    setVisible((prevState) => {
      onVisibilityChange?.(!prevState);
      return !prevState;
    });
  };

  const checkTargetNodeNesting = (target: Node): boolean => {
    // Workaround for dropdown closing on menu item click.
    // Problem: we can pass various JSX structure in `content` prop.
    // So in this case we need to capture interactive elements and close dropdown on click.
    // As for now we are capturing an attribute `data-dropdown-closable` inside dropdown.
    // Maybe we need to find better solution for this logic.
    const isChildOfDropdown =
      ref.current && ref.current.contains(target as Node);
    if (!isChildOfDropdown) {
      return false;
    }

    const closeableElements =
      ref.current?.querySelectorAll(`[data-dropdown-closable="true"]`) || [];

    const isTargetChildOfClosable = [...closeableElements].some((element) =>
      element.contains(target)
    );

    return isTargetChildOfClosable;
  };

  const outsideDropdownClickHandler = () => {
    if (trigger !== "click") {
      return;
    }
    hideDropdownHandler();
  };

  const insideDropdownClickHandler = (event: MouseEvent) => {
    if (trigger !== "click") {
      return;
    }

    const isClosableElementInsideDropdown =
      closeOnItemClick && checkTargetNodeNesting(event.target as Node);

    if (isClosableElementInsideDropdown) {
      hideDropdownHandler();
    }
  };

  useOutsideClick(ref, outsideDropdownClickHandler, insideDropdownClickHandler);

  return (
    <div
      ref={ref}
      className="nb-interactive-dropdown-container"
      onMouseEnter={dropdownMouseEnterHandler}
      onMouseLeave={dropdownMouseLeaveHandler}
      onClick={dropdownClickHandler}
      tabIndex={-1}
    >
      {children}
      <div
        aria-hidden={disabled}
        role="menu"
        className={cx([
          "nb-interactive-dropdown-content",
          {
            "nb-interactive-dropdown-content--visible": visible,
            "nb-interactive-dropdown-content--disabled": disabled,
            "nb-interactive-dropdown-content--bordered": bordered,
            "nb-interactive-dropdown-content--top": position === "top",
            "nb-interactive-dropdown-content--top-start":
              position === "top-start",
            "nb-interactive-dropdown-content--top-end": position === "top-end",
            "nb-interactive-dropdown-content--right": position === "right",
            "nb-interactive-dropdown-content--right-start":
              position === "right-start",
            "nb-interactive-dropdown-content--right-end":
              position === "right-end",
            "nb-interactive-dropdown-content--bottom": position === "bottom",
            "nb-interactive-dropdown-content--bottom-start":
              position === "bottom-start",
            "nb-interactive-dropdown-content--bottom-end":
              position === "bottom-end",
            "nb-interactive-dropdown-content--left": position === "left",
            "nb-interactive-dropdown-content--left-start":
              position === "left-start",
            "nb-interactive-dropdown-content--left-end":
              position === "left-end",
            "nb-interactive-dropdown-content--size-xsm":
              spaceSize === "x-small",
            "nb-interactive-dropdown-content--size-sm": spaceSize === "small",
            "nb-interactive-dropdown-content--size-md": spaceSize === "medium",
            "nb-interactive-dropdown-content--size-l": spaceSize === "large",
            "nb-interactive-dropdown-content--size-xl": spaceSize === "x-large",
            "nb-interactive-dropdown-content--size-xxl":
              spaceSize === "xx-large",
          },
          className,
        ])}
        onClick={(evt) => evt.stopPropagation()}
        tabIndex={-1}
      >
        <div className="nb-interactive-dropdown-main">{content}</div>
      </div>
    </div>
  );
};
