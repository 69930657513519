import { Fragment } from "react";
import {
  ArrayPath,
  FieldArrayWithId,
  FieldErrorsImpl,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { InputItem } from "../InputItem";
import { TPairWordsInputDefaultValue } from "../PairWordsInput.types";
import "./InputList.styles.scss";

type TInputListProps<
  TFormValues extends TPairWordsInputDefaultValue = TPairWordsInputDefaultValue
> = {
  /**
   * Name attribute of the `PairWordsInput` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: ArrayPath<TFormValues>;
  /**
   * This object contains the defaultValue and key of the `react-hook-form` fields.
   */
  fields: FieldArrayWithId<TFormValues, ArrayPath<TFormValues>, "id">[];
  /**
   * The short hint displayed in the word `input` before the user enters a value.
   */
  wordPlaceholder?: string;
  /**
   * The short hint displayed in the linked word `input` before the user enters a value.
   */
  linkWordPlaceholder?: string;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (inputIndex: number) => void;
};

export const InputList = <
  TFormValues extends TPairWordsInputDefaultValue = TPairWordsInputDefaultValue
>(
  props: TInputListProps<TFormValues>
): JSX.Element => {
  const {
    name,
    register,
    fields,
    wordPlaceholder,
    linkWordPlaceholder,
    errors,
    onDelete,
  } = props;

  return (
    <Space direction="vertical" justify="start" size="x-small" fullWidth>
      {fields.map((item, index, self) => (
        <Fragment key={item.id}>
          <InputItem<TFormValues>
            id={item.id}
            index={index}
            name={`${name}.${index}` as Path<TFormValues>}
            wordPlaceholder={wordPlaceholder}
            linkWordPlaceholder={linkWordPlaceholder}
            disableDelete={self.length === 1}
            register={register}
            errors={errors}
            onDelete={() => onDelete?.(index)}
          />
          <Divider margin="yAxis" marginSize="small" />
        </Fragment>
      ))}
    </Space>
  );
};
