import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import cx from "classnames";
import { useTeachers } from "hooks/api/teachers";
import { useStudents } from "hooks/api/students";
import { useGroups } from "hooks/api/groups";
import { useRole } from "hooks/common";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { Select } from "components/Interactive/Select";
import { Paragraph } from "components/Typography/Paragraph";
import { DateControls } from "../DateControls";
import { ScheduleContent } from "content";
import { TGroup } from "types/api/groups";
import { TStudent } from "types/api/student";
import { TTeacher } from "types/api/teacher";
import {
  TScheduleControlsForm,
  TScheduleControlsItemOption,
} from "./ScheduleControls.types";
import "./ScheduleControls.styles.scss";

type ScheduleControlsProps = {
  /**
   * Callback function will fire on select teacher change event.
   *
   * @param teacherId - the new selected teacher ID value or undefined if select was reset.
   */
  onTeacherChange?: (teacherId?: number) => void;
  /**
   * Callback function will fire on select group change event.
   *
   * @param groupId - the new selected group ID value or undefined if select was reset.
   */
  onGroupChange?: (groupId?: number) => void;
  /**
   * Callback function will fire on select student change event.
   *
   * @param studentId - the new selected student ID value or undefined if select was reset.
   */
  onStudentChange?: (studentId?: number) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const ScheduleControls: React.FC<ScheduleControlsProps> = (props) => {
  const { onTeacherChange, onGroupChange, onStudentChange, className } = props;

  const [title, setTitle] = useState<string>(
    ScheduleContent.Controls.Title.DEFAULT
  );

  const { isUserStudent, isUserTeacher, isUserAdmin } = useRole();
  const { activeUser } = useActiveUser();

  const { data: teachersData } = useTeachers({
    blocked: isUserStudent || isUserTeacher,
  });
  const { data: studentsData } = useStudents({ blocked: isUserStudent });
  const { data: groupsData } = useGroups({ blocked: isUserStudent });

  const teachers: TTeacher[] = useMemo(
    () => teachersData?.content || [],
    [teachersData]
  );
  const students: TStudent[] = useMemo(
    () => studentsData?.content || [],
    [studentsData]
  );
  const groups: TGroup[] = useMemo(
    () => groupsData?.content || [],
    [groupsData]
  );

  const { control, watch } = useForm<TScheduleControlsForm>();

  const { teacher, group, student } = watch();

  const isGroupSelectVisible: boolean = !student && isUserAdmin;
  const isStudentSelectVisible: boolean = !group && !isUserStudent;

  const teacherOptions: TScheduleControlsItemOption[] = useMemo(
    () =>
      teachers.map((teacher) => ({
        label: `${teacher.person.firstName} ${teacher.person.lastName}`,
        value: teacher.id,
      })),
    [teachers]
  );

  const groupOptions: TScheduleControlsItemOption[] = useMemo(
    () =>
      groups.map((group) => ({
        label: group.name,
        value: group.id,
      })),
    [groups]
  );

  const studentOptions: TScheduleControlsItemOption[] = useMemo(
    () =>
      students.map((student) => ({
        label: `${student.person?.firstName} ${student.person?.lastName}`,
        value: student.id,
      })),
    [students]
  );

  useEffect(() => {
    /** Setting page name depending on selected teacher */
    if (isUserTeacher && activeUser) {
      const newTitle = `${ScheduleContent.Controls.Title.GREETING} ${activeUser.firstName}!`;
      setTitle(newTitle);
      return;
    }
    if (teacher?.value) {
      const newTitle = `${ScheduleContent.Controls.Title.PREFIX} ${teacher.label}`;
      setTitle(newTitle);
      /** Here we can handle teacher change event */
      onTeacherChange?.(teacher?.value);
    }
    if (!teacher) {
      setTitle(ScheduleContent.Controls.Title.DEFAULT);
    }
  }, [teacher?.value, onTeacherChange, teacher, isUserTeacher, activeUser]);

  useEffect(() => {
    /** Here we can handle group change event */
    onGroupChange?.(group?.value);
  }, [group?.value, onGroupChange]);

  useEffect(() => {
    /** Here we can handle student change event */
    onStudentChange?.(student?.value);
  }, [student?.value, onStudentChange]);

  if (isUserStudent) {
    return null;
  }

  return (
    <section className={cx(["nb-sub-schedule-controls-container", className])}>
      <Paragraph font="ptmono" bold className="nb-sub-schedule-controls-title">
        {title}
      </Paragraph>
      <div className="nb-sub-schedule-controls-wrapper">
        {isUserAdmin && (
          <Select<TScheduleControlsForm, TScheduleControlsItemOption>
            name="teacher"
            id="schedule-teacher"
            className="nb-sub-schedule-controls-select"
            control={control}
            options={teacherOptions}
            placeholder={ScheduleContent.Controls.Teacher.PLACEHOLDER}
            noOptionsText={ScheduleContent.Controls.Teacher.EMPTY}
            searchable
            clearable
          />
        )}
        {isGroupSelectVisible && (
          <Select<TScheduleControlsForm, TScheduleControlsItemOption>
            name="group"
            id="schedule-group"
            className="nb-sub-schedule-controls-select"
            control={control}
            options={groupOptions}
            placeholder={ScheduleContent.Controls.Group.PLACEHOLDER}
            noOptionsText={ScheduleContent.Controls.Group.EMPTY}
            searchable
            clearable
          />
        )}
        {isStudentSelectVisible && (
          <Select<TScheduleControlsForm, TScheduleControlsItemOption>
            name="student"
            id="schedule-student"
            className="nb-sub-schedule-controls-select"
            control={control}
            options={studentOptions}
            placeholder={ScheduleContent.Controls.Student.PLACEHOLDER}
            noOptionsText={ScheduleContent.Controls.Student.EMPTY}
            searchable
            clearable
          />
        )}
        <DateControls />
      </div>
    </section>
  );
};
