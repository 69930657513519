import cx from "classnames";
import { FileUploadContent } from "content";
import { ContentModal } from "components/Common/ContentModal";
import { ImageUpload } from "components/Interactive/ImageUpload";
import type { TImageUploadProps } from "components/Interactive/ImageUpload";
import "./ImageUploadModal.styles.scss";

export type TImageUploadModalProps = Omit<TImageUploadProps, "className"> & {
  /**
   * If `true`, the modal is shown.
   * @default false
   */
  open?: boolean;
  /**
   * The title of the modal component.
   */
  title?: string;
  /**
   * The emoji before modal text content.
   */
  emoji?: string;
  /**
   * Override react portal container id.
   * @default "upload-user-image-modal"
   */
  portalId?: string;
  /**
   * Override or extend the styles applied to the component wrapper.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component content wrapper.
   */
  contentClassName?: string;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: () => void;
};

export const ImageUploadModal: React.FC<TImageUploadModalProps> = (props) => {
  const {
    open,
    title = FileUploadContent.Modal.TITLE,
    emoji = FileUploadContent.Modal.EMOJI,
    portalId = "upload-user-image-modal",
    className,
    contentClassName,
    onClose,
    ...fileUploadProps
  } = props;

  return (
    <ContentModal
      portalId={portalId}
      open={open}
      title={title}
      emoji={emoji}
      onClose={onClose}
      contentClassName={contentClassName}
      modalClassName="nb-interactive-image-upload-modal"
      className={cx(["nb-interactive-image-upload-container", className])}
    >
      <ImageUpload {...fileUploadProps} />
    </ContentModal>
  );
};
