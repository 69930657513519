import { useImageUpload } from "hooks/redux";
import { useLazyTimeout } from "hooks/common/useLazyTimeout";
import { CourseDetailsContent } from "content";
import { ContentModal } from "components/Common/ContentModal";
import { EditCourseLessonForm } from "../EditCourseLessonForm";
import { TCourseLesson } from "types/api/course";
import "./EditCourseLessonModal.styles.scss";

type EditCourseLessonModalProps = {
  /**
   * Course lesson data for default values in `EditCourseLessonForm` component.
   */
  courseLesson: TCourseLesson | null;
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
};

export const EditCourseLessonModal: React.FC<EditCourseLessonModalProps> = (
  props
) => {
  const { courseLesson, open = false, onClose } = props;

  const { resetAllImageUploadStates } = useImageUpload();
  const resetDelay = useLazyTimeout(300);

  const modalCloseHandler = () => {
    onClose?.();
    resetDelay(resetAllImageUploadStates);
  };

  return (
    <ContentModal
      portalId="edit-course-lesson-modal"
      footerId="edit-course-lesson-modal-footer"
      open={open}
      title={CourseDetailsContent.Edit.Lesson.TITLE}
      emoji={CourseDetailsContent.Edit.Lesson.EMOJI}
      onClose={modalCloseHandler}
    >
      <EditCourseLessonForm
        courseLesson={courseLesson}
        onSubmit={modalCloseHandler}
      />
    </ContentModal>
  );
};
