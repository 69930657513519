import { OptionProps, components } from "react-select";
import cx from "classnames";
import { TSelectDefaultOption } from "../../Select.types";
import "./Option.styles.scss";

type SelectOptionProps<
  Option extends TSelectDefaultOption = TSelectDefaultOption
> = {
  /**
   * If `true`, the dropdown indicator will have style modifications.
   * @default false
   */
  isLabelsJSXElements?: boolean;
} & OptionProps<Option, false>;

export const Option = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>({
  isLabelsJSXElements = false,
  isFocused,
  isSelected,
  ...optionProps
}: SelectOptionProps<Option>): JSX.Element => (
  <components.Option
    {...optionProps}
    isFocused={isFocused}
    isSelected={isSelected}
    className={cx([
      "nb-interactive-select-option",
      {
        "nb-interactive-select-option--selected": isSelected,
        "nb-interactive-select-option--element": isLabelsJSXElements,
      },
    ])}
  />
);
