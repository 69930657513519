import { useDraggable } from "@dnd-kit/core";
import { Draggable } from "../Draggable";
import { TExerciseSentenceAnswer } from "types/app/exercises";
import "./DraggableItem.styles.scss";

type TDraggableItemProps = {
  /**
   * Answer data item for the draggable component.
   */
  answer: TExerciseSentenceAnswer;
  /**
   * Indicates if draggable answer is placed in correct blank.
   * @default false
   */
  correct?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
};

export const DraggableItem: React.FC<TDraggableItemProps> = (props) => {
  const { answer, correct = false, showResult = false } = props;

  const { isDragging, setNodeRef, listeners } = useDraggable({
    id: answer.id,
    data: { answer },
    disabled: showResult,
  });

  return (
    <Draggable
      ref={setNodeRef}
      dragging={isDragging}
      listeners={listeners}
      content={answer.content}
      correct={correct}
      showResult={showResult}
    />
  );
};
