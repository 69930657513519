import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useCurrentPath } from "hooks/common/useCurrentPath";
import { useBreadcrumbs } from "hooks/redux/useBreadcrumbs";
import { Text } from "components/Typography/Text";
import { BreadcrumbsContent } from "content";
import { breadcrumbs } from "constants/breadcrumbs";
import "./Breadcrumbs.styles.scss";

export const Breadcrumbs: React.FC = () => {
  const pathname = useCurrentPath();
  const { pageName } = useBreadcrumbs();

  const currentBreadcrumbs = useMemo(
    () =>
      breadcrumbs.find(
        (breadcrumb) =>
          breadcrumb.route.path.trim().toLowerCase() ===
          pathname?.trim().toLowerCase()
      ),
    [pathname]
  );

  if (!currentBreadcrumbs) {
    return (
      <Text bold font="ptmono" className="nb-breadcrumbs-route-current">
        <Text className="nb-breadcrumbs-emoji">
          {BreadcrumbsContent.Fallback.EMOJI}
        </Text>
        {BreadcrumbsContent.Fallback.TITLE}
      </Text>
    );
  }

  return (
    <nav className="nb-breadcrumbs-container">
      <Text className="nb-breadcrumbs-emoji">
        {currentBreadcrumbs.route.emoji}
      </Text>
      <ul className="nb-breadcrumbs-list">
        {currentBreadcrumbs.preRoutes.map((route) => (
          <li key={route.key} className="nb-breadcrumbs-route-item">
            <Link className="nb-breadcrumbs-route-link" to={route.path}>
              {route.name}
            </Link>
            <span className="nb-breadcrumbs-item-separator">
              {BreadcrumbsContent.SEPARATOR}
            </span>
          </li>
        ))}
        <li className="nb-breadcrumbs-route-item">
          <Text bold font="ptmono" className="nb-breadcrumbs-route-current">
            {currentBreadcrumbs.route.isDynamicName
              ? pageName || currentBreadcrumbs.route.name
              : currentBreadcrumbs.route.name}
          </Text>
        </li>
      </ul>
    </nav>
  );
};
