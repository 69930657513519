import {
  Control,
  FieldErrorsImpl,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { Space } from "components/Common/Space";
import { IconButton } from "components/Interactive/IconButton";
import { TrueOrFalseAssertion } from "../TrueOrFalseAssertion";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { TAssertionSentencesDefaultValue } from "../AssertionSentences.types";
import "./AssertionItem.styles.scss";

type TAssertionItemProps<
  TFormValues extends TAssertionSentencesDefaultValue = TAssertionSentencesDefaultValue
> = {
  /**
   * Name attribute of the `AssertionSentences` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: Path<TFormValues>;
  /**
   * Index of the assertion sentence from `react-hook-form` array fields.
   */
  index: number;
  /**
   * The id that `react-hook-form` created to associate `input` element and form array fields.
   */
  id?: string;
  /**
   * The short hint displayed in the assertion `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * If `true`, the delete assertion button is disabled. Used for preventing deletion of only one assertion sentence.
   * @default false
   */
  disableDelete?: boolean;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const AssertionItem = <
  TFormValues extends TAssertionSentencesDefaultValue = TAssertionSentencesDefaultValue
>(
  props: TAssertionItemProps<TFormValues>
): JSX.Element => {
  const {
    name,
    index,
    id,
    placeholder,
    control,
    register,
    errors,
    disableDelete = false,
    onDelete,
  } = props;

  return (
    <Space justify="between" align="start" size="x-small">
      <TrueOrFalseAssertion<TFormValues>
        assertionIndex={index}
        name={name}
        id={id}
        placeholder={placeholder}
        control={control}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <IconButton
        variant="secondary"
        size="medium"
        icon={<Close />}
        className="nb-interactive-assertion-item-remove"
        onClick={onDelete}
        disabled={disableDelete}
      />
    </Space>
  );
};
