import { nanoid } from "nanoid";
import {
  EXERCISE_DURATION_OPTIONS,
  TRUE_OR_FALSE_EXERCISE_OPTIONS,
} from "utils/options/exercise";
import { ensure } from "utils/common";
import { TTrueOrFalseExercisePayload } from "types/app/exercises";
import { TExercise } from "types/api/exercise";
import { TTrueOrFalseExerciseForm } from "./TrueOrFalseExerciseForm.types";

const [firstTimerOption] = EXERCISE_DURATION_OPTIONS;
const [firstAssertionOption] = TRUE_OR_FALSE_EXERCISE_OPTIONS;

export const getTrueOrFalseExerciseFormInitialValues = (
  editExercise: TExercise<TTrueOrFalseExercisePayload> | null
): TTrueOrFalseExerciseForm => ({
  text: editExercise?.payload.text || "",
  timer:
    EXERCISE_DURATION_OPTIONS.find(
      (option) => option.value === editExercise?.payload.data.timer
    ) || firstTimerOption,
  answers: editExercise?.payload.data.answers.map((answer) => ({
    ...answer,
    isCorrect: ensure(
      TRUE_OR_FALSE_EXERCISE_OPTIONS.find(
        (option) => option.value === answer.isCorrect
      )
    ),
  })) || [
    {
      id: nanoid(),
      content: "",
      isCorrect: firstAssertionOption,
    },
  ],
});
