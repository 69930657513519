import { useMutation, useQueryClient } from "react-query";
import { EExercisesQueryKeys } from "api/endpoints/exercises";
import { addExercise } from "api/services/exercises";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TAddExercise, TExercise } from "types/api/exercise";
import { TUrlParam } from "types/utils";

export const useAddExercise = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam
) => {
  const queryClient = useQueryClient();
  return useMutation<
    TExercise<TPayload, TText>,
    TApiError,
    TAddExercise<TPayload, TText>
  >(
    (chapterExercise: TAddExercise<TPayload, TText>) =>
      addExercise<TPayload, TText>(
        courseId,
        lessonId,
        chapterId,
        chapterExercise
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EExercisesQueryKeys.getExercises);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
