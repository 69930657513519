export const GroupDetails = {
  Name: "Група",
  Emoji: "👩",
  Search: {
    PLACEHOLDER: "Пошук по учасникам групи",
    NO_OPTIONS: "Учасників не знайдено",
  },
  Table: {
    Header: {
      NUMBER: "№",
      AVATAR: "Фото",
      NAME: "Ім'я",
      PHONE: "Телефон",
      ACTION: "Дії",
    },
    Button: {
      DELETE: "Видалити",
    },
  },
  Empty:
    "Список учасників групи порожній. Натисніть “Додати студента”, щоб почати роботу 💪",
  Delete: {
    TITLE: "Ви дійсно хочете видалити учасника групи?",
    EMOJI: "😥",
    Notification: {
      SUCCESS: "Учасника групи успішно видалено",
      ERROR: "Не вдалося видалити учасника групи",
    },
  },
  Add: {
    TITLE: "Додати студента",
    EMOJI: "👩",
    SUBMIT: "Додати в групу",
    Input: {
      Participants: {
        PLACEHOLDER: "Пошук студента...",
      },
    },
    Notification: {
      SUCCESS: "Студента(-ів) успішно додано в групу",
      ERROR: "Не вдалось додати студента(-ів) в групу",
    },
  },
  Hero: {
    Action: {
      Button: {
        MATERIALS: "Додати матеріал",
        ADD_STUDENTS: "Додати студента",
      },
    },
  },
};
