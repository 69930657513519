import { forwardRef } from "react";
import cx from "classnames";
import { Space } from "components/Common/Space";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import "./RadioInput.styles.scss";

type TRadioInputProps = {
  /**
   * Input label text for the `RadioInput` element.
   */
  label?: string;
  /**
   * The id of the `RadioInput` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * Name attribute of the `RadioInput` element.
   */
  name?: string;
  /**
   * Value attribute of the `RadioInput` element.
   */
  value?: string;
  /**
   * If `true`, the `RadioInput` will be checked.
   * @default false
   */
  checked?: boolean;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Callback will be triggered when the checkmark state was changed.
   *
   * @param event - react input change event
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TRadioInputProps
> = (props, ref) => {
  const {
    label,
    id,
    name,
    value,
    disabled = false,
    checked = false,
    className,
    onChange,
  } = props;

  return (
    <Space
      direction="horizontal"
      justify="start"
      align="center"
      size="x-small"
      className={cx(["nb-interactive-radio-group-input-container", className])}
    >
      <div className="nb-interactive-radio-group-input-wrapper">
        <input
          ref={ref}
          type="radio"
          aria-label={label}
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className="nb-interactive-radio-group-input"
        />
        <div
          className={cx([
            "nb-interactive-radio-group-input-tick",
            {
              "nb-interactive-radio-group-input-tick--checked": checked,
            },
          ])}
        >
          <TickIcon
            className={cx([
              "nb-interactive-radio-group-input-tick-icon",
              {
                "nb-interactive-radio-group-input-tick-icon--checked": checked,
              },
            ])}
            width={10}
            height={7}
          />
        </div>
      </div>
      {label && (
        <label className="nb-interactive-radio-group-input-label" htmlFor={id}>
          {label}
        </label>
      )}
    </Space>
  );
};

/**
 * `RadioInput` is interactive component which allows to toggle the state of a circle radio button with a checkmark.
 */
export const ForwardedRadioInput = forwardRef(RadioInput);
