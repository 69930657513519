import { Cell, flexRender } from "@tanstack/react-table";
import cx from "classnames";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import type { DraggableAttributes } from "@dnd-kit/core/dist/";
import { ReactComponent as Swap } from "assets/icons/swap.svg";
import { getCellHackedSize } from "../Table.utils";
import { TDefaultTableData } from "../Table.types";
import "./TableCell.styles.scss";

type TableCellProps<TData extends TDefaultTableData = TDefaultTableData> = {
  /**
   * Cell object generated in `react-table` to operate with data.
   */
  cell: Cell<TData, unknown>;
  /**
   * If `true`, the drag'n'drop icon is shown with draggable attributes and listeners.
   * @default false
   */
  showDragIcon?: boolean;
  /**
   * The useSortable hook provides a set of sensible default attributes for draggable items.
   * @link https://docs.dndkit.com/presets/sortable/usesortable#attributes
   */
  draggableAttributes?: DraggableAttributes;
  /**
   * The listeners property contains the activator event handlers for each Sensor that is defined on the parent DndContext provider.
   * @link https://docs.dndkit.com/presets/sortable/usesortable#listeners
   */
  draggableListeners?: SyntheticListenerMap;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const TableCell = <TData extends TDefaultTableData = TDefaultTableData>(
  props: TableCellProps<TData>
): JSX.Element => {
  const {
    cell,
    showDragIcon = false,
    className,
    draggableAttributes,
    draggableListeners,
  } = props;

  return (
    <td
      className={cx(["nb-table-body-cell", className])}
      style={{ width: getCellHackedSize(cell.column.getSize()) }}
    >
      <div className="nb-table-body-cell-inner-wrapper">
        {showDragIcon && (
          <div
            className="nb-table-body-cell-inner-icon"
            {...draggableAttributes}
            {...draggableListeners}
          >
            <Swap width={15} height={15} />
          </div>
        )}
        <div className="nb-table-body-cell-inner">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      </div>
    </td>
  );
};
