import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  ChooseRightWordFormExerciseContent,
  ExerciseConstructorContent,
} from "content";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { Space } from "components/Common/Space";
import { Textarea } from "components/Interactive/Textarea";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { adaptExerciseStringToData } from "./ChooseRightWordFormExerciseForm.helpers";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import {
  EExerciseType,
  TChooseRightWordFormExercisePayload,
} from "types/app/exercises";
import { TChooseRightWordFormExerciseForm } from "./ChooseRightWordFormExerciseForm.types";
import "./ChooseRightWordFormExerciseForm.styles.scss";

export const ChooseRightWordFormExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TChooseRightWordFormExercisePayload>();

  const { mutate: addExercise } =
    useAddExercise<TChooseRightWordFormExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TChooseRightWordFormExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TChooseRightWordFormExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TChooseRightWordFormExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TChooseRightWordFormExerciseForm> = useMemo(
    () => ({
      text: editExercise?.payload.text || "",
      exerciseText: editExercise?.payload.data.exerciseText || "",
    }),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<TChooseRightWordFormExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TChooseRightWordFormExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TChooseRightWordFormExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TChooseRightWordFormExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TChooseRightWordFormExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (
    formData: TChooseRightWordFormExerciseForm
  ) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const exerciseData = adaptExerciseStringToData(formData.exerciseText);

    const addBody: TAddExercise<TChooseRightWordFormExercisePayload> = {
      type: EExerciseType.CHOOSE_RIGHT_WORD_FORM,
      payload: {
        text: formData.text,
        data: {
          exerciseText: formData.exerciseText,
          ...exerciseData,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TChooseRightWordFormExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TChooseRightWordFormExerciseForm>
          name="text"
          id="choose-right-word-form-exercise-condition"
          className="nb-exercise-constructor-choose-right-word-form-condition"
          label={ChooseRightWordFormExerciseContent.Add.Input.Condition.LABEL}
          placeholder={
            ChooseRightWordFormExerciseContent.Add.Input.Condition.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Textarea<TChooseRightWordFormExerciseForm>
          name="exerciseText"
          id="choose-right-word-form-exercise-text"
          className="nb-exercise-constructor-choose-right-word-form-text"
          label={ChooseRightWordFormExerciseContent.Add.Input.Text.LABEL}
          placeholder={
            ChooseRightWordFormExerciseContent.Add.Input.Text.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
          hint={ChooseRightWordFormExerciseContent.Add.Input.Text.HINT}
        />
      </Space>
    </ExerciseConfigForm>
  );
};
