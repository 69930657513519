/**
 * The simplest method to generate color value based on random string.
 * @param baseString - string to base color value.
 * @returns hex value of some particular pre-selected color.
 */
export const generateColorBasedOnString = (baseString: string): string => {
  const colors = [
    "light-blue",
    "light-purple",
    "light-orange",
    "light-green",
    "light-brown",
    "blue",
    "purple",
    "orange",
    "green",
    "brown",
  ];

  let hash = 0;
  if (baseString.length === 0) {
    return colors[hash];
  }
  for (let i = 0; i < baseString.length; i++) {
    hash = baseString.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};
