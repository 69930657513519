export const InputWordsIntoGaps = {
  Add: {
    Input: {
      Condition: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Text: {
        LABEL: "Текст вправи",
        PLACEHOLDER: "I [to play/am playing] tennis.",
        HINT: 'Підказка \nДля створення порожніх прогалин використовуйте квадратні дужки: []. Відокремте підказку від правильної відповіді за допомогою "/": [підказка/правильна відповідь]. Додаткові правильні відповіді додавайте через додаткові "/": [підказка/правильна відповідь 1/правильна відповідь 2]. \nЯкщо хочете використовувати кілька правильних відповідей без підказок, залиште порожній простір перед першим, тобто почніть з "/". \n\nПриклади: \n1. I [to play/am playing] tennis. \n2. This flower is [better] than that. \n3. We [/have/are having] dinner with my sister.',
      },
    },
  },
};
