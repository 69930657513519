export const Notification = {
  Error: {
    ICON: "❌",
    DEFAULT: "Упс, щось пішло не так! Виникла невідома помилка...",
  },
  Success: {
    ICON: "✅",
    DEFAULT: "Операція успішно виконана!",
  },
};
