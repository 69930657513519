import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { StudentsContent } from "content";
import { useRole } from "hooks/common";
import { Avatar } from "components/Common/Avatar";
import { Tag } from "components/Common/Tag";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { Tooltip } from "components/Interactive/Tooltip";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { formatPhoneNumber } from "utils/common";
import { getRowSequenceNumber } from "utils/table";
import { TStudent } from "types/api/student";

type TUseStudentColumns = (options?: {
  onDeleteClick?: (studentId: number) => void;
  onEditClick?: (student: TStudent) => void;
  onStudentNameClick?: (studentId: number) => void;
}) => ColumnDef<TStudent, string>[];

export const useStudentColumns: TUseStudentColumns = (options) => {
  const { onDeleteClick, onEditClick, onStudentNameClick } = options || {};
  const { isUserTeacher, isUserAdmin } = useRole();

  const columnHelper = createColumnHelper<TStudent>();

  const columns: ColumnDef<TStudent, string>[] = [
    columnHelper.accessor(() => "index", {
      id: "index",
      header: StudentsContent.Table.Header.NUMBER,
      cell: (info) =>
        getRowSequenceNumber(info.row.index, info.table.getState().pagination),
      size: 0.05,
    }),
    columnHelper.accessor(() => "avatar", {
      id: "avatar",
      header: StudentsContent.Table.Header.AVATAR,
      cell: () => <Avatar bordered size="small" />,
      size: 0.1,
    }),
    columnHelper.accessor(() => "name", {
      id: "name",
      header: StudentsContent.Table.Header.NAME,
      cell: ({ row: { original } }) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={`${original.person.firstName} ${original.person.lastName}`}
          spaceSize="small"
          variant="compact"
        >
          {isUserTeacher ? (
            <Button
              variant="text"
              onClick={() => onStudentNameClick?.(original.id)}
            >
              {original.person.firstName}&nbsp;{original.person.lastName}
            </Button>
          ) : (
            `${original.person.firstName} ${original.person.lastName}`
          )}
        </Tooltip>
      ),
      size: 0.15,
    }),
    columnHelper.accessor((row) => row.person.email, {
      id: "email",
      header: StudentsContent.Table.Header.EMAIL,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={info.getValue()}
          spaceSize="small"
          variant="compact"
        >
          {info.getValue()}
        </Tooltip>
      ),
      size: 0.15,
    }),
    columnHelper.accessor((row) => row.person.phone, {
      id: "phone",
      header: StudentsContent.Table.Header.PHONE,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={formatPhoneNumber(info.getValue())}
          spaceSize="small"
          variant="compact"
        >
          {formatPhoneNumber(info.getValue())}
        </Tooltip>
      ),
      size: 0.15,
    }),
    columnHelper.accessor((row) => row.languageLevel as string, {
      id: "level",
      header: StudentsContent.Table.Header.LEVEL,
      cell: (info) => info.getValue(),
      size: 0.1,
    }),
    // ...(isUserTeacher
    //   ? [
    //       columnHelper.accessor(() => "Due", {
    //         id: "status",
    //         header: StudentsContent.Table.Header.STATUS,
    //         cell: () => (
    //           <Tag bold bordered color="warning">
    //             Due
    //           </Tag>
    //         ),
    //         size: 0.1,
    //       }),
    //     ]
    //   : []),
    columnHelper.accessor(() => "action", {
      id: "action",
      header: StudentsContent.Table.Header.ACTION,
      cell: ({ row }) => (
        <div className="nb-page-study-student-action">
          {isUserTeacher && (
            <Button
              variant="link"
              className="nb-page-study-student-button-primary"
              to={`/study/students/${row.original.id}/classroom`}
            >
              {StudentsContent.Table.Button.CLASSROOM}
            </Button>
          )}
          {isUserAdmin && (
            <>
              <Button
                onClick={() => onEditClick?.(row.original)}
                className="nb-page-study-student-button-primary"
              >
                {StudentsContent.Table.Button.EDIT}
              </Button>
              <IconButton
                icon={<Delete />}
                onClick={() => onDeleteClick?.(row.original.id)}
              />
            </>
          )}
        </div>
      ),
      size: 152,
    }),
  ];

  return columns;
};
