import { useMutation, useQueryClient } from "react-query";
import { editStudent } from "api/services/students";
import { EStudentsQueryKeys } from "api/endpoints/students";
import { logError } from "utils/notifications";
import { TStudent } from "types/api/student";
import { TApiError } from "types/api/error";

export const useEditStudent = () => {
  const queryClient = useQueryClient();
  return useMutation<TStudent, TApiError, TStudent>(editStudent, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStudentsQueryKeys.getStudents);
    },
    onError: (error) => logError({ error }),
    retry: false,
  });
};
