import {
  TExerciseIdentifier,
  TMakeWordFromLettersExerciseParsedWordLetter,
  TMakeWordFromLettersExerciseWordBlank,
} from "types/app/exercises";

export const MAX_MAKE_WORDS_FROM_LETTERS_ATTEMPTS = 3;

/**
 * Helper function that allows to update a word letter data when it was moved to the blank.
 * @param letter letter data that should be updated.
 * @param destinationBlank destination blank data where the target letter should be moved.
 * @returns updated array of letters.
 */
const updateLetterDataOnBlankEnter = (
  letter: TMakeWordFromLettersExerciseParsedWordLetter,
  destinationBlank: TMakeWordFromLettersExerciseWordBlank
): TMakeWordFromLettersExerciseParsedWordLetter => ({
  ...letter,
  attempts: [...letter.attempts, destinationBlank.id],
  currentAttempt: letter.currentAttempt + 1,
  isTouched: true,
  isCorrectDestination: letter.content === destinationBlank.correctContent,
  currentBlankId: destinationBlank.id,
});

/**
 * Helper function that allows to update a word letter data when it was moved from the blank.
 * @param letter letter data that should be updated.
 * @returns updated array of letters.
 */
const updateLetterDataOnBlankLeave = (
  letter: TMakeWordFromLettersExerciseParsedWordLetter
): TMakeWordFromLettersExerciseParsedWordLetter => ({
  ...letter,
  currentBlankId: null,
  isTouched: true,
  isCorrectDestination: false,
});

/**
 * Helper function that allows to update blank data when letter was moved into the blank.
 * @param blank blank data where the letter should be added.
 * @param letter letter data that was added to the blank.
 * @returns updated array of blanks.
 */
const updateBlankDataOnLetterEnter = (
  blank: TMakeWordFromLettersExerciseWordBlank,
  letter: TMakeWordFromLettersExerciseParsedWordLetter
): TMakeWordFromLettersExerciseWordBlank => ({
  ...blank,
  isEmpty: false,
  currentLetter: updateLetterDataOnBlankEnter(letter, blank),
  isCorrectLetter: letter.content === blank.correctContent,
});

/**
 * Helper function that allows to update blank data when letter was removed from the blank.
 * @param blank blank data to remove letter from.
 * @param letter letter data that was removed from the blank.
 * @returns updated array of blanks.
 */
const updateBlankDataOnLetterLeave = (
  blank: TMakeWordFromLettersExerciseWordBlank
): TMakeWordFromLettersExerciseWordBlank => ({
  ...blank,
  isEmpty: true,
  currentLetter: null,
  isCorrectLetter: false,
});

/**
 * Helper function that allows to update an array of start zone letters by adding new item with slots replacement.
 * @param letters an array of word letters that are located in the start zone area.
 * @param targetLetter letter data that should be added to the existing start zone letters array on `replaceLetterId` slot.
 * @param replaceLetterId identifier of the letter that should be replaced to the new slot.
 * @returns updated array of start zone letters.
 */
export const letterToEmptyStartZoneSlot = (
  letters: TMakeWordFromLettersExerciseParsedWordLetter[],
  targetLetter: TMakeWordFromLettersExerciseParsedWordLetter,
  replaceLetterId: TExerciseIdentifier
): TMakeWordFromLettersExerciseParsedWordLetter[] => {
  const replaceLetter = letters.find(
    (letter) => letter.id === replaceLetterId
  ) as TMakeWordFromLettersExerciseParsedWordLetter;
  return letters.map((letter) =>
    // Keep the same slot if `replaceLetterId` is the same as `targetLetterId`
    letter.id === targetLetter.id && replaceLetterId === targetLetter.id
      ? updateLetterDataOnBlankLeave(targetLetter)
      : letter.id === targetLetter.id // Giving target letter slot to replace letter
      ? replaceLetter
      : letter.id === replaceLetterId // Giving replace letter slot to target letter
      ? updateLetterDataOnBlankLeave(targetLetter)
      : letter
  );
};

/**
 * Helper function that allows to update an array of start zone letters by removing target item from start zone slot.
 * @param letters an array of word letters that are located in the start zone area.
 * @param targetLetterId identifier of the target letter that should be removed from start zone slot.
 * @param destinationBlank destination blank data where the target letter should be moved.
 * @returns updated array of start zone letters.
 */
export const letterFromStartZoneSlot = (
  letters: TMakeWordFromLettersExerciseParsedWordLetter[],
  targetLetterId: TExerciseIdentifier,
  destinationBlank: TMakeWordFromLettersExerciseWordBlank
): TMakeWordFromLettersExerciseParsedWordLetter[] =>
  letters.map((letter) =>
    letter.id === targetLetterId
      ? updateLetterDataOnBlankEnter(letter, destinationBlank)
      : letter
  );

/**
 * Helper function that allows updating an array of start zone letters by replacing a target letter with a replace letter.
 * @note The target letter will enter the blank and the replace letter will leave the blank.
 * @param letters an array of word letters that are located in the start zone area.
 * @param targetLetter target letter data that should be replaced with a replace letter.
 * @param replaceLetter replace letter data that should be placed instead of the target letter.
 * @param targetBlank destination blank data where the target letter should be moved.
 * @returns updated array of start zone letters.
 */
export const letterFromStartZoneSlotWithReplacement = (
  letters: TMakeWordFromLettersExerciseParsedWordLetter[],
  targetLetter: TMakeWordFromLettersExerciseParsedWordLetter,
  replaceLetter: TMakeWordFromLettersExerciseParsedWordLetter,
  targetBlank: TMakeWordFromLettersExerciseWordBlank
): TMakeWordFromLettersExerciseParsedWordLetter[] =>
  letters.map((letter) =>
    letter.id === targetLetter.id
      ? updateLetterDataOnBlankLeave(replaceLetter)
      : letter.id === replaceLetter.id
      ? updateLetterDataOnBlankEnter(targetLetter, targetBlank)
      : letter
  );

/**
 * Helper function that allows updating an array of start zone letters by replacing a target letter with a replace letter.
 * @note The target letter will leave the blank and replace letter will enter the blank.
 * @param letters an array of word letters that are located in the start zone area.
 * @param targetLetter target letter data that should be replaced with a replace letter.
 * @param replaceLetter replace letter data that should be placed instead of the target letter.
 * @param targetBlank destination blank data where the target letter should be moved.
 * @returns updated array of start zone letters.
 */
export const letterToOccupiedStartZoneSlot = (
  letters: TMakeWordFromLettersExerciseParsedWordLetter[],
  targetLetter: TMakeWordFromLettersExerciseParsedWordLetter,
  replaceLetter: TMakeWordFromLettersExerciseParsedWordLetter,
  targetBlank: TMakeWordFromLettersExerciseWordBlank
): TMakeWordFromLettersExerciseParsedWordLetter[] =>
  letters.map((letter) =>
    letter.id === targetLetter.id
      ? updateLetterDataOnBlankEnter(replaceLetter, targetBlank)
      : letter.id === replaceLetter.id
      ? updateLetterDataOnBlankLeave(targetLetter)
      : letter
  );

/**
 * Helper function that allows updating an array of start zone letters by switching a target letter with a replace letter that was present in the start zone slot.
 * @param letters an array of word letters that are located in the start zone area.
 * @param targetLetter target letter data that should be replaced with a replace letter.
 * @param replaceLetter replace letter data that should be placed instead of the target letter.
 * @returns updated array of start zone letters.
 */
export const letterFromStartZoneToOccupiedStartZoneSlot = (
  letters: TMakeWordFromLettersExerciseParsedWordLetter[],
  targetLetter: TMakeWordFromLettersExerciseParsedWordLetter,
  replaceLetter: TMakeWordFromLettersExerciseParsedWordLetter
): TMakeWordFromLettersExerciseParsedWordLetter[] =>
  letters.map((letter) =>
    letter.id === targetLetter.id
      ? replaceLetter
      : letter.id === replaceLetter.id
      ? targetLetter
      : letter
  );

/**
 * Helper function that allows updating an array of start zone letters by switching a target with a replace letters in start zone slots.
 * @note Similar to `letterFromStartZoneToOccupiedStartZoneSlot`, but we do not gave replace letter, only an ID of it.
 * @param letters an array of word letters that are located in the start zone area.
 * @param targetLetter target letter data that should be replaced with a replace letter.
 * @param replaceLetterId identifier of the letter that should be replaced to the new slot.
 * @returns updated array of start zone letters.
 */
export const letterFromStartZoneToEmptyStartZoneSlot = (
  letters: TMakeWordFromLettersExerciseParsedWordLetter[],
  targetLetter: TMakeWordFromLettersExerciseParsedWordLetter,
  replaceLetterId: TExerciseIdentifier
): TMakeWordFromLettersExerciseParsedWordLetter[] => {
  const replaceLetter = letters.find(
    (letter) => letter.id === replaceLetterId
  ) as TMakeWordFromLettersExerciseParsedWordLetter;
  return letterFromStartZoneToOccupiedStartZoneSlot(
    letters,
    targetLetter,
    replaceLetter
  );
};

/**
 * Helper function that allows updating an array of word letter blanks by adding a letter to the empty targeted blank.
 * @param blanks an array of all blanks that for the word.
 * @param targetBlankId identifier of the targeted blank that should be updated.
 * @param newLetter new letter data that should be added to the targeted blank.
 * @returns updated array of blanks.
 */
export const letterToEmptyBlank = (
  blanks: TMakeWordFromLettersExerciseWordBlank[],
  targetBlankId: TExerciseIdentifier,
  newLetter: TMakeWordFromLettersExerciseParsedWordLetter
): TMakeWordFromLettersExerciseWordBlank[] =>
  blanks.map((blank) =>
    blank.id === targetBlankId
      ? updateBlankDataOnLetterEnter(blank, newLetter)
      : blank
  );

/**
 * Helper function that allows updating an array of word letter blanks by adding a letter to the occupied targeted blank.
 * @param blanks an array of all blanks that for the word.
 * @param targetBlankId identifier of the targeted blank that should be updated.
 * @param newLetter new letter data that should be added to the targeted blank.
 * @returns updated array of blanks.
 */
export const letterToOccupiedBlank = (
  blanks: TMakeWordFromLettersExerciseWordBlank[],
  targetBlankId: TExerciseIdentifier,
  newLetter: TMakeWordFromLettersExerciseParsedWordLetter
): TMakeWordFromLettersExerciseWordBlank[] =>
  blanks.map((blank) =>
    blank.id === targetBlankId
      ? updateBlankDataOnLetterEnter(blank, newLetter)
      : blank
  );

/**
 * Helper function that allows updating an array of word letter blanks by removing a letter from the targeted blank.
 * @param blanks an array of all blanks that for the word.
 * @param targetLetterId identifier of the targeted letter that should be removed from the blank.
 * @returns updated array of blanks.
 */
export const letterFromBlank = (
  blanks: TMakeWordFromLettersExerciseWordBlank[],
  targetLetterId: TExerciseIdentifier
): TMakeWordFromLettersExerciseWordBlank[] =>
  blanks.map((blank) =>
    blank.currentLetter?.id === targetLetterId
      ? updateBlankDataOnLetterLeave(blank)
      : blank
  );

/**
 * Helper function that allows updating an array of word letter blanks by replacing a letter from the targeted blank to other empty blank.
 * @param blanks an array of all blanks that for the word.
 * @param previousBlankId identifier of the previous blank that should be updated.
 * @param destinationBlankId identifier of the destination blank that should be updated.
 * @param targetLetter letter data that should be moved from the previous blank to the destination blank.
 * @returns updated array of blanks.
 */
export const letterFromBlankToEmptyBlank = (
  blanks: TMakeWordFromLettersExerciseWordBlank[],
  previousBlankId: TExerciseIdentifier,
  destinationBlankId: TExerciseIdentifier,
  targetLetter: TMakeWordFromLettersExerciseParsedWordLetter
): TMakeWordFromLettersExerciseWordBlank[] =>
  blanks.map((blank) =>
    blank.id === previousBlankId
      ? updateBlankDataOnLetterLeave(blank)
      : blank.id === destinationBlankId
      ? updateBlankDataOnLetterEnter(blank, targetLetter)
      : blank
  );

/**
 * Helper function that allows updating an array of word letter blanks by replacing a letter from the targeted blank to other occupied blank.
 * @param blanks an array of all blanks that for the word.
 * @param previousBlankId identifier of the previous blank that should be updated.
 * @param destinationBlankId identifier of the destination blank that should be updated.
 * @param targetLetter letter data that should be moved from the previous blank to the destination blank.
 * @param replaceLetter letter data that should be moved from the destination blank to the previous blank.
 * @returns updated array of blanks.
 */
export const letterFromBlankToOccupiedBlank = (
  blanks: TMakeWordFromLettersExerciseWordBlank[],
  previousBlankId: TExerciseIdentifier,
  destinationBlankId: TExerciseIdentifier,
  targetLetter: TMakeWordFromLettersExerciseParsedWordLetter,
  replaceLetter: TMakeWordFromLettersExerciseParsedWordLetter
): TMakeWordFromLettersExerciseWordBlank[] =>
  blanks.map((blank) =>
    blank.id === previousBlankId
      ? updateBlankDataOnLetterEnter(blank, replaceLetter)
      : blank.id === destinationBlankId
      ? updateBlankDataOnLetterEnter(blank, targetLetter)
      : blank
  );
