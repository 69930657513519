import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const writeCompositionExercise: TExerciseItem = {
  title: "Твір / текст",
  backgroundText: "Твір",
  tags: [
    "Твір / текст",
    "написати",
    "твір",
    "текст",
    "описати",
    "фотографію",
    "write",
    "type",
    "text",
    "composition",
  ],
  type: EExerciseType.ESSAY,
  cover: "write-composition.png",
};
