import { useQuery } from "react-query";
import { ERequestQueryKeys } from "api/endpoints/requests";
import { getRequests } from "api/services/requests";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TPageableRequestParams } from "types/api/server";
import { TRequestResponse } from "types/api/requests";

export const useRequests = (options?: TPageableRequestParams) => {
  return useQuery<TRequestResponse, TApiError>(
    [ERequestQueryKeys.getAllRequests, options],
    () => getRequests(options),
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
