import { ValueContainerProps, components } from "react-select";
import cx from "classnames";
import { TSelectDefaultOption } from "../../Select.types";
import "./ValueContainer.styles.scss";

type SelectValueContainerProps<
  Option extends TSelectDefaultOption = TSelectDefaultOption
> = {
  /**
   * Element placed left of the selected value.
   */
  icon?: React.ReactElement;
  /**
   * If `true`, the dropdown indicator will have style modifications.
   * @default false
   */
  isLabelsJSXElements?: boolean;
} & ValueContainerProps<Option, false>;

export const ValueContainer = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>({
  children,
  icon,
  isLabelsJSXElements = false,
  ...props
}: SelectValueContainerProps<Option>): JSX.Element => (
  <components.ValueContainer
    {...props}
    className={cx([
      "nb-interactive-select-value-container",
      {
        "nb-interactive-select-value-container--with-icon": icon,
        "nb-interactive-select-value-container--element": isLabelsJSXElements,
      },
    ])}
  >
    {children}
  </components.ValueContainer>
);
