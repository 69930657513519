import { NoticeProps } from "react-select";
import { TSelectOption } from "types/app/select";
import "./LoadingMessage.styles.scss";

type LoadingMessageProps<
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
> = {
  loadingText: string;
} & NoticeProps<Option, false>;

export const LoadingMessage = <
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>(
  props: LoadingMessageProps<Option>
): JSX.Element => {
  const { loadingText } = props;

  return (
    <div {...props.innerProps} className="nb-interactive-search-loading">
      {loadingText ? loadingText : props.children}
    </div>
  );
};
