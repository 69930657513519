import {
  TExerciseIdentifier,
  TExerciseSentence,
  TExerciseSentenceAnswer,
  TMoveWordsIntoGapsExerciseBlank,
} from "types/app/exercises";

export const START_ZONE_ID = "move-words-into-blanks-start-zone";

/**
 * Helper function that allow to update blank information on new answer inset.
 * @param blank target blank data that should be updated.
 * @param newAnswer new answer data that should be added.
 * @returns updated blank data.
 */
const updateBlankInfo = (
  blank: TMoveWordsIntoGapsExerciseBlank,
  newAnswer: TExerciseSentenceAnswer
): TMoveWordsIntoGapsExerciseBlank => ({
  ...blank,
  attempts: [...blank.attempts, newAnswer],
  currentAnswer: newAnswer,
  currentAttempt: blank.currentAttempt + 1,
  isEmpty: false,
  isCorrectAnswer: blank.correctAnswer.id === newAnswer.id,
  isAllAttemptsFailed:
    blank.correctAnswer.id !== newAnswer.id &&
    blank.currentAttempt + 1 >= blank.maxAttempts,
});

/**
 * Helper function that allows to remove current answer and update information about it.
 * @param blank target blank data that should be updated.
 * @returns updated blank data.
 */
const removeCurrentAnswerFromBlank = (
  blank: TMoveWordsIntoGapsExerciseBlank
): TMoveWordsIntoGapsExerciseBlank => ({
  ...blank,
  currentAnswer: null,
  isEmpty: true,
  isCorrectAnswer: false,
});

/**
 * Helper function that allows to updating an array of start zone answers by removing requested item.
 * @param answers an array of answers that are located in the start zone area.
 * @param targetAnswerId identifier of the target answer that should be removed from start zone.
 * @returns updated array of answers without targeted answer.
 */
export const removeAnswerFromStartZone = (
  answers: TExerciseSentenceAnswer[],
  targetAnswerId: TExerciseIdentifier
): TExerciseSentenceAnswer[] =>
  answers.filter((answer) => answer.id !== targetAnswerId);

/**
 * Helper function that allows to updating an array of start zone answers by adding new item.
 * @param answers an array of answers that are located in the start zone area.
 * @param newAnswer new answer data that should be added to the existing start zone answers array.
 * @returns updated array of answers with new answer.
 */
export const addAnswerToStartZone = (
  answers: TExerciseSentenceAnswer[],
  newAnswer: TExerciseSentenceAnswer
): TExerciseSentenceAnswer[] => answers.concat(newAnswer);

/**
 * Helper function that allows updating an array of start zone answers by replacing an answer.
 * @param answers an array of answers that are located in the start zone area.
 * @param draggedAnswerId identifier of the dragged answer that should be replaced.
 * @param replaceAnswer replace answer data that should take place of the dragged answer.
 * @returns updated array of answers with replaced answer.
 */
export const replaceAnswerInStartZone = (
  answers: TExerciseSentenceAnswer[],
  draggedAnswerId: TExerciseIdentifier,
  replaceAnswer: TExerciseSentenceAnswer
) =>
  answers.map((answer) =>
    answer.id === draggedAnswerId ? replaceAnswer : answer
  );

/**
 * Helper function that allows updating an array of exercise sentences by removing an answer from blank.
 * @param sentences an array of all sentences that exist in an exercise.
 * @param targetBlankId identifier of the targeted blank that should be removed.
 * @returns updated array of sentences without targeted blank.
 */
export const removeAnswerFromBlank = (
  sentences: TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[],
  targetBlankId: TExerciseIdentifier
): TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[] =>
  sentences.map(({ chunks, ...sentence }) => ({
    ...sentence,
    chunks: chunks.map((chunk) =>
      chunk.blank?.id === targetBlankId
        ? {
            ...chunk,
            blank: removeCurrentAnswerFromBlank(chunk.blank),
          }
        : chunk
    ),
  }));

/**
 * Helper function that allows updating an array of exercise sentences by adding an answer to the targeted blank.
 * @param sentences an array of all sentences that exist in an exercise.
 * @param targetBlankId identifier of the targeted blank that should be updated.
 * @param newAnswer new answer data that should be added to the targeted blank.
 * @returns updated array of sentences with new answer in targeted blank.
 */
export const addAnswerToBlank = (
  sentences: TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[],
  targetBlankId: TExerciseIdentifier,
  newAnswer: TExerciseSentenceAnswer
): TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[] =>
  sentences.map(({ chunks, ...sentence }) => ({
    ...sentence,
    chunks: chunks.map((chunk) =>
      chunk.blank && chunk.blank.id === targetBlankId
        ? {
            ...chunk,
            blank: updateBlankInfo(chunk.blank, newAnswer),
          }
        : chunk
    ),
  }));

/**
 * Helper function that allows updating an array of exercise sentences by switching answer blank from source to destination one.
 * @param sentences an array of all sentences that exist in an exercise.
 * @param targetAnswer target answer data that should be switched from source blank to destination blank.
 * @param destinationBlankId identifier of the destination blank that should be updated.
 * @param sourceBlankId identifier of the source blank that should be updated.
 * @returns updated array of sentences with switched target answer from source to destination blank.
 */
export const switchAnswerInBlanks = (
  sentences: TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[],
  targetAnswer: TExerciseSentenceAnswer,
  destinationBlankId: TExerciseIdentifier,
  sourceBlankId: TExerciseIdentifier
): TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[] =>
  sentences.map(({ chunks, ...sentence }) => ({
    ...sentence,
    chunks: chunks
      .map((chunk) =>
        chunk.blank?.id === sourceBlankId
          ? {
              ...chunk,
              blank: removeCurrentAnswerFromBlank(chunk.blank),
            }
          : chunk
      )
      .map((chunk) =>
        chunk.blank?.id === destinationBlankId
          ? {
              ...chunk,
              blank: updateBlankInfo(chunk.blank, targetAnswer),
            }
          : chunk
      ),
  }));

/**
 * Helper function that allows updating an array of exercise sentences by replacing answers from source to destination.
 * Which means that answer in source blank will take place in destination blank and answer in destination blank will take place in source one.
 * @param sentences an array of all sentences that exist in an exercise.
 * @param sourceBlank source blank data with a current answer which one should be moved to the destination blank.
 * @param destinationBlank destination blank data with a current answer which one should be moved to the source blank.
 * @returns updated array of sentences with replaced answers from source to destination and otherwise.
 */
export const replaceAnswersInBlanks = (
  sentences: TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[],
  sourceBlank: TMoveWordsIntoGapsExerciseBlank,
  destinationBlank: TMoveWordsIntoGapsExerciseBlank
): TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>[] =>
  sentences.map(({ chunks, ...sentence }) => ({
    ...sentence,
    chunks: chunks
      .map((chunk) =>
        chunk.blank?.id === destinationBlank.id && sourceBlank.currentAnswer
          ? {
              ...chunk,
              blank: updateBlankInfo(chunk.blank, sourceBlank.currentAnswer),
            }
          : chunk
      )
      .map((chunk) =>
        chunk.blank?.id === sourceBlank.id && destinationBlank.currentAnswer
          ? {
              ...chunk,
              blank: updateBlankInfo(
                chunk.blank,
                destinationBlank.currentAnswer
              ),
            }
          : chunk
      ),
  }));
