import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { TeachersContent } from "content";
import { Avatar } from "components/Common/Avatar";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { Tooltip } from "components/Interactive/Tooltip";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { formatPhoneNumber } from "utils/common";
import { getRowSequenceNumber } from "utils/table";
import { TTeacher } from "types/api/teacher";

type TUseTeacherColumns = (options?: {
  onDeleteClick?: (teacherId: number) => void;
  onEditClick?: (teacher: TTeacher) => void;
}) => ColumnDef<TTeacher, string>[];

export const useTeacherColumns: TUseTeacherColumns = (options) => {
  const { onDeleteClick, onEditClick } = options || {};

  const columnHelper = createColumnHelper<TTeacher>();

  const columns: ColumnDef<TTeacher, string>[] = [
    columnHelper.accessor(() => "index", {
      id: "index",
      header: TeachersContent.Table.Header.NUMBER,
      cell: (info) =>
        getRowSequenceNumber(info.row.index, info.table.getState().pagination),
      size: 0.05,
    }),
    columnHelper.accessor(() => "avatar", {
      id: "avatar",
      header: TeachersContent.Table.Header.AVATAR,
      cell: () => <Avatar bordered size="small" />,
      size: 0.1,
    }),
    columnHelper.accessor(() => "name", {
      id: "name",
      header: TeachersContent.Table.Header.NAME,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={`${info.row.original.person.firstName} ${info.row.original.person.lastName}`}
          spaceSize="small"
          variant="compact"
        >
          {info.row.original.person.firstName}&nbsp;
          {info.row.original.person.lastName}
        </Tooltip>
      ),
      size: 0.2,
    }),
    columnHelper.accessor((row) => row.person.email, {
      id: "email",
      header: TeachersContent.Table.Header.EMAIL,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={info.getValue()}
          spaceSize="small"
          variant="compact"
        >
          {info.getValue()}
        </Tooltip>
      ),
      size: 0.2,
    }),
    columnHelper.accessor((row) => row.person.phone, {
      id: "phone",
      header: TeachersContent.Table.Header.PHONE,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={formatPhoneNumber(info.getValue())}
          spaceSize="small"
          variant="compact"
        >
          {formatPhoneNumber(info.getValue())}
        </Tooltip>
      ),
      size: 0.2,
    }),
    columnHelper.accessor(() => "action", {
      id: "action",
      header: TeachersContent.Table.Header.ACTION,
      cell: ({ row }) => (
        <div className="nb-page-study-teacher-action">
          <Button
            onClick={() => onEditClick?.(row.original)}
            className="nb-page-study-teacher-edit"
          >
            {TeachersContent.Table.Button.EDIT}
          </Button>
          <IconButton
            icon={<Delete />}
            onClick={() => onDeleteClick?.(row.original.id)}
          />
        </div>
      ),
      size: 152,
    }),
  ];

  return columns;
};
