export const Note = {
  Add: {
    Input: {
      Type: {
        LABEL: "Тип нотатки",
        PLACEHOLDER: "Оберіть тип нотатки",
      },
      Visible: {
        LABEL: "Не показувати замітку учневі",
      },
      Content: {
        LABEL: "Зміст нотатки",
        PLACEHOLDER: "Введіть текст нотатки",
      },
    },
  },
  Option: {
    TEACHER_NOTE: "Нотатки вчителя",
    INFO: "Корисна інформація",
  },
};
