import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  DescribeImageExerciseContent,
  ExerciseConstructorContent,
} from "content";
import { ReactComponent as ImageIcon } from "assets/icons/image.svg";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { Textarea } from "components/Interactive/Textarea";
import { Select } from "components/Interactive/Select";
import { Space } from "components/Common/Space";
import { ImageListUpload } from "components/Interactive/ImageListUpload";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { IMAGE_EXERCISE_GALLERY_VIEW_OPTIONS } from "utils/options/exercise";
import {
  adaptExerciseImagesToData,
  getDescribeImageExerciseFormInitialValues,
} from "./DescribeImageExerciseForm.helpers";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import {
  EExerciseType,
  TDescribeImageExercisePayload,
} from "types/app/exercises";
import { TDescribeImageExerciseForm } from "./DescribeImageExerciseForm.types";
import "./DescribeImageExerciseForm.styles.scss";

export const DescribeImageExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TDescribeImageExercisePayload>();

  const { mutate: addExercise } = useAddExercise<TDescribeImageExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TDescribeImageExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TDescribeImageExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TDescribeImageExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TDescribeImageExerciseForm> = useMemo(
    () => getDescribeImageExerciseFormInitialValues(editExercise),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<TDescribeImageExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TDescribeImageExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TDescribeImageExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TDescribeImageExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TDescribeImageExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TDescribeImageExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const isImagesValid: boolean = formData.images.some(
      (image) => image.imageEncoded !== null
    );
    if (!isImagesValid && !isEditMode) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR_NO_FILE,
      });
      return;
    }

    const parsedImages = adaptExerciseImagesToData(
      formData.images,
      editExercise?.payload.data.images
    );

    const addBody: TAddExercise<TDescribeImageExercisePayload> = {
      type: EExerciseType.IMAGE,
      payload: {
        text: formData.text,
        data: {
          galleryView: formData.galleryView.value,
          images: parsedImages,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TDescribeImageExercisePayload> = {
        id: editExercise.id,
        type: EExerciseType.IMAGE,
        payload: {
          text: formData.text,
          data: {
            galleryView: formData.galleryView.value,
            images: parsedImages,
          },
        },
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TDescribeImageExerciseForm>
          name="text"
          id="describe-image-exercise-text"
          className="nb-exercise-constructor-describe-image-text"
          label={DescribeImageExerciseContent.Add.Input.Text.LABEL}
          placeholder={DescribeImageExerciseContent.Add.Input.Text.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Select<TDescribeImageExerciseForm>
          name="galleryView"
          id="describe-image-exercise-select"
          className="nb-exercise-constructor-describe-image-select"
          placeholder={
            DescribeImageExerciseContent.Add.Input.GalleryView.PLACEHOLDER
          }
          label={DescribeImageExerciseContent.Add.Input.GalleryView.LABEL}
          options={IMAGE_EXERCISE_GALLERY_VIEW_OPTIONS}
          control={control}
          errors={errors}
          icon={<ImageIcon />}
          required
          fullWidth
        />
        <ImageListUpload<TDescribeImageExerciseForm>
          name="images"
          className="nb-exercise-constructor-describe-image-images"
          label={DescribeImageExerciseContent.Add.Input.Upload.LABEL}
          hint={DescribeImageExerciseContent.Add.Input.Upload.HINT}
          pickImageButtonText={
            DescribeImageExerciseContent.Add.Input.Upload.Button.PICK
          }
          addImageButtonText={
            DescribeImageExerciseContent.Add.Input.Upload.Button.ADD
          }
          changeImageButtonText={
            DescribeImageExerciseContent.Add.Input.Upload.Button.CHANGE
          }
          descriptionPlaceholder={
            DescribeImageExerciseContent.Add.Input.Upload.DESCRIPTION
          }
          control={control}
          register={register}
          errors={errors}
          fullWidth
        />
      </Space>
    </ExerciseConfigForm>
  );
};
