export const LandingDashboard = {
  Name: "Налаштування лендінгу",
  Emoji: "🗺️",
  Search: {
    PLACEHOLDER: "Пошук пропозицій",
    NO_OPTIONS: "Пропозицій не знайдено",
  },
  Empty: "Список порожній, натисніть додати, щоб вони з'явились на лендінгу 💪",
  Button: {
    ADD: "Додати",
  },
  Table: {
    Header: {
      NUMBER: "№",
      TYPE: "Тип",
      DESCRIPTION: "Опис",
      PRICE: "Ціна",
      ACTION: "Дії",
    },
    Button: {
      EDIT: "Змінити",
    },
  },
  Delete: {
    TITLE: "Ви дійсно хочете видалити пропозицію?",
    EMOJI: "😥",
    Notification: {
      SUCCESS: "Пропозицію успішно видалено",
      ERROR: "Не вдалося видалити пропозицію",
    },
  },
  Add: {
    TITLE: "Додати пропозицію",
    EMOJI: "📎",
    SUBMIT: "Додати",
    Notification: {
      SUCCESS: "Пропозиція успішно додана",
      ERROR: "Не вдалось додати пропозицію",
    },
    Button: {
      ADD_DESCRIPTION: "Додати перевагу",
      ADD_OPTION: "Додати варіант",
    },
    Input: {
      Type: {
        LABEL: "Назва методу навчання",
        PLACEHOLDER: "Індивідуальні заняття",
      },
      Description: {
        LABEL: "Опис",
        PLACEHOLDER: "Переваги цього методу",
      },
      OptionName: {
        LABEL: "Варіант ціни",
        PLACEHOLDER: "1 місяць",
      },
      OptionPrice: {
        LABEL: "Ціна",
        PLACEHOLDER: "4800",
      },
      OptionEconomy: {
        LABEL: "Економія",
        PLACEHOLDER: "200",
      },
    },
  },
  Edit: {
    TITLE: "Пропозиція",
    EMOJI: "📎",
    SUBMIT: "Зберегти",
    Notification: {
      SUCCESS: "Пропозиція успішно змінена",
      ERROR: "Не вдалось змінити пропозицію",
    },
    Button: {
      ADD_DESCRIPTION: "Додати перевагу",
      ADD_OPTION: "Додати варіант",
    },
    Input: {
      Type: {
        LABEL: "Назва методу навчання",
        PLACEHOLDER: "Індивідуальні заняття",
      },
      Description: {
        LABEL: "Опис",
        PLACEHOLDER: "Переваги цього методу",
      },
      OptionName: {
        LABEL: "Варіант ціни",
        PLACEHOLDER: "1 місяць",
      },
      OptionPrice: {
        LABEL: "Ціна",
        PLACEHOLDER: "4800",
      },
      OptionEconomy: {
        LABEL: "Економія",
        PLACEHOLDER: "200",
      },
    },
  },
};
