import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  DescribeGifExerciseContent,
  ExerciseConstructorContent,
} from "content";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { useModal } from "hooks/common";
import { Image } from "components/Common/Image";
import { Paragraph } from "components/Typography/Paragraph";
import { Space } from "components/Common/Space";
import { Textarea } from "components/Interactive/Textarea";
import { Button } from "components/Interactive/Button";
import { ImageUploadModal } from "components/Interactive/ImageUploadModal";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { convertBlobToBase64String } from "utils/common";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import {
  EExerciseType,
  TDescribeGifExercisePayload,
} from "types/app/exercises";
import { TDescribeGifExerciseForm } from "./DescribeGifExerciseForm.types";
import "./DescribeGifExerciseForm.styles.scss";

export const DescribeGifExerciseForm: React.FC = () => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TDescribeGifExercisePayload>();
  const { isModalOpen, openModal, closeModal } = useModal(false);

  const { mutate: addExercise } = useAddExercise<TDescribeGifExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TDescribeGifExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TDescribeGifExercisePayload>(courseId, lessonId, chapterId);
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TDescribeGifExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TDescribeGifExerciseForm> = useMemo(
    () => ({
      text: editExercise?.payload.text || "",
      description: editExercise?.payload.data.description || "",
    }),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<TDescribeGifExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (editExercise?.payload.data.fileUrl && isEditMode) {
      setImageUrl(editExercise.payload.data.fileUrl);
    }
    return () => {
      setImageUrl(null);
    };
  }, [editExercise?.payload.data.fileUrl, isEditMode]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
    setUploadedImage(null);
  };

  const submitLessonExercise = (
    body: TAddExercise<TDescribeGifExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TDescribeGifExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TDescribeGifExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TDescribeGifExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TDescribeGifExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    if (!uploadedImage && !isEditMode) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR_NO_FILE,
      });
      return;
    }

    const [, cleanBase64] = uploadedImage?.split(",") || [];

    const addBody: TAddExercise<TDescribeGifExercisePayload> = {
      type: EExerciseType.GIF,
      payload: {
        text: formData.text,
        data: {
          fileUrl: null,
          fileEncoded: cleanBase64,
          description: formData.description,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TDescribeGifExercisePayload> = {
        id: editExercise.id,
        type: EExerciseType.GIF,
        payload: {
          text: formData.text,
          data: {
            fileUrl: editExercise.payload.data.fileUrl,
            fileEncoded: cleanBase64 || editExercise.payload.data.fileEncoded,
            description: formData.description,
          },
        },
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  const imageUploadHandler = (image: Blob) => {
    convertBlobToBase64String(image).then(setUploadedImage).then(closeModal);
  };

  const isImageAvailable: boolean = useMemo(
    () => imageUrl !== null || uploadedImage !== null,
    [imageUrl, uploadedImage]
  );

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Textarea<TDescribeGifExerciseForm>
          name="text"
          id="describe-gif-exercise-text"
          className="nb-exercise-constructor-describe-gif-text"
          label={DescribeGifExerciseContent.Add.Input.Text.LABEL}
          placeholder={DescribeGifExerciseContent.Add.Input.Text.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Space direction="vertical" justify="start" size="x-small" fullWidth>
          <Paragraph variant={2}>
            {DescribeGifExerciseContent.Add.Input.Upload.LABEL}
          </Paragraph>
          {isImageAvailable && (
            <Image
              src={(uploadedImage || imageUrl) as string}
              rounded
              fullWidth
            />
          )}
          <Button variant="secondary" fullWidth onClick={openModal}>
            {isImageAvailable
              ? DescribeGifExerciseContent.Add.Input.Upload.Button.CHANGE
              : DescribeGifExerciseContent.Add.Input.Upload.Button.ADD}
          </Button>
        </Space>
        <Textarea<TDescribeGifExerciseForm>
          name="description"
          id="describe-gif-exercise-description"
          className="nb-exercise-constructor-describe-gif-description"
          label={DescribeGifExerciseContent.Add.Input.Description.LABEL}
          placeholder={
            DescribeGifExerciseContent.Add.Input.Description.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
          hint={DescribeGifExerciseContent.Add.Input.Description.HINT}
        />
      </Space>
      <ImageUploadModal
        open={isModalOpen}
        accept="image/gif"
        id="describe-gif-exercise-upload-input"
        name="describe-gif-exercise-upload-input"
        portalId="describe-gif-exercise-upload-modal"
        withUploadButton
        title={DescribeGifExerciseContent.Add.Modal.TITLE}
        emoji={DescribeGifExerciseContent.Add.Modal.EMOJI}
        selectButtonText={DescribeGifExerciseContent.Add.Modal.Button.SELECT}
        uploadButtonText={DescribeGifExerciseContent.Add.Modal.Button.UPLOAD}
        inputLabel={DescribeGifExerciseContent.Add.Modal.Input.LABEL}
        onClose={closeModal}
        onUpload={imageUploadHandler}
        className="nb-exercise-constructor-describe-gif-upload-modal"
      />
    </ExerciseConfigForm>
  );
};
