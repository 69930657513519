import { useMutation, useQueryClient } from "react-query";
import { EExercisesQueryKeys } from "api/endpoints/exercises";
import { removeHomeworkExercise } from "api/services/exercises";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TUrlParam } from "types/utils";

export const useRemoveHomeworkExercise = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  homeworkId: TUrlParam
) => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(
    (exerciseId: number) =>
      removeHomeworkExercise(courseId, lessonId, homeworkId, exerciseId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EExercisesQueryKeys.getHomeworkExercises);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
