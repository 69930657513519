import { nanoid } from "nanoid";
import { shuffleArray } from "utils/common";
import {
  TSortWordsExerciseColumn,
  TSortWordsExerciseParsedColumn,
  TSortWordsExerciseParsedData,
  TSortWordsExerciseParsedWord,
  TSortWordsExercisePayload,
} from "types/app/exercises";
import { TExercise } from "types/api/exercise";
import { TSortWordsExerciseForm } from "./SortWordsExerciseForm.types";

export const getSortWordsExerciseFormInitialValues = (
  editExercise: TExercise<TSortWordsExercisePayload> | null
): TSortWordsExerciseForm => ({
  text: editExercise?.payload.text || "",
  columns: editExercise?.payload.data.rawColumns || [
    {
      id: nanoid(),
      name: "",
      value: "",
    },
    {
      id: nanoid(),
      name: "",
      value: "",
    },
  ],
});

/**
 * Utility function that helps to adapt user inputted data to structured data which makes easier to work with drag'n'drop operations.
 * @param columns raw user inputted data
 * @returns object of structured `sort words` exercise data.
 */
export const adaptExerciseColumnsToData = (
  columns: TSortWordsExerciseColumn<string>[]
): TSortWordsExerciseParsedData => {
  const parsedColumns: TSortWordsExerciseParsedColumn[] = [];
  const parsedWords: TSortWordsExerciseParsedWord[] = [];

  for (const column of columns) {
    const columnWords = column.value
      .split("/")
      .map((word) => word.trim())
      .filter((word) => word !== "");

    const currentColumnWords: TSortWordsExerciseParsedWord[] = [];

    for (const word of columnWords) {
      const wordId = nanoid();

      const wordData: TSortWordsExerciseParsedWord = {
        id: wordId,
        isTouched: false,
        isCorrectDestination: false,
        isAllAttemptsFailed: false,
        correctColumnId: column.id,
        currentColumnId: null,
        attempts: [],
        currentAttempt: 0,
        hint: null,
        content: word,
      };

      currentColumnWords.push(wordData);
      parsedWords.push(wordData);
    }

    const columnData: TSortWordsExerciseParsedColumn = {
      id: column.id,
      isEmpty: true,
      isLocked: false,
      currentWords: [],
      correctWords: currentColumnWords,
      hint: null,
      content: column.name,
    };

    parsedColumns.push(columnData);
  }

  return {
    columns: shuffleArray(parsedColumns),
    words: shuffleArray(parsedWords),
  };
};
