import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { Text } from "components/Typography/Text";
import { ClueSelect } from "components/Interactive/ClueSelect";
import {
  TChooseRightWordExerciseBlank,
  TExerciseSentence,
} from "types/app/exercises";
import { TSelectOption } from "types/app/select";
import "./SentenceItem.styles.scss";

type TSentenceItemProps = {
  /**
   * A sentence data to construct an exercise.
   */
  sentence: TExerciseSentence<TChooseRightWordExerciseBlank>;
  /**
   * A flag to show the result of the exercise.
   * @default false
   */
  showResult?: boolean;
  /**
   * A function to handle a change of the sentence.
   */
  onSentenceChange?: (
    sentence: TExerciseSentence<TChooseRightWordExerciseBlank>
  ) => void;
};

export const SentenceItem: React.FC<TSentenceItemProps> = (props) => {
  const { sentence, showResult = false, onSentenceChange } = props;

  const { isExercisePreviewMode, isExerciseReadOnlyMode } = useLessonSettings();

  const clueSelectChangeHandler = (
    option: TSelectOption<string>,
    blankId: string | number
  ) => {
    const [currentAnswer] = sentence.chunks.flatMap(
      (chunk) =>
        chunk.blank?.answers.find((answer) => answer.id === option.value) || []
    );
    if (!currentAnswer) {
      return;
    }
    const updatedSentence = {
      ...sentence,
      chunks: sentence.chunks.map((chunk) => ({
        ...chunk,
        ...(chunk.blank?.id === blankId && {
          blank: {
            ...chunk.blank,
            currentAnswer,
            isEmpty: false,
            isCorrectAnswer: currentAnswer.isCorrect,
            isAllAttemptsFailed:
              !currentAnswer.isCorrect &&
              chunk.blank.currentAttempt + 1 >= chunk.blank.maxAttempts,
            currentAttempt: chunk.blank?.currentAttempt + 1,
            attempts: [...chunk.blank.attempts, currentAnswer],
          },
        }),
      })),
    };
    onSentenceChange?.(updatedSentence);
  };

  const getCorrectAnswerOption = (
    blank: TChooseRightWordExerciseBlank
  ): TSelectOption<string> | null => {
    if (isExercisePreviewMode) {
      if (!blank.currentAnswer) {
        return null;
      }
      return {
        value: blank.currentAnswer.id as string,
        label: blank.currentAnswer.content,
      };
    }
    return null;
  };

  return (
    <Space
      direction="horizontal"
      align="center"
      justify="start"
      size={["xx-small", "x-small"]}
      wrap
    >
      {sentence.chunks.map((chunk) =>
        chunk.blank !== null ? (
          <ClueSelect
            key={chunk.blank.id}
            id={chunk.blank.id}
            name={chunk.blank.id}
            options={chunk.blank.options}
            onChange={clueSelectChangeHandler}
            hint={chunk.blank.hint}
            correct={chunk.blank.isCorrectAnswer}
            value={getCorrectAnswerOption(chunk.blank)}
            showResult={showResult}
            disabled={isExerciseReadOnlyMode}
          />
        ) : (
          <Text key={chunk.id} variant="body2">
            {chunk.text?.content}
          </Text>
        )
      )}
    </Space>
  );
};
