import cx from "classnames";
import "./LessonSidebar.styles.scss";

type LessonSidebarProps = {
  /**
   * Whether the component should be rendered with header.
   * @default false
   */
  withHeader?: boolean;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const LessonSidebar: React.FC<LessonSidebarProps> = (props) => {
  const { withHeader = false, children } = props;

  return (
    <aside
      className={cx("nb-lesson-details-sidebar-container", {
        "nb-lesson-details-sidebar-container--with-header": withHeader,
      })}
    >
      {children}
    </aside>
  );
};
