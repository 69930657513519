import { useMutation, useQueryClient } from "react-query";
import { changeProfilePhoto } from "api/services/profile";
import { EAuthQueryKeys } from "api/endpoints/auth";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TUser } from "types/api/user";
import { TFileChangePhoto } from "types/api/file";
import { TUrlParam } from "types/utils";

export const useChangeProfilePhoto = (userId: TUrlParam) => {
  const queryClient = useQueryClient();
  return useMutation<TUser, TApiError, TFileChangePhoto>(
    (body: TFileChangePhoto) => changeProfilePhoto(userId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EAuthQueryKeys.getCurrentUser);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
