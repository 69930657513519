import { formatDuration } from "date-fns";
import { ReactComponent as SelectDragModeIcon } from "assets/icons/select-mode-drag.svg";
import { ReactComponent as SelectInputModeIcon } from "assets/icons/select-mode-input.svg";
import { ReactComponent as SelectSelectModeIcon } from "assets/icons/select-mode-select.svg";
import {
  CompareImageWithDescriptionExerciseContent,
  DescribeImageExerciseContent,
  EssayExerciseContent,
  NoteExerciseContent,
  TrueOrFalseExerciseContent,
} from "content";
import { SelectModeOption } from "components/ExerciseConstructor/Exercises/CompareImageWithDescriptionExerciseForm/SelectModeOption";
import { TestExerciseDuration } from "constants/exercises/test";
import {
  ECompareImageWithDescriptionMode,
  EDescribeImageExerciseGalleryView,
  EEssayExerciseType,
  ENoteExerciseType,
  TExerciseTimerValue,
} from "types/app/exercises";
import { TSelectOption } from "types/app/select";
import { TRadioOption } from "types/app/radio";

export const EXERCISE_DURATION_OPTIONS: TSelectOption<TExerciseTimerValue>[] =
  Object.values(TestExerciseDuration).map((value) => ({
    label: formatDuration({ minutes: value }),
    value: value as TExerciseTimerValue,
  }));

export const TRUE_OR_FALSE_EXERCISE_OPTIONS: TSelectOption<boolean>[] = [
  { label: TrueOrFalseExerciseContent.Option.FALSE, value: false },
  { label: TrueOrFalseExerciseContent.Option.TRUE, value: true },
];

export const IMAGE_EXERCISE_GALLERY_VIEW_OPTIONS: TSelectOption<EDescribeImageExerciseGalleryView>[] =
  [
    {
      label: DescribeImageExerciseContent.Option.COLUMN,
      value: EDescribeImageExerciseGalleryView.COLUMN,
    },
  ];

export const ESSAY_EXERCISE_TYPE_OPTIONS: TRadioOption<EEssayExerciseType>[] = [
  {
    label: EssayExerciseContent.Option.ESSAY,
    value: EEssayExerciseType.ESSAY,
  },
  {
    label: EssayExerciseContent.Option.TEXT,
    value: EEssayExerciseType.TEXT,
  },
];

export const NOTE_EXERCISE_TYPE_OPTIONS: TSelectOption<ENoteExerciseType>[] = [
  {
    label: NoteExerciseContent.Option.TEACHER_NOTE,
    value: ENoteExerciseType.TEACHER_NOTE,
  },
  {
    label: NoteExerciseContent.Option.INFO,
    value: ENoteExerciseType.INFO,
  },
];

export const COMPARE_IMAGE_WITH_DESCRIPTION_EXERCISE_MODE_OPTIONS: TSelectOption<
  ECompareImageWithDescriptionMode,
  JSX.Element
>[] = [
  {
    label: (
      <SelectModeOption
        icon={<SelectDragModeIcon />}
        title={CompareImageWithDescriptionExerciseContent.Option.Drag.TITLE}
        description={
          CompareImageWithDescriptionExerciseContent.Option.Drag.DESCRIPTION
        }
      />
    ),
    value: ECompareImageWithDescriptionMode.DRAG,
  },
  {
    label: (
      <SelectModeOption
        icon={<SelectInputModeIcon />}
        title={CompareImageWithDescriptionExerciseContent.Option.Input.TITLE}
        description={
          CompareImageWithDescriptionExerciseContent.Option.Input.DESCRIPTION
        }
      />
    ),
    value: ECompareImageWithDescriptionMode.INPUT,
  },
  {
    label: (
      <SelectModeOption
        icon={<SelectSelectModeIcon />}
        title={CompareImageWithDescriptionExerciseContent.Option.Select.TITLE}
        description={
          CompareImageWithDescriptionExerciseContent.Option.Select.DESCRIPTION
        }
      />
    ),
    value: ECompareImageWithDescriptionMode.SELECT,
  },
];
