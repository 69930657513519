import { useDraggable } from "@dnd-kit/core";
import { Draggable } from "../Draggable";
import { TMakeWordFromLettersExerciseParsedWordLetter } from "types/app/exercises";
import "./DraggableItem.styles.scss";

type TDraggableItemProps = {
  /**
   * A letter data from the parsed word.
   */
  letter: TMakeWordFromLettersExerciseParsedWordLetter;
  /**
   * Indicates if draggable letter is placed in correct blank.
   * @default false
   */
  correct?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
};

export const DraggableItem: React.FC<TDraggableItemProps> = (props) => {
  const { letter, correct = false, showResult = false } = props;

  const { isDragging, setNodeRef, listeners } = useDraggable({
    id: letter.id,
    data: { letter },
    disabled: showResult,
  });

  return (
    <Draggable
      ref={setNodeRef}
      dragging={isDragging}
      listeners={listeners}
      content={letter.content}
      correct={correct}
      showResult={showResult}
    />
  );
};
