import { DraggableItem } from "../DraggableItem";
import { Droppable } from "../Droppable";
import {
  TExerciseIdentifier,
  TMakeWordFromLettersExerciseWordBlank,
} from "types/app/exercises";
import "./DroppableItem.styles.scss";

type TDroppableItemProps = {
  /**
   * A word blank that will be rendered as droppable item.
   */
  blank: TMakeWordFromLettersExerciseWordBlank;
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DroppableItem: React.FC<TDroppableItemProps> = (props) => {
  const {
    dragging = false,
    showResult = false,
    blank,
    dragActiveId = null,
  } = props;

  return (
    <Droppable blank={blank} dragging={dragging}>
      {blank.currentLetter !== null &&
        blank.currentLetter?.id !== dragActiveId && (
          <DraggableItem
            letter={blank.currentLetter}
            correct={blank.isCorrectLetter}
            showResult={showResult}
          />
        )}
    </Droppable>
  );
};
