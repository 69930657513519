import { get, post, put, remove } from "api";
import { ADMIN_ENDPOINTS } from "api/endpoints/admin";
import { TUrlParam } from "types/utils";
import {
  TAdminLandingOffer,
  TAdminLandingOfferCreateBody,
} from "types/api/admin";

export const getLandingOffers = (): Promise<TAdminLandingOffer[]> =>
  get<TAdminLandingOffer[]>(ADMIN_ENDPOINTS.LANDING);

export const createLandingOffer = (
  body: TAdminLandingOfferCreateBody
): Promise<TAdminLandingOffer> =>
  post<TAdminLandingOffer>(ADMIN_ENDPOINTS.LANDING, body);

export const editLandingOffer = (
  body: TAdminLandingOffer
): Promise<TAdminLandingOffer> =>
  put<TAdminLandingOffer>(ADMIN_ENDPOINTS.LANDING, body);

export const deleteLandingOffer = (id: TUrlParam): Promise<void> =>
  remove<void>(ADMIN_ENDPOINTS.LANDING_ID(id));
