import { ValueContainerProps, components } from "react-select";
import cx from "classnames";
import { TClueSelectDefaultOption } from "../../ClueSelect.types";
import "./ValueContainer.styles.scss";

export const ValueContainer = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>({
  children,
  hasValue,
  ...props
}: ValueContainerProps<Option, false>): JSX.Element => (
  <components.ValueContainer
    {...props}
    hasValue={hasValue}
    className={cx([
      "nb-interactive-clue-select-value-container",
      {
        "nb-interactive-clue-select-value-container--has-value": hasValue,
      },
    ])}
  >
    {children}
  </components.ValueContainer>
);
