import { useMemo } from "react";
import { NoteExerciseContent } from "content";
import { ReactComponent as ListIcon } from "assets/icons/list.svg";
import { ReactComponent as ExclamationIcon } from "assets/icons/exclamation.svg";
import { Note } from "components/Common/Note";
import { Space } from "components/Common/Space";
import { ENoteExerciseType, TNoteExercise } from "types/app/exercises";
import "./Notes.styles.scss";

type TNotesProps = {
  /**
   * An exercise raw data from API response.
   */
  exercise: TNoteExercise;
};

export const Notes: React.FC<TNotesProps> = (props) => {
  const { exercise } = props;

  const noteTitle: string = useMemo(
    () =>
      exercise.payload.data.noteType === ENoteExerciseType.TEACHER_NOTE
        ? NoteExerciseContent.Option.TEACHER_NOTE
        : NoteExerciseContent.Option.INFO,
    [exercise.payload.data.noteType]
  );

  const noteIcon: React.ReactElement = useMemo(
    () =>
      exercise.payload.data.noteType === ENoteExerciseType.TEACHER_NOTE ? (
        <ListIcon width={20} height={20} />
      ) : (
        <ExclamationIcon width={20} height={20} />
      ),
    [exercise.payload.data.noteType]
  );

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <Note
        title={noteTitle}
        icon={noteIcon}
        content={exercise.payload.data.content}
        fullWidth
      />
    </Space>
  );
};
