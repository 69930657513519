import { useRef } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useAddRequest } from "hooks/api/requests";
import { Input } from "components/Interactive/Input";
import { Button } from "components/Interactive/Button";
import { Select } from "components/Interactive/Select";
import { ReactPortal } from "components/Common/ReactPortal";
import { RequestsContent } from "content";
import { logSuccess } from "utils/notifications";
import { REQUEST_STUDY_METHOD_OPTIONS } from "utils/options";
import { TSelectOption } from "types/app/select";
import { ERequestStudyMethod } from "types/api/requests";
import { TAddRequestForm } from "./AddRequestForm.types";
import "./AddRequestForm.styles.scss";

type TAddRequestFormProps = {
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The function to call when the form is submitted.
   */
  onSubmit?: () => void;
};

export const AddRequestForm: React.FC<TAddRequestFormProps> = (props) => {
  const { className, onSubmit } = props;

  const idRef = useRef<string>(nanoid());

  const { mutate: addRequest } = useAddRequest();

  const [initialStudyMethod] = REQUEST_STUDY_METHOD_OPTIONS;

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddRequestForm>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      phone: "",
      studyMethod: initialStudyMethod,
      period: "",
    },
  });

  const handleAddTeacherSubmit = (formData: TAddRequestForm) => {
    const requestBody = {
      ...formData,
      studyMethod: formData.studyMethod?.label || null,
    };

    addRequest(requestBody, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(RequestsContent.Add.Notification.SUCCESS);
        reset();
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-add-request-form", className])}
      onSubmit={handleSubmit(handleAddTeacherSubmit)}
    >
      <Input<TAddRequestForm>
        name="name"
        id="add-request-name"
        label={RequestsContent.Add.Input.Name.LABEL}
        placeholder={RequestsContent.Add.Input.Name.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Input<TAddRequestForm>
        name="phone"
        type="tel"
        id="add-request-phone"
        label={RequestsContent.Add.Input.Phone.LABEL}
        placeholder={RequestsContent.Add.Input.Phone.PLACEHOLDER}
        minLength={12}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <Select<TAddRequestForm, TSelectOption<ERequestStudyMethod>>
        name="studyMethod"
        id="add-request-method"
        label={RequestsContent.Add.Input.StudyMethod.LABEL}
        placeholder={RequestsContent.Add.Input.StudyMethod.PLACEHOLDER}
        options={REQUEST_STUDY_METHOD_OPTIONS}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Input<TAddRequestForm>
        name="period"
        id="add-request-period"
        label={RequestsContent.Add.Input.Period.LABEL}
        placeholder={RequestsContent.Add.Input.Period.PLACEHOLDER}
        register={register}
        errors={errors}
        fullWidth
      />
      <ReactPortal wrapperId="add-request-modal-footer">
        <Button variant="primary" type="submit" form={idRef.current} fullWidth>
          {RequestsContent.Add.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
