import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import cx from "classnames";
import { RequestsContent } from "content";
import { useEditRequest } from "hooks/api/requests";
import { Select } from "components/Interactive/Select";
import { REQUEST_STATUS_OPTIONS } from "utils/options";
import { logSuccess } from "utils/notifications";
import { ERequestStatus, TRequest } from "types/api/requests";
import { TSelectOption } from "types/app/select";
import "./RequestStatusCell.styles.scss";

type TRequestStatusForm = {
  /**
   * Request status.
   */
  status: TSelectOption<ERequestStatus>;
};

type TRequestStatusCellProps = {
  /**
   * Request data for the table cell.
   */
  request: TRequest;
  /**
   * Callback to handle request status change.
   */
  onChange?: (request: TRequest, status: ERequestStatus) => void;
};

const [initialStatus] = REQUEST_STATUS_OPTIONS;

export const RequestStatusCell: React.FC<TRequestStatusCellProps> = (props) => {
  const { request, onChange } = props;

  const { mutate: editRequest } = useEditRequest();

  const defaultValues: TRequestStatusForm = useMemo(
    () => ({
      status:
        REQUEST_STATUS_OPTIONS.find(
          (option) => option.value === request.status
        ) || initialStatus,
    }),
    [request.status]
  );

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<TRequestStatusForm>({
    defaultValues,
  });

  const status = watch("status");

  const requestStatusChangeHandler = (data: TRequestStatusForm) => {
    const body = {
      ...request,
      status: data.status.value,
    };
    editRequest(body, {
      onSuccess: () => {
        onChange?.(body, data.status.value);
        logSuccess(RequestsContent.EditStatus.Notification.SUCCESS);
      },
    });
  };

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit(requestStatusChangeHandler)()
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select<TRequestStatusForm>
      name="status"
      id={`request-status-${request.id}`}
      options={REQUEST_STATUS_OPTIONS}
      control={control}
      errors={errors}
      className={cx("nb-study-requests-table-status-select", {
        "nb-study-requests-table-status-select--new":
          status.value === ERequestStatus.NEW,
        "nb-study-requests-table-status-select--processed":
          status.value === ERequestStatus.PROCESSED,
      })}
    />
  );
};
