import { Row } from "@tanstack/react-table";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import cx from "classnames";
import { TableRow } from "../TableRow";
import type {
  TDefaultTableData,
  TTableRowIdentifier,
  TTableSubClassnames,
} from "../Table.types";
import "./TableBody.styles.scss";

type TableBodyProps<TData extends TDefaultTableData = TDefaultTableData> = {
  /**
   * Sorted array of the unique identifiers associated with the elements that use the useSortable hook within it.
   * @link https://docs.dndkit.com/presets/sortable/sortable-context#items
   * @default []
   */
  items?: TTableRowIdentifier[];
  /**
   * If `true` the `Table` rows can be reordered by drag'n'drop mechanics
   * and can be handled with `onDragComplete` event listener.
   *
   * @default false
   */
  allowRowReorder?: boolean;
  /**
   * Array of row objects generated in `react-table` to operate with data and cells.
   */
  rows: Row<TData>[];
  /**
   * Object for `Table` sub-components to override or extend the styles applied to the particular component.
   */
  className?: TTableSubClassnames;
};

export const TableBody = <TData extends TDefaultTableData = TDefaultTableData>(
  props: TableBodyProps<TData>
): JSX.Element => {
  const { rows, items = [], allowRowReorder = false, className } = props;

  return (
    <tbody className={cx(["nb-table-body", className?.root])}>
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
        disabled={!allowRowReorder}
      >
        {rows.map((row) => (
          <TableRow<TData>
            key={row.original.id}
            row={row}
            allowRowReorder={allowRowReorder}
            className={className?.row}
          />
        ))}
      </SortableContext>
    </tbody>
  );
};
