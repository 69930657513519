import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { removeCourseLessonChapter } from "api/services/courses";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";

export const useRemoveCourseLessonChapter = (
  courseId?: string | number,
  lessonId?: string | number
) => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, number>(
    (chapterId: number) =>
      removeCourseLessonChapter(courseId, lessonId, chapterId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          ECoursesQueryKeys.getAllCourseLessonChapters
        );
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
