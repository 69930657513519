import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { editCourseLesson } from "api/services/courses";
import { logError } from "utils/notifications";
import { TCourseLesson, TEditCourseLessonBody } from "types/api/course";
import { TApiError } from "types/api/error";
import { TUrlParam } from "types/utils";

export const useEditCourseLesson = (courseId: TUrlParam) => {
  const queryClient = useQueryClient();
  return useMutation<TCourseLesson, TApiError, TEditCourseLessonBody>(
    (courseLesson: TEditCourseLessonBody) =>
      editCourseLesson(courseId, courseLesson),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ECoursesQueryKeys.getAllCourseLessons);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
