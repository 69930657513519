import { Navigate, Outlet } from "react-router-dom";
import { useRole } from "hooks/common";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { Route } from "constants/routes";

type TAdminProtectedProps = {
  /**
   * Path to redirect if user is not admin
   */
  redirectTo?: string;
};

export const AdminProtected: React.FC<TAdminProtectedProps> = (props) => {
  const { redirectTo = Route.App.Study.ROOT.path } = props;

  const { isUserAdmin } = useRole();
  const { activeUser } = useActiveUser();

  return activeUser !== null && isUserAdmin ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} />
  );
};
