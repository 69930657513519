import { useMemo } from "react";
import { StudentsContent } from "content";
import { useStudentById } from "hooks/api/students";
import { ContentModal } from "components/Common/ContentModal";
import { Space } from "components/Common/Space";
import { PersonInfo } from "../PersonInfo";
import { StudentSchedule } from "../StudentSchedule";
import "./StudentScheduleModal.styles.scss";

type TStudentScheduleModalProps = {
  /**
   * Selected student ID to show the schedule for.
   * If `null`, the modal is not shown.
   * @default null
   */
  studentId: number | null;
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose?: () => void;
};

export const StudentScheduleModal: React.FC<TStudentScheduleModalProps> = (
  props
) => {
  const { studentId = null, open = false, onClose } = props;

  const { data: studentData, isLoading } = useStudentById(
    studentId || undefined
  );

  const isModalOpen: boolean = useMemo(
    () => open && studentId !== null,
    [open, studentId]
  );

  return (
    <ContentModal
      portalId="student-schedule-modal"
      open={isModalOpen}
      title={StudentsContent.Schedule.TITLE}
      emoji={StudentsContent.Schedule.EMOJI}
      onClose={onClose}
      className="nb-sub-study-student-schedule-modal"
      contentClassName="nb-sub-study-student-schedule-modal-content"
    >
      <Space
        direction="vertical"
        justify="stretch"
        align="stretch"
        size="large"
        fullWidth
      >
        <PersonInfo
          firstName={studentData?.person.firstName}
          lastName={studentData?.person.lastName}
          email={studentData?.person.email}
          loading={isLoading}
        />
        <StudentSchedule
          student={studentData}
          open={isModalOpen}
          loading={isLoading}
        />
      </Space>
    </ContentModal>
  );
};
