import { SingleValueProps, components } from "react-select";
import cx from "classnames";
import { TSelectDefaultOption } from "../../Select.types";
import "./SingleValue.styles.scss";

type SelectSingleValueProps<
  Option extends TSelectDefaultOption = TSelectDefaultOption
> = {
  /**
   * If `true`, the dropdown indicator will have style modifications.
   * @default false
   */
  isLabelsJSXElements?: boolean;
} & SingleValueProps<Option, false>;

export const SingleValue = <
  Option extends TSelectDefaultOption = TSelectDefaultOption
>({
  isLabelsJSXElements = false,
  children,
  ...props
}: SelectSingleValueProps<Option>): JSX.Element => (
  <components.SingleValue
    {...props}
    className={cx([
      "nb-interactive-select-value",
      {
        "nb-interactive-select-value--element": isLabelsJSXElements,
      },
    ])}
  >
    {children}
  </components.SingleValue>
);
