export const WordsComparing = {
  Add: {
    Input: {
      Condition: {
        LABEL: "Умова вправи",
        PLACEHOLDER: "Опишіть студенту умову вправи",
      },
      Words: {
        LABEL: "Слова для порівняння",
        WORD_PLACEHOLDER: "Слово",
        LINK_WORD_PLACEHOLDER: "Пов'язане слово",
        HINT_PLACEHOLDER: "Підказка до пари",
        ADD_PAIR_BUTTON: "Додати пару",
      },
    },
  },
};
