import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { editCourseLessonChapter } from "api/services/courses";
import { logError } from "utils/notifications";
import { TCourseLessonChapter } from "types/api/course";
import { TApiError } from "types/api/error";

export const useEditCourseLessonChapter = (
  courseId?: string | number,
  lessonId?: string | number
) => {
  const queryClient = useQueryClient();
  return useMutation<TCourseLessonChapter, TApiError, TCourseLessonChapter>(
    (lessonChapter: TCourseLessonChapter) =>
      editCourseLessonChapter(courseId, lessonId, lessonChapter),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          ECoursesQueryKeys.getAllCourseLessonChapters
        );
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
