import { DragOverlay, defaultDropAnimation } from "@dnd-kit/core";
import { ReactPortal } from "components/Common/ReactPortal";
import "./DraggableOverlay.styles.scss";

type TDraggableOverlayProps = {
  /**
   * The unique identifier of the portal element.
   * @default "drag-and-drop-draggable-overlay"
   */
  portalId?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The children of the component.
   */
  children?: React.ReactNode;
};

export const DraggableOverlay: React.FC<TDraggableOverlayProps> = (props) => {
  const {
    portalId = "drag-and-drop-draggable-overlay",
    className,
    children,
  } = props;

  return (
    <ReactPortal wrapperId={portalId}>
      <DragOverlay dropAnimation={defaultDropAnimation} className={className}>
        {children}
      </DragOverlay>
    </ReactPortal>
  );
};
