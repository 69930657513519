import { useEffect, useRef } from "react";

type TUseLazyTimeoutCallback = () => void;

type TUseLazyTimeout = (
  delay: number
) => (callback: TUseLazyTimeoutCallback) => void;

/**
 * Custom common react hook to use timeout in correct way.
 * It will store timeout in ref and will cleanup unfinished timeout on unmount.
 * @remark This hook is called `lazy` because it will not set timeout right after initialization.
 * Hook returns callback which allows to set it manually in event handler etc.
 * @param delay timeout delay.
 * @returns ready to use react timeout callback function.
 */
export const useLazyTimeout: TUseLazyTimeout = (delay) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const lazyTimeout = (callback: TUseLazyTimeoutCallback) => {
    timeoutRef.current = setTimeout(callback, delay);
  };

  return lazyTimeout;
};
