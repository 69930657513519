import { useCallback, useEffect, useState } from "react";

type TUseKeyPress = (targetKey: string, callback?: () => unknown) => boolean;

/**
 * Common hook to handle keyboard press events. As an example: to check `Esc` button press on modal components.
 * @param targetKey - keyboard key to check.
 * @param callback - event handler.
 * @returns indicator which states that targeted key was pressed or not.
 */
export const useKeyPress: TUseKeyPress = (targetKey, callback) => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  const downHandler = useCallback(
    ({ key }: KeyboardEvent): void => {
      if (key === targetKey) {
        setKeyPressed(true);
        callback?.();
      }
    },
    [targetKey, callback]
  );

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [downHandler]);

  return keyPressed;
};
