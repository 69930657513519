import { toast } from "react-toastify";
import { NotificationContent } from "content";
import "./notification.styles.scss";

type TLogSuccess = (message?: string) => void;

export const logSuccess: TLogSuccess = (message) => {
  const logMessage = message || NotificationContent.Success.DEFAULT;

  toast.success(logMessage, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4000,
    className: "nb-notification-container nb-notification-container--success",
    bodyClassName: "nb-notification-body",
    icon: NotificationContent.Success.ICON,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
  });
};
