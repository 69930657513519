import { DropdownIndicatorProps, components } from "react-select";
import cx from "classnames";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { TClueSelectDefaultOption } from "../../ClueSelect.types";
import "./DropdownIndicator.styles.scss";

export const DropdownIndicator = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>({
  isFocused,
  hasValue,
  ...dropdownProps
}: DropdownIndicatorProps<Option, false>): JSX.Element => {
  return (
    <components.DropdownIndicator
      {...dropdownProps}
      isFocused={isFocused}
      hasValue={hasValue}
      className={cx([
        "nb-interactive-clue-select-dropdown-indicator",
        {
          "nb-interactive-clue-select-dropdown-indicator--has-value": hasValue,
        },
      ])}
    >
      <Chevron
        width={15}
        height={15}
        className={cx([
          "nb-interactive-clue-select-icon-chevron",
          {
            "nb-interactive-clue-select-icon-chevron--focused": isFocused,
          },
        ])}
      />
    </components.DropdownIndicator>
  );
};
