import { RoleGlobalContent } from "content";
import { isAdmin, isStudent, isTeacher } from "utils/check";
import { EUserRole } from "types/api/role";

/**
 * Simple function to get user friendly word for user role.
 * @param role enum user role value.
 * @returns user friendly language based role word.
 */
export const getRoleContent = (role?: EUserRole): string => {
  if (isAdmin(role)) {
    return RoleGlobalContent.ADMIN;
  }

  if (isTeacher(role)) {
    return RoleGlobalContent.TEACHER;
  }

  if (isStudent(role)) {
    return RoleGlobalContent.STUDENT;
  }

  return RoleGlobalContent.DEFAULT;
};
