import { useMemo } from "react";
import { DragOverlay, defaultDropAnimation } from "@dnd-kit/core";
import { ReactPortal } from "components/Common/ReactPortal";
import { Draggable } from "../Draggable";
import {
  TExerciseIdentifier,
  TMakeWordFromLettersExerciseParsedWordLetter,
} from "types/app/exercises";
import "./DraggableOverlay.styles.scss";

type TDraggableOverlayProps = {
  /**
   * An array of all available word letters to find the one thats currently dragged.
   */
  letters: TMakeWordFromLettersExerciseParsedWordLetter[];
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const DraggableOverlay: React.FC<TDraggableOverlayProps> = (props) => {
  const { dragActiveId = null, letters, className } = props;

  const dragSelectedLetter: TMakeWordFromLettersExerciseParsedWordLetter | null =
    useMemo(() => {
      if (!dragActiveId) {
        return null;
      }
      const letter = letters.find(({ id }) => id === dragActiveId);
      return letter || null;
    }, [dragActiveId, letters]);

  return (
    <ReactPortal wrapperId="make-words-from-letters-draggable-item-overlay">
      <DragOverlay dropAnimation={defaultDropAnimation} className={className}>
        {dragSelectedLetter && (
          <Draggable
            content={dragSelectedLetter.content}
            dragging
            dragOverlay
          />
        )}
      </DragOverlay>
    </ReactPortal>
  );
};
