import { useEffect, useRef } from "react";
import cx from "classnames";
import { useLazyTimeout, useStateCallback } from "hooks/common";
import { Space } from "components/Common/Space";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import "./TestCheckmark.styles.scss";

type TTestCheckmarkProps = {
  /**
   * Input label text for the `TestCheckmark` element.
   */
  label?: string;
  /**
   * The id of the `TestCheckmark` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * Name attribute of the `TestCheckmark` element.
   */
  name?: string;
  /**
   * If `true`, the `TestCheckmark` will take up the full width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the component will be checked by default.
   *
   * @default false
   */
  defaultChecked?: boolean;
  /**
   * The value of the `checkbox` element, required for a controlled component.
   */
  value?: boolean;
  /**
   * If `true`, the `TestCheckmark` element is focused during the first mount.
   *
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Callback will be triggered when the checkmark state was changed.
   *
   * @param checked - active state for checked parameter
   * @param event - react input change event
   */
  onChange?: (
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

/**
 * `TestCheckmark` is interactive component which allows to toggle the state of a single circle checkbox with a checkmark.
 */
export const TestCheckmark: React.FC<TTestCheckmarkProps> = (props) => {
  const {
    label,
    id,
    name,
    fullWidth = false,
    disabled = false,
    defaultChecked = false,
    value,
    autoFocus = false,
    className,
    onChange,
  } = props;

  const innerRef = useRef<HTMLInputElement>(null);

  const [isInnerChecked, setIsInnerChecked] =
    useStateCallback<boolean>(defaultChecked);

  const autofocusTimer = useLazyTimeout(100);

  useEffect(() => {
    if (value !== undefined) {
      setIsInnerChecked(value);
    }
  }, [value, setIsInnerChecked]);

  useEffect(() => {
    autofocusTimer(() => {
      if (
        document.activeElement !== innerRef.current &&
        autoFocus &&
        !disabled &&
        innerRef.current
      ) {
        innerRef.current.focus();
      }
    });
  }, [autoFocus, disabled, autofocusTimer]);

  const checkmarkChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      evt.preventDefault();
      return;
    }

    setIsInnerChecked(
      (prevState) => !prevState,
      (updatedState) => onChange?.(updatedState, evt)
    );
  };

  return (
    <Space
      direction="horizontal"
      justify="start"
      align="start"
      size="x-small"
      fullWidth={fullWidth}
      className={cx(["nb-interactive-test-checkmark-wrapper", className])}
    >
      <div className="nb-interactive-test-checkmark-input-wrapper">
        <input
          ref={innerRef}
          type="checkbox"
          aria-label={label}
          id={id}
          name={name}
          checked={isInnerChecked}
          disabled={disabled}
          onChange={checkmarkChangeHandler}
          className="nb-interactive-test-checkmark-input"
        />
        <div
          className={cx([
            "nb-interactive-test-checkmark-tick",
            {
              "nb-interactive-test-checkmark-tick--checked": isInnerChecked,
            },
          ])}
        >
          <TickIcon
            className={cx([
              "nb-interactive-test-checkmark-tick-icon",
              {
                "nb-interactive-test-checkmark-tick-icon--checked":
                  isInnerChecked,
              },
            ])}
            width={10}
            height={7}
          />
        </div>
      </div>
      {label && (
        <label className="nb-interactive-test-checkmark-label" htmlFor={id}>
          {label}
        </label>
      )}
    </Space>
  );
};
