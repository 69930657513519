import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { endOfWeek, startOfWeek } from "date-fns";
import { EAppReducers } from "store/white-list";

export enum EScheduleViewType {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

type TScheduleState = {
  viewType: EScheduleViewType;
  /**
   * We need to store date as timestamp because redux do not like not serialized types.
   */
  start: number;
  end: number;
};

const initialState: TScheduleState = {
  viewType: EScheduleViewType.WEEK,
  start: startOfWeek(new Date(), { weekStartsOn: 1 }).valueOf(),
  end: endOfWeek(new Date(), { weekStartsOn: 1 }).valueOf(),
};

export const scheduleSlice = createSlice({
  name: EAppReducers.SCHEDULE,
  initialState,
  reducers: {
    setViewType: (state, action: PayloadAction<EScheduleViewType>) => {
      state.viewType = action.payload;
    },
    setStartDate: (state, action: PayloadAction<number>) => {
      state.start = action.payload;
    },
    setEndDate: (state, action: PayloadAction<number>) => {
      state.end = action.payload;
    },
    resetDate: (state) => {
      state.start = initialState.start;
      state.end = initialState.end;
    },
  },
});

export const scheduleReducer = scheduleSlice.reducer;
