import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { EssayExerciseContent, ExerciseConstructorContent } from "content";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { useModal } from "hooks/common";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { Paragraph } from "components/Typography/Paragraph";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { Textarea } from "components/Interactive/Textarea";
import { RadioGroup } from "components/Interactive/RadioGroup";
import { ImageUploadModal } from "components/Interactive/ImageUploadModal";
import { Space } from "components/Common/Space";
import { Image } from "components/Common/Image";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { convertBlobToBase64String } from "utils/common";
import { ESSAY_EXERCISE_TYPE_OPTIONS } from "utils/options";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import { EExerciseType, TEssayExercisePayload } from "types/app/exercises";
import { TEssayExerciseForm } from "./EssayExerciseForm.types";
import "./EssayExerciseForm.styles.scss";

const [initialEssayType] = ESSAY_EXERCISE_TYPE_OPTIONS;

export const EssayExerciseForm: React.FC = () => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isImageDeleted, setIsImageDeleted] = useState<boolean>(false);

  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TEssayExercisePayload>();
  const { isModalOpen, openModal, closeModal } = useModal(false);

  const { mutate: addExercise } = useAddExercise<TEssayExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TEssayExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TEssayExercisePayload>(courseId, lessonId, chapterId);
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TEssayExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TEssayExerciseForm> = useMemo(
    () => ({
      text: editExercise?.payload.text || "",
      description: editExercise?.payload.data.description || "",
      essayType:
        ESSAY_EXERCISE_TYPE_OPTIONS.find(
          (option) => option.value === editExercise?.payload.data.essayType
        ) || initialEssayType,
    }),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<TEssayExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (editExercise?.payload.data.fileUrl && isEditMode) {
      setImageUrl(editExercise.payload.data.fileUrl);
    }
    return () => {
      setImageUrl(null);
    };
  }, [editExercise?.payload.data.fileUrl, isEditMode]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
    setUploadedImage(null);
    setIsImageDeleted(false);
  };

  const submitLessonExercise = (body: TAddExercise<TEssayExercisePayload>) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TEssayExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TEssayExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TEssayExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TEssayExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const [, cleanBase64] = uploadedImage?.split(",") || [];

    const addBody: TAddExercise<TEssayExercisePayload> = {
      type: EExerciseType.ESSAY,
      payload: {
        text: formData.text,
        data: {
          fileUrl: null,
          fileEncoded: cleanBase64 || null,
          description: formData.description || null,
          essayType: formData.essayType.value,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TEssayExercisePayload> = {
        id: editExercise.id,
        type: EExerciseType.ESSAY,
        payload: {
          text: formData.text,
          data: {
            fileUrl: isImageDeleted ? null : editExercise.payload.data.fileUrl,
            fileEncoded: cleanBase64 || editExercise.payload.data.fileEncoded,
            description: formData.description || null,
            essayType: formData.essayType.value,
          },
        },
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  const imageUploadHandler = (image: Blob) => {
    convertBlobToBase64String(image).then(setUploadedImage).then(closeModal);
  };

  const imageDeleteHandler = () => {
    setImageUrl(null);
    setUploadedImage(null);
    setIsImageDeleted(true);
  };

  const isImageAvailable: boolean = useMemo(
    () => imageUrl !== null || uploadedImage !== null,
    [imageUrl, uploadedImage]
  );

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <RadioGroup<TEssayExerciseForm>
          name="essayType"
          control={control}
          errors={errors}
          id="essay-exercise-type"
          layout="horizontal"
          variant="rect"
          label={EssayExerciseContent.Add.Input.Type.LABEL}
          options={ESSAY_EXERCISE_TYPE_OPTIONS}
          required
          fullWidth
        />
        <Textarea<TEssayExerciseForm>
          name="text"
          id="essay-exercise--text"
          className="nb-exercise-constructor-essay-exercise-text"
          label={EssayExerciseContent.Add.Input.Text.LABEL}
          placeholder={EssayExerciseContent.Add.Input.Text.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Space direction="vertical" justify="start" size="x-small" fullWidth>
          <Paragraph variant={2}>
            {EssayExerciseContent.Add.Input.Upload.LABEL}
          </Paragraph>
          {isImageAvailable && (
            <Image
              src={(imageUrl || uploadedImage) as string}
              rounded
              fullWidth
            />
          )}
          <Space
            direction="horizontal"
            justify="stretch"
            align="center"
            size="x-small"
            fullWidth
          >
            <Button variant="secondary" fullWidth onClick={openModal}>
              {isImageAvailable
                ? EssayExerciseContent.Add.Input.Upload.Button.CHANGE
                : EssayExerciseContent.Add.Input.Upload.Button.ADD}
            </Button>
            {isImageAvailable && (
              <IconButton
                icon={<DeleteIcon />}
                variant="secondary"
                className="nb-exercise-constructor-essay-exercise-image-remove"
                onClick={imageDeleteHandler}
              />
            )}
          </Space>
        </Space>
        <Textarea<TEssayExerciseForm>
          name="description"
          id="essay-exercise--description"
          className="nb-exercise-constructor-essay-exercise-description"
          label={EssayExerciseContent.Add.Input.Description.LABEL}
          placeholder={EssayExerciseContent.Add.Input.Description.PLACEHOLDER}
          register={register}
          errors={errors}
          fullWidth
          hint={EssayExerciseContent.Add.Input.Description.HINT}
        />
      </Space>
      <ImageUploadModal
        open={isModalOpen}
        aspect={16 / 9}
        zoomSpeed={0.5}
        accept="image/jpeg, image/png, image/jpg, image/webp"
        id="essay-exercise-upload-input"
        name="essay-exercise-upload-input"
        portalId="essay-exercise-upload-modal"
        withUploadButton
        title={EssayExerciseContent.Add.Modal.TITLE}
        emoji={EssayExerciseContent.Add.Modal.EMOJI}
        selectButtonText={EssayExerciseContent.Add.Modal.Button.SELECT}
        uploadButtonText={EssayExerciseContent.Add.Modal.Button.UPLOAD}
        inputLabel={EssayExerciseContent.Add.Modal.Input.LABEL}
        onClose={closeModal}
        onUpload={imageUploadHandler}
        className="nb-exercise-constructor-essay-exercise-upload-modal"
      />
    </ExerciseConfigForm>
  );
};
