import { format } from "date-fns";
import {
  EScheduleViewType,
  scheduleSelector,
  scheduleSlice,
} from "store/features/schedule";
import { useAppDispatch, useAppSelector } from "hooks/redux";

export const useScheduleDates = () => {
  const dispatch = useAppDispatch();
  const { viewType, start, end } = useAppSelector(scheduleSelector);
  const { setViewType, setStartDate, setEndDate, resetDate } =
    scheduleSlice.actions;

  const formatDate = (date: number) => format(date, "yyyy-MM-dd");
  const formatQueryDate = (date: number) => format(date, "MM/dd/yyyy HH:mm:ss");

  return {
    viewType,
    startDate: start,
    endDate: end,
    formattedStartDate: formatDate(start),
    formattedEndDate: formatDate(end),
    queryStartDate: formatQueryDate(start),
    queryEndDate: formatQueryDate(end),
    setViewType: (type: EScheduleViewType) => {
      dispatch(setViewType(type));
    },
    setStartDate: (startDate: Date) => {
      dispatch(setStartDate(startDate.valueOf()));
    },
    setEndDate: (endDate: Date) => {
      dispatch(setEndDate(endDate.valueOf()));
    },
    resetDate: () => {
      dispatch(resetDate());
    },
  };
};
