import { PlaceholderProps, components } from "react-select";
import type { TClueSelectDefaultOption } from "../../ClueSelect.types";
import "./Placeholder.styles.scss";

export const Placeholder = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>(
  props: PlaceholderProps<Option, false>
): JSX.Element => {
  return (
    <components.Placeholder
      {...props}
      className="nb-interactive-clue-select-placeholder"
    >
      {props.children}
    </components.Placeholder>
  );
};
