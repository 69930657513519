import { Space } from "components/Common/Space";
import { TestQuestionItem } from "../TestQuestionItem";
import { TTestExerciseQuestionItem } from "types/app/exercises";
import "./TestQuestionList.styles.scss";

type TTestQuestionListProps = {
  /**
   * Array of test exercise question data.
   * @default []
   */
  questions?: TTestExerciseQuestionItem[];
};

export const TestQuestionList: React.FC<TTestQuestionListProps> = (props) => {
  const { questions = [] } = props;

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      {questions.map((item, index) => (
        <TestQuestionItem
          key={item.question}
          question={item}
          order={index + 1}
        />
      ))}
    </Space>
  );
};
