import { useMutation, useQueryClient } from "react-query";
import { addFile } from "api/services/files";
import { EFilesQueryKeys } from "api/endpoints/files";
import { logError } from "utils/notifications";
import { TAddFileBody, TFile } from "types/api/file";
import { TApiError } from "types/api/error";

export const useAddFile = () => {
  const queryClient = useQueryClient();
  return useMutation<TFile, TApiError, TAddFileBody>(
    ({ file, params }) => addFile(file, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EFilesQueryKeys.getFileById);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
