import { Table } from "@tanstack/react-table";
import cx from "classnames";
import { IconButton } from "components/Interactive/IconButton";
import { Text } from "components/Typography/Text";
import { TableContent } from "content";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { TDefaultTableData } from "../Table.types";
import "./TableFooter.styles.scss";

type TableFooterProps<TData extends TDefaultTableData = TDefaultTableData> = {
  /**
   * Core table object of `react-table` API.
   */
  table: Table<TData>;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Callback fired when the page is changed.
   *
   * @param {Number} page - new page index of the `Table` component.
   * @return {undefined} should be voided function.
   */
  onPageChange?: (newPage: number) => void;
};

export const TableFooter = <
  TData extends TDefaultTableData = TDefaultTableData
>(
  props: TableFooterProps<TData>
): JSX.Element => {
  const { table, className, onPageChange } = props;

  const handlePreviousButtonClick = () => {
    if (!table.getCanPreviousPage()) {
      return;
    }
    const currentPage = table.getState().pagination.pageIndex - 1;
    onPageChange?.(currentPage);
    table.previousPage();
  };

  const handleNextButtonClick = () => {
    if (!table.getCanNextPage()) {
      return;
    }
    const currentPage = table.getState().pagination.pageIndex + 1;
    onPageChange?.(currentPage);
    table.nextPage();
  };

  return (
    <div className={cx(["nb-table-footer", className])}>
      {/* Jump to very first page button (need to change icon)  */}
      {/* <IconButton
        icon={<Chevron />}
        className="nb-table-pagination-first"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      /> */}
      <IconButton
        icon={<Chevron />}
        className="nb-table-pagination-previous"
        onClick={handlePreviousButtonClick}
        disabled={!table.getCanPreviousPage()}
      />
      <IconButton
        icon={<Chevron />}
        className="nb-table-pagination-next"
        onClick={handleNextButtonClick}
        disabled={!table.getCanNextPage()}
      />
      {/* Jump to very last page button (need to change icon)  */}
      {/* <IconButton
        icon={<Chevron />}
        className="nb-table-pagination-last"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      /> */}
      <div className="nb-table-pagination-counter">
        <Text variant="body2">
          {TableContent.Pagination.PAGE}&nbsp;
          <Text bold variant="body2">
            {table.getState().pagination.pageIndex + 1}
          </Text>
          &nbsp;{TableContent.Pagination.SEPARATOR}&nbsp;
          <Text bold variant="body2">
            {table.getPageCount()}
          </Text>
        </Text>
      </div>
    </div>
  );
};
