import { useLocation } from "react-router-dom";
import { BrandLogo } from "components/Common/BrandLogo";
import { Divider } from "components/Common/Divider";
import { SiteNavigation } from "components/Navigation/SiteNavigation";
import { NavigationItem } from "components/Navigation/NavigationItem";
import { ReactComponent as Mail } from "assets/icons/mail.svg";
import { NavigationContent } from "content";
import { Route } from "constants/routes";
import "./Sidebar.styles.scss";

export const Sidebar: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <aside className="nb-layout-sidebar">
      <div className="nb-layout-sidebar-logo">
        <BrandLogo />
      </div>
      <Divider />
      <div className="nb-layout-sidebar-menu">
        <SiteNavigation />
      </div>
      <div className="nb-layout-sidebar-image">
        <img
          src="/assets/images/sidebar-alina.png"
          width={166}
          height={192}
          alt="Аліна - засновник школи"
        />
      </div>
      <Divider />
      <div className="nb-layout-sidebar-support">
        <NavigationItem
          link={Route.App.Support.ROOT.path}
          label={NavigationContent.Main.Support}
          icon={<Mail />}
          active={pathname.includes(Route.App.Support.ROOT.path)}
        />
      </div>
    </aside>
  );
};
