import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "hooks/common/useModal";
import { useEditGroup, useGroupById, useGroupMembers } from "hooks/api/groups";
import { useBreadcrumbs } from "hooks/redux";
import { useGroupMemberColumns } from "hooks/table/useGroupMemberColumns";
import { GroupDetailsContent } from "content";
import { Empty } from "components/Common/Empty";
import { ActionModal } from "components/Common/ActionModal";
import { Table } from "components/Table";
import { StudySearch } from "pages/Study/components/StudySearch";
import { GroupHero } from "../../components/GroupHero";
import { AddGroupModal } from "../../components/AddGroupModal";
import { logError, logSuccess } from "utils/notifications";
import { Route } from "constants/routes";
import { TStudent } from "types/api/student";
import { TSelectOption } from "types/app/select";
import { EStudySearchTarget } from "types/app/study";
import "./GroupDetails.styles.scss";

export const GroupDetails: React.FC = () => {
  const { id } = useParams();
  const groupId: number | undefined = id ? Number(id) : undefined;
  const navigate = useNavigate();
  const { setPageName, resetPageName } = useBreadcrumbs();

  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedStudentId, setSelectedStudentId] = useState<number | null>(
    null
  );

  const {
    data: groupData,
    isLoading: isGroupLoading,
    isError,
  } = useGroupById(groupId);
  const { data: membersData, isLoading: membersLoading } =
    useGroupMembers(groupId);

  const { mutate } = useEditGroup();

  const groupMembers: TStudent[] = useMemo(
    () => membersData || [],
    [membersData]
  );
  const isEmpty: boolean = groupMembers.length === 0;

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);
  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  useEffect(() => {
    if (groupData?.name) {
      setPageName(groupData?.name);
    }
    return () => resetPageName();
  }, [groupData?.name, setPageName, resetPageName]);

  useEffect(() => {
    if (isError) {
      navigate(Route.App.Study.GROUPS.path);
    }
  }, [isError, navigate]);

  const searchCallback = useCallback(async (): Promise<
    TSelectOption<number>[]
  > => {
    return groupMembers
      .map((member) => ({
        label: `${member.person.firstName} ${member.person.lastName}`,
        value: member.id,
      }))
      .filter((member) => member.label.includes(searchValue));
  }, [groupMembers, searchValue]);

  const handleDeleteButtonClick = (id: number) => {
    setSelectedStudentId(id);
    openDeleteModal();
  };

  const handleDeleteSubmit = () => {
    if (selectedStudentId === null || !groupData) {
      logError({ message: GroupDetailsContent.Delete.Notification.ERROR });
      return;
    }

    const newGroupData = {
      ...groupData,
      memberIds: groupData.memberIds.filter((id) => id !== selectedStudentId),
    };
    mutate(newGroupData, {
      onSuccess: () => {
        logSuccess(GroupDetailsContent.Delete.Notification.SUCCESS);
        closeDeleteModal();
      },
    });
  };

  const columns = useGroupMemberColumns({
    onDeleteClick: handleDeleteButtonClick,
  });

  return (
    <>
      <StudySearch
        searchTarget={EStudySearchTarget.Groups}
        searchPlaceholder={GroupDetailsContent.Search.PLACEHOLDER}
        searchCallback={searchCallback}
        setSearchValue={setSearchValue}
        onAddButtonClick={openAddModal}
      />
      <GroupHero group={groupData} loading={isGroupLoading} />
      {isEmpty && !membersLoading ? (
        <Empty text={GroupDetailsContent.Empty} />
      ) : (
        <Table<TStudent>
          data={groupMembers}
          columns={columns}
          loading={membersLoading}
          totalItems={groupMembers.length}
        />
      )}
      <ActionModal
        text={GroupDetailsContent.Delete.TITLE}
        emoji={GroupDetailsContent.Delete.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteSubmit}
      />
      <AddGroupModal open={isAddModalOpen} onClose={closeAddModal} />
    </>
  );
};
