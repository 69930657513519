import { get, post, remove } from "api";
import { FILES_ENDPOINTS } from "api/endpoints/files";
import {
  TAddFile,
  TAddFileRequestParams,
  TFile,
  TFileBinary,
} from "types/api/file";
import { TUrlParam } from "types/utils";

export const addFile = (
  file: TAddFile,
  params: TAddFileRequestParams
): Promise<TFile> =>
  post<TFile>(FILES_ENDPOINTS.ROOT, file, {
    params,
  });

export const getFileById = (id: TUrlParam): Promise<TFileBinary> =>
  get<TFileBinary>(`${FILES_ENDPOINTS.ROOT}/${id}`, {
    responseType: "blob",
  });

export const removeFile = (id: number): Promise<void> =>
  remove<void>(`${FILES_ENDPOINTS.ROOT}/${id}`);
