export enum ECoursesQueryKeys {
  getAllCourses = "getAllCourses",
  getCourseById = "getCourseById",
  getAllCourseLessons = "getAllCourseLessons",
  getCourseLessonById = "getCourseLessonById",
  getAllCourseLessonChapters = "getAllCourseLessonChapters",
  getCourseLessonChapterById = "getCourseLessonChapterById",
}

export const COURSES_ENDPOINTS = {
  COURSES: "/materials/courses",
};
