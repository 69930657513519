import { Space } from "components/Common/Space";
import "./ImagesList.styles.scss";

type TImagesListProps = {
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const ImagesList: React.FC<TImagesListProps> = (props) => {
  const { children, className } = props;

  return (
    <Space
      direction="horizontal"
      justify="start"
      align="start"
      size="x-large"
      fullWidth
      wrap
      className={className}
    >
      {children}
    </Space>
  );
};
