import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const compareWordsExercise: TExerciseItem = {
  title: "Зіставлення слів",
  backgroundText: "Зіставлення",
  tags: [
    "Зіставлення слів",
    "зіставлення",
    "зіставити",
    "порівняти",
    "слів",
    "слова",
    "compare",
    "words",
  ],
  type: EExerciseType.WORDS_COMPARING,
  cover: "compare-words.png",
};
