import { Fragment } from "react";
import {
  ArrayPath,
  Control,
  FieldArrayWithId,
  FieldErrorsImpl,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { Divider } from "components/Common/Divider";
import { Space } from "components/Common/Space";
import { ImageItem } from "../ImageItem";
import { TImageListUploadDefaultValue } from "../ImageListUpload.types";
import "./ImageList.styles.scss";

type TImageListProps<
  TFormValues extends TImageListUploadDefaultValue = TImageListUploadDefaultValue
> = {
  /**
   * Name attribute of the `ImageList` component.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: ArrayPath<TFormValues>;
  /**
   * This object contains the defaultValue and key of the `react-hook-form` fields.
   */
  fields: FieldArrayWithId<TFormValues, ArrayPath<TFormValues>, "id">[];
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
  /**
   * This method allows you to register an input or select element and apply validation rules to React Hook Form. Validation rules are all based on the HTML standard and also allow for custom validation methods.
   * @link https://react-hook-form.com/api/useform/register
   */
  register?: UseFormRegister<TFormValues>;
  /**
   * An object with field errors.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * The minimum number of images allowed to upload.
   * @default 1
   */
  minImagesAllowed?: number;
  /**
   * The content of the pick image button.
   */
  pickImageButtonText?: string;
  /**
   * The content of the change image button.
   */
  changeImageButtonText?: string;
  /**
   * The short hint displayed in the description `textarea` before the user enters a value.
   */
  descriptionPlaceholder?: string;
  /**
   * If `true`, the description field will be required to fill.
   * @default false
   */
  descriptionRequired?: boolean;
  /**
   * Callback fired when `delete` event ocurred.
   */
  onDelete?: (inputIndex: number) => void;
  /**
   * Callback fired when user clicks on `Add image` button.
   */
  onAddModalOpen?: (inputIndex: number) => void;
};

export const ImageList = <
  TFormValues extends TImageListUploadDefaultValue = TImageListUploadDefaultValue
>(
  props: TImageListProps<TFormValues>
): JSX.Element => {
  const {
    name,
    fields,
    errors,
    pickImageButtonText,
    changeImageButtonText,
    descriptionPlaceholder,
    minImagesAllowed,
    descriptionRequired = false,
    control,
    register,
    onDelete,
    onAddModalOpen,
  } = props;

  return (
    <Space direction="vertical" justify="start" size="x-small" fullWidth>
      {fields.map((item, index, self) => (
        <Fragment key={item.id}>
          <ImageItem<TFormValues>
            id={item.id}
            index={index}
            name={`${name}.${index}` as Path<TFormValues>}
            disableDelete={self.length === minImagesAllowed}
            control={control}
            register={register}
            errors={errors}
            pickImageButtonText={pickImageButtonText}
            changeImageButtonText={changeImageButtonText}
            descriptionRequired={descriptionRequired}
            placeholder={descriptionPlaceholder}
            onDelete={() => onDelete?.(index)}
            onAddModalOpen={() => onAddModalOpen?.(index)}
          />
          <Divider margin="yAxis" marginSize="small" />
        </Fragment>
      ))}
    </Space>
  );
};
