import { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import { useEditGroup } from "hooks/api/groups";
import { useStudents } from "hooks/api/students";
import { ContentModal } from "components/Common/ContentModal";
import { ReactPortal } from "components/Common/ReactPortal";
import { PersonSelect } from "components/Interactive/PersonSelect";
import { Button } from "components/Interactive/Button";
import { GroupDetailsContent } from "content";
import { logError, logSuccess } from "utils/notifications";
import { TGroup } from "types/api/groups";
import { TStudent } from "types/api/student";
import { TPersonSelectOption } from "types/app/select";
import { TAddMembersGroupForm } from "./AddStudentsModal.types";
import "./AddStudentsModal.styles.scss";

type AddStudentsModalProps = {
  /**
   * Group data to display in `Hero` component.
   */
  group?: TGroup;
  /**
   * If `true`, the modal is shown.
   *
   * @default false
   */
  open?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
};

export const AddStudentsModal: React.FC<AddStudentsModalProps> = (props) => {
  const { open = false, group, onClose } = props;

  const [studentSearchValue, setStudentSearchValue] = useState<string>("");
  const idRef = useRef<string>(nanoid());

  const { mutate } = useEditGroup();
  const { data: studentsData } = useStudents({
    searchText: studentSearchValue,
  });
  const students: TStudent[] = useMemo(
    () => studentsData?.content || [],
    [studentsData?.content]
  );
  const groupMemberIds: number[] = useMemo(
    () => group?.memberIds || [],
    [group?.memberIds]
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddMembersGroupForm>({
    mode: "onBlur",
  });

  const filteredStudents = useMemo(
    () =>
      students
        .map((student) => ({
          id: student.id,
          name: `${student.person.firstName} ${student.person.lastName}`,
          description: student.person.email,
        }))
        .filter((student) => !groupMemberIds.includes(student.id)),
    [students, groupMemberIds]
  );
  const studentsSearchCallback = useCallback(
    async (): Promise<TPersonSelectOption[]> =>
      Promise.resolve(filteredStudents),
    [filteredStudents]
  );

  const studentsSearchHandler = (searchValue: string) => {
    setStudentSearchValue(searchValue);
  };

  const handleAddGroupMembersSubmit = (formData: TAddMembersGroupForm) => {
    if (!group) {
      return;
    }

    const addedMemberIds = formData.members.map((member) => member.id);
    const memberIds = [...new Set([...group.memberIds, ...addedMemberIds])];
    const editedGroup = {
      ...group,
      memberIds,
    };

    mutate(editedGroup, {
      onSuccess: () => {
        logSuccess(GroupDetailsContent.Add.Notification.SUCCESS);
        onClose();
        reset({ members: [] });
      },
      onError: () => {
        logError({ message: GroupDetailsContent.Add.Notification.ERROR });
      },
    });
  };

  return (
    <ContentModal
      portalId="add-students-to-group-modal"
      footerId="add-students-to-group-modal-footer"
      open={open}
      title={GroupDetailsContent.Add.TITLE}
      emoji={GroupDetailsContent.Add.EMOJI}
      onClose={onClose}
    >
      <form
        id={idRef.current}
        className="nb-sub-add-group-form"
        onSubmit={handleSubmit(handleAddGroupMembersSubmit)}
      >
        <PersonSelect<TAddMembersGroupForm, TPersonSelectOption, true>
          name="members"
          id="add-group-members-modal"
          className="nb-sub-add-group-select"
          placeholder={GroupDetailsContent.Add.Input.Participants.PLACEHOLDER}
          searchCallback={studentsSearchCallback}
          onSearchChange={studentsSearchHandler}
          control={control}
          errors={errors}
          defaultOptions={filteredStudents}
          size={3}
          multi
          menuAlwaysOpen
          required
          fullWidth
        />
        <ReactPortal wrapperId="add-students-to-group-modal-footer">
          <Button
            className="nb-sub-add-group-submit"
            variant="primary"
            type="submit"
            form={idRef.current}
            fullWidth
          >
            {GroupDetailsContent.Add.SUBMIT}
          </Button>
        </ReactPortal>
      </form>
    </ContentModal>
  );
};
