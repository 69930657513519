import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { ExerciseConstructorContent, TestExerciseContent } from "content";
import { Divider } from "components/Common/Divider";
import { Textarea } from "components/Interactive/Textarea";
import { TestQuestions } from "components/Interactive/TestQuestions";
import { Switch } from "components/Interactive/Switch";
import { Select } from "components/Interactive/Select";
import { Text } from "components/Typography/Text";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { ReactComponent as Time } from "assets/icons/time.svg";
import { EXERCISE_DURATION_OPTIONS } from "utils/options/exercise";
import { logError, logSuccess } from "utils/notifications";
import { getTestExerciseFormInitialValues } from "./TestExerciseForm.helpers";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import { EExerciseType, TTestExercisePayload } from "types/app/exercises";
import { TChooseAnswerFromOptionsExerciseForm } from "./TestExerciseForm.types";
import "./TestExerciseForm.styles.scss";

export const TestExerciseForm: React.FC = () => {
  const [isWithTimer, setIsWithTimer] = useState<boolean>(true);

  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TTestExercisePayload>();
  const { mutate: addExercise } = useAddExercise<TTestExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TTestExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } = useEditExercise<TTestExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TTestExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TChooseAnswerFromOptionsExerciseForm> = useMemo(
    () => getTestExerciseFormInitialValues(editExercise),
    [editExercise]
  );

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<TChooseAnswerFromOptionsExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (editExercise && isEditMode) {
      setIsWithTimer(editExercise.payload.data.timer !== null);
    }
  }, [editExercise, isEditMode]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (body: TAddExercise<TTestExercisePayload>) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (body: TAddExercise<TTestExercisePayload>) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TTestExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TTestExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (
    formData: TChooseAnswerFromOptionsExerciseForm
  ) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const addBody: TAddExercise<TTestExercisePayload> = {
      type: EExerciseType.TEST,
      payload: {
        text: formData.text,
        data: {
          timer: isWithTimer ? formData.timer.value : null,
          questions: formData.questions,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TTestExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  const timerSwitchChangeHandler = (checked: boolean) => {
    setIsWithTimer(checked);
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <div className="nb-exercise-constructor-test">
        <Textarea<TChooseAnswerFromOptionsExerciseForm>
          name="text"
          id="test-exercise-condition"
          className="nb-exercise-constructor-test-condition"
          label={TestExerciseContent.Add.Input.Condition.LABEL}
          placeholder={TestExerciseContent.Add.Input.Condition.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <div className="nb-exercise-constructor-test-timer-wrapper">
          <Text variant="body2">
            {TestExerciseContent.Add.Input.Timer.LABEL}
          </Text>
          <Switch value={isWithTimer} onChange={timerSwitchChangeHandler} />
          {isWithTimer && (
            <Select<TChooseAnswerFromOptionsExerciseForm>
              name="timer"
              id="test-exercise-timer-select"
              className="nb-exercise-constructor-test-timer"
              placeholder={TestExerciseContent.Add.Input.Timer.PLACEHOLDER}
              options={EXERCISE_DURATION_OPTIONS}
              control={control}
              errors={errors}
              icon={<Time />}
              required
              fullWidth
            />
          )}
        </div>
        <Divider margin="none" />
        <TestQuestions<TChooseAnswerFromOptionsExerciseForm>
          control={control}
          register={register}
          errors={errors}
          answerLabel={TestExerciseContent.Add.Input.TestQuestions.ANSWER_LABEL}
          answerPlaceholder={
            TestExerciseContent.Add.Input.TestQuestions.ANSWER_PLACEHOLDER
          }
          questionLabel={
            TestExerciseContent.Add.Input.TestQuestions.QUESTION_LABEL
          }
          questionPlaceholder={
            TestExerciseContent.Add.Input.TestQuestions.QUESTION_PLACEHOLDER
          }
          addAnswerButtonText={
            TestExerciseContent.Add.Input.TestQuestions.ADD_ANSWER_TEXT
          }
          addQuestionButtonText={
            TestExerciseContent.Add.Input.TestQuestions.ADD_QUESTION_TEXT
          }
        />
      </div>
    </ExerciseConfigForm>
  );
};
