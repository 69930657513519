import { useMemo, useRef } from "react";
import cx from "classnames";
import { useImageUpload } from "hooks/redux";
import { FileUploadContent } from "content";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { Space } from "components/Common/Space";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { TUploadInputProps, UploadInput } from "./UploadInput";
import { ImageCropper, TImageCropperProps } from "./ImageCropper";
import { logError } from "utils/notifications";
import { convertBase64ToBlob, generateCroppedImage } from "utils/common";
import "./ImageUpload.styles.scss";

export type TImageUploadProps = Omit<
  TImageCropperProps,
  "image" | "className"
> &
  Omit<TUploadInputProps, "fullWidth" | "className"> & {
    /**
     * If `true`, the image cropper will show up after user selects file.
     * @remark Cropper wont appear if user selected non valid image MIME type.
     * To prevent that, please provide appropriate `accept` prop.
     * @default false
     */
    withImageCropper?: boolean;
    /**
     * If `true`, the upload button will show up below the upload input.
     * @default false
     */
    withUploadButton?: boolean;
    /**
     * The inner text of the select file button component.
     */
    selectButtonText?: string;
    /**
     * The inner text of the upload button component.
     */
    uploadButtonText?: string;
    /**
     * A string indicating the image output format.
     * The default type is image/png; that type is also used if the given type isn't supported.
     * @default "image/png"
     */
    outputImageType?: string;
    /**
     * Override or extend the styles applied to the component wrapper.
     */
    className?: string;
    /**
     * Callback fired when the file upload event was called.
     * @param fileBlob file as text or binary data.
     */
    onUpload?: (fileBlob: Blob) => void;
  };

/**
 * `ImageUpload` interactive component that allows to select and upload image by selecting or dragging.
 * TODO: To use this component with better UI/UX we need to add upload file process and uploaded file list components.
 */
export const ImageUpload: React.FC<TImageUploadProps> = (props) => {
  const {
    // cropper props
    zoomSpeed,
    aspect,
    minZoom,
    maxZoom,
    defaultZoom,
    defaultCrop,
    showGrid,
    cropShape,
    loadingText,
    // input props
    name,
    id,
    inputLabel,
    disabled,
    accept,
    // own props
    withImageCropper = false,
    withUploadButton = false,
    outputImageType = "image/png",
    selectButtonText = FileUploadContent.Root.Button.SELECT,
    uploadButtonText = FileUploadContent.Root.Button.UPLOAD,
    onUpload,
    className,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    imageBase64,
    croppedArea,
    setImage,
    setCroppedArea,
    setCroppedImage,
    resetAllImageUploadStates,
  } = useImageUpload();

  const uploadFileClickHandler = () => {
    if (!imageBase64) {
      logError({ message: "File was not selected" });
      return;
    }

    // If cropper was used we need to make cropped image from file type
    if (withImageCropper) {
      generateCroppedImage(imageBase64, {
        crop: croppedArea,
        outputImageType,
      })
        .then((image) => {
          setCroppedImage(image);
          onUpload?.(image);
        })
        .catch((err) => logError({ message: err?.message }));
      return;
    }

    convertBase64ToBlob(imageBase64)
      .then(onUpload)
      .catch((err) => logError({ message: err?.message }));
  };

  const selectFileClickHandler = () => {
    inputRef.current?.click();
  };

  const deleteFileClickHandler = () => {
    resetAllImageUploadStates();
  };

  const isCropperVisible: boolean = useMemo(
    () => (withImageCropper && imageBase64 !== null) || false,
    [withImageCropper, imageBase64]
  );

  return (
    <Space
      direction="vertical"
      size="medium"
      justify="stretch"
      align="center"
      fullWidth
      className={cx(["nb-interactive-image-upload-wrapper", className])}
    >
      {!imageBase64 && (
        <UploadInput
          ref={inputRef}
          name={name}
          id={id}
          inputLabel={inputLabel}
          disabled={disabled}
          accept={accept}
          fullWidth
          onFileChange={setImage}
        />
      )}
      {isCropperVisible && imageBase64 && (
        <div className="nb-interactive-image-cropper-container">
          <IconButton
            icon={<DeleteIcon />}
            variant="tertiary"
            onClick={deleteFileClickHandler}
            className="nb-interactive-image-upload-delete"
          />
          <ImageCropper
            image={imageBase64}
            minZoom={minZoom}
            maxZoom={maxZoom}
            zoomSpeed={zoomSpeed}
            aspect={aspect}
            defaultZoom={defaultZoom}
            defaultCrop={defaultCrop}
            showGrid={showGrid}
            cropShape={cropShape}
            loadingText={loadingText}
            onCropComplete={(_, area) => setCroppedArea(area)}
          />
        </div>
      )}
      {!isCropperVisible && imageBase64 && (
        <div className="nb-interactive-image-upload-preview">
          <IconButton
            icon={<DeleteIcon />}
            variant="tertiary"
            onClick={deleteFileClickHandler}
            className="nb-interactive-image-upload-delete"
          />
          <img src={imageBase64} alt="Your selected image preview" />
        </div>
      )}
      {withUploadButton && !imageBase64 && (
        <Button variant="primary" fullWidth onClick={selectFileClickHandler}>
          {selectButtonText}
        </Button>
      )}
      {imageBase64 && (
        <Button variant="primary" fullWidth onClick={uploadFileClickHandler}>
          {uploadButtonText}
        </Button>
      )}
    </Space>
  );
};
