import { DeepNullable } from "types/utils";
import {
  TExercise,
  TExerciseFileEncoded,
  TExerciseFileUrl,
} from "types/api/exercise";

export enum EEssayExerciseType {
  ESSAY = "ESSAY",
  TEXT = "TEXT",
}

export type TEssayExercisePayload = DeepNullable<TExerciseFileEncoded> &
  DeepNullable<TExerciseFileUrl> & {
    /**
     * Prompt image description for a student.
     */
    description?: string | null;
    /**
     * Exercise essay type.
     */
    essayType: EEssayExerciseType;
  };

export type TEssayExercise = TExercise<
  TEssayExercisePayload & DeepNullable<TExerciseFileUrl>
>;
