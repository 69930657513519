import { SingleValueProps, components } from "react-select";
import { TClueSelectDefaultOption } from "../../ClueSelect.types";
import "./SingleValue.styles.scss";

export const SingleValue = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>({
  children,
  ...props
}: SingleValueProps<Option, false>): JSX.Element => (
  <components.SingleValue
    {...props}
    className="nb-interactive-clue-select-value"
  >
    {children}
  </components.SingleValue>
);
