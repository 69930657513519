import cx from "classnames";
import "./Loader.styles.scss";

type LoaderProps = {
  /**
   * If `true`, the `Loader` will take height of full screen.
   *
   * @default false
   */
  isFullScreen?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

/**
 * Loader common component to display animation while loading data.
 */
export const Loader: React.FC<LoaderProps> = ({
  isFullScreen = false,
  className,
}) => {
  return (
    <div
      className={cx([
        "nb-common-loader-container",
        { "nb-common-loader-container--full": isFullScreen },
        className,
      ])}
    >
      <div className="nb-common-loader-spinner">
        <div className="nb-common-loader-spinner-item"></div>
        <div className="nb-common-loader-spinner-item"></div>
        <div className="nb-common-loader-spinner-item"></div>
        <div className="nb-common-loader-spinner-item"></div>
        <div className="nb-common-loader-spinner-item"></div>
      </div>
    </div>
  );
};
