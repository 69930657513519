import { get, post, put, remove } from "api";
import { STUDENTS_ENDPOINTS } from "api/endpoints/students";
import {
  TStudent,
  TStudentAddRequestBody,
  TStudentResponse,
} from "types/api/student";
import { TUrlParam } from "types/utils";

export const fetchStudents = (
  searchText = "",
  pageNumber = 0,
  pageSize = 10,
  teacherId: TUrlParam = null
): Promise<TStudentResponse> =>
  get<TStudentResponse>(STUDENTS_ENDPOINTS.GET_ALL_STUDENTS, {
    params: {
      searchText,
      pageNumber,
      pageSize,
      teacherId,
    },
  });

export const fetchStudentById = (id: TUrlParam): Promise<TStudent> =>
  get<TStudent>(`${STUDENTS_ENDPOINTS.GET_ALL_STUDENTS}/${id}`);

export const addStudent = (
  student: TStudentAddRequestBody
): Promise<TStudent> =>
  post<TStudent>(STUDENTS_ENDPOINTS.GET_ALL_STUDENTS, student);

export const editStudent = (student: TStudent): Promise<TStudent> =>
  put<TStudent>(STUDENTS_ENDPOINTS.GET_ALL_STUDENTS, student);

export const removeStudent = (id: TUrlParam): Promise<void> =>
  remove<undefined>(`${STUDENTS_ENDPOINTS.GET_ALL_STUDENTS}/${id}`);
