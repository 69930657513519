export const LessonDuration = {
  THIRTY: 30,
  FORTY_FIVE: 45,
  SIXTY: 60,
  NINETY: 90,
};

export const LessonCreateTimeRange = {
  MIN: 8,
  MAX: 22, // Calendar will subtract 1 hour, so 22 will be equal to 21
};
