import { useEffect } from "react";
import cx from "classnames";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { useKeyPress } from "hooks/common/useKeyPress";
import { ReactPortal } from "components/Common/ReactPortal";
import { ConstructorMain } from "../ConstructorMain";
import { ConstructorSecondary } from "../ConstructorSecondary";
import "./ExerciseConstructorRoot.styles.scss";

type ExerciseConstructorRootProps = {
  /**
   * Override react portal container id.
   */
  constructorId?: string;
  /**
   * Override or extend the styles applied to the component content wrapper.
   */
  className?: string;
};

/**
 * Exercise constructor which work in association with `useExerciseConstructor` hook that store states in redux manager.
 */
export const ExerciseConstructorRoot: React.FC<ExerciseConstructorRootProps> = (
  props
) => {
  const { constructorId = "exercise-constructor", className } = props;

  const { isMainDrawerOpen, closeAll, setCourseId, setLessonId, setChapterId } =
    useExerciseConstructor();

  useKeyPress("Escape", closeAll);

  useEffect(() => {
    return () => {
      setCourseId(null);
      setLessonId(null);
      setChapterId(null);
    };
  }, [setChapterId, setCourseId, setLessonId]);

  return (
    <ReactPortal wrapperId={constructorId}>
      <div
        aria-hidden={!isMainDrawerOpen}
        tabIndex={-1}
        className={cx([
          "nb-exercise-constructor-modal",
          { "nb-exercise-constructor-modal--open": isMainDrawerOpen },
        ])}
        onClick={closeAll}
      >
        <div
          role="dialog"
          aria-modal
          className={cx([
            "nb-exercise-constructor-modal-content",
            { "nb-exercise-constructor-modal-content--open": isMainDrawerOpen },
            className,
          ])}
          onClick={(evt) => evt.stopPropagation()}
        >
          <ConstructorMain />
          <ConstructorSecondary />
        </div>
      </div>
    </ReactPortal>
  );
};
