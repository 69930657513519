import { MenuProps, components } from "react-select";
import { TPersonSelectOption } from "types/app/select";
import "./Menu.styles.scss";

export const Menu = <Option extends TPersonSelectOption = TPersonSelectOption>(
  props: MenuProps<Option>
): JSX.Element => {
  return (
    <components.Menu {...props} className="nb-interactive-person-select-menu">
      {props.children}
    </components.Menu>
  );
};
