import { MenuListProps, components } from "react-select";
import { TClueSelectDefaultOption } from "../../ClueSelect.types";
import "./MenuList.styles.scss";

export const MenuList = <
  Option extends TClueSelectDefaultOption = TClueSelectDefaultOption
>(
  props: MenuListProps<Option, false>
): JSX.Element => {
  return (
    <components.MenuList
      {...props}
      className="nb-interactive-clue-select-menu-list"
    >
      {props.children}
    </components.MenuList>
  );
};
