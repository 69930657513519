import { LessonDetailsContent } from "content";
import { Heading } from "components/Typography/Heading";
import { Loader } from "components/Common/Loader";
import { Divider } from "components/Common/Divider";
import { LessonContentChapter } from "../LessonContentChapter";
import type { TCourseLesson, TCourseLessonChapter } from "types/api/course";
import "./LessonContent.styles.scss";

type LessonContentProps = {
  /**
   * Indicates active chapter item ID, if any.
   * @default null
   */
  activeChapterId?: null | number;
  /**
   * If `true` component will display loader with animation.
   * @default false
   */
  loading?: boolean;
  /**
   * Lesson data to display.
   */
  lesson?: TCourseLesson;
  /**
   * Lesson chapters data to display.
   */
  lessonChapters?: TCourseLessonChapter[];
};

export const LessonContent: React.FC<LessonContentProps> = (props) => {
  const {
    activeChapterId = null,
    loading = false,
    lesson,
    lessonChapters = [],
  } = props;

  return (
    <div className="nb-lesson-details-content-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Heading>
            {lesson?.name || LessonDetailsContent.Content.Lesson.TITLE}
          </Heading>
          <Divider variant="dashed" margin="yAxis" marginSize="large" />
          {lessonChapters
            .filter((chapter) => chapter.id === activeChapterId)
            .map((chapter, index) => (
              <LessonContentChapter
                key={chapter.id}
                chapter={chapter}
                order={index + 1}
                active
              />
            ))}
        </>
      )}
    </div>
  );
};
