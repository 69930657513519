import { MenuProps, components } from "react-select";
import { TAssertionSelectDefaultOption } from "../../../AssertionSentences.types";
import "./Menu.styles.scss";

export const Menu = <
  Option extends TAssertionSelectDefaultOption = TAssertionSelectDefaultOption
>(
  props: MenuProps<Option, false>
): JSX.Element => {
  return (
    <components.Menu
      {...props}
      className="nb-interactive-true-or-false-assertion-select-menu"
    >
      {props.children}
    </components.Menu>
  );
};
