import { addToDictionaryExercise } from "./add-to-dictionary";
import { buttonExercise } from "./button";
import { testExercise } from "./test";
import { chooseRightWordFormExercise } from "./choose-right-word-form";
import { compareWordsExercise } from "./compare-words";
import { composeWordsFromLettersExercise } from "./compose-words-from-letters";
import { compareImageWithDescriptionExercise } from "./compare-image-with-description";
import { describeAudioExercise } from "./describe-audio";
import { describeGifExercise } from "./describe-gif";
import { describeImageExercise } from "./describe-image";
import { describeVideoExercise } from "./describe-video";
import { moveWordsIntoGapsExercise } from "./move-words-into-gaps";
import { notesExercise } from "./notes";
import { recordAudioExercise } from "./record-audio";
import { sentenceOrderExercise } from "./sentence-order";
import { sortWordsExercise } from "./sort-words";
import { trueOrFalseExercise } from "./true-or-false";
import { typeWordsIntoGapsExercise } from "./type-words-into-gaps";
import { writeCompositionExercise } from "./write-composition";
import { TExerciseItem } from "types/app/exercises";

export const constructorExercises: TExerciseItem[] = [
  describeImageExercise,
  describeGifExercise,
  describeVideoExercise,
  moveWordsIntoGapsExercise,
  testExercise,
  writeCompositionExercise,
  // describeAudioExercise,
  typeWordsIntoGapsExercise,
  compareImageWithDescriptionExercise,
  chooseRightWordFormExercise,
  trueOrFalseExercise,
  compareWordsExercise,
  composeWordsFromLettersExercise,
  sortWordsExercise,
  // sentenceOrderExercise,
  buttonExercise,
  notesExercise,
  // addToDictionaryExercise,
  // recordAudioExercise,
];

export {
  // addToDictionaryExercise,
  buttonExercise,
  testExercise,
  chooseRightWordFormExercise,
  compareWordsExercise,
  composeWordsFromLettersExercise,
  compareImageWithDescriptionExercise,
  // describeAudioExercise,
  describeGifExercise,
  describeImageExercise,
  describeVideoExercise,
  moveWordsIntoGapsExercise,
  notesExercise,
  // recordAudioExercise,
  // sentenceOrderExercise,
  sortWordsExercise,
  trueOrFalseExercise,
  typeWordsIntoGapsExercise,
  writeCompositionExercise,
};
