import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { TestCheckmark } from "components/Interactive/TestCheckmark";
import { ExerciseCondition } from "components/Typography/ExerciseCondition";
import { TTestExerciseQuestionItem } from "types/app/exercises";
import "./TestQuestionItem.styles.scss";

type TTestQuestionItemProps = {
  /**
   * The test exercise question data.
   */
  question: TTestExerciseQuestionItem;
  /**
   * The question sequence order.
   * @default 1
   */
  order?: number;
};

export const TestQuestionItem: React.FC<TTestQuestionItemProps> = (props) => {
  const { question, order = 1 } = props;

  const { isExerciseReadOnlyMode, isExercisePreviewMode } = useLessonSettings();

  return (
    <Space direction="vertical" justify="start" size="small" fullWidth>
      <ExerciseCondition prefix={`${order}.`} text={question.question} />
      {question.answers.map(({ content, id, isCorrect }) => (
        <TestCheckmark
          key={id}
          label={content}
          name={id.toString()}
          id={id.toString()}
          disabled={isExerciseReadOnlyMode}
          value={isExercisePreviewMode ? isCorrect : false}
        />
      ))}
    </Space>
  );
};
