import cx from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { EssayExerciseContent } from "content";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { Image } from "components/Common/Image";
import { Textarea } from "components/Interactive/Textarea";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { Paragraph } from "components/Typography/Paragraph";
import {
  EEssayExerciseType,
  TEssayExercise,
  TEssayExercisePayload,
} from "types/app/exercises";
import { TEssayFormValues } from "./Essay.types";
import "./Essay.styles.scss";

type TEssayProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TEssayExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const Essay: React.FC<TEssayProps> = (props) => {
  const { chapterId, exercise, prefix = null } = props;

  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { isExerciseReadOnlyMode } = useLessonSettings();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TEssayFormValues>({
    mode: "onBlur",
    defaultValues: { essay: "" },
  });

  const saveEssayClickHandler = handleSubmit(() => {
    if (isLocked) {
      setIsLocked(false);
      return;
    }
    // Handle input data here
    setIsLocked(true);
  });

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TEssayExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
        isCollapsed={isCollapsed}
        onCollapseToggle={() => setIsCollapsed((prev) => !prev)}
      />
      <div
        className={cx([
          "nb-exercise-container",
          {
            "nb-exercise-container--collapsed": isCollapsed,
          },
        ])}
      >
        {exercise.payload.data.fileUrl && (
          <Image
            src={exercise.payload.data.fileUrl}
            alt="Essay related image"
            className="nb-exercise-essay-image"
            fullWidth
            rounded
          />
        )}
        <Paragraph variant={2} lineBreak>
          {exercise.payload.data.description}
        </Paragraph>
        {exercise.payload.data.essayType === EEssayExerciseType.ESSAY && (
          <Textarea<TEssayFormValues>
            name="essay"
            id="essay-exercise-essay"
            className="nb-exercise-essay-text"
            placeholder={EssayExerciseContent.Main.Input.Essay.PLACEHOLDER}
            register={register}
            errors={errors}
            disabled={isLocked || isExerciseReadOnlyMode}
            buttonText={
              isLocked
                ? EssayExerciseContent.Main.Input.Essay.CHANGE
                : EssayExerciseContent.Main.Input.Essay.SAVE
            }
            onButtonClick={saveEssayClickHandler}
            withButton
            buttonProps={{ disabled: isExerciseReadOnlyMode }}
            required
            fullWidth
          />
        )}
      </div>
    </Space>
  );
};
