import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const trueOrFalseExercise: TExerciseItem = {
  title: "Вибрати брехня чи істина",
  backgroundText: "Брехня чи істина",
  tags: [
    "Вибрати брехня чи істина",
    "вибрати",
    "обрати",
    "брехня",
    "неправда",
    "правда",
    "істина",
    "чи",
    "choose",
    "true",
    "or",
    "false",
  ],
  type: EExerciseType.TRUE_OR_FALSE,
  cover: "true-or-false.png",
};
