import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import {
  ExerciseConstructorContent,
  MoveWordsIntoGapsExerciseContent,
} from "content";
import { Textarea } from "components/Interactive/Textarea";
import { Space } from "components/Common/Space";
import { Text } from "components/Typography/Text";
import { Switch } from "components/Interactive/Switch";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { adaptExerciseStringToData } from "./MoveWordsIntoGapsExerciseForm.helpers";
import {
  EExerciseType,
  TMoveWordsIntoGapsExercisePayload,
} from "types/app/exercises";
import { TAddExercise, TEditExercise } from "types/api/exercise";
import { TMoveWordsIntoGapsExerciseForm } from "./MoveWordsIntoGapsExerciseForm.types";
import "./MoveWordsIntoGapsExerciseForm.styles.scss";

export const MoveWordsIntoGapsExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor<TMoveWordsIntoGapsExercisePayload>();

  const [isWrapOnSentence, setIsWrapOnSentence] = useState<boolean>(
    editExercise?.payload.data.isWrapOnSentence || false
  );

  const { mutate: addExercise } =
    useAddExercise<TMoveWordsIntoGapsExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: addHomeworkExercise } =
    useAddHomeworkExercise<TMoveWordsIntoGapsExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: editExerciseHandler } =
    useEditExercise<TMoveWordsIntoGapsExercisePayload>(
      courseId,
      lessonId,
      chapterId
    );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TMoveWordsIntoGapsExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );

  const defaultValues: Partial<TMoveWordsIntoGapsExerciseForm> = useMemo(
    () => ({
      text: editExercise?.payload.text || "",
      exerciseText: editExercise?.payload.data.exerciseText || "",
    }),
    [editExercise]
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<TMoveWordsIntoGapsExerciseForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const successCallback = () => {
    logSuccess(
      isEditMode
        ? ExerciseConstructorContent.Notification.Edit.SUCCESS
        : ExerciseConstructorContent.Notification.Add.SUCCESS
    );
    closeAll();
    reset(defaultValues);
  };

  const submitLessonExercise = (
    body: TAddExercise<TMoveWordsIntoGapsExercisePayload>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TMoveWordsIntoGapsExercisePayload>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TMoveWordsIntoGapsExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TMoveWordsIntoGapsExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const exerciseSubmitHandler = (formData: TMoveWordsIntoGapsExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const exerciseData = adaptExerciseStringToData(
      formData.exerciseText,
      isWrapOnSentence
    );

    const addBody: TAddExercise<TMoveWordsIntoGapsExercisePayload> = {
      type: EExerciseType.MOVE_WORDS_INTO_GAPS,
      payload: {
        text: formData.text,
        data: {
          exerciseText: formData.exerciseText,
          ...exerciseData,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: TEditExercise<TMoveWordsIntoGapsExercisePayload> = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  const wrapChangeHandler = (checked: boolean) => {
    setIsWrapOnSentence(checked);
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(exerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <div className="nb-exercise-constructor-move-words-into-gaps">
        <Textarea<TMoveWordsIntoGapsExerciseForm>
          name="text"
          id="move-words-into-gaps-exercise-condition"
          className="nb-exercise-constructor-move-words-into-gaps-condition"
          label={MoveWordsIntoGapsExerciseContent.Add.Input.Condition.LABEL}
          placeholder={
            MoveWordsIntoGapsExerciseContent.Add.Input.Condition.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
        />
        <Textarea<TMoveWordsIntoGapsExerciseForm>
          name="exerciseText"
          id="move-words-into-gaps-exercise-text"
          className="nb-exercise-constructor-move-words-into-gaps-text"
          label={MoveWordsIntoGapsExerciseContent.Add.Input.Text.LABEL}
          placeholder={
            MoveWordsIntoGapsExerciseContent.Add.Input.Text.PLACEHOLDER
          }
          register={register}
          errors={errors}
          required
          fullWidth
          hint={MoveWordsIntoGapsExerciseContent.Add.Input.Text.HINT}
        />
        <Space
          direction="horizontal"
          size={"medium"}
          justify="between"
          align="center"
          fullWidth
        >
          <Text variant="body2">
            {MoveWordsIntoGapsExerciseContent.Add.Input.Wrap.LABEL}
          </Text>
          <Switch value={isWrapOnSentence} onChange={wrapChangeHandler} />
        </Space>
      </div>
    </ExerciseConfigForm>
  );
};
