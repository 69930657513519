import { TExercise } from "types/api/exercise";

export enum ENoteExerciseType {
  TEACHER_NOTE = "TEACHER_NOTE",
  INFO = "INFO",
}

export type TNoteExercisePayload = {
  /**
   * Note content.
   */
  content: string;
  /**
   * Is note visible for student.
   */
  isStudentVisible: boolean;
  /**
   * Exercise note type.
   */
  noteType: ENoteExerciseType;
};

export type TNoteExercise = TExercise<TNoteExercisePayload>;
