import { AvatarUploadContent, UserSettingsContent } from "content";
import { useModal } from "hooks/common";
import { useImageUpload } from "hooks/redux";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { useChangeProfilePhoto } from "hooks/api/profile";
import { ImageUploadModal } from "components/Interactive/ImageUploadModal";
import { Space } from "components/Common/Space";
import { Avatar } from "components/Common/Avatar";
import { convertBlobToBase64String } from "utils/common";
import { logSuccess } from "utils/notifications";
import { TFileChangePhoto } from "types/api/file";
import "./ProfileAvatar.styles.scss";

export const ProfileAvatar: React.FC = () => {
  const { activeUser } = useActiveUser();

  const { mutate: changeProfilePhoto } = useChangeProfilePhoto(activeUser?.id);

  const { resetCroppedArea, resetUploadedImage } = useImageUpload();

  const { isModalOpen, closeModal, openModal } = useModal(false);

  const closeUploadFileModalHandler = () => {
    closeModal();
    resetCroppedArea();
    resetUploadedImage();
  };

  const imageHandler = async (fileBlob: Blob) => {
    const base64 = await convertBlobToBase64String(fileBlob);
    const [, cleanBase64] = base64.split(",");
    const body: TFileChangePhoto = {
      fileEncoded: cleanBase64,
    };
    changeProfilePhoto(body, {
      onSuccess: () => {
        logSuccess(UserSettingsContent.Form.Notification.SUCCESS);
        closeUploadFileModalHandler();
      },
    });
  };

  return (
    <>
      <Space direction="vertical" size="small" justify="start" align="center">
        <Avatar
          image={activeUser?.photoUrl}
          size="x-large"
          emoji="🤓‍"
          bordered
        />
        <button
          type="button"
          className="nb-page-user-settings-change-photo-button"
          onClick={openModal}
        >
          {UserSettingsContent.Form.Button.CHANGE_PHOTO}
        </button>
      </Space>
      <ImageUploadModal
        open={isModalOpen}
        outputImageType="image/webp"
        aspect={1}
        zoomSpeed={0.5}
        cropShape="round"
        id="user-profile-photo-upload-input"
        name="user-profile-photo-upload-input"
        portalId="user-profile-photo-upload-modal"
        title={AvatarUploadContent.Modal.TITLE}
        emoji={AvatarUploadContent.Modal.EMOJI}
        selectButtonText={AvatarUploadContent.Root.Button.SELECT}
        uploadButtonText={AvatarUploadContent.Root.Button.UPLOAD}
        inputLabel={AvatarUploadContent.Input.LABEL}
        withImageCropper
        withUploadButton
        onClose={closeUploadFileModalHandler}
        onUpload={imageHandler}
        className="nb-page-user-settings-upload-modal"
      />
    </>
  );
};
