export const UserSettings = {
  Name: "Особистий профіль",
  Emoji: "📒",
  Form: {
    Input: {
      FirstName: {
        LABEL: "Ваше ім'я",
        PLACEHOLDER: "Тарас",
      },
      LastName: {
        LABEL: "Ваше прізвище",
        PLACEHOLDER: "Шевченко",
      },
      Email: {
        LABEL: "Ваша електронна пошта",
        PLACEHOLDER: "taras.shevchenko@mail.com",
      },
      Phone: {
        LABEL: "Номер телефону",
        PLACEHOLDER: "+380 (99) 000 00 00",
      },
      Language: {
        LABEL: "Мова викладання",
        PLACEHOLDER: "Оберіть мову",
      },
    },
    Button: {
      CHANGE_PASSWORD: "Змінити пароль",
      CHANGE_PHOTO: "Змінити фото",
    },
    Notification: {
      SUCCESS: "Профіль успішно оновлено",
      ERROR: "Не вдалось оновити профіль",
    },
  },
  ChangePassword: {
    TITLE: "Змінити пароль",
    EMOJI: "👆🏻",
    Input: {
      OldPassword: {
        LABEL: "Введіть старий пароль",
        PLACEHOLDER: "Старий пароль",
      },
      NewPassword: {
        LABEL: "Введіть новий пароль",
        PLACEHOLDER: "Новий пароль",
      },
      ConfirmNewPassword: {
        LABEL: "Повторити пароль",
        PLACEHOLDER: "Новий пароль",
      },
    },
    Error: {
      NOT_MATCH: "Паролі не співпадають",
    },
    Button: {
      SUBMIT: "Зберегти зміни",
    },
    Notification: {
      SUCCESS: "Пароль успішно змінено",
      ERROR: "Не вдалось змінити пароль",
    },
  },
};
