import { Navigate, Route, Routes } from "react-router-dom";
import { useCurrentUser } from "hooks/api/auth";
import { useBodyCustomScrollbar } from "hooks/common";
import { Route as PlatformRoute } from "constants/routes";
import { AuthLayout } from "layouts/AuthLayout";
import { AppLayout } from "layouts/AppLayout";
import { Schedule } from "pages/Schedule";
import { Login } from "pages/Login";
import { Materials } from "pages/Materials";
import { CourseDetails } from "pages/Materials/pages/course-details";
import { LessonDetails } from "pages/Materials/pages/course-details/pages/lesson-details";
import { Finance } from "pages/Finance";
import { Study } from "pages/Study";
import { Teachers } from "pages/Study/pages/teachers";
import { Students } from "pages/Study/pages/students";
import { StudentClassroom } from "pages/Study/pages/students/pages/classroom";
import { StudentClassroomCourse } from "pages/Study/pages/students/pages/classroom/pages/course";
import { StudentClassroomCourseLesson } from "./pages/Study/pages/students/pages/classroom/pages/course/pages/lesson";
import { Groups } from "pages/Study/pages/groups";
import { GroupDetails } from "pages/Study/pages/groups/pages/group-details";
import { Requests } from "pages/Study/pages/requests";
import { Reports } from "pages/Study/pages/reports";
import { UserSettings } from "pages/User/Settings";
import { AdminPage } from "pages/Admin";
import { LandingDashboard } from "pages/Admin/pages/landing";
import { Loader } from "components/Common/Loader";
import { AdminProtected } from "components/Layout/AdminProtected";

export const App: React.FC = () => {
  const { isLoading } = useCurrentUser();

  useBodyCustomScrollbar();

  if (isLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={PlatformRoute.Auth.Login.ROOT.path} element={<Login />} />
      </Route>
      <Route element={<AppLayout />}>
        <Route
          path="*"
          element={
            <Navigate to={PlatformRoute.App.Schedule.ROOT.path} replace />
          }
        />

        <Route
          path={PlatformRoute.App.Schedule.ROOT.path}
          element={<Schedule />}
        />
        <Route path={PlatformRoute.App.Study.ROOT.path} element={<Study />} />

        <Route
          path={PlatformRoute.App.Study.STUDENTS.path}
          element={<Students />}
        />
        <Route
          path={PlatformRoute.App.Study.STUDENT_CLASSROOM.path}
          element={<StudentClassroom />}
        />
        <Route
          path={PlatformRoute.App.Study.STUDENT_CLASSROOM_COURSE.path}
          element={<StudentClassroomCourse />}
        />
        <Route
          path={PlatformRoute.App.Study.STUDENT_CLASSROOM_COURSE_LESSON.path}
          element={<StudentClassroomCourseLesson />}
        />
        <Route
          path={PlatformRoute.App.Study.TEACHERS.path}
          element={<Teachers />}
        />
        <Route
          path={PlatformRoute.App.Study.GROUPS.path}
          element={<Groups />}
        />
        <Route
          path={PlatformRoute.App.Study.GROUP_DETAILS.path}
          element={<GroupDetails />}
        />
        <Route element={<AdminProtected />}>
          <Route
            path={PlatformRoute.App.Study.REQUESTS.path}
            element={<Requests />}
          />
        </Route>
        <Route
          path={PlatformRoute.App.Study.REPORTS.path}
          element={<Reports />}
        />

        <Route
          path={PlatformRoute.App.Materials.ROOT.path}
          element={<Materials />}
        />
        <Route
          path={PlatformRoute.App.Materials.COURSE_DETAILS.path}
          element={<CourseDetails />}
        />
        <Route
          path={PlatformRoute.App.Finance.ROOT.path}
          element={<Finance />}
        />
        <Route
          path={PlatformRoute.App.User.SETTINGS.path}
          element={<UserSettings />}
        />
        <Route element={<AdminProtected />}>
          <Route
            path={PlatformRoute.App.Admin.ROOT.path}
            element={<AdminPage />}
          />
          <Route
            path={PlatformRoute.App.Admin.LANDING.path}
            element={<LandingDashboard />}
          />
        </Route>
      </Route>
      <Route element={<AppLayout hideHeader />}>
        <Route
          path={PlatformRoute.App.Materials.LESSON_DETAILS.path}
          element={<LessonDetails />}
        />
      </Route>
    </Routes>
  );
};
