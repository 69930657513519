import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { GroupsContent } from "content";
import { Avatar } from "components/Common/Avatar";
import { AvatarList } from "components/Common/AvatarList";
import { Button } from "components/Interactive/Button";
import { IconButton } from "components/Interactive/IconButton";
import { Tooltip } from "components/Interactive/Tooltip";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { getRowSequenceNumber } from "utils/table";
import { TGroup } from "types/api/groups";

type TUseGroupColumns = (options?: {
  onDeleteClick?: (groupId: number) => void;
}) => ColumnDef<TGroup, string>[];

export const useGroupColumns: TUseGroupColumns = (options) => {
  const { onDeleteClick } = options || {};

  const columnHelper = createColumnHelper<TGroup>();

  const columns: ColumnDef<TGroup, string>[] = [
    columnHelper.accessor(() => "index", {
      id: "index",
      header: GroupsContent.Table.Header.NUMBER,
      cell: (info) =>
        getRowSequenceNumber(info.row.index, info.table.getState().pagination),
      size: 0.05,
    }),
    columnHelper.accessor(() => "avatar", {
      id: "avatar",
      header: GroupsContent.Table.Header.AVATAR,
      cell: () => <Avatar bordered size="small" />,
      size: 0.1,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: GroupsContent.Table.Header.NAME,
      cell: (info) => (
        <Tooltip
          mouseEnterDelay={1000}
          text={info.getValue()}
          spaceSize="small"
          variant="compact"
        >
          {info.getValue()}
        </Tooltip>
      ),
      size: 0.25,
    }),
    columnHelper.accessor(() => "members", {
      id: "members",
      header: GroupsContent.Table.Header.PARTICIPANTS,
      cell: (info) => (
        <AvatarList
          size="small"
          userData={info.row.original.memberIds.length}
        />
      ),
      size: 0.25,
    }),
    columnHelper.accessor(() => "action", {
      id: "action",
      header: GroupsContent.Table.Header.ACTION,
      cell: ({ row }) => (
        <div className="nb-page-study-groups-action">
          <Button
            variant="link"
            to={`/study/groups/${row.original.id}`}
            className="nb-page-study-groups-action-link"
          >
            {GroupsContent.Table.Button.CLASSROOM}
          </Button>
          <IconButton
            icon={<Delete />}
            onClick={() => onDeleteClick?.(row.original.id)}
          />
        </div>
      ),
      size: 204,
    }),
  ];

  return columns;
};
