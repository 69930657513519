import { MenuListProps, components } from "react-select";
import cx from "classnames";
import { TPersonSelectOption } from "types/app/select";
import "./MenuList.styles.scss";

type CustomMenuListProps<
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
> = {
  size: 1 | 2 | 3 | 4 | 5;
} & MenuListProps<Option, IsMulti>;

export const MenuList = <
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
>({
  size,
  ...props
}: CustomMenuListProps<Option, IsMulti>): JSX.Element => {
  return (
    <components.MenuList
      {...props}
      className={cx([
        "nb-interactive-person-select-menu-list",
        `nb-interactive-person-select-menu-list--${size}`,
      ])}
    >
      {props.children}
    </components.MenuList>
  );
};
