import "./AppContainer.styles.scss";

type AppContainerProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  return <div className="nb-layout-app-container">{children}</div>;
};
