import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const sortWordsExercise: TExerciseItem = {
  title: "Відсортувати слова",
  backgroundText: "Сортування",
  tags: [
    "Відсортувати слова",
    "відсортувати",
    "сортування",
    "слова",
    "слів",
    "по",
    "колонках",
    "sort",
    "words",
    "into",
    "columns",
  ],
  type: EExerciseType.SORT_WORDS,
  cover: "sort-words.png",
};
