import { PaginationState } from "@tanstack/react-table";

/**
 * Get sequence row number based on row index and table pagination state.
 *
 * @param {Number} rowIndex - current table row index.
 * @param {PaginationState} pagination - state of `react-table` pagination.
 * @returns {Number} result - row sequence number.
 */
export const getRowSequenceNumber = (
  rowIndex: number,
  pagination: PaginationState
) => {
  return rowIndex + 1 + pagination.pageIndex * pagination.pageSize;
};
