import { Avatar } from "components/Common/Avatar";
import { Paragraph } from "components/Typography/Paragraph";
import "./PersonInfo.styles.scss";

type TPersonInfoProps = {
  /**
   * First name of the person.
   */
  firstName?: string;
  /**
   * Last name of the person.
   */
  lastName?: string;
  /**
   * Email of the person.
   */
  email?: string;
  /**
   * Loading state of the component.
   * If `true`, the component will be in the loading state.
   * @default false
   */
  loading?: boolean;
};

export const PersonInfo: React.FC<TPersonInfoProps> = (props) => {
  const { firstName, lastName, email, loading = false } = props;

  return loading ? (
    <div className="nb-sub-students-person-info-skeleton" />
  ) : (
    <div className="nb-sub-students-person-info-container">
      <div className="nb-sub-students-person-info-avatar">
        <Avatar size="small" color="grey" />
      </div>
      <div className="nb-sub-students-person-info-content">
        <Paragraph
          variant={2}
          bold
          className="nb-sub-students-person-info-title"
        >
          {firstName}&nbsp;{lastName}
        </Paragraph>
        <Paragraph variant={2} className="nb-sub-students-person-info-text">
          {email}
        </Paragraph>
      </div>
    </div>
  );
};
