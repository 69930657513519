import { ActionMeta, MultiValue } from "react-select";
import { Text } from "components/Typography/Text";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { TPersonSelectOption } from "types/app/select";
import "./CustomMultiValueContainer.styles.scss";

type CustomMultiValueContainerProps<
  Option extends TPersonSelectOption = TPersonSelectOption
> = {
  /**
   * Handle change events on the select.
   */
  onChange: (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => void;
  /**
   * Array of currently selected options.
   */
  values: MultiValue<Option>;
};

export const CustomMultiValueContainer = <
  Option extends TPersonSelectOption = TPersonSelectOption
>(
  props: CustomMultiValueContainerProps<Option>
): JSX.Element => {
  const { values, onChange } = props;

  const removeOptionHandler = (option: Option) => {
    const newValue = values.filter((item) => item.id !== option.id);
    onChange(newValue, {
      action: "remove-value",
      removedValue: option,
    });
  };

  return (
    <div className="nb-interactive-person-select-multi-container">
      {values.map((option) => (
        <div
          key={option.id}
          className="nb-interactive-person-select-value-label"
        >
          <Text
            variant="body2"
            className="nb-interactive-person-select-value-label-text"
          >
            {option.name}
          </Text>
          <button
            aria-label="Прибрати"
            type="button"
            className="nb-interactive-person-select-remove-button"
            onClick={() => removeOptionHandler(option)}
          >
            <Close width={15} height={15} />
          </button>
        </div>
      ))}
    </div>
  );
};
