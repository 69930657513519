import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useLanguage } from "hooks/api/language";
import { useEditStudent } from "hooks/api/students";
import { useTeachersByLanguage } from "hooks/api/teachers";
import { Input } from "components/Interactive/Input";
import { Select } from "components/Interactive/Select";
import { Button } from "components/Interactive/Button";
import { Avatar } from "components/Common/Avatar";
import { ReactPortal } from "components/Common/ReactPortal";
import { StudentsContent, ValidationContent } from "content";
import { logSuccess } from "utils/notifications";
import { EMAIL_REGEX } from "constants/validation";
import { TStudent } from "types/api/student";
import { TTeacher } from "types/api/teacher";
import { ELanguageLevel } from "types/api/language";
import { TSelectOption } from "types/app/select";
import { TEditStudentForm } from "./EditStudentForm.types";
import "./EditStudentForm.styles.scss";

type EditStudentFormProps = {
  targetStudent: TStudent | null;
  className?: string;
  onSubmit?: () => void;
};

export const EditStudentForm: React.FC<EditStudentFormProps> = (props) => {
  const { className, targetStudent, onSubmit } = props;

  const idRef = useRef<string>(nanoid());

  const { data: languageData } = useLanguage();
  const { mutate } = useEditStudent();

  const languageOptions: TSelectOption[] =
    languageData?.map((lang) => ({
      label: lang,
      value: lang,
    })) || [];
  const levelOptions: TSelectOption<ELanguageLevel>[] = Object.entries(
    ELanguageLevel
  ).map(([key, level]) => ({
    label: key,
    value: level,
  }));

  const defaultValues = useMemo(
    () => ({
      firstName: targetStudent?.person.firstName,
      lastName: targetStudent?.person.lastName,
      email: targetStudent?.person.email,
      phone: targetStudent?.person.phone,
      ...(targetStudent?.person.language && {
        language: {
          label: targetStudent.person.language,
          value: targetStudent.person.language,
        },
      }),
      ...(targetStudent?.languageLevel && {
        languageLevel: {
          label: `${targetStudent?.languageLevel}`,
          value: targetStudent?.languageLevel,
        },
      }),
      price: targetStudent?.price.toString(),
    }),
    [targetStudent]
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TEditStudentForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const currentLanguage = watch("language");

  const { data: teachersData } = useTeachersByLanguage(currentLanguage?.value);
  const teachers: TTeacher[] = useMemo(
    () => teachersData?.content || [],
    [teachersData?.content]
  );
  const teacherOptions: TSelectOption<number>[] = teachers.map((teacher) => ({
    label: `${teacher.person.firstName} ${teacher.person.lastName}`,
    value: teacher.id,
  }));

  useEffect(() => {
    const currentTeacher = teachers.find(
      (teacher) => teacher.id === targetStudent?.teacherId
    );
    if (currentTeacher) {
      setValue("teacher", {
        label: `${currentTeacher.person.firstName} ${currentTeacher.person.lastName}`,
        value: currentTeacher.id,
      });
    }
  }, [teachers, targetStudent, setValue]);

  const handleEditStudentSubmit = (formData: TEditStudentForm) => {
    if (targetStudent === null) {
      return;
    }
    const student = {
      id: targetStudent.id,
      person: {
        ...targetStudent.person,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        language: formData.language.value,
      },
      languageLevel: formData.languageLevel.value,
      price: parseInt(formData.price),
      teacherId: formData.teacher.value,
    };

    mutate(student, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(StudentsContent.Edit.Notification.SUCCESS);
        reset();
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-edit-student-form", className])}
      onSubmit={handleSubmit(handleEditStudentSubmit)}
    >
      <div className="nb-sub-edit-student-avatar-wrapper">
        <Avatar bordered size="large" />
      </div>
      <Input<TEditStudentForm>
        name="firstName"
        id="edit-student-first-name"
        label={StudentsContent.Edit.Input.FirstName.LABEL}
        placeholder={StudentsContent.Edit.Input.FirstName.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Input<TEditStudentForm>
        name="lastName"
        id="edit-student-last-name"
        label={StudentsContent.Edit.Input.LastName.LABEL}
        placeholder={StudentsContent.Edit.Input.LastName.PLACEHOLDER}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <Input<TEditStudentForm>
        name="email"
        type="email"
        id="edit-student-email"
        label={StudentsContent.Edit.Input.Email.LABEL}
        placeholder={StudentsContent.Edit.Input.Email.PLACEHOLDER}
        register={register}
        errors={errors}
        rules={{
          pattern: {
            value: EMAIL_REGEX,
            message: ValidationContent.Pattern.Email,
          },
        }}
        required
        fullWidth
      />
      <Input<TEditStudentForm>
        name="phone"
        type="tel"
        id="edit-student-phone"
        label={StudentsContent.Edit.Input.Phone.LABEL}
        placeholder={StudentsContent.Edit.Input.Phone.PLACEHOLDER}
        minLength={12}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <div className="nb-sub-edit-student-selects-wrapper">
        <Select<TEditStudentForm, TSelectOption>
          name="language"
          id="edit-student-language"
          label={StudentsContent.Edit.Input.Language.LABEL}
          placeholder={StudentsContent.Edit.Input.Language.PLACEHOLDER}
          options={languageOptions}
          control={control}
          errors={errors}
          required
        />
        <Select<TEditStudentForm, TSelectOption<ELanguageLevel>>
          name="languageLevel"
          id="edit-student-level"
          label={StudentsContent.Edit.Input.Level.LABEL}
          placeholder={StudentsContent.Edit.Input.Level.PLACEHOLDER}
          options={levelOptions}
          control={control}
          errors={errors}
          required
        />
      </div>
      <Select<TEditStudentForm, TSelectOption<number>>
        name="teacher"
        id="add-student-teacherId"
        label={StudentsContent.Add.Input.Teacher.LABEL}
        placeholder={StudentsContent.Add.Input.Teacher.PLACEHOLDER}
        options={teacherOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Input<TEditStudentForm>
        name="price"
        type="number"
        id="edit-student-price"
        label={StudentsContent.Edit.Input.Price.LABEL}
        placeholder={StudentsContent.Edit.Input.Price.PLACEHOLDER}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <ReactPortal wrapperId="edit-student-modal-footer">
        <Button
          className="nb-sub-edit-student-submit"
          variant="primary"
          type="submit"
          form={idRef.current}
          fullWidth
        >
          {StudentsContent.Edit.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
