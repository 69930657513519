export const TestQuestions = {
  Default: {
    QUESTION_LABEL: "Питання",
    QUESTION_PLACEHOLDER: "Question",
    ANSWER_LABEL: "Відповіді",
    ANSWER_PLACEHOLDER: "Answer",
    ADD_QUESTION_TEXT: "Додати питання",
    ADD_ANSWER_TEXT: "Додати відповідь",
  },
};
