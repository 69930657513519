import { nanoid } from "nanoid";
import { TImageListUploadInitialValue } from "./ImageListUpload.types";

export const getImageListUploadInitialValue =
  (): TImageListUploadInitialValue => ({
    id: nanoid(),
    description: "",
    imageEncoded: null,
    imageUrl: null,
  });
