import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useLanguage } from "hooks/api/language";
import { useAddCourse } from "hooks/api/courses";
import { AvatarUpload } from "components/Interactive/AvatarUpload";
import { Input } from "components/Interactive/Input";
import { Textarea } from "components/Interactive/Textarea";
import { Select } from "components/Interactive/Select";
import { Button } from "components/Interactive/Button";
import { ReactPortal } from "components/Common/ReactPortal";
import { MaterialsContent } from "content";
import { logSuccess } from "utils/notifications";
import { convertBlobToBase64String } from "utils/common";
import { ELanguageLevel } from "types/api/language";
import { TAddCourseBody } from "types/api/course";
import { TSelectOption } from "types/app/select";
import { TAddCourseForm } from "./AddCourseForm.types";
import "./AddCourseForm.styles.scss";

type AddCourseFormProps = {
  /**
   * Callback function fires on add course submit event.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const AddCourseForm: React.FC<AddCourseFormProps> = (props) => {
  const { className, onSubmit } = props;

  const [selectedFile, setSelectedFile] = useState<Blob | null>(null);
  const idRef = useRef<string>(nanoid());

  const { mutate: addCourseHandler } = useAddCourse();
  const { data: languageData, isSuccess: isLanguageSuccess } = useLanguage();

  const languageOptions: TSelectOption<string>[] =
    languageData?.map((lang) => ({
      label: lang,
      value: lang,
    })) || [];
  const [initialLanguageOption] = languageOptions;
  const levelOptions: TSelectOption<ELanguageLevel>[] = Object.entries(
    ELanguageLevel
  ).map(([key, level]) => ({
    label: key,
    value: level,
  }));
  const [initialLevelOption] = levelOptions;

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<TAddCourseForm>({
    mode: "onBlur",
    defaultValues: {
      languageLevel: initialLevelOption,
    },
  });

  useEffect(() => {
    const isLanguageFieldTouched = !!touchedFields.language;
    if (isLanguageSuccess && !isLanguageFieldTouched) {
      setValue("language", initialLanguageOption);
    }
  }, [
    isLanguageSuccess,
    touchedFields.language,
    initialLanguageOption,
    setValue,
  ]);

  const handleAddCourseSubmit = async (formValues: TAddCourseForm) => {
    const imageEncoded =
      selectedFile &&
      (await convertBlobToBase64String(selectedFile, { removePrefix: true }));

    const course: TAddCourseBody = {
      name: formValues.name,
      language: formValues.language.value,
      languageLevel: formValues.languageLevel.value,
      description: formValues.description || null,
      imageEncoded,
    };

    addCourseHandler(course, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(MaterialsContent.Add.Notification.SUCCESS);
        setSelectedFile(null);
        reset({
          name: "",
          language: initialLanguageOption,
          languageLevel: initialLevelOption,
          description: "",
        });
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-add-course-form", className])}
      onSubmit={handleSubmit(handleAddCourseSubmit)}
    >
      <AvatarUpload
        bordered
        resetOnClose
        size="large"
        aspect={171 / 219}
        cropShape="rect"
        shape="rect"
        mode="save"
        className="nb-sub-add-course-avatar"
        onSave={setSelectedFile}
      />
      <Input<TAddCourseForm>
        name="name"
        id="add-course-name"
        className="nb-sub-add-course-name-input"
        label={MaterialsContent.Add.Input.Name.LABEL}
        placeholder={MaterialsContent.Add.Input.Name.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Select<TAddCourseForm, TSelectOption>
        name="language"
        id="add-course-language"
        label={MaterialsContent.Add.Input.Language.LABEL}
        placeholder={MaterialsContent.Add.Input.Language.PLACEHOLDER}
        options={languageOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Select<TAddCourseForm, TSelectOption<ELanguageLevel>>
        name="languageLevel"
        id="add-course-level"
        label={MaterialsContent.Add.Input.Level.LABEL}
        placeholder={MaterialsContent.Add.Input.Level.PLACEHOLDER}
        options={levelOptions}
        control={control}
        errors={errors}
        required
        fullWidth
      />
      <Textarea<TAddCourseForm>
        name="description"
        id="add-course-description"
        className="nb-sub-add-course-description-input"
        label={MaterialsContent.Add.Input.Description.LABEL}
        placeholder={MaterialsContent.Add.Input.Description.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        fullWidth
      />
      <ReactPortal wrapperId="add-course-modal-footer">
        <Button
          className="nb-sub-add-course-submit"
          variant="primary"
          type="submit"
          form={idRef.current}
          fullWidth
        >
          {MaterialsContent.Add.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
