import { get, post, put, remove } from "api";
import { TEACHERS_ENDPOINTS } from "api/endpoints/teachers";
import {
  TTeacher,
  TTeacherAddRequestBody,
  TTeachersResponse,
} from "types/api/teacher";

export const fetchLanguage = (): Promise<string[]> =>
  get<string[]>(TEACHERS_ENDPOINTS.GET_LANGUAGES);

export const fetchTeachers = (
  searchText = "",
  pageNumber = 0,
  pageSize = 10
): Promise<TTeachersResponse> =>
  get<TTeachersResponse>(TEACHERS_ENDPOINTS.GET_ALL_TEACHERS, {
    params: {
      searchText,
      pageNumber,
      pageSize,
    },
  });

export const fetchTeacherById = (id?: number): Promise<TTeacher> =>
  get<TTeacher>(`${TEACHERS_ENDPOINTS.GET_ALL_TEACHERS}/${id}`);

export const addTeacher = (
  teacher: TTeacherAddRequestBody
): Promise<TTeacher> => post<TTeacher>("teachers", teacher);

export const editTeacher = (teacherData: TTeacher): Promise<TTeacher> =>
  put<TTeacher>("teachers", teacherData);

export const removeTeacher = (id: number): Promise<void> =>
  remove<undefined>(`${TEACHERS_ENDPOINTS.GET_ALL_TEACHERS}/${id}`);

export const fetchTeachersByLanguage = (
  language: string
): Promise<TTeachersResponse> =>
  get<TTeachersResponse>(
    `${TEACHERS_ENDPOINTS.GET_ALL_TEACHERS}?language=${language}`
  );
