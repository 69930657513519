import { ExerciseConstructorContent } from "content";
import { Button } from "components/Interactive/Button";
import { Divider } from "components/Common/Divider";
import "./ExerciseConfigForm.styles.scss";

type ExerciseConfigFormProps = {
  /**
   * Flag that indicates whether the form is in edit mode.
   * @default false
   */
  isEditMode?: boolean;
  /**
   * Callback function that will fire on form submit event.
   * @param event - react synthetic event.
   */
  onSubmit?: (event?: React.BaseSyntheticEvent) => Promise<void>;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const ExerciseConfigForm: React.FC<ExerciseConfigFormProps> = (
  props
) => {
  const { isEditMode = false, onSubmit, children } = props;

  return (
    <form className="nb-exercise-constructor-config-form" onSubmit={onSubmit}>
      <div className="nb-exercise-constructor-config-content">{children}</div>
      <div className="nb-exercise-constructor-config-action">
        <Divider margin="bottom" marginSize="large" color="grey-100" />
        <Button
          className="nb-exercise-constructor-config-submit"
          variant="primary"
          type="submit"
          fullWidth
        >
          {isEditMode
            ? ExerciseConstructorContent.Config.EDIT_SUBMIT
            : ExerciseConstructorContent.Config.ADD_SUBMIT}
        </Button>
      </div>
    </form>
  );
};
