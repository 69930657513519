import { useEffect, useMemo, useState } from "react";
import { useTransition } from "@react-spring/web";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { WordList } from "../WordList";
import { AnimatedWordButton } from "../WordButton";
import { moveArrayElement } from "utils/common/array";
import {
  WORD_GAP,
  updateLinkedWords,
  updateWords,
} from "./CompareController.helpers";
import {
  TExerciseIdentifier,
  TWordsComparingExerciseParsedData,
  TWordsComparingExerciseParsedLinkedWord,
  TWordsComparingExerciseParsedWord,
} from "types/app/exercises";
import "./CompareController.styles.scss";

type TCompareControllerProps = {
  /**
   * Parsed exercise data from exercise payload.
   */
  exerciseData: TWordsComparingExerciseParsedData;
};

export const CompareController: React.FC<TCompareControllerProps> = (props) => {
  const { exerciseData } = props;

  const [words, setWords] = useState<TWordsComparingExerciseParsedWord[]>(
    exerciseData.words
  );
  const [linkedWords, setLinkedWords] = useState<
    TWordsComparingExerciseParsedLinkedWord[]
  >(exerciseData.shuffledLinkedWords);
  const [selectedWord, setSelectedWord] =
    useState<TWordsComparingExerciseParsedWord | null>(null);
  const [isWordsHighlighted, setIsWordsHighlighted] = useState<boolean>(true);
  const [isLinkedWordsHighlighted, setIsLinkedWordsHighlighted] =
    useState<boolean>(false);
  const [updateTransition, setUpdateTransition] = useState<boolean>(false);

  const isShowResult: boolean = words.every((word) => word.isPairSelected);

  const { isExercisePreviewMode, isExerciseLiveMode } = useLessonSettings();

  let height = 0;
  let linkedWordsHeight = 0;
  const wordIdToHeightMap = useMemo(
    () => new Map<TExerciseIdentifier, number>(),
    []
  );
  const wordIndexToHeightMap = useMemo(() => new Map<number, number>(), []);
  const linkedWordIdToHeightMap = useMemo(
    () => new Map<TExerciseIdentifier, number>(),
    []
  );
  const linkedWordIndexToHeightMap = useMemo(
    () => new Map<number, number>(),
    []
  );
  const linkedTransitions = useTransition(
    linkedWords.map((data, index) => {
      const wordHeight = wordIndexToHeightMap.get(index) ?? 0;
      const linkedWordHeight = linkedWordIdToHeightMap.get(data.id) ?? 0;
      const resultedHeight =
        linkedWordHeight > wordHeight ? linkedWordHeight : wordHeight;
      return {
        ...data,
        y:
          index === 0
            ? (linkedWordsHeight += resultedHeight) - resultedHeight
            : (linkedWordsHeight += resultedHeight + WORD_GAP) - resultedHeight,
        height: resultedHeight,
      };
    }),
    {
      key: (item: TWordsComparingExerciseParsedLinkedWord) => item.id,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: (
        data: TWordsComparingExerciseParsedLinkedWord & { y: number }
      ) => ({
        y: data.y,
        height: linkedWordIdToHeightMap.get(data.id),
        opacity: 1,
      }),
      update: ({ y, height }, index) => {
        const wordHeight = wordIndexToHeightMap.get(index) ?? 0;
        const resultedHeight = height > wordHeight ? height : wordHeight;

        return {
          y,
          height: resultedHeight,
        };
      },
      onRest: () => {
        setUpdateTransition((prev) => !prev);
      },
    }
  );
  const transitions = useTransition(
    words.map((data, index) => {
      const thisWordHeight = wordIdToHeightMap.get(data.id) ?? 0;
      const linkedWordHeight = linkedWordIndexToHeightMap.get(index) ?? 0;
      const resultedHeight =
        linkedWordHeight > thisWordHeight ? linkedWordHeight : thisWordHeight;
      return {
        ...data,
        y:
          index === 0
            ? (height += resultedHeight) - resultedHeight
            : (height += resultedHeight + WORD_GAP) - resultedHeight,
        height: resultedHeight,
      };
    }),
    {
      key: (item: TWordsComparingExerciseParsedWord) => item.id,
      from: { height: 0, opacity: 1 },
      leave: { height: 0, opacity: 1 },
      enter: (
        data: TWordsComparingExerciseParsedWord & { y: number; height: number },
        index
      ) => {
        const thisWordHeight = wordIdToHeightMap.get(data.id) ?? 0;
        const linkedWordHeight = linkedWordIndexToHeightMap.get(index) ?? 0;
        const resultedHeight =
          thisWordHeight > linkedWordHeight ? thisWordHeight : linkedWordHeight;

        return {
          y: data.y,
          height: resultedHeight,
          opacity: 1,
        };
      },
      update: ({ y, height }, index) => {
        const linkedWordHeight = linkedWordIndexToHeightMap.get(index) ?? 0;
        const resultedHeight =
          height > linkedWordHeight ? height : linkedWordHeight;

        return {
          y,
          height: resultedHeight,
        };
      },
      reset: updateTransition,
    }
  );

  useEffect(() => {
    if (isExercisePreviewMode) {
      const filledWords = exerciseData.words.map((word) => ({
        ...word,
        isPairCorrect: true,
        isPairSelected: true,
        selectedLinkedWordId: word.correctLinkedWordId,
      }));
      setWords(filledWords);
      setLinkedWords(
        exerciseData.shuffledLinkedWords
          .map((word) => ({
            ...word,
            isPairCorrect: true,
            isPairSelected: true,
            selectedWordId: word.correctWordId,
            order: filledWords.findIndex(
              (item) => item.id === word.correctWordId
            ),
          }))
          .sort((a, b) => a.order - b.order)
      );
    }

    if (isExerciseLiveMode) {
      setWords(exerciseData.words);
      setLinkedWords(exerciseData.shuffledLinkedWords);
    }
  }, [isExercisePreviewMode, isExerciseLiveMode, exerciseData]);

  const wordButtonClickHandler = (id: TExerciseIdentifier) => {
    const targetWord = words.find((word) => word.id === id) ?? null;
    if (!targetWord) {
      return;
    }
    setSelectedWord(targetWord);
    setIsWordsHighlighted(false);
    setIsLinkedWordsHighlighted(true);
  };

  const linkedWordButtonClickHandler = (id: TExerciseIdentifier) => {
    if (!selectedWord) {
      return;
    }
    const selectedLinkedWord =
      linkedWords.find((word) => word.id === id) ?? null;
    if (!selectedLinkedWord) {
      return;
    }
    const toIndex = words.findIndex((word) => word.id === selectedWord.id);
    const fromIndex = linkedWords.findIndex(
      (word) => word.id === selectedLinkedWord.id
    );
    setWords((prevWords) =>
      updateWords(prevWords, selectedWord.id, selectedLinkedWord)
    );
    setLinkedWords((prevLinkedWords) =>
      moveArrayElement(
        updateLinkedWords(prevLinkedWords, selectedLinkedWord.id, selectedWord),
        fromIndex,
        toIndex
      )
    );
    setSelectedWord(null);
    setIsWordsHighlighted(true);
    setIsLinkedWordsHighlighted(false);
  };

  return (
    <Space
      direction="horizontal"
      justify="between"
      align="stretch"
      size="medium"
      className="nb-exercise-words-comparing-container"
      fullWidth
    >
      <WordList style={{ height: height }}>
        {transitions((style, item, t, index) => (
          <AnimatedWordButton
            style={{
              zIndex: words.length - index,
              position: "absolute",
              willChange: "transform, height, opacity",
              ...style,
            }}
            id={item.id}
            content={item.content}
            selected={selectedWord?.id === item.id}
            highlight={!item.isPairSelected && isWordsHighlighted}
            paired={item.isPairSelected}
            correct={item.isPairCorrect}
            showResult={isShowResult}
            onClick={wordButtonClickHandler}
            onHeightChange={(id, height) => {
              wordIdToHeightMap.set(id, height);
              wordIndexToHeightMap.set(index, height);
            }}
          />
        ))}
      </WordList>
      <Divider direction="vertical" />
      <WordList style={{ height: linkedWordsHeight }}>
        {linkedTransitions((style, item, t, index) => (
          <AnimatedWordButton
            style={{
              zIndex: linkedWords.length - index,
              position: "absolute",
              willChange: "transform, height, opacity",
              ...style,
            }}
            id={item.id}
            content={item.content}
            highlight={!item.isPairSelected && isLinkedWordsHighlighted}
            paired={item.isPairSelected}
            correct={item.isPairCorrect}
            showResult={isShowResult}
            onClick={linkedWordButtonClickHandler}
            onHeightChange={(id, height) => {
              linkedWordIdToHeightMap.set(id, height);
              linkedWordIndexToHeightMap.set(index, height);
            }}
          />
        ))}
      </WordList>
    </Space>
  );
};
