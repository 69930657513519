export const Teachers = {
  Name: "Викладачі",
  Emoji: "📚️",
  Search: {
    PLACEHOLDER: "Пошук викладачів",
    NO_OPTIONS: "Викладачів не знайдено",
  },
  Table: {
    Header: {
      NUMBER: "№",
      AVATAR: "Фото",
      NAME: "Ім'я та Прізвище",
      EMAIL: "Пошта",
      PHONE: "Телефон",
      ACTION: "Дії",
    },
    Button: {
      EDIT: "Змінити",
    },
  },
  Empty: "Список викладачів пустий",
  Delete: {
    TITLE: "Ви дійсно хочете видалити викладача?",
    EMOJI: "😥",
    Notification: {
      SUCCESS: "Викладача успішно видалено",
      ERROR: "Не вдалося видалити викладача",
    },
  },
  Add: {
    TITLE: "Додати викладача",
    EMOJI: "📚",
    SUBMIT: "Додати",
    Notification: {
      SUCCESS: "Викладач успішно доданий",
      ERROR: "Не вдалось додати викладача",
    },
    Input: {
      FirstName: {
        LABEL: "Ім'я",
        PLACEHOLDER: "Тарас",
      },
      LastName: {
        LABEL: "Прізвище",
        PLACEHOLDER: "Шевченко",
      },
      Email: {
        LABEL: "Email",
        PLACEHOLDER: "taras@mail.com",
      },
      Phone: {
        LABEL: "Телефон",
        PLACEHOLDER: "+380 (099) 111-22-33",
      },
      Language: {
        LABEL: "Мова викладання",
        PLACEHOLDER: "Оберіть мову",
      },
    },
  },
  Edit: {
    TITLE: "Профіль",
    EMOJI: "📚",
    SUBMIT: "Зберегти",
    Notification: {
      SUCCESS: "Викладач успішно змінений",
      ERROR: "Не вдалось змінити дані викладача",
    },
    Input: {
      FirstName: {
        LABEL: "Ім'я",
        PLACEHOLDER: "Тарас",
      },
      LastName: {
        LABEL: "Прізвище",
        PLACEHOLDER: "Шевченко",
      },
      Email: {
        LABEL: "Email",
        PLACEHOLDER: "taras@mail.com",
      },
      Phone: {
        LABEL: "Телефон",
        PLACEHOLDER: "+380 (099) 111-22-33",
      },
      Language: {
        LABEL: "Мова викладання",
        PLACEHOLDER: "Оберіть мову",
      },
    },
  },
};
