import { InputProps, components } from "react-select";
import { TAssertionSelectDefaultOption } from "../../../AssertionSentences.types";
import "./Input.styles.scss";

export const Input = <
  Option extends TAssertionSelectDefaultOption = TAssertionSelectDefaultOption
>(
  props: InputProps<Option, false>
): JSX.Element => (
  <components.Input
    className="nb-interactive-true-or-false-assertion-select-input"
    {...props}
  />
);
