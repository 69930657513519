import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../appStore";
import { EAppReducers } from "../../white-list";

const getState = (state: RootState) => state[EAppReducers.EXERCISE_CONSTRUCTOR];

export const exerciseConstructorSelector = createSelector(
  getState,
  (state) => state
);
