import { useCallback, useEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useLazyTimeout } from "hooks/common/useLazyTimeout";
import { useKeyPress } from "hooks/common/useKeyPress";
import { useStateCallback } from "hooks/common/useStateCallback";
import { ReactPortal } from "components/Common/ReactPortal";
import { calculateTooltipPosition } from "./Tooltip.helpers";
import {
  TTooltipArrow,
  TTooltipPosition,
  TTooltipSpaceSize,
  TTooltipTrigger,
  TTooltipVariant,
} from "./Tooltip.types";
import "./Tooltip.styles.scss";

type TooltipProps = {
  /**
   * The text shown in the tooltip.
   * If `text` was not provided, a tooltip will never display.
   * @default null
   */
  text?: string | null;
  /**
   * The position of the tooltip relative to the target.
   * @default "top"
   */
  position?: TTooltipPosition;
  /**
   * Change arrow's visible state and change whether the arrow is pointed at the center of target.
   * @default "none"
   */
  arrow?: TTooltipArrow;
  /**
   * Tooltip trigger mode
   * @default "hover"
   */
  trigger?: TTooltipTrigger;
  /**
   * Space size between children and tooltip itself.
   * @namespace {
   *  none: 0px,
   *  x-small: 5px,
   *  small: 10px,
   *  medium: 15px,
   *  large: 20px,
   *  x-large: 25px,
   *  xx-large: 30px
   * }
   * @default "none"
   */
  spaceSize?: TTooltipSpaceSize;
  /**
   * Tooltip display variant mode.
   * @default "default"
   */
  variant?: TTooltipVariant;
  /**
   * If `true`, the component is disabled and wont appear on any action.
   * @default false
   */
  disabled?: boolean;
  /**
   * Whether the floating tooltip card is open by default.
   * @default false
   */
  defaultVisible?: boolean;
  /**
   * Delay in seconds, before tooltip is shown on mouse enter.
   * @default 0.1
   */
  mouseEnterDelay?: number;
  /**
   * Delay in seconds, before tooltip is hidden on mouse leave.
   * @default 0.1
   */
  mouseLeaveDelay?: number;
  /**
   * Callback executed when visibility of the tooltip card is changed.
   * @param visible - current `visible` state of the tooltip.
   */
  onVisibilityChange?: (visible: boolean) => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component container.
   */
  containerClassName?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

/**
 * `Tooltip` displays informative text when user hover over or tap an element.
 */
export const Tooltip: React.FC<TooltipProps> = (props) => {
  const {
    text = null,
    position = "top",
    arrow = "none",
    trigger = "hover",
    spaceSize = "none",
    variant = "default",
    disabled = false,
    defaultVisible = false,
    mouseEnterDelay = 0.1,
    mouseLeaveDelay = 0.1,
    onVisibilityChange,
    children,
    className,
    containerClassName,
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const wrapperId = useRef<string>(`nb-tooltip-${nanoid()}`);

  const [visible, setVisible] = useStateCallback<boolean>(defaultVisible);
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  // We need to store the mouse over state in a ref and not in a useState because
  // useState value will not be updated in timeout callback as we pass an outdated function reference.
  const isMouseOver = useRef<boolean>(false);

  const mouseEnterTimeout = useLazyTimeout(mouseEnterDelay);
  const mouseLeaveTimeout = useLazyTimeout(mouseLeaveDelay);

  const showTooltipHandler = useCallback(() => {
    const shouldShowTooltip =
      (!disabled || text !== null) && isMouseOver.current;

    if (shouldShowTooltip) {
      setVisible(true, onVisibilityChange);
    }
  }, [setVisible, onVisibilityChange, disabled, text]);

  const hideTooltipHandler = useCallback(() => {
    if (!disabled || text !== null) {
      setVisible(false, onVisibilityChange);
    }
  }, [setVisible, onVisibilityChange, disabled, text]);

  useKeyPress("Escape", hideTooltipHandler);

  const tooltipMouseEnterHandler = () => {
    if (trigger !== "hover") {
      return;
    }
    isMouseOver.current = true;
    mouseEnterTimeout(showTooltipHandler);
  };

  const tooltipMouseLeaveHandler = () => {
    if (trigger !== "hover") {
      return;
    }
    isMouseOver.current = false;
    mouseLeaveTimeout(hideTooltipHandler);
  };

  const tooltipClickHandler = () => {
    if (trigger !== "click" || disabled || text === null) {
      return;
    }
    setVisible((prevState) => !prevState, onVisibilityChange);
  };

  useEffect(() => {
    const outsideTooltipClickHandler = (event: MouseEvent) => {
      if (trigger !== "click") {
        return;
      }
      // If click on the container (trigger) we don't hide the tooltip here because
      // we have a separate handler for that.
      const isTargetTrigger =
        ref.current && ref.current.contains(event.target as Node);
      // If click on the content we don't hide the tooltip of UX reasons.
      const isTargetContent =
        contentRef && contentRef.contains(event.target as Node);
      if (!isTargetTrigger && !isTargetContent) {
        hideTooltipHandler();
      }
    };

    document.addEventListener("click", outsideTooltipClickHandler, true);

    return () => {
      document.removeEventListener("click", outsideTooltipClickHandler, true);
    };
  }, [hideTooltipHandler, trigger, contentRef]);

  const contentRefChangeHandler = useCallback((node: HTMLDivElement | null) => {
    setContentRef(node);
  }, []);

  const tooltipPositionHandler = useCallback(() => {
    if (!ref.current || !contentRef) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    const contentRect = contentRef.getBoundingClientRect();
    contentRef.style.setProperty(
      "--nb-interactive-tooltip-transform-translate",
      calculateTooltipPosition(rect, contentRect, position, spaceSize)
    );
  }, [position, spaceSize, contentRef]);

  useEffect(() => {
    if (visible) {
      tooltipPositionHandler();
    }

    window.addEventListener("scroll", tooltipPositionHandler);
    window.addEventListener("resize", tooltipPositionHandler);
    return () => {
      window.removeEventListener("scroll", tooltipPositionHandler);
      window.removeEventListener("resize", tooltipPositionHandler);
    };
  }, [tooltipPositionHandler, visible]);

  return (
    <div
      ref={ref}
      className={cx(["nb-interactive-tooltip-container", containerClassName])}
      onMouseEnter={tooltipMouseEnterHandler}
      onMouseLeave={tooltipMouseLeaveHandler}
      onClick={tooltipClickHandler}
      tabIndex={-1}
    >
      {children}
      {text !== null && (
        <ReactPortal wrapperId={wrapperId.current}>
          <div
            ref={contentRefChangeHandler}
            className={cx([
              "nb-interactive-tooltip-content",
              {
                "nb-interactive-tooltip-content--visible": visible,
                "nb-interactive-tooltip-content--compact":
                  variant === "compact",
              },
              className,
            ])}
            onClick={(evt) => evt.stopPropagation()}
            tabIndex={-1}
            aria-hidden={!visible}
          >
            <p role="tooltip" className="nb-interactive-tooltip-text">
              {text}
            </p>
            <span
              aria-hidden
              className={cx([
                "nb-interactive-tooltip-arrow",
                {
                  "nb-interactive-tooltip-arrow--visible": arrow === "visible",
                  "nb-interactive-tooltip-arrow--top": position === "top",
                  "nb-interactive-tooltip-arrow--top-start":
                    position === "top-start",
                  "nb-interactive-tooltip-arrow--top-end":
                    position === "top-end",
                  "nb-interactive-tooltip-arrow--right": position === "right",
                  "nb-interactive-tooltip-arrow--right-start":
                    position === "right-start",
                  "nb-interactive-tooltip-arrow--right-end":
                    position === "right-end",
                  "nb-interactive-tooltip-arrow--bottom": position === "bottom",
                  "nb-interactive-tooltip-arrow--bottom-start":
                    position === "bottom-start",
                  "nb-interactive-tooltip-arrow--bottom-end":
                    position === "bottom-end",
                  "nb-interactive-tooltip-arrow--left": position === "left",
                  "nb-interactive-tooltip-arrow--left-start":
                    position === "left-start",
                  "nb-interactive-tooltip-arrow--left-end":
                    position === "left-end",
                },
              ])}
            />
          </div>
        </ReactPortal>
      )}
    </div>
  );
};
