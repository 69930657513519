import { PlaceholderProps, components } from "react-select";
import { TSelectOption } from "types/app/select";
import "./Placeholder.styles.scss";

type SearchPlaceholderProps<
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
> = {
  placeholder: string;
} & PlaceholderProps<Option, false>;

export const Placeholder = <
  Option extends TSelectOption<string | number> = TSelectOption<string | number>
>(
  props: SearchPlaceholderProps<Option>
): JSX.Element => {
  const { placeholder } = props;

  return (
    <components.Placeholder
      {...props}
      className="nb-interactive-search-placeholder"
    >
      {placeholder ? placeholder : props.children}
    </components.Placeholder>
  );
};
