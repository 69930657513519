import { useMemo, useRef, useState } from "react";
import { Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CarouselContent } from "content";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { Button } from "components/Interactive/Button";
import { Paragraph } from "components/Typography/Paragraph";
import { CarouselSlide } from "../CarouselSlide";
import { TTrueOrFalseExerciseAnswer } from "types/app/exercises";
import "./Carousel.styles.scss";

type TCarouselProps = {
  /**
   * The answers that will be displayed in the carousel.
   */
  answers: TTrueOrFalseExerciseAnswer[];
  /**
   * The function that will be called when the user clicks on the answer button.
   */
  onAnswerClick?: (sentenceId: string, isUserAnswerCorrect: boolean) => void;
};

export const Carousel: React.FC<TCarouselProps> = (props) => {
  const { answers, onAnswerClick } = props;

  const swiperRef = useRef<SwiperType>();

  const [slidePosition, setSlidePosition] = useState<number>(1);

  const { isExerciseReadOnlyMode } = useLessonSettings();

  const isPreviousButtonDisabled: boolean = useMemo(
    () => slidePosition === 1 || isExerciseReadOnlyMode,
    [isExerciseReadOnlyMode, slidePosition]
  );
  const isNextButtonDisabled: boolean = useMemo(
    () => slidePosition === answers.length || isExerciseReadOnlyMode,
    [slidePosition, answers, isExerciseReadOnlyMode]
  );

  const carouselPreviousButtonClickHandler = () => {
    if (isPreviousButtonDisabled) {
      return;
    }
    swiperRef.current?.slidePrev();
  };

  const carouselNextButtonClickHandler = () => {
    if (isNextButtonDisabled) {
      return;
    }
    swiperRef.current?.slideNext();
  };

  const carouselSlideChangeHandler = (swiper: SwiperType) => {
    setSlidePosition(swiper.activeIndex + 1);
  };

  const carouselBeforeInitHandler = (swiper: SwiperType) => {
    swiperRef.current = swiper;
    setSlidePosition(swiper.activeIndex + 1);
  };

  const sentenceAnswerClickHandler = (
    sentenceId: string,
    isUserAnswerCorrect: boolean
  ) => {
    onAnswerClick?.(sentenceId, isUserAnswerCorrect);
  };

  return (
    <div className="nb-true-or-false-exercise-carousel-container">
      <Paragraph
        variant={2}
        className="nb-true-or-false-exercise-carousel-counter"
      >
        {slidePosition}&nbsp;/&nbsp;{answers.length}
      </Paragraph>
      <Space
        direction="horizontal"
        size="medium"
        justify="between"
        align="end"
        fullWidth
      >
        <Button
          variant="ghost"
          disabled={isPreviousButtonDisabled}
          onClick={carouselPreviousButtonClickHandler}
          className="nb-true-or-false-exercise-carousel-button"
        >
          {CarouselContent.Navigation.PREVIOUS}
        </Button>
        <Swiper
          slidesPerView={1}
          simulateTouch={false}
          onSlideChange={carouselSlideChangeHandler}
          onBeforeInit={carouselBeforeInitHandler}
        >
          {answers.map(({ content, isCorrect, id }) => (
            <SwiperSlide key={id}>
              <CarouselSlide
                id={id.toString()}
                sentenceText={content}
                isSentenceCorrect={isCorrect}
                onAnswerClick={sentenceAnswerClickHandler}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button
          variant="ghost"
          disabled={isNextButtonDisabled}
          onClick={carouselNextButtonClickHandler}
          className="nb-true-or-false-exercise-carousel-button"
        >
          {CarouselContent.Navigation.NEXT}
        </Button>
      </Space>
    </div>
  );
};
