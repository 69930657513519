import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useEditCourseLesson } from "hooks/api/courses";
import { CourseDetailsContent } from "content";
import { AvatarUpload } from "components/Interactive/AvatarUpload";
import { Input } from "components/Interactive/Input";
import { Textarea } from "components/Interactive/Textarea";
import { Button } from "components/Interactive/Button";
import { ReactPortal } from "components/Common/ReactPortal";
import { logError, logSuccess } from "utils/notifications";
import { convertBlobToBase64String } from "utils/common";
import { TCourseLesson, TEditCourseLessonBody } from "types/api/course";
import { TEditCourseLessonForm } from "./EditCourseLessonForm.types";
import "./EditCourseLessonForm.styles.scss";

type EditCourseLessonFormProps = {
  /**
   * Course lesson data to display current (default) values.
   */
  courseLesson: TCourseLesson | null;
  /**
   * Callback function fires on add course submit event.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const EditCourseLessonForm: React.FC<EditCourseLessonFormProps> = (
  props
) => {
  const { courseLesson, className, onSubmit } = props;

  const [selectedFile, setSelectedFile] = useState<Blob | null>(null);
  const idRef = useRef<string>(nanoid());

  const { mutate: editCourseLessonHandler } = useEditCourseLesson(
    courseLesson?.courseId
  );

  const defaultValues = useMemo(
    () => ({
      name: courseLesson?.name,
      ...(courseLesson?.description && {
        description: courseLesson?.description,
      }),
    }),
    [courseLesson]
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TEditCourseLessonForm>({
    mode: "onBlur",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleAddCourseSubmit = async (formValues: TEditCourseLessonForm) => {
    if (!courseLesson) {
      logError({
        message: CourseDetailsContent.Edit.Lesson.Notification.ERROR,
      });
      return;
    }

    const imageEncoded =
      selectedFile &&
      (await convertBlobToBase64String(selectedFile, { removePrefix: true }));

    const courseLessonBody: TEditCourseLessonBody = {
      id: courseLesson.id,
      courseId: courseLesson.courseId,
      name: formValues.name,
      description: formValues.description || null,
      order: courseLesson.order,
      imageUrl: courseLesson.imageUrl,
      homeworkId: courseLesson.homeworkId,
      // Adding this field conditionally because if it's null api will delete the image
      ...(imageEncoded && { imageEncoded }),
    };

    editCourseLessonHandler(courseLessonBody, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(CourseDetailsContent.Edit.Lesson.Notification.SUCCESS);
        setSelectedFile(null);
        reset({
          name: "",
          description: "",
        });
      },
    });
  };

  return (
    <form
      id={idRef.current}
      className={cx(["nb-sub-edit-course-lesson-form", className])}
      onSubmit={handleSubmit(handleAddCourseSubmit)}
    >
      <AvatarUpload
        bordered
        resetOnClose
        size="large"
        cropShape="round"
        mode="save"
        className="nb-sub-edit-course-lesson-avatar"
        previousImage={courseLesson?.imageUrl}
        onSave={setSelectedFile}
      />
      <Input<TEditCourseLessonForm>
        name="name"
        id="edit-course-lesson-name"
        className="nb-sub-edit-course-lesson-name-input"
        label={CourseDetailsContent.Edit.Lesson.Input.Name.LABEL}
        placeholder={CourseDetailsContent.Edit.Lesson.Input.Name.PLACEHOLDER}
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Textarea<TEditCourseLessonForm>
        name="description"
        id="edit-course-lesson-description"
        className="nb-sub-edit-course-lesson-description-input"
        label={CourseDetailsContent.Edit.Lesson.Input.Description.LABEL}
        placeholder={
          CourseDetailsContent.Edit.Lesson.Input.Description.PLACEHOLDER
        }
        register={register}
        errors={errors}
        autoFocus
        fullWidth
      />
      <ReactPortal wrapperId="edit-course-lesson-modal-footer">
        <Button
          className="nb-sub-edit-course-lesson-submit"
          variant="primary"
          type="submit"
          form={idRef.current}
          fullWidth
        >
          {CourseDetailsContent.Edit.Lesson.SUBMIT}
        </Button>
      </ReactPortal>
    </form>
  );
};
