import {
  TTooltipAxis,
  TTooltipPosition,
  TTooltipSpaceSize,
} from "./Tooltip.types";

const TOOLTIP_SPACE_OFFSET = {
  none: 0,
  "x-small": 5,
  small: 10,
  medium: 15,
  large: 20,
  "x-large": 25,
  "xx-large": 30,
} as const;

export const calculateTooltipPosition = (
  contentRect: DOMRect,
  tooltipRect: DOMRect,
  position: TTooltipPosition = "top",
  offsetSize: TTooltipSpaceSize = "none"
): string => {
  const { left, top, width, height } = contentRect;
  const { width: tooltipWidth, height: tooltipHeight } = tooltipRect;

  const getDimensions = (): TTooltipAxis => {
    switch (position) {
      case "top":
        return {
          x: left + width / 2 - tooltipWidth / 2,
          y: top - tooltipHeight,
        };

      case "top-start":
        return {
          x: left,
          y: top - tooltipHeight,
        };

      case "top-end":
        return {
          x: left + width - tooltipWidth,
          y: top - tooltipHeight,
        };

      case "bottom":
        return {
          x: left + width / 2 - tooltipWidth / 2,
          y: top + height,
        };

      case "bottom-start":
        return {
          x: left,
          y: top + height,
        };

      case "bottom-end":
        return {
          x: left + width - tooltipWidth,
          y: top + height,
        };

      case "left":
        return {
          x: left - tooltipWidth,
          y: top + height / 2 - tooltipHeight / 2,
        };

      case "left-start":
        return {
          x: left - tooltipWidth,
          y: top,
        };

      case "left-end":
        return {
          x: left - tooltipWidth,
          y: top + height - tooltipHeight,
        };

      case "right":
        return {
          x: left + width,
          y: top + height / 2 - tooltipHeight / 2,
        };

      case "right-start":
        return {
          x: left + width,
          y: top,
        };

      case "right-end":
        return {
          x: left + width,
          y: top + height - tooltipHeight,
        };

      default:
        throw new Error("Invalid tooltip position");
    }
  };

  const calcOffset = (dimensions: TTooltipAxis): TTooltipAxis => {
    if (position.includes("top")) {
      return {
        x: dimensions.x,
        y: dimensions.y - TOOLTIP_SPACE_OFFSET[offsetSize],
      };
    }

    if (position.includes("bottom")) {
      return {
        x: dimensions.x,
        y: dimensions.y + TOOLTIP_SPACE_OFFSET[offsetSize],
      };
    }

    if (position.includes("left")) {
      return {
        x: dimensions.x - TOOLTIP_SPACE_OFFSET[offsetSize],
        y: dimensions.y,
      };
    }

    if (position.includes("right")) {
      return {
        x: dimensions.x + TOOLTIP_SPACE_OFFSET[offsetSize],
        y: dimensions.y,
      };
    }

    return dimensions;
  };

  const dimensions = getDimensions();
  const { x, y } = calcOffset(dimensions);

  return `translate(${x}px, ${y}px)`;
};
