import { useNavigate } from "react-router-dom";
import { useModal } from "hooks/common/useModal";
import { MaterialsContent } from "content";
import { Space } from "components/Common/Space";
import { CoverButton } from "components/Interactive/CoverButton";
import { MaterialsPagination } from "components/Courses/MaterialsPagination";
import { MaterialsCoverItem } from "components/Courses/MaterialsCoverItem";
import { AddCourseModal } from "../AddCourseModal";
import { Route } from "constants/routes";
import { TCourse } from "types/api/course";
import "./MaterialsList.styles.scss";

type MaterialsListProps = {
  /**
   * Courses data to populate materials list.
   */
  courses?: TCourse[];
  /**
   * Current page number.
   * @default 0
   */
  currentPage?: number;
  /**
   * Total pages number.
   * @default 0
   */
  totalPages?: number;
  /**
   * The callback is fired when the page is changed.
   * @param {Number} page - new page index of the `Table` component.
   * @return {undefined} should be voided function.
   */
  onPageChange?: (newPage: number) => void;
  /**
   * Determines whether pagination is enabled.
   * If false, all materials will be displayed on a single page without pagination zone.
   * @default true
   */
  paginationEnabled?: boolean;
};

export const MaterialsList: React.FC<MaterialsListProps> = (props) => {
  const {
    courses = [],
    currentPage = 0,
    totalPages = 0,
    onPageChange,
    paginationEnabled = true,
  } = props;

  const navigate = useNavigate();

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);

  const courseCoverClickHandler = (id: number) => {
    navigate(Route.App.Materials.COURSE_DETAILS.construct(id));
  };

  return (
    <section className="nb-sub-materials-list-container">
      <Space
        direction="vertical"
        justify="start"
        align="start"
        size="medium"
        fullWidth
      >
        <ul className="nb-sub-materials-list">
          <li className="nb-sub-materials-list-item">
            <CoverButton onClick={openAddModal}>
              {MaterialsContent.Button.Add.TITLE}
            </CoverButton>
          </li>
          {courses.map((course) => (
            <MaterialsCoverItem
              key={course.id}
              course={course}
              onClick={courseCoverClickHandler}
            />
          ))}
        </ul>
        {paginationEnabled && (
          <MaterialsPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )}
      </Space>
      <AddCourseModal open={isAddModalOpen} onClose={closeAddModal} />
    </section>
  );
};
