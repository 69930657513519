import {
  Control,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { Avatar } from "components/Common/Avatar";
import { Text } from "components/Typography/Text";
import { Select } from "components/Interactive/Select";
import { TSelectOption } from "types/app/select";
import "./DetailedDurationSelect.styles.scss";

type DetailedDurationSelectProps<
  TFormValues extends FieldValues = FieldValues,
  Option extends TSelectOption<number> = TSelectOption<number>
> = {
  /**
   * Detailed data to display near `Select` component.
   */
  details?: {
    id: number;
    name: string;
    description: string;
  };
  /**
   * Name attribute of the `Select` element.
   * Also this field is required for `react-hook-form` to control element.
   */
  name: FieldPath<TFormValues>;
  /**
   * The id of the `select` element.
   * Provide if label is used.
   */
  id?: string;
  /**
   * The short hint displayed in the `Select` before the user selects a value.
   */
  placeholder?: string;
  /**
   * If `true`, the `Select` will be disabled.
   * @default false
   */
  readonly?: boolean;
  /**
   * Array of options that populate the select menu
   */
  options?: Option[];
  /**
   * 	An object with field errors. There is also an ErrorMessage component to retrieve error message easily.
   */
  errors?: Partial<FieldErrorsImpl<TFormValues>>;
  /**
   * This object contains methods for registering components into React Hook Form.
   */
  control?: Control<TFormValues>;
};

export const DetailedDurationSelect = <
  TFormValues extends FieldValues = FieldValues,
  Option extends TSelectOption<number> = TSelectOption<number>
>(
  props: DetailedDurationSelectProps<TFormValues, Option>
): JSX.Element => {
  const {
    errors,
    control,
    name,
    options = [],
    id,
    placeholder,
    readonly = false,
    details,
  } = props;

  return (
    <div className="nb-sub-detailed-duration-wrapper">
      <div className="nb-sub-detailed-duration-info">
        <Avatar size="small" />
        <div className="nb-sub-detailed-duration-content">
          <Text variant="body2" bold>
            {details?.name}
          </Text>
          <Text
            variant="body2"
            className="nb-sub-detailed-duration-content-secondary"
          >
            {details?.description}
          </Text>
        </div>
      </div>
      <Select<TFormValues, Option>
        name={name}
        id={id}
        icon={<TimeIcon width={15} height={15} />}
        placeholder={placeholder}
        options={options}
        control={control}
        errors={errors}
        readonly={readonly}
        className="nb-sub-detailed-duration-select"
        required
        fullWidth
      />
    </div>
  );
};
