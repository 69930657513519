import { useMemo } from "react";
import { useModal } from "hooks/common/useModal";
import { Avatar } from "components/Common/Avatar";
import { Text } from "components/Typography/Text";
import { Loader } from "components/Common/Loader";
import { Button } from "components/Interactive/Button";
import { AddStudentsModal } from "../AddStudentsModal";
import { GroupDetailsContent } from "content";
import { getStudentPlural } from "utils/content";
import { TGroup } from "types/api/groups";
import "./GroupHero.styles.scss";

type GroupHeroProps = {
  /**
   * Group data to display in `Hero` component.
   */
  group?: TGroup;
  /**
   * If `true` component will display loader with animation.
   *
   * @default false
   */
  loading?: boolean;
};

export const GroupHero: React.FC<GroupHeroProps> = (props) => {
  const { group, loading = false } = props;

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);

  const studentsAmountLabel: string = useMemo(
    () => getStudentPlural(group?.memberIds.length),
    [group?.memberIds.length]
  );

  const addStudentsClickHandler = () => {
    openAddModal();
  };

  return (
    <>
      <section className="nb-sub-group-details-hero">
        {loading ? (
          <Loader />
        ) : (
          <div className="nb-sub-group-details-hero-wrapper">
            <div className="nb-sub-group-details-hero-content">
              <Avatar bordered size="small" />
              <div className="nb-sub-group-details-hero-info">
                <Text
                  variant="body1"
                  bold
                  className="nb-sub-group-details-hero-title"
                >
                  {group?.name}
                </Text>
                <Text variant="body2">{studentsAmountLabel}</Text>
              </div>
            </div>
            <div className="nb-sub-group-details-hero-actions">
              <Button
                variant="primary"
                className="nb-sub-group-details-hero-button"
              >
                {GroupDetailsContent.Hero.Action.Button.MATERIALS}
              </Button>
              <Button
                variant="secondary"
                className="nb-sub-group-details-hero-button"
                onClick={addStudentsClickHandler}
              >
                {GroupDetailsContent.Hero.Action.Button.ADD_STUDENTS}
              </Button>
            </div>
          </div>
        )}
      </section>
      <AddStudentsModal
        group={group}
        open={isAddModalOpen}
        onClose={closeAddModal}
      />
    </>
  );
};
