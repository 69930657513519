import { useQuery } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { getCourseById } from "api/services/courses";
import { logError } from "utils/notifications";
import { TCourse } from "types/api/course";
import { TApiError } from "types/api/error";

export const useCourseById = (id?: string | number) => {
  return useQuery<TCourse, TApiError>(
    [ECoursesQueryKeys.getCourseById, id],
    () => getCourseById(id),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: id !== undefined,
    }
  );
};
