import { useForm } from "react-hook-form";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { TRecordAudioExerciseForm } from "./RecordAudioExerciseForm.types";
import "./RecordAudioExerciseForm.styles.scss";

export const RecordAudioExerciseForm: React.FC = () => {
  const { handleSubmit } = useForm<TRecordAudioExerciseForm>({
    mode: "onBlur",
    defaultValues: {},
  });

  const addExerciseSubmitHandler = (formData: TRecordAudioExerciseForm) => {
    // eslint-disable-next-line no-console
    console.log("add RecordAudioExerciseForm handler", formData);
  };

  return (
    <ExerciseConfigForm onSubmit={handleSubmit(addExerciseSubmitHandler)}>
      RecordAudioExerciseForm
    </ExerciseConfigForm>
  );
};
