import { forwardRef, useRef, useState } from "react";
import cx from "classnames";
import { FileUploadContent } from "content";
import { Text } from "components/Typography/Text";
import { convertFileToBlob } from "utils/common";
import { mergeRefs } from "utils/react";
import { logError } from "utils/notifications";
import "./UploadInput.styles.scss";

export type TUploadInputProps = {
  /**
   * The name of the `input` element.
   */
  name?: string;
  /**
   * The id of the `input` element.
   */
  id?: string;
  /**
   * The text content that will be shown in the file input area.
   */
  inputLabel?: string;
  /**
   * Option to fit width to its parent's width.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Disable upload button.
   * @default false
   */
  disabled?: boolean;
  /**
   * File types that can be accepted. See input accept Attribute
   * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
   * @default "image/*"
   */
  accept?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * A callback function executed when file(s) are selected or dragged and dropped into upload area.
   * @param file - user selected file as raw binary data.
   */
  onFileChange?: (file: Blob) => void;
};

const UploadInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TUploadInputProps
> = (props, ref) => {
  const {
    name = "interactive-upload-input",
    id = "upload-input",
    inputLabel = FileUploadContent.Input.LABEL,
    fullWidth = false,
    disabled = false,
    accept = "image/*",
    onFileChange,
    className,
  } = props;

  const innerRef = useRef<HTMLInputElement | null>(null);

  const [dragActive, setDragActive] = useState<boolean>(false);

  const filesHandler = (files: FileList) => {
    const [file] = files;
    if (file) {
      convertFileToBlob(file)
        .then(onFileChange)
        .catch((err) => logError({ message: err?.message }));
    }
  };

  const fileChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const files = evt.target.files;
    if (!files) {
      logError({ message: FileUploadContent.Notification.NO_FILES_ERROR });
      return;
    }
    filesHandler(files);
  };

  const fileDragHandler = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.type === "dragenter" || evt.type === "dragover") {
      setDragActive(true);
    }
    if (evt.type === "dragleave") {
      setDragActive(false);
    }
  };

  const fileDropHandler = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDragActive(false);
    const files = evt.dataTransfer.files;
    if (!files) {
      logError({ message: FileUploadContent.Notification.NO_FILES_ERROR });
      return;
    }
    filesHandler(files);
  };

  const fileUploadButtonClick = () => {
    innerRef.current?.click();
  };

  return (
    <div
      className={cx([
        "nb-interactive-upload-input-wrapper",
        { "nb-interactive-upload-input-wrapper--full": fullWidth },
        className,
      ])}
      onDragEnter={fileDragHandler}
    >
      <input
        ref={mergeRefs([ref, innerRef])}
        id={id}
        name={name}
        type="file"
        disabled={disabled}
        accept={accept}
        multiple={false}
        onChange={fileChangeHandler}
        className="nb-interactive-upload-input"
      />
      <button
        type="button"
        disabled={disabled}
        onClick={fileUploadButtonClick}
        className={cx([
          "nb-interactive-upload-input-button",
          {
            "nb-interactive-upload-input-button--drag-active": dragActive,
          },
        ])}
      >
        <Text
          variant="body2"
          className="nb-interactive-upload-input-button-text"
        >
          {inputLabel}
        </Text>
      </button>
      {dragActive && (
        <div
          aria-hidden
          className="nb-interactive-upload-input-drag-area"
          onDragEnter={fileDragHandler}
          onDragLeave={fileDragHandler}
          onDragOver={fileDragHandler}
          onDrop={fileDropHandler}
        ></div>
      )}
    </div>
  );
};

/**
 * `UploadInput` in interactive component that helps you with drag'n'drop files to a specific area, to select your required files.
 * Alternatively, you can also select by clicking on an input.
 */
export const ForwardedUploadInput = forwardRef(UploadInput);
