import { NoticeProps, components } from "react-select";
import { TPersonSelectOption } from "types/app/select";
import "./NoOptionsMessage.styles.scss";

type NoOptionsMessageProps<
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
> = {
  noOptionsText: string;
} & NoticeProps<Option, IsMulti>;

export const NoOptionsMessage = <
  Option extends TPersonSelectOption = TPersonSelectOption,
  IsMulti extends boolean = false
>(
  props: NoOptionsMessageProps<Option, IsMulti>
): JSX.Element => {
  const { noOptionsText } = props;

  return (
    <components.NoOptionsMessage
      {...props}
      className="nb-interactive-person-select-no-options"
    >
      {noOptionsText ? noOptionsText : props.children}
    </components.NoOptionsMessage>
  );
};
