import { EExerciseType, TExerciseItem } from "types/app/exercises";

export const moveWordsIntoGapsExercise: TExerciseItem = {
  title: "Перенести слова у пропуски",
  backgroundText: "Перенести",
  tags: [
    "Перенести слова у пропуски",
    "перенести",
    "перетягти",
    "перемістити",
    "слова",
    "у",
    "пропуски",
    "move",
    "words",
    "into",
    "gaps",
    "blanks",
  ],
  type: EExerciseType.MOVE_WORDS_INTO_GAPS,
  cover: "move-words-into-gaps.png",
};
