import { TServerMeta } from "./server";

export enum ERequestStatus {
  PROCESSED = "PROCESSED",
  NEW = "NEW",
}

export enum ERequestStudyMethod {
  INDIVIDUAL = "INDIVIDUAL",
  GROUP = "GROUP",
}

export type TRequest = {
  id: number;
  name: string;
  phone: string;
  studyMethod: string | null;
  period: string | null;
  status: ERequestStatus;
  createdAt: string | null;
};

export type TRequestResponse = {
  content: TRequest[];
} & TServerMeta;

export type TAddRequestBody = Omit<TRequest, "id" | "status" | "createdAt">;

export type TEditRequestBody = TRequest;
