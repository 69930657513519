import { nanoid } from "nanoid";
import { BRACKET_CONTENT } from "constants/regexp";
import { shuffleArray } from "utils/common";
import { COMPARE_IMAGE_WITH_DESCRIPTION_EXERCISE_MODE_OPTIONS } from "utils/options";
import {
  TCompareImageWithDescriptionExerciseImage,
  TCompareImageWithDescriptionExerciseParsedAnswer,
  TCompareImageWithDescriptionExerciseParsedData,
  TCompareImageWithDescriptionExerciseParsedImage,
  TCompareImageWithDescriptionExercisePayload,
} from "types/app/exercises";
import { TSelectOption } from "types/app/select";
import { TExercise } from "types/api/exercise";
import { TCompareImageWithDescriptionExerciseForm } from "./CompareImageWithDescriptionExerciseForm.types";

const [firstModeOption] = COMPARE_IMAGE_WITH_DESCRIPTION_EXERCISE_MODE_OPTIONS;

export const getCompareImageWithDescriptionExerciseFormInitialValues = (
  editExercise: TExercise<TCompareImageWithDescriptionExercisePayload> | null
): TCompareImageWithDescriptionExerciseForm => ({
  text: editExercise?.payload.text || "",
  mode:
    COMPARE_IMAGE_WITH_DESCRIPTION_EXERCISE_MODE_OPTIONS.find(
      (mode) => mode.value === editExercise?.payload.data.mode
    ) || firstModeOption,
  images: editExercise?.payload.data.images.map((image) => ({
    id: image.rawImage.id,
    description: image.rawImage.description,
    imageEncoded: image.fileEncoded,
    imageUrl: image.fileUrl || null,
  })) || [
    {
      id: nanoid(),
      description: "",
      imageEncoded: null,
      imageUrl: null,
    },
  ],
});

/**
 * Utility function that helps to adapt raw images data to structured data which makes easier to work with complex operations such as drag and drop, select, etc.
 * @description The `description` field can come with answers in 3 different formats: `[answer1]`, `[hint/answer1/answer2/...]`, `[/answer1/answer2/...]`, so the goal is to split each sub-string into answers and hint. Note: if the first string answer is starting with `/` then it will be considered as an omitted hint and will be ignored. So, if brackets contain only one answer, then it will be considered as a single answer, otherwise it will be considered as a hint with answers.
 * @example
 * "[string1*]" => "[answer1*]"
 * "[string1/string2*]" => "[hint/answer1*]"
 * "[/string1/string2*]" => "[answer1/answer2*]"
 * @param images raw user inputted images data.
 * @returns {TCompareImageWithDescriptionExerciseParsedData} object of structured exercise data.
 */
export const adaptExerciseImagesToData = (
  images: TCompareImageWithDescriptionExerciseImage[],
  prevImages: TCompareImageWithDescriptionExerciseParsedImage[] = []
): TCompareImageWithDescriptionExerciseParsedData => {
  const parsedImages: TCompareImageWithDescriptionExerciseParsedImage[] = [];
  const answers: TCompareImageWithDescriptionExerciseParsedAnswer[] = [];

  for (const [index, image] of images.entries()) {
    const { id, description, imageEncoded } = image;
    const prevImage = prevImages[index];

    const parsedDescription: string[] = description
      .trim()
      .toLowerCase()
      .split(BRACKET_CONTENT)
      .map((item) => item.trim())
      .filter(Boolean);

    const [rawAnswers] = parsedDescription.filter(
      (item) => item.startsWith("[") && item.endsWith("]")
    );

    if (!rawAnswers) {
      continue;
    }

    const splittedAnswers: string[] = rawAnswers
      .slice(1, rawAnswers.length - 1)
      .split("/");

    const isSingleAnswer = splittedAnswers.length === 1;
    const [hintRaw] = isSingleAnswer ? [] : splittedAnswers.splice(0, 1);
    const hint = hintRaw || null;

    const parsedAnswers: TCompareImageWithDescriptionExerciseParsedAnswer[] =
      splittedAnswers.map((item) => ({
        id: nanoid(),
        content: item.replace(/\*/g, ""),
        isCorrect: item.includes("*"),
        isTouched: false,
        isSelected: false,
      }));

    const options: TSelectOption<string>[] = parsedAnswers.map((item) => ({
      label: item.content,
      value: item.id.toString(),
    }));

    const [, fileEncoded] = imageEncoded?.split(",") || [];

    const parsedImage: TCompareImageWithDescriptionExerciseParsedImage = {
      id,
      attempts: [],
      correctAnswers: parsedAnswers.filter((item) => item.isCorrect),
      currentAnswer: null,
      currentAttempt: 0,
      hint,
      isEmpty: true,
      isAllAttemptsFailed: false,
      isCorrectAnswer: false,
      isLocked: false,
      isTouched: false,
      options,
      rawImage: {
        id: image.id,
        description: image.description,
      },
      fileUrl: prevImage?.fileUrl || "",
      fileEncoded,
    };

    parsedImages.push(parsedImage);
    answers.push(...parsedAnswers);
  }

  return {
    images: parsedImages,
    answers,
    shuffledAnswers: shuffleArray(answers),
  };
};
