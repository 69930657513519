import { useQuery } from "react-query";
import { EExercisesQueryKeys } from "api/endpoints/exercises";
import { fetchExercises } from "api/services/exercises";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TExercise } from "types/api/exercise";
import { TUrlParam } from "types/utils";

export const useExercises = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam
) => {
  return useQuery<TExercise[], TApiError>(
    [EExercisesQueryKeys.getExercises, courseId, lessonId, chapterId],
    () => fetchExercises(courseId, lessonId, chapterId),
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
