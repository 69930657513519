import { get, post, put, remove } from "api";
import { GROUPS_ENDPOINTS } from "api/endpoints/groups";
import { TAddGroup, TAllGroups, TGroup } from "types/api/groups";
import { TStudent } from "types/api/student";

export const fetchGroups = (
  searchText = "",
  pageNumber = 0,
  pageSize = 10
): Promise<TAllGroups> =>
  get<TAllGroups>(GROUPS_ENDPOINTS.GROUPS, {
    params: {
      searchText,
      pageNumber,
      pageSize,
    },
  });

export const addGroup = (group: TAddGroup): Promise<TGroup> =>
  post<TGroup>(GROUPS_ENDPOINTS.GROUPS, group);

export const editGroup = (group: TGroup): Promise<TGroup> =>
  put<TGroup>(GROUPS_ENDPOINTS.GROUPS, group);

export const removeGroup = (id: number): Promise<void> =>
  remove<undefined>(`${GROUPS_ENDPOINTS.GROUPS}/${id}`);

export const fetchGroupById = (id?: number): Promise<TGroup> =>
  get<TGroup>(`${GROUPS_ENDPOINTS.GROUPS}/${id}`);

export const fetchGroupMembers = (id?: number): Promise<TStudent[]> =>
  get<TStudent[]>(`${GROUPS_ENDPOINTS.GROUPS}/${id}/students`);
